<template>
  <div class="filter-container">
    <p class="filter-title">{{ title }}</p>
    <ul class="filter-set">
      <li v-for="item in items.slice(0, numToShow)" :key="item.name">
        <label class="checkbox-container">
          <span :for="item.name" class="filter-name mx-1">
            <span
              v-if="
                item.name != 'Consulting' &&
                item.name != 'Invest' &&
                item.name != 'Mentoring'
              "
              >{{ item.name }}</span
            >
            <span v-if="item.name === 'Consulting'">{{
              $t("Consultants")
            }}</span>
            <span v-if="item.name === 'Invest'">{{ $t("investors") }}</span>
            <span v-if="item.name === 'Mentoring'">{{ $t("Mentors") }}</span>
          </span>
          ({{ item.count }})
          <input type="checkbox" @click="addfilter(item)" />
          <span class="checkmark"></span>
        </label>
      </li>
    </ul>
    <a
      href="#"
      v-if="items.length > 4 && wantMore"
      class="btn btn-more"
      @click.prevent="seeMore()"
    >
      {{ $t("More") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="7"
        viewBox="0 0 11.092 6.732"
        class="chevron-arrow"
      >
        <path
          d="M10.771,130.048l-4.937-4.937a.61.61,0,0,1,0-.862l.576-.576a.61.61,0,0,1,.861,0l3.931,3.912,3.931-3.912a.61.61,0,0,1,.861,0l.576.576a.61.61,0,0,1,0,.862l-4.937,4.937a.61.61,0,0,1-.862,0Z"
          transform="translate(-5.656 -123.495)"
          fill="#1ba54b"
        />
      </svg>
    </a>
    <a
      href="#"
      v-if="items.length > 4 && !wantMore"
      class="btn btn-more"
      @click.prevent="seeLess()"
    >
      {{ $t("Less") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="7"
        viewBox="0 0 12.786 7.759"
        class="chevron-arrow"
      >
        <path
          d="M11.552,131.048l-5.69-5.69a.7.7,0,0,1,0-.994l.664-.664a.7.7,0,0,1,.993,0l4.531,4.51,4.531-4.51a.7.7,0,0,1,.993,0l.664.664a.7.7,0,0,1,0,.994l-5.69,5.69a.7.7,0,0,1-.994,0Z"
          transform="translate(18.442 131.254) rotate(180)"
          fill="#1ba54b"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "../../i18n";
export default {
  watch: {
    numToShow: function (val) {
      this.filterOptions = this.items.slice(0, val);
    },
  },
  created() {
    this.numToShow = this.limit;
    this.filterOptions = this.items.slice(0, this.numToShow);
    this.allFilters = this.getSearchFilters;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getDiaspora",
      "getShowPagination",
      "getSearchFilters",
      "getModalMode",
      "getDiasporaPage",
      "getDiasporaLimit",
      "getDiasporaTotalCount",
    ]),
  },
  data() {
    return {
      checked: [],
      wantMore: true,
      numToShow: 10,
      filterOptions: [],
      filters: [],
      allFilters: [],
      exists: false,
    };
  },
  methods: {
    ...mapActions(["searchWithFilters"]),
    async addfilter(item) {
      this.$store.commit("setDiasporaPage", 1);
      if (this.allFilters[this.code].includes(item.value)) {
        this.filters = this.filters.filter((option) => {
          return option !== item.value;
        });
      } else {
        this.filters.push(item.value);
      }
      this.$store.commit("setSearchFilters", {
        field: this.code,
        filters: this.filters,
      });

      await this.searchWithFilters({
        data: {
          user_id: this.authUser.id,
          page: this.getDiasporaPage,
          limit: this.getDiasporaLimit,
          filters: this.getSearchFilters,
        },
      });
      if (
        this.getDiaspora.length >= this.getDiasporaLimit &&
        this.getDiaspora.length < this.getDiasporaTotalCount
      ) {
        this.$store.commit("setShowLoadMore", true);
      } else if (
        this.getDiaspora.length === this.getDiasporaTotalCount ||
        this.getDiaspora.length === 0
      ) {
        this.$store.commit("setShowLoadMore", false);
      }
    },
    seeMore: function () {
      this.numToShow = this.items.length;
      this.wantMore = false;
    },
    seeLess: function () {
      this.numToShow = this.limit;
      this.wantMore = true;
    },
  },

  props: {
    title: String,
    items: Array,
    code: String,
    limit: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style scoped>
svg {
  width: 0.625rem !important;
  height: 0.625rem !important;
}
</style>
<style>
.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  height: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 2px solid #cdd0d5;
  border-radius: 5px;
}
.checkbox-container:hover input ~ .checkmark {
  border: 2px solid #1ba54b;
}
.checkbox-container input:checked ~ .checkmark {
  background-color: #1ba54b;
  border: 2px solid #1ba54b;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
<style scoped>
p {
  margin: 0;
}
li {
  font-size: 0.85rem;
}
.filter-container {
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #f0f4f2;

  /* background-color: #1ca54c09; */
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.057);
  margin: 5px 5px;
  padding: 1rem 0.5rem;
}
.filter-container:last-child {
  border-bottom: none;
}
.filter-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #000000 !important;
}
.filter-set {
  padding-left: 0.25rem;
  margin: 1rem 0;
}
.filter-set > li {
  list-style: none !important;
  padding: 0;
}
.filter-name {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-more {
  display: block;
  background: #cfead9;
  padding: 0.5rem;
  color: #1ba54b;
  border-radius: 1rem;
  font-size: 0.75rem;
  text-align: center;
  text-decoration: none;
}
.btn-more:hover {
  background: #1ba54b;
  color: #fff;
}
.btn-more:hover path {
  fill: #fff;
}
.btn-more::before {
  display: inline-block;
}
.chevron-arrow {
  vertical-align: middle;
  margin-left: 1rem;
}
</style>