<template>
  <form action="" class="form" v-on:submit.prevent="submit">
    <h1 class="form__title py-3">{{ $t("recoverPassword") }}</h1>
    <Alert v-if="success" :text="success_msg" type="success" :size="12" />
    <Alert v-if="error" :text="error_msg" type="danger" :size="12" />
    <Input
      v-model="form.email"
      typeName="email"
      label="Email"
      name="Email"
      @change="checkInput('email')"
      :isRequired="true"
      :hasError="errors.email"
      :error_msg="msg.email"
      class="py-4"
    />
    <SubmitBtn value="send" class="my-4" :hasLoader="true" :loading="loading" />
    <LoginBtn
      text="alreadyOn"
      buttonText="signIn"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.login')}`"
    />
    <LoginBtn
      text="newToBantaba"
      buttonText="joinNow"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.register')}`"
      class="my-4"
    />

    <!-- <div class="row">
      <AuthSimpleBtn
        text="forgotPassword"
        buttonText="recoverPassword"
        :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.login')}`"
      />
      <AuthSimpleBtn
        text="newToBantaba"
        buttonText="joinNow"
        :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.register')}`"
      />
    </div> -->
  </form>
</template>
<script>
import Input from "./Input";
import SubmitBtn from "./SubmitBtn";
import LoginBtn from "./LoginBtn";
import AuthSimpleBtn from "./AuthSimpleBtn";
import CheckBox from "./CheckBox.vue";

import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import store from "../../store/";
import APIURLS from "../../constant";
export default {
  beforeRouteEnter: (to, from, next) => {
    if (store.user) {
      return next(`/${i18n.locale}/${i18n.t("routes.dashboard")}`);
    }
    next();
  },
  data() {
    return {
      form: {
        email: "",
      },
      errors: {
        email: false,
      },
      msg: {
        email: "",
      },
      success_msg: "",
      sent: 0,
      error: null,
      success: null,
      loading: false,
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Form",
  components: {
    Input,
    SubmitBtn,
    LoginBtn,
    //AuthSimpleBtn,
  },
  methods: {
    ...mapActions(["recoverPassword", "checkEmailExist"]),
    submit() {
      this.loading = true;
      let lang;
      if (localStorage.getItem("userLang")) {
        lang = localStorage.getItem("userLang");
      } else {
        lang = this.$i18n.locale;
      }
      if (!this.isEmpty(this.form.email)) {
        this.form.link = `${APIURLS.SITELINK}${i18n.locale}/new-pass/`;
        this.form.subject = this.$i18n.t("resetSubject");
        this.form.title = this.$i18n.t("resetTitle");
        this.form.text = this.$i18n.t("resetText");
        this.form.text2 = this.$i18n.t("notReset");
        this.form.lang = lang;
        this.form.buttonText = this.$i18n.t("resetButtonText");
        this.recoverPassword({ data: this.form })
          .then((response) => {
            if (response) {
              this.loading = false;
              this.success = true;
              this.error = false;
              this.success_msg = this.$i18n.t("emailHasBeenSent");
              this.sent = true;
              this.$noty.success(this.$i18n.t("success"));
              this.form.email = "";
            }
            // this.$router.push(`/${i18n.locale}/${i18n.t('routes.dashboard')}`);
          })
          .catch((error) => {
            this.loading = false;
            this.error = true;
            this.success = false;
            this.sent = false;
            this.msg.email = error;
            this.error_msg = error;
            console.log("rejected", error);
            // this.$noty.error(error);
          });
      } else {
        this.error = true;
        this.errors.email = true;
        this.msg.email = "email is required";
      }
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async checkInput(name) {
      if (this.isEmpty(this.form[name])) {
        this.errors[name] = true;
        this.msg[name] = `${this.$i18n.t(name)} is required`;
      } else {
        this.errors[name] = null;
        this.msg[name] = "";
      }

      //remo  ve empty space at the beginning and the end
      let formValue = this.form[name].trim();

      //validate email
      if (name === "email") {
        if (this.validateEmail(this.form[name])) {
          this.errors[name] = null;
        } else {
          this.errors[name] = true;
          this.msg[name] = "Email address not valid!";
        }
      }
    },
  },
};
</script>
<style scoped>
.form {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  border-radius: 1rem;
  box-shadow: none;
}
.form__title {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 1.5rem;
}
.row-inputs {
  display: flex;
  justify-content: space-between;
}
.input-div {
  position: relative;
  width: 48%;
}
.term-and-service-container {
  display: flex;
  padding: 12px 0;
  padding-top: 25px;
  font-size: 14px;
}

.term-highlight {
  color: var(--primary-green);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    padding-bottom: 100px;
    border-radius: 1rem;
    box-shadow: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>