
import Network from '../views/connections/Network.vue';
import Connected from '../views/connections/Connected.vue';
import Requested from '../views/connections/Requested.vue';
import Blocked from '../views/connections/Blocked.vue';
import Diaspora from '../views/connections/Diaspora.vue';
import Mentors from '../views/connections/Mentors.vue';
import Consultants from '../views/connections/Consultants.vue';
import Followers from '../views/connections/Followers.vue';
import i18n from "../i18n";
import { authGuard } from './guard';

var userLang = navigator.language || navigator.userLanguage;

var defaultLang = userLang.substring(0, 2);

i18n.locale = defaultLang;

export default [

  {
    path: "my-network",
    name: "NetworkEn",
    component: Network,
    beforeEnter: authGuard
  },
  {
    path: "mon-réseau",
    name: "NetworkFr",
    component: Network,
    beforeEnter: authGuard
  },
  {
    path: "diaspora",
    name: "DiasporaEn",
    component: Diaspora,
    beforeEnter: authGuard
  },
  {
    path: "diaspora",
    name: "DiasporaFr",
    component: Diaspora,
    beforeEnter: authGuard
  },
  {
    path: "mentors",
    name: "MentorsEn",
    component: Mentors,
    beforeEnter: authGuard
  },
  {
    path: "mentors",
    name: "MentorsFr",
    component: Mentors,
    beforeEnter: authGuard
  },
  {
    path: "consultants",
    name: "ConsultantsEn",
    component: Consultants,
    beforeEnter: authGuard
  },
  {
    path: "consultants",
    name: "ConsultantsFR",
    component: Consultants,
    beforeEnter: authGuard
  },

  {
    path: "followers",
    name: "FollowersEn",
    component: Followers,
    beforeEnter: authGuard
  },
  {
    path: "abonnés",
    name: "FollowersFr",
    component: Followers,
    beforeEnter: authGuard
  },

  {
    path: "mon-réseau/connections",
    name: "ConnectedFr",
    component: Connected,
    beforeEnter: authGuard
  },
  {
    path: "my-network/connections",
    name: "ConnectedEn",
    component: Connected,
    beforeEnter: authGuard
  },
  {
    path: "mon-réseau/demandes-en-attente",
    name: "RequestedFr",
    component: Requested,
    beforeEnter: authGuard
  },
  {
    path: "my-network/pending-requests",
    name: "RequestedEn",
    component: Requested,
    beforeEnter: authGuard
  },
  {
    path: "mon-réseau/bloqués",
    name: "BlockedFr",
    component: Blocked,
    beforeEnter: authGuard
  },
  {
    path: "my-network/blocked",
    name: "BlockedEn",
    component: Blocked,
    beforeEnter: authGuard
  }
];
