// TODO:the diaspora list container tab when logged in as startup ==> not yet fixed
<template>
  <div
    id="diaspora"
    :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
  >
    <div v-if="!loading">
      <div class="container searchbar-container">
        <div class="full-width-col">
          <SearchbarStartups />
        </div>
      </div>
     
      <div class="container">
        <div class="main-container-section">
          <div class="sidebar-col">
            <Sidebar
              :countries="options.countries"
              :areas="options.areas"
              :types="options.types"
              :years="options.years"
            />
          </div>

          <div class="options-group" v-if="!isOnSelect">
            <a
              v-for="(filter, index) in Object.keys(options)"
              href="#"
              :key="index"
              class="filter-button"
              @click.prevent="showModal(filter)"
              >{{ filter }}</a
            >
            <div class="text-center clear-button-wrapper">
              <a
                class="filter-button clear-button"
                :href="`/${$i18n.locale}/diaspora`"
                v-if="isClearFilter"
                >{{ $t("clearfilter") }}</a
              >
            </div>
          </div>

          <div class="container-col">
            <Container />
          </div>
        </div>
      </div>
      <div
        v-if="getModalMode === true"
        class="modal-container"
        :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
      >
        <div class="modal-content">
          <p
            class="filter-description"
            :class="{ offsetVerified: authUser.is_verified === 0 }"
          >
            {{ selected.description }}
          </p>
          <ul
            class="filter-set"
            :class="{ offsetVerifiedFilterSet: authUser.is_verified === 0 }"
          >
            <li v-for="item in selected.contents" :key="item.name">
              <label class="checkbox-container">
                <span :for="item.name" class="filter-name">
                  <span
                    v-if="
                      item.name != 'Consulting' &&
                      item.name != 'Invest' &&
                      item.name != 'Mentoring'
                    "
                    >{{ item.name }}</span
                  >
                  <span v-if="item.name === 'Consulting'">{{
                    $t("Consultants")
                  }}</span>
                  <span v-if="item.name === 'Invest'">{{
                    $t("investors")
                  }}</span>
                  <span v-if="item.name === 'Mentoring'">{{
                    $t("Mentors")
                  }}</span> </span
                >&nbsp;({{ item.count }})
                <input
                  type="checkbox"
                  @click="addMobilefilter(item, selected.code)"
                />
                <span class="checkmark"></span>
              </label>
            </li>
          </ul>
          <a href="#" class="apply-button" @click.prevent="applyFilters">{{
            $t("apply")
          }}</a>
          <a
            href="#"
            class="close-button"
            :class="{ offsetVerifiedButton: authUser.is_verified === 0 }"
            @click.prevent="closeModal"
            >&times;</a
          >
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import SearchbarStartups from "../../components/Startups/Searchbar.vue";
import Sidebar from "../../components/Startups/Sidebar.vue";
import Container from "../../components/Startups/Container.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Community | Diaspora",
      meta: [
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  async created() {
    await this.loadEngagementFilter();
    this.options.types.contents = this.getEngagementFilter;
    await this.loadCountriesFilter({ lang: this.$i18n.locale });
    this.options.countries.contents = this.getCountriesFilter;
    await this.loadExpertisesFilter({ lang: this.$i18n.locale });
    this.options.areas.contents = this.getExpertisesFilter;
    this.allFilters = this.getSearchFilters;
    if (!JSON.parse(localStorage.getItem("diaspora"))) {
      await this.searchWithFilters({
        data: {
          user_id: this.authUser.id,
          page: this.getDiasporaPage,
          limit: this.getDiasporaLimit,
          filters: this.getSearchFilters,
        },
      });
      this.users = this.getDiaspora;
      this.$store.commit("setDiaspora", this.getDiaspora);
      this.$store.commit("setSavedDiaspora", this.getDiaspora);

      //save to locale storage
      localStorage.setItem("diaspora", JSON.stringify(this.getDiaspora));
      this.$store.commit("setShowPagination", true);
      localStorage.setItem("diasporaTotalCount", this.getDiasporaTotalCount);
    } else {
      this.users = JSON.parse(localStorage.getItem("diaspora"));
      this.diasporaTotalCount = JSON.parse(
        localStorage.getItem("diasporaTotalCount")
      );

      this.$store.commit("setDiaspora", this.users);
      this.$store.commit("setSavedDiaspora", this.users);
      this.$store.commit("setDiasporaTotalCount", this.diasporaTotalCount);
      this.$store.commit("setShowPagination", true);
      this.allFilters = this.getSearchFilters;
    }
    this.count = this.users.length;
    this.loading = false;


  },
  computed: {
    ...mapGetters([
      "authUser",
      "getDiaspora",
      "getEngagementFilter",
      "getCountriesFilter",
      "getExpertisesFilter",
      "getModalMode",
      "getDiasporaPage",
      "getDiasporaLimit",
      "getDiasporaTotalCount",
      "getSearchFilters",
      "getFilterCode",
    ]),
  },
  data() {
    return {
      isOnSelect: false,
      loading: true,
      isClearFilter: false,
      hideoOnSelect: false,
      allFilters: [],
      filters: [],
      options: {
        countries: {
          description: "Country of residence",
          contents: [],
          code: "countries",
        },
        areas: {
          description: "Area of expertise",
          contents: [],
          code: "areas",
        },
        types: {
          description: "Engagement Types",
          contents: [],
          code: "types",
        },
      },
      selected: [],
      users: {},
      count: 0,
      diasporaTotalCount: 0,
    };
  },
  methods: {
    ...mapActions([
      "loadDiaspora",
      "loadEngagementFilter",
      "loadCountriesFilter",
      "loadExpertisesFilter",
      "searchWithFilters",
    ]),
    async addMobilefilter(item, code) {
      this.$store.commit("setDiasporaPage", 1);
      if (this.allFilters[code].includes(item.value)) {
        this.filters = this.filters.filter((option) => {
          return option !== item.value;
        });
      } else {
        this.filters.push(item.value);
      }
      this.$store.commit("setSearchFilters", {
        field: code,
        filters: this.filters,
      });
    },
    async applyFilters() {
      this.$store.commit("setDiasporaPage", 1);
      await this.searchWithFilters({
        data: {
          user_id: this.authUser.id,
          page: this.getDiasporaPage,
          limit: this.getDiasporaLimit,
          filters: this.getSearchFilters,
        },
      });

      if (
        this.getDiaspora.length >= this.getDiasporaLimit &&
        this.getDiaspora.length < this.getDiasporaTotalCount
      ) {
        this.$store.commit("setShowLoadMore", true);
      } else if (
        this.getDiaspora.length === this.getDiasporaTotalCount ||
        this.getDiaspora.length === 0
      ) {
        this.$store.commit("setShowLoadMore", false);
      }

      this.$store.commit("setModalMode", false);
    },
    showModal: function (filter) {
      this.selected = this.options[filter];
      this.$store.commit("setModalMode", true);
      this.$store.commit("setFilterCode", filter);
      this.isOnSelect = true;
    },
    closeModal: function () {
      this.$store.commit("setModalMode", false);
      this.isOnSelect = false;
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Diaspora",
  components: {
    Container,
    SearchbarStartups,
    Sidebar,
  },
};
</script>


<style>
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue Bold.ttf");
  font-weight: 700;
}
* {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
}
:root {
  --border-color: #c8e3cf;
}
body {
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

#diaspora {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: #f0f4f2 !important;
}
.container {
  max-width: 1150px;
  margin: 0 auto;
}

.btn {
  cursor: pointer;
  border: none;
}
.full-width-col {
  box-sizing: border-box;
  padding: 0 0.75%;
  width: 100%;
}
@media (max-width: 1400px) {
  .container {
    max-width: 1000px;
  }
}
@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
}
@media (max-width: 991px) {
  .container {
    max-width: 650px;
    padding: 0 0 !important;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: 500px;
  }
}
@media (max-width: 574px) {
  .container {
    width: 90%;
    width: 100vw;
  }
  .main-container {
    padding: 0 2% !important;
    margin: 0 10px !important;
    padding-bottom: 80px !important;
  }
}
@media (min-width: 1200px) {
  #diaspora {
    /* max-width: 67%; */
    /* max-width: min(78%, 1140px) !important; */
    max-width: 990px;
    min-height: 100vh;
    width: 100%;
    /* background-color: red !important; */
  }
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .searchbar-container {
    position: fixed;
    width: 100%;
    z-index: 30;
    background: #f0f4f2;
    padding: 0.5rem 0;
    margin: 10px 0;
    /* max-width: 67% !important; */
    max-width: min(78%, 1140px) !important;
  }
  .full-width-col {
    padding: 0;
    width: 100%;
  }
  .sidebar-col {
    width: 16vw;
    padding: 0 0.5%;
    padding-left: 0;
  }
  .container-col {
    margin-left: 16vw;
    padding: 0%;
    /* padding-left: 0%; */
    /* background: #18792f; */
  }
}
</style>


<style scoped>
.searchbar-container {
  position: fixed;
  width: 100%;
  z-index: 30;
  background: #f0f4f2;
  padding: 1rem 0;
}
.main-container-section {
  padding-top: 7rem;
  overflow: auto;
}
.sidebar-col {
  position: fixed;
  width: 18vw;
}
.container-col {
  margin-left: 18vw;
  margin-bottom: 1rem;
}
.sidebar-col,
.container-col {
  box-sizing: border-box;
  padding: 0 0.5%;
}
.options-group {
  display: none;
  width: 100%;
  margin: 0 3% 1rem;
}
.filter-button {
  box-sizing: border-box;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  background: #1ba54b;
  color: #fff;
  margin-top: 0.35rem;
  font-size: 1rem;
  text-align: center;
  width: 45%;
}
.filter-button:first-letter {
  text-transform: uppercase;
}
.filter-button:hover {
  background: #18792f;
}
.apply-button {
  display: block;
  width: 40%;
  margin: 1.5rem auto;
  padding: 0.75rem;
  border-radius: 0.5rem;
  text-decoration: none;
  text-align: center;
  background: #1ba54b;
  color: #fff;
  font-weight: 700;
}
.apply-button:hover {
  background: #18792f;
}
.clear-button-wrapper {
  width: 100%;
}
.clear-button {
  border: 1px solid gray !important;
  color: gray !important;
}
.clear-button:hover {
  background: rgb(170, 162, 162);
  color: #f5f5f5;
}
.modal-container {
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  overflow-y: scroll !important;
  background-color: #fff;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  position: relative;
}
.filter-description {
  position: relative;
  top: 0;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
}
.filter-set {
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1.1rem;
}
.filter-set li {
  margin-top: 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #f0f4f2;
}
.close-button {
  display: block;
  position: absolute;
  right: 0.125rem;
  top: 0.125rem;
  width: 1.9rem;
  height: 1.9rem;
  text-align: center;
  padding: 0rem;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: #fff;
  border-radius: 200%;
  background: #1ba54b;
  z-index: 9999999999;
}
.close-button:hover {
  background: #18792f;
}
.showNotVerifiedNotfication {
  /* margin-top: 40px; */
}
@media (max-width: 1599px) {
  .sidebar-col {
    width: 20vw;
  }
  .container-col {
    margin-left: 20vw;
  }
}
@media (max-width: 1199px) {
  .sidebar-col {
    width: 25vw;
  }
  .container-col {
    margin-left: 25vw;
  }
}
@media (max-width: 991px) {
  .main-container-section {
    padding-top: 5rem;
  }
  .sidebar {
    overflow-y: unset;
    max-height: unset;
  }
  .sidebar-col {
    display: none;
  }
  .container-col {
    width: 100%;
    margin-left: 0 !important;
    padding-top: 60px;
  }
  .options-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    align-items: center;
    position: fixed;
    /* z-index: 9999; */
    z-index: 8999;
    width: 80vw;
    left: 0;
    background-color: #f0f4f2 !important;
    padding: 0px;
    margin-top: -10px;
    /* border: 1px solid #7070701c; */
    border-radius: 0px;
    width: calc(100vw - 2rem);
  }
  .checkbox-container {
    padding-left: 2rem;
  }
}
@media (min-width: 1200px) {
  .sidebar-col {
    width: 16vw;
    padding: 0 0.5%;
    padding-left: 0;
  }
  .container-col {
    margin-left: 16vw;
    padding: 0%;
    min-height: 77vh;
    /* padding-left: 0%; */
    /* background: #18792f; */
  }
  .main-container {
    overflow-y: scroll !important;
    padding-bottom: 120px !important;
    height: 100%;
  }
}
.showNotVerifiedNotfication {
  padding-top: 70px;
}
@media (max-width: 600px) {
  #diaspora {
    min-height: 100vh;
  }
  .close-button {
    display: block;
    position: fixed !important;
    right: 0.225rem;
    top: 0.5rem;
    /* width: 1.5rem;
  height: 1.5rem; */
    text-align: center;
    padding: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-decoration: none;
    color: #fff;
    background: crimson;
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    border-radius: 50%;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-button {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    padding: 0.5rem;
    padding: 0.3rem 0.25rem;
    border-radius: 1rem;
    text-decoration: none;
    background: #f5f5f5;
    color: #339f4d;
    margin-top: 0.35rem;
    font-size: 1rem;
    text-align: center;
    width: 32%;
    border: 1px solid var(--border-color);
  }
  .filter-button:hover {
    background: #18792f;
    color: white;
  }
  .options-group {
    /* display: none; */
    width: 100vw;
    background-color: #e7f1eb !important;
    /* background: linear-gradient(180deg, rgb(231, 241, 235) 0%, rgb(231, 241, 235) 100%); */
    margin-left: 0;
    position: fixed;
    top: 65px;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    margin-top: 0;
    padding: 5px 10px;
    /* margin: 0 3% 1rem; */
  }
  .filter-description {
    position: relative;
    /* top: -0.5px; */
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    background: rgb(255, 255, 255);
    background-color: #e7f1eb;
    color: #18792f;
    /* background: orange; */
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    /* border-bottom: 1px solid #00000024; */
    border: 1px solid var(--border-color);
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999000;
    /* align-items: center; */
    padding-top: 0;
    padding-left: 15px;
  }
  .searchbar-container {
    background-color: #e7f1eb !important;
    /* background: rgb(28,165,75);
background: linear-gradient(180deg, rgba(28,165,75,1) 0%, rgba(231,241,235,1) 100%); */
    z-index: 999 !important ;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 66px;
    padding: 0px 0 !important;
  }
  .full-width-col {
    box-sizing: border-box;
    padding: 0;
    width: 95%;
    height: 100%;
    display: flex;
    align-content: center;
    position: relative;
  }
  .modal-container {
    width: 100%;
    height: 100% !important;
    z-index: 99999;
    /* background-color: rgb(255, 0, 0); */
  }
  .filter-set {
    padding-left: 0;
    margin-top: 50px;
    font-size: 1.1rem;
    overflow: hidden;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 80px;
    background: #0000000f;
    background-color: white;
    scroll-behavior: smooth;
  }
  .apply-button {
    display: block;
    width: 78%;
    margin: 1.5rem auto;
    padding: 0.5rem;
    border-radius: 2.5rem;
    text-decoration: none;
    text-align: center;
    background: var(--first-color);
    color: #fff;
    font-size: 1.3rem;
    font-weight: 700;
    position: fixed;
    z-index: 999999;
    bottom: 46px;
    /* transform: translatex(0.5%); */
    transform: translatex(11vw);
  }
  .filter-set li {
    margin-top: 0.75rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #339f4d1c;
    width: 100%;
    padding-left: 1rem;
  }
  /* .filter-set li:first-child {
    border-top: 2px solid #339f4d1c;
} */
  .checkbox-container .checkmark {
    background-color: #1ba54b00;
    border: 2px solid #444343;
    border-radius: 8px;
  }
  .showNotVerifiedNotfication {
    padding-top: 60px;
  }
}
@media (max-width: 375px) {
  .showNotVerifiedNotfication {
    display: block;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    padding: 0;
    padding-bottom: 50px;
    width: 100%;
    height: 100%;
    overflow-y: scroll !important;
    max-width: 100vw;
    height: 100vh !important;
    margin-top: 60px;
    margin-top: -18px;
    z-index: 999;
    max-height: 100vh !important;
    padding: 0 !important;
  }
  .offsetVerified {
    top: 30px !important;
  }
  .offsetVerifiedButton {
    top: 40px !important;
  }
  .offsetVerifiedFilterSet {
    margin-top: 112px;
  }
}
</style>


<style>
.page-content {
    overflow: inherit !important;
}
</style>