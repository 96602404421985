
<template>
    <div>
        <div v-if="getStartupIDVault === false">
            <div class="d-flex align-items-center" v-if="getCurrStartupPitchdeckNotAvail === false">
                <div class="btn-labeled-verifys d-flex" @click="requestPitchDecksNotFound()">
                    <p class="label d-flex align-items-center">
                        <span>{{ $t(text) }}</span> <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                    </p>
                </div>
            </div>

            <div class="d-flex align-items-center" v-else>
                <div class="btn-labeled-verifys d-flex requested">
                    <p class="label d-flex align-items-center">
                        <span>{{ $t(text2) }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="d-flex align-items-center" v-if="getCurrStartup === false">
                <div class="btn-labeled-verify d-flex" @click="requestPitchDecks()">
                    <p class="label d-flex align-items-center">
                        <span>{{ $t(text) }}</span> <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                    </p>
                </div>
            </div>

            <div class="d-flex align-items-center" v-else>
                <div class="btn-labeled-verifys d-flex">
                    <p class="label d-flex align-items-center">
                        <span>{{ $t(text2) }}</span>
                    </p>
                </div>
            </div>
        </div>
        <PitchDeckNonExistantModal v-if="showModal" />
    </div>
</template>

<script>
import APIURLS from '../../../../../constant';
import i18n from '../../../../../i18n';
import Axios from "axios";
import PitchDeckNonExistantModal from '../../../../Modal/PDNonExistanModal.vue';
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        PitchDeckNonExistantModal
    },
    props: {
        startup: Object,
        text: {
            type: String,
            default: "requestPitchDeck",
        },

        text2: {
            type: String,
            default: "pitchDeckRequested"
        },
        text3: {
            type: String,
            default: "noPitchDeck"
        },
        text4: {
            type: String,
            default: "pitchDeckAvailable"
        },

       
    },

    data() {
        return {
            pitchData: {
                request_date: null,
                status: 1
            },
            showModal: false,
            loading: false,
            textFR: "Cette startup n'a pas encore téléchargé de pitch deck, nous allons le notifier que vous avez demandé pour son pitch deck.",
            textEN: "This startup has not uploaded a pitch deck yet, we will notify the startup you requested for their pitch deck"
        }
    },

    created() {
        this.$store.dispatch("currStartup");
        this.$store.dispatch("getVaultByStartupId");
        this.$store.dispatch("currStartupPitchdeckNotAvailable");

        // console.log("this.getCurrStartupPitchdeckNotAvail", this.getCurrStartupPitchdeckNotAvail);
        // console.log("this.getStartupIDVault", this.getStartupIDVault)
    },

    computed: {
        ...mapGetters([
            "authUser",
            "getCurrStartup",
            "getStartup",
            "getCurrStartupPitchdeckNotAvail",
            "getStartupIDVault",
            "getUserLanguage",
            "getUserLang",
            "getForeignCountriesList",
            "getUserTalents"]),
    },

    methods: {
        ...mapActions([
            "requestPitchDeck",
            "loadUserExpertises",
            "getForeignCountryByLang",
            "getAFricanCountryByLang",
            "getForeignCountryByLang",
            "loadCountriesList",
            "getSectorByLang",
        ]),

        async requestPitchDecks() {
        //    // alert('fire follow action');
        //     alert(this.startup.id)
            this.pitchData.request_date = new Date();

            let expertise;
            let location;

            let lang = this.getUserLang;
            let livesInCode = this.authUser.profile
                ? this.authUser.profile.residenceContry
                : "";

            location = await this.getForeignCountryByLang({
                code: livesInCode,
                lang,
            });

            await this.loadUserExpertises({
                lang,
                user_id: this.authUser.id,
            });

            let talents = this.getUserTalents;
            if (talents.length && talents[0].talents_name) {
                expertise = talents[0].talents_name;
            }

            this.pitchData.lang = lang;
            this.pitchData.name = this.authUser.firstname + " " + this.authUser.name;
            this.pitchData.avatar = this.authUser.avatar;
            this.pitchData.expertise = expertise;
            this.pitchData.location = location;
            this.pitchData.email = this.getStartup.user ? this.getStartup.user.email : '';
            this.pitchData.profile_link = APIURLS.SITELINK + `${this.$i18n.locale}/profile/${this.authUser.username}`;
            this.pitchData.startup_link = APIURLS.SITELINK + `${this.$i18n.locale}/startup/${this.getStartup.slug}`;
            //  console.log({ getCurrStartup: this.getStartup })
            this.loading = true;
            //alert('hey!')
            this.$store.dispatch("requestPitchDeck", this.pitchData)
                .then(async () => {
                    await this.follow();
                    this.$store.dispatch("currStartup");
                    this.$store.dispatch("getVaultByStartupId");
                    this.$store.dispatch("currStartupPitchdeckNotAvailable");
                    this.loading = false;
                });
        },

        async follow() {
            let sector, countryOfOrigin, livesIn, headquarters, sender_avatar;
            let lang = this.getUserLang;
            if (this.authUser.type === 1) {
                let originCode = this.authUser.profile
                    ? this.authUser.profile.birthCountry
                    : "";
                countryOfOrigin = await this.getAFricanCountryByLang({
                    code: originCode,
                    lang,
                });
                let livesInCode = this.authUser.profile
                    ? this.authUser.profile.residenceContry
                    : "";
                livesIn = await this.getForeignCountryByLang({
                    code: livesInCode,
                    lang,
                });
            } else {
                let code = this.authUser.startup ? this.authUser.startup.sector : "";
                sector = await this.getSectorByLang({ code, lang });
                let countryCode = this.authUser.startup
                    ? this.authUser.startup.location
                    : "";
                headquarters = await this.getAFricanCountryByLang({
                    code: countryCode,
                    lang,
                });
            }

            let name =
                this.authUser.type === 1
                    ? (this.authUser.firstname ? this.authUser.firstname : "") +
                    " " +
                    this.authUser.name
                    : this.authUser.startup.startupname;
            const data = {
                startup_id: this.startup.id,
                user_id: this.authUser.id,
                name,
                to: this.startup.user_id,
                sender_avatar: this.authUser.avatar,
                sender_id: this.authUser.id,
                lang,
                headquarters,
                sector,
                countryOfOrigin,
                livesIn,
            };

            Axios({
                method: "post",
                url: APIURLS.FOLLOWERS + "follow",
                headers: { "auth-token": this.authUser.token },
                data: data,
            })
            .catch((error) => {
                    console.log("error", error);
                    this.$noty.error(error.response.data.message);
                });
        },

        requestPitchDecksNotFound() {
            // console.log({ startup: this.startup })
            // alert();
            this.pitchData.startup_name = this.startup.startupname;
            this.pitchData.email = this.startup.user.email;
            this.pitchData.page_link = APIURLS.SITELINK + `${this.getUserLang}/startup/${this.startup.slug}`;
            this.pitchData.request_date = new Date();
            this.loading = true;
            this.$store.dispatch("requestPitchDeckNotAvailable", this.pitchData)
                .then(() => {
                    this.$store.dispatch("currStartup");
                    this.$store.dispatch("getVaultByStartupId");
                    this.$store.dispatch("currStartupPitchdeckNotAvailable");
                    this.loading = false;
                    this.showModal = true;
                    if (this.$i18n.locale === "en") {
                        this.modalText = this.textEN;
                    } else {
                        this.modalText = this.textFR;
                    }

                });
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-labeled-verify {
    position: relative;
    display: inline-block;
    color: #00853B;
    border: 1px solid #00853B;
    border-radius: 15px;
    padding: .5em;
    height: 32px;

    &:hover {
        background-color: #daf7e7;
        ;
        cursor: pointer;
    }

    .label {
        padding: 0 7.5px 0 7.5px;
        font-size: 16px !important;

        // color: #727272;
        @include max-width(415) {
            display: none;
        }
    }
}

.btn-labeled-verifys {
    position: relative;
    display: inline-block;
    color: #00853B;
    border: 1px solid #00853B;
    border-radius: 15px;
    padding: .5em;
    height: 32px;

    &:hover {
        background-color: #daf7e7;
        ;
        cursor: pointer;
    }
    
    .label {
        padding: 0 7.5px 0 7.5px;
        font-size: 16px !important;

        // color: #727272;
        @include max-width(415) {
            display: none;
        }
    }
}

.requested{
    color:black8 !important;
    border: 1px solid gray!important;
    cursor:default;
    background-color:rgba(237, 237, 237, 0.36);
}
</style>
