<template>
  <div>
    <div :class="`col-sm-${size} col-md-${size}`" class="py-4">
      <div :class="`alert-message alert-message-${type}`">
        <h4>
          {{ title }}
        </h4>
        <p class="text-center">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Alert",
  props: {
    size: Number,
    type: String,
    title: String,
    text: String,
    link: String,
  },
};
</script>
<style scoped>
.alert-message {
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.alert-message p {
  color: #000 !important;
  font-size: 15px;
}

.alert-message h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.alert-message p:last-child {
  margin-bottom: 0;
}
.alert-message code {
  background-color: #fff;
  border-radius: 3px;
}
.alert-message-success {
  background-color: #f4fdf0;
  border-color: #349e4dc4;
}
.alert-message-success h4 {
  color: #3c763d;
}
.alert-message-danger {
  background-color: #f4433623;
  border-color: #f34f4ab0;
}
.alert-message-danger h4 {
  color: #d9534f;
}
.alert-message-warning {
  background-color: #fcf8f2;
  border-color: #f0ad4e;
}
.alert-message-warning h4 {
  color: #f0ad4e;
}
.alert-message-info {
  background-color: #f4f8fa;
  border-color: #5bc0de;
}
.alert-message-info h4 {
  color: #5bc0de;
}
.alert-message-default {
  background-color: #eee;
  border-color: #b4b4b4;
}
.alert-message-default h4 {
  color: #000;
}
.alert-message-notice {
  background-color: #fcfcdd;
  border-color: #bdbd89;
}
.alert-message-notice h4 {
  color: #444;
}
.hide {
  display: none;
}

</style>
