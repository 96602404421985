<template>
  <div>
    <div
      class="blur-main-content"
      v-bind:class="{
        activeBubble: getIsBubbleActive,
        closed: getIsBubbleClosed,
      }"
    ></div>
    <div
      class="chat-bubble"
      v-bind:class="{
        activeBubble: getIsBubbleActive,
        closed: getIsBubbleClosed,
      }"
      ref="MessageBubble"
      v-if="getIsNewChatConvo"
    >
      <EmpyChatHeader />
      <BubbleMessages :chatMessages="chatData" v-if="!this.getIsNewChatConvo" />
      <div
        class="message-input"
        v-if="getCanSendMessages && !this.getIsNewChatConvo"
      >
        <form v-on:submit.prevent="commitSendMessage" class="message-form">
          <p class="input">
            <strong></strong>
            <textarea
              @keyup.enter="commitSendMessage"
              :placeholder="$t('Write a message...')"
              ref="myTextarea"
              v-model="message"
              :min-height="30"
              :max-height="350"
              class="textarea"
              @input="resize($event)"
              id="textarea"
            />
          </p>
          <button class="send-message" type="submit">
            <svg
              class="crt8y2ji"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path
                d="M16.6915026,12.4744748 L3.50612381,13.2599618 C3.19218622,13.2599618 3.03521743,13.4170592 3.03521743,13.5741566 L1.15159189,20.0151496 C0.8376543,20.8006365 0.99,21.89 1.77946707,22.52 C2.41,22.99 3.50612381,23.1 4.13399899,22.8429026 L21.714504,14.0454487 C22.6563168,13.5741566 23.1272231,12.6315722 22.9702544,11.6889879 C22.8132856,11.0605983 22.3423792,10.4322088 21.714504,10.118014 L4.13399899,1.16346272 C3.34915502,0.9 2.40734225,1.00636533 1.77946707,1.4776575 C0.994623095,2.10604706 0.8376543,3.0486314 1.15159189,3.99121575 L3.03521743,10.4322088 C3.03521743,10.5893061 3.34915502,10.7464035 3.50612381,10.7464035 L16.6915026,11.5318905 C16.6915026,11.5318905 17.1624089,11.5318905 17.1624089,12.0031827 C17.1624089,12.4744748 16.6915026,12.4744748 16.6915026,12.4744748 Z"
                fill-rule="evenodd"
                stroke="none"
              ></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import EmpyChatHeader from "./EmpyChatHeader";
import BubbleMessages from "./BubbleMessages";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      //  title: 'Default App Title',
      //  titleTemplate: '%s | vue-meta Example App',
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  name: "EmptyChatBubble",
  mounted() {},
  computed: {
    cssVars() {
      return {
        "--defaultPositionStyles": `${this.MessageBubbleHeight}`,
      };
    },
    ...mapGetters([
      "getIsBubbleClosed",
      "getIsBubbleActive",
      "getCurrentlyChattingWith",
      "getIsNewChatConvo",
      "getCanSendMessages",
    ]),
  },
  data: () => {
    return {
      message: "",
    };
  },
  props: {
    chatData: {
      type: Object,
      //required: true
    },
    avatar: String,
  },
  components: {
    EmpyChatHeader,
    BubbleMessages,
  },
  methods: {
    resize(e) {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
    handleChange($event) {
      console.log($event.target);
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
  },
};
</script>

<style scoped>
*:focus {
  outline: 0 !important;
}
.chat-bubble {
  position: fixed;
  z-index: 9000;
  right: calc(288px + 20px);
  bottom: -350px;
  /* bottom: calc(50px - var(--defaultPositionStyles));  */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: calc(100vh - 100px);
  width: 336px;
  background-color: white;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.8rem 0.8rem 0 0;
  transition-duration: 167ms;
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
  display: block;
}

.activeBubble {
  bottom: 0;
  display: block;
}
.closed {
  display: none;
}

.message-input {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 40px; */
  bottom: 0px;
  /* background: pink; */
  padding-bottom: 0px;
}
.message-form {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* border-top: 1px solid #ccc; */
  align-items: center;
  margin-top: 40px !important;
}
/* input[type='text'],
select {
  padding: 0px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
} */
.input,
.textarea {
  /* border: 1px solid #ccc; */
  font-family: inherit;
  font-size: inherit;
  max-height: 100px !important;
  padding: 6px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll !important;
}
p.input {
  width: 100%;
  background-color: white;
  text-align: left;
}

.textarea {
  display: block;
  width: 100%;
  resize: both;
  min-height: 40px;
  line-height: 20px;
  border: none !important;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.42857;
  border-radius: 8px;
  padding-right: 45px;
  box-sizing: border-box;
}

textarea:focus {
  outline: none !important;
  border: 1px solid rgba(15, 127, 18, 0.5) !important;
}
.textarea::active .message-form {
  border-top: 1px solid blue;
}
/* .textarea[contenteditable]:empty::before {
  content: "write a message";
  color: rgb(254, 0, 0);
} */
button.send-message {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 45px;
  border-radius: 35px;
  max-height: 35px;
  /* padding: 10px 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent !important;
  right: 10px;
  top: 20px;
}
button.send-message svg {
  width: 20px;
  fill: #c4c8cb;
  fill: #3d8f38;
}
textarea:focus + button.send-message svg {
  fill: pink !important;
}

.testing {
  position: absolute;
  top: -50%;
}
@media (max-width: 650px) {
  .chat-bubble {
    width: 100vw !important;
    height: 100vh;
    bottom: 0px;
    left: 0px !important;
    max-height: calc(100vh - 0px);
    z-index: 100000;
  }
  /* .activeBubble {
  bottom: 0px;
}  */

  .message-input {
    bottom: 75px;
  }
  .textarea {
    border: none;
    line-height: 1.2;
    border-radius: 15px;
    padding-right: 45px;
    box-sizing: border-box;
  }
  button.send-message {
    position: absolute;
    right: 0px;
    height: 100%;
    width: 35px;
    border-radius: 35px;
    max-height: 35px;
    right: 10px;
    top: 25px;
  }
  button.send-message svg {
    width: 16px;
  }
}
.blur-main-content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* background-color: #3b90370e; */
  /* filter: blur(10px); */
  backdrop-filter: saturate(0%) blur(1.5px);
}
</style>