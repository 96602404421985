<template>
  <div>
    <div v-if="!loading">
      <PageCover
        :avatar="authUser.avatar"
        :is_startup="true"
        :showEdit="true"
        :startup="startup"
        :followersCount="followersCount"
        :interrestedCount="interrestedCount"
      />
      <div class="">
        <div class="container">
          <div class="row">
            <div
              class="
                col col-xl-12 col-lg-12
                order-lg-2
                col-md-12 col-sm-12 col-12
              "
              v-if="startup.video"
            >
              <IntroVideo :link="startup.video" />
            </div>
            <main
              class="
                col col-xl-8
                order-xl-2
                col-lg-12
                order-lg-2
                col-md-12 col-sm-12 col-12
              "
            >
              <div id="description">
                <About
                  :text="startup.description"
                  :is_html="true"
                  name="description"
                />
              </div>
              <div id="product">
                <About :text="startup.product" :is_html="true" name="product" />
              </div>
              <div id="team">
                <TeamList :members="members" />
              </div>
            </main>
            <aside class="col col-xl-4 order-xl-4 col-lg-12 order-lg-4 col-12">
              <div id="overview">
                <Overview :startup="startup" />
              </div>
              <widget
                v-if="followers.length"
                title="followers"
                :users="followers.slice(0, 5)"
                :link="`/${$i18n.locale}/${this.$i18n.t('routes.followers')}`"
                linkText="allFollowers"
              />
              <PeopleSupportStartups :users="users" />
              <TalentsNeeded :talents="talents" v-if="talents.length" />
            </aside>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import i18n from "../../../i18n";
import moment from "moment";
import Axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Bantaba | My Page ",
    };
  },
  watch: {
    async $route() {
      const startup_id = this.startup.id;
      const lang = i18n.locale;
      await this.loadStartupNeeds({ startup_id, lang });
      this.talents = this.getStartupNeededTalents;
    },
  },
  async created() {
    var sortBy = require("lodash").sortBy;
    await this.loadStartup();
    this.startup = this.getStartup;
    const startup_id = this.startup.id;
    var countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    if (this.startup) {
      this.startup.location = countries.getName(
        this.startup.location,
        i18n.locale,
        {
          select: "official",
        }
      );
    }
    if (this.startup) {
      this.startup.foundingYear = moment(this.startup.foundingYear).format(
        "YYYY"
      );
    }
    await this.loadUsersSupportStartups();

    this.users = this.getUsersSupportStartups;
    // await this.loadFollowers(startup_id);
    const user_id = this.getStartup.user_id;
    await this.loadStartupNeeds({ startup_id });
    // await this.loadFollowersCount(startup_id);
    this.followersCount = this.startup.followers.length;
    await this.loadInterrestedCount(startup_id);
    this.interrestedCount = this.getInterrestedCounts;
   
    this.talents = this.getStartupNeededTalents;
    this.followers = this.getFollowers;
    await this.loadStartupTeam({ user_id });
    this.members = this.getStartupTeam;
    this.loading = false;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserProfile",
      "getStartup",
      "getUsersSupportStartups",
      "getStartupBySlug",
      "getStartupNeededTalents",
      "getStartupTeam",
      "getFollowers",
      "getFollowersCount",
      "getInterrestedCounts",
    ]),
  },
  methods: {
    ...mapActions([
      "loadProfile",
      "loadStartup",
      "loadConnections",
      "loadConnCounts",
      "loadUsersSupportStartups",
      "loadStartupBySlug",
      "loadStartupNeeds",
      "loadStartupTeam",
      "loadFollowers",
      "loadFollowersCount",
      "loadInterrestedCount",
    ]),
  },
  data() {
    return {
      startup: {},
      followers: {},
      users: {},
      sector: "",
      talents: {},
      members: {},
      about: "",
      iscurrentUser: false,
      connectionsCount: 0,
      loading: true,
      followersCount: 0,
      interrestedCount: 0,
    };
  },
  name: "ViewProfile",
};
</script>
