<template>
  <div class="border  shadow-md rounded bg-white mb-3">
    <div class="box-title border-bottom p-3 text-center">
      <h6 class="m-0">{{ $t('VIDEO') }}</h6>
    </div>
    <div class="box-body p-3">
      <form @submit.prevent="updateVideo">
        <div class="row">
          <div class="col-sm-12 mb-2" v-if="link">
            <iframe width="100%" height="400" :src="link"> </iframe>
          </div>
          <div class="col-sm-12 mb-2">
            <div class="js-form-message">
              <label class="form-label">
                {{ $t('videoLink') }}
              </label>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="organization"
                  v-model="video"
                  :placeholder="$t('link')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-right">
          <div class="overflow-hidden text-center p-3">
            <button
              :disabled="btnLoading"
              type="submit"
              class="font-weight-bold btn btn-light rounded p-3 btn-block"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="btnLoading"></i>
              {{ btnLoading ? '' : $t('save') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.video = this.link;
  },
  data() {
    return {
      video: null,
      loading: false,
      btnLoading: false,
    };
  },
  methods: {
    updateVideo() {
      this.btnLoading = true;
      this.$store
        .dispatch('updateStartup', { video: this.video })
        .then((success) => {
          this.$noty.success('Video Updated Successfully !');
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          this.$noty.error(error);
        });
    },
  },
  name: 'UpdateVideo',
  props: {
    link: String,
  },
};
</script>
