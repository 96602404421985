<template>
  <div>
    <div class="error_msg" v-if="hasError">{{ $t(error_msg) }}</div>
    <div class="accountTypeContainer">
      <AccountCard
        :class="hasError ? 'error' : ''"
        label="Diaspora"
        :profileImg="diasporaImg"
      />
      <AccountCard
        :class="hasError ? 'error' : ''"
        label="Startup"
        :profileImg="startupImg"
      />
    </div>
  </div>
</template>
<script>
import AccountCard from "./AccountCard";

import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  data: () => {
    return {
      diasporaImg: require("../../assets/registration/diaspora.svg"),
      startupImg: require("../../assets/registration/startup.svg"),
    };
  },

  methods: {},
  computed: {
    ...mapGetters(["getFomData", "getAccountType"]),
  },
  name: "AccountType",
  props: {
    error_msg: {
      type: String,
      default: " ",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountCard,
  },
};
</script>
<style scoped>
.accountTypeContainer {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.error {
  border: 1.5px solid var(--error-color) !important;
}

.error_msg {
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 100%;
  color: var(--error-color) !important;
}

.reset {
  background-color: white;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .accountTypeContainer {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1200px) {
  .accountTypeContainer {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>