<template>
  <div class="final-step">
    <div class="container">
      <P>hello</P>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "FinalStep",
  components: {},
  computed: {
    ...mapGetters(["getAccountType"]),
  },
};
</script>
<style scoped>
*{
  overflow: hidden;
}
 .final-step {
  position: absolute;
  z-index: 2000;
 width: 100vw !important;
  height: 100vh !important;
  background-color: rgb(255, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}
/*
.container {
  position: relative !important;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.balloons-bg {
  position: absolute;
  left: -230px;
  top: 50px;
  display: flex;
  justify-content: center;
  width: 1524px;
  height: 800px;
  z-index: 999;
}
.main-content {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.illustation {
  height: 65vh;
  margin-top: -120px;
}

.welcome-messages {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
}
.first-text {
  margin-bottom: -10px;
  margin-top: -100px;
  z-index: 3000;
  font-size: 26px;
  letter-spacing: 0.5px;
  font-weight: lighter;
}
.bantaba-text {
  margin: 0;
  font-weight: bold;
  font-size: 150px !important;
  z-index: 3000;
}
.paragraph {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 3000;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: lighter;
}
.welcome-messages::before {
  content: "";
  background: url("../../../../assets/registration/fade.svg");
  position: absolute;
  width: 900px;
  height: 400px;
  z-index: 1000;
  top: -400px;
  left: 0px;
} */
/* @media only screen and (max-width: 600px) {
  .final-step {
    position: absolute;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    margin-top: -50px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
   
  }
  .illustation {
    height: auto;
    width: 120vw;
    margin-top: -20px;
  }

  .welcome-messages {
    padding: 10px 0;
    padding-top: 30px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .welcome-messages::before {
    top: -300px;
  }
  .bantaba-text {
    font-size: 15vw !important;
    padding: 10px 0;
    padding: 20px 0;
    letter-spacing: 8px;
  }
  .first-text {
    font-size: 20px;
  }
  .paragraph {
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100vw;
    padding: 10px 30px;
    font-size: 22px;
    letter-spacing: -0.5px;
  }
} */

/* 
@media only screen and (min-width: 600px) {
  .final-step {
    position: absolute;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    margin-top: -50px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .illustation {
    height: auto;
    width: 120vw;
    margin-top: -20px;
  }
  .welcome-messages {
    padding: 10px 0;
    padding-top: 30px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .welcome-messages::before {
    top: -300px;
  }
  .bantaba-text {
    font-size: 15vw !important;
    padding: 10px 0;
    padding: 20px 0;
    letter-spacing: 8px;
  }
  .first-text {
    font-size: 20px;
  }
  .paragraph {
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100vw;
    padding: 0px 20px;
    font-size: 18px;
    letter-spacing: -0.5px;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .final-step {
    position: absolute;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    margin-top: -50px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
} */
  /* .container{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
} */
  /* .bg{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
} */
  /* .balloons-bg{
    position: absolute;
    left: -230px;
    top: 50px;
    display: flex;
    justify-content: center;
    width: 1524px;
    height: 800px;
    z-index: 999;
} */
  /* .main-content{
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
} */
  /* .illustation {
    height: 65vh;
    margin-top: -120px;
  }

  .welcome-messages {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2000;
  }
  .first-text {
    margin-bottom: -10px;
    margin-top: -100px;
    z-index: 3000;
    font-size: 26px;
    letter-spacing: 0.5px;
    font-weight: lighter;
  }
  .bantaba-text {
    margin: 0;
    font-weight: bold;
    font-size: 150px !important;
    z-index: 3000;
  } */
  /* .paragraph {
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 3000;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: lighter;
  } */
  /* .paragraph span {
    font: 32px !important;
} */
  /* .welcome-messages::before {
    content: "";
    background: url("../../../../assets/registration/fade.svg");
    position: absolute;
    width: 900px;
    height: 400px;
    z-index: 1000;
    top: -400px;
    left: calc(50% - 450px);
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>