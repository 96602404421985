<template>
  <div class="py-4">
    <div class="container-fluid px-md-5">
      <div class="row" v-if="!loading">
        <main
          class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          "
        >
          <StartupAside :mentorsCount="count" />
          <div
            class="box shadow-sm border rounded bg-white mb-3 osahan-share-post"
          >
            <Connections
              :users="users"
              type="investors"
              emptyMessage="NoMentors"
              :showSearch="false"
            />
          </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Community | Mentors",
    };
  },
  async created() {
    if (!JSON.parse(localStorage.getItem("mentors"))) {
      await this.loadMentors(this.authUser.id);
      this.users = this.getMentors;
      //save to locale storage
      localStorage.setItem("mentors", JSON.stringify(this.getMentors));
    } else {
      this.users = JSON.parse(localStorage.getItem("mentors"));
    }
    this.count = this.users.length;
    this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getMentors"]),
  },

  methods: {
    ...mapActions(["loadMentors"]),
  },
  data() {
    return {
      loading: true,
      users: {},
      count: 0,
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Mentors",
};
</script>

<style></style>
