<template>
  <div v-if="getIsBlockModal" class="report-a-person-view">
    <div class="modal" v-if="!getHasReported">
      <div class="modal-header">
        <div class="modal-header-title-container">
          <h1 class="header-title">
            {{ $t("reportTitle") }}
          </h1>
          <p class="header-title-secondary">{{ $t("reportWontNotify") }}</p>
        </div>
        <button class="close-modal" @click="closeBlockModal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-supported-dps="24x24"
            fill="currentColor"
            class="mercado-match"
            width="24"
            height="24"
            focusable="false"
          >
            <path
              d="M13.42 12L20 18.58 18.58 20 12 13.42 5.42 20 4 18.58 10.58 12 4 5.42 5.42 4 12 10.58 18.58 4 20 5.42z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <p class="modal-action" @click="reportUserCommit('scam')">
          {{ $t("reportScam")
          }}<span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              class="mercado-match"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
            >
              <path
                d="M11.45 3L15 8l-3.55 5H9l2.84-4H2V7h9.84L9 3z"
              ></path></svg
          ></span>
        </p>
        <p class="modal-action" @click="reportUserCommit('spam')">
          {{ $t("reportSpam") }}
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              class="mercado-match"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
            >
              <path
                d="M11.45 3L15 8l-3.55 5H9l2.84-4H2V7h9.84L9 3z"
              ></path></svg
          ></span>
        </p>
        <p
          class="modal-action"
          @click="reportUserCommit('inappropriate behavior')"
        >
          {{ $t("reportInappropriate")
          }}<span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              class="mercado-match"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
            >
              <path
                d="M11.45 3L15 8l-3.55 5H9l2.84-4H2V7h9.84L9 3z"
              ></path></svg
          ></span>
        </p>
        <p class="modal-action" @click="reportUserCommit('harassment')">
          {{ $t("reportHarassment")
          }}<span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              class="mercado-match"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
            >
              <path
                d="M11.45 3L15 8l-3.55 5H9l2.84-4H2V7h9.84L9 3z"
              ></path></svg
          ></span>
        </p>
      </div>
    </div>
    <div class="sucessful-modal" v-if="getHasReported">
      <!-- <div class="sucessful-modal"> -->
      <div class="icon">
        <svg
          id="Capa_1"
          enable-background="new 0 0 512.004 512.004"
          height="512"
          viewBox="0 0 512.004 512.004"
          width="512px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="256.002"
            x2="256.002"
            y1="512.004"
            y2="0"
          >
            <stop offset="0" stop-color="#0F801250" />
            <stop offset="1" stop-color="#0F8012" />
          </linearGradient>
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="255.987"
            x2="255.987"
            y1="512.004"
            y2="212.004"
          >
            <stop offset="0" stop-color="#FED439" />
            <stop offset="1" stop-color="#FED439" />
          </linearGradient>
          <g>
            <g>
              <g>
                <path
                  d="m417.618 134.264c-4.014-4.907-10.605-6.841-16.67-4.658-5.977 2.124-9.961 7.793-9.961 14.136v8.262c0 16.538-13.447 30-30 30s-30-13.462-30-30v-32.806c0-81.138-65.303-116.06-68.086-117.495-4.336-2.256-9.492-2.285-13.828 0-2.783 1.436-68.086 36.328-68.086 117.334v32.967c0 16.538-13.447 30-30 30s-30-13.462-30-30v-8.262c0-6.343-3.984-12.012-9.961-14.136-6.123-2.183-12.686-.249-16.67 4.658-40.605 49.893-62.988 110.391-62.988 170.376 0 103.213 69.463 207.363 224.619 207.363 155.186 0 224.648-104.15 224.648-207.378.001-59.941-22.382-120.439-63.017-170.361z"
                  fill="url(#SVGID_1_)"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="m255.987 212.004c-82.8 0-150 67.2-150 150s67.2 150 150 150 150-67.2 150-150-67.199-150-150-150zm-75 105c0-8.401 6.599-15 15-15s15 6.599 15 15c0 8.399 6.599 15 15 15s15 6.599 15 15c0 8.399-6.599 15-15 15-24.902 0-45-20.101-45-45zm105 135c-8.401 0-15-6.601-15-15 0-8.401-6.599-15-15-15s-15 6.599-15 15c0 8.399-6.599 15-15 15s-15-6.601-15-15c0-24.9 20.098-45 45-45s45 20.1 45 45c0 8.399-6.599 15-15 15zm0-90c-8.401 0-15-6.601-15-15 0-8.401 6.599-15 15-15s15-6.601 15-15c0-8.401 6.599-15 15-15s15 6.599 15 15c0 24.899-20.097 45-45 45z"
                  fill="url(#SVGID_2_)"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <h1 class="success-title">{{ $t("reportSuccessful") }}</h1>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "ReportAPersonView",
  computed: {
    ...mapGetters([
      "getCurrentlyChattingWith",
      "authUser",
      "getMyName",
      "getHasReported",
      "getIsBlockModal",
    ]),
  },
  methods: {
    ...mapActions(["reportUser"]),
    async reportUserCommit(reportType) {
      let reporting_name =
        this.getCurrentlyChattingWith.firstname +
        " " +
        this.getCurrentlyChattingWith.name;
      if (this.getCurrentlyChattingWith.type === 2) {
        reporting_name = this.getCurrentlyChattingWith.startup.startupname;
      }

      let from_name = this.authUser.firstname + " " + this.authUser.name;

      if (this.authUser.type === 2) {
        from_name = this.authUser.startup.startupname;
      }
      let report = {
        from_id: this.authUser.id,
        from_name,
        reporting_id: this.getCurrentlyChattingWith.id,
        reporting_name,
        reporting_for: reportType,
      };
      await this.reportUser({ report });
      // this.$store.commit("reportUser", report);
    },
    ...mapMutations(["openBlockModal", "closeBlockModal"]),
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.report-a-person-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: saturate(100%) blur(1px);
  display: flex;
  /* padding-top: 60px; */
  justify-content: center;
  align-items: center;
}
.sucessful-modal {
  width: 700px;
  height: 50vh;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.sucessful-modal div.icon {
  height: 70%;
  width: 100%;
  padding: 20px 0;
  background-color: #fed439;
  background-image: linear-gradient(to bottom, #fed439, #fbe596);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 40px; */
  border-bottom: 2px solid rgba(0, 0, 0, 0.061);
}
.sucessful-modal svg {
  width: 100%;
  height: 100%;
}
.sucessful-modal h1 {
  font-family: inherit;
  padding: 25px 10px;
  font-size: 25px;
}
.modal {
  background-color: white;
  width: 700px;
  max-height: 50vh;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* display: none !important; */
}
.modal-header {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  /* background-color: #fed439; */
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* background-image: linear-gradient(to bottom, #fed439, #fbe596); */
  background-color: var(--first-color) !important;
}
.modal-header-title-container {
  text-align: left;
}
.header-title {
  font-size: 18px;
  /* color: rgba(0, 0, 0, 0.9); */
  font-weight: 800;
  line-height: 25px;
  padding-bottom: 10px;
  color: #fff !important;
}
.header-title-secondary {
  font-size: 18px;
  margin-top: 4px;
  /* color: rgba(0, 0, 0, 0.6); */
  color: #fff !important;
}
.close-modal {
  border: none;
  background: white;
  padding: 5px;
  border-radius: 100px;
  border: none;
}
.close-modal:hover {
  background: rgba(253, 167, 167, 1);
  border: 1px solid white;
}
.close-modal svg {
  width: 25px;
  height: 25px;
}
.close-modal:focus {
  outline: none;
}
.modal-body {
  width: 100%;
}
.modal-action {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #191919da;
  line-height: 1.42857;
  font-weight: 400;
  padding: 15px 20px;
}
.modal-action svg {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.modal-action:hover {
  cursor: pointer;
  background-color: #ebebeb9f;
  color: #349e4d;
}
* {
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 650px) {
  .modal {
    background-color: white;
    width: 99vw;
    max-height: 90vh;
    border-radius: 10px;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .sucessful-modal {
    width: 100vw;
    height: 90vh;
  }
  .sucessful-modal div.icon {
    height: 70%;
    width: 100%;
    padding: 20px 0;
    background-color: #fed439;
    background-image: linear-gradient(to bottom, #fed439, #fbe596);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 40px; */
    border-bottom: 2px solid rgba(0, 0, 0, 0.061);
  }
  .sucessful-modal svg {
    width: 80%;
    height: 80%;
  }
  /* .sucessful-modal h1 {
  padding: 25px 15px;
  font-size: 25px;
} */
}

.header-title {
  font-size: 22px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  line-height: 28px;
  padding-top: 8px;
  padding-bottom: 10px;
  font-family: sans-serif;
  font-weight: 500;
  text-align: left;
  padding-top: 30px;
}
</style>