<template>
  <div class="StepOne">
    <LeftSide />
    <RightSide />
    <LanguageSwitcherRegister />
  </div>
</template>
<script>
import RightSide from "./RightSide";
import LeftSide from "./LeftSide";
import LanguageSwitcherRegister from "../../../components/LanguageSwitcherRegister.vue";
export default {
  name: "StepOne",
  components: {
    RightSide,
    LeftSide,
    LanguageSwitcherRegister,
  },
};
</script>
<style scoped>
.StepOne {
  background-color: var(--bg-color);
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  margin-top: 0px;
  margin-left: 0;
  overflow: hidden;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .StepOne {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>