<template>
  <transition v-if="isShow" name="modal">
    <div class="modal-mask error">
      <div class="modal-wrapper" @click.self="hide">
        <div class="modal-container modal-container-small">
          <div class="modal-header mb-1">
            <!-- <slot name="header"> </slot> -->
            <div class="header-element">
              <div class="img-error-mobile">
                <img
                  alt=""
                  src="./../../assets/images/partnerships/smile-error.png"
                />
              </div>
              <div class="img-error-red">
                <img
                  alt=""
                  src="./../../assets/images/partnerships/smoll-error.jpg"
                />
              </div>
              <p class="header-text">
                <b class="text-title-error">{{
                  $t("Oops, your startup profile is incomplete.")
                }}</b>
                <span
                  ><b class="text-mobile">{{
                    $t("Oops, your startup profile is incomplete.")
                  }}</b></span
                >
              </p>
            </div>
            <div class="btn-modal-header">
              <button class="modal-default-button" @click="close">
                <img
                  alt=""
                  src="./../../assets/images/partnerships/modal-close.png"
                />
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="modal-body-error-text">
              <div class="please">
                {{ $t("Please add the following details to continue:") }}
              </div>
              <ul>
                <li class="line" v-if="getApplyResults.Logo != 'Yes'">
                  <img
                    class="cross"
                    alt=""
                    src="./../../assets/images/partnerships/cross.svg"
                  />
                  {{ $t("Upload your Logo") }}
                </li>
                <li class="line" v-if="getApplyResults.Website != 'Yes'">
                  <img
                    class="cross"
                    alt=""
                    src="./../../assets/images/partnerships/cross.svg"
                  />
                  {{ $t("Provide your Website") }}
                </li>
                <li class="line" v-if="getApplyResults.Stage != 'Yes'">
                  <img
                    class="cross"
                    alt=""
                    src="./../../assets/images/partnerships/cross.svg"
                  />
                  {{ $t("Fill in your Stage") }}
                </li>

                <li class="line" v-if="getApplyResults.Description != 'Yes'">
                  <img
                    class="cross"
                    alt=""
                    src="./../../assets/images/partnerships/cross.svg"
                  />
                  {{ $t("Fill in your startup Description") }}
                </li>

                <li class="line" v-if="getApplyResults.Teams != 'Yes'">
                  <img
                    class="cross"
                    alt=""
                    src="./../../assets/images/partnerships/cross.svg"
                  />
                  {{ $t("Provide At least one Team Member") }}
                </li>
              </ul>
            </div>
            <!--            <slot name="body"></slot>-->
          </div>
          <div class="modal-footer-partnership">
            <div class="modal-footer-error-btn">
              <button class="partnership-modal-error-btn" @click="gotToProfile">
                <b>{{ $t("Edit profile") }}</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import modal from "@/mixins/modal";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["authUser", "getApplyResults", "getUserLang"]),
  },
  name: "ModalError",
  mixins: [modal],
  methods: {
    ...mapActions(["checkStartupCompleted", "getStartupByUserId"]),
    async gotToProfile() {
      let startup = await this.getStartupByUserId(this.authUser.id);
      window.location.href = `/${this.getUserLang}/startup/${startup.slug}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modal";
.cross {
  width: 15px;
}
.line {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgba(117, 117, 117, 0.247);
  background-color: rgba(218, 218, 218, 0.26);
  margin-top: 10px;
  margin-bottom: 10px;
}
.error {
  .header-element {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-modal-header button svg {
    width: 100%;
    height: 100%;
    position: center;
    border-radius: 50%;
  }

  .btn-modal-header {
    display: flex;
    align-items: center;
  }

  .partnership-modal-error-btn {
    padding: 5px 28px;
    max-width: 208px;
    display: block;
    border-radius: 6px;
    margin: 10px auto 10px;
    background-color: var(--first-color);
    color: #fff !important;
    border: 1px solid #000;
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  }

  .partnership-modal-error-btn:hover {
    background-color: var(--btn-bg-color);
    color: #fff !important;
    border: 1px solid #fff;
  }

  .modal-footer-error-btn {
    display: flex;
  }

  .text-title-error {
    color: #e31c39;
    font-size: 23px;
  }

  .header-text {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .img-size-bg {
    border-color: #fcc9cc;
  }

  .img-error-red {
    display: none;
  }

  .img-error-mobile img {
    margin-right: 20px;
    position: relative;
    border-radius: 50%;
  }

  .header-text .text-mobile {
    display: none;
  }

  .modal-header {
    background: #fce3e7;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    column-gap: 20px;
  }

  .modal-body {
    padding-bottom: 0;
  }

  .modal-body-error-text {
    text-align: start;
    margin-bottom: 60px;
    font-size: 20px;
  }

  .modal-footer-partnership {
    background-color: #e3f2e9;
  }

  .modal-footer-partnership {
    display: flex;
    justify-content: center;
    border-radius: 0 0 10px 10px;
  }

  .text-center {
    text-align: center !important;
  }

  .btn-action:hover {
    background-color: var(--first-color);
    color: #fff !important;
  }

  .btn-action {
    border: 1px solid var(--first-color);
    border-radius: 15px !important;
    color: #707070;
  }

  .modal-mask {
    position: fixed;
    z-index: 10002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container.modal-container-small {
    max-width: 690px;
  }

  .modal-container {
    width: 80%;
    height: auto !important;
    max-height: 500px;
    border-bottom: 0 solid !important;
    overflow-y: scroll !important;
    margin: 0 auto;
    padding: 0 !important;
    background-color: rgb(255, 255, 255);
    border-radius: 15px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-default-button {
    border: none !important;
    position: relative;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 1500px !important;
    background: #ececec !important;
    cursor: pointer !important;
    right: 0;
    float: right;
    font-size: 10px;
    z-index: 10000;
  }

  .img-error-red {
    display: none;
    border-radius: 50%;
  }

  .modal-default-button img {
    max-width: 100%;
    max-height: 100%;
  }

  .modal-default-button:hover {
    background: #cecccc !important;
  }

  .btn-icon .svg {
    fill: #707070 !important;
    color: #707070 !important;
  }

  @media screen and (min-width: 375px) and (max-width: 800px) {
    .modal-container {
      width: 380px;
      height: 327px !important;
      max-height: 1000px !important;
      border-bottom: 30px solid #fff;
      overflow: scroll !important;
    }
    .header-text .text-mobile {
      display: block;
      color: #ffffff;
    }
    .modal-header {
      background: #e31c39;
      color: #ffffff;
    }
    .text-title-error {
      font-size: 16px;
      display: none;
    }
    .img-error-mobile {
      display: none;
    }
    .img-error-red img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .img-error-red {
      display: block;
      border-radius: 50%;
    }

    .modal-container.modal-container-small {
      max-width: 690px;
      width: calc(100% - 40px);
      height: auto !important;
      margin: 0 auto;
    }

    .modal-body-text-code span {
      display: none;
    }
    .modal-body-error-text {
      font-size: 16px;
    }
  }

  .partnership-modal-error-btn {
    font-size: 18px;
  }

  .partnership-modal-error-btn:hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.16));
  }

  @media (min-width: 1200px) {
    .img-size-bg img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 600px) {
    .line,
    .please {
      font-size: 14px;
    }
    .header-text .text-mobile {
      display: block;
      color: #ffffff;
    }
    .img-error-mobile {
      display: none;
    }
    .text-title-error {
      font-size: 16px;
      display: none;
    }
    .modal-header {
      background: #e31c39;
    }
  }
  @media screen and (max-width: 375px) {
    .modal-container {
      width: 300px;
      height: 400px !important;
      max-height: 500px !important;
      overflow: scroll !important;
    }
    .img-error-red {
      display: block;
      border-radius: 50%;
    }

    .modal-container.modal-container-small {
      width: calc(100% - 30px);
      height: auto !important;
    }
  }

  .modal-header h3 {
    margin-top: 0;
    text-align: center !important;
    color: #42b983;
  }

  .modal-default-button {
    border: none !important;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    background: rgb(148, 148, 148);
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .img-error-red img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }
}
</style>
