import axios from 'axios';
import i18n from '../../i18n';
import Vue from 'vue';
import APIURLS from '../../constant';

const state = {
  page: 1,
  count: 0,
  isLoadingMessage: false,
  loadMoreClicked: false,
  isBlockModal: false,
  hasReported: false,
  loadingMoreChats: false,
  hasMoreMessages: false,
  allMessagesCount: 0,
  chatPagination: 100,
  chatMessagesCount: 0,
  isOnline: false,
  otherUserIsOnline: false,
  isShowActionModal: false,
  search: null,
  chatServerStatus: false,
  isActive: true,
  isBubbleActive: false,
  isBubbleClosed: true,
  canSendMessages: true,
  typing: false,
  typingUser: {},
  blockedUser: {},
  isBlocked: false,
  isChatListClick: false,
  chatListHeight: 0,
  chatHeaderHeight: 0,
  currentlyChattingWith: {},
  totalNumberOfUnreadMessages: 0,
  isNewChatConvo: false,
  onlineUsers: {},
  components: [],
  chatList: {},
  messages: {},
};
const getters = {
  getChatServerStatus: (state) => {
    return state.chatServerStatus;
  },
  getPage: (state) => {
    return state.page;
  },
  getCount: (state) => {
    return state.count;
  },
  getIsLoadingMessage: (state) => {
    return state.isLoadingMessage;
  },
  getLoadMoreClicked: (state) => {
    return state.loadMoreClicked;
  },

  getAllMessagesCount: (state) => {
    return state.allMessagesCount;
  },
  getChatMessagesCount: (state) => {
    return state.chatMessagesCount;
  },
  getChatPagination: (state) => {
    return state.chatPagination;
  },
  getHasMoreMessages: (state) => {
    return state.hasMoreMessages;
  },
  getLoadingMoreChats: (state) => {
    return state.loadingMoreChats;
  },
  getIsNewChatConvo: (state) => {
    return state.isNewChatConvo;
  },
  getIsOnline: (state) => {
    return state.isOnline;
  },
  getTypingUser: (state) => {
    return state.typingUser;
  },

  getTyping: (state) => {
    return state.typing;
  },

  getOtherUserIsOnline: (state) => {
    return state.otherUserIsOnline;
  },
  getCanSendMessages: (state) => {
    return state.canSendMessages;
  },
  getBlockedUser: (state) => {
    return state.blockedUser;
  },
  getIsBlocked: (state) => {
    return state.isBlocked;
  },
  getMessages: (state) => {

    return state.messages;
  },
  getIsBubbleActive: (state) => {
    return state.isBubbleActive;
  },
  getIsBubbleClosed: (state) => {
    return state.isBubbleClosed;
  },
  getIsActive: (state) => {
    return state.isActive;
  },
  getCurrentlyChattingWith: (state) => {
    return state.currentlyChattingWith;
  },
  getMyID: (state) => {
    return state.myID;
  },
  getMyName: (state) => {
    return state.myName;
  },
  getOnlineUsers: (state) => {
    return state.onlineUsers;
  },
  getTotalNumberOfUnreadMessages: (state) => {
    return state.totalNumberOfUnreadMessages;
  },
  getChatList: (state) => {
    return state.chatList;
  },
  getIsShowActionModal: (state) => {
    return state.isShowActionModal;
  },
  getHasReported: (state) => {
    return state.hasReported;
  },
  getIsBlockModal: (state) => {
    return state.isBlockModal;
  },
  getSearch: (state) => {
    return state.search;
  },
};
const actions = {
  sortMessaging({ commit }, messages) {
    messages.sort((first, second) => {
      return (
        new Date(second.messages[0]?.createdAt) -
        new Date(first.messages[0]?.createdAt)
      );
    });
    commit('setChatList', messages);
  },
  checkCanMessage({ commit }, { user_id, type
  }) {
    console.log({
      user_id, type
    })
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.USERS + 'chat-requirements/' + user_id)
        .then(({ data, status }) => {
          // console.log({ data, status });
          if (status === 200 || status === 201) {
            if (type === 1) {
              if (data.res.profilePic === 'Yes' && data.res.workExperience === 'Yes') {
                resolve(true);
              } else {
                resolve(false);
              }
            }

            if (type === 2) {
              if (data.res.Logo === 'Yes' && data.res.Website === 'Yes' && data.res.Teams === 'Yes') {
                resolve(true);
              } else {
                resolve(false);
              }
            }

          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadChatList({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.PRIVATECHAT + 'user/' + user_id + '/chat/all/')
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setChatList', data.recentChatedUsers);
            commit('SetTotalUnreadMes', data.totalNumberOfUnreadMessages);
            //commit('setLoadMoreClicked', false);
            resolve(true);
          }
        })
        .catch((error) => {
          commit('setChatList', []);
          reject(error);
        });
    });
  },
  loadUserGroups({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.GROUPSCHAT + 'user/' + user_id)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setUserGroups', data.groups);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadUserAllMessages({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.PRIVATECHAT + '/pm/user/' + user_id + '/chat/all/')
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setUserInitialMessages', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadUserMessages({ commit }, { senderId, receiverId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURLS.PRIVATECHAT + 'sender/' + senderId + '/receiver/' + receiverId
        )
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setMessages', data.messages);
            commit('setChatMessagesCount', data.messagesCount);
            commit('setAllMessagesCount', data.allMessagesCount);
            if (data.allMessagesCount > 100) {
              commit('setHasMoreMessages', true);
            } else {
              commit('setHasMoreMessages', false);
            }
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  markUnreadMessages({ commit, getters }, { from, to }) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.PRIVATECHAT + 'mark-unread-messages', { from, to })
        .then(({ data, status }) => {
          if (data === 'ok') {
            commit('resetUserUnreadMsg', getters.getCurrentlyChattingWith.id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  blockUser({ commit }, { from, to }) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.PRIVATECHAT + 'block', { from, to })
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setIsBlocked', true);
            commit('setBlockedUser', { from });
            resolve(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  unblockUser({ commit }, { from, to }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.PRIVATECHAT + 'unblock/from/' + from + '/to/' + to)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setIsBlocked', false);
            commit('setBlockedUser', {});
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  checkBlockUser({ commit }, { from, to }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.PRIVATECHAT + 'check-blocked/from/' + from + '/to/' + to)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            if (data.message === 'blocked') {
              commit('setIsBlocked', true);
              commit('setBlockedUser', data.user);
            } else {
              commit('setIsBlocked', false);
              commit('setBlockedUser', {});
            }
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  createGroup({ commit }, group) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.GROUPSCHAT + 'create', group)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('pushGroup', data.group);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error.response);
          }
        });
    });
  },

  reportUser({ commit, dispatch }, { report }) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.PRIVATECHAT + 'report', report)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setReportUser');
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error.response);
          }
        });
    });
  },

  async openNewChatBox({ commit, dispatch, getters }, user) {
    commit('setMessages', {});
    commit('setIsBubbleClosed', false);
    commit('setIsBubbleActive', true);
    commit('setIsNewChatConvo', true);
    commit('setHasMoreMessages', false);
  },

  async openChatBoxWithUser({ commit, dispatch, getters }, user) {
    commit('setIsLoadingMessage', true);
    commit('setCurrentlyChattingWith', user);
    commit('setIsBubbleClosed', false);
    commit('setIsBubbleActive', true);
    commit('setIsNewChatConvo', false);
    commit('setPage', 1);
    commit('setCount', 0);
    let senderId = getters.authUser.id;
    let receiverId = getters.getCurrentlyChattingWith.id;
    let from = senderId;
    let to = receiverId;
    await dispatch('checkBlockUser', { from, to });
    let foundUser = getters.getChatList.find((chat) => chat.user_id === to);
    if (getters.getChatList.length && foundUser) {
      commit('setMessages', foundUser.messages);
      commit('setChatMessagesCount', foundUser.messagesCount);
      commit('setAllMessagesCount', foundUser.allMessagesCount);
      if (foundUser.allMessagesCount > 100) {
        commit('setHasMoreMessages', true);
      } else {
        commit('setHasMoreMessages', false);
      }
    } else {
      await dispatch('loadUserMessages', { senderId, receiverId });
    }
    await dispatch('markUnreadMessages', { from, to });
    commit('setIsLoadingMessage', false);
  },
  async destroyChatBox({ commit }) {
    commit('setChatList', []);
    commit('setOnlineUsers', {});
    commit('setMessages', []);
    commit('setAllMessagesCount', 0);
    commit('setCurrentlyChattingWith', {});
    commit('setTyping', {});
    commit('setIsBubbleActive', false);
    commit('setIsNewChatConvo', false);
    commit('setIsBubbleClosed', true);
    commit('closeActionModal');
    commit('closeBlockModal');
    commit('setPage', 1);
    commit('setCount', 0);
  },
};
const mutations = {
  setPage: (state, value) => {
    return (state.page = value);
  },

  setCount: (state, value) => {
    return (state.count = value);
  },

  setChatServerStatus: (state, value) => {
    return (state.chatServerStatus = value);
  },

  setIsOnline: (state) => {
    return (state.isOnline = true);
  },
  setOtherUserIsOnline: (state, value) => {
    return (state.otherUserIsOnline = value);
  },
  setOnlineUsers: (state, users) => {
    return (state.onlineUsers = users);
  },
  setChatMessagesCount: (state, count) => {
    return (state.chatMessagesCount = count);
  },
  setIsDisconected: (state) => {
    return (state.isOnline = false);
  },
  setChatList: (state, chatList) => {
    state.chatList = chatList;
  },
  setMessages: (state, messages) => {
    state.messages = messages;
  },
  setAllMessagesCount: (state, count) => {
    state.allMessagesCount = count;
  },
  addNewMessage: (state, message) => {
    // state.messages.push(message);
    state.messages.unshift(message);
  },
  setCurrentlyChattingWith: (state, user) => {
    state.currentlyChattingWith = user;
  },
  resetUserUnreadMsg: (state, user_id) => {
    var index = state.chatList.findIndex((item) => item.user_id === user_id);
    if (index !== -1) {
      state.totalNumberOfUnreadMessages -=
        state.chatList[index].unreadMessagesCount;
      Object.assign(state.chatList[index], { unreadMessagesCount: 0 });
    }
  },
  incrementUserUnreadMsg: (state, user_id) => {
    var index = state.chatList.findIndex((item) => item.user_id === user_id);
    if (index !== -1) {
      state.totalNumberOfUnreadMessages += 1;
      var count = state.chatList[index].unreadMessagesCount + 1;
      Object.assign(state.chatList[index], { unreadMessagesCount: count });
    }
  },
  setCanSendMessages: (state, value) => {
    state.canSendMessages = value;
  },
  setTyping: (state, value) => {
    state.typing = value;
  },
  setIsLoadingMessage: (state, value) => {
    state.isLoadingMessage = value;
  },
  setTypingUser: (state, user) => {
    state.typingUser = user;
  },
  setBlockedUser: (state, value) => {
    state.blockedUser = value;
  },
  setIsBlocked: (state, value) => {
    state.isBlocked = value;
  },
  setIsNewChatConvo: (state, value) => {
    state.isNewChatConvo = value;
  },
  setIsBubbleActive: (state, value) => {
    state.isBubbleActive = value;
  },
  setIsBubbleClosed: (state, value) => {
    state.isBubbleClosed = value;
  },
  setLoadMoreClicked: (state, value) => {
    state.loadMoreClicked = value;
  },
  setLoadingMoreChats: (state, value) => {
    state.loadingMoreChats = value;
  },
  setChatPagination: (state, value) => {
    state.chatPagination = value;
  },
  setHasMoreMessages: (state, value) => {
    state.hasMoreMessages = value;
  },
  SetTotalUnreadMes: (state, totalNumberOfUnreadMessages) => {
    state.totalNumberOfUnreadMessages = totalNumberOfUnreadMessages;
  },
  calculateTotalUnreadMessages: (state) => {
    state.totalNumberOfUnreadMessages = state.chatList
      .filter((chat) => chat.unreadMessagesCount > 0)
      .map((chat) => chat.unreadMessagesCount)
      .reduce((totalToread, num) => totalToread + num, 0);
  },
  select: function (state, event, id) {
    state.currentlyChattingWith = state.chatList[event.currentTarget.id - 1];
    state.isBubbleActive = true;
    state.isShowActionModal = false;
    state.chatList[event.currentTarget.id - 1].unreadMessagesCount = 0;




    //compute unread messages
    state.totalNumberOfUnreadMessages = state.chatList
      .filter((chat) => chat.unreadMessagesCount > 0)
      .map((chat) => chat.unreadMessagesCount)
      .reduce((totalToread, num) => totalToread + num, 0);

    //open the bubble chat
    state.isBubbleClosed = false;
  },
  handleToggle: (state) => {
    state.isActive = !state.isActive;
  },
  toggleBubbleActive(state) {
    state.isShowActionModal = false;
    state.isBubbleActive = !state.isBubbleActive;
    this.messageContainerToScroll = this.$el.querySelector('#messages');
    this.messageContainerToScroll.scrollTop = this.messageContainerToScroll.scrollHeight;



  },
  closeBubble(state) {
    state.isBubbleClosed = true;
    state.isShowActionModal = false;
  },
  handleChatListClick(state) {
    state.isBubbleActive = true;
  },
  closeMessage() {
    console.log('message closed');
  },
  setCurrentChattingWith() {
    console.log('mutation from store');
  },
  showActionModal: (state) => {
    state.isShowActionModal = !state.isShowActionModal;
  },
  closeActionModal: (state) => {
    state.isShowActionModal = false;
  },
  openBlockModal(state) {
    state.isBlockModal = true;
  },
  setUserInitialMessages: (state, messages) => {
    state.messages = messages;
  },
  closeBlockModal(state) {
    state.isBlockModal = false;
  },
  openReportModal(state) {
    state.isBlockModal = true;
  },
  setReportUser(state, payload) {
    state.hasReported = true;
    let timer = setTimeout(() => {
      state.isBlockModal = false;
      state.hasReported = false;
      clearTimeout(timer);
    }, 1000);
  },
  sendMessage(state, payload) {
    const { id, userId, message } = payload;
    state.chatList[id - 1].messages.push(message);
    //state.chatList[]
  },
  ScrollMessagesToBottom() {
    this.messageContainerToScroll = this.$el.querySelector('#messages');
    this.messageContainerToScroll.scrollTop = this.messageContainerToScroll.scrollHeight;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
