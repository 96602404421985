<template>
  <div class="select">
    <div class="select-value" @click="toggleShowOptions">
      {{ selectedOptionLabel }}
      <span :class="{ active: showedOptions }" class="select-value_arrow">
        <img
          alt=""
          src="./../../assets/images/profile-settings/arrow-down.png"
        />
      </span>
    </div>
    <transition name="slide-down">
      <div v-if="showedOptions" class="select-options">
        <div
          v-for="option in options"
          :key="option.key"
          :class="{ active: option.key === value }"
          class="select-options-item"
          @click="change(option)"
        >
          <b> {{ option.label }} </b>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "VSelect",
  data: () => ({
    showedOptions: false,
  }),
  props: {
    value: {},
    label: {},
    options: {},
    selectedValue: {
      type: String,
    },
  },
  mounted() {
    if (this.selectedValue) {
      // eslint-disable-next-line vue/no-mutating-props
      this.label = this.$i18n.t(this.selectedValue);
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((op) => op.key === this.value);
    },
    selectedOptionLabel() {
      return this.selectedOption.label;
    },
  },

  methods: {
    toggleShowOptions() {
      this.showedOptions = !this.showedOptions;
    },
    hideOptions() {
      this.showedOptions = false;
    },
    change(option) {
      this.$emit("input", option.key);
      this.hideOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  margin-bottom: 15px;

  &-value {
    border: 1px solid #0f8d42;
    border-radius: 10px;
    text-align: left;
    font-weight: 700;
    padding: 10px 10px 10px 20px;
    position: relative;
    z-index: 2;
    background-color: #fff;
    cursor: pointer;

    &_arrow {
      display: inline-block;
      position: absolute;
      right: 0;
      margin: 5px 10px;
      transform: rotateX(180deg);
      transform-origin: center;

      img {
        max-width: 15px;
      }

      &.active {
        transform: rotateX(0);
      }
    }
  }

  &-options {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 10px;
    text-align: left;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #c6c6c6;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;

    &-item {
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover,
      &.active {
        color: #0f8d42;
        background: #e4f4e9;
        border-radius: 5px;
      }
    }
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-40px);
}
</style>

