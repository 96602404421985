<template>
  <div class="text-center">
    <div class="card-block">
      <div
        class="alert alert-info"
        role="alert"
        v-if="routeName === 'ProfileEn' || routeName === 'ProfileFr'"
      >
        <p class="text-dark">
          {{ $t(text) }}
        </p>
        <hr />
        <p class="mb-0">
          <a :href="buttonLink" class="btn btn-primary mb-2">{{
            $t(buttonText)
          }}</a>
        </p>
      </div>
      <div class="mx-3 my-2 alert alert-dark" role="alert" v-else>
        <p class="text-dark">
          {{ $t('noData') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import i18n from '../i18n';
export default {
  mounted() {
    this.routeName = this.$route.name;
  },
  computed: {
    ...mapGetters(['authUser']),
  },
  data() {
    return {
      routeName: '',
    };
  },
  methods: {},
  name: 'NewsList',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonLink: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
