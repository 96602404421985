import axios from 'axios';
import i18n from '../../i18n';
import APIURLS from '../../constant';
import currentUser from './auth';

const state = {
  step: 1,
  accountType: null,
  isStepOne: true,
  isStepTwo: true,
  isStepThree: true,
  isStepFour: true,
  isStepFive: true,

  isStepOneDone: true,
  isStepTwoDone: true,
  isStepThreeDone: true,
  isStepFourDone: true,
  isStepFiveDone: true,
  showTypes: true,
  sector_err: false,
  formData: {
    lastName: '',
    firstName: '',
    avatar: '',
    email: '',
    password: '',
    tractions: '',
    business_model: '',
    revenue_state: '',
    referral_code: '',
    referral_initail_stage: '', // grap the ref at step one
    referral_final_stage: '', // grap the ref at final step /// check if intial stage ref and final stage ref are equal
    accountType: 'Startup',
    levelOfEducation: '',
    yearsOfExperience: '',
    africanCountryOfOrigin: '',
    countryOfResidence: '',
    supportStartup: null,
    supportType: [],
    sectorOfInterrest: [],
    startupName: '',
    website: '',
    description: '',
    foundingYear: '',
    location: '',
    startupStage: '',
    numberInTeams: '',
    sector: '',
    slug: '',
    logo: '',
    cover: '',
    video: '',
    expertises: [],
  },
  err_msg_residence: null,
  err_msg_origin: null,
  err_msg_exp_diasp: null,
  err_msg_supportType: null,
  err_msg_sectorOfInterest: null,
  err_msg_tractions: null,
  err_msg_revenue_state: null,
  err_msg_businessModal: null,
};
const getters = {
  getSector_err: (state) => {
    return state.sector_err;
  },

  getShowTypes: (state) => {
    return state.showTypes;
  },
  getFordataField: (state) => (field) => {
    return state.formData[field];
  },
  getErrorMsg: (state) => (field) => {
    return state[field];
  },
  getIsStepOne: (state) => {
    return state.isStepOne;
  },
  getIsStepTwo: (state) => {
    return state.isStepTwo;
  },
  getIsStepThree: (state) => {
    return state.isStepThree;
  },
  getIsStepFour: (state) => {
    return state.isStepFour;
  },
  getIsStepFive: (state) => {
    return state.isStepFive;
  },
  getIsStepOneDone: (state) => {
    return state.isStepOneDone;
  },
  getIsStepTwoDone: (state) => {
    return state.isStepTwoDone;
  },
  getIsStepThreeDone: (state) => {
    return state.isStepThreeDone;
  },
  getIsStepFourDone: (state) => {
    return state.isStepFourDone;
  },
  getIsStepFiveDone: (state) => {
    return state.isStepFiveDone;
  },
  getStep: (state) => {
    return state.step;
  },
  getFomData: (state) => {
    return state.formData;
  },
  getAccountType: (state) => {
    return state.accountType;
  },
  getLevelOfEducation: (state) => {
    return state.formData.levelOfEducation;
  },
  getTractions: (state) => {
    return state.formData.tractions;
  },
  getBusinessModel: (state) => {
    return state.formData.business_model;
  },
  getrevenueState: (state) => {
    return state.formData.revenue_state;
  },
  getreferralCode: (state) => {
    return state.formData.referral_code;
  },
};

const actions = {
  checkEmailExist({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.USERS + 'check-email/' + email)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
};
const mutations = {
  setSector_err: (state, value) => {
    state.sector_err = value;
  },
  setShowTypes: (state, value) => {
    state.showTypes = value;
  },
  gotoNext: (state) => {
    state.step++;
  },
  goNextBis: (state, value) => {
    state.step = value;
  },
  goPrevious: (state) => {
    state.step--;
  },
  goPreviousBis: (state, value) => {
    state.step = value;
  },
  setStep: (step, payload) => {
    state.step = payload;
    if (payload === 1) {
      state.isStepOne = true;
      state.isStepTwo = false;
      state.isStepThree = false;
      state.isStepFour = false;
      state.isStepFive = false;

      state.isStepOneDone = false;
      state.StepTwoDone = false;
      state.isStepThreeDone = false;
      state.isStepFourDone = false;
      state.isStepFiveDone = false;
    }
  },
  setAccountType: (state, payload) => {
    state.formData.accountType = payload;
    state.accountType = payload;
  },
  setFormData: (state, { field, value }) => {
    return (state.formData[field] = value);
  },
  setErrorMsg: (state, { field, value }) => {
    return (state[field] = value);
  },
  setTractions: (state, { traction }) => {
    state.formData.tractions = traction;
  },
  setBusinessModel: (state, { model }) => {
    state.formData.business_model = model;
  },
  setRevenueState: (state, { revenue }) => {
    state.formData.revenue_state = revenue;
  },
  getreferralCode: (state, { refCode }) => {
    state.formData.referral_code = refCode;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
