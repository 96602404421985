<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0 text-center">{{ $t('team') }}</h6>
    </div>
    <div class="box-body p-3" v-if="members.length">
      <div class="row memberList">
        <ProfileCard
          class="col col-md-6 mb-3"
          v-for="(member, index) in members"
          :key="index"
          :user="member"
          :is_team="true"
        />
      </div>
    </div>
    <NoData
      class="p-4"
      v-else
      text="noAbout"
      buttonText="addAbout"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.editProfile')}`"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Widget',
  props: {
    members: {},
  },
};
</script>

<style scoped>
 .memberList {
   display: flex;
  flex-direction: row;
  flex-wrap:  wrap;
  justify-content: center;
 }
 @media (max-width: 768px) {
   .memberList {
  flex-direction: column;
 }
 }
</style>
