<template>
  <div class="container">
    <div
      class="box mb-3 shadow-sm border rounded bg-white profile-box text-center"
    >
      <div class="py-4 px-3 border-bottom">
        <img
          :src="avatar"
          class="img-fluid mt-2 rounded-circle"
          width="120"
          alt=""
        />
        <h5 class="font-weight-bold text-dark mb-1 mt-4">
          {{ firstname }} {{ name }}
        </h5>
        <!-- <small> @{{ username }}</small>
        <br /> -->
        <div class="mt-0 mb-2">
          <span
            class="ml-2 badge badge-pill badge-success"
            v-if="is_verified"
            >{{ $t("verified") }}</span
          >

          <span class="ml-2 badge badge-pill badge-danger" v-else>{{
            $t("profileNonVerified")
          }}</span>
        </div>
        <p class="mb-0 text-muted">{{ headline }}</p>
      </div>
      <div class="d-flex" v-if="showDetails">
        <div class="col-6 border-right p-1">
          <h6 class="font-weight-bold text-dark mb-1">
            <i class="fa fa-birthday-cake" aria-hidden="true"></i>
          </h6>
          <p class="mb-0 text-black-50 small">{{ dob }}</p>
        </div>
      </div>
      <ActivateAccount :showVerified="showVerified && !is_verified" />
      <span v-if="showLink === 1">
        <div class="d-flex">
          <div class="col-12 border-right p-3">
            <h6 class="font-weight-bold text-dark mb-1">{{ count }}</h6>
            <p class="mb-0 text-black-50 small">Connections</p>
          </div>
        </div>
        <div class="overflow-hidden border-top">
          <a
            class="font-weight-bold p-3 d-block"
            :href="`/${$i18n.locale}/${this.$i18n.t('routes.myProfile')}`"
          >
            {{ $t("navBar.myProfile") }}
          </a>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import i18n from "../../i18n";
import { mapGetters } from "vuex";
export default {
  name: "ProfileWidget",
  props: {
    name: {
      type: String,
      default: "",
    },
    firstname: {
      type: String,
      default: "",
    },
    headline: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
    sex: {
      type: String,
      default: "",
    },
    dob: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    avatar: {
      type: String,
      default: "",
    },
    showLink: {
      type: Number,
      default: 1,
    },
    showVerified: {
      type: Boolean,
      default: false,
    },
    is_verified: {
      type: Number,
      default: 0,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapGetters(["authUser"]),
};
</script>
