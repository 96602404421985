<template>
  <div>
    <div class="vh-100 bg-white">
      <div class="container">
        <div class="row align-items-center vh-100">
          <div class="col-md-12 text-center icon-welcome">
            <img
              src="https://res.cloudinary.com/bantaba/image/upload/v1622977357/platform/pending-5-861794_ddrvjh.png"
              alt="pending icon"
              width="50"
            />
            <h1 class="text-dark font-weight-bold">{{ $t('welcome') }} !!!</h1>
            <p class="lead my-4">
              {{ $t('pendingAccountText') }}
            </p>
            <p class="lead text-danger mb-4">
              {{ $t('verifySpamFolder') }}
            </p>
            <a href="/" class="btn btn-primary btn-lg">
              {{ $t('navBar.home') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PendingEmail',
};
</script>
<style>
.match {
  color: green !important;
}
.mismatch {
  color: red !important;
}
.icon-welcome {
  padding: 20px 0;
}
</style>
