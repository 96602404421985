<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t(title) }}</h6>
    </div>
    <div class="box-body border-bottom p-3">
      <div
        class="d-flex align-items-center osahan-post-header mb-3 people-list"
        v-for="user in users"
        :key="user.id"
      >
        <div class="dropdown-list-image mr-3">
          <img class="rounded-circle" :src="user.avatar" alt="" />
          <!-- <div class="status-indicator bg-success"></div> -->
        </div>
        <div class="font-weight-bold mr-2">
          <div class="text-truncate">{{ user.name }}</div>
          <!-- <div class="small text-gray-500">@{{ user.username }}</div> -->
        </div>
        <span class="ml-auto"
          ><a
            :href="`/${$i18n.locale}/profile/${user.username}`"
            class="btn btn-light btn-sm"
          >
            <i class="feather-eye"></i>
            Profile
          </a>
        </span>
      </div>
    </div>
    <div class="box-title border-bottom p-3 text-center" v-if="link">
      <a :href="link" class="m-0">{{ $t(linkText) }}</a>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Widget",
  props: {
    title: String,
    linkText: String,
    link: String,
    users: {},
  },
};
</script>
