<template>
  <form class="form sidebar">
    <FilterBoxStartup
      :title="getStartupsOptions.countries.description"
      code="countries"
      :items="getStartupsOptions.countries.contents"
      :limit="10"
    />
    <FilterBoxStartup
      :title="getStartupsOptions.sectors.description"
      code="sectors"
      :items="getStartupsOptions.sectors.contents"
      :limit="10"
    />
    <!-- <FilterBoxStartup
      :title="stages.description"
      :items="stages.contents"
      code="stages"
      :limit="10"
    /> -->
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FilterBoxStartup from "./FilterBox_startup.vue";

export default {
  mounted() {
    
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUsers",
      "getConnectionsCounts",
      "getStartups",
      "getCountriesFilter",
      "getModalMode",
      "getStartupsPage",
      "getStartupsLimit",
      "getStartupTotalCount",
      "getStartupSearchFilters",
      "getFilterCode",
      "getSectorsFilter",
      "getStartupsOptions",
    ]),
  },
  components: {
    FilterBoxStartup,
  },
  props: {
    countries: Object,
    sectors: Object,
    stages: Object,
  },
};
</script>

<style scoped>
ul li {
  list-style-type: none;
}
::-webkit-scrollbar {
  display: block !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f4f2 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75b38c !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1ba54b !important;
}
.sidebar {
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.67);
  padding-top: 0.5rem;
  overflow-y: auto;
  max-height: 90vh;
  padding-bottom: 100px !important;
}
.sidebar {
  overflow-y: scroll;
  scrollbar-color: #75b38c #f0f4f2;
}
</style>

