<template>
    <div>
        <div class="form-wrapper">
            <div class=" text-left margin_top" v-if="GetModalContentCategory === 'accept'">
                <p>{{ $t(text) }}</p>
            </div>

            <div class=" text-left margin_top" v-if="GetModalContentCategory === 'reject'">
                <p>{{ $t(text1) }}</p>
            </div>

            <!-- <div class="mt-3">
                <input type="checkbox" name="checkbox" value="1" :checked="checkedValue" @change="setCheckboxActive">
                <label for="checkbox" class="ml-2">{{ $t(text2) }}</label>
            </div> -->

            <div class="margin_top d-flex justify-content-between pl-3 pr-3">
                <div>
                    <button v-if="disabled" :disabled="disabled" class="button button-disabled">{{ buttonLoader?
                    $t('loading')+'...': $t(text3) }}</button>

                    <button v-else class="button" @click="submitFormData">{{ buttonLoader? $t('loading')+'...':
                    $t(text3) }}</button>
                </div>
                <div>
                    <button class="button button-color" @click="closeMyModal">{{ $t(text4) }}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import APIURLS from '../../../../../../constant';
import i18n from '../../../../../../i18n';
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        GetModalContentCategory: String,
        getModalContentValue: Object,
        text: {
            type: String,
            default: "uploadFormText1",
        },

        text1: {
            type: String,
            default: "uploadFormText2",
        },

        text2: {
            type: String,
            default: "dontAskAgain",
        },

        text3: {
            type: String,
            default: "yes",
        },

        text4: {
            type: String,
            default: "cancel",
        }
    },

    data() {
        return {
            checkedValue: "",
            sector: '',
            disabled: false,
            buttonLoader: false,
            formdata: {
                id: null,
                status: ''
            }
        }
    },

    mounted() {

        this.isCheckboxActive()
    },

    computed: {
        ...mapGetters([
            "authUser",
            "getCurrStartup",
            "getStartup",
            "getCurrStartupPitchdeckNotAvail",
            "getStartupIDVault",
            "getUserLang"]),
    },

    methods: {
        ...mapActions(['updateRequestVault',
            "loadUserExpertises",
            "getForeignCountryByLang",
            "getSectorByLang",
            "getUserById"
        ]),

        isCheckboxActive() {
            var check = ''
            if (this.GetModalContentCategory === 'accept') {
                check = sessionStorage.getItem("checkBoxStatusA")
                this.checkedValue = check
                check = ""
            }

            if (this.GetModalContentCategory === 'reject') {
                check = sessionStorage.getItem("checkBoxStatusR")
                this.checkedValue = check
                check = ""
            }
        },

        setCheckboxActive() {
            if (this.GetModalContentCategory === 'accept') {
                this.checkedValue = "1"
                this.formdata.status = "2"
                sessionStorage.setItem("checkBoxStatusA", this.checkedValue)
            }

            if (this.GetModalContentCategory === 'reject') {
                this.checkedValue = "1"
                this.formdata.status = "3"
                sessionStorage.setItem("checkBoxStatusR", this.checkedValue)
            }
        },

        checkStatus() {
            if (this.GetModalContentCategory === 'accept') {
                this.formdata.status = "2"
            }

            if (this.GetModalContentCategory === 'reject') {
                this.formdata.status = "3"
            }
        },

        async submitFormData() {
            this.disabled = true;
            this.buttonLoader = true;
            this.checkStatus();
            this.formdata.id = this.getModalContentValue.id;

            let lang = this.getUserLang;
            await this.getSector();
            let user = await this.getUserById({ user_id: this.getModalContentValue.requester_id });
            //console.log({ user });
            this.formdata.lang = lang;
            this.formdata.name = user.firstname;
            this.formdata.startup_name = this.getStartup.startupname;
            this.formdata.avatar = this.authUser.avatar;
            this.formdata.sector = this.sector;
            this.formdata.location = this.getStartup.location;
            this.formdata.email = user.email;
            this.formdata.profile_link = APIURLS.SITELINK + `${this.$i18n.locale}/profile/${user.username}`;
            this.formdata.startups_page = APIURLS.SITELINK + `${this.$i18n.locale}/startups`;

            //console.log({ formdata: this.formdata });
            this.updateRequestVault(this.formdata)
                .then(() => {
                    this.$store.dispatch("getVaultRequest");

                    this.disabled = false;
                    this.buttonLoader = false;
                    this.closeMyModal()
                })

        },

        closeMyModal() {
            this.$store.commit("set_show_modal", false)
        },
        async getSector() {
            let lang = this.getUserLang;
            let code = this.getStartup.sector;
            if (this.getStartup.sector != null) {
                this.sector = await this.getSectorByLang({ code, lang });
            } else {
                this.sector = "";
            }
        },

        fortmatCountry(country) {
            var countries = require("i18n-iso-countries");
            countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
            countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
            return (country = countries.getName(country, i18n.locale, {
                select: "official",
            }));
        },
    },
}
</script>


<style scoped>
.form-wrapper {
    padding: 0 2em 2em 2em
}

.input-control {
    width: 100%;
    border: 1px solid green;
    border-radius: .2em;
}

.inputText {
    padding: .5em;
    width: 100%;
    background-color: #fff;
    display: inline-block;
}

.inputfile+label {
    /* font-size: 1.25em;
        font-weight: 700; */
    padding: .5em;
    background-color: #fff;
    display: inline-block;
}

.cancel-upload-btn {
    width: 10px;
    height: 9px;
}

.margin_top {
    margin-top: 1.3em;
}

.remove_margin_bottom {
    margin-bottom: -2em;
}

.button {
    color: #131313;
    background: #B1E0C2;
    border: none;
    padding: .5em 2em .5em 2em;
    border-radius: 7px;
    width: 132px;
}

.button-disabled {
    opacity: 0.5 !important;
}

.disable {
    opacity: 0.5;
}

.button-color {
    color: #888;
    background: #fff;
    border: 1px solid #B1E0C2;
}

/* .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: red;
    } */
</style>