<template>
    <div class="loading-bubble">
  <div class="chat--bubbles">
    <span></span>
    <span></span>
    <span></span>
  </div><!--fb-chat-bubbles-->
</div><!--fb-chat-->
</template>
<script>
export default {
    name: 'NewLoader'
}
</script>

<style lang="scss" scoped>
.loading-bubble{
  background-color: #E6E7EC;
  width: 100px;
  line-height: 60px;
  height: 60px;
  display: block;
  border-radius: 30% / 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  // page centering
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
  &--bubbles{
    text-align: center
  }
  
    span{
      display: inline-block;
      background-color: #B6B5BA;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      margin-right: 5px;
      animation: bob 2s infinite;
    }
      
      // SAFARI GLITCH
      &:nth-child(1)
      {
           animation-delay: -1s
      }
       
      &:nth-child(2)
        {
            animation-delay: -0.85s
        }
      &:nth-child(3)
        {
            animation-delay: -0.7s;
        margin-right: 0
        }


        }
        

@keyframes bob
{
  10%{
    transform: translateY(-10px);
    background-color: #9E9DA2;
  }
    
  50%{
    transform: translateY(0);
    background-color: #B6B5BA;
  }
}
</style>