<template>
  <div>
    <div class="border rounded bg-white mb-3">
      <div class="box-title border-bottom p-3">
        <h6 class="m-0">{{ $t('socialProfiles') }}</h6>
        <p class="mb-0 mt-0 small">
          {{ $t('socialProfilesText') }}
        </p>
      </div>
      <div class="box-body px-3">
        <form @submit.prevent="updateSocials">
          <div class="p-3 border-bottom">
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-linkedin position-absolute text-linkedin"></i>
              <input
                placeholder="Linkedin"
                type="link"
                class="form-control"
                v-model="socials.linkedin"
              />
            </div>
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-instagram position-absolute text-instagram"></i>
              <input
                placeholder="Instagram"
                type="link"
                class="form-control"
                v-model="socials.instagram"
              />
            </div>
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-facebook position-absolute text-facebook"></i>
              <input
                placeholder="Facebook"
                type="link"
                class="form-control"
                v-model="socials.facebook"
              />
            </div>
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-twitter position-absolute text-twitter"></i>
              <input
                placeholder="Twitter"
                type="link"
                class="form-control"
                v-model="socials.twitter"
              />
            </div>
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-youtube position-absolute text-youtube"></i>
              <input
                placeholder="Youtube"
                type="link"
                class="form-control"
                v-model="socials.youtube"
              />
            </div>
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-globe position-absolute text-dark"></i>
              <input
                :placeholder="$t('website')"
                type="link"
                class="form-control"
                v-model="socials.website"
              />
            </div>
            <div class="position-relative icon-form-control mb-2">
              <i class="feather-github position-absolute text-dark"></i>
              <input
                placeholder="Github"
                type="link"
                class="form-control"
                v-model="socials.github"
              />
            </div>
          </div>
          <div class="overflow-hidden text-center p-3">
            <button
              :disabled="btnLoading"
              type="submit"
              class="font-weight-bold btn btn-light rounded p-3 btn-block"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="btnLoading"></i>
              {{ btnLoading ? '' : $t('save') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.socials = this.startup;
  },
  data() {
    return {
      btnLoading: false,
    };
  },
  methods: {
    updateSocials() {
      this.btnLoading = true;
      this.$store
        .dispatch('updateStartup', this.socials)
        .then((success) => {
          this.$noty.success('Socials Updated Successfully !');
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          this.$noty.error(error);
        });
    },
  },
  name: 'UpdateSocialMedias',
  props: {
    startup: {},
  },
};
</script>

<style>
.text-linkedin {
  color: #0e76a8;
}
.text-instagram {
  color: #c13584;
}
.text-facebook {
  color: #3b5998;
}
.text-twitter {
  color: #00acee;
}
.text-youtube {
  color: #c4302b;
}
</style>
