<template>
  <form action="" class="form" v-on:submit.prevent="commitStep1">
    <h1 class="form__title">{{ $t("signupToBantaba") }}</h1>
    <div class="row-inputs">
      <div class="input-div">
        <Input
          v-model="form.firstName"
          typeName="text"
          label="firstName"
          name="firstName"
          @change="saveInput('firstName')"
          :isRequired="true"
          :hasError="errors.firstName"
          :error_msg="msg.firstName"
        />
      </div>
      <div class="input-div">
        <Input
          v-model="form.lastName"
          typeName="text"
          label="lastName"
          name="lastName"
          @change="saveInput('lastName')"
          :isRequired="true"
          :hasError="errors.lastName"
          :error_msg="msg.lastName"
        />
      </div>
    </div>
    <Input
      v-model="form.email"
      typeName="email"
      label="Email"
      name="Email"
      @change="saveInput('email')"
      :isRequired="true"
      :hasError="errors.email"
      :error_msg="msg.email"
    />

    <Input
      v-model="form.password"
      typeName="password"
      @change="saveInput('password')"
      label="password"
      name="password"
      :isPassword="true"
      :isRequired="true"
      :hasError="errors.password"
      :error_msg="msg.password"
    />
    <AccountType :hasError="errors.accountType" :error_msg="msg.accountType" />
    <div class="term-and-service-container">
      <CheckBox :isRequired="true" />
      <p class="terms-conditions">
        {{ $t("YouAgreeToBantaba") }}
        <a
          target="_blank"
          :href="`/${$i18n.locale}/privacy`"
          class="term-highlight"
          >{{ $t("privacyPolicies") }}</a
        >, {{ $t("and") }}
        <a
          target="_blank"
          :href="`/${$i18n.locale}/cookies`"
          class="term-highlight"
          >{{ $t("cookiesPolicies") }}</a
        >.
      </p>
    </div>
    <SubmitBtn value="signUp" class="step1_signUp" />

    <LoginBtn
      text="alreadyOn"
      buttonText="signIn"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.login')}`"
    />
  </form>
</template>
<script>
import Input from "./Input";
import SubmitBtn from "./SubmitBtn";
import LoginBtn from "./LoginBtn";
import AccountType from "./AccountType";
import CheckBox from "./CheckBox.vue";

import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Form",
  data() {
    return {
      form: {
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        accountType: false,
      },
      errors: {},
      msg: {
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        accountType: "",
      },
    };
  },
  components: {
    Input,
    SubmitBtn,
    AccountType,
    LoginBtn,
    CheckBox,
  },
  created() {
    //set user language
    let lang = localStorage.getItem("userLang");
    if (lang) {
      this.$i18n.locale = lang;
    }
    const inputs = ["firstName", "lastName", "email", "password"];
    inputs.map((item) => {
      if (localStorage.getItem(item))
        this.form[item] = localStorage.getItem(item);
      this.$store.commit("setFormData", {
        field: item,
        value: this.form[item].trim(),
      });
    });
  },
  computed: {
    ...mapGetters(["getFomData", "getAccountType"]),
  },
  methods: {
    ...mapActions(["checkEmailExist"]),
    ...mapMutations(["gotoNext", "goPrevious", "setStep"]),
    async commitStep1() {
      if (!this.getAccountType) {
        this.errors.accountType = true;
        this.msg.accountType = "chooseTypeOfAccount";
      } else {
        this.errors.accountType = null;
        this.msg.accountType = "";
      }
      let checkEmail = await this.checkEmailExist(this.form.email);

      if (checkEmail === "exist") {
        this.errors.email = true;
        this.msg.email = "emailExist";
      } else {
        this.errors.email = null;
        this.msg.email = "";
      }

      let containsError = Object.values(this.errors).includes(true);
      if (!containsError) {
        localStorage.setItem("email", this.form.email);
        this.$store.commit("setFormData", {
          field: "email",
          value: this.form.email,
        });
        this.$store.commit("setFormData", {
          field: "password",
          value: this.form.password,
        });
        //setup the username
        let username =
          this.form.firstName[0].toLowerCase() +
          this.form.lastName[0].toLowerCase() +
          new Date().getTime().toString(36);
        localStorage.setItem("username", username);
        this.$store.commit("setFormData", {
          field: "username",
          value: username,
        });
        //set user language
        let lang = localStorage.getItem("userLang");
        if (lang) {
          this.$i18n.locale = lang;
        }
        if (this.getAccountType === "Diaspora") {
          //remove registration data for startup
          localStorage.removeItem("slug");
          localStorage.removeItem("startupName");
          localStorage.removeItem("location");
          localStorage.removeItem("website");
          localStorage.removeItem("description");
          localStorage.removeItem("startupStage");
          localStorage.removeItem("numberInTeams");
          localStorage.removeItem("sector");
          localStorage.removeItem("expertises");
          localStorage.removeItem("supportType");
          localStorage.removeItem("avatar");
          localStorage.removeItem("cover");

          this.$store.commit("setFormData", {
            field: "slug",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "location",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "website",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "description",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "startupStage",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "numberInTeams",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "sector",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "expertises",
            value: [],
          });
          this.$store.commit("setFormData", {
            field: "supportType",
            value: [],
          });
          this.$store.commit("setFormData", {
            field: "avatar",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "cover",
            value: null,
          });
        } else {
          //remove registration data for diaspora
          localStorage.removeItem("africanCountryOfOrigin");
          localStorage.removeItem("countryOfResidence");
          localStorage.removeItem("levelOfEducation");
          localStorage.removeItem("yearsOfExperience");
          localStorage.removeItem("expertises");
          localStorage.removeItem("supportType");
          localStorage.removeItem("sectorOfInterrest");
          localStorage.removeItem("bio");
          localStorage.removeItem("avatar");
          localStorage.removeItem("supportStartup");

          this.$store.commit("setFormData", {
            field: "africanCountryOfOrigin",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "countryOfResidence",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "levelOfEducation",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "yearsOfExperience",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "expertises",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "supportType",
            value: [],
          });
          this.$store.commit("setFormData", {
            field: "sectorOfInterrest",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "bio",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "avatar",
            value: null,
          });
          this.$store.commit("setFormData", {
            field: "supportStartup",
            value: null,
          });
        }
        window.dataLayer.push({ event: "step1_signUp" });
        this.$store.commit("setStep", 2);
      }
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async saveInput(name) {
      if (this.isEmpty(this.form[name])) {
        this.errors[name] = true;
        this.msg[name] = `${this.$i18n.t(name)} ${this.$i18n.t("isRequired")}`;
      } else {
        this.errors[name] = null;
        this.msg[name] = "";
      }

      //remove empty space at the beginning and the end
      let formValue = this.form[name].trim();

      //validate email
      if (name === "email") {
        if (this.validateEmail(this.form[name])) {
          this.errors[name] = null;
          let checkEmail = await this.checkEmailExist(this.form[name]);
          if (checkEmail === "exist") {
            this.errors[name] = true;
            this.msg[name] =
              "This Email is already taken. Please choose a new one";
          } else {
            this.errors[name] = null;
            this.msg[name] = "";
          }
        } else {
          this.errors[name] = true;
          this.msg[name] = "Email address not valid!";
        }
      }

      //save to localstorage and store
      if (name != "password") {
        localStorage.setItem(`${name}`, formValue);
        this.$store.commit("setFormData", {
          field: name,
          value: formValue,
        });
      }
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.form {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 2rem 2rem;
  border-radius: 1rem;
  box-shadow: none;
}
.form__title {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 1.5rem;
  font-family: inherit;
}
.row-inputs {
  display: flex;
  justify-content: space-between;
}
.input-div {
  position: relative;
  width: 48%;
}
.term-and-service-container {
  display: flex;
  padding: 12px 0;
  padding-top: 25px;
  font-size: 14px;
}

.term-highlight {
  color: var(--primary-green);
}
p.terms-conditions {
  text-align: left;
}
/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    padding-bottom: 100px;
    border-radius: 1rem;
    box-shadow: none;
  }
  .row-inputs {
  display: flex;
  justify-content: space-between;
}
} */
@media only screen and (max-width: 1200px) {
  .form {
    width: 100%;
    height: 100vh;
    padding: 1.5rem 15px;
    /* padding-bottom: 0px; */
    border-radius: 1rem;
    box-shadow: none;
  }
  .row-inputs {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .input-div {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    height: 100vh;
    padding: 0.1rem 15px;
    /* padding-bottom: 0px; */
    border-radius: 1rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    overflow-y: auto;
    padding-bottom: 100px;
    /* min-width: 300px; */
  }
  .form__title {
    font-weight: 600;
    font-size: 25px;
    padding: 1rem 0;
    font-family: inherit;
  }
  .form__div {
    height: 45px;
  }
  .term-and-service-container {
    padding: 10px 0;
    /* padding-top: 25px; */
    font-size: 12px;
    /* background: greenyellow; */
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 3px !important;
  }
  .checkmark:after {
    content: "✔";
    font-size: 0.5em;
    color: #fff;
    padding-left: 6px;
    padding-top: 2px;
    position: absolute;
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 320px) {
  .form__title {
    font-weight: 500;
    font-size: 20px;
    padding: 1rem 0 0 0;
    font-family: inherit;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>