var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"related background container-lg round-lg"},[_c('h1',{staticClass:"related-title"},[_vm._v("Related Posts")]),_vm._l((10),function(i){return _c('NewsCard',{key:i,attrs:{"article":{
      title: 'Hey',
      slug: 'hey',
      country: 'BF',
      summary: 'hey',
      fullText: 'hey',
      source: 'hey',
      sourceName: 'lol',
      publishedDate: 'sddssd',
    }}})}),_c('div',{staticClass:"article-cards-wrapper"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }