<template>
  <div class="border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t('interests') }}</h6>
    </div>

    <div class="box-body">
      <form @submit.prevent="createInterrest" v-if="!showInter">
        <div class="border rounded bg-white mb-3">
          <div class="box-body px-3 pt-3 pb-0">
            <div class="row">
              <div class="col-sm-12 mb-4">
                <label id="TO" class="form-label"
                  >{{ $t('name') }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <select
                    class="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    v-model="form.name"
                    required
                  >
                    <option selected disabled>{{ $t('choose') }}...</option>
                    <option
                      v-for="interrest in interrestList"
                      :key="interrest.id"
                      :value="interrest.code"
                      >{{ $t(interrest.code) }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
          </div>
          <button
            :disabled="loading"
            type="submit"
            class="btn btn-success ml-3"
          >
            <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
            {{ loading ? '' : $t('save') }}
          </button>

          <button
            type="submit"
            @click="hideInterrests"
            class="btn btn-danger ml-3"
          >
            {{ $t('cancel') }}
          </button>
        </div>
      </form>
      <div class="card-body" v-else>
        <div v-if="inters.length">
          <div class="col-12 mb-2">
            <button
              type="button"
              class="btn btn-success m-1 tag"
              :style="{ backgroundColor: randomColor() }"
              v-for="interrest in inters"
              :key="interrest.id"
            >
              {{ $t(interrest.name) }}
              <span
                @click="deleteInterrest(interrest.id)"
                class="badge badge-light"
                ><span class="feather-x"></span
              ></span>
            </button>
          </div>
        </div>
        <button type="button" @click="showInterrests" class="btn btn-primary">
          {{ $t('addInterrest') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  async mounted() {
    this.inters = this.interrests;
    await this.loadInterrestList;
    this.interrestList = this.getInterrestList;
  },
  computed: {
    ...mapGetters(['getInterrestList']),
    ...mapActions(['loadInterrestList']),
  },
  data() {
    return {
      form: {},
      inters: {},
      loading: false,
      showInter: true,
      interrestList: {},
      colors: [
        'gray',
        'blue',
        'green',
        '#2471A3',
        'purple',
        'teal',
        'orange',
        'brown',
        'deep-orange',
        'blue-grey',
        '#2471A3',
      ],
    };
  },
  methods: {
    showInterrests() {
      this.showInter = false;
    },
    hideInterrests() {
      this.showInter = true;
    },
    randomColor(id) {
      const r = () => Math.floor(256 * Math.random());
      //return `rgb(${r()}, ${r()}, ${r()})`;
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    createInterrest() {
      this.loading = true;
      this.$store
        .dispatch('createInterrest', {
          interrest: this.form,
        })
        .then((success) => {
          this.loading = false;
          this.showInter = true;
          this.form = {};
          this.$noty.success(this.$i18n.t('interestAdded'));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
    deleteInterrest(interrest_id) {
      this.$store
        .dispatch('deleteInterrest', {
          interrest_id: interrest_id,
        })
        .then((success) => {
          this.showInter = true;
          this.$noty.success(this.$i18n.t('interestDeleted'));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  name: 'AddInterrests',
  props: {
    interrests: {},
  },
};
</script>
<style>
.tag {
  text-transform: capitalize;
  border-radius: 10px;
  border: 1px solid transparent;
}
</style>
