<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div
      class="location"
      v-bind="$attrs"
      v-if="!editing && livesIn && !getProfileEditingMode"
    >
      <div class="icon">
        <img
          src="@/assets/images/profile/thumbtack.svg"
          alt=""
          width="14"
          height="14"
        />
      </div>
      <p class="info">
        <span class="label">{{ $t("LivesIn") }}: </span>
        <span class="text">{{ livesIn }}</span>
      </p>
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div
      class="location"
      v-bind="$attrs"
      v-if="!editing && getProfileEditingMode"
    >
      <div class="icon">
        <img
          src="@/assets/images/profile/thumbtack.svg"
          alt=""
          width="14"
          height="14"
        />
      </div>
      <p class="info">
        <span class="label">{{ $t("LivesIn") }} </span>
        <span class="text"><span v-if="livesIn">: </span>{{ livesIn }}</span>
      </p>
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div class="location" v-if="editing">
      <select
        class="col-md-5 mr-2 profile-controls"
        name="locations"
        @input="handleSelect($event)"
        v-model="countryCode"
      >
        <option
          v-for="(country, index) in foreignCountries"
          :key="index"
          :value="country.code"
          :selected="
            getProfile.profile.residenceContry === country.code
              ? 'selected'
              : ''
          "
        >
          {{ country.name }}
        </option>
      </select>
      <save-cancel-button-group @save="save" @cancel="cancel()" />
    </div>
  </div>
</template>

<script>
import EditButton from "../../buttons/EditButton";
import SaveCancelButtonGroup from "../../buttons/SaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../../../../i18n";
export default {
  async mounted() {
    this.countryCode = this.getProfile.profile
      ? this.getProfile.profile.residenceContry
      : "";
    await this.getForeignCountries();
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getProfileEditingMode",
      "getForeignCountriesList",
    ]),
  },
  props: {
    livesIn: String,
  },
  components: {
    EditButton,
    SaveCancelButtonGroup,
  },
  data() {
    return {
      countries: null,
      foreignCountries: [],
      countriesLists: [],
      editing: false,
      countryCode: "",
    };
  },
  methods: {
    ...mapActions(["loadCountriesList", "loadForeignCountriesList"]),
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    save() {
      this.$store
        .dispatch("updateUserProfile", {
          residenceContry: this.countryCode,
        })
        .then(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.livesIn = this.fortmatCountry(this.countryCode);
          this.$noty.success(this.$i18n.t("success"));
          this.editing = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    async getForeignCountries() {
      var lang = i18n.locale;
      await this.loadForeignCountriesList({ lang });
      this.getForeignCountriesList.forEach((country) => {
        this.foreignCountries.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: auto !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: 1px solid var(--first-color) !important;
  border-radius: 0.3rem !important;
  outline: none !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>


<style lang="scss" scoped>
.location {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .icon {
    line-height: 0;
  }

  .info {
    // flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    // text-align: center;

    .label {
      color: #666668;

      @include max-width(1125) {
        display: none;
      }

      @include max-width(991) {
        display: inline;
      }

      @include max-width(510) {
        display: none;
      }
    }

    .text {
      color: #48484a;
    }
  }
}
</style>
