<template>
  <div class="placeholder">
    <div v-if="users" class="wow">
      <div class="flex-row" v-if="showSearch">
        <form
          class="form-inline-2 searchbar"
          :class="{ offsetLayoutMargin: authUser.is_verified === 0 }"
          v-if="motrerSearch"
          @submit.prevent="lookupUser"
        >
          <img
            src="@/assets/svg/search.svg"
            alt="Search"
            width="20"
            height="20"
            class="search-img"
          />
          <vue-typeahead-bootstrap
            v-model="query"
            :data="connections"
            :serializer="(item) => item.login"
            highlightClass="special-highlight-class"
            @hit="selecteduser = $event"
            :minMatchingChars="3"
            :autocomplete="false"
            :placeholder="$t('searchUsers')"
            inputClass="search-input-2"
            class="search-input-2"
            :disabledValues="selecteduser ? [selecteduser.login] : []"
            @input="lookupUser"
          >
            <template slot="suggestion" slot-scope="{ data, htmlText }">
              <div class="d-flex align-items-center">
                <img
                  class="rounded-circle"
                  :src="data.avatar_url"
                  style="width: 40px; height: 40px"
                />

                <span class="ml-4" v-html="htmlText"></span>
                {{ data.avatar }}
                <i class="ml-auto fab fa-github-square fa-2x"></i>
              </div>
            </template>
          </vue-typeahead-bootstrap>
          <div class="clear-icon-container">
            <button
              class=""
              @click="clearSearch"
              :style="`opacity:${query ? 1 : 0}`"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                data-supported-dps="16x16"
                fill="currentColor"
                class="mercado-match"
                width="16"
                height="16"
                focusable="false"
              >
                <path
                  d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <div
        class="fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div
          class="mainBackground"
          :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
        >
          <div class="full-view" :class="{ emptyCard: users }">
            <div class="flex-row flex-start" v-if="users.length">
              <div
                class="col-xl-3 col-lg-4 col-md-6 mb-3"
                v-for="(user, i) in users"
                :key="i"
              >
                <ConnectionCard
                  :showFromOrigin="true"
                  bgColor="#BAE0C2"
                  :user="user"
                  :type="type"
                  :index="i"
                  :received="received"
                  :sent="sent"
                  :users="users"
                />
              </div>
              <LoadMore
                class="full-with"
                v-if="getShowDiasporaLoadMore"
                @click.native="loadMore"
                :loadingMore="loadingMore"
              />
            </div>

            <div class="flex-row no-search" v-else>
              <transition name="slide">
                <NoDataFound
                  v-if="type === 'all'"
                  :svg="require('../../assets/images/noData/no_connection.svg')"
                  :title="$t('noConnectionFound')"
                  :text="$t('noConnectionFoundText')"
                  :buttonText="$t('resetFilters')"
                  :buttonType="2"
                  :hasTitle="true"
                  :hasText="true"
                  :hasButton="false"
                />

                <NoDataFound
                  v-else-if="type === 'connected'"
                  :svg="require('../../assets/images/noData/no-requests.svg')"
                  :title="$t(emptyMessage)"
                  :text="$t('ConnectWithLikeMinded')"
                  :buttonText="$t('findConnections')"
                  :buttonLink="`/${$i18n.locale}/${this.$i18n.t(
                    'routes.network'
                  )}`"
                  :buttonType="1"
                  :hasTitle="true"
                  :hasText="true"
                  :hasButton="true"
                />

                <NoDataFound
                  v-else-if="type === 'requested' || type === 'requested'"
                  :svg="require('../../assets/images/noData/no_diaspora.svg')"
                  :title="$t('noUnansweredRequests')"
                  :text="$t('ConnectWithLikeMinded')"
                  :buttonText="$t('findConnections')"
                  :buttonLink="`/${$i18n.locale}/${this.$i18n.t(
                    'routes.network'
                  )}`"
                  :buttonType="1"
                  :hasTitle="true"
                  :hasText="true"
                  :hasButton="true"
                />

                <NoDataFound
                  v-else-if="type === 'blocked'"
                  :svg="require('../../assets/images/noData/no_blocked.svg')"
                  :title="$t('noBlockedRequests')"
                  :text="$t('noBlockedRequestsText')"
                  :buttonText="$t('resetFilters')"
                  :buttonType="2"
                  :hasTitle="true"
                  :hasText="true"
                  :hasButton="false"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <NoDataFound
        :svg="require('../../assets/images/noData/no_diaspora.svg')"
        :title="$t('noUsersFound') + ' :('"
        buttonText="Reset Filters"
        :buttonLink="`/${$i18n.locale}/diaspora`"
        :hasTitle="true"
        :hasText="false"
        :hasButton="false"
      />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "../../i18n";
import LoadMore from "../Loaders/LoadingMore.vue";
import ConnectionCard from "../Startups/ConnectionCard.vue";
import NoDataFound from "../NoDataFound/Version-2.vue";
import { io } from "socket.io-client";
export default {
  created() {
    this.savedUsers = this.users;
    this.motrerSearch = this.showSearch;
  },
  mounted() {
    // alert(this.users.length);
    // this.sockets = io(APIURLS.LINKEDINSERVERLINK);
    // this.sockets.on("request", (data) => {
    //   if (data.user_id === this.authUser.id) {
    //     this.getNotifs();
    //   }
    // });
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserCover",
      "getRerender",
      "getUserNotifications",
      "getUserLang",
      "getUsers",
      "getDiaspora",
      "getDiasporaPage",
      "getDiasporaTotalCount",
      "getShowDiasporaLoadMore",
      "getDiasporaLimit",
      "getDiasporaSearchFilters",
      "getUserTalents"
    ]),
  },
  data() {
    return {
      sockets: {},
      check: null,
      showFilter: false,
      search: {},
      since: null,
      query: "",
      motrerSearch: null,
      selecteduser: null,
      connections: [],
      savedUsers: [],
      notFound: false,
      originContry: "",
      residenceContry: "",
      cover: "",
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions([
      "openChatBoxWithUser",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "loadUserNotifications",
      "loadMoreDiaspora",
      "loadUserExpertises"
    ]),
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.$store.commit("setUserNewNotifications", this.getUserNotifications);
    },
    clearSearch() {
      this.query = "";
      this.$store.commit("setDiaspora", this.savedUsers);
      this.$store.commit("setShowDiasporaLoadMore", true);
      this.$store.commit("setShowPagination", true);
    },
    lookupUser: debounce(function () {
      if (this.query) {
        fetch(
          `${APIURLS.USERS}lookupUser/${this.query}/user/${this.authUser.id}`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (!data.items.length) {
              this.notFound = true;
            }
            this.connections = data.items;
            // eslint-disable-next-line vue/no-mutating-props
            this.users = data.items;
            this.$store.commit("setDiaspora", data.items);
            this.$store.commit("setShowDiasporaLoadMore", false);
          });
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.users = this.savedUsers;
        this.$store.commit("setDiaspora", this.savedUsers);
      }
    }, 500),
    // lookupUser: debounce(function() {
    //   // in practice this action should be debounced
    //   fetch(`https://api.github.com/search/users?q=${this.query}`)
    //     .then((response) => {
    //       return response.json();
    //     })
    //     .then((data) => {
    //       this.users = data.items;
    //       this.connections = data.items;
    //     });
    // }, 500),
    searchDiaspora(user_id) {
      if (this.search.residenceContry) {
        this.search.residenceContry = this.countryCode(
          this.search.residenceContry
        );
      }
      if (this.search.originContry) {
        this.search.originContry = this.countryCode(this.search.originContry);
      }
      const data = {
        residenceContry: this.search.residenceContry,
        birthCountry: this.search.originContry,
        user_id,
      };
      if (this.search.originContry || this.search.residenceContry) {
        Axios.post(`${APIURLS.CONNECTIONS}searchDiaspora`, data)
          .then(({ data }) => {
            //this.connections = data.users;
            // eslint-disable-next-line vue/no-mutating-props
            this.users = data.users;
          })
          .catch((error) => {
            this.$noty.error(error.response.data.message);
          });
      }
    },
    async getDate(user_id) {
      return await Axios.post(`${APIURLS.CONNECTIONS}getDate`, {
        from: this.authUser.id,
        to: user_id,
      })
        .then(({ data }) => {
          this.since = moment(data.response.since).format("DD/MM/YYYY");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    // async getCountry(country){
    //   return await Axios.post(`${APIURLS.CONNECTIONS}getDate`, {
    //     from: this.authUser.id,
    //     to: user_id,
    //   })
    //     .then(({ data }) => {
    //       this.since = moment(data.response.since).format("DD/MM/YYYY");
    //     })
    //     .catch((error) => {
    //       this.$noty.error(error.response.data.message);
    //     });
    // },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, this.getUserLang, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, this.getUserLang));
    },
    showFilters() {
      this.showFilter = true;
      this.motrerSearch = false;
    },
    clearFilters() {
      this.search.residenceContry = null;
      this.search.originContry = null;
      // eslint-disable-next-line vue/no-mutating-props
      this.users = this.savedUsers;
      this.$store.commit("setUsers", this.savedUsers);
    },
    HideFilters() {
      this.showFilter = false;
      this.motrerSearch = true;
      this.search = {};
    },
    async connect(user_id, index) {
      let countryOfOrigin, livesIn;
      let lang = this.getUserLang;
      let originCode = this.authUser.profile
        ? this.authUser.profile.birthCountry
        : "";
      countryOfOrigin = await this.getAFricanCountryByLang({
        code: originCode,
        lang,
      });
      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";

      livesIn = await this.getForeignCountryByLang({
        code: livesInCode,
        lang,
      });

      let expertise = '';
      let accept_link = `/${this.$i18n.locale}/${this.$i18n.t('routes.network')}/${this.$i18n.t('routes.pendingRequests')}`;
      let profile_link = `/${this.getUserLang}/profile/${this.authUser.username}`;

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });


      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }
     
      Axios.post(`${APIURLS.CONNECTIONS}request`, {
        from: this.authUser.id,
        to: user_id,
        name: this.authUser.name + " " + this.authUser.firstname,
        lang,
        countryOfOrigin,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        livesIn,
        expertise,
        profile_link,
        accept_link
      })
        .then(({ data }) => {
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$store.commit("increaseSentRequests");
          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    async accept(user_id, index) {
      let data = {
        receiver_name:'',
        from: user_id,
        to: this.authUser.id,
        name: this.authUser.name + " " + this.authUser.firstname,
        lang: this.getUserLang,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
       
      };
      Axios.post(`${APIURLS.CONNECTIONS}accept`, data)
        .then(async ({ data }) => {
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$store.commit("decreaseReceivedRequests");
          this.$store.commit("increaseConnected");
          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    unBlock(user_id, index) {
      let data = {
        from: this.authUser.id,
        to: user_id,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
      };

      Axios.post(`${APIURLS.CONNECTIONS}unblock`, data)
        .then(({ data }) => {
          Axios.get(
            `${APIURLS.LINKEDINSERVERLINK}/request/` + this.authUser.id
          );
          this.$store.commit("decreaseBlocked");
          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    cancel(user_id, index, received) {
      let data = {};
      if (received) {
        data = {
          from: user_id,
          to: this.authUser.id,
          avatar: this.authUser.avatar,
          sender_id: this.authUser.id,
        };
      } else {
        data = {
          from: this.authUser.id,
          to: user_id,
          sender_avatar: this.authUser.avatar,
          sender_id: this.authUser.id,
        };
      }
      Axios.post(`${APIURLS.CONNECTIONS}cancel`, data)
        .then(({ data }) => {
          Axios.get(
            `${APIURLS.LINKEDINSERVERLINK}/request/` + this.authUser.id
          );
          if (received) {
            this.$store.commit("decreaseReceivedRequests");
          } else {
            this.$store.commit("decreaseSentRequests");
          }

          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    checkConnection(user_id) {
      Axios.get(
        APIURLS.CONNECTIONS +
          "connected/user/" +
          user_id +
          "/auth_user/" +
          this.authUser.id
      )
        .then((response) => {
          this.check = response.data;
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async loadMore() {
      this.loadingMore = true;
      let page = this.getDiasporaPage + 1;
      let user_id = this.authUser.id;
      await this.loadMoreDiaspora({
        data: {
          lang: this.$i18n.locale,
          user_id,
          page,
          limit: this.getDiasporaLimit,
          filters: this.getDiasporaSearchFilters,
        },
      });

      this.$store.commit("setDiasporaPage", page);
      if (this.getDiaspora.length === this.getDiasporaTotalCount) {
        this.$store.commit("setShowDiasporaLoadMore", false);
      }
      this.loadingMore = false;
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Connections",
  components: {
    //Searchbar,
    LoadMore,
    ConnectionCard,
    NoDataFound,
  },
  props: {
    users: {},
    type: {
      type: String,
      default: "all",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    sent: {
      type: Boolean,
      default: false,
    },
    received: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: "NoConnections",
    },
  },
};
</script>


<style scoped>
.mainBackground {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  padding: 0 1%;
  margin: 0.8% 0;
  overflow-y: scroll;

  /* background-color: pink; */
  margin: 0;
  padding: 0;
  margin-top: 55px;
}

.form-inline-2 {
  width: 100% !important;
}
.clear-icon-container {
  position: relative;
  right: 30px;
  z-index: 9999;
}
.clear-icon-container button {
  background: rgb(176, 187, 186);
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 1000px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.clear-icon-container :hover {
  background: rgb(146, 146, 146);
}
.clear-icon-container button svg {
  color: rgba(0, 0, 0, 0.6);
  width: 10px;
  height: 10px;
}

img.search-img {
  width: 20px !important;
  height: 20px !important;
  padding: 0;
}

.searchbar {
  position: relative;
  box-sizing: border-box;
  padding: 0.3rem !important;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  height: 55px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
}

.search-img {
  margin-left: 1.5rem;
}
.searchbar input[type="text"] {
  border: none !important;
  font-size: 0.85rem;
  padding: 0 1.25rem !important;
  color: #000;
}
.searchbar input[type="text"]::placeholder {
  color: #8c8c8c;
  font-size: 0.85rem;
}
.searchbar input[type="text"]:focus {
  outline: none !important;
}
.form-control:focus {
  outline: none !important;
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-search {
  position: absolute;
  right: 10px;
  background-color: #39a251;
  color: #fff;
  border: 1px solid rgb(255 255 255 / 27%);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.7rem 3.5rem;
}
.btn-search:hover {
  background: #18792f;
}

@media (max-width: 991px) {
  .searchbar {
    padding: 0.7rem;
  }
  .search-img {
    padding: 0 0.5rem 0 0;
  }

  .searchbar input[type="text"] {
    padding: 0 0.5rem 0 0;
    font-size: 16px;
  }
  .btn-search {
    display: none;
    /* font-size: .875rem;
    padding: .65rem 2.5rem; */
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    height: 100vh;
  }
  div#home {
    /* background: red; */
    display: flex;
    align-items: center;
    /* max-width: min(78vw, 1140px); */
    max-width: 990px;
  }
  .mainBackground {
    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 1rem;
    padding: 0 0%;
    margin: 0.8% 0;
    /* min-width: min(78vw, 1140px) !important;  */
    width: 100%;
    min-height: 80vh;
  }
  .wow {
    width: 100%;
  }
  .flex-start {
    display: flex;
    align-items: flex-start;
    /* background:red; */
    align-items: flex-start !important;
    /* background: red; */
    justify-content: flex-start !important;
    height: 100%;
  }
  .full-view {
    height: 100%;
  }

  .full-with {
    width: 100%;
  }
  .emptyCard {
    height: 70vh;
    /* background-color: pink; */
  }
  /* individual cards */
  .col-xl-3.col-lg-4.col-md-6.mb-3 {
    max-height: 400px;
  }
  .flex-row.no-search {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
  }
  .alert.alert-info.m-4.text-center {
    /* height: 300px; */
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    background: white;
    height: 100%;
    width: 100%;
  }
  .alert-card {
    background-color: transparent;
    max-width: 100%;
    height: 200px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  /* .flex-row[data-v-4dde93fb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
} */
}
@media (max-width: 574px) {
  .searchbar {
    padding: 0 !important;
    /* border: 1px solid red; */
  }
  .alert.alert-info.m-4.text-center {
    /* height: 300px; */
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    background: white;
    height: 100%;
    width: 100%;
    margin: 0 !important;
  }
  .alert-card {
    background-color: transparent;
    max-width: 100vw;
    height: 60vh;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  .flex-row.no-search {
    background-color: transparent;
    width: 100%;
    height: 70vh;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .flex-start {
    width: 100%;
  }
  /* .btn-search {
    font-size: .75rem;
    padding: .5rem 1.2rem;
    border-radius: .75rem;
  } */
  .searchbar {
    height: 45px;
    margin-top: 10px;
    position: fixed;
    z-index: 99;
    top: 55px;
  }
  img.search-img {
    width: 20px !important;
    height: 20px !important;
    padding: 2px;
    left: 0;
    position: absolute;
    z-index: 9999999999999;
  }
  .form-inline-2 {
    width: 95% !important;
    transform: translatex(2.5%);
    /* position: relative; */
  }
  .search-input-2 {
    padding-left: 40px;
  }
  .clear-icon-container {
    position: relative;
    right: 5px;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
  }
}

.widget-author {
  text-align: center;
  padding: 15px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

.widget-author .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background-color: gray;
}

.widget-author .cover-img img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100px;
}

.widget-author .profile-img {
  margin-bottom: 26px;
}

.widget-author .profile-img a {
  border-radius: 50%;
  position: relative;
}

.widget-author .profile-img a img {
  box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.widget-author .author-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-author .author-name a {
  color: #000000;
}

.widget-author .author-location {
  font-size: 14px;
  color: #9e9faf;
  margin-bottom: 5px;
}

.widget-author .author-badge {
  margin-bottom: 45px;
}

.widget-author .author-badge li {
  display: inline-block;
  margin-right: 8px;
}

.widget-author .author-badge li:last-child {
  margin-right: 0;
}

.widget-author .author-badge a {
  font-size: 20px;
  color: #ffffff;
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  position: relative;
}

.widget-author .author-badge a:before {
  content: "";
  height: 5px;
  width: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 8px;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}

.widget-author .author-badge .profile-circle {
  background-color: #e7ecf4;
  font-size: 16px;
  font-weight: 700;
  color: #8f8f8f;
}

.widget-author .author-statistics li {
  display: inline-block;
  position: relative;
  line-height: 1;
}

.widget-author .author-statistics li:after {
  content: "";
  height: 31px;
  width: 1px;
  background-color: #e8e8e8;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-author .author-statistics li:last-child {
  margin-right: 0;
}

.widget-author .author-statistics li:last-child:after {
  display: none;
}

.widget-author .author-statistics li a {
  text-align: center;
  padding: 0 15px 0 12px;
}

.widget-author .author-statistics .item-number {
  font-weight: 400;
  color: #9e9faf;
  display: block;
  margin-bottom: 5px;
}

.widget-author .author-statistics .item-text {
  font-weight: 400;
  color: #9e9faf;
  font-size: 12px;
}

.special-input-class {
  background-color: black !important;
  color: white !important;
}
.special-highlight-class {
  font-weight: 900;
  color: #585656;
}

.connection {
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important; */
  margin: 10px 0px;
  overflow: hidden !important;
}
.connectionTitle {
  height: 25px !important;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.network-item-footer {
  background-color: rgba(0, 0, 0, 0.029);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}
.footer-btn-container {
  width: 100%;
  margin: 5px;
}
.footer-btn-container button {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  opacity: 0.5;
}
.footer-btn-container a {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  opacity: 0.5;
}
.footer-btn-container i {
  padding: 0 10px;
}
.footer-btn-container a:hover {
  opacity: 1;
}
.footer-btn-container button:hover {
  opacity: 1;
}
.residentialInfo {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.057);
  border-top: 2px solid rgba(0, 0, 0, 0.057);
}
.residentialInfo > li > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px !important;
  flex-direction: row;
  font-size: 14px;

  padding: 10px 10px !important;
}
.residentLabel {
  font-size: 14px;
}
.residentCityName {
  font-size: 14px;
  font-weight: bolder !important;
  color: rgba(0, 0, 0, 0.708) !important;
  padding-left: 50px;
  /* text-align: right !important; */
}
@media (max-width: 576px) {
  .flex-row {
    margin-top: 45px;
    padding-top: 0;
    padding-bottom: 50px;

    margin-top: 0px;

    justify-content: flex-start;
  }
  .wow {
    padding-top: 10px;
  }
  .col-xl-3.col-lg-4.col-md-6.mb-3 {
    width: 50%;
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: 0rem !important;
  }
  .offsetLayoutMargin {
    top: 86px;
  }
  .showNotVerifiedNotfication {
    margin-top: 95px !important;
  }
}
</style>
