<template>
  <div class="#">
    <div class="login-19">
      <div class="login-19">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-5 col-sm-12 bg-color-15 none-992 bg-img"></div>
            <div class="col-lg-7 col-sm-12 align-self-center form-section">
              <div class="form-inner py-2 mx-4">
                <h3 class="text-white">{{ $t("FinalSteps") }}</h3>
                <div
                  class="alert alert-danger text-center mx-4"
                  role="alert"
                  v-if="errors.length"
                >
                  <ul class="mx-4">
                    <li v-for="(erreur, index) in errors" :key="index">
                      {{ erreur }}
                    </li>
                  </ul>
                </div>
                <form class="px-3" @submit.prevent="submit">
                  <div class="row col-md-12">
                    <div class="form-group form-box col-md-6">
                      <label class="text-white"
                        >{{ $t("startupName") }}
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="input-text form-control"
                        required
                        :placeholder="$t('startupName')"
                        v-model="form.startupname"
                      />
                    </div>
                    <div class="form-group form-box col-md-6">
                      <label class="text-white"
                        >{{ $t("Location") }}
                        <span class="text-danger">*</span>
                      </label>
                      <VueMultiselect
                        v-model="form.location"
                        :options="countriesLists"
                        :multiple="false"
                        track-by="name"
                        :custom-label="customLabel"
                        selectLabel="+"
                        deselectLabel="-"
                        required
                      />
                    </div>
                  </div>
                  <div class="row col-md-12">
                    <div class="form-group form-box col-md-6">
                      <label class="text-white"
                        >{{ $t("sector") }}
                        <span class="text-danger">*</span>
                      </label>
                      <VueMultiselect
                        v-model="form.sector"
                        :options="allSectors"
                        :multiple="false"
                        track-by="name"
                        :custom-label="customLabel"
                        selectLabel="+"
                        deselectLabel="-"
                        required
                      />
                    </div>
                    <div class="form-group form-box col-md-6">
                      <label class="text-white"
                        >{{ $t("foundingYear") }}
                        <span class="text-danger">*</span>
                      </label>
                      <Datepicker
                        v-model="form.foundingYear"
                        :language="fr"
                        :clear-button="false"
                        input-class="input-text form-control"
                        :required="true"
                        :typeable="true"
                        :disabledDates="disabledDates"
                        minimum-view="year"
                        :format="DatePickerFormat"
                        :placeholder="$t('foundingYear')"
                      />
                    </div>
                  </div>
                  <div class="row col-md-12">
                    <div class="form-group form-box col-md-6">
                      <label class="text-white"
                        >{{ $t("numbInTeam") }}
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        min="1"
                        class="input-text"
                        required
                        v-model="form.numbInTeam"
                        :placeholder="$t('numbInTeam')"
                      />
                    </div>
                    <div class="form-group form-box col-md-6">
                      <label class="text-white"
                        >{{ $t("typeofTalents") }}
                        <span class="text-danger">*</span>
                      </label>
                      <VueMultiselect
                        v-model="form.typeofTalents"
                        :options="types"
                        :multiple="true"
                        track-by="name"
                        :custom-label="customLabel"
                        selectLabel="+"
                        deselectLabel="-"
                        required
                      />
                    </div>
                  </div>
                  <div class="row col-md-12">
                    <div class="form-group form-box col-md-12">
                      <label class="text-white"
                        >{{ $t("talentsNeeded") }}
                        <span class="text-danger">*</span>
                        (max:5)</label
                      >
                      <VueMultiselect
                        v-model="form.talents"
                        :options="allTalents"
                        :multiple="true"
                        track-by="name"
                        :custom-label="customLabel"
                        :max="10"
                        selectLabel="+"
                        deselectLabel="-"
                        required
                      />
                    </div>
                    <div class="form-group form-box col-md-12">
                      <label class="text-white">{{ $t("website") }} </label>
                      <input
                        type="text"
                        class="input-text form-control"
                        :placeholder="$t('website')"
                        v-model="form.website"
                      />
                    </div>
                    <div class="form-group form-box col-md-12">
                      <label class="text-white"
                        >{{ $t("descriptionStartup") }}
                        <span class="text-danger">*</span>
                        (Elevator Pitch)</label
                      >
                      <textarea
                        v-model="form.description"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group clearfix">
                    <button
                      :disabled="loading"
                      type="submit"
                      class="
                        btn btn-primary btn-block
                        text-uppercase text-bold
                        p-3
                      "
                    >
                      <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                      {{ loading ? "" : $t("JoinNow") }}
                    </button>
                  </div>
                </form>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../../i18n";
import store from "../../../store/";
import APIURLS from "../../../constant";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";
import VueMultiselect from "vue-multiselect";
export default {
  beforeRouteEnter: (to, from, next) => {
    if (!store.getters.getRegistrationForm.profileType) {
      return next(`/${i18n.locale}/${i18n.t("routes.register")}`);
    }
    if (store.getters.authUser) {
      return next(`/${i18n.locale}/${i18n.t("routes.myActivity")}`);
    }
    next();
  },
  metaInfo() {
    return {
      title: `Bantaba | ${this.$i18n.t("register")} | Startup`,
    };
  },
  watch: {
    async $route() {
      await this.getCountries();
    },
  },

  async mounted() {
    var lang = i18n.locale;
    await this.getCountries();
    await this.loadSectorsList({ lang });
    await this.loadTalents({ lang });
    this.sectors = this.getSectorstList;
    this.talents = this.getTalents;
    this.sectors.forEach((sector) => {
      this.allSectors.push({
        id: sector.id,
        code: sector.code,
        name: sector.name,
      });
    });

    this.types = [
      {
        id: 1,
        code: "Invest",
        name: i18n.t("Investment"),
      },
      {
        id: 2,
        code: "Consulting",
        name: i18n.t("Consulting"),
      },
      {
        id: 3,
        code: "Mentoring",
        name: i18n.t("Mentoring"),
      },
    ];
    this.talents.forEach((talent) => {
      this.allTalents.push({
        talent_id: talent.id,
        code: talent.code,
        name: talent.name,
      });
    });
    var countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    this.countries = countries.getNames(i18n.locale, { select: "official" });
  },
  computed: {
    ...mapGetters([
      "getSectorstList",
      "getTalents",
      "getRegistrationForm",
      "getCountriesList",
    ]),
  },
  data() {
    return {
      result: null,
      countries: null,
      talents: {},
      allTalents: [],
      types: [],
      allSectors: [],
      countriesLists: [],
      sectors: {},
      disabledDates: {
        from: new Date(),
      },
      DatePickerFormat: "yyyy",
      en: en,
      fr: fr,
      form: {
        name: "",
        email: "",
        password: "",
        sector: "",
        talents: [],
      },
      error: null,
      success: null,
      loading: false,
      errors: [],
    };
  },
  methods: {
    ...mapActions([
      "logUserIn",
      "loadSectorsList",
      "loadTalents",
      "loadCountriesList",
    ]),
    async getCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.countriesLists.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    customLabel(option) {
      return `${option.name}`;
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    submit() {
      this.errors = [];
      if (!this.form.startupname) {
        this.errors.push(
          this.$i18n.t("startupName") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.location) {
        this.errors.push(
          this.$i18n.t("location") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.sector) {
        this.errors.push(
          this.$i18n.t("sector") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.foundingYear) {
        this.errors.push(
          this.$i18n.t("foundingYear") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.numbInTeam) {
        this.errors.push(
          this.$i18n.t("numbInTeam") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.typeofTalents) {
        this.errors.push(
          this.$i18n.t("typeofTalents") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.talents.length) {
        this.errors.push(
          this.$i18n.t("talentsNeeded") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.description) {
        this.errors.push(
          this.$i18n.t("descriptionStartup") + " " + this.$i18n.t("required")
        );
      }
      if (!this.getRegistrationForm.profileType) {
        // this.$router.push(`/${i18n.locale}/${i18n.t("routes.register")}`).catch(err => {});
        let url = `/${i18n.locale}/${i18n.t("routes.register")}`;
        if (this.$route.path != url) {
          this.$router.push(url);
        }
      }

      if (
        this.form.startupname &&
        this.form.location &&
        this.form.sector &&
        this.form.foundingYear &&
        this.form.numbInTeam &&
        this.form.typeofTalents &&
        this.form.talents.length &&
        this.form.description
      ) {
        this.loading = true;
        var countries = require("i18n-iso-countries");
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
        // this.form.location = countries.getAlpha2Code(
        //   this.form.location,
        //   i18n.locale
        // );
        this.form.location = this.form.location.code;
        this.form.startupname = this.form.startupname.trim();
        Axios.get(
          APIURLS.STARTUP + "check-startupname/" + this.form.startupname
        ).then((response) => {
          if (!response.data) {
            this.loading = false;
            this.error = true;
            this.errorMsg = `${i18n.t("startupAlreadyExists")}`;
          } else {
            this.$store.commit("setRegister", {
              property: "startupname",
              value: this.form.startupname,
            });
            this.$store.commit("setRegister", {
              property: "slug",
              value: this.convertToSlug(this.form.startupname),
            });

            this.$store.commit("setRegister", {
              property: "location",
              value: this.form.location,
            });

            this.$store.commit("setRegister", {
              property: "talents",
              value: this.form.talents,
            });

            this.$store.commit("setRegister", {
              property: "foundingYear",
              value: this.form.foundingYear,
            });
            this.$store.commit("setRegister", {
              property: "sector",
              value: this.form.sector,
            });

            this.$store.commit("setRegister", {
              property: "numbInTeam",
              value: this.form.numbInTeam,
            });

            this.$store.commit("setRegister", {
              property: "typeofTalents",
              value: this.form.typeofTalents,
            });

            this.$store.commit("setRegister", {
              property: "description",
              value: this.form.description,
            });

            this.$store.commit("setRegister", {
              property: "is_entrepreneur",
              value: 1,
            });
            this.$store.commit("setRegister", {
              property: "website",
              value: this.form.website,
            });
            this.$store.commit("setRegister", {
              property: "is_verified",
              value: 0,
            });

            let data = this.getRegistrationForm;
            data.emailData = {
              subject: this.$i18n.t("thanksForSignup"),
              buttonText: this.$i18n.t("verifyEmail"),
              email: data.email,
              link: `${APIURLS.SITELINK}${i18n.locale}/activate/`,
              text: this.$i18n.t("confirmEmailText"),
              title: this.$i18n.t("thanksForSignup"),
              name: data.name + data.firstname,
              thanks: this.$i18n.t("thanks"),
            };
            data.type = 2;
            data.is_entrepreneur = 1;
            data.is_verified = 0;
            Axios.post(APIURLS.AUTH + "register", data)
              .then((response) => {
                this.loading = false;
                const loginDetails = {
                  email: data.email,
                  password: data.password,
                };
                this.logUserIn(loginDetails)
                  .then((response) => {
                    this.form.email = "";
                    this.form.password = "";
                    this.loading = false;
                    this.$noty.success("Success");
                    let url = `/${i18n.locale}/${i18n.t("routes.myActivity")}`;
                    if (this.$route.path != url) {
                      this.$router.push(url);
                    }
                    // this.$router.push(
                    //   `/${i18n.locale}/${i18n.t("routes.myActivity")}`
                    // ).catch(err => {});
                  })
                  .catch((error) => {
                    this.loading = false;
                    this.error = true;
                    this.errorMsg = error.response.data.message;
                    this.$noty.error(this.errorMsg);
                  });
              })
              .catch((error) => {
                console.log("error", error);
                console.log(
                  "error.response.data.message",
                  error.response.data.message
                );
                this.loading = false;
                this.errorMsg = error.response.data.message;
                this.errors.push(this.errorMsg);
                this.$noty.error(this.errorMsg);
              });
          }
        });
      }
    },
  },
  name: "CompleteStartup",
  components: {
    Datepicker,
    VueMultiselect,
  },
};
</script>
<style>
input[type="text"],
select {
  border: none !important;
}
</style>
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.form-box label {
  font-size: 16px !important;
}
</style>
