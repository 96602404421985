<template>
  <div class="profile-outer-layout">
    <div class="profilePage" v-if="!loading">
      <div class="bg-white basic-section">
        <div class="profile-cover">
          <cld-image v-if="has_cover" class="cover-bg" cloudName:bantaba :publicId="coverImg" alt="Cover">
            <cld-transformation height="300" width="auto" />
            <cld-transformation border="5px_solid_red" radius="20" />
          </cld-image>
          <img v-else :src="coverImg" alt="cover" />
          <div class="edit-cover"></div>
        </div>
        <BasicInfo :startup="startup" />
      </div>
      <div class="bg-green tabBars desktop" v-if="getStartupEditingMode">
        <div @click="setTabtoPublic" :class="{ active: tabActive === `public` }" class="tab left">
          {{ $t("PublicView") }}
        </div>
        <div @click="setTabtoFollowers" :class="{ active: tabActive === `followers` }" class="tab">
          {{ $t("Followers") }}
        </div>
        <div @click="setTabtoDataRoom" :class="{ active: tabActive === `data-room` }" class="tab">
          {{ $t("DataRoom") }}
        </div>
        <span class="stickRight">{{ $t("OnlyYouCanSeeYourFollowers") }}</span>
      </div>
      <div class="bg-white tabBars mobile">
        <div @click="setTabtoPublic" :class="{ active: tabActive === `public` }" class="tab left">
          {{ $t("aboutUs") }}
        </div>
        <div @click="setTabtoTeam" :class="{ active: tabActive === `team` }" class="tab">
          {{ $t("team") }}
        </div>
        <div @click="setTabtoFollowers" :class="{ active: tabActive === `followers` }" class="tab">
          {{ $t("Followers") }}
        </div>
        <!--<div
          @click="setTabtoDataRoom"
          :class="{ active: tabActive === `data-room` }"
          class="tab"
        >
          {{ $t("DataRoom") }}
        </div>-->
      </div>
      <div v-if="tabActive === `public`" class="container bg-white cover-video" :class="{
        hideVideoContainer:
          getStartupEditingMode === false ||
          this.videoLink === null ||
          this.videoLink === ``,
      }">
        <template v-if="!getStartupEditingMode">
          <IntroVideo v-if="startup.video" class="media" :link="videoLink" />
        </template>
        <template v-if="getStartupEditingMode">
          <edit-button class="edit-button reposition" v-if="showEditButtons && !editing" @click.native="showForm"
            text="Edit" />
          <IntroVideo v-if="startup.video && !editing" class="media" :link="videoLink" />
          <div class="upload-a-video" v-if="!startup.video || editing">
            <div @click="uploadingALink" class="upload-link-container">
              <img v-if="!isUploadingLink" src="@/assets/images/playbutton.svg" />
              <p v-if="!isUploadingLink && videoLink === ``">upload a video</p>
            </div>
            <div v-if="isUploadingLink" class="upload-link-container">
              <input class="input-link" placeholder="Enter Youtube URL" type="text" v-model="videoLink" />
              <div class="upload-button-container">
                <button class="save-link" @click="uploadLink">
                  {{ $t("save") }}
                </button>
                <button class="cancel-link" @click="hideForm">
                  {{ $t("cancel") }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="full-width">
        <div v-if="tabActive === `public`" class="fluid-container about-section"
          :class="{ fixHeightSize: showEditButtons }">
          <About :text="getStartupBio" />
          <Overview :startup="startup" :seekingFunding="seekingFunding" />
        </div>
        <div class="container2 data-room-section follower-section" v-if="tabActive === `data-room`">
          <Tab :tab-content="dataRoomTabHead" />
        </div>
        <div class="fluid-container other-section" :class="{ fixHeightSize: showEditButtons }"
          v-if="tabActive === `public`">
          <TractionSection :tractionData="startup.traction" v-if="tabActive != `data-room`" />

          <Talents :talents="startup.talents" :startup_id="startup.id" v-if="tabActive === `public`" />
          <!-- <SliderCarousel v-if="tabActive === `public`" /> -->
          <Team :user_id="user_id" :startup_id="startup_id" v-if="tabActive === `team` || tabActive === `public`" />
        </div>
        <div class="container2 follower-section" v-if="tabActive === `followers`">
          <ul v-if="getFollowers.length">
            <li class="follower-card" v-for="follower in getFollowers" :key="follower.name">
              <div class="follower-profile">
                <a @click="viewProfile(follower)">
                  <cld-image class="img-profile rounded-circle avatar" cloudName:bantaba :publicId="follower.avatar"
                    alt="Cover">
                    <cld-transformation height="300" width="auto" />
                    <cld-transformation border="5px_solid_red" radius="20" />
                  </cld-image>
                </a>
              </div>
              <div class="follower-card-details">
                <div class="follower-info">
                  <a @click="viewProfile(follower)" class="link">
                    <h2 v-if="follower.type === 1">
                      {{
                          (follower.firstname ? follower.firstname : "") +
                          " " +
                          follower.name
                      }}
                    </h2>
                    <h2 v-if="follower.type === 2">
                      {{ follower.startup ? follower.startup.startupname : "" }}
                    </h2>
                  </a>
                  <p>{{ fortmatCountry(follower.profile.residenceContry) }}</p>
                  {{ follower.user }}
                </div>
                <div class="f-chat" @click="openChatBoxWithUser(follower)">
                  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_dd_31:232)">
                      <circle cx="35" cy="33" r="31" fill="#ACE2C7" />
                      <path
                        d="M54.5185 31.8519C54.5185 40.7294 46.2932 47.926 36.1481 47.926C34.3287 47.9284 32.5167 47.6922 30.7587 47.2233C29.4177 47.903 26.3384 49.2073 21.1579 50.0569C20.6987 50.1304 20.3496 49.6528 20.531 49.2257C21.3439 47.306 22.0787 44.7479 22.2992 42.4149C19.4862 39.5904 17.7778 35.8934 17.7778 31.8519C17.7778 22.9744 26.0031 15.7778 36.1481 15.7778C46.2932 15.7778 54.5185 22.9744 54.5185 31.8519ZM29.2593 31.8519C29.2593 31.2429 29.0173 30.6588 28.5867 30.2282C28.156 29.7975 27.572 29.5556 26.963 29.5556C26.3539 29.5556 25.7699 29.7975 25.3392 30.2282C24.9086 30.6588 24.6667 31.2429 24.6667 31.8519C24.6667 32.4609 24.9086 33.045 25.3392 33.4756C25.7699 33.9063 26.3539 34.1482 26.963 34.1482C27.572 34.1482 28.156 33.9063 28.5867 33.4756C29.0173 33.045 29.2593 32.4609 29.2593 31.8519ZM38.4444 31.8519C38.4444 31.2429 38.2025 30.6588 37.7719 30.2282C37.3412 29.7975 36.7572 29.5556 36.1481 29.5556C35.5391 29.5556 34.9551 29.7975 34.5244 30.2282C34.0938 30.6588 33.8518 31.2429 33.8518 31.8519C33.8518 32.4609 34.0938 33.045 34.5244 33.4756C34.9551 33.9063 35.5391 34.1482 36.1481 34.1482C36.7572 34.1482 37.3412 33.9063 37.7719 33.4756C38.2025 33.045 38.4444 32.4609 38.4444 31.8519ZM45.3333 34.1482C45.9423 34.1482 46.5264 33.9063 46.9571 33.4756C47.3877 33.045 47.6296 32.4609 47.6296 31.8519C47.6296 31.2429 47.3877 30.6588 46.9571 30.2282C46.5264 29.7975 45.9423 29.5556 45.3333 29.5556C44.7243 29.5556 44.1402 29.7975 43.7096 30.2282C43.279 30.6588 43.037 31.2429 43.037 31.8519C43.037 32.4609 43.279 33.045 43.7096 33.4756C44.1402 33.9063 44.7243 34.1482 45.3333 34.1482Z"
                        fill="#00853C" />
                    </g>
                    <defs>
                      <filter id="filter0_dd_31:232" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha" />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix"
                          values="0 0 0 0 0.172549 0 0 0 0 0.168627 0 0 0 0 0.164706 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31:232" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha" />
                        <feOffset dx="1" dy="1" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_31:232" result="effect2_dropShadow_31:232" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_31:232" result="shape" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
            </li>
          </ul>
          <NoDataFound v-else :svg="require('../../assets/images/noData/no_followers.svg')" :title="$t('No Followers')"
            :text="
              $t('Invite People to follow the exciting journey of your startup')
            " :hasTitle="true" :hasText="true" :hasButton="false" />
        </div>
      </div>
    </div>
    <Loader v-else />
    <VerificationModal v-if="getShowModal === true" :get-modal-content-category="getModalContentCategory"
      :get-modal-main-content="getModalMainContent" :get-modal-content-value="getModalContentValue" />
  </div>
</template>

<script>
import i18n from "../../i18n";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
// import Plyr from "vue-plyr";
import NoDataFound from "../../components/NoDataFound/Version-1.vue";
import BasicInfo from "../../components/Startups/StartupPage/BasicInfo.vue";
import About from "../../components/Startups/StartupPage/About.vue";
import Overview from "../../components/Startups/StartupPage/Overview.vue";
import Talents from "../../components/Startups/StartupPage/Talents.vue";
import TractionSection from "../../components/Startups/StartupPage/TractionSection.vue";
//import SliderCarousel from "../../components/Startups/StartupPage/SliderCarousel.vue";
import Tab from "../../components/Profile/Diaspora/profile/Tab/tab.vue";
import VerificationModal from "../../components/Profile/Diaspora/profile/Modal/modal";

import EditButton from "../../components/Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import SaveCancelButtonGroup from "../../components/Profile/Diaspora/profile/buttons/SaveCancelButtonGroup.vue";
import Team from "../../components/Startups/StartupPage/Team.vue";
var psl = require("psl");
export default {
  metaInfo() {
    return {
      title: "Bantaba | Startups | " + this.startup.startupname,
    };
  },
  watch: {
    // async $route() {
    //   const startup_id = this.startup.id;
    //   const lang = i18n.locale;
    //   await this.loadStartupNeeds({ startup_id, lang });
    //   this.talents = this.getStartupNeededTalents;
    // },
  },
  mounted() {
    // if (this.getProfileEditingMode) {
    //   this.tabActive = "followers";
    // }
  },
  async created() {
    const lang = i18n.locale;
    let slug = this.$route.params.slug;
    await this.loadStartupBySlug({ slug });
    this.startup = this.getStartup;
    if (this.startup) {
      this.$store.commit("setStartupBio", this.startup.description);
    }

    if (this.authUser.type === 2 && this.authUser.startup.slug === slug) {
      this.mypage = true;
      this.$store.commit("setStartupEditingMode", true);
    } else {
      this.mypage = false;
      this.$store.commit("setStartupEditingMode", false);
    }

    if (this.startup.cover) this.coverImg = this.startup.cover;
    else this.coverImg = require(`@/assets/images/startups/page/bg.svg`);

    if (
      this.coverImg ===
      "https://res.cloudinary.com/bantaba/image/upload/v1624848743/bantaba/images-5_zhv0ka.jpg"
    ) {
      this.has_cover = false;
      this.coverImg = require(`@/assets/images/startups/page/bg.svg`);
    } else {
      let url = this.coverImg;
      if (url && url.indexOf("upload/") > -1) {
        var index = url.indexOf("upload/");
        var first_part = url.substr(0, index + 6);
        var last_part = url.substr(index + 6);
        this.coverImg = first_part + "/q_auto:low/c_fill,w_990" + last_part;
      }
    }

    const user_id = this.getStartup.user_id;
    const startup_id = this.startup.id;
    this.startup_id = this.startup.id;
    this.user_id = this.getStartup.user_id;
    await this.loadFollowers(startup_id);
    var countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    if (this.startup) {
      this.startup.location = countries.getName(
        this.startup.location,
        i18n.locale,
        {
          select: "official",
        }
      );
    }
    if (this.startup) {
      this.startup.foundingYear = moment(this.startup.foundingYear).format(
        "YYYY"
      );

      this.videoLink = this.startup.video;
    }
    await this.loadStartupNeeds({ startup_id, lang });
    await this.loadStartupTypeOfSupport({ startup_id });
    // await this.loadStartupTeam({ user_id });
    //this.members = this.getStartupTeam;

    this.talents = this.getStartupNeededTalents;

    if (
      this.getUserSupportTypes.filter(function (e) {
        return e.typeOfSupport === "Invest";
      }).length > 0
    ) {
      this.seekingFunding = "yes";
    } else {
      this.seekingFunding = "no";
    }
    if (this.authUser.type === 1) {
      let viewData = {
        diaspora_id: this.authUser.id,
        user_id: this.authUser.id,
        startup_id: this.startup.user_id,
        name: this.authUser.firstname + " " + this.authUser.name,
        sender_avatar: this.authUser.avatar,
        sender_id: this.authUser.user_id,
        lang: this.getUserLang,
      };
      await this.viewedPage({ data: viewData });
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserProfile",
      "getStartup",
      "getStartupBySlug",
      "getStartupNeededTalents",
      "getStartupTeam",
      "getFollowers",
      "getFollowersCount",
      "getProfileEditingMode",
      "getStartupEditingMode",
      "showEditButtons",
      "getUserSupportTypes",
      "getUserLang",
      "getStartupBio",
      "getShowModal",
      "getModalContentCategory",
      "getModalMainContent",
      "getModalContentValue"
    ]),
  },
  methods: {
    ...mapActions([
      "loadProfile",
      "loadStartup",
      "loadConnCounts",
      "loadStartupBySlug",
      "loadStartupNeeds",
      "loadStartupTeam",
      "viewedPage",
      "loadFollowers",
      "loadSectorsFilter",
      "loadStartupTypeOfSupport",
      "openChatBoxWithUser",
    ]),
    viewProfile(user) {
      let url = `/${this.getUserLang}/profile/${user.username}`;
      if (user.type === 2) {
        url = `/${this.getUserLang}/startup/${user.startup.slug}`;
      }
      window.location.href = url;
    },
    setTabtoPublic() {
      this.tabActive = "public";
    },
    setTabtoFollowers() {
      this.tabActive = "followers";
    },
    setTabtoTeam() {
      this.tabActive = "team";
    },
    setTabtoDataRoom() {
      this.tabActive = "data-room";
    },
    uploadingALink() {
      this.isUploadingLink = true;
    },
    uploadLink() {
      this.$store
        .dispatch("updateStartup", {
          video: this.videoLink,
        })
        .then(async () => {
          this.isUploadingLink = false;
          this.editing = false;
          this.$snotify.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    cancelLink() {
      this.isUploadingLink = false;
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    toggleEditMode() {
      this.editing = true;
    },
    showForm() {
      this.editing = true;
      this.isUploadingLink = true;
    },
    hideForm() {
      this.editing = false;
      this.isUploadingLink = false;
    },
    cancel: function () {
      this.editing = false;
    }
  },
  data() {
    return {
      has_cover: true,
      user_id: 0,
      startup_id: 0,
      seekingFunding: "",
      mypage: false,
      videoLink: "",
      coverImg: "",
      startup: {},
      users: {},
      about: "",
      editing: false,
      members: {},
      lookingFor: [],
      iscurrentUser: false,
      connectionsCount: 0,
      loading: true,
      talents: {},
      tabActive: "public",
      isUploadingLink: false,
      dataRoomTabHead: [{
          "id": 1,
          "tabNav": this.$t("vault")
        },
        {
          "id": 2,
          "tabNav": this.$t("request")
        }]
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Startup",
  components: {
    About,
    Overview,
    Talents,
    TractionSection,
    //SliderCarousel,
    NoDataFound,
    Team,
    BasicInfo,
    EditButton,
    Tab,
    VerificationModal,
  },
};
</script>

<style scoped>
.link:hover h2 {
  color: var(--first-color) !important;
  text-decoration: underline !important;
}

.stickRight {
  position: absolute !important;
  right: 10px !important;
  float: right !important;
}

.img-profile {
  object-fit: cover;
  width: 100px;
  border: 2px solid #1ba54b26;
  box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915;
}

.edit-button {
  position: absolute !important;
  right: 20px;
  /* top: 20px; */
  top: 5px;
  padding: 5px 20px;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  z-index: 99;
}

.reposition {
  top: 30px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.profile-outer-layout {
  /* background-color: orange; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
}

.profilePage {
  /* position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0; */
  position: relative;
  /* width: 100vw; */

  min-height: 100vh;
  /* background: #F5F7F9; */
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  padding-top: 25px;
  padding-bottom: 40px;
  /* width: 100vw !important; */
  width: min(78%, 1140px) !important;
  max-width: 990px;
  /* display: none; */
  /* height: 100vh; */
  overflow-y: auto;
  padding-bottom: 0px;
  scroll-behavior: smooth;
  padding-bottom: 60px;
}

.bg-green {
  background-color: #eaf5ef !important;
  z-index: 100;
}

.bg-white {
  background-color: rgb(255, 255, 255);
  position: relative;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
  border-radius: 0px 0px 8px 8px;
  border-radius: 15px;
  width: 100%;
}

.full-width {
  width: 100%;
}

.profile-cover {
  /* width: 100%;
    height: 445px; */
  width: 100%;
  height: 180px;
  /* background: #eee; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* opacity:0.2; */
  background: #1ca54b;
  background: linear-gradient(180deg,
      #00000020 0%,
      #a0ffc110 50%,
      #1ca54b00 100%);
}

.profile-cover .cld-image {
  border-radius: 8px 8px 0px 0px;
  object-fit: fill;
}

.cover-bg {
  height: auto;
  width: 100%;
  opacity: 0.9;
  /* backdrop-filter: blur(10px); */
  mix-blend-mode: darken;
  /* max-width: 100%;
    max-height: 100%; */
}

.tabBars {
  width: 100%;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.2rem 0rem;
  overflow: hidden;
}

.left {
  margin-left: 5rem;
}

.tab {
  height: 46px;
  padding: 10px 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  color: #333333;
}

.active {
  border-bottom: 1px solid green;
}

.cover-video {
  margin-top: -20px;
  width: 100%;
  /* height: 400px; */
  overflow: hidden;
  border-radius: 24px;
  /* background: #007bff24 !important;
  background: #007bff !important; */
  background: #ffffff !important;
  padding: 6px 1px;
}

.hideVideoContainer {
  /* display: none; */
}

.cover-video .media {
  width: 100%;
  height: auto;
}

.cover-video .upload-a-video {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.upload-link-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.input-link {
  width: 90%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #00853c !important;
  padding-left: 15px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload-button-container {
  /* position: absolute; */
  width: 90%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  bottom: 0px;
}

button.save-link,
button.cancel-link {
  display: block;
  color: #41ac66;
  font-size: 15px;
  background-color: #fff;
  padding: 5px 15px;
  margin: 5px;
  border: 1px solid #41ac66;
  border-radius: 15px;
}

button.cancel-link {
  color: #ff0200;
  background-color: #fff;
  border: 1px solid #ff0200;
}

button.save-link:hover {
  color: #fff;
  background-color: #41ac66;
}

button.cancel-link:hover {
  color: #fff;
  background-color: #ff0200;
}

.cover-video .upload-a-video img {
  width: 60px;
  height: 60px;
}

.cover-video .upload-a-video p {
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  padding-top: 10px;
}

video {
  border-radius: 10px;
}

.about-section {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  height: 310px;
  font-size: 20px;
}

.fixHeightSize {
  overflow: inherit;
  height: auto;
}

.cover2 {
  padding: 0;
  margin-top: 16px;
  overflow: hidden;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 4px rgba(44, 43, 42, 0.1));
  border-radius: 8px;
}

.cover2 img {
  height: 100%;
  width: auto;
}

.other-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.desktop {
  display: block;
  display: flex;
}

.mobile {
  display: none;
}

.follower-section {
  background-color: white;
  width: 100%;
}

.follower-section ul {
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2%;
}

.follower-card {
  width: 48%;
  display: flex;
  /* background-color: red; */
  margin-bottom: 20px;
  border-radius: 40px;
  border: 1px solid rgba(0, 128, 0, 0);
  padding: 0px;
}

.follower-card:hover {
  border: 1px solid rgba(0, 128, 0, 0.249);
  background-color: rgba(0, 128, 0, 0.05);
}

.follower-profile {
  width: 22%;
  padding: 0;
}

.follower-card-details {
  width: 78%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.follower-info {
  padding-left: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 62px);
  padding-left: 10px;
  height: 120px;
}

.follower-info h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #333333;
}

.follower-info p {
  font-size: 20px;
  line-height: 134.99%;
  letter-spacing: 0.02em;
  color: #3333338e;
}

.f-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.f-chat svg {
  width: 62px;
  height: 62px;
}

@media (max-width: 600px) {
  .edit-button {
    right: 20px;
    top: 8px;
  }

  .profilePage {
    width: 100vw !important;
    overflow-x: hidden;
    padding: 0;
    margin: 0;

    height: 100%;
    overflow-y: scroll;
    padding-bottom: 0px;
    scroll-behavior: smooth;
    padding-bottom: 60px;
  }

  .profile-cover {
    border-radius: 0;
  }

  .basic-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 0;
  }

  .cover-bg {
    height: auto;
    width: 100%;
  }

  .about-section {
    margin-top: 8px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw !important;
  }

  .other-section {
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
  }

  .cover-video {
    margin-top: -10px;

    width: 100vw;
    height: auto;
    /* border-radius: 0; */
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 3px 3px !important;
    padding-top: 13px !important;
  }

  .cover-video .media {
    width: auto;
    height: 100%;
  }

  .cover-video .upload-a-video {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
  }

  .cover-video .upload-a-video img {
    width: 35px;
    height: 35px;
  }

  .cover-video .upload-a-video p {
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4f4f4f;
    padding-top: 10px;
  }

  video {
    border-radius: 10px;
  }

  .left {
    margin-left: 0;
  }

  .tabBars {
    overflow: hidden;
    margin-bottom: 2px;
    border-radius: 0;
    /* width: 100%; */
    margin: 0.5rem 0;
    padding: 5px 1rem !important;
    margin-top: 0.3px;

    background: linear-gradient(90deg, #eafff2 0%, rgb(255, 255, 255) 100%);
  }

  .tab {
    height: 100%;
    padding: 5px 0px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #333333;
    border-radius: 5px;
    /* width: 33vw !important; */
    width: 29vw !important;
    border: 1px solid #4646461f;
    transition: all 0.3 ease-in-out;
    height: 45px;
    font-weight: bold;
  }

  .active {
    border-bottom: none;
    /* background-color: rgb(255, 255, 255); */
    border: 1px solid var(--green-color);
    background-color: #1ba54b;
    border: 1px solid #1ba54b;
    color: white;
  }

  .follows-container {
    width: 100vw !important;
  }

  .basic-footer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 20px 0;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
  }

  .follower-section ul {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2%;
    padding-bottom: 50px;
  }

  .follower-card {
    /* width: 75%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 0px;
    border: 1px solid rgba(0, 128, 0, 0);
    padding: 0px;
    align-items: center;
    padding: 5px 0;
     border-bottom: 1px solid #00800042; */
    width: 95%;
    display: flex;
    margin-bottom: 5px;
    /* border-radius: 0px; */
    border-radius: 10px;
    border: 1px solid rgba(0, 128, 0, 0);
    padding: 0px;
    /* flex-direction: column; */
    align-items: center;
    padding: 0px 0;
    border-bottom: 1px solid #0080001e;
    height: 90px;
  }

  .follower-card-details {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
  }

  .follower-profile {
    width: 20%;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .img-profile {
    object-fit: cover;
    width: 60px;
    height: 60px;
    border: 2px solid #1ba54b26;
    box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915;
  }

  .f-chat svg {
    width: 32px;
    height: 32px;
  }

  .follower-info {
    padding-left: 20px;
  }

  .follower-info h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    background: linear-gradient(180deg, #eafff2 0%, rgb(255, 255, 255) 100%);
    text-align: left;
  }

  .follower-info p {
    font-size: 16px;
    line-height: 134.99%;
    letter-spacing: 0.02em;
    color: #3333338e;
  }
}

@media (max-width: 320px) {
  .profile-cover {
    height: 150px;
  }
}
</style>
