<template>
  <div class="py-4 container">
    <div class="container-fluid px-md-5">
      <div class="row row-connected"
      :class="{ offsetLayoutMargin: authUser.is_verified === 0 }"
       v-if="!loading">
        <main
          class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          "
        >
          <ConnectionAside v-if="authUser.type === 1" />
          <StartupAside
            :counts="getConnectionsCounts"
            v-else-if="authUser.type === 2"
          />
          <div class="mb-3 min-wdth">
            <Connections
              :users="getConnected"
              type="connected"
              emptyMessage="NoConnections"
              :showSearch="false"
            />
          </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
  if (this.authUser.confirmed) {
    this.$store.commit("setShowDiasporaLoadMore", false);
    if (!JSON.parse(localStorage.getItem("connected"))) {
      await this.loadConnections(this.authUser.id);
      //save to locale storage
      localStorage.setItem("connected", JSON.stringify(this.getConnected));
    } else {
      let users = JSON.parse(localStorage.getItem("connected"));
      this.$store.commit("setConnected", users);
    }
    this.$store.commit("setUsers", this.getConnected);
      this.loading = false;
    } else {
    this.$noty.error(this.$i18n.t('NonApprovedMsg'));
    this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters(["authUser", "getConnected", "getConnectionsCounts"]),
  },
  metaInfo() {
    return {
      title: "Bantaba | Community | Connections",
    };
  },
  methods: {
    ...mapActions(["loadConnections", "loadConnCounts"]),
  },
  data() {
    return {
      loading: true,
      users: {},
      counts: {},
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Connected",
};
</script>

<style scoped>
.min-wdth {
  width: 100%;
}
@media (min-width: 700px) {
  .row-connected {
    /* background-color: green !important; */
    justify-content: center;
  }
  .offsetLayoutMargin{
    margin-top: 30px;
  }
}
@media (max-width: 600px) {
  .container-fluid {
    min-height: 100vh;
  }
}
@media (min-width: 1200px) {
  /* .py-4 {
    padding-top: 0.2rem ;
  } */
  .pb-4,
  .py-4 {
    padding-bottom: 0rem !important;
  }

  .pt-4,
  .py-4 {
    padding-top: 0rem !important;
  }
}
</style>
