<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="interests-header" v-if="getUserSupportTypes.length || showEditButtons">
      <span class="title">{{ $t("LookingTo") }}</span>
      <labeled-edit-button @click.native="edit()" v-if="showEditButtons && !editing" />
      <save-cancel-button-group @save="save" @cancel="cancelEdit" v-if="editing" />
    </div>
    <div class="interests-body" ref="lookings">
      <div class="show-wrapper d-flex flex-wrap" v-if="!adding">
        <div v-for="(support, index) in getUserSupportTypes" :key="index" class="tag-wrapper">
          <tag v-if="getProfileEditingMode && showEditButtons && editing" :name="$t(support.typeOfSupport)"
            :icon="support.name" :isEditMode="showEditButtons && editing"
            @click.native="deleteSupportType(support.id)" />
          <tag v-else :name="$t(support.typeOfSupport)" :icon="support.name" />
        </div>
        <div class="tag-wrapper" v-if="editing">
          <add-more-button @click.native="addMore" />
        </div>
        <p v-if="showEditButtons && getUserSupportTypes.length <= 0 && !editing">
          {{ $t("addSupportTypes")}}</p>
      </div>
      <div class="add-wrapper" v-if="adding">
        <select name="lookingto" class="w-100 profile-controls" v-model="support">
          <option v-for="(engagement, index) in engagementTypes" :key="index" :value="engagement"
            :selected="index === 0">
            {{ engagement }}
          </option>
        </select>

        <div class="w-100">
          <labeled-save-cancel-button-group class="justify-content-end" @save="createSupport" @cancel="cancelAdd()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from "../main/Tag";
import AddMoreButton from "../buttons/AddMoreButton";
import LabeledEditButton from "../buttons/LabeledEditButton";
import SaveCancelButtonGroup from "../buttons/SaveCancelButtonGroup";
import LabeledSaveCancelButtonGroup from "../buttons/LabeledSaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
export default {
  async mounted() {
    this.user_id = this.getProfile.id;
    await this.loadUserTypeOfSupport({
      user_id: this.user_id,
    });
    this.support = this.engagementTypes[0];
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getUserSupportTypes",
      "getProfileEditingMode",
    ]),
  },
  props: {
    tags: Array,
  },
  components: {
    Tag,
    AddMoreButton,
    LabeledEditButton,
    SaveCancelButtonGroup,
    LabeledSaveCancelButtonGroup,
  },
  data() {
    return {
      editing: false,
      support: "",
      adding: false,
      user_id: 0,
      engagementTypes: ["Invest", "Consulting", "Mentoring"],
    };
  },
  watch: {
    editing: function (newValue) {
      if (!newValue) this.adding = false;
    },
  },
  methods: {
    ...mapActions([
      "loadProfile",
      "updateBasicInfo",
      "loadUserTypeOfSupport",
      "createUserTypeOfSupport",
      "deleteUserSupport",
    ]),
    addMore: function () {
      this.adding = true;
    },
    cancelAdd: function () {
      this.adding = false;
    },
    edit: function () {
      this.editing = true;
      this.$refs.lookings.classList.add("editing");
    },
    save: function () {
      this.editing = false;
      this.$refs.lookings.classList.remove("editing");
    },
    cancelEdit: function () {
      this.editing = false;
      this.$refs.lookings.classList.remove("editing");
    },
    async createSupport() {
      if (this.support) {
        let user_id = this.authUser.id;
        let support = this.support;
        this.$store
          .dispatch("createUserTypeOfSupport", {
            user_id,
            support,
          })
          .then(async () => {
            await this.loadUserTypeOfSupport({ user_id });
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
            this.adding = true;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
    },
    async deleteSupportType(support_id) {
      if (this.getUserSupportTypes.length <= 1) {
        this.$noty.info(this.$i18n.t("sectionCantBeEmpty"));
      } else { 
        let user_id = this.authUser.id;
        // await this.deleteUserSupport({ user_id, support_id });

        this.$store
          .dispatch("deleteUserSupport", {
            user_id,
            support_id,
          })
          .then(async () => {
            await this.loadUserTypeOfSupport({ user_id });
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
            this.adding = false;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
     
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: none !important;
  border-bottom: 2px solid var(--first-color) !important;
  border-radius: 0rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
@media (min-width: 600px) {
}

.tag {
  color: rgba(0, 0, 0, .69) !important;
  border: 1px solid rgba(0, 0, 0, .15) !important;
  box-shadow: none !important;
}

.editBorder {
  color: #c31e3e !important;
  border: 1px solid #c31e3e !important;
}
</style>


