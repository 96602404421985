<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="outline" v-bind="$attrs" v-if="!editing">
      <p class="profile-about">
        {{ about }}
        <span v-if="showSeeMore">
          <a
            class="read-more"
            @click="readMore"
            v-if="!isShow"
            href="javascript:;"
            >... {{ $t("seeMore") }}
          </a>
        </span>
        <transition name="slide">
          <p class="mb-0">
            <a
              class="read-more"
              @click="readLess(feed)"
              v-if="isShow"
              href="javascript:;"
              >{{ $t("seeLess") }}
            </a>
          </p>
        </transition>
      </p>
      <div class="col-md-12">
        <edit-button
          class="justify-content-end"
          v-if="showEditButtons"
          @edit="edit"
        />
      </div>
    </div>
    <div class="outline" v-if="editing">
      <textarea
      ref="biotextarea"
      :class="{minHeightTextArea:showEditButtons}"
      class="edit-diaspora-bio"
      v-model="bio" @keyup="charCount()" rows="5"
       @input="textareaResize($event)"></textarea>
      <save-cancel-button-group
        class="justify-content-end"
        @save="save"
        @cancel="cancel"
      />
      <span class="charChout">{{ totalcharacter }}</span>
    </div>
  </div>
</template>

<script>
import EditButton from "../buttons/EditButton";
import SaveCancelButtonGroup from "../buttons/SaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
    this.bio = this.getProfile.profile ? this.getProfile.profile.about : "";
    this.lastBio = this.bio;
    this.checkBioLength(this.bio);
    this.charCount(this.bio);
  },
  components: {
    EditButton,
    SaveCancelButtonGroup,
  },
  data() {
    return {
      bio: "",
      lastBio: '',
      editing: false,
      isShow: false,
      showSeeMore: true,
      totalcharacter: 0,
    };
  },
  computed: {
    ...mapGetters(["authUser", "getProfile", "showEditButtons"]),
    outline: function () {
      return (
        "Last week Saturday we had amazing opportunity to hear some poetry in a park Last week Saturday " +
        "we had amazing opportunity to hear some poetry in a park Last week Saturday we had amazing opportunity to hear some poetry." +
        "Last week Saturday we had amazing opportunity to hear some poetry in a park Last week Saturday " +
        "we had amazing opportunity to hear some poetry in a park Last week Saturday we had amazing opportunity to hear some poetry." +
        "Last week Saturday we had amazing opportunity to hear some poetry in a park Last week Saturday " +
        "we had amazing opportunity to hear some poetry in a park Last week Saturday we had amazing opportunity to hear some poetry."
      );
    },
  },
  methods: {
    textareaResize(e) {

      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

    },
    charCount: function (bio) {
      if (bio) {
        this.totalcharacter = bio.length;
      } else {
        this.totalcharacter = this.about.length;
      }
    },
    edit: function () {
      this.editing = !this.editing;
    },
    save() {
      this.$store
        .dispatch("updateAbout", {
          about: this.bio,
        })
        .then((success) => {
          this.editing = false;
          if (this.bio.length > 200) {
            this.checkBioLength(this.bio);
          }
          this.about = this.bio;
          this.$snotify.info(this.$i18n.t("bioUpdated"), "Success");

          //this.$noty.success(this.$i18n.t("bioUpdated"));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    checkBioLength(bio) {
      if (bio.length > 200) {
        this.about = bio.substr(0, 200);
        this.isShow = false;
        this.showSeeMore = true;
      } else {
        this.about = bio;
        this.showSeeMore = false;
        this.isShow = false;
      }
    },
    cancel: function () {

      this.bio = this.lastBio;
      this.checkBioLength(this.bio);
      this.editing = false;
    },
    readMore() {
      this.isShow = !this.isShow;
      this.about = this.bio;
    },
    readLess() {
      this.about = this.bio.substr(0, 200);
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-about {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
textarea {
  border: 1px solid #b9b9b9 !important;
  border-radius: 5px !important;
}
.minHeightTextArea{
  height: auto;
  min-height: 10vh;
  border: 1px solid var(--green-color) !important;
}
textarea:focus {
  border: 1px solid var(--green-color) !important;
  border-radius: 5px !important;
  /* height: auto !important; */

}
.charChout {
  position: relative;
  top: -20px !important;
  margin-top: -100px !important;
}
.read-more {
  font-weight: bold;
  color: var(--first-color);
}
.outline p {
  color: #000 !important;
}
.outline {
  color: #2e3335;
  font-size: 15px;
  line-height: 23px;
  padding: 0 17.5px;

  @include min-width(992) {
    margin-top: 20px;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
  }

  @include max-width(415) {
    padding: 10px 15px;
  }

  & > p {
    .edit-profile & {
      padding-bottom: 10px;
    }
    @include max-width(991) {
      padding-bottom: 15px;
      text-align: center;
    }
  }

  textarea {
    width: 100%;
  }

  .button-section {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
