<template>
  <div class="left-side">
    <AuthHeader :logo="require('@/assets/registration/logo.png')" />
    <div class="content">
      <LoginForm />
    </div>
  </div>
</template>
<script>
import LoginForm from "../../../components/Auth/LoginForm.vue";
import AuthHeader from "../../../components/Auth/AuthHeader.vue";
export default {
  name: "LeftSide",
  components: {
    LoginForm,
    AuthHeader,
  },
};
</script>
<style scoped>
.left-side {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 0;
  flex-grow: 0.4;
  padding-left: 2vw;
  font-family: "Inter", sans-serif;
}
.content {
  width: 90%;
  height: auto;
  background-color: #549b560d;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 30px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .left-side {
    /* margin-top: 100px; */
    padding-left: 0;
    border-radius: 50px;
    z-index: 3000;

    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>