<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div
      class="experience"
      v-bind="$attrs"
      v-if="!editing && experienceYears && !getProfileEditingMode"
    >
      <span class="info"
        >{{ experienceYears }}
        <span class="desktop-only">{{ $t("YearsOfExperience") }}</span></span
      >
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div
      class="experience"
      v-bind="$attrs"
      v-if="!editing && getProfileEditingMode"
    >
      <span class="info"
        >{{ experienceYears }}
        <span class="desktop-only">{{ $t("YearsOfExperience") }}</span></span
      >
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div class="experience" v-if="editing">
      <select name="experience" v-model="experienceYears" class="info">
        <option
          v-for="(exp, index) in yearsOfExperience"
          :key="index"
          :value="exp"
          :selected="experienceYears === exp ? 'selected' : ''"
        >
          {{ exp }} {{ $t("YearsOfExperience") }}
        </option>
      </select>
      <save-cancel-button-group @save="save" @cancel="cancel()" />
    </div>
  </div>
</template>

<script>
import EditButton from "../buttons/EditButton";
import SaveCancelButtonGroup from "../buttons/SaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    this.experienceYears = this.experience;
  },
  props: {
    experience: String,
  },
  components: {
    EditButton,
    SaveCancelButtonGroup,
  },
  data() {
    return {
      editing: false,
      experienceYears: "",
      yearsOfExperience: ["0-1", "2-4", "5-10", "10+"],
    };
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getProfileEditingMode",
    ]),
  },
  methods: {
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    save() {
      if (this.experienceYears) {
        this.$store
          .dispatch("updateProfile", {
            experienceYears: this.experienceYears,
          })
          .then(() => {
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.experience {
  display: flex;
  align-items: center;

  .info {
    font-size: 16px;
    margin-right: 10px;
  }
}
</style>
