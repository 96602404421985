var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-country-container"},[_c('div',{staticClass:"select-outer right"},[_c('p',[_vm._v(_vm._s(_vm.$t("Country of Residence")))]),_c('ModelSelect',{attrs:{"isError":_vm.getErrorMsg('err_msg_residence') ? true : false,"options":_vm.residence.map((d) => {
          let value = d.id;
          let text = d.name;
          let name = d.name;
          let code = d.code;
          let item = {
            value,
            text,
            code,
            name,
          };
          return item;
        }),"placeholder":_vm.$t('Resident Country')},on:{"input":function($event){return _vm.saveInput($event, 'countryOfResidence')}},model:{value:(_vm.itemResident),callback:function ($$v) {_vm.itemResident=$$v},expression:"itemResident"}})],1),_c('div',{staticClass:"select-outer left"},[_c('p',[_vm._v(_vm._s(_vm.$t("Country of Origin")))]),_c('ModelSelect',{attrs:{"isError":_vm.getErrorMsg('err_msg_origin') ? true : false,"options":_vm.origin.map((d) => {
          let value = d.id;
          let text = d.name;
          let name = d.name;
          let code = d.code;
          let item = {
            value,
            text,
            code,
            name,
          };
          return item;
        }),"placeholder":_vm.$t('African Country')},on:{"input":function($event){return _vm.saveInput($event, 'africanCountryOfOrigin')}},model:{value:(_vm.itemOrigin),callback:function ($$v) {_vm.itemOrigin=$$v},expression:"itemOrigin"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }