<template>
  <div class="outer-wrapper">
    <div class="traction" v-if="tractionData && !showEditButtons">
      <Header title="Traction" contenteditable="true" />
      <p class="traction-sub-text">{{ $t('What we have achieved so far')}}</p>
      <div class="traction-body" v-html="tractionData" />
      <textarea v-if="
        (tractionData === `` && editing) || (tractionData === null && editing)
      " type="text" v-model="tractionInput" placeholder="Highlight what your startup has achieved so far..." />
    </div>

    <!--make sure that you can only edit when your are on edit mode -->
    <!--  -->

    <div class="traction">
      <div v-if="showEditButtons">
        <Header title="Traction" contenteditable="true" />

        <p class="traction-sub-text">
          <br />
          Add Traction to brag now.... investors and mentors want to know what
          your startup has achieved so far. Traction includes milestones like
          number of users, revenue, product launch, expansions, funds raised
        </p>
        <div class="traction-body" v-html="tractionData" v-if="showEditButtons && !editing" />
        <Traction :tractions="tractionData" label="traction" v-if="showEditButtons && editing" />
      </div>

      <edit-button class="edit-button" v-if="showEditButtons && !editing" @click.native="toggleEditMode" text="edit" />

      <edit-button class="edit-button" v-if="showEditButtons && editing" @click.native="save" text="save"
        :isSave="true" />

      <!-- <button class="edit-button saveTraction">
      Add Traction
    </button> -->
    </div>
  </div>
</template>
<script>
import Header from "./ShareElements/Header.vue";
import Body from "./ShareElements/Body.vue";
import EditButton from "../../../components/Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import Traction from "../../../components/Auth/Traction/TractionStartup.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

const tractionWithData = `<p><strong class="ql-size-large ql-font-serif">Courage and folly is </strong><strong class="ql-size-large ql-font-serif" style="color: rgb(230, 0, 0);">always</strong><strong class="ql-size-large ql-font-serif"> just a fine line.</strong></p><p><br></p><p><span class="ql-font-serif">Hear my words, and bear witness to my vow. Night gathers, and now my watch begins. It shall not end until my death. I shall take no wife, hold no lands, father no children. I shall wear no crowns and win no glory. I shall live and die at my post. I am the sword in the darkness. I am the watcher on the walls. I am the fire that burns against the cold, the light that brings the dawn, the horn that wakes the sleepers, the shield that guards the realms of men. I pledge my life and honor to the Night’s Watch, for this night and all the nights to come.</span></p><p><br></p><p><span class="ql-font-serif">We are born to suffer, to suffer can make us strong.</span></p><p><br></p><p><span class="ql-font-serif">The things we love destroy us every time.</span></p>`;
export default {
  name: "TractionSection",
  props: {
    tractionData: String,
  },
  async mounted() {
    await this.setTractions(this.tractionData);
    this.content = this.tractionData;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartupEditingMode",
      "showEditButtons",
      "getStartupBio",
      "getTractions",
    ]),
  },
  data() {
    return {
      tractionInput: "",
      editing: false,
      content: "",
    };
  },
  components: {
    Header,
    EditButton,
    Traction,
    // Body,
  },
  methods: {
    ...mapMutations(["setTractions"]),
    toggleEditMode() {
      this.editing = true;
      let aboutParagraph = document.getElementById("about");
      //aboutParagraph.contentEditable = true;
      let descriptionText = document.getElementById("discript_text");
      descriptionText.style.webkitLineClamp = "inherit";
      descriptionText.style.webkitLineClamp = "inherit";
      descriptionText.style.webkitBoxOrient = "inherit";
    },

    removeHtmlTags(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.innerText;
    },

    save() {
      var element = document.getElementById("tractopn-content");
      //let traction = element;
      //console.log("traction html ", traction)
      let charsCount = element.textContent.length;
      if (charsCount > 200) {
        this.$store
          .dispatch("updateStartup", {
            traction: this.getTractions,
          })
          .then(async () => {
            this.editing = false;
            //this.setTractions(this.getTractions);
            // eslint-disable-next-line vue/no-mutating-props
            this.tractionData = this.getTractions;
            this.$snotify.success(this.$i18n.t("success"), "Success");
          })
          .catch((error) => {
            console.log("Error while saving the traction ", error)
            this.$noty.error(error);
          });
      } else {
        this.$snotify.error("Min chars 200");
      }
    },
    cancel: function () {
      this.editing = false;

      let descriptionText = document.getElementById("discript_text");
      descriptionText.style.whiteSpace = "ellipsis";
      descriptionText.style.display = "-webkit-box";
      descriptionText.style.webkitLineClamp = "6";
      descriptionText.style.webkitBoxOrient = "vertical";
      descriptionText.style.overflow = "hidden";
      descriptionText.style.marginBottom = "10px";
    },
  },
};
</script>

<style scoped>
.outer-wrapper {
  width: 100%;
}
.traction {
  margin: 10px 0;
  width: 100%;
  /* height: 100%; */
  background: #ffffff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
  border-radius: 8px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.traction-body {
  padding: 10px;
  background-color: white;
  text-align: left;
  font-size: 16px;
  line-height: 134.99%;
  letter-spacing: 0.02em;
  /* padding: 0px 20px; */
  padding: 20px;
  padding-bottom: 20px;
  padding-top: 0;
}


.traction-sub-text {
  padding-top: 5px;
  padding: 5px 20px;
  font-size: 16px;
  color: #00000065;
}
.edit-button {
  position: absolute !important;
  right: 20px;
  top: 5px;
  padding: 5px 20px;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  z-index: 99;
}
ul {
  background-color: red !important;
}
/* li {
  list-style: dots;
} */

li {
  display: list-item !important;
  list-style: number !important;
  /* color: red !important; */
}
textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}
*:focus {
  outline: none;
}
.saveTraction {
  border: none;
  background-color: #f4f5f5;
  color: #329e4d;
  max-width: 120px;
  border-radius: 5px;
  float: left;
}
</style>

	<style>
.traction-body > ul{
  padding: 15px 25px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  line-height: 25px;
}
.traction-body > ul > li{
  padding: 5px 5px;
}
.traction-body > ul > li::marker {
  color: black;
  font-weight: 900;
}
</style>
