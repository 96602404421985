<template>
  <div class="select-country-container">
    <!-- <CustomSelect
      :options="residence"
      :default="form.countryOfResidence"
      class="select"
      :hasError="getErrorMsg('err_msg_residence') ? true : false"
      @input="saveInput($event, 'countryOfResidence')"
    /> -->
    <div class="select-outer right">
      <p>{{ $t("Country of Residence") }}</p>
      <ModelSelect
        :isError="getErrorMsg('err_msg_residence') ? true : false"
        @input="saveInput($event, 'countryOfResidence')"
        :options="
          residence.map((d) => {
            let value = d.id;
            let text = d.name;
            let name = d.name;
            let code = d.code;
            let item = {
              value,
              text,
              code,
              name,
            };
            return item;
          })
        "
        v-model="itemResident"
        :placeholder="$t('Resident Country')"
      />
    </div>
    <div class="select-outer left">
      <p>{{ $t("Country of Origin") }}</p>
      <ModelSelect
        :isError="getErrorMsg('err_msg_origin') ? true : false"
        @input="saveInput($event, 'africanCountryOfOrigin')"
        :options="
          origin.map((d) => {
            let value = d.id;
            let text = d.name;
            let name = d.name;
            let code = d.code;
            let item = {
              value,
              text,
              code,
              name,
            };
            return item;
          })
        "
        v-model="itemOrigin"
        :placeholder="$t('African Country')"
      />
    </div>

    <!-- <CustomSelect
      :options="origin"
      :default="form.africanCountryOfOrigin"
      class="select mx-3"
      :hasError="getErrorMsg('err_msg_origin') ? true : false"
      @input="saveInput($event, 'africanCountryOfOrigin')"
    /> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

// import CustomSelect from "./CustomSelect.vue";
import CustomSelect from "./SelectInputWidthSearch.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  created() {
    const inputs = ["africanCountryOfOrigin", "countryOfResidence", "location"];
    inputs.map((item) => {
      if (JSON.parse(localStorage.getItem(item))) {
        this.form[item] = JSON.parse(localStorage.getItem(item)).name;
        this.$store.commit("setFormData", {
          field: item,
          value: JSON.parse(localStorage.getItem(item)).code,
        });

       // this.itemOrigin = JSON.parse(localStorage.getItem(item)).code;
      }
    });
  },
  computed: {
    ...mapGetters(["getFomData", "getAccountType", "getErrorMsg","getFordataField"]),
  },
  mounted() {
    //     this.originOptions  = this.origin.map( d=>{
    //   let value  = d.id;
    //   let text  = d.name;
    //   let item  = {
    //     value, text
    //   }
    //   return item;
    // })
  },
  data() {
    return {
      itemOrigin: {
        value: "",
        text: "",
        code: "",
        name: "",
      },
      itemResident: {
        value: "",
        text: "",
        code: "",
        name: "",
      },
      originOptions: [],
      form: {
        countryOfResidence: "countryOfResidence",
        africanCountryOfOrigin: "africanCountryOfOrigin",
      },
      errors: {},
      msg: {
        countryOfResidence: "",
        africanCountryOfOrigin: "",
      },
    };
  },
  methods: {
    // filter() {
    // },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async saveInput(e, name) {
      
      if (this.isEmpty(this.form[name])) {
        this.errors[name] = true;
        this.msg[name] = `${this.$i18n.t(name)} is required`;
      } else {
        this.errors[name] = null;
        this.msg[name] = "";
      }
      //remove empty space at the beginning and the end
      let formValue = JSON.stringify(e); 
     // alert(e.code)
      //console.log({ e })
      //save to localstorage and store
      localStorage.setItem(`${name}`, formValue);
      this.$store.commit("setFormData", {
        field: name,
        value: e.code,
      });

      let africanCountryOfOrigin = this.getFordataField(
        "africanCountryOfOrigin"
      );
      // console.log({ africanCountryOfOrigin })
    },
  },
  name: "SelectCountries",
  components: {
    // CustomSelect,
    ModelSelect,
  },
  props: {
    size: Number,
    type: String,
    title: String,
    text: String,
    link: String,
    origin: Array,
    residence: Array,
  },
};
</script>
<style scoped>
:focus {
  outline: none !important;
}
.select::-ms-expand {
  display: none;
}

.select-country-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 50px;
}

.select-outer {
  width: 50%;
}
.right {
  padding-right: 10px;
}
.left {
  padding-left: 10px;
}
/* @media only screen and (max-width: 600px) {
  #ul{
    display: grid;
    grid-template-columns: 100% !important;
    grid-gap: 5px;
    width: 100%;
}
} */
@media (max-width: 600px) {
  .right {
    padding-right: 3px;
  }
  .left {
    padding-left: 3px;
  }
}
@media only screen and (max-width: 1200px) {
  .select-country-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px !important;
  }
  .ml-3,
  .mx-3 {
    margin-left: 0rem !important;
    margin-top: 0rem !important;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
</style>
