import { render, staticRenderFns } from "./StartupAside.vue?vue&type=template&id=23baa622&scoped=true&"
import script from "./StartupAside.vue?vue&type=script&lang=js&"
export * from "./StartupAside.vue?vue&type=script&lang=js&"
import style0 from "./StartupAside.vue?vue&type=style&index=0&id=23baa622&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23baa622",
  null
  
)

export default component.exports