<template>
  <div class="experience-container">
    <div class="education">
      <p class="engagement-type-title">
        {{ $t(engageText) }}
        <span class="msg_error" v-if="err_msg.supportStartup"
          >: {{ $t(err_msg.supportStartup) }}</span
        >
      </p>
      <ul class="yes_no">
        <li v-for="(option, index) in supportStartup" :key="index">
          <SelectBtn
            :text="option.name"
            :icon="option.icon"
            @click.native="setEngagement(option.name)"
            :active="getFordataField('supportStartup') === option.code"
            class="yes_no_btn"
          />
        </li>
      </ul>
    </div>
    <div class="experience" v-if="getShowTypes">
      <p class="engagement-type-title">
        {{ $t(typeOfEngagementText) }}
        <!-- <span class="msg_error" v-if="err_msg.supportType"
          >: {{ err_msg.supportType }}</span
        > -->
        <span class="msg_error" v-if="getErrorMsg('err_msg_supportType')"
          >:{{ $t(getErrorMsg("err_msg_supportType")) }}</span
        >
        <!-- <span class="msg_error" v-if="hasError">: {{ msg }}</span> -->
      </p>
      <ul class="stages">
        <li v-for="type in engagementTypes" :key="type">
          <SelectBtn
            :text="type"
            :icon="null"
            @click.native="setSupportType(type)"
            :active="selectedTypes.includes(type)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import SelectBtn from "./SelectBtn.vue";
export default {
  mounted() {
    let supportStartup = "yes";
    this.$store.commit("setShowTypes", true);
    if (supportStartup === "no") {
      this.$store.commit("setFormData", {
        field: "supportStartup",
        value: 0,
      });
      // this.$store.commit("setFormData", {
      //   field: "supportType",
      //   value: [],
      // });
      // this.$store.commit("setErrorMsg", {
      //   field: "err_msg_supportType",
      //   value: null,
      // });
      // localStorage.removeItem("supportType");
      localStorage.removeItem("sectorOfInterrest");
    } else if (supportStartup === "yes") {
      this.$store.commit("setFormData", {
        field: "supportStartup",
        value: 1,
      });
    }

    // let selectedTypes = JSON.parse(localStorage.getItem("supportType"));
    // if (selectedTypes) {
    //   this.$store.commit("setFormData", {
    //     field: "supportType",
    //     value: selectedTypes,
    //   });
    //   this.selectedTypes = selectedTypes;
    // }

    this.$store.commit("setFormData", {
      field: "supportType",
      value: [],
    });
    this.$store.commit("setErrorMsg", {
      field: "err_msg_supportType",
      value: null,
    });
    localStorage.removeItem("supportType");
  },

  computed: {
    ...mapGetters([
      "getFomData",
      "getAccountType",
      "getFordataField",
      "getShowTypes",
      "getErrorMsg",
    ]),
  },
  data: () => {
    return {
      isEngaginWithStartups: false,
      supportStartup: [
        {
          name: "yes",
          code: 1,
          icon: require("@/assets/registration/icons/smiling.svg"),
        },
        {
          name: "no",
          code: 0,
          icon: require("@/assets/registration/icons/confused.svg"),
        },
      ],
      engagementTypes: ["Investing", "Consulting", "Mentoring"],

      err_msg: {},
      error: false,
      selectedTypes: [],
    };
  },
  methods: {
    ...mapMutations(["setAccountType"]),
    setEngagement(name) {
      // this.$store.commit("setSector_err", false);
      if (name === "no") {
        this.$store.commit("setShowTypes", false);
        this.$store.commit("setFormData", {
          field: "supportStartup",
          value: 0,
        });
        this.$store.commit("setErrorMsg", {
          field: "err_msg_supportType",
          value: null,
        });
        this.$store.commit("setFormData", {
          field: "supportType",
          value: null,
        });
        localStorage.removeItem("supportType");
        localStorage.removeItem("sectorOfInterrest");
      } else {
        this.$store.commit("setShowTypes", true);
        this.$store.commit("setFormData", {
          field: "supportStartup",
          value: 1,
        });
      }
      localStorage.setItem("supportStartup", name);
    },
    setSupportType(type) {
      this.err_msg.supportType = "";
      let error = false;
      if (this.selectedTypes.includes(type)) {
        this.selectedTypes = this.selectedTypes.filter((item) => {
          return item !== type;
        });
      } else {

        this.selectedTypes.push(type);
      }
      this.$store.commit("setFormData", {
        field: "supportType",
        value: this.selectedTypes,
      });
      localStorage.setItem("supportType", JSON.stringify(this.selectedTypes));
    },
  },
  name: "EngageWithStartups",
  components: {
    SelectBtn,
  },
  props: {
    engageText: String,
    hasError: Boolean,
    msg: String,
    typeOfEngagementText: String,
  },
};
</script>
<style scoped>
.experience-container {
  display: flex;
  /* padding: 20px 0; */
  justify-content: space-between;
  padding: 20px 50px;
  align-items: flex-start;
}
.education {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-right: 20px;
}
.btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.experience {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 20px;
}

ul {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 5px;
  width: 100%;
}
.experience ul {
  grid-template-columns: 32% 32% 32%;
}

li {
  list-style: none;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.engagement-type-title {
  text-align: left;
  font-size: 18px !important;
  /* color: #717171; */
  padding-bottom: 10px;
  margin-bottom: 0.5rem;
}
ul.yes_no {
  display: grid;
  grid-template-columns: 35% 35% !important;
  grid-gap: 8px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  /* .rightContainer {
    background-color: rgb(255, 255, 255);
    height: calc(100% - 0.4vh);
    width: 100%;
    flex-grow: 0.6;
    padding: 10px 0px;
    align-items: flex-start;
    position: relative;
  } */
  /* .right-header {
    display: flex;
    padding: 0px 15px;
    align-items: flex-start;
    text-align: center;
  }
  .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    padding: 25px 0px;
    margin-top: -40px;
    text-align: center;
    width: 100vw;
    z-index: 1000;
    border-radius: 64px;
    background-color: #ffffff;
  } */
  .engagement-type-title {
    text-align: left;
    font-size: 18px !important;
    color: #717171;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .experience-container {
    padding: 15px 15px !important;
    /* background-color: #FE9A0C14; */
    flex-direction: column;
    border-radius: 25px;
    padding-top: 0px !important;
  }
  .education {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0px;
  }
  .experience {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 8px 0px;
  }
  ul {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 5px;
    width: 100%;
  }

  li {
    list-style: none;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul.yes_no {
    display: grid;
    grid-template-columns: 50% 50% !important;
    grid-gap: 5px;
    width: 100%;
  }

  ul.stages {
    display: grid;
    grid-template-columns: 33% 33% 33% !important;
    grid-gap: 5px;
    width: 100%;
  }
}
</style>