
import Startup from '../views/startups/Single.vue';
import Startups from '../views/startups/All.vue';
import i18n from "../i18n";
import { authGuard } from './guard';

import Partnerships from '../views/partnerships/All.vue';
import SinglePartnership from '../views/partnerships/Single.vue';

var userLang = navigator.language || navigator.userLanguage;

var defaultLang = userLang.substring(0, 2);

i18n.locale = defaultLang;

export default [

  {
    path: "startups",
    name: "StartupsEn",
    component: Startups,
    beforeEnter: authGuard
  },
  {
    path: "startups",
    name: "StartupsFr",
    component: Startups,
    beforeEnter: authGuard
  },

  {
    path: "startup/:slug",
    name: "StartupEn",
    component: Startup,
    beforeEnter: authGuard
  },

  {
    path: "startup/:slug",
    name: "StartupFr",
    component: Startup,
    beforeEnter: authGuard
  },

  {
    path: "partnerships",
    name: "PartnershipsEn",
    component: Partnerships,
    beforeEnter: authGuard
  },
  {
    path: "partenariats",
    name: "PartnershipsFr",
    component: Partnerships,
    beforeEnter: authGuard
  },
  {
    path: "partnerships/:slug",
    name: "SinglePartnershipEn",
    component: SinglePartnership,
    beforeEnter: authGuard
  },
  {
    path: "partenariats/:slug",
    name: "SinglePartnershipFr",
    component: SinglePartnership,
    beforeEnter: authGuard
  },
];
