import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import connections from './modules/connections';
import chat from './modules/chat';
import registration from './modules/registration';
import NavModal from './modules/NavModal';
import LinkedIn from './modules/LinkedIn';
import EmailVerficifation from './modules/EmailVerficifation';
import Partnerships from './modules/partnerships';
import profileSettings from './modules/profileSettings';
import verifyUsers from './modules/verifyUser';

//Load Vuex
Vue.use(Vuex);

//Create Store
export default new Vuex.Store({
  modules: {
    auth,
    connections,
    chat,
    registration,
    NavModal,
    LinkedIn,
    EmailVerficifation,
    Partnerships,
    profileSettings,
    verifyUsers
  },
});
