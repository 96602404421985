<template>
  <div class="empty-chat-header">
    <div class="empty-chat-top">
      <div class="new-message-text">
        {{ $t("NewMessage") }}
      </div>
      <button class="header-message-btn" @click="commitCloseBubble">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path
            d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
          ></path>
        </svg>
      </button>
    </div>
    <template v-if="getChatServerStatus">
      <div class="empty-chat-middle">
        <vue-typeahead-bootstrap
          v-if="authUser.type === 2"
          class="type-a-name col-12"
          type="text"
          :placeholder="$t('searchConnection')"
          v-model="query"
          :data="diasporaList"
          :serializer="(item) => item.login"
          highlightClass="special-highlight-class"
          @hit="selecteduser = $event"
          :minMatchingChars="3"
          :autocomplete="false"
          :disabledValues="selecteduser ? [selecteduser.login] : []"
          @input="lookupDiaspora"
        >
        </vue-typeahead-bootstrap>

        <vue-typeahead-bootstrap
          v-else
          class="type-a-name col-12"
          type="text"
          :placeholder="$t('searchConnection')"
          v-model="query"
          :data="diasporaList"
          :serializer="(item) => item.login"
          highlightClass="special-highlight-class"
          @hit="selecteduser = $event"
          :minMatchingChars="3"
          :autocomplete="false"
          :disabledValues="selecteduser ? [selecteduser.login] : []"
          @input="lookupConnections"
        >
        </vue-typeahead-bootstrap>
      </div>
      <div class="search-list" v-if="showSuggestions">
        <p class="search-list-header">{{ $t("suggestion") }}</p>
        <ul class="search-list-ul">
          <li
            class="search-list-li chat"
            v-for="diaspora in diasporaList"
            :key="diaspora.id"
            @click="startNewConvo(diaspora)"
          >
            <div class="mx-2">
              <img class="gravatar rounded-circle" :src="diaspora.avatar" />
            </div>
            <p class="chat-member-name">
              {{ diaspora.firstname }} {{ diaspora.name }}
            </p>
          </li>
        </ul>
      </div>
      <ChatCardInfo
        text="startAConvo"
        svg="search.svg"
        v-if="showWelcomeMess"
      />
      <ChatCardInfo text="ChatNotFound" svg="notFound.svg" v-if="notFound" />
    </template>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import ChatCardInfo from "./ChatCardInfo";
import EmptyNewConvo from "./EmptyNewConvo";
import Axios from "axios";
import APIURLS from "../../constant";
import { debounce } from "lodash";
import moment from "moment";
import i18n from "../../i18n";

export default {
  computed: {
    ...mapGetters([
      "getCurrentlyChattingWith",
      "authUser",
      "getOnlineUsers",
      "getChatServerStatus",
    ]),
  },
  data: () => {
    return {
      notFound: false,
      showWelcomeMess: true,
      isrecipient: false,
      canshowHeader: false,
      recipientName: "",
      query: "",
      selecteduser: null,
      enteredName: "",
      diasporaList: [],
      showSuggestions: false,
    };
  },
  methods: {
    ...mapActions([
      "getSingleUserById",
      "loadUserMessages",
      "openChatBoxWithUser",
    ]),
    commitCloseBubble() {
      (this.isrecipient = false),
        (this.canshowHeader = false),
        (this.recipientName = ""),
        (this.enteredName = ""),
        this.$store.commit("closeBubble");
    },
    addARecipient() {
      this.isrecipient = true;
      this.canshowHeader = true;
      this.enteredName = this.recipientName;
      this.$store.commit("createNewChat", this.enteredName);
      this.recipientName = "";
    },
    async startNewConvo(diaspora) {
      let user = await this.getSingleUserById({ user_id: diaspora.id });
      this.$store.commit("setCurrentlyChattingWith", user);
      this.$store.commit("setIsNewChatConvo", false);
      this.$store.commit("setIsBubbleClosed", false);
      this.$store.commit("setIsBubbleActive", true);
      this.$store.commit("setTyping", false);
      var check = this.getOnlineUsers.filter(
        (item) => item.id === this.getCurrentlyChattingWith.id
      );
      if (check.length) this.$store.commit("setOtherUserIsOnline", true);
      else this.$store.commit("setOtherUserIsOnline", false);
      //set the user messages
      let senderId = this.authUser.id;
      let receiverId = this.getCurrentlyChattingWith.id;
      await this.loadUserMessages({ senderId, receiverId });
      await this.openChatBoxWithUser(user);
    },
    lookupDiaspora: debounce(function () {
      // in practice this action should be debounced
      if (this.query) {
        fetch(`${APIURLS.USERS}lookupUser/${this.query}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.showWelcomeMess = false;
            if (!data.items.length) {
              this.notFound = true;
              this.showSuggestions = false;
            } else {
              this.showSuggestions = true;
            }
            this.diasporaList = data.items;
          });
      } else {
        this.diasporaList = [];
        this.showSuggestions = false;
        this.notFound = false;
        this.showWelcomeMess = true;
      }
    }, 500),

    lookupConnections: debounce(function () {
      // in practice this action should be debounced
      if (this.query) {
        fetch(
          `${APIURLS.CONNECTIONS}search-connected/${this.authUser.id}/${this.query}`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.showWelcomeMess = false;
            if (!data.items.length) {
              this.notFound = true;
              this.showSuggestions = false;
            } else {
              this.showSuggestions = true;
            }
            this.diasporaList = data.items;
          });
      } else {
        this.diasporaList = [];
        this.showSuggestions = false;
        this.notFound = false;
        this.showWelcomeMess = true;
      }
    }, 500),
  },

  props: {
    title: String,
    currentlyChattingAvatar: String,
  },
  components: {
    ChatCardInfo,
  },
  name: "EmpyChatHeader",
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
.type-a-name {
  font-size: 14px;
}
li.chat {
  position: relative;
  display: flex;
  align-items: center;
  width: 100% !important;
  padding: 12px;
  height: 50px;
  overflow: hidden !important;
}

.chat-member-profile {
  width: 25px;
  height: 25px;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gravatar {
  width: 100%;
  height: 100%;
}
.empty-chat-header {
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  position: relative;
}
.empty-chat-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  height: 50px;
  padding: 10px;
  background: #08ad6e;
  background: #57b466;
  border-radius: 0.8rem 0.8rem 0 0;
}
.header-message-btn {
  border: none;
  width: 32px;
  height: 32px;
  background-color: #dbdbdb75 !important;
  border-radius: 50%;
}

p.chat-member-name {
  display: block;
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
  padding-left: 15px;
}
.header-message-btn svg {
  width: 14px;
  height: 14px;
}
.header-message-btn:hover {
  background-color: #ffffffcc !important;
  transition: all 0.2 ease-in-out;
}
.empty-chat-middle {
  display: flex;
  padding-bottom: 8px;
  padding: 5px 16px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 1) 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.empty-chat-middle span {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
}
input {
  margin-left: 10px;
}

.newMessageHeader {
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 0;
  position: relative;
  min-height: 48px;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.user-profile {
  width: 32px;
  height: 32px;
  background-color: orange;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-clip: content-box;
}
.gravatar {
  width: 32px;
  height: 32px;
}

.recipientName {
  /* position: relative; */
  margin-left: 10px;
  max-width: 230px;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.42857;
  color: rgba(0, 0, 0, 0.9);
  font-size: 22px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.new-message-text {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
form {
  font-size: 16px;
}

.search-list {
  background-color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
  padding: 10px 0px;
  z-index: 30000;
  overflow-y: scroll !important;
  height: 1090px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.search-list-header {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgb(61 63 62 / 11%);
  text-align: left;
  font-size: 1.2rem;
}
.search-list-ul:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.search-list-ul {
  overflow-y: scroll !important;
}

.search-list-li {
  list-style: none;
  padding: 10px !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  border: 5px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
}

.search-list-li:hover {
  background: #e0e0e0c5;
  padding: 10px !important;
  cursor: pointer;
  border-radius: 12px;
  border: 5px solid white;
  border-bottom: 1px solid white !important;
}
/* input.form-control[type="text"], select, textarea {
    padding-left: 10px !important;
    padding: 22px 0;
    border: 1px solid rgb(116 116 116 / 49%) !important;

        padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: 45px !important;
    border: 1px solid rgb(87 180 102) !important;
    border-radius: 10px !important;
}  */
input#typeahead-input {
  padding: 22px 0;
}
.input-group {
  width: 95%;
  padding: 15px 0;
}
input#typeahead-input-77645 {
  height: 50px;
  border-radius: 10px;
  /* padding: 5px 10px 15px 10px; */
}
</style>