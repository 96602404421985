
import ChangePassword from '../views/profile/ChangePassword.vue';

import Page from '../views/profile/Startup/Page.vue';
import Branding from '../views/profile/Startup/Branding.vue';
import EditPage from '../views/profile/Startup/EditPage.vue';
import Admin from '../views/profile/Startup/Admin.vue';
import Team from '../views/profile/Startup/team/Team.vue';
import Product from '../views/profile/Startup/Product.vue';

import Profile from '../views/profile/User/ViewProfile.vue';
import EditProfile from '../views/profile/User/EditProfile.vue';
import Cursus from '../views/profile/User/Cursus.vue';
import ProfileSettings from '../views/profile/User/profileSettings/index';
import { authGuard } from './guard';
import i18n from "../i18n";

var userLang = navigator.language || navigator.userLanguage;

var defaultLang = userLang.substring(0, 2);

i18n.locale = defaultLang;

export default [

  {
    path: "my-profile",
    name: "ProfileEn",
    component: Profile,
    beforeEnter: authGuard
  },
  {
    path: "mon-profile",
    name: "ProfileFr",
    component: Profile,
    beforeEnter: authGuard
  },

  {
    path: "profile/:username",
    name: "UserProfileEn",
    component: Profile,
    beforeEnter: authGuard
  },
  {
    path: "profile/:username",
    name: "UserProfileFr",
    component: Profile,
    beforeEnter: authGuard
  },
  {
    path: "edit-profile",
    name: "EditProfileEn",
    component: EditProfile,
    beforeEnter: authGuard
  },
  {
    path: "modifier-profile",
    name: "EditProfileFr",
    component: EditProfile,
    beforeEnter: authGuard
  },
  {
    path: "my-page",
    name: "PageEn",
    component: Page,
    beforeEnter: authGuard
  },
  {
    path: "ma-page",
    name: "PageFr",
    component: Page,
    beforeEnter: authGuard
  },

  {
    path: "startup/edit-page",
    name: "EditPageEn",
    component: EditPage,
    beforeEnter: authGuard
  },
  {
    path: "startup/modifier-page",
    name: "EditPageFr",
    component: EditPage,
    beforeEnter: authGuard
  },

  {
    path: "startup/branding",
    name: "BrandingEn",
    component: Branding,
    beforeEnter: authGuard
  },
  {
    path: "startup/branding",
    name: "BrandingFr",
    component: Branding,
    beforeEnter: authGuard
  },

  {
    path: "startup/product",
    name: "ProductEn",
    component: Product,
    beforeEnter: authGuard
  },
  {
    path: "startup/product",
    name: "ProductFr",
    component: Product,
    beforeEnter: authGuard
  },

  {
    path: "startup/admin",
    name: "AdminEn",
    component: Admin,
    beforeEnter: authGuard
  },
  {
    path: "startup/admin",
    name: "AdminFr",
    component: Admin,
    beforeEnter: authGuard
  },

  {
    path: "startup/team",
    name: "TeamEn",
    component: Team,
    beforeEnter: authGuard
  },
  {
    path: "startup/equipe",
    name: "TeamFr",
    component: Team,
    beforeEnter: authGuard
  },

  {
    path: "edit-my-cursus",
    name: "CursusEn",
    component: Cursus,
    beforeEnter: authGuard
  },
  {
    path: "modifier-mon-parcours",
    name: "CursusFr",
    component: Cursus,
    beforeEnter: authGuard
  },

  {
    path: "new-password/:page",
    name: "ChangePasswordEn",
    component: ChangePassword,
    beforeEnter: authGuard
  },

  {
    path: "new-password-public",
    name: "ChangePasswordEn",
    component: ChangePassword,
    beforeEnter: authGuard
  },
  {
    path: "nouveau-mot-de-passe/:page",
    name: "ChangePasswordFr",
    component: ChangePassword,
  },
  {
    path: "nouveau-mot-de-passe-public",
    name: "ChangePasswordFr",
    component: ChangePassword,
  },

  //Profile Settings
  {
    path: "parametres-du-profile",
    name: "ProfileSettingsEn",
    component: ProfileSettings,
    beforeEnter: authGuard
  },
  {
    path: "profile-settings",
    name: "ProfileSettingsFr",
    component: ProfileSettings,
    beforeEnter: authGuard
  },
];
