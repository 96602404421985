<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="">
    <div class="avatar avatar-only" :style="bgColorCss">
      <img v-if="!showPreview" class="img-profile rounded-circle" :src="userAvatar" @load="onImgLoad" alt=""
        style="width: 97%" />
      <!-- <img v-if="!showPreview" src="@/assets/images/edit-img-icon.png" @click="viewEditButtons"
        style="position: absolute; bottom: 0; right: 0; cursor: pointer" alt="" /> -->
      <template v-if="showEditButtons">
        <!-- <template v-if="showEditButtons"> -->
        <label class="camera btn" for="avatar-file" style="margin-bottom: 0px" v-if="!showPreview">
          <!-- <img src="@/assets/images/profile-img-placeholder.png" style="width: 97%" @click="triggerImgModalTrue"
            alt="" /> -->

          <img v-if="!showPreview" src="@/assets/images/edit-img-icon.png" @click="viewEditButtons"
            style="position: absolute; bottom: 0; right: 0; cursor: pointer" alt="" />
        </label>
      </template>
    </div>
    <imageUploadModal :isShow="isUploadModal" :loading="loading" :showPreview="showPreview" @closeModal="closeModal"
      @showPreviewTrue="showPreviewTrue" @submit="submit" :startVal="startVal" :endVal="endVal" :decimals="decimals"
      :options="options" />
  </div>
</template>

<script>
import Axios from "axios";
import "./upload-modal.css";
import store from "../../../../../store";
import imageUploadModal from "./imageUploadModal.vue";
import APIURLS from "../../../../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    store.commit("SET_IMAGE_MODAL", false);
    console.log(this.$i18n.t("imageSizeIsMoreThan"));
    console.log(store.state.isImageModal.open);
    var percentageComplete = 0;
    var strokeDashOffsetValue = 100 - percentageComplete * 100;
    var progressBar = document.querySelector(".js-progress-bar");
    progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
    this.savedAvatar = this.avatar;
    this.userAvatar = this.avatar;

    let url = this.avatar;
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // eslint-disable-next-line vue/no-mutating-props
      this.avatar = first_part + "/q_auto:low/c_fill,h_120,w_120" + last_part;
      this.userAvatar = this.avatar;
    } else {
      this.userAvatar = this.avatar;
    }
  },
  props: {
    initials: String,
    bgColor: String,
    avatar: String,
  },
  computed: {
    ...mapGetters(["authUser", "getProfile"]),
    bgColorCss: function () {
      return {
        background: "white",
      };
    },
    ...mapGetters([
      "authUser",
      "getProfile",
      "getProfileEditingMode",
      "showEditButtons",
    ]),
  },
  data() {
    return {
      isActive: false,
      startVal: 0,
      endVal: 0,
      decimals: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 3,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      isNumberAnumation: false,
      isImageUploadError: "",
      isUploadModal: false,
      isLoaded: false,
      showPreview: false,
      cropImg: "",
      buttonChoice: "select",
      image: null,
      fileContents: null,
      cloudName: "bantaba",

      preset: "Users-Avatars",
      formData: null,
      loading: false,
      savedAvatar: "",
      userAvatar: "",
    };
  },
  methods: {
    triggerImgModalTrue() {
      store.commit("SET_IMAGE_MODAL", true);
      this.isUploadModal = true;
      console.log(store.state.isImageModal.open);
    },
    onReady: function (instance, CountUp) {
      const that = this;
      instance.update(that.endVal + 50);
    },
    viewEditButtons() {
      this.$store.commit("setShowEditButtons", true);
      store.commit("SET_IMAGE_MODAL", true);
      this.isUploadModal = true;
      console.log(store.state.isImageModal.open);
    },
    closeModal() {
      this.showPreview = false;
      this.buttonChoice = "select";
      console.log(store.state.isImageModal.open);
      store.commit("SET_IMAGE_MODAL", false);
      this.isUploadModal = false;
      console.log(this.isUploadModal);
      this.$store.commit("setShowEditButtons", false);
      console.log("close");
      var percentageComplete = 0;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
      this.startVal = 0;
      this.endVal = 0;
      this.cropImg = "";
      this.image = null;
      this.formData = null;
    },
    ...mapActions(["loadProfile", "updateProfile"]),
    showPreviewTrue(value) {
      this.showPreview = value;
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropImg);
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    submit(obj) {
      this.startVal = 0;
      this.endVal = 0;
      this.loading = true;

      var zeroPercentageComplete = 0;
      var zeroStrokeDashOffsetValue = 100 - zeroPercentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${zeroStrokeDashOffsetValue}`;
      var percentageComplete = 0.5;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var afterPercentageComplete = 1;
      var afterStrokeDashOffsetValue = 100 - afterPercentageComplete * 100;

      console.log(this.startVal, this.endVal);
      setTimeout(() => {
        this.startVal = 0;
        this.endVal = 50;
        progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
        console.log(this.startVal, this.endVal);
      }, 100);
      this.cropImg = obj.cropedImageData;
      console.log(this.cropImg);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.results = response.data;
              console.log(response.data.bytes)
              this.image = this.results.secure_url;

              console.log(`${this.results.bytes} bytes`)
              // .dispatch("updateProfile", { avatar: this.image, avatar_size: this.results.bytes })
              this.$store
                .dispatch("updateProfile", { avatar: this.image })
                .then((success) => {
                  this.startVal = 50;
                  this.endVal = 100;
                  this.userAvatar = this.image;
                  let auth = JSON.parse(localStorage.getItem("auth"));
                  auth.avatar = this.image;
                  localStorage.removeItem("auth");
                  localStorage.setItem("auth", JSON.stringify(auth));
                  this.$store.commit("setAuthUser", auth);
                  this.showPreview = false;

                  this.buttonChoice = "select";
                  console.log(this.image);
                  console.log(this.userAvatar);
                  progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
                  setTimeout(() => {
                    this.loading = false;
                    this.$noty.success(this.$i18n.t("avatarUpdated"));
                    this.closeModal();
                  }, 3800);
                })
                .catch((error) => {
                  this.startVal = 50;
                  this.endVal = 100;
                  setTimeout(() => {
                    this.loading = false;
                    this.closeModal();
                    this.$noty.error(error);
                  }, 3800);
                });
            })
            .catch((error) => {
              this.startVal = 50;
              this.endVal = 100;
              setTimeout(() => {
                this.loading = false;
                this.errors.push(error);
              }, 3800);
            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(obj.file);
    },
    cancel() {
      this.userAvatar = this.avatar;
      this.showPreview = false;
      this.buttonChoice = "select";
      store.commit("SET_IMAGE_MODAL", false);
      this.isUploadModal = false;
    },
    triggerUploadImgTrue() {
      this.showPreview = true;
    },
  },
  components: {
    imageUploadModal,
  },
};
</script>

<style lang="scss" scoped>
.avatar-only {
  position: absolute;
  transform: translateY(-115px);
  z-index: 5;

  @include max-width(991) {
    left: 50%;
    transform: translate(50%, -50%);
    box-shadow: none;
  }
}

.drag-button {
  background-color: #011108;
  color: #ffffff;
  font-size: 14px;
  z-index: 500;
  margin-top: -60px;
  cursor: default;
  position: absolute;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-image-modal-footer {
  border-top: 1px solid rgb(222 226 230);
}

$progress-bar-stroke-width: 3;
$progress-bar-size: 166px;

svg {
  height: $progress-bar-size;
  transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #c9e9d7;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #c9e9d7;
  stroke: #009743;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 3s ease-in-out;
}

.modal-trigger-heading {
  font-size: 20px;
}

.feed-modal-title {
  font-size: 20px;
  color: #011108;
  font-weight: 700 !important;
  font-family: poppins, sans-serif !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.cross-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #5b615e;
}

.cross-svg-icon {
  width: 16px;
  height: 16px;
  color: #009743;
}

.feed-modal {
  border-radius: 20px;
  width: 645px;
}

.upload-img {
  background-color: transparent !important;
  border: 1px solid rgb(238 238 238);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.upload-img:hover {
  background-color: rgb(233, 230, 230) !important;
}

.publish-now {
  background-color: #009743;
  color: white;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.publish-now:hover {
  background-color: #09b657;
  color: white;
}

.modal-custom-body {
  padding: 0px !important;
}

.cropper-wrap-box {
  height: 100px !important;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.cropper-point.point-se {
  top: calc(85% + 1px);
  right: 14%;
}

.cropper-point.point-sw {
  top: calc(85% + 1px);
  left: 14%;
}

.cropper-point.point-nw {
  top: calc(15% - 5px);
  left: 14%;
}

.cropper-point.point-ne {
  top: calc(15% - 5px);
  right: 14%;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.upload-btn-row {
  width: 100% !important;
  padding-left: 0px;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  border: 2px solid #fff;
  font-size: 45px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 5px solid green; */
  flex-direction: column;

  .camera {
    display: none;
  }

  input {
    display: none;
  }

  .edit-profile & {
    .camera {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(233, 233, 233, 0.81);

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

button.btn.upload-btn.btn-success {
  width: 200px;
  height: 45px;
}

button.btn.upload-btn.btn-danger {
  width: 200px;
  height: 45px;
}

.avatar-custom {
  position: relative;
  background: white !important;
  margin-bottom: -100px;
}

@media only screen and (max-width: 600px) {
  .avatar-custom {
    margin-bottom: 50px;
  }

  .drag-button {
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: center;
    width: 85%;
  }

  .progress-cancel-btn {
    width: 90%;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  .upload-image-modal-header {
    border-bottom: none !important;
  }

  .upload-image-modal-footer {
    border-top: none;
  }

  #circle-crop {
    margin-top: -280px !important;
  }

  .modal-trigger-heading {
    padding-left: 8px;
    font-size: 15px;
  }

  .modal-second {
    width: 100%;
  }

  .upload-img {
    background-color: #f2fdf7;
  }

  .publish-now {
    width: 150px;
  }

  .feed-modal-title {
    font-size: 18px;
  }

  .publish-now-disable {
    width: 150px;
  }

  .feed-modal {
    border-radius: 15px;
    width: 100%;
  }

  button.btn.upload-btn.btn-success {
    width: 300px;
    height: 45px;
  }

  button.btn.upload-btn.btn-danger {
    width: 300px;
    height: 45px;
  }
}
</style>