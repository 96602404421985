<template>
  <div class="py-4 container">
    <div class="container-fluid px-md-5">
      <div class="row center-on-desktop" v-if="!loading">
        <main
          class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          "
        >
          <ConnectionAside />
          <div class="mb-3 view-container">
            <ul
              class="nav request-tab justify-content-center"
              :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
              id="myTab"
              role="tablist"
            >
              <li class="justify-content-center">
                <a
                  class="active request-menu"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >{{ $t("sent") }} ({{ getConnectionsCounts.sentRequests }})</a
                >
              </li>
              <li class="justify-content-center">
                <a
                  class="request-menu"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >{{ $t("received") }} ({{
                    getConnectionsCounts.receivedRequests
                  }})</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <Connections2
                  :users="getSent"
                  type="requested"
                  emptyMessage="NoRequests"
                  :showSearch="false"
                  :sent="true"
                />
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <Connections2
                  :users="getReceived"
                  type="requested"
                  emptyMessage="NoRequests"
                  :showSearch="false"
                  :received="true"
                  class="recieved"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: `Bantaba | Community | ${this.$i18n.t("pendingRequests")}`,
    };
  },
  async created() {
    if (this.authUser.confirmed) {
    this.$store.commit("setShowDiasporaLoadMore", false);
    if (
      !JSON.parse(localStorage.getItem("sent")) ||
      !JSON.parse(localStorage.getItem("received"))
    ) {
      await this.loadReqested(this.authUser.id);
      //save to locale storage
      localStorage.setItem("sent", JSON.stringify(this.getSent));
      localStorage.setItem("received", JSON.stringify(this.getReceived));
    } else {
      let sent = JSON.parse(localStorage.getItem("sent"));
      let received = JSON.parse(localStorage.getItem("received"));
      this.$store.commit("setSent", sent);
      this.$store.commit("setReceived", received);
    }
    this.loading = false;

  } else {
      this.$noty.error(this.$i18n.t('NonApprovedMsg'));
    this.$router.go(-1);
  }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getSent",
      "getReceived",
      "getConnectionsCounts",
    ]),
  },
  methods: {
    ...mapActions(["loadReqested", "loadConnCounts"]),
  },
  data() {
    return {
      loading: true,
      sent: {},
      received: {},
      counts: {},
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Requested",
  // components: {
  //   Connections2,
  // },
};
</script>

<style scoped>
.request-tab {
  margin: 20px;
}
.request-tab li{
  list-style: none !important;
}
.request-menu {
  padding: 15px 25px !important;
  margin-right: 10px;
  width: 100% !important;
  border-radius: 10px;
  border: none !important;
  border-bottom: none !important;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #888da8 !important;
}
.tab {
  width: auto;
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.active {
  border: none !important;
}

.request-menu.active {
  font-weight: bold;
  background: #cfead9;
  color: #168b3f !important;
  border-bottom: none !important;
  border: none !important;
  border: 0px solid red !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    height: 100vh;
  }
  div#home {
    /* min-width: min(78vw, 1140px); */
    min-width: 990px;
  }
  .recieved {
    /* min-width: min(78vw, 1140px); */
    min-width: 990px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .center-on-desktop {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .container-fluid {
    min-height: 100vh;
  }
  .view-container {
    min-height: 80vh !important;
  }
  .mainBackground {
    background: transparent;
    border-radius: 1rem;
    padding: 0 1%;
    border: none;
    min-height: 60vh !important;
  }

  .request-tab {
    /* margin: 20px; */
    margin-top: 90px;
    z-index: 10000;
    /* background: red; */
  }
  .showNotVerifiedNotfication {
    margin-top: 120px;
  }
}
</style>

<style>

</style>

