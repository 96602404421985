<template>
  <div class="#">
    <div class="login-19">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 col-sm-12 bg-color-15 none-992 bg-img"></div>
          <div class="col-lg-7 col-sm-12 align-self-center form-section">
            <div class="form-inner py-2 mx-4">
              <h3 class="text-white">{{ $t("FinalSteps") }}</h3>
              <div
                class="alert alert-danger text-center mx-4"
                role="alert"
                v-if="errors.length"
              >
                <ul class="mx-4">
                  <li v-for="(erreur, index) in errors" :key="index">
                    {{ erreur }}
                  </li>
                </ul>
              </div>
              <form class="px-3" @submit.prevent="submit">
                <div class="form-group form-box col-md-6">
                  <label class="text-white"
                    >{{ $t("countryOfResidence") }}
                    <span class="text-danger">*</span></label
                  >
                  <VueMultiselect
                    v-model="form.CountryofResidence"
                    :options="foreignCountries"
                    :multiple="false"
                    track-by="name"
                    :custom-label="customLabel"
                    selectLabel=""
                    deselectLabel=""
                    :placeholder="$t('choose')"
                    :required="true"
                  />
                </div>
                <div class="form-group form-box col-md-6">
                  <label class="text-white"
                    >{{ $t("africanCountryOfOrigin") }}
                    <span class="text-danger">*</span></label
                  >
                  <VueMultiselect
                    v-model="form.AfricanCountryofOrigin"
                    :options="countriesLists"
                    :multiple="false"
                    track-by="name"
                    :custom-label="customLabel"
                    selectLabel=""
                    deselectLabel=""
                    :placeholder="$t('choose')"
                    :required="true"
                  />
                </div>

                <div class="form-group form-box col-md-12">
                  <label class="text-white"
                    >{{ $t("areaOfExpertise") }}
                    <span class="text-danger">*</span>(max:10)</label
                  >
                  <VueMultiselect
                    v-model="form.talents"
                    :options="allTalents"
                    :multiple="true"
                    track-by="name"
                    :custom-label="customLabel"
                    :max="10"
                    selectLabel=""
                    deselectLabel=""
                    required
                  />
                </div>
                <div class="form-group form-box col-md-6">
                  <label class="text-white"
                    >{{ $t("interrestedToSupport") }}
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    id="inputCountry"
                    class="input-text custom-select"
                    v-model="form.supportStartup"
                    required
                    @change="setSupport"
                  >
                    <option value="" selected disabled>
                      {{ $t("choose") }}
                    </option>
                    <option value="1">{{ $t("yes") }}</option>
                    <option value="0">{{ $t("no") }}</option>
                  </select>
                </div>

                <div class="form-group form-box col-md-6">
                  <label class="text-white">{{ $t("TypeOfSupport") }} </label>
                  <VueMultiselect
                    :disabled="!showSupport"
                    v-model="form.typeOfSupport"
                    :options="types"
                    :multiple="true"
                    track-by="name"
                    :custom-label="customLabel"
                    selectLabel="+"
                    deselectLabel="-"
                  />
                </div>
                <div class="form-group form-box col-md-12">
                  <label class="text-white"
                    >{{ $t("sectorOfInterrest") }} (max:5)</label
                  >

                  <VueMultiselect
                    v-model="form.sectorOfInterrest"
                    :options="allSectors"
                    :multiple="true"
                    track-by="name"
                    :custom-label="customLabel"
                    :disabled="!showSupport"
                    label="sectorOfInterrest"
                    placeholder="Select one"
                    :allow-empty="false"
                    :searchable="true"
                    :max="5"
                    selectLabel=""
                    deselectLabel=""
                  />
                </div>

                <div class="form-group form-box col-md-12">
                  <label class="text-white"
                    >{{ $t("Bio")
                    }}<span class="text-danger ml-1">*</span></label
                  >
                  <textarea
                    v-model="form.about"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    required
                    @focus="generateBio()"
                  ></textarea>
                </div>
                <div class="form-group clearfix">
                  <button
                    :disabled="loading"
                    type="submit"
                    class="
                      btn btn-primary btn-block
                      text-uppercase text-bold
                      p-3
                    "
                  >
                    <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                    {{ loading ? "" : $t("JoinNow") }}
                  </button>
                </div>
              </form>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../../i18n";
import store from "../../../store/";
import APIURLS from "../../../constant";
import moment from "moment";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";
import VueMultiselect from "vue-multiselect";
export default {
  beforeRouteEnter: (to, from, next) => {
    if (!store.getters.getRegistrationForm.profileType) {
      return next(`/${i18n.locale}/${i18n.t("routes.register")}`);
    }
    if (store.getters.authUser) {
      return next(`/${i18n.locale}/${i18n.t("routes.myActivity")}`);
    }
    next();
  },
  metaInfo() {
    return {
      title: `Bantaba | ${this.$i18n.t("register")} | Diaspora`,
    };
  },
  watch: {
    async $route() {
      await this.getCountries();
      await this.getForeignCountries();
    },
  },
  head() {
    return {
      script: [
        {
          src: "https://platform.linkedin.com/in.js",
          type: "text/javascript",
        },
      ],
    };
  },
  async mounted() {
    var lang = i18n.locale;
    await this.getCountries();
    await this.getForeignCountries();
    await this.loadSectorsList({ lang });
    await this.loadTalents({ lang });
    this.sectors = this.getSectorstList;
    this.talents = this.getTalents;

    this.talents.forEach((talent) => {
      this.allTalents.push({
        talent_id: talent.id,
        code: talent.code,
        name: talent.name,
      });
    });

    this.types = [
      {
        id: 1,
        code: "Invest",
        name: i18n.t("Invest"),
      },
      {
        id: 2,
        code: "Consulting",
        name: i18n.t("Consulting"),
      },
      {
        id: 3,
        code: "Mentoring",
        name: i18n.t("Mentoring"),
      },
    ];
    this.sectors.forEach((sector) => {
      this.allSectors.push({
        sector_id: sector.id,
        code: sector.code,
        name: sector.name,
      });
    });
    var countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    this.countries = countries.getNames(i18n.locale, { select: "official" });
  },
  computed: {
    ...mapGetters([
      "getSectorstList",
      "getTalents",
      "getRegistrationForm",
      "getCountriesList",
      "getForeignCountriesList",
    ]),
  },
  data() {
    return {
      countries: null,
      foreignCountries: [],
      result: null,
      sectors: {},
      allSectors: [],
      talents: {},
      types: [],
      countriesLists: [],
      allTalents: [],
      diaspora: true,
      startup: false,
      corporations: false,
      showSupport: false,
      disabled: false,
      AfricanCountryofOrigin: "",
      CountryofResidence: "",
      residenceContry: "",
      active1: "",
      active2: "",
      checked: "",
      form: {
        name: "",
        email: "",
        password: "",
        supportStartup: "",
        sectorOfInterrest: [],
        interrested: "",
        talents: [],
        typeOfSupport: [],
      },
      error: null,
      success: null,
      loading: false,
      errorMsg: "",
      errors: [],
    };
  },
  methods: {
    ...mapActions([
      "logUserIn",
      "loadSectorsList",
      "loadTalents",
      "loadCountriesList",
      "loadForeignCountriesList",
    ]),
    generateBio() {
      var expertises = this.form.talents.map(function (talent) {
        return talent["name"];
      });

      this.form.about =
        "I am from " +
        this.form.AfricanCountryofOrigin.name +
        " and residing in " +
        this.form.CountryofResidence.name +
        " I have expertise in " +
        expertises;

      if (this.form.supportStartup) {
        var supportTypes = this.form.typeOfSupport.map((support) => {
          return support["name"];
        });

        var sectorOfInterrest = this.form.sectorOfInterrest.map((sector) => {
          return sector["name"];
        });

        this.form.about +=
          " I am interested to support startups within " +
          sectorOfInterrest +
          ",by either " +
          supportTypes +
          ". Let's have a chat.";
      }
    },
    async getCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.countriesLists.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    async getForeignCountries() {
      var lang = i18n.locale;
      await this.loadForeignCountriesList({ lang });
      this.getForeignCountriesList.forEach((country) => {
        this.foreignCountries.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    setInterrested(bool) {},
    customLabel(option) {
      return `${option.name}`;
    },
    setSupport() {
      if (this.form.supportStartup === "1") {
        this.showSupport = true;
      } else {
        this.showSupport = false;
      }
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    submit() {
      this.errors = [];
      if (!this.form.CountryofResidence) {
        this.errors.push(
          this.$i18n.t("countryOfResidence") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.AfricanCountryofOrigin) {
        this.errors.push(
          this.$i18n.t("africanCountryOfOrigin") +
            " " +
            this.$i18n.t("required")
        );
      }
      if (!this.form.talents.length) {
        this.errors.push(
          this.$i18n.t("areaOfExpertise") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.supportStartup) {
        this.errors.push(
          this.$i18n.t("interrestedToSupport") + " " + this.$i18n.t("required")
        );
      }
      if (!this.form.about) {
        this.errors.push(
          this.$i18n.t("about") + " " + this.$i18n.t("required")
        );
      }
      if (
        this.form.supportStartup === 1 &&
        this.form.typeOfSupport.length === 0
      ) {
        this.errors.push(
          this.$i18n.t("TypeOfSupport") + " " + this.$i18n.t("required")
        );
      }
      if (!this.getRegistrationForm.profileType) {
        let url = `/${i18n.locale}/${i18n.t("routes.register")}`;
        if (this.$route.path != url) {
          this.$router.push(url);
        }
        // this.$router.push(`/${i18n.locale}/${i18n.t("routes.register")}`).catch(err => {});
      }
      if (
        this.form.CountryofResidence &&
        this.form.AfricanCountryofOrigin &&
        this.form.talents.length &&
        this.form.supportStartup &&
        this.form.about &&
        !(this.form.supportStartup === 1 && this.form.typeOfSupport.length === 0)
      ) {
        this.loading = true;
        this.$store.commit("setRegister", {
          property: "CountryofResidence",
          value: this.form.CountryofResidence.code,
        });
        this.$store.commit("setRegister", {
          property: "AfricanCountryofOrigin",
          value: this.form.AfricanCountryofOrigin.code,
        });
        this.$store.commit("setRegister", {
          property: "talents",
          value: this.form.talents,
        });
        this.$store.commit("setRegister", {
          property: "sectors",
          value: this.form.sectorOfInterrest,
        });

        this.$store.commit("setRegister", {
          property: "supportStartup",
          value: this.form.supportStartup,
        });
        this.$store.commit("setRegister", {
          property: "interrested",
          value: this.form.interrested,
        });
        this.$store.commit("setRegister", {
          property: "about",
          value: this.form.about,
        });
        this.$store.commit("setRegister", {
          property: "typeOfSupport",
          value: this.form.typeOfSupport,
        });
        let data = this.getRegistrationForm;
        data.emailData = {
          subject: this.$i18n.t("thanksForSignup"),
          buttonText: this.$i18n.t("verifyEmail"),
          email: data.email,
          link: `${APIURLS.SITELINK}${i18n.locale}/activate/`,
          text: this.$i18n.t("confirmEmailText"),
          title: this.$i18n.t("thanksForSignup"),
          name: data.name,
          thanks: this.$i18n.t("thanks"),
        };
        data.type = 1;
        Axios.post(APIURLS.AUTH + "register", data)
          .then((response) => {
            const loginDetails = {
              email: data.email,
              password: data.password,
            };
            this.logUserIn(loginDetails)
              .then((response) => {
                this.form.email = "";
                this.form.password = "";
                this.loading = false;
                this.$noty.success("Success");
                let url = `/${i18n.locale}/${i18n.t("routes.register")}`;
                if (this.$route.path != url) {
                  this.$router.push(url);
                }
                // this.$router.push(
                //   `/${i18n.locale}/${i18n.t("routes.myActivity")}`
                // ).catch(err => {});
              })
              .catch((error) => {
                this.loading = false;
                this.error = true;
                this.errorMsg = error.response.data.message;
                this.$noty.error(this.errorMsg);
              });
          })
          .catch((error) => {
            console.log("error", error);
            console.log(
              "error.response.data.message",
              error.response.data.message
            );
            this.loading = false;
            this.errorMsg = error.response.data.message;
            this.errors.push(this.errorMsg);
            this.$noty.error(this.errorMsg);
          });
      }
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Complete",
  components: {
    VueMultiselect,
  },
};
</script>

<style>
.select2-search__field {
  padding: 10px !important;
  /* background-color: red !important; */
}
.custom-select:disabled {
  color: #ccc;
  background-color: rgb(216, 216, 216) !important;
}
</style>
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.vue-form input[type="radio"],
.vue-form input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.vue-form input[type="radio"] + label,
.vue-form input[type="checkbox"] + label {
  padding-left: 24px;
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after {
  border-radius: 50%;
}
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.vue-form input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="radio"]:focus + label::before,
.vue-form input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::after {
  display: block;
}
.vue-form input[type="radio"]:checked + label::before,
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::before,
.vue-form input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}

.form-box label {
  font-size: 14px !important;
}
</style>
