import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            isShow: false,
            resolve: null,
            reject: null,
            code: null,
            results: null,
        };
    },
    computed: {
        ...mapGetters(["authUser"]),
    },
    methods: {
        ...mapActions(['checkStartupCompleted', 'getStartupByUserId', 'createClickTrack']),
        show() {
            this.isShow = true
            return new Promise(((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            }))
        },

        hide() {
            this.isShow = false
        },

        close() {
            this.isShow = false

        },
        async apply(code, link, offer_code) {
            this.$store.commit('SET_LOADING', true);
            let startup = await this.getStartupByUserId(this.authUser.id);
            let data = {
                user_id: this.authUser.id,
                username: this.authUser.username,
                startup_name: this.authUser.startup.startupname,
                email: this.authUser.email,
            };
            let completed;
            switch (code) {
                case 'aws':
                case 'miro':
		case 'notion':
                    completed = await this.checkStartupCompleted(startup.id);
                    this.$store.commit('SET_LOADING', false);
                    if (completed.score < 100) {
                        this.$store.commit('SET_APPLY_RESULTS', completed.results);
                        data.type = 'failed';
                        let resp = await this.createClickTrack(data);
                        this.reject(this.hide);
                    } else {
                        this.$store.commit('SET_OFFER_CODE', offer_code);
                        this.$store.commit('SET_COMPANY_CODE', code);
                        data.type = 'success';
                        data.company_code = code;
                        let resp = await this.createClickTrack(data);
                        this.resolve(this.show);
                    }
                    break;
                default:
                    if (link != null) window.location.href = link;

            }
        }

    },
    beforeDestroy() {
        this.resolve = null
        this.reject = null
    }
}
