<template>
  <div class="#">
    <div class="box shadow-sm border rounded bg-white mb-3">
      <div class="widget widget-user-about">
        <div class="widget-heading">
          <h3 class="widget-title">{{ $t('languages') }}</h3>
        </div>
        <div class="box-body" v-if="languages.length">
          <div
            class="d-flex align-items-center osahan-post-header py-1 border-bottom people-list"
            v-for="(language, index) in languages"
            :key="index"
          >
            <div class="dropdown-list-image mr-3">
              <img
                class="rounded-circle"
                src="https://res.cloudinary.com/yanfomaweb/image/upload/v1611151891/Bantaba/lang.png"
                alt="language"
              />
            </div>
            <div class="font-weight-bold">
              <div class="text-truncate">
                {{ $t(language.name) }}
                <span class="badge badge-success text-white ml-1">{{
                  $t(language.level)
                }}</span>
              </div>
              <div class="small text-muted">
                <span v-if="language.grade && language.grade">
                  {{ language.test }}: {{ language.grade }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <NoData
          v-else
          text="noLanguage"
          buttonText="addLang"
          :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.cursus')}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Languages',
  props: {
    languages: {},
  },
};
</script>
