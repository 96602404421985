<template>
  <button :class="hasText ? 'see-more-btn' : 'see-more-btn-no-button'">
    <span v-if="hasText">{{ $t(text) }}</span>
    <img
      class="step-btn-icon"
      src="../../assets/registration/icons/arrow-down.svg"
    />
  </button>
</template>

<script>
export default {
  name: "SeeMoreButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    hasText: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}

.see-more-btn-no-button,
.see-more-btn {
  border: none;
  position: absolute;
  color: #808896;
  border: 1px solid rgba(0, 0, 0, 0.283);
  background-color: rgba(255, 255, 255, 0.577);
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}
.see-more-btn-no-button {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  /* left: calc(50% - 20px); */
  bottom: 20px;
  position:fixed;
  z-index: 1000;
  transform: translateX(calc(25vw - 25px)); 
  /* background-color: orange; */
  display: none;
}
.see-more-btn-no-button img{
  height: 16px;
}

.see-more-btn {
  width: 150px;
  height: 60px;
  border-radius: 8px;
  left: calc(50% - 75px);
  bottom: 15px;
}

.see-more-btn-no-button span,
.see-more-btn span {
  padding: 0 10px;
}
.see-more-btn-no-button:hover,
.see-more-btn:hover {
  background-color: var(--first-color);
  color: white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .see-more-btn-no-button {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  left: calc(50% - 20px);
  bottom: 65px;
  position: fixed;
  z-index: 1000;
  transform: translateX(calc(50% - 25px));
  /* background-color: orange; */
}
.see-more-btn-no-button img{
  height: 20px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
</style>