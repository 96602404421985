<template>
  <div>
    <div class="py-4">
      <div class="container-fluid px-md-5">
        <div class="row" v-if="!loading">
          <main class="col-md-10 box bg-white rounded my-4 p-4">
            <div class="border rounded bg-white mb-3">
              <div class="box-title border-bottom px-5 py-3">
                <h6 class="m-0">{{ $t("editStartupInfo") }}</h6>
              </div>
              <div class="box-body px-5 py-3">
                <form @submit.prevent="updateBasicProfil">
                  <div class="row">
                    <div class="col-sm-12 mb-2">
                      <div class="js-form-message">
                        <label id="nameLabel" class="form-label">
                          {{ $t("nameFristName") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            v-model="profile.user.name"
                            :placeholder="$t('namePlaceholder')"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-sm-6 mb-2">
                      <label id="usernameLabel" class="form-label">
                        {{ $t('username') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          name="username"
                          v-model="profile.user.username"
                          :placeholder="$t('usernamePlaceholder')"
                          required
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            @click="checkUsername(profile.user.username)"
                          >
                            <i class="feather-check-circle mr-1"></i>
                            {{ $t('check') }}
                          </span>
                        </div>
                      </div>
                      <div class="match m-1" v-if="availability">
                        {{ availabilityText }}
                      </div>
                      <div class="mismatch m-1" v-else>
                        {{ availabilityText }}
                      </div>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="emailLabel" class="form-label">
                          {{ $t("email") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            required
                            :placeholder="$t('emailPlaceholder')"
                            v-model="profile.user.email"
                          />
                          <small class="form-text text-muted">{{
                            $t("neverShareInfo")
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="phoneNumberLabel" class="form-label">
                          {{ $t("phone") }}
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="phone"
                            :placeholder="$t('phone')"
                            v-model="profile.phone"
                          />
                          <small class="form-text text-muted"
                            >{{ $t("neverShareInfo") }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("countryOfResidence") }}
                          <!-- <span class="text-danger">*</span> -->
                        </label>
                        <div class="form-group">
                          <country-select
                            v-model="profile.residenceContry"
                            :countryName="true"
                            :autocomplete="true"
                            :disablePlaceholder="true"
                            :use188n="false"
                            :country="profile.residenceContry"
                            class="form-control custom-select"
                            :placeholder="$t('selectCountryOfResidence')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("cityOfResidence") }}
                          <!-- <span class="text-danger">*</span> -->
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="location"
                            v-model="profile.residenceCity"
                            :placeholder="$t('cityOfResidencePlaceholder')"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("africanCountryOfOrigin") }}
                          <!-- <span class="text-danger">*</span> -->
                        </label>
                        <div class="form-group">
                          <country-select
                            v-model="profile.birthCountry"
                            :countryName="true"
                            :autocomplete="true"
                            :usei18n="true"
                            :disablePlaceholder="true"
                            :country="profile.birthCountry"
                            class="form-control custom-select"
                            :placeholder="$t('selectAfricanCountryOfOrigin')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label class="form-label">
                          {{ $t("preferredLanguage") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <select
                            class="custom-select"
                            v-model="profile.preferedLanguage"
                          >
                            <option disabled>
                              {{ $t("selectLanguage ") }}
                            </option>
                            <option
                              :value="lang"
                              v-for="(lang, i) in $i18n.availableLocales"
                              :key="i"
                            >
                              {{ getLang(lang) }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 text-right">
                    <div class="overflow-hidden text-center">
                      <button
                        :disabled="btnLoadingBasic"
                        type="submit"
                        class="
                          font-weight-bold
                          btn btn-light
                          rounded
                          p-3
                          btn-block
                        "
                      >
                        <i
                          class="fa fa-spin fa-1x fa-spinner"
                          v-if="btnLoadingBasic"
                        ></i>
                        {{ loading ? "" : $t("save") }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../../constant";
import { mapGetters, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import i18n from "../../../i18n";
import { en, fr } from "vuejs-datepicker/dist/locale";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Admin ",
    };
  },
  async created() {
    await this.loadStartup();
    this.startup = this.getStartup;
    var lang = i18n.locale;
    await this.loadSectorsList({ lang });
    this.sectors = this.getSectorstList;

    await this.loadProfile();
    this.profile = this.getProfile;
    //if()
    if (this.profile) {
      this.profile.residenceContry = this.fortmatCountry(
        this.profile.residenceContry
      );
      this.profile.birthCountry = this.fortmatCountry(
        this.profile.birthCountry
      );
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getStartup", "getSectorstList", "getProfile"]),
  },
  data() {
    return {
      result: null,
      en: en,
      fr: fr,
      DatePickerFormat: "yyyy",
      startup: {},
      sectors: {},
      profile: {},
      loading: true,
      btnLoading: false,
      btnLoadingBasic: false,
      btnLoadingSocials: false,
      availabilityText: null,
      availability: null,
    };
  },
  methods: {
    ...mapActions([
      "loadStartup",
      "loadSectorsList",
      "loadProfile",
      "updateBasicInfo",
    ]),
    getLang(locale) {
      var value = "navBar." + locale;
      return this.$i18n.t(value);
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    checkUsername(username) {
      Axios.get(APIURLS.USERS + "check-username/" + username).then(
        (response) => {
          if (this.authUser.username === username) {
            this.availability = true;
            this.availabilityText = `${this.$i18n.t("yourUsername")}`;
          } else if (response.data) {
            this.availability = true;
            this.availabilityText = `${this.$i18n.t("usernameAvailable")}`;
          } else {
            this.availability = false;
            this.availabilityText = `${this.$i18n.t("usernameNotAvailable")}`;
          }
        }
      );
    },
    updateBasicProfil() {
      this.btnLoadingBasic = true;
      const preferedLanguage = localStorage.getItem("preferedLanguage");
      if (preferedLanguage) {
        if (this.profile.preferedLanguage != preferedLanguage) {
          localStorage.removeItem("preferedLanguage");
          localStorage.setItem(
            "preferedLanguage",
            this.profile.preferedLanguage
          );
        }
      } else {
        if (this.profile.preferedLanguage) {
          localStorage.setItem(
            "preferedLanguage",
            this.profile.preferedLanguage
          );
        }
      }

      const basicInfo = {
        name: this.profile.user.name,
        email: this.profile.user.email,
        username: this.profile.user.username,
      };
      const extendedInfo = {
        phone: this.profile.phone,
        residenceContry: this.countryCode(this.profile.residenceContry),
        residenceCity: this.profile.residenceCity,
        birthCountry: this.countryCode(this.profile.birthCountry),
        preferedLanguage: this.profile.preferedLanguage,
      };
      this.$store
        .dispatch("updateBasicInfo", {
          basicInfo: basicInfo,
          extendedInfo: extendedInfo,
        })
        .then((success) => {
          this.$noty.success(`${i18n.t("success")}`);
          this.btnLoadingBasic = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Admin",
};
</script>
