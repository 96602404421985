<template>
  <section class="related background container-lg round-lg">
    <h1 class="related-title">Related Posts</h1>

    <NewsCard
      :article="{
        title: 'Hey',
        slug: 'hey',
        country: 'BF',
        summary: 'hey',
        fullText: 'hey',
        source: 'hey',
        sourceName: 'lol',
        publishedDate: 'sddssd',
      }"
      v-for="i in 10"
      :key="i"
    />

    <div class="article-cards-wrapper"></div>
  </section>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import NewsCard from "./NewsCard.vue";
import i18n from "../../i18n";
export default {
  methods: {
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  name: "RelatedNews",
  component: {
    NewsCard,
  },
  props: {
    news: {},
  },
};
</script>

<style scoped>
.related {
  padding: 40px;
  box-sizing: border-box;
}
.related-title {
  color: #333;
  font-size: 25px;

  border-bottom: 1px solid #777;
}
</style>
