<template>
  <div class="p-5 rounded bg-white  text-center">
    <imageUploadModal :isShow="isUploadModal" :loading="loading" :showPreview="showPreview" @closeModal="closeModal"
      @showPreviewTrue="showPreviewTrue" @submit="submit" :startVal="startVal" :endVal="endVal" :decimals="decimals"
      :options="options" :isProgressBar="false">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
        <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

        <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress jss-progress-bar" />
      </svg>
    </imageUploadModal>
    <div class="m-profile-placeholder p-2 mx-3 border-bottom">
      <cld-image class="img-profile rounded-circle avatar mx-2" cloudName:bantaba :publicId="avatar" alt="user avatar"
        v-if="!showPreview">
        <cld-transformation height="auto" width="auto" />
        <cld-transformation border="5px_solid_red" radius="20" />
      </cld-image>
      <div class="cropper-container" v-else>
        <!-- <VueCropper ref="cropper" :aspect-ratio="1 / 1" :src="avatar" :viewMode="1" preview=".preview" :container-style="{
          height: '100%',
          padding: '0',
          display: 'flex',
          maxHeight: '300px',
          width: '400px',
        }" :img-style="{ width: '400px', height: '300px' }" /> -->
        <form class="upload-btn-options" enctype="multipart/form-data" action="#" @submit.prevent="submit">
          <button v-if="buttonChoice === 'upload'" data-toggle="tooltip" data-placement="top"
            data-original-title="Upload" type="submit" :disabled="loading" class="btn btn-success ml-3">
            <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
            <i class="feather-upload-cloud" v-else></i>
            {{ loading ? "..." + $t("upload") : $t("upload") }}
          </button>
          <button v-if="buttonChoice === 'upload'" data-toggle="tooltip" data-placement="top"
            data-original-title="Delete" type="submit" @click="cancel" class="btn btn-danger ml-3">
            <i class="feather-trash-2"></i> {{ $t("cancel") }}
          </button>
        </form>
      </div>


    </div>
    <div class="py-1 my-2">
      <form enctype="multipart/form-data" action="#">
        <label v-if="buttonChoice === 'select'" @click="triggerImgModalTrue" data-toggle="tooltip" data-placement="top"
          data-original-title="Upload New Picture" class="btn btn-info m-0" for="fileAttachmentBtn">
          <i class="feather-upload" v-if="is_team"> {{ $t("select") }}</i>
          <i class="feather-upload" v-else> {{ $t("change") }}</i>

        </label>
        <!-- <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload"
          type="submit"
          :disabled="loading"
          class="btn btn-success ml-3"
        >
          <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
          <i class="feather-upload-cloud" v-else></i>
          {{ loading ? "..." + $t("upload") : $t("upload") }}
        </button>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete"
          type="submit"
          @click="cancel"
          class="btn btn-danger ml-3"
        >
          <i class="feather-trash-2"></i> {{ $t("cancel") }}
        </button> -->
      </form>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { mapGetters, mapActions } from "vuex";
import store from "../../store";
import imageUploadModal from "./../Profile/Diaspora/profile/main/imageUploadModal.vue";
export default {
  async created() {
    this.avatar = this.profileAavatar;
  },
  mounted() {
    store.commit("SET_IMAGE_MODAL", false);
  },
  computed: {
    ...mapGetters(["authUser", "getProfile"]),
  },
  data() {
    return {
      isActive: false,
      startVal: 0,
      endVal: 0,
      decimals: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 3,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      showPreview: false,
      cropImg: "",
      avatar: null,
      buttonChoice: "select",
      image: null,
      file: "",
      fileContents: null,
      cloudName: "bantaba",
      preset: "Users-Avatars",
      formData: null,
      loading: false,
      isUploadModal: false
    };
  },
  methods: {
    triggerImgModalTrue() {
      store.commit("SET_IMAGE_MODAL", true);
      this.isUploadModal = true;
      console.log(store.state.isImageModal.open);
    },
    showPreviewTrue(value) {
      this.showPreview = value;
    },
    closeModal() {
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isUploadModal = false;
      store.commit("SET_IMAGE_MODAL", false);
      console.log(this.isUploadModal)
      var percentageComplete = 0;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
      this.startVal = 0;
      this.endVal = 0;
      this.cropImg = "";
      this.image = null;
      this.formData = null;
    },
    ...mapActions(["loadProfile", "updateProfile"]),
    onFileChange(e) {
      this.showPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.avatar = URL.createObjectURL(file);
      this.buttonChoice = "upload";
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropImg);
    },
    submit(obj) {
      this.cropImg = obj.cropedImageData;
      this.startVal = 0;
      this.endVal = 0;
      this.loading = true;
      var zeroPercentageComplete = 0;
      var zeroStrokeDashOffsetValue = 100 - zeroPercentageComplete * 100;
      var progressBar = document.querySelector(".jss-progress-bar");
      progressBar.style = `stroke-dashoffset: ${zeroStrokeDashOffsetValue}`;
      var percentageComplete = 0.5;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var afterPercentageComplete = 1;
      var afterStrokeDashOffsetValue = 100 - afterPercentageComplete * 100;
      setTimeout(() => {
        this.startVal = 0;
        this.endVal = 50;
        progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
        console.log(this.startVal, this.endVal);
      }, 100);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          console.log('0')
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              console.log('1')
              this.results = response.data;
              this.image = this.results.secure_url;
              if (this.is_team) {
                this.startVal = 50;
                this.endVal = 100;

                progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
                setTimeout(() => {
                  this.$store.commit("setTeamTempImage", { image: this.image });
                  this.avatar = this.results.secure_url;
                  this.showPreview = false;
                  this.$noty.success(this.$i18n.t("success"));
                  this.loading = false;
                  this.buttonChoice = "select";
                  this.closeModal();
                }, 3800);
              } else {
                this.$store
                  .dispatch("updateProfile", { avatar: this.image })
                  .then((success) => {
                    this.startVal = 50;
                    this.endVal = 100;

                    progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
                    setTimeout(() => {
                      console.log('2')
                      let auth = JSON.parse(localStorage.getItem("auth"));
                      auth.avatar = this.image;
                      localStorage.removeItem("auth");
                      localStorage.setItem("auth", JSON.stringify(auth));
                      this.$store.commit("setAuthUser", auth);
                      this.image = null;
                      this.showPreview = false;
                      this.avatar = this.results.secure_url;
                      this.$noty.success(this.$i18n.t("avatarUpdated"));
                      this.loading = false;
                      this.buttonChoice = "select";
                      this.closeModal();
                    }, 3800);
                  })
                  .catch((error) => {
                    console.log('3')
                    this.startVal = 50;
                    this.endVal = 100;
                    setTimeout(() => {
                      this.loading = false;
                      this.showPreview = false;
                      this.$noty.error(error);
                      this.closeModal();
                    }, 3800);

                  });
              }
            })
            .catch((error) => {
              console.log('4')
              this.startVal = 50;
              this.endVal = 100;
              setTimeout(() => {
                this.loading = false;
                this.errors.push(error);
                this.closeModal();
              }, 3800);
            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(obj.file);
    },
    cancel() {
      this.avatar = this.profileAavatar;
      this.showPreview = false;
      this.buttonChoice = "select";
    },
  },
  name: "UpdateAvatar",
  components: {
    // VueCropper,
    imageUploadModal
  },
  props: {
    is_team: Boolean,
    profileAavatar: {
      type: String,
    },
  },
};
</script>
<style scoped>
.m-profile-placeholder {
  background-color: #eee;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  padding: 0 !important;
}

.m-profile-placeholder img {
  /* width: 60%;
  height: 60%;
  object-fit: cover; */
  width: 90%;
  height: 90%;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0 !important;
}

.cropper-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  /* max-width: 30px; */
  margin-top: 0;
  background: #000000c4;
  /* transform: translateX(calc(50vw - 40vh)); */
  /* transform: translateX(calc(50vw - 150px)); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;
  /* pointer-events: none; */
}

.upload-btn-options {
  padding: 10px 0;
  z-index: 999 !important;
}

@media (max-width: 600px) {
  .cropper-container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    /* max-width: 30px; */
    margin-top: -130vh;
    background: rgba(0, 0, 0, 0.144);
    /* transform: translateX(calc(50vw - 40vh)); */
    /* transform: translateX(calc(50vw - 150px)); */
    display: flex;
    z-index: 9999;
  }
}
</style>

