<template>
  <div id="app">
    <!-- <div id="push-permission"></div> -->

    <vue-snotify class="notifications"></vue-snotify>
    <div id="nav">
      <a
        style="cursor: pointer"
        :style="{ display: installBtn }"
        @click="installer()"
      >
        <h1>Install!</h1>
      </a>
    </div>
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
  </div>
</template>

<script>
const default_layout = "DefaultLayout";
import { mapGetters, mapActions } from "vuex";
export default {
  created() {
    let installPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!");
        } else {
          console.log("Install denied!");
        }
      });
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters(["authUser"]),
    layout() {
      return this.$route.meta.layout || default_layout;
    },
  },
  data() {
    return {
      points: 0,
      installBtn: "none",
      installer: undefined,
    };
  },
  methods: {
    ...mapActions(["loadprofileStrength"]),
  },
  name: "App",
};
</script>
<style>
* p {
  text-align: left !important;
}
.notifications {
  position: absolute;
  z-index: 100000 !important;
}
</style>
