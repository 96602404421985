const state = () => ({
    directMessage: {
        daily: false,
        weekly: false
    },
    activityAroundUp: {
        daily: false,
        weekly: false
    },
    newStartups: {
        daily: false,
        weekly: false
    },
    connectionRequest: {
        daily: false,
        weekly: false
    },
    password: '',
    confirmPassword: '',
    email: '',
    confirmEmail: '',
    language: 'en',
    currentEmail: 'some-current@email.com',
});

const getters = {};

const actions = {};

const mutations = {
    setPassword: (state, password) => state.password = password,
    setEmail: (state, email) => state.email = email,
    setConfPassword: (state, password) => state.confirmPassword = password,
    setConfEmail: (state, email) => state.confirmEmail = email,
    setLanguage: (state, language) => state.language = language,

    setDirectMessage: (state, value) => state.directMessage = value,
    setActivityAroundUp: (state, value) => state.activityAroundUp = value,
    setNewStartups: (state, value) => state.newStartups = value,
    setConnectionRequest: (state, value) => state.connectionRequest = value,
};

export default {
    state,
    getters,
    mutations,
    actions,
};
