<template>
  <div :class="{
    hideStyles: avatar.includes(`https://res.cloudinary.com/bantaba/`),
  }" class="profile-card">
    <imageUploadModal :isShow="isUploadModal" :loading="loading" :showPreview="showPreview" @closeModal="closeModal"
      @showPreviewTrue="showPreviewTrue" @submit="submit" :startVal="startVal" :endVal="endVal" :decimals="decimals"
      :options="options" />
    <form class="cropview" enctype="multipart/form-data" action="#" @submit.prevent="submit">
      <label for="image" class="imgage-container" @click="isUploadModal = true" style="cursor: pointer;">
        <!-- <input
          type="file"
          @change="onFileChange"
          name="image"
          id="image"
          style="display: none"
        /> -->
        <img :src="avatar" class="img-fluid mt-2" :class="{
          previewAvatar: avatar.includes(
            `https://res.cloudinary.com/bantaba/`
          ),
        }" alt="avatar" v-if="!showPreview" />
        <!-- <div class="cropperContainer" v-else>
          <VueCropper
            class="Showmobile"
            ref="cropper"
            :aspect-ratio="1 / 1"
            :src="avatar"
            :viewMode="1"
            :dragMode="none"
            :autoCrop="false"
            preview=".preview"
            :container-style="{
              height: '100%',
              marginTop: 'calc(-100% - 400px)',
              padding: '0',
              display: 'flex',
              maxHeight: '100vw',
              maxWidth: '600px',
            }"
            :img-style="{
              width: '100vw',
              height: 'auto',
              maxWidth: '100vw',
              maxHeight: '300px',
            }"
          />
          <VueCropper
            class="ShowDesktop"
            ref="cropper"
            :aspect-ratio="1 / 1"
            :src="avatar"
            :viewMode="1"
            :dragMode="none"
            :autoCrop="false"
            preview=".preview"
            :container-style="{
              height: '100%',
              marginTop: '500px',
              padding: '0',
              display: 'flex',
              maxHeight: '300px',
              width: '400px',
            }"
            :img-style="{
              width: '100%',
              height: 'auto',
              maxWidth: '300px',
              maxHeight: '300px',
            }"
          />
        </div> -->
      </label>
      <p class="profile-text" :class="{ hideTextPlaceholder: isShowPreview }">
        <span class="profile-text-placeholder">{{ $t("Add Photo") }} </span>
      </p>
      <!-- <div class="row upload-options-container">
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload"
          type="submit"
          :disabled="loading"
          class="btn btn-success ml-3"
        >
          <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
          <i class="feather-upload-cloud" v-else></i>
          {{ loading ? "..." + $t("upload") : $t("upload") }}
        </button>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete"
          type="submit"
          @click="cancel"
          class="btn btn-danger ml-3"
        >
          <i class="feather-trash-2"></i> {{ $t("cancel") }}
        </button>
      </div> -->
    </form>
  </div>
</template>
<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapState, mapMutations, mapGetters } from "vuex";
import imageUploadModal from "./../Profile/Diaspora/profile/main/imageUploadModal.vue";

export default {
  mounted() {},
  computed: {
    ...mapGetters(["getFomData", "getAccountType"]),
  },
  data: () => {
    return {
      isActive: false,
      startVal: 0,
      endVal: 0,
      decimals: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 3,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      showPreview: false,
      isShowPreview: false,
      cropImg: "",
      avatar: require("@/assets/registration/icons/camera.svg"),
      buttonChoice: "select",
      image: null,
      file: "",
      fileContents: null,
      cloudName: "bantaba",
      preset: "Users-Avatars",
      formData: null,
      loading: false,
      isUploadModal: false
    };
  },

  methods: {
    showPreviewTrue(value) {
      this.showPreview = value;
    },
    closeModal() {
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isUploadModal = false;
      console.log(this.isUploadModal)
      var percentageComplete = 0;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
      this.startVal = 0;
      this.endVal = 0;
      this.cropImg = "";
      this.image = null;
      this.formData = null;
    },
    ...mapMutations(["setAccountType"]),
    onFileChange(e) {
      this.showPreview = true;
      this.isShowPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.avatar = URL.createObjectURL(file);
      this.buttonChoice = "upload";
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropImg);
    },
    cancel() {
      this.avatar = require("@/assets/registration/icons/camera.svg");
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isShowPreview = false;
    },
    submit(obj) {
      // perform the crop
       this.cropImg = obj.cropedImageData;
      this.startVal = 0;
      this.endVal = 0;
      this.loading = true;
      var zeroPercentageComplete = 0;
      var zeroStrokeDashOffsetValue = 100 - zeroPercentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${zeroStrokeDashOffsetValue}`;
      var percentageComplete = 0.5;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var afterPercentageComplete = 1;
      var afterStrokeDashOffsetValue = 100 - afterPercentageComplete * 100;
      setTimeout(() => {
        this.startVal = 0;
        this.endVal = 50;
        progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
        console.log(this.startVal, this.endVal);
      }, 100);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.startVal = 50;
                  this.endVal = 100;
             
              progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
                  setTimeout(() => {
              this.results = response.data;
              this.image = this.results.secure_url;
              this.showPreview = false;
              this.avatar = this.results.secure_url;
              this.$noty.success(this.$i18n.t("avatarUpdated"));
              this.loading = false;
              this.buttonChoice = "select";
              this.closeModal();
              this.$store.commit("setFormData", {
                field: "avatar",
                value: this.avatar,
              });
              localStorage.setItem("avatar", this.avatar);
               }, 3800);
            })
            .catch((error) => {
               this.startVal = 50;
                  this.endVal = 100;
                  setTimeout(() => {
                    this.loading = false;
              this.errors.push(error);
                    this.closeModal();
                  }, 3800);
            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(obj.file);
    },
  },
  name: "ProfileUlpoad",
  components: { imageUploadModal },
};
</script>
<style scoped lang="scss">
.drag-button {
  background-color: #011108;
  color: #ffffff;
  font-size: 14px;
  z-index: 500;
  margin-top: -60px;
  cursor: default;
  position: absolute;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-image-modal-footer {
  border-top: 1px solid rgb(222 226 230);
}

$progress-bar-stroke-width: 3;
$progress-bar-size: 166px;

svg {
  height: $progress-bar-size;
  //transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #c9e9d7;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #c9e9d7;
  stroke: #009743;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 3s ease-in-out;
}

.modal-trigger-heading {
  font-size: 20px;
}

.feed-modal-title {
  font-size: 20px;
  color: #011108;
  font-weight: 700 !important;
  font-family: poppins, sans-serif !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.cross-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #5b615e;
}

.cross-svg-icon {
  width: 16px;
  height: 16px;
  color: #009743;
}

.create-feed-modal {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex;
  z-index: 15000 !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.feed-modal {
  border-radius: 20px;
  width: 645px;
}

.upload-img {
  background-color: transparent !important;
  border: 1px solid rgb(238 238 238);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.upload-img:hover {
  background-color: rgb(233, 230, 230) !important;
}

.publish-now {
  background-color: #009743;
  color: white;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.publish-now:hover {
  background-color: #09b657;
  color: white;
}

.modal-custom-body {
  padding: 0px !important;
}

.profile-card.hideStyles {
  background: transparent;
  border: none;
  padding-top: 20px;
  display: flex;
}

.hideTextPlaceholder {
  display: none !important;
}

.offsetCropper {
  position: fixed !important;
  top: -100px !important;
}

.cropperContainer {
  position: fixed;
  /* top: 0; */
  left: 0;
  right: 0;
  /* background: orange; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 300px; */
  z-index: 1000;
  width: 100vw;
  height: 100vw;
  bottom: 60px;
}

.profile-card {
  width: min(305px, 48%);
  height: 225px;
  background-color: #f0f4f7;
  border: 1px solid #888c93;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 54px;
  border: 2px solid #888c9342;
  /* height: 125px; */

  width: 100%;
  border-radius: 24px;
}

.profile-icon {
  width: 50px;
}

.profile-text {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  position: absolute;
  top: calc(50% - 13.5px);
  right: calc(50% - 100px);
  font-size: 18px;
  font-weight: 600px;
  pointer-events: none;
}

span.profile-text-placeholder {
  font-weight: bold;
}

.cover-text-placeholder {
  position: absolute;
  top: -150px;
  left: -39px;
  background-color: #00000054;
  color: white;
}

/* form.cropview {
    background: green;
    width: 100vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    border-radius: 20px;
    padding: 20px 0;
} */
form.cropview {
  height: 100%;
  margin-top: 10px;
  width: 100%;
}

.upload-options-container {
  position: absolute;
  z-index: 5000000;
  /* bottom: 20px; */
  left: 0;
  right: 0;
  top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center; */
}

.imgage-container {
  /* background-color: red; */
  /* background-color: red; */
  width: 100%;
  height: 100%;
  /* padding-left: 20px; */
  /* padding-left: 250px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

img.img-fluid.mt-2 {
  transform: translateX(-50px);
}

img.img-fluid.mt-2.previewAvatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  max-width: 100px;
  margin-top: 10px;
  border-radius: 50%;
  border: 3px solid red;
}

.Showmobile {
  display: none;
}

.ShowDesktop {
  display: flex;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .imgage-container {
    /* background-color: red; */
    /* background-color: red; */
    width: 100%;
    height: 100%;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  img.img-fluid.mt-2 {
    transform: translateX(-80px);
  }

  .Showmobile {
    display: block;
    width: 100vw;
    /* margin-bottom: 400px; */
    transform: translateX(100px);
  }

  .ShowDesktop {
    display: none;
  }

  .profile-card {
    width: 100%;
    height: 80px;
    border-radius: 28px;
    background-color: #f0f4f7;
    border: 1px solid #888c93;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    /* padding: 0 60px; */
    padding: 0px;
    overflow: inherit;
    position: relative;
  }

  form.cropview {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    /* background: green; */
    position: relative;
    /* flex-direction: column;
  padding-bottom: 120px; */
  }

  .profile-icon {
    width: 20px;
  }

  .img-fluid {
    max-width: 30px;
    height: auto;
    position: relative;
  }

  .img-fluid::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: rgb(255, 0, 0);
  }

  .profile-upload h3 {
    font-size: 20px;
    color: #808896;
    padding: 0;
  }

  .profile-upload h5 {
    font-size: 16px;
    padding: 0;
    color: #b4b9c3;
    font-weight: lighter;
  }

  .profile-text {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    position: absolute;
    left: 70px;
    top: calc(50% - 9px);
  }

  .upload-options-container {
    position: absolute;
    z-index: 5000000;
    /* bottom: -60px; */
    left: -60px;
    /* left: 0px;
    right: 0px; */
    padding: 0 0px;
    padding-top: 0px;
    /* left: 50px; */
    /* right: 50px; */
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    top: 60px;
    /* flex-direction: column;  */
  }
}
</style>