import Dashboard from '../views/Dashboard.vue';
import ScrollToPostDashboard from '../views/ScrollToPostDashboard.vue';
import Terms from '../views/GDPR/Terms.vue';
import Privacy from '../views/GDPR/Privacy.vue';
import Cookies from '../views/GDPR/Cookies.vue';
import Notifications from '../views/profile/Notifications.vue';
import Partnerships from '../views/partnerships/All';
import { authGuard } from './guard';
import i18n from '../i18n';

var userLang = navigator.language || navigator.userLanguage;

var defaultLang = userLang.substring(0, 2);

i18n.locale = defaultLang;

export default [
  {
    path: 'home',
    name: 'homeEn',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: 'home/:post_uuid',
    name: 'homePostEn',
    component: ScrollToPostDashboard,
    beforeEnter: authGuard
  },
  {
    path: 'accueil/:post_uuid',
    name: 'homePostFr',
    component: ScrollToPostDashboard,
    beforeEnter: authGuard
  },

  {
    path: 'accueil',
    name: 'homeFr',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: 'my-activity',
    name: 'DashboardEn',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: 'my-activity/:id',
    name: 'DashboardnkLiEn',
    component: Dashboard,
    // beforeEnter: authGuard
  },
  {
    path: 'mon-activité',
    name: 'DashboardFr',
    component: Dashboard,
    beforeEnter: authGuard,
  },

  {
    path: 'partnerships',
    name: 'PartnershipsEn',
    component: Partnerships,
    beforeEnter: authGuard,
  },
  {
    path: 'partenariats',
    name: 'PartnershipsFr',
    component: Partnerships,
    beforeEnter: authGuard,
  },
  {
    path: 'notifications',
    name: 'NotificationsEn',
    component: Notifications,
    beforeEnter: authGuard,
  },
  {
    path: 'notifications',
    name: 'NotificationsFr',
    component: Notifications,
    beforeEnter: authGuard,
  },
  {
    path: 'privacy',
    name: 'PrivacyEn',
    component: Privacy,
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: 'privacy',
    name: 'PrivacyFr',
    component: Privacy,
    meta: {
      layout: 'LoginLayout',
    },
  },

  {
    path: 'terms',
    name: 'TermsEn',
    component: Terms,
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: 'terms',
    name: 'TermsFr',
    component: Terms,
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: 'cookies',
    name: 'CookiesEn',
    component: Cookies,
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: 'cookies',
    name: 'CookiesFr',
    component: Cookies,
    meta: {
      layout: 'LoginLayout',
    },
  },
];
