<template>
  <button class="selectBtn" :class="active ? 'active' : ''">
    <img class="degree-img" v-if="icon != null" :src="icon" />
    <span>{{ $t(text) }}</span>
  </button>
</template>
<script>
export default {
  name: "SelectBtn",
  props: {
    active: Boolean,
    text: String,
    icon: String,
  },
  
};
</script>
<style scoped>
*{
  padding: 0;
  margin: 0;
}
.degree-img {
  width: 16px !important;
  display: none;
}
.selectBtn {
  border: 1px solid #ccd0d5;
  background-color: white;
  color: #171616;
  /* height: 42px; */
  height: 36px;
  width: 100%;
  /* border-radius: 24px; */
  border-radius: .28571429rem;
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.selectBtn span {
  padding-left: 5px;
  font-size: 15px;
}
.selectBtn.active {
  border-color: var(--first-color);
  color: var(--first-color);
}
/* .selectBtn:hover {
  border-color: var(--first-color);
  color: var(--first-color);
} */

@media only screen and (max-width: 600px) {
  .selectBtn {
    border: 1px solid #ccd0d5;
    background-color: white;
    color: #171616;
    height: 38px;
    width: 100%;
    border-radius: 15px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .degree-img {
    width: 12px !important;
  }
}
</style>