<template>
    <div>
        <div>
            <div class="d-flex align-items-center" v-if="isVerified">
                <div class="pending-verify d-flex">
                    <p class="label d-flex align-items-center">
                        <span>{{ $t('verified') }}

                            <svg class="verifiedIcon" xmlns="http://www.w3.org/2000/svg" width="27" height="33"
                                viewBox="0 0 27 33">
                                <path id="Icon_material-verified-user" data-name="Icon material-verified-user"
                                    d="M18,1.5,4.5,7.5v9c0,8.325,5.76,16.11,13.5,18,7.74-1.89,13.5-9.675,13.5-18v-9Zm-3,24-6-6,2.115-2.115L15,21.255l9.885-9.885L27,13.5Z"
                                    transform="translate(-4.5 -1.5)" fill="#349e4d" />
                            </svg>

                        </span>
                    </p>
                </div>
            </div>
            <div class="d-flex align-items-center" v-else>
                <div id="tool-tip" v-if="getVault.result.data.length < 1">
                    <div class="">
                        <p class="d-flex align-items-start tool-tip">
                            <span>
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-inside-1_61_1174" fill="white">
                                        <path
                                            d="M12.6 21H15.4V12.6H12.6V21ZM14 0C6.265 0 0 6.265 0 14C0 21.735 6.265 28 14 28C21.735 28 28 21.735 28 14C28 6.265 21.735 0 14 0ZM14 25.2C7.826 25.2 2.8 20.174 2.8 14C2.8 7.826 7.826 2.8 14 2.8C20.174 2.8 25.2 7.826 25.2 14C25.2 20.174 20.174 25.2 14 25.2ZM12.6 9.8H15.4V7H12.6V9.8Z" />
                                    </mask>
                                    <path
                                        d="M12.6 21H7.6C7.6 23.7614 9.83858 26 12.6 26V21ZM15.4 21V26C18.1614 26 20.4 23.7614 20.4 21H15.4ZM15.4 12.6H20.4C20.4 9.83858 18.1614 7.6 15.4 7.6V12.6ZM12.6 12.6V7.6C9.83858 7.6 7.6 9.83858 7.6 12.6H12.6ZM12.6 9.8H7.6C7.6 12.5614 9.83858 14.8 12.6 14.8V9.8ZM15.4 9.8V14.8C18.1614 14.8 20.4 12.5614 20.4 9.8H15.4ZM15.4 7H20.4C20.4 4.23858 18.1614 2 15.4 2V7ZM12.6 7V2C9.83858 2 7.6 4.23858 7.6 7H12.6ZM12.6 26H15.4V16H12.6V26ZM20.4 21V12.6H10.4V21H20.4ZM15.4 7.6H12.6V17.6H15.4V7.6ZM7.6 12.6V21H17.6V12.6H7.6ZM14 -5C3.50358 -5 -5 3.50358 -5 14H5C5 9.02642 9.02642 5 14 5V-5ZM-5 14C-5 24.4964 3.50358 33 14 33V23C9.02642 23 5 18.9736 5 14H-5ZM14 33C24.4964 33 33 24.4964 33 14H23C23 18.9736 18.9736 23 14 23V33ZM33 14C33 3.50358 24.4964 -5 14 -5V5C18.9736 5 23 9.02642 23 14H33ZM14 20.2C10.5874 20.2 7.8 17.4126 7.8 14H-2.2C-2.2 22.9354 5.06458 30.2 14 30.2V20.2ZM7.8 14C7.8 10.5874 10.5874 7.8 14 7.8V-2.2C5.06458 -2.2 -2.2 5.06458 -2.2 14H7.8ZM14 7.8C17.4126 7.8 20.2 10.5874 20.2 14H30.2C30.2 5.06458 22.9354 -2.2 14 -2.2V7.8ZM20.2 14C20.2 17.4126 17.4126 20.2 14 20.2V30.2C22.9354 30.2 30.2 22.9354 30.2 14H20.2ZM12.6 14.8H15.4V4.8H12.6V14.8ZM20.4 9.8V7H10.4V9.8H20.4ZM15.4 2H12.6V12H15.4V2ZM7.6 7V9.8H17.6V7H7.6Z"
                                        fill="#00853B" mask="url(#path-1-inside-1_61_1174)" />
                                </svg>
                            </span>
                            <span class="tool-tip-text">
                                <p>
                                <ol>
                                    <li><b> {{$t( text3 )}}</b>
                                        <ul>
                                            <li>{{$t( text4 )}}</li>
                                        </ul>
                                    </li>
                                    <li class="pt-3"><b>{{$t( text5 )}}</b>
                                        <ul>
                                            <li>{{$t( text6 )}}</li>
                                        </ul>
                                    </li>
                                    <!-- <li><b>(Ques)</b> What document do i need to submit to get verified?
                            <ul>
                              <b>(Ans)</b>
                              <li>Business registration doument</li>
                              <li>Pitch deck</li>
                            </ul>
                          </li> -->
                                </ol>
                        </p>
                        </span>
                        </p>
                    </div>
                </div>

                <div class="btn-labeled-verify d-flex" @click="showVerificationModal()"
                    v-if="getVault.result.data.length < 1">
                    <p class="label d-flex align-items-center">
                        <span>{{ $t(text) }}</span>
                    </p>
                </div>

                <div class="pending-verify d-flex" v-else>
                    <p class="label d-flex align-items-center">
                        <span>{{ $t(text2) }}</span>
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        text: {
            type: String,
            default: "requestVerification",
        },

        text2: {
            type: String,
            default: "verificationPending",
        },

        text3: {
            type: String,
            default: "tooltipText1",
        },

        text4: {
            type: String,
            default: "tooltipText2",
        },

        text5: {
            type: String,
            default: "tooltipText3",
        },

        text6: {
            type: String,
            default: "tooltipText4",
        },
    },

    data() {
        return {
            modal_data: {
                show: true,
                category: 'all_upload'
            },
            isVerified: false,
        }
    },

    async created() {
        await this.$store.dispatch('getVault');
        if (this.getVault.result.data.length && this.getVault.result.data[0].status === 2 && this.getVault.result.data[1].status === 2 && this.getVault.result.data[2].status === 2) {
            this.isVerified = true;
        }
    },

    computed: {
        ...mapGetters([
            "getVault"
        ])
    },

    methods: {
        showVerificationModal() {
            this.$store.commit("set_show_modal", this.modal_data)
        }
    }
};
</script>


<style lang="scss" scoped>
.verifiedIcon {
    width: 13px;
    padding-bottom: 4px;
}

ol li,
ul li,
ol li b {
    list-style-type: none !important;
    font-size: 9pt !important;
    margin: 0 !important;
    padding: 0 !important;
}

ol li,
ol li b {
    width: 345px !important;
    margin-left: -20px !important;
    // background-color: blue !important;
}

.btn-labeled-verify {
    position: relative;
    display: inline-block;
    color: #00853B;
    border: 1px solid #00853B;
    border-radius: 15px;
    padding: 1.2em;
    height: 32px;

    &:hover {
        background-color: #daf7e7;
        cursor: pointer;
    }

    .label {
        padding: 0 7.5px 0 7.5px;
        font-size: 16px !important;

        // color: #727272;
        @include max-width(415) {
            display: none;
        }
    }
}

.pending-verify {
    position: relative;
    display: inline-block;
    color: #00853B;
    border: 1px solid #00853B;
    border-radius: 15px;
    padding: 1.2em;
    height: 32px;
}

.tool-tip .tool-tip-text {
    display: none;
    width: 357px;
    background-color: #ffff;
    color: black;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 999;
    right: 5em;
    box-shadow: 2px 3px #c7c7c7;
}


.tool-tip:hover .tool-tip-text {
    display: block;
}

//.tool-tip .tool-tip-text::after {
//content: " ";
//position: absolute;
//top: 50%;
//left: 100%;
//margin-top: -5px;
//border-width: 5px;
//border-style: solid;
//border-color: transparent transparent transparent black;
//}

#tool-tip {
    margin-right: 10px;
}
</style>
s