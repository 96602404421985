<template>
  <div class="aside-filter-tabs"
  :class="{offsetLayoutMargin: authUser.is_verified === 0}"
  >
    <div class="bg-white border p-2">
      <ul class="nav nav-justified" id="myTab" role="tablist">
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('NetworkFr') || activeMenu('NetworkEn')"
            :to="`/${$i18n.locale}/${this.$i18n.t('routes.network')}`"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            ><i class="feather-users"></i>
            <span class="navItemLable">Diaspora</span>
            <!-- <span class="mx-1 font-weight-bold counts"
              >({{ getConnectionsCounts.diaspora }})</span
            > -->
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('ConnectedFr') || activeMenu('ConnectedEn')"
            :to="`/${$i18n.locale}/${this.$i18n.t(
              'routes.network'
            )}/connections`"
            role="tab"
            aria-selected="false"
          >
            <i class="feather-user-check text-dark"></i>
            <span class="navItemLable">Connections </span>
            <span class="mx-1 font-weight-bold counts"
              >({{ getConnectionsCounts.connected }})</span
            >
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('RequestedFr') || activeMenu('RequestedEn')"
            :to="`/${$i18n.locale}/${this.$i18n.t(
              'routes.network'
            )}/${this.$i18n.t('routes.pendingRequests')}`"
          >
            <i class="feather-clock text-dark"></i>
            <span class="navItemLable">
              {{ $t("pendingRequests") }}
            </span>
            <span class="mx-1 font-weight-bold counts"
              >({{
                getConnectionsCounts.receivedRequests +
                getConnectionsCounts.sentRequests
              }})</span
            >
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('BlockedFr') || activeMenu('BlockedEn')"
            :to="`/${$i18n.locale}/${this.$i18n.t(
              'routes.network'
            )}/${this.$i18n.t('blocked')}`"
            ><i class="feather-user-x text-dark"></i>
            <span class="navItemLable">
              {{ $t("blocked") }}
            </span>
            <span class="mx-1 font-weight-bold counts"
              >({{ getConnectionsCounts.blocked }})</span
            >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
    if (!JSON.parse(localStorage.getItem("connectionsCount"))) {
      await this.loadConnCounts();
      //save to locale storage
      localStorage.setItem(
        "connectionsCount",
        JSON.stringify(this.getConnectionsCounts)
      );
    } else {
      let connectionsCount = JSON.parse(
        localStorage.getItem("connectionsCount")
      );
      this.$store.commit("setConnectionsCounts", connectionsCount);
    }
  },
  computed: {
    ...mapGetters(["authUser", "getUsers", "getConnectionsCounts"]),
  },
  data() {
    return {
      counts: {},
    };
  },
  methods: {
    ...mapActions(["loadUsers", "loadConnCounts"]),
    activeMenu(name) {
      let urlName = this.$route.name;
      if (urlName === name) return "active ";
    },
  },
  name: "ConnectionAside",
};
</script>

<style scoped>
.border {
  border-radius: 10px !important;
}
.nav-itemN,
.nav-link {
  display: block;
}

a.nav-link.router-link-exact-active.router-link-active {
  border: none !important;
}
.connection-aside.active {
  font-weight: bold;
  background: #cfead9 !important;
  color: #168b3f !important;
  font-weight: bold !important;
  border: 5px solid white !important;
  border-radius: 10px !important;
  margin: 5px;
}

.connection-aside.active,
.connection-aside i {
  font-weight: bold !important;
  color: #1ba54b !important;
}
.connection-aside {
  margin: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 1rem !important;
  color: #888da8;
  position: relative;
}
.aside-menu li:hover {
  font-weight: bold;
  color: #0f7f12 !important;
}
.aside-menu.active li,
.aside-menu.active i {
  font-weight: bold;
  background: #fccc2f !important;
  color: #0f7f12 !important;
}
.aside-menu li:hover {
  font-weight: bold;
  color: #0f7f12 !important;
}
.nav-item {
  position: relative;
}
.navItemLable {
  display: inline-block;
  position: relative;
  padding-left: 10px;
}
@media (min-width: 1200px) {
  .aside-filter-tabs {
    width: 100% !important;
    /* min-width: min(78vw, 1140px) !important; */
    /* position: fixed;
    top: 80px; */
  }
  .border {
    border-radius: 24px !important;
  }

  ul#myTab {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .bg-white.border.p-2 {
    padding: 0.1rem !important;
    margin: 0;
  }
  .nav-item {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 2px 0px;
  }

  .connection-aside {
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    padding: 1rem !important;
    color: #888da8;
    height: 100% !important;
    /* padding: 0 5px !important; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    background: orange !important;
    background: #99999914 !important;
  }
}
@media (max-width: 576px) {
  .aside-filter-tabs {
    padding: 0px 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    z-index: 999;
    background: #e7f1eb;
  }
  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navItemLable {
    display: none;
    position: absolute;
    top: -25px;
    left: -50%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .nav-item:hover .navItemLable {
    display: block;
  }

  ul#myTab {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .bg-white.border.p-2 {
    padding: 0.1rem !important;
    margin: 0;
    background: #e7f1eb !important;
    border-radius: 0 !important;
  }
  .connection-aside.active {
    font-weight: bold;
    background: white !important;
    color: #168b3f !important;
    font-weight: bold !important;
    border: 5px solid white !important;
    border-radius: 10px !important;
    margin: 5px;
  }
  .offsetLayoutMargin{ margin-top: 30px;}
}
@media (max-width: 320px) {
  .connection-aside {
    margin: 2px;
    font-size: 13px;
    font-weight: 500;
    padding: 1rem !important;
    color: #888da8;
    position: relative;
  }
  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .counts {
    position: absolute;
    background-color: #28a745;
    padding: 5px;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center;
    border-radius: 15px;
    color: white;
  }
}
</style>
