<template>
  <div class="box shadow-sm border rounded bg-white mb-3 osahan-post">
    <div class="p-3 d-flex align-items-center osahan-post-header">
      <div class="dropdown-list-image mr-3">
        <img
          class="rounded-circle"
          :src="feed.user.avatar"
          alt="user avatar"
          v-if="feed.user"
        />
      </div>
      <div class="font-weight-bold">
        <div>
          <span class="text-truncate" v-if="feed.user && feed.user.startup">
            {{ feed.user.startup.startupname }}</span
          >
          <span class="text-truncate" v-else> {{ feed.user.name }}</span>
          <span class="small text-gray-500"> </span>
        </div>
      </div>
      <span class="ml-auto small">
        <span
          class="ml-auto mb-auto"
          v-if="feed.user && feed.user.id === authUser.id"
        >
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-light btn-sm rounded"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="feather-more-vertical"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" style="">
              <button
                class="dropdown-item"
                type="button"
                @click="deleteFeed(feed.id)"
              >
                <i class="feather-trash"></i> {{ $t('delete') }}
              </button>
            </div>
          </div>
          <br />
          <div class="text-right text-muted pt-1">
            <timeago
              :datetime="feed.created_at"
              :locale="$i18n.locale"
              :auto-update="60"
              :converter="convertTime"
            />
          </div>
        </span>
      </span>
    </div>
    <div class="p-3 osahan-post-body">
      <p class="mb-0">
        <span v-html="summary"> </span>
        <span v-if="showSeeMore">
          <a
            @click="readMore"
            v-if="!isShow"
            :ref="`readMore${feed.id}`"
            href="javascript:;"
            >... {{ $t('seeMore') }}
          </a>
        </span>
        <transition name="slide">
          <span v-if="isShow" v-html="fullText"> </span>
        </transition>
      </p>
    </div>
    <Comment
      :index="index"
      :newsId="feed.id"
      :comments="feed.comments"
      :isUserFeed="true"
      :feedAuthId="feed.user_id"
      :feedPost="this.feed.post"
    />
  </div>
</template>

<script>
import Axios from 'axios';
import APIURLS from '../../constant';
import Swal from 'sweetalert2';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import i18n from '../../i18n';
export default {
  async created() {
    await this.loadInterrestList;
    this.tags = this.getInterrestList;
  },
  async mounted() {
    if (this.feed.post.length > 500) {
      this.showSeeMore = true;
      this.summary = this.feed.post.substr(0, 500);
      this.fullText = this.feed.post.substr(500, this.feed.post.length);
    } else {
      this.summary = this.feed.post;
    }
  },
  computed: {
    ...mapGetters(['authUser', 'getInterrestList']),
    ...mapActions(['loadInterrestList']),
  },
  data() {
    return {
      summary: '',
      showLike: true,
      showAddComment: true,
      isShow: false,
      showSeeMore: false,
    };
  },
  name: 'UserFeed',
  methods: {
    setSeeMore(post) {
    },
    convertTime(date, locale, converterOptions) {
      let year = ' y';
      let day = ' d';
      if (this.$i18n.locale === 'fr') {
        year = ' an';
        day = ' j';
      }

      const prefix = converterOptions.prefix || '';
      const suffix = converterOptions.suffix || '';
      const yearSuffix = converterOptions.yearSuffix || year;
      const monthSuffix = converterOptions.monthSuffix || ' m';
      const daySuffix = converterOptions.daySuffix || day;
      const hourSuffix = converterOptions.hourSuffix || ' h';
      const minuteSuffix = converterOptions.minuteSuffix || ' min';
      const secondsSuffix = 's';
      const diffSpacer = converterOptions.diffSpacer || '';

      let difference =
        new Date().getTime() - new Date(Date.parse(date)).getTime();

      const yearsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30 / 12
      );
      difference -= yearsDifference * 1000 * 60 * 60 * 24 * 30 * 12;

      if (yearsDifference > 0) {
        return `${prefix} ${yearsDifference}${diffSpacer}${yearSuffix} ${suffix}`;
      }

      const monthsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30
      );
      difference -= monthsDifference * 1000 * 60 * 60 * 24 * 30;

      if (monthsDifference > 0) {
        return `${prefix} ${monthsDifference}${diffSpacer}${monthSuffix} ${suffix}`;
      }

      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      if (daysDifference > 0) {
        return `${prefix} ${daysDifference}${diffSpacer}${daySuffix} ${suffix}`;
      }

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      if (hoursDifference > 0) {
        return `${prefix} ${hoursDifference}${diffSpacer}${hourSuffix} ${suffix}`;
      }

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      if (minutesDifference > 0) {
        return `${prefix} ${minutesDifference}${diffSpacer}${minuteSuffix} ${suffix}`;
      }

      const secondsDifference = Math.floor(difference / 1000);
      difference -= secondsDifference * 1000;

      if (secondsDifference > 0) {
        return `${prefix} ${secondsDifference}${diffSpacer}${secondsSuffix} ${suffix}`;
      }
      return `${prefix} ${secondsSuffix} ${suffix}`;
    },
    deleteFeed(feed_id) {
      Swal.fire({
        title: this.$i18n.t('deleteFeed'),
        text: this.$i18n.t('sureToDeleteFeed'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
        timerProgressBar: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.$store
            .dispatch('deleteFeed', {
              feed_id: feed_id,
              user_id: this.authUser.id,
              index: this.index,
            })
            .then((success) => {
              this.$noty.success('Success !');
            })
            .catch((error) => {
              Swal.fire({
                title: 'Request failed',
                text: error,
                icon: 'error',
              });
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    formatDate(date) {
      return moment(date).format('YYYY');
    },
    fortmatCountry(country) {
      var countries = require('i18n-iso-countries');
      countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
      return (country = countries.getName(country, i18n.locale, {
        select: 'official',
      }));
    },
    readMore() {
      this.isShow = !this.isShow;
    },
  },
  props: {
    feed: {},
    index: Number,
  },
};
</script>

<style>
.osahan-post {
  border-radius: 10px !important;
}
.osahan-post-header {
  display: flex;
  width: 100%;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
