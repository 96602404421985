<template>
  <div class="step-two">
    <IllustrationLayout
      v-if="getAccountType === 'Diaspora'"
      :illustrationImg="illustrationImg"
    />
    <IllustrationLayout
      v-if="getAccountType === 'Startup'"
      :illustrationImg="illustrationImgStartUp"
    />
    <StepThreeContent />
  </div>
</template>
<script>
import IllustrationLayout from "../../../../components/Auth/IllustrationLayout.vue";
import StepThreeContent from "./StepThreeContent";
import i18n from "../../../../i18n";
import store from "../../../../store";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  beforeRouteEnter: (to, from, next) => {
    if (this.$store.getters.authUser) {
      return next(`/${i18n.locale}/${i18n.t("routes.dashboard")}`);
    }
    next();
  },
  metaInfo() {
    return {
      title: `Bantaba | ${this.$i18n.t("register")} | Step 3`,
    };
  },
  computed: {
    ...mapGetters(["getAccountType"]),
  },
  data: () => {
    return {
      illustrationImg: require("../../../../assets/registration//illustration2.svg"),
      illustrationImgStartUp: require("../../../../assets/registration/Startup-llustration3.svg"),
      //startupImg: require("../../../../assets/registration/startup.svg"),
    };
  },
  name: "StepThree",
  components: {
    IllustrationLayout,
    StepThreeContent,
  },
};
</script>
<style scoped>
.step-two {
  position: absolute;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  /* margin-top: -50px; */
  /* background-color: pink; */
  transform: translateX(0vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .step-two {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    position: relative;
    height: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>