<template>
  <div>
    <div
      class="MessagingList"
      :style="cssVars"
      :class="{ active: getIsActive }"
      ref="MessagingList"
      id="MessagingList"
    >
      <Header ref="chatHeader" id="chatHeader" :title="$t('Messaging')" />
      <div class="search-row hideOnMobile">
        <div class="search-icon-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            data-supported-dps="16x16"
            fill="currentColor"
            class="mercado-match"
            width="16"
            height="16"
            focusable="false"
          >
            <path
              d="M14.56 12.44L11.3 9.18a5.51 5.51 0 10-2.12 2.12l3.26 3.26a1.5 1.5 0 102.12-2.12zM3 6.5A3.5 3.5 0 116.5 10 3.5 3.5 0 013 6.5z"
            ></path>
          </svg>
        </div>
        <input
          class="search-input"
          type="text"
          :placeholder="$t('searchMessages')"
          v-model="search"
        />
        <div class="clear-icon-container">
          <button
            class="header-message-btn right-btn"
            @click="clearSearch"
            v-if="search"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              class="mercado-match"
              width="16"
              height="16"
              focusable="false"
            >
              <path
                d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="scrollable">
        <ul class="chatList">
          <li
            class="empty-li hideOnMobile"
            v-if="filteredMessages && filteredMessages.length === 0"
          >
            <div class="empty-svg-container">
              <img class="svg2" src="../../assets/chat/chat-center-2.svg" />
            </div>
            <div class="empty-instruction-container">
              <h6>{{ $t("chatListIsEmpty") }}</h6>
              <p>{{ $t("createNewConvoInstruction") }}</p>
              <p>
                <button
                  @click="openNewChatBox"
                  type="button"
                  class="newConvoBtn btn btn-success btn-sm px-2 py-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    data-supported-dps="16x16"
                    fill="currentColor"
                    class="mercado-match"
                    width="16"
                    height="16"
                    focusable="false"
                  >
                    <path
                      d="M15 2.53a1.51 1.51 0 01-.44 1L9.15 9 6 10l1-3.12 5.44-5.44A1.5 1.5 0 0115 2.53zM12 11a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h3V2H5a3 3 0 00-3 3v6a3 3 0 003 3h6a3 3 0 003-3V8h-2z"
                    ></path>
                  </svg>
                  {{ $t("NewMessage") }}
                </button>
              </p>
            </div>
          </li>
          <li
            v-else
            class="chat"
            v-for="chat in filteredMessages"
            :key="chat.user_id"
            :id="chat.user_id"
            v-on:click="selectedChat(chat)"
          >
            <div class="chat-member-profile chat-user-profile mx-1">
              <OptimizeImg :src="chat.fromAvartar" />
              <!-- <img class="gravatar rounded-circle" :src="chat.fromAvartar" /> -->
            </div>
            <div class="chat-member-rightside">
              <div class="chat-member-list-header">
                <p class="chat-member-name">{{ chat.from }}</p>
                <p class="chat-member-time">
                  <timeago
                    class="text-secondary small"
                    :datetime="chat.messages[0]?.createdAt"
                    :locale="$i18n.locale"
                    :auto-update="60"
                    :converter="convertTime"
                  />
                </p>
              </div>
              <div class="chat-member-list-message-snippet">
                <p>
                  <span v-if="chat.messages[0]?.senderId === authUser.id">
                    {{ $t("You") }}:
                  </span>
                  {{ chat.messages[0]?.text }}
                </p>
              </div>
            </div>
            <p
              v-if="chat.unreadMessagesCount > 0"
              class="chat-unreadMessagesCount"
            >
              {{ chat.unreadMessagesCount }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <ChatBubble
      v-if="getCurrentlyChattingWith"
      :chatData="this.getCurrentlyChattingWith"
    />
    <ReportAPersonView />
    <EmptyChatBubble />
  </div>
</template>

<script>
import Header from "./Header";
import ChatBubble from "./ChatBubble";
import ReportAPersonView from "./ReportAPersonView";
//import Search from  "./Search";
import Axios from "axios";
import APIURLS from "../../constant";
import { debounce } from "lodash";
import _ from "lodash";
import moment from "moment";
import i18n from "../../i18n";
import EmptyChatBubble from "./EmptyChatBubble";

import OptimizeImg from "./OptimizeImg";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  async created() {
    //load user messages
    const user_id = this.authUser.id;
    await this.loadChatList(user_id);
    await this.sortMessaging(this.getChatList);
    window.onbeforeunload = () => {
      this.$socket.emit("leave", this.authUser.name);
      this.$socket.emit("disconnect");
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.matchHeight();
    });
    let url = this.chat ? this.chat.fromAvartar : "";
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // this.avatar_image = first_part + "/q_auto:low/c_fill,h_250,w_250/" + last_part;
      this.avatar_image =
        first_part + "/q_auto:low/c_fill,h_50,w_50/" + last_part;
    } else {
      this.avatar_image = this.chat ? this.chat.fromAvartar : "";
    }
  },
  computed: {
    ...mapState(["chatList"]),
    ...mapGetters([
      "authUser",
      "getIsOnline",
      "getIsActive",
      "getIsBubbleActive",
      "getCurrentlyChattingWith",
      "getChatList",
      "getMessages",
      "getIsNewChatConvo",
      "getIsBubbleClosed",
      "getOnlineUsers",
      "getIsBlocked",
      "getSearch",
      "getPage",
      "getChatServerStatus",
    ]),
    filteredMessages() {
      if (this.getChatList.length) {
        return this.getChatList.filter((message) => {
          return message.from.toLowerCase().includes(this.search.toLowerCase());
        });
      } else {
        return this.getChatList;
      }
    },
    cssVars() {
      return {
        "--messaging-list-height": this.MessagingListHeight + "px",
      };
    },
  },
  data() {
    return {
      avatar_image: "",
      avatar_isLoaded: false,
      search: "",
      selectedUser: null,
      users: [],
      MessagingListHeight: 0,
    };
  },
  sockets: {
    connect() {
      this.$store.commit("setIsOnline");
      this.$socket.emit("loggedin", this.authUser);
      this.$store.commit("setChatServerStatus", true);
      this.$store.commit("setCanSendMessages", true);
    },
    async updateUserList(userList) {
      this.$store.commit("setOnlineUsers", userList);
    },
    async invite(data) {
      this.$socket.emit("joinRoom", data);
    },
    disconnect() {
      this.$store.commit("setIsDisconected");
    },
  },
  methods: {
    onImgLoad() {
      this.avatar_isLoaded = true;
    },
    ...mapMutations(["calculateTotalUnreadMessages", "select"]),
    ...mapActions([
      "openChatBoxWithUser",
      "getUserById",
      "blockUser",
      "unblockUser",
      "checkBlockUser",
      "loadChatList",
      "unBlockUser",
      "markUnreadMessages",
      "sortMessaging",
      "openNewChatBox",
      "getSingleUserById",
      "sortMessaging",
    ]),
    matchHeight() {
      this.MessagingListHeight = this.$refs.MessagingList.clientHeight;
    },
    clearSearch() {
      this.search = "";
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async createRoom() {
      this.$store.commit("setIsNewChatConvo", false);
      var users = [];
      users.push(this.authUser.username.toLowerCase());
      users.push(this.getCurrentlyChattingWith.username.toLowerCase());
      let room = users.sort().join(".");
      this.$socket.emit("create", {
        room: room,
        userId: this.authUser.id,
        withUserId: this.getCurrentlyChattingWith.id,
      });
      //check if the user is blocked
      const from = this.authUser.id;
      const to = this.getCurrentlyChattingWith.id;
      await this.checkBlockUser({ from, to });
      await this.markUnreadMessages({ from, to });
      var check = this.getOnlineUsers.filter(
        (item) => item.id === this.getCurrentlyChattingWith.id
      );
      if (check.length) this.$store.commit("setOtherUserIsOnline", true);
      else this.$store.commit("setOtherUserIsOnline", false);
      this.$store.commit("closeActionModal");
    },
    async selectedChat(chat) {
      this.$store.commit("setIsNewChatConvo", false);
      let from = this.authUser.id;
      let to = chat.user_id;
      await this.checkBlockUser({ from, to });
      let user = await this.getSingleUserById({ user_id: chat.user_id });
      this.$store.dispatch("markUnreadMessages", { from, to });
      this.$store.commit("setCurrentlyChattingWith", user);
      this.$store.commit("setIsLoadingMessage", true);
      this.$store.commit("setChatMessagesCount", chat.messagesCount);
      this.$store.commit("setMessages", chat.messages);
      this.$store.commit("setAllMessagesCount", chat.allMessagesCount);
      if (chat.allMessagesCount > 20) {
        this.$store.commit("setHasMoreMessages", true);
      } else {
        this.$store.commit("setHasMoreMessages", false);
      }
      this.$store.commit("setIsBubbleClosed", false);
      this.$store.commit("setIsBubbleActive", true);
      this.$store.commit("setTyping", false);
      this.$store.commit("setPage", 1);
      this.$store.commit("setCount", 0);
      this.$store.commit("setIsLoadingMessage", false);
    },
    convertTime(date, locale, converterOptions) {
      let year = " y";
      let day = " d";
      if (this.$i18n.locale === "fr") {
        year = " an";
        day = " j";
      }
      const prefix = converterOptions.prefix || "";
      const suffix = converterOptions.suffix || "";
      const yearSuffix = converterOptions.yearSuffix || year;
      const monthSuffix = converterOptions.monthSuffix || " m";
      const daySuffix = converterOptions.daySuffix || day;
      const hourSuffix = converterOptions.hourSuffix || " h";
      const minuteSuffix = converterOptions.minuteSuffix || " min";
      const secondsSuffix = "s";
      const diffSpacer = converterOptions.diffSpacer || "";

      let difference =
        new Date().getTime() - new Date(Date.parse(date)).getTime();

      const yearsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30 / 12
      );
      difference -= yearsDifference * 1000 * 60 * 60 * 24 * 30 * 12;

      if (yearsDifference > 0) {
        return `${prefix} ${yearsDifference}${diffSpacer}${yearSuffix} ${suffix}`;
      }

      const monthsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30
      );
      difference -= monthsDifference * 1000 * 60 * 60 * 24 * 30;

      if (monthsDifference > 0) {
        return `${prefix} ${monthsDifference}${diffSpacer}${monthSuffix} ${suffix}`;
      }

      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      if (daysDifference > 0) {
        return `${prefix} ${daysDifference}${diffSpacer}${daySuffix} ${suffix}`;
      }

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      if (hoursDifference > 0) {
        return `${prefix} ${hoursDifference}${diffSpacer}${hourSuffix} ${suffix}`;
      }

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      if (minutesDifference > 0) {
        return `${prefix} ${minutesDifference}${diffSpacer}${minuteSuffix} ${suffix}`;
      }

      const secondsDifference = Math.floor(difference / 1000);
      difference -= secondsDifference * 1000;

      if (secondsDifference > 0) {
        return `${prefix} ${secondsDifference}${diffSpacer}${secondsSuffix} ${suffix}`;
      }

      if (secondsDifference === 0) {
        return this.$i18n.t("justNow");
      }
      return `${prefix} ${secondsSuffix} ${suffix}`;
    },
  },
  destroyed() {
    this.$socket.off("connect");
    this.$socket.off("disconnect");
    this.$socket.off("users");
    this.$socket.off("user connected");
    this.$socket.off("user disconnected");
    this.$socket.off("private message");
  },
  name: "MessagingList",
  props: {
    msg: String,
    // MessagingListHeight: {
    //   type: Number,
    //   default: 0,
    // },
  },
  components: {
    Header,
    ChatBubble,
    ReportAPersonView,
    EmptyChatBubble,
    OptimizeImg,
  },
};
</script>


<style scoped>
.newConvoBtn {
  border-radius: 20px;
  background: #0d7f13;
}
.newConvoBtn:hover {
  background: #04b90d;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.MessagingList {
  z-index: 2000 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 250px);
  flex: 0 0 288px;
  width: 288px;
  background-color: #fff;
  min-width: 0;
  transform: translateY(0);
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0px;
  padding-top: 0;
  /* float: right; */
  position: fixed;
  /* bottom: calc(50px - var(--defaultPositionStyles));
   */
  bottom: 0;
  right: 0;
  padding-bottom: 0px;
}
::-webkit-scrollbar {
  display: block !important;
  width: 8px !important;
  background: #dfdfdf52 !important;
  background: #009937 !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(87, 83, 83) !important;
  border-radius: 8px;
  background: #009937 !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(87, 83, 83) !important;
  background: #009937 !important;
}

.active {
  /* bottom: -655px; */
  bottom: calc(50px - var(--messaging-list-height));
}

.scrollable {
  overflow: hidden;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 5px;
}
.chatList {
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* overflow: hidden; */
  /* overflow: hidden;
  flex-grow: 1;
  overflow-y: auto;  */
}
li.chat {
  position: relative;
  display: flex;
  align-items: center;
  width: 100% !important;
  padding: 12px 0;
  height: 76px;
  overflow: hidden !important;
}

.chat-member-profile {
  width: 45px;
  height: 45px;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.svg2 {
  width: 130px;
  height: 130px;
}

.gravatar {
  width: 100%;
  height: 100%;
}
.chat-member-rightside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background-color: green; */
  width: calc(100% - 48px);
  padding: 8px 12px 8px 8px;
  opacity: 1;
}
.hideOnMobile {
  display: block;
}
li.chat {
  border-bottom: 1px solid rgba(110, 110, 110, 0.199);
}
li.chat:hover .chat-member-rightside {
  opacity: 1;
}
li.chat:hover {
  background-color: #d0e9d3bd;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
}
.chat-member-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: green; */
  width: 100%;
  padding-top: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
  padding-left: 8px;
}
p.chat-member-name {
  display: block;
  font-size: 0.8rem;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
  width: 150px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
p.chat-member-time {
  display: block;
  font-size: 0.8rem;
  line-height: 1;
  right: 0px;
  color: rgba(0, 0, 0, 1);
}
.chat-member-list-message-snippet {
  display: block;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
  width: 100%;
}
.chat-member-list-message-snippet p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  margin-right: 20px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.chat-unreadMessagesCount {
  position: absolute;
  background: #0f7f12;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 40px;
  right: 15px;
  color: white;
}

.empty-li {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-radius: 30px;
  background-color: #00000009;
  margin-top: 10px;
}

.empty-header-message-btn {
  display: block;
  position: relative;
  max-width: 230px;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.42857;
}

.empty-svg-container svg {
  width: 8rem;
  height: 8rem;
  stroke-width: 0;
  fill: rgba(0, 0, 0, 0.1);
  stroke: #0d7f1337;
}
.empty-instruction-container {
  width: 90%;
  background-color: #0d7f1317;
  border: 3px solid #0d7f1369;
  border-radius: 10px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.empty-instruction-container h6 {
  padding-bottom: 5px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}
.empty-instruction-container p {
  padding-top: 5px;
  color: rgba(0, 0, 0, 0.5);
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 650px) {
  .scrollable {
    padding-bottom: 60px;
  }
  .MessagingList {
    width: 74px;
    /* bottom: -calc(-638px - 95px); */
    /* bottom: 55px; */
    /* bottom: calc(-638px + 95px); */
    right: 0;
    /* remove the background & bbox shadow */
    /* background-color: white;
    backdrop-filter: saturate(0%) blur(1.5px); 
    box-shadow: none; */
    /* z-index: 5000 !important; */
    /* z-index: 99900 !important; */
    z-index: 9900 !important;
  }
  .active {
    bottom: 55px;
    bottom: calc(48px + 55px + 2px + 15px - var(--messaging-list-height));
  }
  .hideOnMobile {
    display: none !important;
  }
  .chat-member-rightside,
  .search-row,
  .chat-member-list-message-snippet,
  p.chat-member-name,
  p.chat-member-time {
    display: none;
  }

  li.chat {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100% !important; */
    width: 60px !important;
    height: 60px;
    border-radius: 100%;
    /* new profile style */
    /* background-color: white;
    border: 2px solid #3C9037;
    margin: -6.5px 0;
     */
  }
  li.chat:hover .chat-member-rightside {
    background-color: #ebebeb;
    opacity: 1;
  }
  li.chat:hover {
    background-color: #ebebeb;
    border-radius: 0px;
    opacity: 1;
    border-radius: 100%;
    margin-top: -15px;
    border: 1px solid #3b90375b;
    box-shadow: 0px 0px 0px 1px #3b903721, 0px 4px 4px #3b90372c;
    /* margin: 0px 0;
    border-radius: 100%; */
  }
  /* li.chat:hover .chat-member-profile {
    width: 100%;
    height: auto;
  } */
  .chat-unreadMessagesCount {
    position: absolute;
    background: #0f7f12;
    width: 20px;
    height: 20px;
    font-size: 12px;
    top: 5px;
    right: 0px;
  }
}

.search-row {
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px;
}
.search-div {
  background-color: #eff3f8;
  position: relative;
}
.search-input {
  box-shadow: none;
  width: 100% !important;
  padding: 8px !important;
  padding-left: 32px !important;
  background-color: #eff3f8;
  border: none;
  border-radius: 5px !important;
  font-size: 13px !important;
  max-height: 45px !important;
  background: #d0e9d357;
  color: #000 !important;
}
.search-input:focus,
.search-input:hover {
  outline: none;
  box-shadow: inset 0 0 0 1px #04b35b;
}
.search-icon-container {
  position: absolute;
  left: 15px;
}
.search-icon-container svg {
  color: rgba(0, 0, 0, 0.6);
  width: 1rem;
  height: 1rem;
}

.clear-icon-container {
  position: absolute;
  right: 10px;
}
.clear-icon-container button {
  background: white;
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 1000px;
}
.clear-icon-container :hover {
  background: rgb(176, 187, 186);
}
.clear-icon-container button svg {
  color: rgba(0, 0, 0, 0.6);
  width: 15px;
  height: 15px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.8);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.8);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.8);
}
</style>


