<template>
  <div class="container-fluid px-md-2 start-up-container">
    <div class="searchPanel py-3 mx-4">
      <form class="job-search p-3" v-if="motrerSearch">
        <div class="input-group">
          <vue-typeahead-bootstrap
            class="col-12"
            v-model="query"
            :data="allStartups"
            :serializer="(item) => item.login"
            highlightClass="special-highlight-class"
            @hit="selectedStartup = $event"
            :minMatchingChars="10"
            :autocomplete="false"
            :placeholder="$t('searchStartups')"
            inputClass="special-input-class form-control "
            :disabledValues="selectedStartup ? [selectedStartup.login] : []"
            @input="lookupStartup"
          >
          </vue-typeahead-bootstrap>
        </div>
        <a href="#" class="float-right mt-1 mr-2" @click="showFilters">
          {{ $t("filters") }}</a
        >
      </form>

      <form class="form-inline p-3" v-if="showFilter">
        <div class="input-group col-5">
          <country-select
            v-model="search.location"
            :countryName="true"
            :autocomplete="true"
            :disablePlaceholder="true"
            :use188n="false"
            :country="search.location"
            class="form-control custom-select"
            :placeholder="$t('selectLocation')"
          />
        </div>
        <div class="input-group col-5">
          <select class="custom-select" v-model="search.sector">
            <option :value="null" selected disabled>{{ $t("sector") }}</option>
            <option
              :value="sector.code"
              v-for="sector in sectors"
              :key="sector.id"
            >
              {{ $t(sector.code) }}
            </option>
          </select>
        </div>
        <div class="input-group col-2">
          <button
            type="button"
            @click="searchStartups()"
            class="btn btn-primary"
          >
            {{ $t("apply") }}
          </button>
        </div>
        <div class="col-11">
          <button
            type="button"
            @click="HideFilters"
            class="btn btn-outline-dark btn-sm float-right m-2"
          >
            {{ $t("hideFilters") }}
          </button>
          <button
            type="button"
            @click="clearFilters"
            class="btn btn-outline-danger btn-sm float-right m-2"
          >
            {{ $t("clearFilters") }}
          </button>
        </div>
      </form>
    </div>
    <div v-if="allStartups.length">
      <div id="content" ref="startups">
        <section class="section-padding homepage-view-offers">
          <div class="mt-4 mb-5 mx-2 list-container">
            <div
              class="col col-xl-3 col-sm-6 mb-5"
              v-for="(startup, index) in allStartups"
              :key="index"
            >
              <div
                class="connection-item custom-card connection shadow-sm h-100"
              >
                <div class="custom-card-image">
                  <a :href="`startup/${startup.slug}`">
                    <img class="img-fluid item-img" :src="startup.cover" />
                  </a>
                  <div class="button-g-btn button-g-btn-up">
                    <img
                      class="img-fluid"
                      :src="startup.user && startup.user.avatar"
                    />
                    <span>{{ startup.sector }}</span>
                  </div>
                </div>
                <div class="p-3 pt-4">
                  <div class="custom-card-body">
                    <h6 class="mt-2 mb-1">
                      <span class="text-gray-900 startup-name" href="#"
                        >{{ startup.startupname }}
                      </span>
                    </h6>
                    <span class="text-gray-500" href="#">
                      <i class="feather-map-pin"></i>
                      {{ fortmatCountry(startup.location) }}
                    </span>
                    <p class="text-black text-left startup-summary mt-1">
                      {{ startup.description | str_limit(80) }}
                    </p>
                  </div>

                  <div class="custom-card-footer d-flex align-items-center">
                    <span class="text-black">
                      <i class="feather-clock"></i>
                      {{ $t("founded") }}:
                      {{ formatDate(startup.foundingYear) }}
                    </span>
                    <a
                      :href="`startup/${startup.slug}`"
                      class="btn btn-sm btn-primary ml-auto"
                    >
                      <i class="feather-eye"></i> Page</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              id="loadMoreStartups"
              class="col-xl-12 order-xl-12 col-lg-12 order-lg-12 col-12"
              @click.prevent="loadMoreStartups"
            >
              <!-- <LoadingMore :loadingMore="loadingMoreStartups" v-if="loadMore" /> -->
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-else>
      <div
        class="alert alert-info m-4 text-center"
        role="alert"
        v-if="notFound"
      >
        <div class="alert alert-info m-4 text-center" role="alert">
          <h4 class="alert-heading py-2">
            {{ $t("noStartupsFound") }}
          </h4>
          <!-- <h4 v-else class="alert-heading pt-4">{{ $t('noStartupsFound') }}</h4> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import moment from "moment";
import { debounce, _ } from "lodash";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  async created() {
    this.notFound = false;
    await this.loadSectorsList;
    this.sectors = this.getSectorstList;
    this.savedStartups = this.startups;
    this.allStartups = this.startups;
    this.startupsCount = await this.getStartupsCount;
    this.count = this.allStartups.length;

    if (this.getStartupsCount > this.count) {
      this.loadMore = true;
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getSectorstList",
      "getStartupsCount",
      "getStartups",
    ]),
    ...mapActions(["loadSectorsList", "loadStartups"]),
  },
  data() {
    return {
      loadMore: false,
      startupsCount: 0,
      tags: {},
      showFilter: false,
      search: {
        sector: null,
      },
      query: "",
      allStartups: [],
      motrerSearch: true,
      selectedStartup: null,
      notFound: false,
      savedStartups: [],
      sector: "",
      location: "",
      sectors: {},
      startupSector: "",
      pagination: 16,
      page: 1,
      count: 0,
      loadingMoreStartups: false,
      fetchMore: true,
    };
  },
  methods: {
    loadMoreStartups(event) {
      this.loadingMoreStartups = true;
      const user_id = this.authUser.id;
      const lang = i18n.locale;
      Axios.get(
        `${APIURLS.STARTUP}loadmore/${user_id}/page/${this.page}/${this.pagination}/${lang}`
      )
        .then((response) => {
          if (response.status === 200) {
            this.allStartups = [...this.allStartups, ...response.data.startups];
            this.count += response.data.startups.length;
            if (this.count === this.startupsCount) {
              this.loadMore = false;
            }
            if (this.getStartupsCount > this.count) {
              this.loadMore = true;
            }
            this.page += 1;
            this.loadingMoreStartups = false;
          }
        })
        .catch((error) => {
          this.loadingMoreStartups = false;
          this.$noty.error(error.data.message);
        });
    },
    getNextStartups() {
      
      if (this.fetchMore) {
        window.onscroll = () => {
          let bottomOfWindow =
            document.documentElement.scrollTop + window.innerHeight ===
            document.documentElement.offsetHeight;
          if (bottomOfWindow) {
            this.loadingMoreStartups = true;
            Axios.get(
              `${APIURLS.STARTUP}loadmore/page/${this.page}/${this.pagination}`
            )
              .then((response) => {
                if (response.status === 200) {
                  this.allStartups = [
                    ...this.allStartups,
                    ...response.data.startups,
                  ];
                  this.loadingMoreStartups = false;
                }
              })
              .catch((error) => {
                this.loadingMoreStartups = false;
                this.$noty.error(error.data.message);
              });
          }
        };
      } else {
        window.removeEventListener("scroll", this.onScroll);
      }
    },
    lookupStartup: debounce(function () {
      
      this.loadMore = false;
      window.removeEventListener("scroll", this.onScroll);
      if (this.query) {
        fetch(`${APIURLS.STARTUP}lookupStartup/${this.query}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (!data.items.length) {
              this.notFound = true;
            }
            this.allStartups = data.items;
          });
      } else {
        this.allStartups = this.savedStartups;
      }
    }, 500),
    searchStartups() {
      
      this.fetchMore = false;
      this.loadMore = false;
      if (this.search.location) {
        this.search.location = this.countryCode(this.search.location);
      }
      const data = {
        location: this.search.location,
        sector: this.search.sector,
      };
      if (this.search.sector || this.search.location) {
        Axios.post(`${APIURLS.STARTUP}searchStartups`, data)
          .then(({ data }) => {
            this.allStartups = _.orderBy(data.startups, ["score"], ["desc"]);
          })
          .catch((error) => {
            this.$noty.error(error.response.data.message);
          });
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    showFilters() {
      this.showFilter = true;
      this.motrerSearch = false;
    },
    clearFilters() {
      this.search.location = null;
      this.search.sector = null;
      this.allStartups = this.savedStartups;
    },
    HideFilters() {
      this.showFilter = false;
      this.motrerSearch = true;
      this.search.location = null;
      this.search.sector = null;
    },
  },
  name: "StartupsList",
  props: {
    startups: {},
    type: {
      type: String,
      default: "all",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    emptyMessage: {
      type: String,
      default: "NoConnections",
    },
  },
};
</script>
<style>
.searchPanel {
  /* background: rgb(255, 247, 177); */
  background: rgba(187, 187, 187, 0.3);
  border-radius: 5px;
}
</style>
<style>
.start-up-container {
}
.connection {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
}
.connectionTitle {
  height: 25px !important;
}

/* Custom Card */
.custom-card-image {
  position: relative;
}
.button-g-btn-up {
  bottom: -16px;
  left: 1rem !important;
  position: absolute;
}
.custom-card-image .item-img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.button-g-btn span {
  background: #2260f8;
  background: -moz-linear-gradient(-45deg, #2260f8 0%, #9053fe 100%);
  background: -webkit-linear-gradient(-45deg, #2260f8 0%, #9053fe 100%);
  background: linear-gradient(135deg, #2260f8 0%, #9053fe 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#2260f8', endColorstr='#9053fe', GradientType=1);
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border: 3px solid #fff;
  padding: 7px 13px;
}
.button-g-btn img {
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50px;
  padding: 3px;
  box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.075) !important;
  object-fit: cover;
}
.shape:not([class*="shape-blur"]) {
  overflow: hidden;
}
.shape-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}
.shape {
  position: absolute;
  pointer-events: none;
}
.shape:not([class*="shape-blur"]) > * {
  -webkit-transform: scale(2);
  transform: scale(2);
}
.shape-fluid-x > * {
  width: 100%;
  height: auto;
}
.shape-bottom > * {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.shape > * {
  display: block;
}
.stor-card .custom-card-badge {
  color: #8a8989;
  font-size: 11px;
  padding: 4px 10px 4px 4px;
  font-weight: 400;
}
.custom-card-badge {
  background: #f7f7f7 none repeat scroll 0 0;
  display: inline-block;
  font-size: 13px;
  padding: 4px;
  width: 100%;
  border-radius: 8px;
}
.stor-card .custom-card-badge .badge {
  font-size: 11px;
  font-weight: 400;
  padding: 3px 5px;
}
.custom-card-badge .badge {
  margin: 0 4px 0 0;
  padding: 5px 8px;
}
.badge {
  font-size: 11px;
  padding: 5px 8px;
  font-weight: 400;
}
.stor-card .store-star {
  bottom: -10px;
  position: absolute;
  right: 1rem !important;
}
.stor-card .member-plan {
  right: 1rem !important;
  position: absolute;
  top: 11px;
  z-index: 9;
}

/* Coupon */
.all-coupon .user-cou-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.lock-badges {
  position: relative;
}
.lock-badges::after {
  background: #f2f4f8 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  cursor: no-drop;
  left: 0;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
}
.lock-badges-icon {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 50px;
  color: #263769 !important;
  font-size: 22px;
  height: 46px;
  line-height: 44px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 46px;
  box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.075) !important;
  z-index: 9;
}

/* Stores */
.banner-overlay {
  background: linear-gradient(50deg, #273444 0, #272b44 100%) !important;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
}
.store-banner {
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 143px 0;
  position: relative;
}
.store-banner {
  background-image: url("");
}
ul {
  margin: 0;
  padding: 0;
}
.dot-list li {
  line-height: 32px;
  padding: 0 0 0 27px;
  position: relative;
  list-style: none;
}
.dot-list li::before {
  background: #2c7be5 none repeat scroll 0 0;
  border-radius: 30px;
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 10px;
}
.gold-members img {
  border-radius: 50px;
  height: 55px;
  width: 55px;
}
.coupon-deal-detail-main-block .coupon-deal-detail-main-img {
  border-radius: 4px 4px 0 0;
  height: 323px;
  object-fit: cover;
  width: 100%;
}
.got-badge {
  bottom: -59px;
  position: absolute;
  right: 33px;
}
.coupon-deal-detail-main-block .user-cou-img {
  background: #fff none repeat scroll 0 0;
  border: 5px solid #fff;
  border-radius: 100px;
  height: 115px;
  width: 115px;
}
.coupon-deal-detail-main-block {
  position: relative;
}
.popular-stores-card {
  border-top: 1px solid #edeef3;
}
.popular-stores-card-body {
  background: #edeef3 none repeat scroll 0 0;
}
.popular-stores-card-offer .mr-3 {
  border-radius: 5px;
  height: 85px;
  object-fit: cover;
  width: 85px;
}

/* Restaurant */
.osahan-deel-cart-item-body {
  max-height: 383px;
  overflow: auto;
}
.offer-dedicated-header .button-g-btn {
  margin: -22px 0 11px;
  position: initial;
}
.offer-dedicated-action-btn {
  margin: 32px 0 0;
}
.restaurant-detailed-ratings .bg-gradient-primary {
  border-radius: 4px !important;
  display: inline-block;
  font-size: 12px;
  margin: 0 4px 0 0;
  padding: 2px 6px;
  vertical-align: text-top;
}
.sd-card-search .form-control {
  border-radius: 8px !important;
  font-size: 15px;
  height: 50px;
  padding: 5px 20px;
}
.sd-card-search .btn {
  color: #777777;
  font-size: 15px;
  padding: 13px 17px;
  position: absolute;
  z-index: 9;
  right: 0;
  text-decoration: none;
}
.new-arrivals-card .card-img {
  position: relative;
}
.new-arrivals-card .card-img .new-arrivals {
  bottom: 11px;
  left: 12px;
  position: absolute;
}
.new-arrivals {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  padding: 3px 11px;
  text-transform: uppercase;
}
.upgrade-progress {
  background: #dee0e6 none repeat scroll 0 0;
  border-radius: 2px;
  height: 2px;
}
.upgrade-progress-progress-bar {
  background: #2260f8;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #2260f8 0%, #9053fe 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #2260f8 0%, #9053fe 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #2260f8 0%, #9053fe 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#2260f8', endColorstr='#9053fe', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border-radius: 50px;
  height: 9px;
  position: relative;
  top: -3px;
}
.rating-checkbox .btn {
  border-color: #dee3f2;
  border-radius: 8px;
  color: #000;
  font-size: 12px;
  background-color: #ffffff;
  min-width: 76px;
  box-shadow: none;
  margin: 0 6px 0 0;
  padding: 11px 2px;
  cursor: pointer;
}
.rating-checkbox .btn img {
  margin-bottom: 6px;
  width: 32px;
}
.rating-checkbox .btn.active,
.rating-checkbox .btn:hover {
  background: #fff none repeat scroll 0 0 !important;
  border-color: #2c7be5 !important;
  color: #2c7be5 !important;
  border: 2px solid;
  font-weight: 400;
}
.mt-4.mb-5.mx-2 {
  display: flex;
  flex: row;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .mt-4.mb-5.mx-2 {
    display: flex;
    flex: row;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
</style>
