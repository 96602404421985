<template>
  <div>
    <div>
      <div class="vh-100 bg-white">
        <div class="container">
          <div class="row align-items-center vh-100">
            <div class="col-md-12 text-center">
              <i class="text-primary fa fa-6x fa-spin fa-spinner m-5"></i>
              <h1 class="text-black font-weight-bold">
                {{ $t("activating") }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
export default {
  async mounted() {
    let verification_key = `${this.$route.params.verification_key}`;

    let data = {
      verification_key,
    };

    data.welcomeData = {
      lang: this.$i18n.locale,
      link: `${APIURLS.SITELINK}${this.$i18n.locale}/${this.$i18n.t(
        "routes.login"
      )}`,
    };
    Axios.post(APIURLS.USERS + "activate-account", data)
      .then((response) => {
        let data = response.data.user;
        this.$noty.success(this.$i18n.t("accountVerified"));
        localStorage.removeItem("auth");
        localStorage.setItem("auth", JSON.stringify(data));
        this.$store.commit("setAuthUser", data);
        let url = `/${i18n.locale}/done`;
        if (this.$route.path != url) {
          this.$router.push(url);
        }
      })
      .catch((error) => {
        this.$noty.error(this.$i18n.t("linkExpired"));
        let url = `/${i18n.locale}/404`;
        if (this.$route.path != url) {
          this.$router.push(url);
        }
        // this.$router.push(`/${i18n.locale}/404`).catch(err => {});
      });
  },
  data() {
    return {
      done: false,
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Activate",
};
</script>
<style>
.match {
  color: green !important;
}
.mismatch {
  color: red !important;
}
</style>
