<template>
  <router-link class="form__button" :to="buttonLink">
     <span class="text">{{ $t(text) }}</span> 
    <span class="btn-text">{{ $t(buttonText) }}</span>
  </router-link>
</template>

<script>
export default {
  name: "LoginBtn",
  props: {
    buttonText: String,
    text: String,
    buttonLink: String,
  },
};
</script>

<style scoped>
.form__button {
  display: block;
  text-align: center;
  padding: 1rem 1rem;
  outline: none;
  border: none;
  background-color: white;
 border: 0.2px solid #57B36650;
  color: #1e2531;
  font-size: 14px;
  font-weight: normal;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  max-height: 74px;
  letter-spacing: 1px;
  margin-top: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.form__button .btn-text {
  font-weight: 500;
  color: var(--primary-green);
}
.form__button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
  padding-top: 1.4rem;
}
 span.text { 
  display: block;
  /* font-size: 18px;
  padding-right: 10px; */
}
.btn-text{
  padding-left: 5px;
}
.mb-4, .my-4 {
    margin-bottom: 1rem !important;
}
.mt-4, .my-4 {
    margin-top: 1rem !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form__button {
    display: flex;
    margin-left: auto;
    margin-top: 0.5rem;
    padding: 0.75rem 0.5rem;
    outline: none;
    border: none;
    background-color: rgb(255, 255, 255);
    border: 0.2px solid #57B36650;
    color: #1e2531;
    font-size: 18px;
    font-weight: normal;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.3s;
    width: 100%;
    /* height: 74px; */
    letter-spacing: 1px;
    /* margin-top: 0px;  */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  span.text {
  font-size: 16px;
  text-align: left;
  display: none !important ;
}
.btn-text{
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: normal;

}
.mb-4, .my-4 {
    margin-bottom: 1,5rem !important;
}
.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}
/* .form__button.mt-4:first-child, .form__button.my-4:first-child {
    margin-top: 4rem !important;
} */
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 320px) {
  .form__button{
     height: auto;
    padding: 0.5rem 2rem;
    font-size: 18px;
    border-radius: 12px;
    margin-top: 0.5rem;
    border: 0.2px solid #57B36650;
   
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>