<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t("willingToSupport") }}</h6>
    </div>
    <div class="box-body p-3">
      <div
        class="d-flex align-items-center osahan-post-header mb-3 people-list"
        v-for="user in users.slice(0, 5)"
        :key="user.id"
      >
        <div class="dropdown-list-image mr-3">
          <img class="rounded-circle" :src="user.avatar" alt="" />
        </div>
        <div class="font-weight-bold mr-2">
          <div class="text-truncate">
            <!-- <a :href="`/${$i18n.locale}/profile/${user.username}`">{{
              user.name
            }}</a> -->
            {{ user.name }}
          </div>
          <!-- <div class="small text-gray-500">@ {{ user.username }}</div> -->
        </div>
        <span class="ml-auto"
          ><a
            :href="`/${$i18n.locale}/profile/${user.username}`"
            class="btn btn-light btn-sm"
          >
            <i class="feather-eye"></i>
            Profile
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeopleSupportStartups",
  props: {
    users: {},
  },
};
</script>
