<template>
  <div class="d-flex">
    <div class="btn-edit" @click="edit()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 12.19 12.19"
      >
        <path
          d="M6.912,2.227,9.96,5.275,3.341,11.894l-2.718.3a.571.571,0,0,1-.631-.631L.3,8.843,6.912,2.227Zm4.933-.454L10.414.342A1.143,1.143,0,0,0,8.8.342L7.45,1.689,10.5,4.737,11.845,3.39a1.143,1.143,0,0,0,0-1.617Z"
          transform="translate(0.011 -0.008)"
          fill="#8c8c8c"
        />
      </svg>
    </div>

    <div class="btn-close" @click="remove()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 12 12"
      >
        <path
          d="M8.275,86l3.411-3.411a1.072,1.072,0,0,0,0-1.516l-.758-.758a1.072,1.072,0,0,0-1.516,0L6,83.725,2.589,80.314a1.072,1.072,0,0,0-1.516,0l-.758.758a1.072,1.072,0,0,0,0,1.516L3.725,86,.314,89.411a1.072,1.072,0,0,0,0,1.516l.758.758a1.072,1.072,0,0,0,1.516,0L6,88.275l3.411,3.411a1.072,1.072,0,0,0,1.516,0l.758-.758a1.072,1.072,0,0,0,0-1.516Z"
          transform="translate(0 -80)"
          fill="#8c8c8c"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    edit: function () {
      this.$emit("edit");
    },
    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-edit svg,
.btn-close svg {
  width: 11px !important;
  height: 11px !important;
}
@mixin btn-props {
  display: none;
  width: 22px;
  height: 22px;
  padding: 5px;
  line-height: 0;
  border: 1px solid rgba(112, 112, 112, 0.002);
  background-color: rgba(140, 140, 140, 0.22);
  border-radius: 15px;

  &:hover {
    background-color: rgba(140, 140, 140, 0.4);
  }

  .edit-profile & {
    display: flex;
  }
}

.btn-edit {
  @include btn-props;
  margin-right: 10px;

  @include max-width(410) {
    margin-right: 5px;
  }
}

.btn-close {
  @include btn-props;
}
</style>
