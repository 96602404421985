<template>
  <div class="container">
    <div class="profile user-profile">
      <div class="profiles_banner">
        <img v-if="startup.cover" :src="startup.cover" alt="cover" />
        <img
          v-else
          src="https://res.cloudinary.com/bantaba/image/upload/v1624019249/bantaba/african-geometric-pattern-in-blue-vector-11989626_jcsinf.jpg"
          alt="cover"
        />
      </div>
      <div class="profiles_content">
        <div class="profile_avatar">
          <div class="profile_avatar_holder">
            <img :src="avatar" alt="Avatar" v-if="avatar" />
            <img :src="startup.user.avatar" alt="Avatar" v-else />
          </div>
          <div class="user_status status_online"></div>
        </div>

        <div class="profile_info text-center">
          <h1>{{ startup.startupname }}</h1>
          <p>
            <i class="feather-tag"></i> {{ sector }} |
            <i class="feather-map-pin"></i> {{ startup.location }}
          </p>
          <div
            class="row profile_action bottom-0 right-0 p-3 text-sm z-50"
            v-if="!is_startup"
          >
            <a
              v-if="interrest != 'interrested'"
              @click="interrested"
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-secondary
                text-white
                mx-1
              "
            >
              <i class="feather-award mx-1"></i>
              <span> {{ $t("interrest") }} </span>
            </a>
            <a
              @click="notInterrested"
              v-else
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-success
                text-white
                mx-1
              "
            >
              <i class="feather-award mx-1"></i>
              <span> {{ $t("interrested") }} </span>
            </a>

            <a
              @click="follow"
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-secondary
                text-white
                mx-1
              "
              v-if="followed === 'unfollowed'"
            >
              <i class="feather-plus mx-1"></i>
              <span> {{ $t("follow") }}</span>
            </a>
            <a
              @click="unfollow"
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-primary
                text-white
                mx-1
              "
              v-else
            >
              <i class="feather-check-circle mx-1"></i>
              <span> {{ $t("followed") }} </span>
            </a>

            <a
              @click="chat"
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-primary
                text-white
                mx-1
              "
            >
              <i class="fa fa-comments mx-1"></i>
              <span> {{ $t("chat") }}</span>
            </a>
          </div>
          <div
            class="row profile_action bottom-0 right-0 p-3 text-sm z-50"
            v-else
          >
            <a
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-success
                text-white
                mx-1
              "
            >
              <i class="feather-award mx-1"></i>
              <span> {{ $t("interrested") }} ({{ interrestedCount }}) </span>
            </a>
            <a
              class="
                flex
                items-center
                justify-center
                h-8
                px-3
                rounded-md
                bg-gray-700 bg-primary
                text-white
                mx-1
              "
            >
              <i class="feather-check-circle mx-1"></i>
              <span> {{ $t("followers") }} ({{ followersCount }}) </span>
            </a>
          </div>
        </div>
      </div>

      <div class="box startupTabs rounded bg-white">
        <ul
          class="nav nav-justified border-bottom osahan-line-tab"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link"
              href="#overview"
              @click.prevent="scrollTo('#overview')"
              ><i class="feather-compass"></i> {{ $t("overview") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#description"
              @click.prevent="scrollTo('#description')"
              ><i class="feather-book-open"></i> {{ $t("description") }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#product')"
              ><i class="feather-box"></i> {{ $t("product") }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#team')"
              ><i class="feather-users"></i>{{ $t("team") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
import Axios from "axios";
export default {
  watch: {
    async $route() {
      await this.getSector();
    },
  },
  async created() {
    await this.checkFollowed();
    await this.checkInterrested();
    await this.getSector();
  },
  data() {
    return {
      followed: "",
      interrest: "",
      sector: "",
    };
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getFollowers",
      "getFollowersCount",
      "getInterrested",
      "getInterrestedCounts",
    ]),
    followStatus() {
      return this.followed;
    },
  },
  methods: {
    ...mapActions([
      "loadFollowers",
      "loadFollowersCount",
      "loadInterrested",
      "loadInterrestedCount",
      "openChatBoxWithUser",
      "getUserById",
      "getSectorByLang",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
    ]),
    async chat() {
      //console.log("this.startup", this.startup);
      let user_id = this.startup.user_id;
      let user = await this.getUserById({ user_id });
      await this.openChatBoxWithUser(user);
    },
    scrollTo(selector) {
      document.querySelector(selector).scrollIntoView({ behavior: "smooth" });
    },
    async getSector() {
      let lang = this.$i18n.locale;
      let code = this.startup.sector;
      this.sector = await this.getSectorByLang({ code, lang });
    },
    async follow() {
      let sector, countryOfOrigin, livesIn, headquarters, sender_avatar;

      let lang = this.$i18n.locale;
      if (this.authUser.type === 1) {
        let originCode = this.authUser.profile
          ? this.authUser.profile.birthCountry
          : "";
        countryOfOrigin = await this.getAFricanCountryByLang({
          code: originCode,
          lang,
        });
        let livesInCode = this.authUser.profile
          ? this.authUser.profile.residenceContry
          : "";
        livesIn = await this.getForeignCountryByLang({
          code: livesInCode,
          lang,
        });
      } else {
        let code = this.authUser.startup ? this.authUser.startup.sector : "";
        sector = await this.getSectorByLang({ code, lang });
        let countryCode = this.authUser.startup
          ? this.authUser.startup.location
          : "";
        headquarters = await this.getAFricanCountryByLang({
          code: countryCode,
          lang,
        });
      }
      const data = {
        startup_id: this.startup.id,
        user_id: this.authUser.id,
        name: this.authUser.name + " " + this.authUser.firstname,
        to: this.startup.user_id,
        sender_avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        lang,
        headquarters,
        sector,
        countryOfOrigin,
        livesIn,
      };

      Axios({
        method: "post",
        url: APIURLS.FOLLOWERS + "follow",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          if (response.data.statusMsg === "Success") {
            this.followed = "followed";
            this.$noty.success(this.$i18n.t("followingStartup"));
          }
          //console.log('response', response);
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
    unfollow() {
      Axios.get(
        APIURLS.FOLLOWERS +
          "user/" +
          this.authUser.id +
          "/startup/" +
          this.startup.id +
          "/unfollow"
      )
        .then((response) => {
          if (response.data.message === "Unfollowed") {
            this.followed = "unfollowed";
            this.$noty.success(this.$i18n.t("unFollowingStartup"));
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
    checkFollowed() {
      Axios.get(
        APIURLS.FOLLOWERS +
          "user/" +
          this.authUser.id +
          "/startup/" +
          this.startup.id
      )
        .then((response) => {
          if (response.data.message === "Not Followed") {
            this.followed = "unfollowed";
          } else {
            this.followed = "followed";
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    checkInterrested() {
      Axios.get(
        APIURLS.INTERRESTED +
          "user/" +
          this.authUser.id +
          "/startup/" +
          this.startup.id
      )
        .then((response) => {
          if (response.data.message === "unInterrested") {
            this.interrest = "uninterrested";
          } else {
            this.interrest = "interrested";
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    interrested() {
      const data = {
        startup_id: this.startup.id,
        user_id: this.authUser.id,
      };
      Axios({
        method: "post",
        url: APIURLS.INTERRESTED + "interrested",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          if (response.data.statusMsg === "Success") {
            this.interrest = "interrested";
            this.$noty.success(this.$i18n.t("interrestedInStartup"));
            this.$noty.success("Success");
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
    notInterrested() {
      const data = {
        startup_id: this.startup.id,
        user_id: this.authUser.id,
      };
      Axios.get(
        APIURLS.INTERRESTED +
          "user/" +
          this.authUser.id +
          "/startup/" +
          this.startup.id +
          "/uninterrested"
      )
        .then((response) => {
          //console.log('response', response);
          if (response.data.message === "Uninterrested") {
            this.interrest = "unInterrested";
            this.$noty.success(this.$i18n.t("notInterrestedInStartup"));
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
  },
  name: "PageCover",
  props: {
    is_startup: Boolean,
    avatar: String,
    startup: {},
    user_id: {},
    followersCount: Number,
    interrestedCount: Number,
  },
};
</script>
<style>
.bg-gray-700 {
  background-color: rgba(55, 65, 81, 0.7);
}
.rounded-md {
  border-radius: 0.375rem;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.h-8 {
  height: 2rem;
}
.flex {
  display: flex;
}
@media (min-width: 1024px) {
  .lg {
    display: flex;
  }
}
.z-50 {
  z-index: 50;
}
.bottom-0 {
  bottom: 0px;
}
.right-0 {
  right: 0px;
}
.absolute {
  position: absolute;
}
.p-3 {
  padding: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.startupTabs {
  border-radius: 1rem !important;
}
.profile {
  position: relative;
  margin-top: -1rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  border: 1px solid #ebe9e6;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.profile .profiles_banner {
  width: 100%;
  position: relative;
  padding: 0;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  height: 250px;
}

.profile .profiles_banner::before {
  position: absolute;
  content: "";
  z-index: 1;
  bottom: -60px;
  left: 0;
  width: 100%;
  height: 60%;
  opacity: 0.5;
  border-radius: inherit;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    color-stop(60%, #212529)
  );
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #212529 60%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #212529 60%);
  background-blend-mode: color-burn;
}

.profile .profiles_banner img {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile .profiles_content {
  position: relative;
  padding: 24px 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile .profiles_content .profile_avatar {
  position: relative;
  z-index: 1;
  width: 90px;
  height: 90px;
  margin-right: 20px;
}

.profile .profiles_content .profile_avatar .profile_avatar_holder {
  overflow: hidden;
  position: relative;
  border-radius: 10%;
  background: #dcdcdc;
  width: 100%;
  height: 100%;
}

.profile .profiles_content .profile_avatar .profile_avatar_holder img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile .profiles_content .profile_avatar .icon_change_photo {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
  border: 2px solid #fff;
  font-size: 16px;
  cursor: pointer;
  bottom: 0;
  right: 0;
  background-color: #c5ccd4;
}

.profile .profiles_content .profile_avatar .user_status {
  height: 19px;
  width: 19px;
  bottom: 13px;
  right: 13px;
}

.profile .profiles_content .profile_info {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

.profile .profiles_content .profile_info h1 {
  color: #464646;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 0;
}

.profile .profiles_content .profile_info p {
  margin-bottom: 0px;
  color: #9c9c9c;
}

.profile .responsive-nav {
  height: 65px !important;
}

.profile .responsive-nav ul {
  padding: 0 10px;
}

.profile .responsive-nav ul li:not(:last-child) {
  padding-right: 30px;
}

.profile .responsive-nav ul li a {
  line-height: 60px !important;
}

.profile .responsive-nav ul li a span {
  padding: 3px 6px;
  font-size: 12px;
}

.profile.user-profile .profiles_banner {
  height: 285px;
}

.profile.user-profile .profiles_banner::before {
  bottom: 0px;
}

.profile.user-profile .profiles_content {
  margin-top: -138px;
  z-index: 2;
  padding: 0;
  padding-bottom: 25px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile.user-profile .profiles_content .profile_avatar {
  width: 160px;
  height: 160px;
  margin-right: 0px;
  margin-bottom: 15px;
}

.profile.user-profile .profiles_content .profile_avatar .profile_avatar_holder {
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  border: 4px solid #fff;
}

.profile.user-profile .profiles_content .profile_info h1 {
  font-size: 30px;
  margin-bottom: 7px;
}

@media (max-width: 992px) {
  .profile {
    margin: -25px -15px 1.5rem;
    border-radius: 0;
  }
  .profile .profiles_banner {
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .profile .profiles_banner {
    height: 170px !important;
  }
  .profile .profiles_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 18px 20px;
  }
  .profile .profiles_content .profile_info {
    text-align: center;
    padding-bottom: 10px;
  }
  .profile .profiles_content .profile_info h1 {
    margin-bottom: 3px !important;
    font-size: 25px !important;
  }
  .profile.is_page .profile_avatar {
    display: none;
  }
  .profile.user-profile .profiles_content {
    margin-top: -90px;
    padding-bottom: 20px;
  }
  .profile.user-profile .profiles_content .profile_avatar {
    position: relative;
    width: 120px;
    height: 120px;
    z-index: 1;
    margin-bottom: 15px;
  }
  .profile.user-profile
    .profiles_content
    .profile_avatar
    .profile_avatar_holder {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
</style>
