<template>
  <div>
    <div
      class="
        box
        shadow-sm
        border
        rounded
        bg-white
        mb-3
        osahan-post
        newsFeedCard
      "
    >
      <div
        class="p-3 d-flex align-items-center border-bottom osahan-post-header"
      >
        <div class="font-weight-bold newsFeedheader">
          <div class="text-truncate">{{ feed.title }}</div>
          <div class="small text-gray-500">
            <span>
              {{ feed.sourceName }}
              {{ fortmatCountry(feed.country) }}
            </span>
            <span class="ml-auto small publishedDate">
              <timeago
                :datetime="feed.publishedDate"
                :locale="$i18n.locale"
              ></timeago>
            </span>
          </div>
        </div>
      </div>
      <div class="p-3 osahan-post-body">
        <p class="mb-0">
          <span v-html="text"> </span>
          <a
            @click="readMore(feed)"
            v-if="!isShow"
            :ref="`readMore${feed.id}`"
            href="javascript:;"
            >... {{ $t("seeMore") }}
          </a>
        </p>
        <transition name="slide">
          <p class="mb-0">
            <span v-if="isShow" v-html="text"> </span>
            <a
              @click="readLess(feed)"
              v-if="isShow"
              :ref="`readMore${feed.id}`"
              href="javascript:;"
              >{{ $t("seeLess") }}
            </a>
          </p>
        </transition>
      </div>
      <Comment
        :index="index"
        :newsId="feed.id"
        :comments="feed.comments"
        :isUserFeed="false"
      />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  async created() {
    await this.loadInterrestList;
    this.tags = this.getInterrestList;
    // this.summary = this.feed.fullText.substr(0, 400);
    this.text = this.feed.summary;
    this.summary = this.feed.summary;
    this.fullText = this.feed.fullText.substr(500, this.feed.fullText.length);
  },
  computed: {
    ...mapGetters(["authUser", "getInterrestList"]),
    ...mapActions(["loadInterrestList"]),
  },
  data() {
    return {
      summary: "",
      showLike: true,
      showAddComment: true,
      isShow: false,
      text: "",
    };
  },
  name: "UserFeeds",
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    readMore(feed) {
      this.text = feed.fullText;
      this.isShow = !this.isShow;
    },
    readLess(feed) {
      this.text = feed.summary;
      this.isShow = false;
    },
  },
  props: {
    feed: {},
    index: Number,
  },
};
</script>

<style>
.osahan-post {
  border-radius: 10px !important;
}
.newsFeedCard {
  padding: 10px !important;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.newsFeedheader {
  width: 100%;
  display: flex;
  font-size: 16px !important;
  flex-direction: column;
}
.newsFeedSource {
  padding: 5px 0;
}
.newsFeedSourceCountry {
  margin-left: 20px;
  padding: 0 20px;
  font-weight: bold;
}
.publishedDate {
  margin-left: 20px;
}
.newsFeedTitle {
  /* width: 100%; */
  /* display: flex;
  flex-basis: wrap; */
  color: #141414a1;
  word-wrap: break-word;
}

.osahan-post-body {
  line-height: 25px;
  font-size: 15px;
}
.feedFooter {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.057);
}
</style>
