<template>
  <div class="form">
    <div class="form-panel one">
      <div class="alert alert-danger text-center" role="alert" v-if="error">
        {{ errorMsg }}
      </div>
      <div class="form-header">
        <h1>{{ $t("changeEmail") }}</h1>
      </div>
      <div class="form-content">
        <div class="currentEmail alert alert-info text-center" role="alert">
          <strong class="font__weight-semibold"
            >{{ $t("currentEmail") }}:</strong
          >
          {{ authUser.email }}
        </div>
        <form @submit.prevent="changeEmail">
          <div class="form-group change-email">
            <label>{{ $t("newEmail") }}</label>
            <input
              type="email"
              class="form-control"
              required
              v-model="form.email"
              ref="email"
            />
          </div>

          <div class="form-group">
            <label>{{ $t("confirmEmail") }}</label>
            <input type="email" class="form-control change-email" required
            @paste.prevent onselectstart="return false" onCopy="return false"
            onCut="return false" onDrag="return false" onDrop="return false"
            autocomplete=off/ v-model="form.emailConfirm"
            v-on:keyup="checkEmailMatch" ref="email" />
          </div>
          <div class="match m-3" v-if="emailMacth">
            {{ macthText }}
          </div>
          <div class="mismatch m-3" v-else>
            {{ macthText }}
          </div>
          <div class="form-group">
            <button
              :disabled="loading || !emailMacth"
              :class="loading || !emailMacth ? 'disabled' : ''"
              type="submit"
              class="btn btn-primary btn-block text-uppercase"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
              {{ loading ? "" : $t("send") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
    //this.authUser;
  },

  async mounted() {
    this.form = {};
  },
  computed: {
    ...mapGetters(["authUser", "getProfile"]),
  },
  data() {
    return {
      form: {},
      error: "",
      loading: false,
      macthText: null,
      emailMacth: false,
    };
  },
  methods: {
    changeEmail() {
      this.loading = true;
      this.$store
        .dispatch("updateProfile", {
          email: this.form.email,
        })
        .then((success) => {
          this.form = {};
          this.$noty.success(this.$i18n.t("emailUpdated"));
          this.loading = false;
        })
        .catch((error) => {
          this.$noty.error(error);
          this.loading = false;
        });
    },
    checkEmailMatch() {
      if (this.form.emailConfirm) {
        this.form.email === this.form.emailConfirm
          ? ((this.macthText = `${this.$i18n.t("emailMatch")}`),
            (this.emailMacth = true))
          : (this.macthText = `${this.$i18n.t("emailNotMatch")}`);
      }
    },
  },
  name: "ChangeEmail",
};
</script>
<style scoped>
.change-email {
  margin-bottom: 20px !important;
}
.setting-content {
  margin-bottom: 60px !important;
}

.btn-apply {
  display: block;
  background: #cfead9;
  width: 100% !important;
  padding: 0.5rem;
  color: #1ba54b;
  border-radius: 1rem;
  font-size: 0.75rem;
  text-align: center;
  text-decoration: none;
}
.btn-apply:hover {
  background: var(--first-color);
  color: #fff !important;
}
.eye {
  position: relative;
  left: 94% !important;
  right: 40px !important;
  top: -30px;
  float: right !important;
  font-size: 20px;
  z-index: 11 !important;
  cursor: pointer;
}
.match {
  color: var(--first-color) !important;
}
.mismatch {
  color: red !important;
}
.overlay,
.form-panel.one:before {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.form {
  z-index: 15;
  position: relative;
  background: #ffffff;
  width: 600px;
  border-radius: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 0px;
}
.form-group:last-child {
  margin: 0;
}
.form-group label {
  display: block;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.disabled {
  background: #7d7e7dd7 !important;
}

.form-group input {
  outline: none;
  display: block;
  background: #cfead98f;
  width: 100%;
  border: 1px solid var(--first-color) !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  padding: 1px 20px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  transition: 1s ease;
}
input:focus {
  color: rgba(0, 0, 0, 0.8) !important;
  outline: none !important;
  border: 1px solid var(--first-color) !important;
  box-shadow: none !important;
}

.form-group button {
  outline: none;
  background: var(--first-color);
  width: 100%;
  border: 0;
  border-radius: 20px;
  padding: 12px 20px;
  color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.form-panel {
  padding: 20px calc(5% + 20px) 20px 20px;
  box-sizing: border-box;
}
.form-panel.one:before {
  content: "";
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.form-header {
  margin: 0 0 40px;
}
.form-header h1 {
  padding: 4px 0;
  color: var(--first-color) !important;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
</style>

<style scoped>
.settingInerTitle {
  font-size: 20px !important;
  margin-bottom: 20px !important;
}
.languages-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 40px;
}
.setting-options h2 {
  font-weight: 500;
}

.settings-container {
  display: flex;
  justify-content: center;
}
.personal {
  position: relative;
  margin-top: 0px !important;
  height: 100% !important;
}
.personal .header h2 {
  position: absolute;
  top: 50px !important;
  color: black !important;
  font-weight: 500;
}

/* mobile */
.personal .header {
  background-color: #cfead9;
  height: 150px;
  display: flex;
  justify-content: center;
}

.setting-items {
  margin: 0 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings {
  position: relative;
  width: 98%;
  height: 100% !important;
  padding: 0;
  top: -50px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.61);
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.personal .header h2 {
  top: 40px !important;
  font-size: 25px;
}
.setting-options-wrapper {
  margin-top: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
}
.setting-options {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px !important;
  background-color: #ebeced;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .settings {
    width: 80%;
    padding: 20px 25px;
    top: 0px;
  }
  .personal .header h2 {
    font-size: 40px;
  }

  .setting-options {
    width: 85%;
  }

  .setting-items {
    margin: 0;
  }
}
@media only screen and (min-width: 992px) {
  .settings {
    background-color: rgba(255, 255, 255, 0.61);
    box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 20px 40px;
    width: 70%;
    position: relative;
  }

  .personal .header {
    background-color: #cfead9;
    height: 200px;
    display: flex;
    justify-content: center;
  }

  .setting-options {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    background-color: #ebeced;
    border-radius: 10px;
  }
  .setting-options-wrapper {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .setting-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 950px) {
  .form {
    width: 100% !important;
  }

  .form-header {
    margin: 0 0 20px;
  }
  .form-header h1 {
    padding: 4px 0;
    font-size: 20px;
  }

  .eye {
    left: 90% !important;
  }
}
@media screen and (max-width: 750px) {
  .form {
    width: 100% !important;
  }

  .form-header h1 {
    padding: 4px 0;
    font-size: 18px;
  }

  .eye {
    left: 85% !important;
  }
}

@media only screen and (max-width: 600px) {
  .currentEmail {
    font-size: 15px;
  }
  .alert-info {
    padding: 2px 0;
  }
}

@media screen and (max-width: 500px) {
  .form {
    width: 100% !important;
  }

  .form-header h1 {
    padding: 4px 0;
    font-size: 16px;
  }

  .eye {
    left: 85% !important;
  }
}
</style>

<style scoped>
</style>