<template>
  <div class="member-card"
   :class="{toggleHeight: canSeeMore}"
  >
  <div class="header-row">
    <div class="m-profile">
      <div class="m-profile-placeholder">
        <cld-image cloudName:bantaba :publicId="data.image" alt="">
          <!-- <cld-transformation height="100" width="100" crop="scale" />
          <cld-transformation border="5px_solid_red" radius="20" /> -->
        </cld-image>
      </div>
    </div>
    <div class="row-name-title" :class="{ columnOnEdit: isEditMode }">
        <div class="m-name">
          <p id="member-name">{{ data.name }}</p>
        </div>
        <div class="m-position">
          <p id="member-position">
            {{ data.position }}
          </p>
        </div>
      </div>
      <div @click="editTeam(data)" v-if="isEditMode" class="edit-btn mx-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 12.19 12.19"
        >
          <path
            d="M6.912,2.227,9.96,5.275,3.341,11.894l-2.718.3a.571.571,0,0,1-.631-.631L.3,8.843,6.912,2.227Zm4.933-.454L10.414.342A1.143,1.143,0,0,0,8.8.342L7.45,1.689,10.5,4.737,11.845,3.39a1.143,1.143,0,0,0,0-1.617Z"
            transform="translate(0.011 -0.008)"
          ></path>
        </svg>
      </div>
      <div
        @click="deleteTeam(data.id, index)"
        v-if="isEditMode"
        class="edit-btn cancel-btn"
      >
        <svg
          viewBox="0 0 24 24"
          aria-hidden="true"
          class="
            r-19zomfu
            r-4qtqp9
            r-yyyyoo
            r-1xvli5t
            r-dnmrzs
            r-bnwqim
            r-1plcrui
            r-lrvibr
          "
        >
          <g>
            <path
              d="M13.414 12l5.793-5.793c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0L12 10.586 6.207 4.793c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414L10.586 12l-5.793 5.793c-.39.39-.39 1.023 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.793 5.793c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L13.414 12z"
            ></path>
          </g>
        </svg>
      </div>
  </div>
    <div class="member-card-details">
      <!-- <div class="row-name-title" :class="{ columnOnEdit: isEditMode }">
        <div class="m-name">
          <p id="member-name">{{ data.name }}</p>
        </div>
        <div class="m-position">
          <p id="member-position">
            {{ data.position }}
          </p>
        </div>
      </div> -->
      <div class="m-discription">
        <p :id="`member-discription` + index"
        :class="{showMore: isSeeMore, expandHeight: canSeeMore}">
          {{ data.description }}
        </p>
        <button 
         v-if="isSeeMore"
        @click="seeMoreMemberDiscription" class="m-span">
          {{ $t(`see ${seeText}`) }}
        </button>
       
      </div>

      <!-- <button class="view-more">View more</button> -->
       <!--edit button moved to the header-->
      <!-- <div @click="editTeam(data)" v-if="isEditMode" class="edit-btn mx-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 12.19 12.19"
        >
          <path
            d="M6.912,2.227,9.96,5.275,3.341,11.894l-2.718.3a.571.571,0,0,1-.631-.631L.3,8.843,6.912,2.227Zm4.933-.454L10.414.342A1.143,1.143,0,0,0,8.8.342L7.45,1.689,10.5,4.737,11.845,3.39a1.143,1.143,0,0,0,0-1.617Z"
            transform="translate(0.011 -0.008)"
          ></path>
        </svg>
      </div> -->
      <!-- <div
        @click="deleteTeam(data.id, index)"
        v-if="isEditMode"
        class="edit-btn cancel-btn"
      >
        <svg
          viewBox="0 0 24 24"
          aria-hidden="true"
          class="
            r-19zomfu
            r-4qtqp9
            r-yyyyoo
            r-1xvli5t
            r-dnmrzs
            r-bnwqim
            r-1plcrui
            r-lrvibr
          "
        >
          <g>
            <path
              d="M13.414 12l5.793-5.793c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0L12 10.586 6.207 4.793c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414L10.586 12l-5.793 5.793c-.39.39-.39 1.023 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.793 5.793c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L13.414 12z"
            ></path>
          </g>
        </svg>
      </div> -->
    </div>
    <div class="see-description-details" id="see-more_member_discription">
      {{ data.description }}
    </div>
  </div>
</template>
<script>
import SaveClose from "./SaveClose.vue";
export default {
  name: "TeamMeberCard",
  components: {
    //SaveClose,
  },
  async mounted() {
    this.lineNo = this.countLines();
    if( Math.round(this.lineNo) >  3 ){
      this.isSeeMore  = true;
    }
  },
  data() {
    return{
      isSeeMore: false,
      canSeeMore: false,
      lineNo: 0,
      seeText: 'more'
    }
  },
  props: {
    data: {
      type: Object,
    },
    isEditMode: Boolean,
    index: Number,
  },
  methods: {
     countLines() {
      var el = document.getElementById("member-discription" + this.index);
      // var divHeight = el.offsetHeight;
      var lineHeight = parseInt(el.style.lineHeight);
      // return Math.round(divHeight / 20);
      const divHeight = +el.offsetHeight  
      return divHeight / 20;
    },
    emitDeleteMember(index) {
      this.$emit("emitDelete", index);
    },
    editTeam(member) {
      this.$emit("emitEdit", member);
    },
    deleteTeam(member_id, index) {
      this.$emit("emitDelete", member_id, index);
    },
    seeMoreMemberDiscription() {
      this.canSeeMore = !this.canSeeMore;
      if( this.canSeeMore ){
        this.seeText  = 'less'
      }else{
        this.seeText  = 'more'
      }
      let discription_tooltipx = document.getElementById(
        "see-description-details"
      );
      discription_tooltipx.style.display = "visible !important";
    },
    closeMoreMemberDiscription() {
      let discription_tooltipx = document.getElementById(
        "see-description-details"
      );
      discription_tooltipx.style.visibility = "hidden";
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.member-card {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05),
    0px 2px 4px rgba(44, 43, 42, 0.05), 0px -2px 4px rgba(44, 43, 42, 0.05);
  border-radius: 24px;
  /* background-color: red; */
  background-color: #f5f7f9;
  background-color: #ffffff10;
  backdrop-filter: blur(20%);
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* height: 225px; */
  /* overflow: hidden; */
  position: relative;
  transition: all 0.2s ease-in-out;
  flex-direction: column;
  padding-bottom: 0;
}
.toggleHeight{ 
  height: auto !important;
}
/* .member-card:hover {
  background-color: #57b36629;
  color: white;
} */
.header-row {
  position: relative;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #0000000f;
  padding-bottom: 5px;
  /* justify-content: space-between; */
}
.m-name,
.m-position,
.m-discription {
  display: flex;
  align-items: center;
}

#member-name,
.row-name-title,
.m-name {
  background: none !important;
}
.member-card .m-name,
.m-discription,
.m-position {
  color: black;
}
/* .member-card:hover .m-name .m-discription .m-position{
      color: #57b366;
} */

.m-profile {
  width: 20%;
  display: flex;
}
.m-profile-placeholder {
  background-color: #eee;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}
.m-profile-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.member-card-details {
  /* width: 80%; */
  /* background-color: rgba(125, 125, 125, 0.166);
    backdrop-filter: blur(20%); */
    width: 100%;
  font-size: 20px;
  line-height: 34px;
  color: #333333;
  position: relative;
  /* padding: 0 15px; */
  padding: 10px 5px;
}
.row-name-title {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.m-name {
  font-weight: bold;
  background: linear-gradient(90deg, #eafff2 0%, rgb(255, 255, 255) 100%);
  border-radius: 20px;
}
.m-discription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 20px;
  /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */

  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; */
}
.m-discription p {
  display: -webkit-box;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  /* -webkit-box-orient: vertical; */
  /* overflow: hidden; */
  /* height: 60px; */
  height: auto;
  line-height: 20px;
  font-size: 15px;
  color: #333333;
  /* font-style: italic; */

  /* border-bottom: 1px solid #0000000f;
  padding-bottom: 10px; */
}
.showMore{
  -webkit-line-clamp: 3 !important; 
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  height: 60px !important; 
}
.expandHeight{
  height: auto !important;
  overflow: inherit !important;
  -webkit-line-clamp:  inherit !important;
  -webkit-box-orient: inherit !important;
}
.m-discription .m-span {
  /* background: #00000024; */
  /* color: #2d2d2d; */
  /* font-size: 12px; */
  /* padding: 0px 10px; */
  /* list-style: 15px; */
  /* border-radius: 20px; */
  margin-top: 5px;
  cursor: pointer;

  background: #00853c24;
    color: #00853c;
    font-size: 12px;
    padding: 0px 5px;
    border-radius: 10px;
    border: 1px solid #00853c50;
}
.see-detail-discription {
  position: absolute;
  /* width: 300px;
    height: 300px; */
  border-radius: 20px;
  padding: 10px;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05),
    0px 2px 4px rgba(44, 43, 42, 0.05), 0px -2px 4px rgba(44, 43, 42, 0.05);
  display: none;
}
.view-more {
  background: none;
  outline: none;
  color: #4f4f4f;
  border: none;
  font-size: 16px;
}
.edit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* background: radial-gradient(50% 50% at 50% 50%, #DFE2E4 78.44%, #D5D9DC 100%); */
  background-color: #00853c2c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-btn svg {
  width: 20px;
  height: 20px;
  fill: #00853c;
}

.edit-btn:hover,
.edit-btn:hover svg {
  background: var(--first-color);
  fill: #fff;
  cursor: pointer;
}

.cancel-btn,
.cancel-btn svg {
  background: #fdb3b096;
  fill: var(--error-color);
}
.cancel-btn:hover,
.cancel-btn:hover svg {
  background: var(--error-color);
  fill: #fff;
  cursor: pointer;
}
/* .member-card:hover .edit-btn svg {
  transform: rotate(90deg);
} */

.btn-wrapper {
  margin-left: 5px;
}
.btn-wrapper .btn-edit {
  width: 25px;
  height: 25px;
  padding: 5px;
  line-height: 0;
  border: 1px solid hsla(0, 0%, 43.9%, 0.002);
  border-radius: 15px;
  background-color: hsla(0, 0%, 54.9%, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}
.see-description-details {
  position: absolute;
  background: #f5fff9;
  box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 10px;
  padding: 20px;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
  text-align: left;
  width: min(78vw, 800px);
  display: none;
  /* visibility: hidden;  */
}
.m-span:hover {
  background-color: #1ca54b;
  color: white;
}
/* .m-span:hover +  .see-description-details{
  visibility: visible;
} */
@media (max-width: 600px) {
  .member-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    flex-direction: column;
    /* max-height: 200px; */
    padding: 8px;
    /* background: orange;
    background: linear-gradient( 
90deg
 , orange 0%, rgb(252, 113, 27) 100%); */
    align-items: flex-start;
  }
  .toggleHeight{ 
  height: auto !important;
}
  .m-profile {
    width: max(20%, 70px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .m-profile-placeholder {
    width: 70px;
    height: 70px;
  }
  .m-discription {
    /* display: flex; */
    display: -webkit-box; 
    /* -webkit-line-clamp: 2; */
    /* -webkit-box-orient: vertical; */
    /* overflow: hidden; */
    font-size: 14px;
    padding-left: 10px;
  }
  
  .member-card-details {
    /* width: calc(100% - max(20%, 70px)); */
    width: 100%;
    font-size: 20px;
    line-height: 34px;
    color: #333333;
    position: relative;
  }
  .row-name-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
  }
  .columnOnEdit {
    display: flex;
    flex-direction: column;
  }
  .edit-btn {
    position: absolute;
    right: 0;
    /* top: -140px; */
    top: 0px;
    /* width: 45px;
    height: 45px; */
    width: 35px;
    height: 35px;
    border-radius: 50%;
    /* background: radial-gradient(50% 50% at 50% 50%, #DFE2E4 78.44%, #D5D9DC 100%); */
    background-color: #00853c2c;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit-btn svg {
  width: 15px;
  height: 15px;
  fill: #00853c;
}
  /* .member-card .m-name[data-v-42cc834e], .m-discription[data-v-42cc834e], .m-position[data-v-42cc834e] {
    color: black;
    font-style: italic;
} */
  .m-name {
    line-height: 30px;
    font-size: 18px;
  }
  .m-position {
    color: #00000080;
    margin-left: 0px;
    font-size: 15px;
    line-height: 20px;
  }
  .m-discription p {
    display: -webkit-box;
    display: -webkit-box;
    /* -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px; */
    height: auto;
    line-height: 20px;
  }
  .showMore{
  -webkit-line-clamp: 3 !important; 
  -webkit-box-orient: vertical;
  overflow: hidden ;
  height: 40px !important; 
}
.expandHeight{
  height: auto !important;
  overflow: inherit !important;
  -webkit-line-clamp:  inherit !important;
  -webkit-box-orient: inherit !important;
}
.upload-btn-options {
  /* padding: 10px 0; */
  z-index: 999 !important;
  
}
}
p[contenteditable="true"] {
  padding: 0 10px;
  border: 2px solid #00853c40;
}

/* p[contenteditable="false"] {
  padding: 0 10px;
  border: 2px solid #00853C40;
  background-color: transparent;
} */
</style>