<template>
  <div class="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
    <ul
      class="nav nav-justified border-bottom osahan-line-tab"
      id="myTab"
      role="tablist"
    >
      <li class="nav-item">
        <a
          @click="postStatus"
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <span v-if="authUser.is_entrepreneur"
            ><i class="feather-edit mr-1"></i
            >{{ $t("ShareAnUpdateAboutYourStartup") }}</span
          >
          <span v-else
            ><i class="feather-edit mr-1"></i>{{ $t("ShareAnUpdate") }}</span
          >
        </a>
      </li>
    </ul>
    <transition name="slide">
      <div v-if="shareUpdate">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade active show"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="p-3 d-flex align-items-center w-100" href="#">
              <div class="dropdown-list-image mr-3">
                <img
                  class="rounded-circle"
                  :src="authUser.avatar"
                  alt=""
                />
                <div class="status-indicator bg-success"></div>
              </div>
              <div class="w-100">
                <textarea
                  :placeholder="
                    authUser.is_entrepreneur
                      ? $t('letDiasporaKnow')
                      : $t('writeYourThoughts')
                  "
                  class="form-control border-0 p-0 shadow-none"
                  rows="5"
                  v-model="post"
                  @focus="setTyping"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="border-top p-3 d-flex align-items-center">
          <div class="mr-auto"></div>
          <div class="flex-shrink-1">
            <button
              :disabled="creatingPost"
              type="button"
              class="btn btn-primary btn-sm"
              @click="postFeeds"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="creatingPost"></i>
              {{ creatingPost ? "" : $t("postStatus") }}
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm ml-2"
              v-if="typing"
              @click="cancelPosting"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  async mounted() {
    // if (this.authUser.type === 1) {
    //   await this.loadProfile();
    // } else if (this.authUser.type === 2) {
    //   // await this.loadStartup();
    //   // const startup_id = this.getStartup.id;
    // }
    // this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getUserProfile", "getFeeds"]),
  },
  methods: {
    ...mapActions(["loadProfile", "loadFeeds", "createFeed", "deleteFeed"]),
    postStatus() {
      this.shareUpdate = !this.shareUpdate;
    },
    setTyping() {
      this.typing = true;
    },
    cancelPosting() {
      this.post = null;
      this.shareUpdate = false;
    },
    postFeeds() {
      if (this.post.length && this.post.trim()) {
        this.creatingPost = true;
        this.typing = false;
        this.$store
          .dispatch("createFeed", {
            post: this.post,
          })
          .then(async (success) => {
            this.creatingPost = false;
            this.feeds;
            this.post = "";
            await this.loadFeeds();
            this.$noty.success(this.$i18n.t("postShared"));
          })
          .catch((error) => {
            this.creatingPost = false;
            this.$noty.error(error);
          });
      }
    },
  },
  data() {
    return {
      post: "",
      loading: true,
      typing: false,
      creatingPost: false,
      shareUpdate: false,
    };
  },
  name: "CreateFeed",
};
</script>
