<template>
  <div class="see-all-sec text-center">
    <div class="btn-see-all text-success" v-if="!loadingMore">
      <i class="icofont-refresh"></i>
      {{ $t("loadMore") }}
    </div>
    <div class="btn-see-all text-success" v-else>
      <i class="fa fa-refresh fa-spin"></i>
      {{ $t("loadingMore") + " ..." }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingMore",
  props: {
    loadingMore: Boolean,
  },
};
</script>
<style scoped>
.see-all-sec {
  border-radius: 15px;
  box-shadow: 2px 2px #f5f5f7;
  border: 1px solid #f5f5f7;
  background: white;
  margin-top: 40px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}

.btn-see-all {
  background: none;
  border: none;
  font-size: 17px;
}
@media (max-width: 650px) {
  .see-all-sec {
    margin-top: 10px;
    width: 95%;
    transform: translateX(calc(5% / 2));
}
}
</style>
