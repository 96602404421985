const state = {
  isEmailVarified: true,
};
const getters = {
  getisEmailVarified: (state) => {
    return state.isEmailVarified;
  },
};

const mutations = {
  setEmailVarified: (state) => {
    state.isEmailVarified = true;
  },
};

export default {
  state,
  getters,
  mutations,
};
