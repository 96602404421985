<template>
  <div class="box mb-3 shadow-md rounded bg-white profile-box text-center">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">COVER</h6>
    </div>
    <div class="py-2 px-3 border-bottom">
      <img
        :src="newCover"
        class="img-fluid"
        alt="cover"
        height="100"
        v-if="!showPreview"
      />
      <VueCropper
        ref="cropper2"
        :aspect-ratio="16 / 9"
        :src="newCover"
        :viewMode="1"
        preview=".preview"
        :img-style="{ width: '400px', height: '300px' }"
        v-else
      />
    </div>
    <div class="p-4">
      <form enctype="multipart/form-data" action="#" @submit.prevent="submit">
        <label
          v-if="buttonChoice === 'select'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload New Picture"
          class="btn btn-info"
          for="fileAttachmentBtn2"
        >
          <i class="feather-upload "></i> {{ $t('change') }}
          <input
            id="fileAttachmentBtn2"
            name="cover"
            type="file"
            accept="image/*"
            class="d-none "
            @change="onCoverChange"
          />
        </label>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload"
          type="submit"
          :disabled="loading"
          class="btn btn-success ml-3"
        >
          <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
          <i class="feather-upload-cloud" v-else></i>
          {{ loading ? '...' + $t('upload') : $t('upload') }}
        </button>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete"
          type="submit"
          @click="cancel"
          class="btn btn-danger ml-3"
        >
          <i class="feather-trash-2"></i> {{ $t('cancel') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import APIURLS from '../constant';
import i18n from '../i18n';
import VueCropper from 'vue-cropperjs';
import { mapGetters, mapActions } from 'vuex';
export default {
  async created() {
    this.newCover = this.cover;
  },
  computed: {
    ...mapGetters(['authUser', 'getProfile']),
  },
  data() {
    return {
      showPreview: false,
      cropCover: '',
      newCover: null,
      buttonChoice: 'select',
      image: null,
      file: '',
      fileContents: null,
      cloudName: 'bantaba',
      preset: 'bantaba',
      formData: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(['loadProfile', 'updateProfile']),
    onCoverChange(e) {
      this.showPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.newCover = URL.createObjectURL(file);
      this.buttonChoice = 'upload';
    },
    prepareFormData: function() {
      this.formData = new FormData();
      this.formData.append('upload_preset', this.preset);
      this.formData.append('file', this.cropCover);

    },
    submit() {
      this.cropCover = this.$refs.cropper2.getCroppedCanvas().toDataURL();
      this.loading = true;
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        function() {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: 'POST',
            data: this.formData,
            onUploadProgress: function(progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.results = response.data;
              this.image = this.results.secure_url;
              this.$store
                .dispatch('updateStartup', { cover: this.image })
                .then((success) => {
                  this.$noty.success('Success !');
                  this.image = null;
                  this.newCover = this.results.secure_url;
                  this.showPreview = false;
                  this.loading = false;
                  this.buttonChoice = 'select';
                })
                .catch((error) => {
                  this.loading = false;
                  this.$noty.error(error);
                });
            })
            .catch((error) => {
              this.loading = false;
              this.errors.push(error);
            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(this.file);
    },
    cancel() {
      this.newCover = this.cover;
      this.showPreview = false;
      this.buttonChoice = 'select';
    },
  },
  name: 'UpdateCover',
  components: { VueCropper },
  props: {
    cover: {
      type: String,
    },
  },
};
</script>

<style>
.myAvatar {
  width: 180px;
  height: 180px;
  border: 5px solid #0f7f12 !important;
}
</style>
