<template>
  <div class="messagebox" v-bind:class="{ active: isActive }">
    <div class="messages-container" ref="infoBox">
      <header class="header">
        <div class="header-left">
          <div class="reciever-profile"></div>
          <div class="reciever-name">John Doe</div>
        </div>
        <div class="header-right">
          <button class="action-button">
            <i class="feather-video"> </i>
          </button>
          <button class="action-button">
            <i class="feather-phone"> </i>
          </button>
          <button
            class="action-button close-message-box"
            v-on:click="showMessageBox"
          >
            <i class="feather-chevron-down " v-if="isActive"> </i>

            <i class="feather-chevron-up" v-if="!isActive"> </i>
          </button>
        </div>
      </header>
      <div class="body">
        <ul class="messages">
          <li class="message" v-for="message in messages" :key="message.text">
            <div class="message-left">
              <div class="reciever-profile"></div>
            </div>
            <div class="message-right">
              <div class="message-header">
                <p class="message-from">{{ message.from }}</p>
                <p class="message-time">{{ message.time }}</p>
              </div>
              <div class="message-content">
                {{ message.text }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="message-input">
        <form onchange="console.log('changed!')" class="message-form">
          <input
            type="text"
            v-model="userName"
            class="message-input"
            placeholder="Write a message"
          />
          <button>send</button>
        </form>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  mounted() {
    this.matchHeight();
  },
  matchHeight() {
    var height = this.$refs.infoBox.clientHeight + 'px';
  },
  data: function() {
    return {
      isActive: false,
      /*
        chats = [
            {
                from: frabbrice,
                messages:[
                    {
                    from: 'John Doe',
                    time: '6:30 PM',
                    text: 'hello how are u doing i hope u are doi'
                    },
                    {
                    from: 'John Doe',
                    time: '6:30 PM',
                    text: 'hello how are u doing i hope u are doi'
                    },
                    {
                    from: 'John Doe',
                    time: '6:30 PM',
                    text: 'hello how are u doing i hope u are doi'
                    },
                ]
            },
            {
                from: lamin,
                messages:[{
                   fromcolor: 'rgba(to, 255, 255, 1)',

                }]
            }
        ]

        */
      messages: [
        {
          from: 'John Doe',
          time: '6:30 PM',
          text:
            'hello how are u doing i hope u are doing well and Nice to Meet u And Thank u so much for accepting me...❤',
        },
        {
          from: 'Eliane',
          time: '6:35 PM',
          text: 'You are welcome. Happy to connect with you.',
        },
        {
          from: 'John Doe',
          time: '6:40 PM',
          text:
            ' We are interrested in your startup idea. We think it could have a lot of impact.',
        },
        {
          from: 'Eliane',
          time: '6:41 PM',
          text:
            'Good to hear that you are interrested in your startup idea. We are working really hard to make it a reality.',
        },
        {
          from: 'John Doe',
          time: '6:43 PM',
          text: 'We would like to work with you to make it a reality.',
        },
        {
          from: 'Eliane',
          time: '6:44 PM',
          text:
            'We highly welcome you to join us  in realizing this wonderful dream',
        },
      ],
    };
  },
  methods: {
    showMessageBox: function() {
      this.isActive = !this.isActive;
    },
  },
  name: 'MessageBox',
};
</script>

<style>
.messagebox {
  height: 0;
  position: fixed;
  z-index: 5000;
  bottom: -410px;
  right: 10px;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
  transition-duration: 167ms;
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* display: none; */
}
.messages-container {
  background-color: rgb(80, 80, 80);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border: 2px solid #354b6e;
  width: 388px;
  /* height: 300px; */
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 0px 0;
  min-height: 68px;
  position: relative;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.057); */
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.16);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.16);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.16);
  background-clip: padding-box;
  background-color: #fff;
  /* border-radius: 0.8rem; */
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}
.header-left {
  flex-grow: 0.7;
  flex-basis: 0;
  height: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reciever-profile {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.536);
  border-radius: 20px;
}
.reciever-name {
  margin-left: 10px;
  font-weight: bolder;
  color: green;
  font-size: 14px;
}
.header-right {
  flex-grow: 0.3;
  flex-basis: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0px;
}
.action-button {
  /* background-color: red; */
  outline: none;
  border: none;
  margin: 0 10px;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #117d12;
  background: rgba(0, 0, 0, 0.051);
  width: 45px;
  height: 45px;
  border-radius: 20px;
}
.action-button:hover {
  color: orange;
  background: #117d12;
}
.close-message-box {
  font-size: 30px;
}
svg {
  width: 1.6rem;
  height: 1.6rem;
}
.body {
  background-color: rgb(255, 255, 255);
  padding: 15px 0;
  height: 400px;
  overflow-y: auto;
}
.messages {
  height: 100%;
  overflow-y: auto;
}
.message {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.091);
  word-wrap: break-word;
  white-space: pre-line;
}
.message-left {
  padding: 0 10px;
}
.message-right {
  padding: 10px;
}
.message-header {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.105);
}
.message-from {
  color: black;
  font-weight: bold;
}
.message-time {
  /* margin-left: 30px; */
  font-weight: lighter;
}
.message-content {
  color: #7f7f7f;
  padding: 10px 0;
}
.message-input {
  position: absolute;
  z-index: 10000;
  width: 80%;
  height: 40px;
  bottom: 0px;
}
.message-form {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
input[type='text'],
select {
  padding: 0px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
*:focus {
  outline: 0 !important;
}

.active {
  bottom: 0;
}
</style>
