<template>
  <div class="btn-wrapper">
    <div class="btn btn-edit" @click.prevent="edit()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 12.19 12.19"
      >
        <path
          d="M6.912,2.227,9.96,5.275,3.341,11.894l-2.718.3a.571.571,0,0,1-.631-.631L.3,8.843,6.912,2.227Zm4.933-.454L10.414.342A1.143,1.143,0,0,0,8.8.342L7.45,1.689,10.5,4.737,11.845,3.39a1.143,1.143,0,0,0,0-1.617Z"
          transform="translate(0.011 -0.008)"
          fill="#8c8c8c"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    edit: function () {
      this.$emit("edit");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-edit svg {
  height: 11px !important;
  width: 11px !important;
}
.btn-wrapper {
  display: none;

  .edit-profile & {
    display: flex;
  }

  .btn-edit {
    width: 22px;
    height: 22px;
    padding: 5px;
    line-height: 0;
    border: 1px solid rgba(112, 112, 112, 0.002);
    border-radius: 15px;
    background-color: rgba(140, 140, 140, 0.22);

    &:hover {
      background-color: rgba(140, 140, 140, 0.4);
    }
  }
}
</style>
