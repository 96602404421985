<template>
  <div class="btn-wrapper">
    <div class="btn-add" @click.prevent="add()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 42.404 42.404"
      >
        <path
          d="M39.375,48.659H25.745V35.029A3.029,3.029,0,0,0,22.716,32H19.688a3.029,3.029,0,0,0-3.029,3.029v13.63H3.029A3.029,3.029,0,0,0,0,51.688v3.029a3.029,3.029,0,0,0,3.029,3.029h13.63v13.63A3.029,3.029,0,0,0,19.688,74.4h3.029a3.029,3.029,0,0,0,3.029-3.029V57.745h13.63A3.029,3.029,0,0,0,42.4,54.716V51.688A3.029,3.029,0,0,0,39.375,48.659Z"
          transform="translate(0 -32)"
          fill="#8c8c8c"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    add: function () {
      this.$emit("add");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add svg {
  width: 11px !important;
  height: 11px !important;
}
.btn-wrapper {
  display: none;

  .edit-profile & {
    display: flex;
  }

  .btn-add {
    width: 22px;
    height: 22px;
    padding: 5px;
    line-height: 0;
    border: 1px solid rgba(112, 112, 112, 0.002);
    border-radius: 15px;
    background-color: rgba(140, 140, 140, 0.22);

    &:hover {
      background-color: rgba(140, 140, 140, 0.4);
    }
  }
}
</style>
