<template>
  <div class="mute-status" v-if="getIsBlocked">
    <p v-if="getBlockedUser.from === authUser.id">
      {{ $t("thisConvo") }}
      <span class="action-status"> {{ $t("chatBlocked") }}</span>
      <button class="blocked" @click="unBlock">{{ $t("Unblock") }}</button>
    </p>
    <p v-else>{{ $t("beenBlockedThisUser") }}.</p>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Block",
  computed: {
    ...mapGetters([
      "authUser",
      "getCurrentlyChattingWith",
      "getIsBlocked",
      "getBlockedUser",
      "getIsNewChatConvo",
    ]),
  },
  methods: {
    ...mapActions(["unblockUser"]),
    async unBlock() {
      const from = this.authUser.id;
      const to = this.getCurrentlyChattingWith.id;
      await this.unblockUser({ from: this.authUser.id, to });
      this.$socket.emit("un_block_user", {
        from,
        to: this.getCurrentlyChattingWith.username,
      });
    },
  },
};
</script>

<style scoped>
.mute-status {
  display: flex;
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.34);
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 1px solid rgba(236, 30, 30, 0.1);
  font-size: 1rem;
  line-height: 1.42857;
}
.mute-status p {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: red;
  font-weight: 500;
  text-align: center !important;
  padding-top: 15px;
}
.action-status {
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.509);
  font-weight: bold;
}
button.muted {
  border: none;
  background-color: #ffc802;
  border-radius: 10px;
  padding: 2px 10px;
  font-weight: bold;
  font-size: 12px;
}
button.blocked {
  border: none;
  background-color: rgb(177, 225, 200);
  color: rgb(20, 19, 19);
  border-radius: 10px;
  padding: 2px 10px;
  font-weight: bold;
  font-size: 12px;
}
</style>