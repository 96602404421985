<template>
  <div>
    <div v-if="!loading">
      <div id="profile" :class="[
        myprofile ? 'profile edit-profile' : 'profile',
        {
          HPaddingZero: this.getUserFollowing.length > 0,
          showNotVerifiedNotfication: authUser.is_verified === 0,
        },
      ]">
        <div class="profile-container">
          <div class="main-container" :class="{
            fullWidth: getUserFollowing.length === 0,
            fullWidth60: getUserFollowing.length > 0,
          }">
            <div class="main-background"></div>
            <div class="main-wrapper">
              <showcase :basicInfos="user_profile.basicInfos" :user="user_profile.user" :status="status" />
              <outline class="desktop-only" />
              <div class="desktop-only">
                <Looking v-if="user_profile.careerAndEdu.engagements.length"
                  :tags="user_profile.careerAndEdu.engagements" />
                <!-- <Expertise v-if="getUserTalents.length" /> -->
                <Expertise />
                <Startup v-if="getUserTalents.length" :tags="getUserTalents" />
              </div>
              <template v-if="getExperiences.length || getEducations.length">
                <tabs :tabs="tabs_d" :initial-tab="initialTab" class="desktop-only">
                  <template #tab-head-experience v-if="getExperiences.length">{{
                  $t("WorkExperience")
                  }}</template>
                  <template #tab-panel-experience v-if="getExperiences.length">
                    <work-experience v-for="(experience, index) in getExperiences" :key="index"
                      :experience="experience" />
                  </template>


                  <template #tab-head-education v-if="getEducations.length">{{
                  $t("Education")
                  }}</template>
                  <template #tab-panel-education v-if="getEducations.length">
                    <Education v-for="(education, index) in getEducations" :key="index" :education="education" />
                  </template>
                </tabs>
              </template>

              <tabs :tabs="tabs_m" initial-tab="interests" class="mobile-only">
                <template #tab-head-interests>Interests</template>
                <template #tab-panel-interests>
                  <Looking v-if="user_profile.careerAndEdu.engagements.length"
                    :tags="user_profile.careerAndEdu.engagements" />
                  <Expertise v-if="getUserTalents.length" />
                  <Startup v-if="getUserTalents.length" :tags="getUserTalents" />
                </template>

                <template #tab-head-career v-if="getExperiences.length || getEducations.length">{{ $t("Career") }}
                  <span class="hidden-xs">Info</span></template>
                <template #tab-panel-career>
                  <education-level :basicInfos="user_profile.basicInfos" />
                  <work-experiences :experiences="getExperiences" v-if="getExperiences.length" />
                  <Educations :educations="getEducations" v-if="getEducations.length" />
                </template>

                <template v-if="getUserFollowing.length" #tab-head-following>{{
                $t("Following")
                }}</template>
                <template #tab-panel-following v-if="getUserFollowing.length">
                  <followings />
                </template>
              </tabs>
            </div>
          </div>

          <div class="sidebar-container" :class="{ fullWidth30: getUserFollowing.length > 0 }"
            v-if="getUserFollowing.length">
            <div class="container-box">
              <div v-if="renderAvailablePitchdeck === true">
                <div class="d-flex justify-content-between align-items-center pr-4">
                  <p class="title">{{ $t(textpitchdeck) }}</p>

                  <p class="tool-tip">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" c>
                      <path
                        d="M12.2 19.1V10.7M12.2 7.99995V5.19995M12.2 23.4C6.026 23.4 1 18.374 1 12.2C1 6.026 6.026 1 12.2 1C18.374 1 23.4 6.026 23.4 12.2C23.4 18.374 18.374 23.4 12.2 23.4Z"
                        stroke="#00853B" stroke-width="2" stroke-linejoin="round" />
                    </svg>
                    <span class="tool-tip-text">
                      <p class="p-3">
                        {{$t(texttool)}}
                      </p>
                    </span>
                  </p>
                </div>

                <requestedPitchdeck />
              </div>

              <div>
                <p class="title">{{ $t("Following") }}</p>
                <followings />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import i18n from "../../../i18n";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import "@/assets/scss/profile.scss";
import Showcase from "../../../components/Profile/Diaspora/profile/main/Showcase";
import Tabs from "../../../components/Profile/Diaspora/profile/main/Tabs";
import WorkExperience from "../../../components/Profile/Diaspora/profile/main/WorkExperience";
import WorkExperiences from "../../../components/Profile/Diaspora/profile/main/WorkExperiences";
import Education from "../../../components/Profile/Diaspora/profile/main/Education";
import Educations from "../../../components/Profile/Diaspora/profile/main/Educations";
import Outline from "../../../components/Profile/Diaspora/profile/showcase/Outline";
import EducationLevel from "../../../components/Profile/Diaspora/profile/interests/EducationLevel";
import Looking from "../../../components/Profile/Diaspora/profile/interests/Looking";
import Expertise from "../../../components/Profile/Diaspora/profile/interests/Expertise";
import Startup from "../../../components/Profile/Diaspora/profile/interests/Startup";
import Followings from "../../../components/Profile/Diaspora/profile/sidebar/Followings";
import requestedPitchdeck from "../../../components/Profile/Diaspora/profile/sidebar/requestedPitchdeck";
import Axios from "axios";
import APIURLS from "../../../constant";
export default {
  props: {
    texttool: {
      type: String,
      default: "requestToolTip",
    },

    textpitchdeck: {
      type: String,
      default: "availablePitchDeck",
    },
  },
  metaInfo() {
    return {
      title:
        "Bantaba | Diaspora | " +
        this.profile.firstname +
        " " +
        this.profile.name,
    };
  },
  // metaInfo() {
  //   return {
  //     title: this.firstname + " " + this.name + " | Bantaba",
  //   };
  // },
  // mounted(){

  // },
  async created() {
    var lang = this.getUserLang;
    var sortBy = require("lodash").sortBy;
    let username = this.$route.params.username;
    const routeName = this.$route.name;
    if (username) {
      if (this.authUser.type === 2 && this.authUser.username === username) {
        let redirect = `/${this.$i18n.locale}/${this.$i18n.t("routes.myPage")}`;
        window.location.replace(redirect);
      }
      const count = username.split(/[\s,-.]+/).length;
      if (count > 1) {
        let url = `/${i18n.locale}/${i18n.t("routes.error")}`;
        if (this.$route.path != url) {
          this.$router.push(url);
        }
        // this.$router.push(`/${i18n.locale}/${i18n.t("routes.error")}`);
      } else {
        if (username === this.authUser.username) {
          this.myprofile = true;
          this.$store.commit("setProfileEditingMode", true);
        } else {
          this.myprofile = false;
          this.$store.commit("setProfileEditingMode", false);
        }
      }
      await this.loadUserByUsername({ username });

      await this.pitchdeckViewchecker();

      this.profile = this.getProfile;
      this.user_id = this.profile.id;

      if (this.authUser.type === 1) {
        await this.getStatus();
      }

      this.username = username;
      this.country = this.profile.profile
        ? this.profile.profile.residenceContry
        : "";
      this.origin = this.profile.profile
        ? this.profile.profile.birthCountry
        : "";
      this.city = this.profile.profile
        ? this.profile.profile.residenceCity
        : "";
    } else {
      this.user_id = this.authUser.id;
      username = this.authUser.username;
      this.firstname = this.authUser.firstname;
      this.username = this.authUser.username;
      this.country = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";
      this.origin = this.authUser.profile
        ? this.authUser.profile.birthCountry
        : "";
      this.city = this.authUser.profile
        ? this.authUser.profile.residenceCity
        : "";
    }
    await this.loadUserTypeOfSupport({ user_id: this.user_id });
    let action = await this.loadUserProfileSections({ username });

    if (!action) {
      // this.$router.push(`/${i18n.locale}/${i18n.t("routes.error")}`);
      let url = `/${i18n.locale}/${i18n.t("routes.error")}`;
      if (this.$route.path != url) {
        this.$router.push(url);
      }
    }
    let engagements = [];
    if (this.getUserSupportTypes) {
      this.getUserSupportTypes.map((engagement) => {
        engagements.push({
          id: engagement.id,
          user_id: engagement.user_id,
          name: engagement.typeOfSupport,
        });
      });
    }

    await this.loadUserExpertises({
      lang,
      user_id: this.user_id,
    });

    await this.loadExperiences({ user_id: this.user_id });
    await this.loadEducation({ user_id: this.user_id });

    this.experiences = this.getExperiences;
    this.educations = this.getEducations;

    await this.loadUserFollowingStartups({
      user_id: this.user_id,
    });
    let basicInfos = {
      user_id: this.user_id,
      avatar: this.avatar,
      living: this.fortmatCountry(this.country),
      origin: this.fortmatCountry(this.origin),
      city: this.city,
      educationLevel: this.profile.educationLevel,
      experienceYears: this.profile.experienceYears,
    };
    let careerAndEdu = {
      bio: this.about,
      interests: [],
      engagements,
    };

    this.user_profile = {
      user: this.profile,
      basicInfos,
      careerAndEdu,
    };

    if (!this.getExperiences.length && this.getEducations.length) {
      this.initialTab = "education";
    }

    await this.loadUserFollowingStartups({
      user_id: this.user_id,
    });

    if (!this.getUserFollowing.length) {
      this.tabs_m = ["interests", "career"];
    }

    if (!this.getExperiences.length && !this.getEducations.length) {
      this.tabs_m = ["interests"];
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "getConnectionStatus",
      "getProfileSections",
      "getUserSupportTypes",
      "getProfileEditingMode",
      "getUserTalents",
      "getUserSectorOfInterest",
      "getExperiences",
      "getEducations",
      "getUserFollowing",
      "getUserLang",
    ]),
    connectionStatus() {
      return this.status;
    },
  },
  methods: {
    ...mapActions([
      "loadUserByUsername",
      "loadUserProfileSections",
      "loadUserTypeOfSupport",
      "loadUserExpertises",
      "loadUserFollowingStartups",
      "loadExperiences",
      "loadEducation",
    ]),
    pitchdeckViewchecker() {
      let authID = JSON.parse(localStorage.getItem('auth'));
      let profileID = JSON.parse(localStorage.getItem('profileUser'));
      // console.log(authID.id, "ungn")
      // console.log(profileID, "ungnf")
      // if (authID.id === profileID) {
      //   this.renderAvailablePitchdeck = true;
      // }
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    getStatus() {
      Axios.get(
        APIURLS.CONNECTIONS +
        "connected/user/" +
        this.profile.id +
        "/auth_user/" +
        this.authUser.id
      )
        .then((response) => {
          if (response.data === "blocked") {
            this.$store.commit("setConnectionStatus", {
              status: response.data,
            });
            // this.$router.push(`/${i18n.locale}/${i18n.t('routes.error')}`);
          }
          this.status = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
  data() {
    return {
      renderAvailablePitchdeck: true,
      status: "",
      initialTab: "experience",
      tabs_m: ["interests", "career", "following"],
      tabs_d: ["experience", "education"],
      myprofile: false,
      profile: {},
      user_profile: {},
      name: "",
      firstname: "",
      username: "",
      country: "",
      city: "",
      avatar: "",
      about: "",
      experiences: {},
      languages: {},
      skills: {},
      educations: {},
      origin: "",
      address: "",
      iscurrentUser: false,
      loading: true,
    };
  },
  name: "ViewProfile",
  components: {
    Showcase,
    WorkExperience,
    WorkExperiences,
    Education,
    Educations,
    Tabs,
    Outline,
    Looking,
    Expertise,
    Startup,
    EducationLevel,
    Followings,
    requestedPitchdeck,
  },
};
</script>


<style lang="scss" scoped>
* {
  text-align: left !important;
}

#profile {
  padding-top: 10px;
  background: #f0f4f2;
  overflow-y: scroll;
  height: 100vh;
}

@media (min-width: 1200px) {
  #profile {
    padding-top: 40px;
    background: #f0f4f2;
    padding-bottom: 100px;
    margin-top: 0;
  }

  .HPaddingZero {
    padding: 5px !important;
    padding-top: 40px !important;
    background: #f0f4f2;
    padding-bottom: 100px;
    margin-top: 0;
    /* background-color: orange; */
  }

  .showNotVerifiedNotfication {
    margin-top: 22px !important;
  }
}

.main-container {
  width: 70%;
  padding: 0 10px;

  @include max-width(991) {
    width: 100%;
    background: #fff;
    padding: 0;
    border-radius: var(--section-border-radius);
  }

  @include max-width(600) {
    padding: 0 !important;
  }

  .main-background {
    height: 180px;
    background: rgba(27, 165, 75, 0.49);
    border-radius: 15px 15px 0 0;
    z-index: 0;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: url("../../../assets/images/bubble.svg") repeat center center;
      background-size: contain;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    @include max-width(991) {
      height: 150px;
      border-radius: 10px;
    }
  }

  .main-wrapper {
    position: relative;
    z-index: 1;

    @include max-width(991) {
      margin-top: -60px;
      margin-left: 4px;
      margin-right: 4px;
      padding-bottom: 15px;
      border-radius: 30px; // This value must be the same as the border-radius value of .showcase (991) class in Showcase.vue
      border: 1px solid rgba(112, 112, 112, 0.2);
      // background-color: orange;
    }

    .hidden-xs {
      @include max-width(410) {
        display: none;
      }
    }
  }
}

.sidebar-container {
  width: 30%;
  padding: 0 10px;

  @include max-width(991) {
    display: none;
  }

  .container-box {
    padding: 10px 0;
    background: #fff;
    border-radius: 15px;

    .title {
      padding: 15px 30px;
      font-size: 20px;
      font-weight: 700;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    }
  }
}

@media (min-width: 1200px) {
  .profile {
    padding: 10px;
    /* width: 67vw; */
    height: 100vh;
    margin-top: 30px;
    /* width: min(78vw, 1140px) !important; */
    width: 990px !important;

    margin-top: 0px;
    padding: 40px;
  }

  .profile-container {
    max-width: 100%;
    /* background-color: red; */
    padding: 0 0px;
  }

  .main-container {
    /* width: auto;
    min-width: 70%;
    max-width: 100%;*/
    padding: 0;
  }

  .fullWidth {
    /* background-color: red; */
    width: 100%;
  }

  .fullWidth60 {
    width: calc(100% - 370px);
  }

  .sidebar-container {
    width: 30%;
    padding: 0 10px;
  }

  .fullWidth30 {
    width: 370px;
    padding-left: 20px;
  }
}

@media (min-width: 600px) {
  .profile {
    padding-top: 0px;
    width: 67vw;
    height: 100vh;
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .showNotVerifiedNotfication {
    margin-top: 22px !important;
  }
}

.tool-tip .tool-tip-text {
  display: none;
  width: 355px;
  background-color: #ffff;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  right: 3em;
  box-shadow: 2px 3px #c7c7c7;
}

.tool-tip:hover .tool-tip-text {
  display: block;
}
</style>

