<template>
  <div class="form__div" :class="hasError ? 'error' : ''">
    <span class="error_msg" v-if="hasError"> {{ $t(error_msg) }}</span>
    <!-- <span v-if="isPassword" class="strength" :class="level_0"></span> -->
    <template v-if="isPassword">
      <a @click="togglePass(false)" class="password">
        <svg
          height="32px"
          width="32px"
          version="1.1"
          viewBox="0 0 612 612"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          v-if="showIcon"
          @click="togglePass"
        >
          <path
            d="m609.61 315.43c3.19-5.874 3.19-12.979 0-18.853-58.464-107.64-172.5-180.72-303.61-180.72s-245.14 73.078-303.61 180.72c-3.19 5.874-3.19 12.979 0 18.853 58.465 107.64 172.5 180.72 303.61 180.72s245.14-73.078 303.61-180.72zm-303.61 136.43c-80.554 0-145.86-65.302-145.86-145.86s65.301-145.86 145.86-145.86 145.86 65.302 145.86 145.86-65.302 145.86-145.86 145.86z"
          />
          <path
            d="m306 231.67c-6.136 0-12.095 0.749-17.798 2.15 5.841 6.76 9.383 15.563 9.383 25.198 0 21.3-17.267 38.568-38.568 38.568-9.635 0-18.438-3.541-25.198-9.383-1.401 5.703-2.15 11.662-2.15 17.798 0 41.052 33.279 74.33 74.33 74.33s74.33-33.279 74.33-74.33-33.277-74.331-74.329-74.331z"
          />
        </svg>
      </a>

      <a @click="togglePass(true)" class="password">
        <svg
          height="32px"
          width="32px"
          version="1.1"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          xml:space="preserve"
          v-if="!showIcon"
        >
          <g>
            <g>
              <path
                d="M8.10869891,20.8913011 C4.61720816,18.8301147 3,16 3,16 C3,16 7,9 16,9 C17.3045107,9 18.5039752,9.14706466 19.6014388,9.39856122 L16.8986531,12.1013469 C16.6097885,12.0350342 16.3089856,12 16,12 C13.7908609,12 12,13.7908609 12,16 C12,16.3089856 12.0350342,16.6097885 12.1013469,16.8986531 L8.10869891,20.8913011 L8.10869891,20.8913011 L8.10869891,20.8913011 Z M12.398561,22.601439 C13.4960246,22.8529356 14.6954892,23.0000001 16,23 C25,22.999999 29,16 29,16 C29,16 27.3827918,13.1698856 23.8913008,11.1086992 L19.8986531,15.1013469 C19.9649658,15.3902115 20,15.6910144 20,16 C20,18.2091391 18.2091391,20 16,20 C15.6910144,20 15.3902115,19.9649658 15.1013469,19.8986531 L12.398561,22.601439 L12.398561,22.601439 L12.398561,22.601439 Z M19,16 C19.0000001,16.7677669 18.7071068,17.5355339 18.1213203,18.1213203 C17.5355339,18.7071068 16.7677669,19.0000001 16,19 L19,16 L19,16 L19,16 Z M16,13 C15.2322331,12.9999999 14.4644661,13.2928932 13.8786797,13.8786797 C13.2928932,14.4644661 12.9999999,15.2322331 13,16 L16,13 L16,13 L16,13 Z M24,7 L7,24 L8,25 L25,8 L24,7 L24,7 Z"
                id="eye-hidden"
              />
            </g>
          </g></svg></a>
    </template>
    <input
      v-on="$listeners"
      :type="!showIcon ? 'text' : typeName"
      class="form__input"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      :required="isRequired"
      @input="$emit('update', $event.target.value)"
    />

    <label :for="label" class="form__label">{{ $t(label) }} </label>
  </div>
</template>

<script>
export default {
  mounted() {
    this.showIcon = this.showPassword;
  },
  data() {
    return {
      showIcon: true,
    };
  },
  methods: {
    togglePass(value) {
      this.showIcon = value;
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Input",
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    typeName: {
      type: String,
      default: "text",
    },
    error_msg: {
      type: String,
      default: " ",
    },
    placeholder: {
      type: String,
      default: " ",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    showPassword: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
};
</script>

<style scoped>
.password {
  position: absolute;
  z-index: 3000;
  right: 10px;
  top: 12px;
}
.showPass svg {
  height: 30px !important;
  width: 30px !important;
  fill: rgb(71, 71, 71) !important;
}
.loader {
  position: absolute;
  top: 10px;
  right: -40px;
}
.form__div {
  position: relative;
  height: 48px;
  margin-bottom: 1.5rem;
}
.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: var(--normal-font-size) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 0.5rem;
  outline: none;
  padding: 1rem;
  background: none;
  z-index: 1;
}
.form__label {
  position: absolute;
  left: 1rem;
  top: 0.7rem;
  padding: 0 0.25rem;
  background-color: #f6faf7;
  color: var(--input-color);
  font-size: var(--normal-font-size);
  transition: 0.3s;
  font-weight: normal;
}

.error_msg {
  position: absolute;
  top: 48px;
  left: 5px;
  width: 100%;
  height: 100%;
  color: var(--error-color) !important;
}

/*Input focus move up label*/
.form__input:focus + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  color: var(--first-color);
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus) + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus*/
.form__input:focus {
  border: 1.5px solid var(--first-color) !important;
}
.error > .form__input {
  border: 1.5px solid var(--error-color) !important;
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
  animation: shake 0.2s ease-in-out 0s 2;
}

.error > .form__label {
  color: var(--error-color) !important;
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
  animation: shake 0.2s ease-in-out 0s 2;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form__div {
    position: relative;
    height: 48px;
    margin-bottom: 1rem;
  }
}

/* Password Strength */

.strength {
  position: absolute;
  bottom: 0px;
  left: 5px;
  right: 0;
  display: block;
  width: 25%;
  height: 5px;
  background-color: #a5df41;
  overflow: hidden;
  border-radius: 0.5rem;
  z-index: 9;
  transition: all 0.5s linear;
}

.level_0 {
  background-color: var(--error-color);
}

.level_1 {
  background-color: var(--red-dar-color);
}

.level_2 {
  width: 50%;
  background-color: var(--warning-dark-color);
}

.level_3 {
  width: 75%;
  background-color: var(--warning-light-color);
}

.level_4 {
  width: 98%;
  background-color: var(--first-color);
}

.level-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 11;
}

.show-icon {
  position: absolute;
  right: 40px;
  top: 15px;
  font-size: 20px;
  z-index: 11;
  cursor: pointer;
}

.hide-icon {
  position: absolute;
  right: 40px !important;
  top: 15px;
  font-size: 20px;
  z-index: 11;
  cursor: pointer;
  color: #555;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>

<style >
@-webkit-keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
</style>
