<template>
  <div class="input-password">
    <label class="label">{{ $t(label) }}</label>
    <div class="control">
      <input
        :type="[showPassword ? 'text' : 'password']"
        :value="value"
        @input="input"
      />
      <button class="button" @click="toggleShow">
        <span class="icon is-small is-right">
          <i
            :class="{
              'feather-eye-off': showPassword,
              'feather-eye': !showPassword,
            }"
            class="fas"
          ></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputPassword",
  data: () => ({
    showPassword: false,
    confirmPassword: "",
  }),
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    input(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.label {
  margin: 10px 0;
}

.input-password {
  font-size: 20px;
  text-align: left;
  margin: 10px 0;
}

.button {
  position: absolute;
  right: 10px;
  top: 0;
}

input {
  padding-right: 35px;
  padding-left: 10px;
  letter-spacing: 2px;
  font-size: 20px;
  height: 45px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #aaaaaa !important;
}

.button {
  background: none;
  border: none;
  border-radius: 50%;
  bottom: -4px;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

button:focus {
  border: none;
  outline: none;
}

.valid input {
  // background: #e4f4e9 no-repeat padding-box;
  border: 1px solid #349e4d;
}

.invalid input {
  // background: #f5d6d6 no-repeat padding-box;
  border: 1px solid #f80000;
}
</style>
