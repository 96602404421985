<template>
  <div>
    <div class="py-4">
      <div class="container-fluid px-md-5">
        <div class="row" v-if="!loading">
          <main class="col-md-10 box bg-white rounded my-4 p-4">
            <UpdateStartupInfos
              class="shadow-md"
              :startup="startup"
              :profile="profile"
              :sectors="sectors"
            />
          </main>
        </div>
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../../constant";
import { mapGetters, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import i18n from "../../../i18n";
import { en, fr } from "vuejs-datepicker/dist/locale";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Edit Page ",
    };
  },
  async created() {
    await this.loadStartup();
    this.startup = this.getStartup;

    //this.startup.location = this.fortmatCountry(this.startup.location);

    var lang = i18n.locale;
    await this.loadSectorsList({ lang });
    this.sectors = this.getSectorstList;

    await this.loadProfile();
    this.profile = this.getProfile;
    //if()
    if (this.profile) {
      this.profile.residenceContry = this.fortmatCountry(
        this.profile.residenceContry
      );
      this.profile.birthCountry = this.fortmatCountry(
        this.profile.birthCountry
      );
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getStartup", "getSectorstList", "getProfile"]),
  },
  data() {
    return {
      result: null,
      en: en,
      fr: fr,
      DatePickerFormat: "yyyy",
      startup: {},
      sectors: {},
      profile: {},
      loading: true,
      btnLoading: false,
      btnLoadingBasic: false,
      btnLoadingSocials: false,
      availabilityText: null,
      availability: null,
    };
  },
  methods: {
    ...mapActions([
      "loadStartup",
      "loadSectorsList",
      "loadProfile",
      "updateBasicInfo",
    ]),
    getLang(locale) {
      var value = "navBar." + locale;
      return this.$i18n.t(value);
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    checkUsername(username) {
      Axios.get(APIURLS.USERS + "check-username/" + username).then(
        (response) => {
          if (this.authUser.username === username) {
            this.availability = true;
            this.availabilityText = `${this.$i18n.t("yourUsername")}`;
          } else if (response.data) {
            this.availability = true;
            this.availabilityText = `${this.$i18n.t("usernameAvailable")}`;
          } else {
            this.availability = false;
            this.availabilityText = `${this.$i18n.t("usernameNotAvailable")}`;
          }
        }
      );
    },
    updateBasicProfil() {
      this.btnLoadingBasic = true;
      const preferedLanguage = localStorage.getItem("preferedLanguage");
      if (preferedLanguage) {
        if (this.profile.preferedLanguage != preferedLanguage) {
          localStorage.removeItem("preferedLanguage");
          localStorage.setItem(
            "preferedLanguage",
            this.profile.preferedLanguage
          );
        }
      } else {
        if (this.profile.preferedLanguage) {
          localStorage.setItem(
            "preferedLanguage",
            this.profile.preferedLanguage
          );
        }
      }

      const basicInfo = {
        name: this.profile.user.name,
        email: this.profile.user.email,
        username: this.profile.user.username,
      };
      const extendedInfo = {
        phone: this.profile.phone,
        residenceContry: this.countryCode(this.profile.residenceContry),
        residenceCity: this.profile.residenceCity,
        birthCountry: this.countryCode(this.profile.birthCountry),
        birthCity: this.profile.birthCity,
        address: this.profile.address,
        postalCode: this.profile.postalCode,
        genre: this.profile.genre,
        dob: this.profile.dob,
        preferedLanguage: this.profile.preferedLanguage,
        organization: this.profile.organization,
        currentPosition: this.profile.currentPosition,
      };
      //this.btnLoadingBasic = false;
      this.$store
        .dispatch("updateBasicInfo", {
          basicInfo: basicInfo,
          extendedInfo: extendedInfo,
        })
        .then((success) => {
          this.$noty.success(`${i18n.t("success")}`);
          this.btnLoadingBasic = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    updateAbout() {
      this.btnLoading = true;
      this.$store
        .dispatch("updateAbout", {
          headline: this.profile.headline,
          about: this.profile.about,
        })
        .then((success) => {
          this.btnLoading = false;
          this.$noty.success(`${i18n.t("success")}`);
          //this.$router.push(`/${i18n.locale}/${i18n.t('routes.dashboard')}`);
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    updateSocials() {
      this.btnLoadingSocials = true;
      this.$store
        .dispatch("updateSocials", {
          linkedin: this.profile.linkedin,
          facebook: this.profile.facebook,
          youtube: this.profile.youtube,
          instagram: this.profile.instagram,
          twitter: this.profile.twitter,
          github: this.profile.github,
          website: this.profile.website,
        })
        .then((success) => {
          this.btnLoadingSocials = false;
          this.$noty.success(`${i18n.t("success")}`);
          //this.$router.push(`/${i18n.locale}/${i18n.t('routes.dashboard')}`);
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  name: "EditProfile",
};
</script>
