<template>
  <div class="main-container padding-bottom">
    <div class="row" v-if="getStartups.length">
      <div class="card-col" v-for="startup in getStartups" :key="startup.name">
        <CardStartup
          :name="startup.startupname"
          :description="startup.description"
          :sector="startup.sector"
          :yrFounded="formatDate(startup.foundingYear)"
          :location="fortmatCountry(startup.location)"
          :profileImg="startup.user ? startup.user.avatar : ''"
          :coverImg="startup.cover"
          bg-color="#BAE0C2"
          :startup="startup"
        />
      </div>
    </div>
    <div class="row" v-else>
      <NoDataFound
        :svg="require('../../assets/images/noData/no_startups.svg')"
        title="Oops, no startups found"
        text="We're working hard on adding more startups 👍🏾"
        buttonText="Reset filters"
        :buttonLink="`/${$i18n.locale}/startups`"
        :hasTitle="true"
        :hasText="true"
        :hasButton="true"
        :buttonType="1"
      />
    </div>
    <!-- <div>getStartups: {{getStartups.length}}</div> -->
    <!-- v-if="getShowStartupLoadMore" -->
    <LoadMore
      v-if="getStartups.length >= 15"
      @click.native="loadMore"
      :loadingMore="loadingMore"
    />
  </div>
</template>

<script>
import CardStartup from "./Card_Startups.vue";
import Pagination from "./Pagination.vue";
import LoadMore from "../Loaders/LoadingMore.vue";
import moment from "moment";
import i18n from "../../i18n";
import ClearFiltersBtn from "../Buttons/ClearFilter.vue";
import NoDataFound from "../NoDataFound/Version-2.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    CardStartup,
    LoadMore,
    NoDataFound,
  },
  mounted() {
    if (
      this.getStartups.length >= this.getStartupsLimit &&
      this.getStartups.length < this.getStartupsTotalCount
    ) {
      this.$store.commit("setShowStartupLoadMore", true);
    } else if (this.getStartups.length === this.getStartupsTotalCount) {
      this.$store.commit("setShowStartupLoadMore", false);
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartups",
      "getShowStartupLoadMore",
      "getStartupsTotalCount",
      "getStartupsPage",
      "getStartupsLimit",
      "getSearchFilters",
      "getStartupSearchFilters",
    ]),
  },

  data() {
    return {
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions(["loadDiaspora", "searchWithFilters", "loadMoreStartups"]),
    async loadMore() {
      this.loadingMore = true;
      let page = this.getStartupsPage + 1;
      let startup_id = this.authUser.startup ? this.authUser.startup.id : 0;
     
      await this.loadMoreStartups({
        data: {
          lang: this.$i18n.locale,
          startup_id,
          page,
          limit: this.getStartupsLimit,
          filters: this.getStartupSearchFilters,
        },
      });
      this.$store.commit("setStartupsPage", page);
      if (this.getStartups.length === this.getStartupsTotalCount) {
        this.$store.commit("setShowStartupLoadMore", false);
      }
      this.loadingMore = false;
    },
    formatDate(date) {
      return moment(date).format("YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  props: {
    startups: Array,
  },
};
</script>

<style scoped>
.main-container {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  padding: 0 1%;
  box-sizing: border-box;
  min-height: 83vh;
  /* overflow-y: scroll; */
}
.pagiation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 1%;
  box-sizing: border-box;
  border-radius: 1rem;
  background: #fff;
  background-color: red !important;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
}
.card-col {
  box-sizing: border-box;
  width: 33.333333333333%;
  padding: 0 0.5%;
}

@media (max-width: 1199px) {
  .card-col {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .card-col {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .card-col {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .main-container {
    /* padding: 0; */
    /* margin-top: 30px; */
    padding-top: 5px;
    min-height: 83vh !important;
    overflow-y: auto !important;
  }
  .row2 {
    height: 95vh;
    overflow-y: scroll;
    padding-bottom: 120px;
  }
  .flex-start {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }
  .card-col {
    box-sizing: border-box;
    /* width: 25%; */
    padding: 0 0.4%;
  }
}
@media (min-width: 600px) {
  .padding-bottom {
    padding-bottom: 60px !important;
  }
}
</style>