/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue';
import App from "./App.vue";

import "./assets/css/GlobalStyle.css";
import "./assets/css/Variable.css";
import "./assets/css/base.css";
import "./assets/scss/base.scss";
import router from "./router";
import i18n from "./i18n";
import store from "./store/";
import VueNoty from "vuejs-noty";
import moment from "moment";
import Clipboard from "v-clipboard";
import CookieLaw from "vue-cookie-law";
import linkify from "vue-linkify";
import Snotify, { SnotifyPosition } from "vue-snotify";
import "vue-snotify/styles/material.css"; // or dark.css or simple.css
import DOMPurify from "dompurify";
import lineClamp from "vue-line-clamp";

Vue.use(lineClamp, {
  // plugin options
});

import Cloudinary from "cloudinary-vue";
Vue.use(Cloudinary, {
  configuration: {
    cloudName: "bantaba",
    secure: false,
  },
});

let optionsSnoty = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 2000,
    showProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  },
};

Vue.use(Snotify, optionsSnoty);

import VueSelect from "vue-select";
Vue.component("v-select", VueSelect.VueSelect);
// import 'vue-select/dist/vue-select.css';

import "vue-search-select/dist/VueSearchSelect.css";

import DefaultLayout from "./layouts/Default.vue";
import LoginLayout from "./layouts/Login.vue";
import LandingLayout from "./layouts/Landing.vue";
import VueMeta from "vue-meta";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import Select2 from 'vue3-select2-component';
import "vuejs-noty/dist/vuejs-noty.css";
// import VuePlyr from 'vue-plyr';
// import 'vue-plyr/dist/vue-plyr.css';

// import VuePhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import vueCountryRegionSelect from "vue-country-region-select";
import InputTag from "vue-input-tag";
// import carousel from 'vue-owl-carousel';
import VueTimeago from "vue-timeago";
import sanitizeHTML from "sanitize-html";
import VueSweetAlert from "vue-sweetalert";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSkeleton from "skeleton-loader-vue";
import ResizeAuto from "./components/ResizeAuto";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import GoTop from "@inotom/vue-go-top";
import Password from "vue-password-strength-meter";
import Avatar from "vue-avatar";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Editor from "@tinymce/tinymce-vue";
import TextareaAutosize from "vue-textarea-autosize";
import CountryFlag from "vue-country-flag";

const options = {
  confirmButtonClass: "btn btn-success btn-fill",
  cancelButtonClass: "btn btn-danger btn-fill",
};

import VueSocketIO from "vue-socket.io";
// import * as io from "socket.io-client";
import io from "socket.io-client";
import APIURLS from "./constant";

const socket = io(APIURLS.CHATLINK);
socket.onAny((event, ...args) => {
  // console.log(event, args);
});

//text popover
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("tippy", TippyComponent);

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io(APIURLS.CHATLINK),
    vuex: {
      store,
    },
  })
);

// const linkedinSocket = io(APIURLS.LINKEDINSERVERLINK);

// linkedinSocket.onAny((event, ...args) => {
//   // console.log("linkedin server on ");
// });
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: io(APIURLS.LINKEDINSERVERLINK),
//   vuex: {
//     store,
//   },
// }));

Vue.use(VueSweetAlert, options);
Vue.use(vueCountryRegionSelect);
Vue.use(GoTop);
Vue.use(VueNoty, { timeout: 2000 });
Vue.use(Clipboard);
Vue.use(VueMeta);
// Vue.use(VuePlyr, { plyr: {} });
Vue.directive("linkified", linkify);
Vue.config.productionTip = false;

// Global registration
Vue.component("Multiselect", Multiselect);
Vue.component("CookieLaw", CookieLaw);
Vue.component("v-select", vSelect);
// Vue.component('Select2', Select2);
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);
Vue.component("ResizeAuto", ResizeAuto);

Vue.component("VueSkeleton", VueSkeleton);
Vue.component("input-tag", InputTag);
Vue.component("Editor", Editor);
Vue.component("country-flag", CountryFlag);

Vue.component("LoginLayout", LoginLayout);
Vue.component("DefaultLayout", DefaultLayout);

Vue.component("Modal", require("./components/Modal/Modal.vue").default);
Vue.component(
  "ModalSuccess",
  require("./components/Modal/ModalSuccess.vue").default
);
Vue.component(
  "ModalError",
  require("./components/Modal/ModalError.vue").default
);

Vue.component("Modal", require("./components/Modal/Modal.vue").default);

Vue.component(
  "MessageBox",
  require("./components/MessageBox/MessageBox.vue").default
);

Vue.component(
  "MessagingList",
  require("./components/ChatComponent/Messaging.vue").default
);

Vue.use(TextareaAutosize);

Vue.component("SocialMedias", require("./components/SocialMedias.vue").default);

Vue.component(
  "Navigation",
  require("./components/Navigation/Navigation.vue").default
);
Vue.component(
  "StarupNewsResources",
  require("./views/startups/StarupNewsResources/StarupNewsResources.vue")
    .default
);
Vue.component(
  "LeftAside",
  require("./components/Asides/LeftAside.vue").default
);
Vue.component(
  "RightAside",
  require("./components/Asides/RightAside.vue").default
);
Vue.component("Footer", require("./components/Footer/Footer.vue").default);
Vue.component(
  "LanguageSwitcher",
  require("./components/LanguageSwitcher.vue").default
);
Vue.component("Loader", require("./components/Loaders/Loader.vue").default);
Vue.component(
  "LoadingMore",
  require("./components/Loaders/LoadingMore.vue").default
);
// Vue.component('carousel', carousel);
// Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
Vue.component("GoTop", GoTop);

Vue.component(
  "ActivateAccount",
  require("./components/ActivateAccount.vue").default
);
Vue.component("AutoLogout", require("./components/AutoLogout.vue").default);
Vue.component("Password", Password);

Vue.component("Widget", require("./components/Widget.vue").default);
Vue.component("ScrollLink", require("./components/ScrollLink.vue").default);
Vue.component("NoData", require("./components/noData.vue").default);
Vue.component("Alert", require("./components/Alert.vue").default);

Vue.component("Avatar", Avatar);
Vue.component(
  "UpdateAvatar",
  require("./components/Avatar/UpdateAvatar.vue").default
);

Vue.component("UpdateCover", require("./components/UpdateCover.vue").default);

Vue.component("FullEditor", require("./components/FullEditor.vue").default);
Vue.component(
  "UpdateSocialMedias",
  require("./components/UpdateSocialMedias.vue").default
);

Vue.component(
  "IntroVideo",
  require("./components/Video/IntroVideo.vue").default
);
Vue.component(
  "UpdateVideo",
  require("./components/Video/UpdateVideo.vue").default
);

Vue.component("Skills", require("./components/Skills/Skills.vue").default);
Vue.component(
  "AddSkills",
  require("./components/Skills/AddSkills.vue").default
);

Vue.component(
  "Education",
  require("./components/Education/Education.vue").default
);
Vue.component(
  "AddEducations",
  require("./components/Education/AddEducations.vue").default
);

Vue.component(
  "Experiences",
  require("./components/Experiences/Experiences.vue").default
);
Vue.component(
  "AddExperiences",
  require("./components/Experiences/AddExperiences.vue").default
);

Vue.component(
  "Languages",
  require("./components/Languages/Languages.vue").default
);
Vue.component(
  "AddLanguages",
  require("./components/Languages/AddLanguages.vue").default
);

Vue.component(
  "Interrests",
  require("./components/Interests/Interrests.vue").default
);
Vue.component(
  "AddInterrests",
  require("./components/Interests/AddInterrests.vue").default
);

Vue.component(
  "StartupsList",
  require("./components/Startups/StartupsList.vue").default
);
Vue.component(
  "StartupAside",
  require("./components/Startups/StartupAside.vue").default
);
Vue.component(
  "UpdateStartupInfos",
  require("./components/Startups/UpdateStartupInfos.vue").default
);
Vue.component(
  "UpdateStartupAdmin",
  require("./components/Startups/UpdateStartupAdmin.vue").default
);
Vue.component(
  "PeopleSupportStartups",
  require("./components/Startups/PeopleSupportStartups.vue").default
);
Vue.component(
  "FeaturedStartups",
  require("./components/Startups/FeaturedStartups.vue").default
);
Vue.component(
  "WillingToSupport",
  require("./components/Startups/WillingToSupport.vue").default
);
Vue.component(
  "TalentsNeeded",
  require("./components/Startups/TalentsNeeded.vue").default
);
Vue.component(
  "TeamList",
  require("./components/Startups/TeamList.vue").default
);
Vue.component(
  "RelevantTalents",
  require("./components/Startups/RelevantTalents.vue").default
);
Vue.component("JobsList", require("./components/Jobs/JobsList.vue").default);
Vue.component(
  "SideJobOffer",
  require("./components/Jobs/SideJobOffer.vue").default
);

Vue.component("NewsList", require("./components/News/NewsList.vue").default);
Vue.component("SideNews", require("./components/News/SideNews.vue").default);

Vue.component(
  "Connections",
  require("./components/Connections/Connections.vue").default
);

Vue.component(
  "Connections2",
  require("./components/Connections/Connections2.vue").default
);

Vue.component(
  "ConnectionAside",
  require("./components/Connections/ConnectionAside.vue").default
);
Vue.component(
  "RecentConnections",
  require("./components/Connections/RecentConnections.vue").default
);

Vue.component(
  "ProfileWidget",
  require("./components/Profile/ProfileWidget.vue").default
);
Vue.component(
  "ProfileCover",
  require("./components/Profile/ProfileCover.vue").default
);
Vue.component(
  "ProfileDetails",
  require("./components/Profile/ProfileDetails.vue").default
);
Vue.component("ProfileCard", require("./components/Profile/Card.vue").default);

Vue.component("PageCover", require("./components/Page/PageCover.vue").default);
Vue.component(
  "PageProfileWidget",
  require("./components/Page/PageProfileWidget.vue").default
);
Vue.component(
  "SimilarPages",
  require("./components/Page/SimilarPages.vue").default
);

Vue.component("Overview", require("./components/Overview.vue").default);
Vue.component("About", require("./components/About/About.vue").default);

Vue.component(
  "CreateFeed",
  require("./components/Feeds/CreateFeed.vue").default
);
Vue.component("NewsFeed", require("./components/Feeds/NewsFeed.vue").default);
Vue.component("UserFeeds", require("./components/Feeds/UserFeeds.vue").default);

Vue.component("Comment", require("./components/Comments/Comment.vue").default);
Vue.component(
  "AddComment",
  require("./components/Comments/AddComment.vue").default
);

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = "en";
  }
  // set the current language for i18n.
  i18n.locale = language;
  next();
});

const authData = localStorage.getItem("auth");
Vue.filter("formatDateEnglish", function (value) {
  if (value) {
    moment.locale(i18n.locale);
    if (i18n.locale === "fr") {
      return moment(String(value)).format("Do MMMM YYYY");
    } else {
      return moment(String(value)).format("MMMM Do YYYY");
    }
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    moment.locale(i18n.locale);
    if (i18n.locale === "fr") {
      return moment(String(value)).format("DD/MM/YYYY");
    } else {
      return moment(String(value)).format("MM/DD/YYYY");
    }
  }
});

// scrollto library
var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

// // You can also pass in the default options
// Vue.use(VueScrollTo, {
//      container: "body",
//      duration: 500,
//      easing: "ease",
//      offset: 0,
//      force: true,
//      cancelable: true,
//      onStart: false,
//      onDone: false,
//      onCancel: false,
//      x: false,
//      y: true
//     })
//     //end

Vue.filter("striphtml", function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

import toNow from "date-fns/distance_in_words_to_now";
import "./registerServiceWorker";
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en",
  locales: {
    fr: require("date-fns/locale/fr"),
  },
  converter(date, locale, converterOptions) {
    const { includeSeconds, addSuffix = true } = converterOptions;
    return toNow(date, {
      locale,
      includeSeconds,
      addSuffix,
    }).replace("about ", "");
  },
});

Vue.filter("str_limit", function (value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

Vue.directive("sane-html", (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

Vue.prototype.$sanitize = sanitizeHTML;
Vue.filter("striphtml", function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

new Vue({
  router,
  i18n,
  data: {
    auth: authData ? JSON.parse(authData) : {},
  },
  methods: {},
  store,
  render: (h) => h(App),
}).$mount("#app");
