<template>
  <div class="page">
    <div class="">
      <div class="page-title text-center">
        <div class="container">
          <div class="page-title__holder">
            <h1 class="page-title__title">{{ $t("cookiesPolicies") }}</h1>
            <!-- <p class="page-title__subtitle text-center">
              Last Updated: July 28, 2018
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="p-5 shadow-sm box border rounded">
              <div id="intro" class="">
                <p class="text" v-html="text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import store from "../../store/";
import APIURLS from "../../constant";
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Cookies ",
    };
  },
  watch: {
    async $route(to, from) {
      this.getCookies();
    },
  },
  async mounted() {
    this.getCookies();
  },
  data() {
    return {
      text: "",
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["getCookiesPolicy"]),
  },
  methods: {
    ...mapActions(["loadCookiesPolicy"]),
    async getCookies() {
      const field = "cookies";
      const lang = i18n.locale;
      this.loading = true;
      await this.loadCookiesPolicy({ field, lang });
      this.text = this.getCookiesPolicy;
      this.loading = false;
    },
  },

  // eslint-disable-next-line vue/multi-word-component-names
  name: "Cookies",
};
</script>
<style scoped>
.page {
  background-color: #ffffff !important;
}
.page-title {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
  padding: 80px;
  background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #b8eed9;
}
.page-title__holder {
  max-width: 878px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.page-title__title {
  line-height: 1.3;
  margin-bottom: 24px;
  color: #000000 !important;
  font-weight: 700 !important;
  font-size: 30px !important;
}
.text >>> strong {
  color: #333b69 !important;
  font-weight: 600 !important;
  line-height: 1.3 !important;
}

.text >>> p {
  color: #6a798c !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 2 !important;
  text-align: left !important;
}
.page-title__subtitle {
  font-size: 20px;
  line-height: 1.4;
}
.page-title.bg-color-overlay .page-title__title,
.page-title.bg-color-overlay .page-title__subtitle,
.page-title .entry__meta li,
.page-title .entry__meta a {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .page-title {
    padding: 80px 0 130px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title {
    padding: 40px 0;
  }
}
</style>
