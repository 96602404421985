<template>
  <li class="nav-item dropdown mr-2">
    <a
      class="nav-link dropdown-toggle pr-0"
      href="#"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="feather-globe mr-2"></i
      ><span class="d-none d-lg-inline">{{ getLang($i18n.locale) }}</span>
    </a>

    <div class="dropdown-menu dropdown-menu-right shadow-sm showLanguage">
      <div v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`">
        <a
          class="dropdown-item"
          v-if="lang != $i18n.locale"
          :value="lang"
          href="#"
          @click.prevent="setLocale(lang)"
          ><template>
            <!-- <country-flag country="fr" size="small" v-if="lang === 'fr'" />
            <country-flag country="gb" size="small" v-else /> -->
            {{ getLang(lang) }}
          </template>
        </a>
      </div>
    </div>
  </li>
</template>

<script>
import Axios from "axios";
import APPURL from "../constant";
export default {
  name: "locale-changer",
  props: {
    mobile: String,
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      //var name = this.$route.name;
      var originalName = this.$route.name;
      var name = originalName.substring(0, originalName.length - 2);
      var lang = originalName.slice(-2);
      if (lang === "En") {
        name += "Fr";
      } else {
        name += "En";
      }
      locale = this.$i18n.t(locale);
      var request = "";
      var slug = `${this.$route.params.slug}`;
      // this.$router.push({ name: name, params: { lang: locale } }).catch(err => {});
      if (this.$route.path != originalName) {
        this.$router.push({ name: name, params: { lang: locale } }).catch(err => {});
      }
    },
    getLang(locale) {
      //return locale.charAt(0).toUpperCase() + locale.slice(1);
      var value = "navBar." + locale;
      return this.$i18n.t(value);
    },
  },
};
</script>

<style scoped>
.sub-menu {
  width: 120px !important;
}
@media (max-width: 576px) {
  .showLanguage {
    position: absolute;
    z-index: 1000;
    top: -70px;
    border: 2px solid rgba(38, 38, 38, 0.482);
    background-color: #ededed;
    /* background: green; */
    height: 60px !important;

    -moz-box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.187);
    -webkit-box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.187);
    box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.187);
  }
  .dropdown-item {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
