<template>
  <div class="sidebar">
    <button @click="openNewTab(job)" class="btn-green">
      {{ $t("applyNow") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.781"
        height="9.634"
        viewBox="0 0 21.781 9.634"
      >
        <g
          id="Icon_feather-arrow-down"
          data-name="Icon feather-arrow-down"
          transform="translate(5.192 17.995) rotate(-90)"
        >
          <path
            id="Path_33"
            data-name="Path 33"
            d="M18,7.5V26.673"
            transform="translate(-4.822 -11.817)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.75"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M17.134,18l-4.817,4.817L7.5,18"
            transform="translate(0.861 -6.228)"
            fill="#fff"
          />
        </g>
      </svg>
    </button>

    <!-- <div class="sidebar-card">
      <h3>Job Summary</h3>
      <hr />

      <div>
        <div class="summary-icons">
          <div class="summ-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.174"
              height="27.061"
              viewBox="0 0 19.174 27.061"
            >
              <path
                id="Path_35"
                data-name="Path 35"
                d="M883.867,490c.338.043.679.068,1.013.13a9.467,9.467,0,0,1,7.424,6.055,9.031,9.031,0,0,1,.625,3.08,8.115,8.115,0,0,1-.513,2.947,28.06,28.06,0,0,1-2.279,4.856,83.49,83.49,0,0,1-5.589,8.431c-.387.522-.787,1.035-1.188,1.562a.763.763,0,0,1-.078-.074,83.251,83.251,0,0,1-7.119-10.623,26.6,26.6,0,0,1-1.927-4.256,8.552,8.552,0,0,1,.9-7.444,9.341,9.341,0,0,1,6.558-4.517c.313-.059.634-.078.952-.117.065-.008.129-.02.194-.03Zm3.195,9.17a3.715,3.715,0,1,0-3.7,3.709A3.713,3.713,0,0,0,887.062,499.17Z"
                transform="translate(-873.76 -490)"
                fill="#00b074"
              />
            </svg>
          </div>
          <div class="summ-title">
            Location <br />
            London, United Kingdom
          </div>
        </div>
      </div>

      <div>
        <div class="summary-icons">
          <div class="summ-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.657"
              height="24.443"
              viewBox="0 0 29.657 24.443"
            >
              <path
                id="Path_10"
                data-name="Path 10"
                d="M553.161,679.733c0,2.479.005,4.958,0,7.436a2.878,2.878,0,0,1-2.491,2.907,3.171,3.171,0,0,1-.455.026q-3.518,0-7.037,0c-1.564,0-3.128-.02-4.691-.021-1.261,0-2.521.018-3.783.02q-3.845.005-7.689,0a2.875,2.875,0,0,1-3-2.987q-.006-7.38,0-14.759a2.866,2.866,0,0,1,2.957-2.946c2.042,0,4.084-.006,6.126.006.281,0,.354-.084.347-.354a5.77,5.77,0,0,1,.06-1.077,2.431,2.431,0,0,1,2.324-1.807c.989.067,1.988,0,2.982,0,.824,0,1.65-.032,2.472.006a2.3,2.3,0,0,1,2.389,1.8,4.64,4.64,0,0,1,.07,1.046c.011.38,0,.38.393.38h6.012a2.877,2.877,0,0,1,3.019,3.029Q553.162,676.086,553.161,679.733Zm-27.574-3.579c0,.523,0,.973,0,1.423.009.755.034,1.51.035,2.266,0,2.4,0,4.805,0,7.209a1.279,1.279,0,0,0,1.436,1.442q5.194,0,10.389,0h5.039q3.8,0,7.608,0a1.284,1.284,0,0,0,1.447-1.459q.006-3.519.011-7.037c0-1.212-.008-2.424-.013-3.636a1.052,1.052,0,0,0-.036-.178,39.915,39.915,0,0,1-9.335,1.907c0,.254,0,.481,0,.707a1.423,1.423,0,0,1-1.461,1.46q-2.123.012-4.245,0a1.425,1.425,0,0,1-1.47-1.481c0-.225,0-.451,0-.686A39.412,39.412,0,0,1,525.586,676.154Zm16.711.285c.088-.005.257-.008.424-.027.594-.067,1.189-.129,1.781-.212a33.138,33.138,0,0,0,6.794-1.647c.174-.064.255-.138.25-.34-.014-.626,0-1.253-.005-1.88a1.253,1.253,0,0,0-1.333-1.315q-11.623,0-23.247,0a1.253,1.253,0,0,0-1.337,1.339c0,.608.01,1.216-.005,1.823a.339.339,0,0,0,.273.382,34.354,34.354,0,0,0,8.237,1.815c.277.029.556.048.844.073.01-.124.016-.2.021-.273a1.421,1.421,0,0,1,1.38-1.337c1.472-.014,2.944-.009,4.416,0a1.188,1.188,0,0,1,1.047.538A7.632,7.632,0,0,1,542.3,676.439Zm-3.716-7.03H541.4c.733,0,.733,0,.73-.724,0-.642-.254-.9-.89-.9-1.287-.007-2.575-.014-3.863-.016-.548,0-1.1-.016-1.643.025a.715.715,0,0,0-.7.831,4.1,4.1,0,0,1,0,.483c-.023.233.058.311.3.309C536.418,669.4,537.5,669.41,538.581,669.41Zm-1.98,9.243c1.3,0,2.546,0,3.8-.008.057,0,.159-.123.161-.191.013-.6.005-1.2.01-1.793,0-.156-.052-.222-.214-.221-1.186,0-2.372,0-3.558.01-.066,0-.187.109-.188.168C536.6,677.279,536.6,677.942,536.6,678.653Z"
                transform="translate(-523.756 -665.911)"
                fill="#00b074"
                stroke="#00b074"
                stroke-width="0.5"
              />
            </svg>
          </div>
          <div class="summ-title">
            Job Type <br />
            Full Time
          </div>
        </div>
      </div>

      <div>
        <div class="summary-icons">
          <div class="summ-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="21"
              viewBox="0 0 28 21"
            >
              <g
                id="Group_195"
                data-name="Group 195"
                transform="translate(-1300 -1014)"
              >
                <g
                  id="Group_4"
                  data-name="Group 4"
                  transform="translate(88.799 268)"
                >
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M1224.409,751.567c0-.345,0-.681,0-1.016a.515.515,0,0,1,.4-.527.573.573,0,0,1,.652.235.633.633,0,0,1,.073.281c.009.315.005.631,0,.946,0,.071.023.091.092.1a3.966,3.966,0,0,1,2.474,1.3,2.515,2.515,0,0,1,.636,1.621.732.732,0,0,1-.284.612,1.176,1.176,0,0,1-1.438,0,.743.743,0,0,1-.282-.578,1.269,1.269,0,0,0-.561-1,1.655,1.655,0,0,0-.635-.3v.086q0,1.454,0,2.909a.128.128,0,0,0,.09.135c.482.221.968.435,1.442.674a3.256,3.256,0,0,1,1.1.867,2.461,2.461,0,0,1,.583,1.6,2.363,2.363,0,0,1-.669,1.594,3.941,3.941,0,0,1-2.124,1.179c-.136.03-.274.051-.419.077v.1q0,.465,0,.929a.565.565,0,0,1-1.128.006c0-.318,0-.637,0-.955,0-.067-.014-.093-.087-.1a4.061,4.061,0,0,1-2.279-1.083,2.567,2.567,0,0,1-.828-1.736.807.807,0,0,1,.637-.9,1.125,1.125,0,0,1,1.122.231.653.653,0,0,1,.236.492,1.256,1.256,0,0,0,.487.986,1.864,1.864,0,0,0,.71.368v-.094q0-1.424,0-2.847a.127.127,0,0,0-.081-.133,14.954,14.954,0,0,1-2.151-1.309c-.044-.033-.086-.07-.13-.1a2.242,2.242,0,0,1-.472-3,3.6,3.6,0,0,1,1.667-1.326A4.406,4.406,0,0,1,1224.409,751.567Zm1.133,9.135a1.7,1.7,0,0,0,1.05-.75.908.908,0,0,0,.079-.848,1.729,1.729,0,0,0-.27-.435,1.973,1.973,0,0,0-.86-.488Zm-1.136-7.488a1.707,1.707,0,0,0-1.022.7.965.965,0,0,0,.2,1.319,8.365,8.365,0,0,0,.711.475c.033.022.07.038.113.061Z"
                    transform="translate(0 0)"
                    fill="#00b074"
                  />
                </g>
                <g
                  id="Rectangle_34"
                  data-name="Rectangle 34"
                  transform="translate(1300 1014)"
                  fill="none"
                  stroke="#00b074"
                  stroke-width="1.5"
                >
                  <rect width="28" height="21" rx="3" stroke="none" />
                  <rect
                    x="0.75"
                    y="0.75"
                    width="26.5"
                    height="19.5"
                    rx="2.25"
                    fill="none"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="summ-title">
            Salary <br />
            $40k - $50k
          </div>
        </div>
      </div>

      <div>
        <div class="summary-icons">
          <div class="summ-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.995"
              height="28.103"
              viewBox="0 0 29.995 28.103"
            >
              <g
                id="Group_48"
                data-name="Group 48"
                transform="translate(-562.27 -1124)"
              >
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M565.911,1132c0,.6,0,1.176,0,1.754a.5.5,0,0,0,.571.572h1.234a.488.488,0,0,0,.555-.551q0-.791,0-1.583V1132h6.072v.173c0,.533,0,1.066,0,1.6a.491.491,0,0,0,.542.551q.641,0,1.283,0a.484.484,0,0,0,.535-.539c0-.588,0-1.177,0-1.78h6.072v.17c0,.539,0,1.078,0,1.617a.481.481,0,0,0,.526.531q.658.005,1.317,0a.482.482,0,0,0,.517-.524c0-.594,0-1.188,0-1.8.056,0,.1-.012.151-.012.406,0,.811,0,1.217,0a2.038,2.038,0,0,1,2.012,1.94c0,.094,0,.189,0,.283q0,4.033,0,8.067a.368.368,0,0,0,.171.34,8.318,8.318,0,0,1,3.575,7.3,8.49,8.49,0,0,1-16.387,2.742.276.276,0,0,0-.312-.2q-5.592.007-11.184,0a2.051,2.051,0,0,1-2.054-1.594,1.965,1.965,0,0,1-.05-.445q0-8.2,0-16.4a2.038,2.038,0,0,1,2.009-2.028c.5,0,1,0,1.5,0C565.818,1131.992,565.856,1132,565.911,1132Zm17.841,24.522a6.969,6.969,0,1,0-6.94-6.724A6.978,6.978,0,0,0,583.753,1156.521Zm2.578-15.113a.694.694,0,0,0,.005-.071q0-1.392,0-2.783a1.153,1.153,0,0,0-.013-.149,1.348,1.348,0,0,0-1.409-1.128q-9.525.014-19.051.005a1.318,1.318,0,0,0-1.414,1.417q0,1.834,0,3.667,0,3.3,0,6.6a1.316,1.316,0,0,0,1.39,1.392h9.451v-1.286c-.306,0-.593,0-.88,0a.52.52,0,0,1-.535-.539c0-.211,0-.422,0-.633a.538.538,0,0,1,.587-.593c.333,0,.667,0,1,0a.131.131,0,0,0,.151-.112c.082-.265.178-.525.267-.788a.633.633,0,0,0,.017-.1c-.5,0-.979,0-1.463,0a.523.523,0,0,1-.559-.565c0-.189,0-.378,0-.567,0-.434.2-.633.63-.634.55,0,1.1,0,1.65,0a.788.788,0,0,1,.614.145c.334-.383.651-.754.976-1.117.121-.135.3-.238.155-.462a.194.194,0,0,1-.01-.1q0-.317,0-.633a.527.527,0,0,1,.583-.59c.555,0,1.111,0,1.667,0a.983.983,0,0,0,.352-.073,8.432,8.432,0,0,1,4.357-.584C585.34,1141.193,585.826,1141.312,586.331,1141.408Z"
                  transform="translate(0 -5.945)"
                  fill="#00b074"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M612.493,1126c0,.484,0,.967,0,1.45a.487.487,0,0,1-.539.535q-.258,0-.517,0a.487.487,0,0,1-.533-.542c0-.756,0-1.512,0-2.267,0-.211,0-.422,0-.633a.482.482,0,0,1,.524-.533c.189,0,.378,0,.567,0a.472.472,0,0,1,.5.506C612.5,1125.009,612.493,1125.5,612.493,1126Z"
                  transform="translate(-36.188 -0.004)"
                  fill="#00b074"
                />
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M579.551,1125.995c0,.484,0,.967,0,1.45a.484.484,0,0,1-.539.534c-.178,0-.355,0-.533,0a.47.47,0,0,1-.511-.493q-.008-1.5,0-3a.46.46,0,0,1,.486-.481q.308-.01.617,0a.466.466,0,0,1,.478.49C579.554,1125,579.55,1125.5,579.551,1125.995Z"
                  transform="translate(-11.677 0)"
                  fill="#00b074"
                />
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M645.438,1126c0,.477,0,.954,0,1.431a.49.49,0,0,1-.543.546c-.183,0-.366,0-.549,0a.473.473,0,0,1-.5-.492q-.007-1.5,0-3a.468.468,0,0,1,.484-.485c.2-.007.411-.006.616,0a.471.471,0,0,1,.488.5C645.441,1125,645.438,1125.5,645.438,1126Z"
                  transform="translate(-60.702)"
                  fill="#00b074"
                />
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M643.4,1196.707a1.465,1.465,0,0,1-2.93.012,1.465,1.465,0,1,1,2.93-.012Z"
                  transform="translate(-58.186 -53.011)"
                  fill="#00b074"
                />
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M631.684,1188.693a1.648,1.648,0,0,1-.339-.184q-.991-.889-1.969-1.792a.517.517,0,0,1-.037-.768.51.51,0,0,1,.755-.031q.993.9,1.978,1.806a.488.488,0,0,1,.141.573A.65.65,0,0,1,631.684,1188.693Z"
                  transform="translate(-49.792 -45.961)"
                  fill="#00b074"
                />
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M651.339,1187.388a.862.862,0,0,1,.163-.232q1.529-1.221,3.069-2.428a.367.367,0,0,1,.545.054.361.361,0,0,1-.058.544q-1.545,1.227-3.1,2.448a.344.344,0,0,1-.391.039A.444.444,0,0,1,651.339,1187.388Z"
                  transform="translate(-66.277 -45.116)"
                  fill="#00b074"
                />
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M609.146,1159.482c.316,0,.633,0,.949,0a.53.53,0,0,1,.575.567c0,.217,0,.433,0,.649a.529.529,0,0,1-.546.549q-.982.008-1.964,0a.527.527,0,0,1-.548-.545c0-.228,0-.455,0-.682a.524.524,0,0,1,.536-.538h1Z"
                  transform="translate(-33.737 -26.402)"
                  fill="#00b074"
                />
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M624.812,1159.48c.322,0,.644,0,.965,0a.52.52,0,0,1,.555.551c0,.222,0,.444,0,.666a.521.521,0,0,1-.527.548q-1,.011-2,0a.51.51,0,0,1-.527-.5,5.816,5.816,0,0,1,0-.781.5.5,0,0,1,.531-.487C624.147,1159.477,624.479,1159.48,624.812,1159.48Z"
                  transform="translate(-45.39 -26.4)"
                  fill="#00b074"
                />
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M609.161,1170.28c.322,0,.644,0,.965,0a.523.523,0,0,1,.546.545q.006.333,0,.666a.521.521,0,0,1-.526.55q-1.006.017-2.013,0a.518.518,0,0,1-.519-.555q0-.325,0-.649a.526.526,0,0,1,.55-.557h1Z"
                  transform="translate(-33.739 -34.437)"
                  fill="#00b074"
                />
                <path
                  id="Path_23"
                  data-name="Path 23"
                  d="M577.751,1193.613c-.316,0-.632,0-.949,0a.529.529,0,0,1-.569-.557q-.007-.324,0-.649a.535.535,0,0,1,.57-.56q.965,0,1.931,0a.528.528,0,0,1,.558.552c0,.228,0,.455,0,.682a.516.516,0,0,1-.525.53c-.338.006-.677,0-1.015,0Z"
                  transform="translate(-10.387 -50.484)"
                  fill="#00b074"
                />
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M577.763,1159.48c.322,0,.644,0,.965,0a.523.523,0,0,1,.558.565c0,.216,0,.433,0,.649a.525.525,0,0,1-.541.551q-.99.008-1.981,0a.524.524,0,0,1-.535-.526c-.009-.238-.009-.477,0-.716a.519.519,0,0,1,.534-.522C577.1,1159.476,577.43,1159.48,577.763,1159.48Z"
                  transform="translate(-10.382 -26.4)"
                  fill="#00b074"
                />
                <path
                  id="Path_25"
                  data-name="Path 25"
                  d="M577.774,1182.808c-.327,0-.654,0-.982,0a.525.525,0,0,1-.56-.547q-.008-.333,0-.666a.521.521,0,0,1,.529-.548q1-.016,2,0a.522.522,0,0,1,.532.56c0,.222,0,.444,0,.666a.518.518,0,0,1-.534.537c-.327,0-.655,0-.982,0Z"
                  transform="translate(-10.386 -42.443)"
                  fill="#00b074"
                />
                <path
                  id="Path_26"
                  data-name="Path 26"
                  d="M640.513,1159.482c.322,0,.643,0,.965,0a.52.52,0,0,1,.556.549q.006.333,0,.666a.525.525,0,0,1-.559.55q-.965.005-1.93,0a.527.527,0,0,1-.564-.562c0-.222,0-.444,0-.666a.519.519,0,0,1,.533-.538h1Z"
                  transform="translate(-57.08 -26.401)"
                  fill="#00b074"
                />
                <path
                  id="Path_27"
                  data-name="Path 27"
                  d="M577.774,1172.046c-.31,0-.621,0-.932,0a.548.548,0,0,1-.605-.61c0-.194,0-.388,0-.582a.529.529,0,0,1,.569-.573q.965,0,1.93,0a.52.52,0,0,1,.559.562q0,.316,0,.632a.528.528,0,0,1-.557.57C578.417,1172.052,578.1,1172.046,577.774,1172.046Z"
                  transform="translate(-10.392 -34.436)"
                  fill="#00b074"
                />
                <path
                  id="Path_28"
                  data-name="Path 28"
                  d="M593.469,1170.28c.322,0,.643,0,.965,0a.519.519,0,0,1,.559.561q0,.317,0,.632a.533.533,0,0,1-.587.573c-.438,0-.876,0-1.314,0-.194,0-.388,0-.582,0a.53.53,0,0,1-.567-.575c0-.216,0-.433,0-.649a.516.516,0,0,1,.545-.542h.982Z"
                  transform="translate(-22.078 -34.436)"
                  fill="#00b074"
                />
                <path
                  id="Path_29"
                  data-name="Path 29"
                  d="M593.474,1159.481c.322,0,.643,0,.965,0a.518.518,0,0,1,.55.554c0,.222,0,.444,0,.666a.516.516,0,0,1-.528.544q-1,.01-2,0a.515.515,0,0,1-.525-.531c0-.239-.005-.477,0-.715a.51.51,0,0,1,.52-.516c.338-.006.677,0,1.015,0Z"
                  transform="translate(-22.074 -26.401)"
                  fill="#00b074"
                />
                <path
                  id="Path_30"
                  data-name="Path 30"
                  d="M593.477,1191.848c.316,0,.633,0,.949,0a.526.526,0,0,1,.564.56q0,.325,0,.649a.516.516,0,0,1-.548.556q-.982,0-1.964,0a.512.512,0,0,1-.539-.531c0-.233,0-.466,0-.7a.525.525,0,0,1,.539-.535h1Z"
                  transform="translate(-22.075 -50.485)"
                  fill="#00b074"
                />
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M593.459,1182.809c-.322,0-.643,0-.965,0a.516.516,0,0,1-.553-.55q0-.333,0-.666a.516.516,0,0,1,.526-.547q1-.014,2,0a.514.514,0,0,1,.528.545c0,.222,0,.444,0,.666a.516.516,0,0,1-.551.552C594.114,1182.811,593.787,1182.809,593.459,1182.809Z"
                  transform="translate(-22.077 -42.443)"
                  fill="#00b074"
                />
              </g>
            </svg>
          </div>
          <div class="summ-title">
            Date Posted <br />
            3 days ago
          </div>
        </div>
      </div>
    </div> -->
    <div class="sidebar-card">
      <h3>{{ $t("Job Location") }}</h3>
      <hr />
      <div class="map-loc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.534"
          height="69.488"
          viewBox="0 0 25.534 69.488"
        >
          <g
            id="Group_195"
            data-name="Group 195"
            transform="translate(-53.52 -377.066)"
          >
            <circle
              id="Ellipse_6"
              data-name="Ellipse 6"
              cx="11.5"
              cy="11.5"
              r="11.5"
              transform="translate(54.554 423.554)"
              fill="#00b074"
              opacity="0.2"
            />
            <g
              id="Ellipse_7"
              data-name="Ellipse 7"
              transform="translate(60.554 429.554)"
              fill="#00b074"
              stroke="#fff"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
          </g>
          <path
            id="Path_54"
            data-name="Path 54"
            d="M1434.534,853.767a12.767,12.767,0,1,0-14.75,12.612l1.983,34.109,1.983-34.109A12.787,12.787,0,0,0,1434.534,853.767Zm-12.767,10.594a10.594,10.594,0,1,1,10.594-10.594A10.606,10.606,0,0,1,1421.767,864.36Z"
            transform="translate(-1409 -841)"
            fill="#00b074"
          />
        </svg>

        <div class="location">
          {{ fortmatCountry(job.country) }}
        </div>
      </div>
      <!-- <img class="map" alt="map" src="../../assets/images/map.png" /> -->
    </div>
    <div class="sidebar-card">
      <h3>{{ $t("toutesLesOffres") }}</h3>
      <hr />
      <div class="all-jobs">
        <a
          class="btn-green"
          :href="`/${$i18n.locale}/${this.$i18n.t('routes.jobs')}`"
        >
          {{ $t("view") }}

          <svg
            data-v-537bd172=""
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="24"
            viewBox="0 0 36 24"
          >
            <path
              data-v-537bd172=""
              id="FontAwsome_eye_"
              data-name="FontAwsome (eye)"
              d="M18,69a6.934,6.934,0,0,0-1.952.313A3.463,3.463,0,0,1,16.5,71,3.5,3.5,0,0,1,13,74.5a3.462,3.462,0,0,1-1.687-.452A6.982,6.982,0,1,0,18,69Zm17.783,6.088A20.046,20.046,0,0,0,18,64,20.049,20.049,0,0,0,.218,75.088a2.022,2.022,0,0,0,0,1.824A20.046,20.046,0,0,0,18,88,20.049,20.049,0,0,0,35.783,76.912a2.022,2.022,0,0,0,0-1.824ZM18,85A17,17,0,0,1,3.13,76,17,17,0,0,1,18,67a17,17,0,0,1,14.871,9A17,17,0,0,1,18,85Z"
              transform="translate(-0.001 -64)"
              fill="#8c8c8c"
            ></path>
          </svg>
        </a>
      </div>
    </div>
    <div class="sidebar-card last-card">
      <h3>{{ $t("Share This Job") }}</h3>
      <hr />
      <button
        class="btn-green copy-link"
        v-clipboard="job.link"
        v-clipboard:success="clipboardSuccess"
        v-clipboard:error="clipboardError"
      >
        {{ $t("Copy Link") }}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.5"
          height="36"
          viewBox="0 0 31.5 36"
        >
          <path
            id="Icon_awesome-copy"
            data-name="Icon awesome-copy"
            d="M22.5,31.5v2.813A1.687,1.687,0,0,1,20.813,36H1.688A1.687,1.687,0,0,1,0,34.313V8.438A1.687,1.687,0,0,1,1.688,6.75H6.75V27.563A3.942,3.942,0,0,0,10.688,31.5Zm0-24.187V0H10.688A1.687,1.687,0,0,0,9,1.688V27.563a1.687,1.687,0,0,0,1.688,1.688H29.813A1.687,1.687,0,0,0,31.5,27.563V9H24.188A1.692,1.692,0,0,1,22.5,7.313Zm8.506-2.182L26.369.494A1.688,1.688,0,0,0,25.176,0H24.75V6.75H31.5V6.324a1.688,1.688,0,0,0-.494-1.193Z"
          />
        </svg>
      </button>

      <!-- <div class="social-icons">
        <div class="social-icon">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.363"
              height="39.888"
              viewBox="0 0 21.363 39.888"
            >
              <path
                id="Icon_awesome-facebook-f"
                data-name="Icon awesome-facebook-f"
                d="M21.573,22.437l1.108-7.219H15.754V10.534c0-1.975.968-3.9,4.07-3.9h3.149V.488A38.4,38.4,0,0,0,17.383,0C11.679,0,7.95,3.457,7.95,9.716v5.5H1.609v7.219H7.95V39.888h7.8V22.437Z"
                transform="translate(-1.609)"
                fill="#405a93"
              />
            </svg>
          </a>
        </div>

        <div class="social-icon">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37.38"
              height="30.36"
              viewBox="0 0 37.38 30.36"
            >
              <path
                id="Icon_awesome-twitter"
                data-name="Icon awesome-twitter"
                d="M33.538,10.947c.024.332.024.664.024,1,0,10.128-7.708,21.8-21.8,21.8A21.65,21.65,0,0,1,0,30.3a15.849,15.849,0,0,0,1.85.095,15.343,15.343,0,0,0,9.511-3.273A7.675,7.675,0,0,1,4.2,21.81a9.661,9.661,0,0,0,1.447.119,8.1,8.1,0,0,0,2.016-.261,7.662,7.662,0,0,1-6.143-7.519v-.095a7.716,7.716,0,0,0,3.463.972A7.673,7.673,0,0,1,2.609,4.78a21.776,21.776,0,0,0,15.8,8.017,8.648,8.648,0,0,1-.19-1.755A7.668,7.668,0,0,1,31.475,5.8a15.083,15.083,0,0,0,4.862-1.85,7.64,7.64,0,0,1-3.368,4.222A15.358,15.358,0,0,0,37.38,6.986a16.468,16.468,0,0,1-3.842,3.961Z"
                transform="translate(0 -3.381)"
                fill="#6aabe8"
              />
            </svg>
          </a>
        </div>

        <div class="social-icon">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41.659"
              height="26.037"
              viewBox="0 0 41.659 26.037"
            >
              <path
                id="Icon_awesome-google-plus-g"
                data-name="Icon awesome-google-plus-g"
                d="M26.209,15.654a11.043,11.043,0,0,1,.213,2.167c0,7.442-4.991,12.717-12.5,12.717a13.019,13.019,0,0,1,0-26.037,12.435,12.435,0,0,1,8.72,3.41L19.1,11.3A7.31,7.31,0,0,0,13.919,9.3a8.225,8.225,0,0,0,0,16.446c5.151,0,7.086-3.712,7.388-5.612H13.919V15.654h12.29Zm12.571.436V12.311h-3.8V16.09H31.2v3.8h3.779v3.779h3.8V19.887h3.779v-3.8H38.78Z"
                transform="translate(-0.9 -4.5)"
                fill="#cd5542"
              />
            </svg>
          </a>
        </div>

        <div class="social-icon">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="31.687"
              height="31.68"
              viewBox="0 0 31.687 31.68"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#fdca23" />
                  <stop offset="0.464" stop-color="#ea011d" />
                  <stop offset="1" stop-color="#c2008b" />
                </linearGradient>
              </defs>
              <path
                id="Icon_awesome-instagram"
                data-name="Icon awesome-instagram"
                d="M15.842,9.955a8.122,8.122,0,1,0,8.122,8.122A8.11,8.11,0,0,0,15.842,9.955Zm0,13.4a5.281,5.281,0,1,1,5.281-5.281,5.29,5.29,0,0,1-5.281,5.281ZM26.191,9.623A1.895,1.895,0,1,1,24.3,7.729,1.89,1.89,0,0,1,26.191,9.623Zm5.38,1.923a9.375,9.375,0,0,0-2.559-6.638,9.437,9.437,0,0,0-6.638-2.559C19.758,2.2,11.919,2.2,9.3,2.349A9.424,9.424,0,0,0,2.665,4.9,9.406,9.406,0,0,0,.106,11.539c-.148,2.616-.148,10.455,0,13.071a9.375,9.375,0,0,0,2.559,6.638A9.449,9.449,0,0,0,9.3,33.806c2.616.148,10.455.148,13.071,0a9.375,9.375,0,0,0,6.638-2.559,9.437,9.437,0,0,0,2.559-6.638c.148-2.616.148-10.448,0-13.064Zm-3.379,15.87a5.346,5.346,0,0,1-3.011,3.011c-2.085.827-7.034.636-9.338.636s-7.26.184-9.338-.636a5.346,5.346,0,0,1-3.011-3.011c-.827-2.085-.636-7.034-.636-9.338s-.184-7.26.636-9.338A5.346,5.346,0,0,1,6.5,5.728c2.085-.827,7.034-.636,9.338-.636s7.26-.184,9.338.636a5.346,5.346,0,0,1,3.011,3.011c.827,2.085.636,7.034.636,9.338S29.019,25.338,28.192,27.416Z"
                transform="translate(0.005 -2.238)"
                fill="url(#linear-gradient)"
              />
            </svg>
          </a>
        </div>

        <div class="social-icon">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30.252"
              height="30.252"
              viewBox="0 0 30.252 30.252"
            >
              <path
                id="Icon_awesome-linkedin-in"
                data-name="Icon awesome-linkedin-in"
                d="M6.772,30.253H.5v-20.2H6.772ZM3.632,7.3A3.65,3.65,0,1,1,7.265,3.633,3.663,3.663,0,0,1,3.632,7.3ZM30.246,30.253H23.987V20.42c0-2.343-.047-5.348-3.261-5.348-3.261,0-3.761,2.546-3.761,5.179v10H10.7v-20.2h6.015V12.81H16.8a6.59,6.59,0,0,1,5.934-3.262c6.348,0,7.514,4.18,7.514,9.609V30.253Z"
                transform="translate(0 -0.001)"
                fill="#327ab0"
              />
            </svg>
          </a>
        </div>

        <div class="social-icon">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34.456"
              height="21.601"
              viewBox="0 0 34.456 21.601"
            >
              <path
                id="Icon_awesome-behance"
                data-name="Icon awesome-behance"
                d="M13.878,14.98a4.54,4.54,0,0,0,2.9-4.427C16.773,6.33,13.627,5.3,10,5.3H0V26.5H10.277c3.852,0,7.471-1.848,7.471-6.155a5.108,5.108,0,0,0-3.87-5.366ZM4.66,8.921H9.033c1.681,0,3.194.473,3.194,2.423,0,1.8-1.178,2.524-2.841,2.524H4.66ZM9.643,22.9H4.66V17.062H9.738c2.052,0,3.35.855,3.35,3.027,0,2.142-1.549,2.811-3.446,2.811ZM31.088,8.5h-8.6V6.414h8.6V8.5Zm3.368,10.546c0-4.54-2.656-8.327-7.471-8.327-4.678,0-7.854,3.517-7.854,8.123,0,4.78,3.009,8.058,7.854,8.058,3.667,0,6.042-1.651,7.184-5.162H30.448c-.4,1.31-2.052,2-3.332,2-2.471,0-3.769-1.448-3.769-3.906H34.42C34.438,19.586,34.456,19.317,34.456,19.048Zm-11.1-1.866a3.3,3.3,0,0,1,3.5-3.278c2.118,0,3.182,1.244,3.362,3.278Z"
                transform="translate(0 -5.302)"
                fill="#246bf6"
              />
            </svg>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import moment from "moment";
export default {
  name: "JobCard",
  methods: {
    openNewTab(job) {
      window.open(job.link, "_blank");
    },
    clipboardSuccess({ value, event }) {
      this.$snotify.success("", "Success");
    },

    clipboardError({ value, event }) {
      this.$snotify.error(this.$i18n.t("bioUpdated"), "Success");
    },
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date).format("LL");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  props: {
    job: null,
  },
};
</script>
<style scoped>
.main-content .sidebar {
  width: 25%;
  float: right;
}

.btn-green {
  background: #cfead9;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--first-color);
  font-weight: bold;
}
.btn-green path {
  fill: var(--first-color) !important;
  stroke: var(--first-color) !important;
}

.last-card {
  margin-bottom: 70px !important;
  padding-bottom: 20px !important;
}

.copy-link svg {
  fill: var(--first-color) !important;
  stroke: var(--first-color) !important;
}
.btn-green:hover {
  background: var(--first-color);
  border: none;
  color: white;
  fill: white !important;
  stroke: white !important;
}

.btn-green:hover,
.btn-green:hover path {
  background: var(--first-color);
  border: none;
  color: white;
  fill: white !important;
  stroke: white !important;
}

.main-content .sidebar .sidebar-card {
  margin-top: 15px;
  border-radius: 10px;
  border: 2px solid #e8e8e8;
  padding: 20px;
  background: white !important;
}

.sidebar-card h3 {
  font-weight: bold;
  text-align: left;
  font-size: 22px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
}

.sidebar-card .map {
  width: 100%;
  margin-top: 20px;
}

.map-loc {
  background: url("../../assets/images/map.png");
  position: relative;
  z-index: 1000 !important;
  width: 100% !important;
  height: 150px !important;
  margin-left: 10px 20px;
  border-radius: 20px;
}

.sidebar-card a {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
  z-index: 100000;
  top: 35%;
  margin-left: 20px;
  margin-right: 20px;
}

.all-jobs {
  background: url("../../assets/images/jobs.jpeg");
  position: relative;
  z-index: 1000 !important;
  width: 100% !important;
  height: 150px !important;
  margin-left: 10px 20px;
  border-radius: 20px;
}

.map-loc svg {
  width: 40px !important;
  height: 40px !important;
  fill: red !important;
  position: relative;
  z-index: 1000 !important;
}

.location {
  position: relative;
  background-color: white !important;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
  z-index: 100000;
  top: 0%;
  margin-left: 20px;
  margin-right: 20px;
}
.main-content .sidebar .summary-icons {
  display: inline-block;
  margin-bottom: 20px;
  text-align: left;
}
.main-content .sidebar .summary-icons svg {
  width: 15px;
}
.main-content .sidebar .summ-icon {
  float: left;
}
.main-content .sidebar .summ-title {
  float: right;
  padding-left: 20px;
  font-weight: normal;
}

.social-icons {
  display: inline;
  width: 100%;
  max-width: 100%;
  padding-left: 25px;
}

.social-icons .social-icon {
  box-shadow: 1px 1px 5px 1px #a7cbf7;
  padding: 20px 0px 15px 0px;
  margin-top: 0px !important;
  height: 50px;
  width: 25%;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  float: left;
}

@media (max-width: 770px) {
  body section {
    padding: 30px;
  }
  .card {
    padding: 60px 20px 40px 20px !important;
  }
  .card .badge {
    right: 12px;
    top: 15px;
  }
  .badge {
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
  }
  .card-footer-btn {
    width: 100%;
    border-radius: 10px;
  }
  .cards-grid {
    grid-gap: 2rem;
  }

  .searchform input {
    width: 50%;
  }
  .searchform button {
    width: 25%;
  }
  .top-banner {
    display: none;
  }

  .top-banner-mobile .card-icon .circle {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .top-banner-mobile .card-icon .circle .c2 {
    width: 70px;
    height: 70px;
    margin: 0 auto;
  }
  .top-banner-mobile .card-icon .circle img {
    width: 30px !important;
  }

  .proj-head-details-mobile .details-card-mobile {
    width: 70%;
    margin-top: 20px;
    padding: 5px 25px 25px 55px;
    display: inline-block;
  }
  .proj-details-mobile .proj-head-details-mobile {
    background: #f5f6f7 !important;
  }
  .proj-details-mobile .proj-head-details-mobile h4 {
    color: #687486;
  }
  .proj-details-mobile .proj-head-details-mobile h4:nth-child(2) {
    margin-top: 15px;
  }
  .proj-details-mobile .proj-head-details-mobile h4:nth-child(3) {
    margin-top: 15px;
  }
  .top-banner-mobile .proj-title {
    margin-top: 10px !important;
    padding: 0px !important;
    width: 100%;
  }
  .top-banner-mobile,
  .proj-details-mobile {
    display: block !important;
  }

  .main-content,
  .more-jobs {
    padding: 0px 30px 0px 30px !important;
    display: block !important;
  }
  .more-jobs {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .main-content .description {
    width: 100% !important;
    display: block !important;
  }
  .main-content .description .contact-det .info h3 {
    font-size: 12px;
  }
  .main-content .description .contact-det {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .main-content .description .contact-det .info {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .main-content .description .contact-det .info h3 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .main-content .description .contact-det .info:nth-child(1) svg {
    height: 20px !important;
    margin-top: -4px !important;
  }
  .main-content .description .contact-det .info:nth-child(2) svg {
    height: 15px !important;
    margin-top: -0px !important;
  }

  .main-content .sidebar {
    width: 100% !important;
    display: block !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .more-jobs .card-more-jobs .card-right {
    width: 70%;
  }
}
</style>
