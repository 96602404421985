<template>
  <div class="add-member-card">
    <div class="m-profile" v-if="showForm">
      <UpdateAvatar :profileAavatar="image" :is_team="true" />
    </div>
    <div class="member-card-details">
      <div class="name-position-row">
        <div class="input-col">
          <label id="nameLabel">
            {{ $t("name") }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            name="name"
            v-model="member.name"
            :placeholder="$t('name')"
            required
          />
        </div>
        <div class="input-col">
          <label>
            Position
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            name="position"
            v-model="member.position"
            placeholder="Position"
            required
          />
        </div>
      </div>
      <label>
        Description
        <span class="text-danger">*</span>
      </label>
      <textarea
        contenteditable="true"
        placeholder="Description"
        v-model="member.description"
        class="m-discription"
        required
      ></textarea>
      <div class="upload-button-container">
        <button class="save-link" @click="saveMember">{{ $t("save") }}</button>
        <button class="cancel-link" @click="cancelMember">
          {{ $t("cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSelect from "./CustomSelect.vue";
import i18n from "../../../../i18n";
import { mapGetters, mapActions } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  mounted() {
    if (this.isEditMember) {
      this.member = this.memberToEdit;
      this.image = this.memberToEdit.image;
    } else {
      this.member = {
        name: "",
        position: "",
        description: "",
      };
      this.image = "@assets/images/startups/camera.svg";
    }
  },
  name: "AddTeamMemberModal",
  components: {
    //CustomSelect,
    // SaveClose
  },
  data() {
    return {
      showForm: true,
      image: "",
      member: {},
      showPreview: false,
      cropImg: "",
      buttonChoice: "select",
      file: "",
      fileContents: null,
      cloudName: "bantaba",
      preset: "bantaba",
      formData: null,
      loading: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
    isEditMember: Boolean,
    memberToEdit: Object,
    isEditMode: Boolean,
    index: Number,
    startup_id: Number,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartup",
      "getStartupTeam",
      "getUserCover",
      "getStartupEditingMode",
      "getTeamTempImage",
      "getTeamTempImgStatus",
    ]),
  },
  methods: {
    ...mapActions(["loadStartupTeam"]),
    onFileChange(e) {
      this.showPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.image = URL.createObjectURL(file);
      this.buttonChoice = "upload";
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropImg);
    },
    emitDeleteMember(index) {
      this.$emit("emitDelete", index);
    },
    async saveMember() {
      if (this.isEditMember) {
        await this.editMember();
      } else {
        await this.createMember();
      }
    },
    async createMember() {
      if (this.getTeamTempImage.image) {
        this.member.startup_id = this.startup_id;
        this.member.image = this.getTeamTempImage.image;
        this.$store
          .dispatch("createTeamMember", { member: this.member })
          .then(async (success) => {
            this.member = "";
            this.$store.commit("setTeamTempImage", { image: "" });
            this.$emit("cancelMember");
            await this.loadStartupTeam({ user_id: this.authUser.id });
            this.$snotify.success(this.$i18n.t("success"), "Success");
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      } else {
        this.$noty.error(
          this.$i18n.t("Please upload the team member image first!")
        );
      }
    },
    async editMember() {
      if (this.getTeamTempImage.image) {
        this.member.image = this.getTeamTempImage.image;
      }
      this.$store
        .dispatch("updateTeamMember", { member: this.member })
        .then(async (success) => {
          this.member = "";
          this.$store.commit("setTeamTempImage", { image: "" });
          this.$emit("cancelMember");
          this.$snotify.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },

    cancelMember() {
      this.$emit("cancelMember");
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.add-member-card {
  /* box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(44, 43, 42, 0.05), 0px -2px 4px rgba(44, 43, 42, 0.05);
      border-radius: 24px; */
  /* background-color: red; */
  background-color: #f5f7f9;
  background-color: #ffffff;
  backdrop-filter: blur(20%);
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  height: 202px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;
  position: absolute;
  z-index: 100;
  /* height: 400px; */
  height: auto;
  align-items: flex-start;
}
.member-card:hover {
  background-color: #57b36629;
  color: white;
}
.member-card .m-name,
.m-discription,
.m-position {
  color: black;
}

.m-profile {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-profile-placeholder {
  background-color: #eee;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.m-profile-placeholder img {
  width: 60%;
  height: 60%;
  object-fit: cover;
}
#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.member-card-details {
  width: 75%;
  /* background-color: rgba(125, 125, 125, 0.166);
    backdrop-filter: blur(20%); */
  font-size: 20px;
  line-height: 34px;
  color: #333333;
  position: relative;
}
label {
  float: left !important;
  flex-direction: start !important;
  align-items: left !important;
  font-size: 16px !important;
}

.m-name {
  font-weight: bold;
}
.name-position-row {
  display: flex;
  justify-content: space-between;
}
.input-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 48%;
}
.form-control {
  border: 1px solid var(--first-color) !important;
  border-radius: 5px !important;
}

.m-discription {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
  height: auto !important;
  padding: 10px 10px;
  /* border: 1px solid var(--first-color) !important; */
  border-radius: 5px !important;
  overflow-y: scroll !important;
  line-height: 20px;
  min-height: 200px;
  border: 1px solid rgb(116, 116, 116) !important;
}

.see-detail-discription {
  position: absolute;
  /* width: 300px;
    height: 300px; */
  border-radius: 20px;
  padding: 10px;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05),
    0px 2px 4px rgba(44, 43, 42, 0.05), 0px -2px 4px rgba(44, 43, 42, 0.05);
}
.view-more {
  background: none;
  outline: none;
  color: #4f4f4f;
  border: none;
  font-size: 16px;
}
.edit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* background: radial-gradient(50% 50% at 50% 50%, #DFE2E4 78.44%, #D5D9DC 100%); */
  background-color: #00853c2c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-btn svg {
  width: 20px;
  height: 20px;
  fill: #00853c;
}
.member-card:hover .edit-btn svg {
  transform: rotate(90deg);
}

.upload-button-container {
  /* position: absolute; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  bottom: 0px;
}
button.save-link,
button.cancel-link {
  display: block;
  color: #41ac66;
  font-size: 15px;
  background-color: #fff;
  padding: 0px 10px;
  border: 1px solid #41ac66;
  border-radius: 10px;
  margin-left: 10px;
}
button.cancel-link {
  color: red;
  border: 1px solid red;
}
@media (max-width: 600px) {
  .add-member-card {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100vh;
    bottom: 0px;
    padding-bottom: 150px;
    padding-bottom: 150px;
  }
  .m-profile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 30vh;
  }
  .m-profile-placeholder {
    width: 120px;
    height: 120px;
  }
  .m-discription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-y: scroll;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
    height: 25vh !important;
    padding: 10px 10px;
  }
  .member-card-details {
    width: 100%;
    font-size: 20px;
    line-height: 34px;
    color: #333333;
    position: relative;
    min-height: 55vh;
    margin-top: 50px;
  }
  .upload-button-container {
    position: absolute;
    /* width: 100%;
  display: flex;
   */
    /* padding: 10px 0; */
    /* justify-content: center; */
    padding-top: 0;
    padding-bottom: 0;
    bottom: 50px;
  }
}
@media (max-width: 375px) {
  .m-profile {
    max-height: 20vh;
  }
  .m-discription {
    height: 20vh !important;
    padding: 10px 10px;
  }
  .upload-button-container {
    bottom: 0 !important;
  }
  .member-card-details{
    /* width: 100%;
    font-size: 20px;
    line-height: 34px;
    color: #333333;
    position: relative; */
    min-height: 53vh;
    margin-top: 73px;
}
}
p[contenteditable="true"] {
  padding: 0 10px;
  border: 2px solid #00853c40;
}
.m-name {
  font-weight: normal;
  width: 100%;
  font-size: 18px;
  padding: 5px 5px;
}
.select {
  width: 100%;
  margin-left: 0;
}
/* p[contenteditable="false"] {
  padding: 0 10px;
  border: 2px solid #00853C40;
  background-color: transparent;
} */
</style>