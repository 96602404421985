import { render, staticRenderFns } from "./Cookies.vue?vue&type=template&id=8cbc376a&scoped=true&"
import script from "./Cookies.vue?vue&type=script&lang=js&"
export * from "./Cookies.vue?vue&type=script&lang=js&"
import style0 from "./Cookies.vue?vue&type=style&index=0&id=8cbc376a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cbc376a",
  null
  
)

export default component.exports