import { render, staticRenderFns } from "./LoadingMore.vue?vue&type=template&id=6f5ac2ec&scoped=true&"
import script from "./LoadingMore.vue?vue&type=script&lang=js&"
export * from "./LoadingMore.vue?vue&type=script&lang=js&"
import style0 from "./LoadingMore.vue?vue&type=style&index=0&id=6f5ac2ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f5ac2ec",
  null
  
)

export default component.exports