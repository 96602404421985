<template>
    <div class="followings">
        <table class="table table-sm">
            <tr v-if="pitchdeckRequested.result.length < 1">
                <td rowspan="2" class="text-center muted-text">{{ $t(text) }}</td>
            </tr>
            <tr v-for="deck in pitchdeckRequested.result" :key="deck.id" v-else>
                <td class="td-width pl-3">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" class="svgr text-font">
                        <rect x="-17.5676" y="-8.74561" width="64.1457" height="40.1818" rx="10"
                            fill="url(#pattern0)" />
                    </svg> <span>{{deck.startup}}</span>
                </td>
                <td>
                    <div class="d-flex justify-content-center align-items-center">
                        <a :href="deck.value" target="_blank" v-if="deck.status==2" class="tool-tip">
                            <svg width="30" height="18" viewBox="0 0 30 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="svga">
                                <path
                                    d="M14.5122 3.14846C11.6743 3.14846 9.35242 5.66722 9.35242 8.74571C9.35242 11.8242 11.6743 14.343 14.5122 14.343C17.3501 14.343 19.6721 11.8242 19.6721 8.74571C19.6721 5.66722 17.3501 3.14846 14.5122 3.14846ZM28.8307 8.25595L26.0573 5.21244C22.9937 1.85409 18.8981 0 14.5122 0C10.1264 0 6.03078 1.85409 2.96714 5.21244L0.193737 8.25595C-0.0642536 8.53581 -0.0642536 8.95561 0.193737 9.23547L2.96714 12.279C6.03078 15.6373 10.1264 17.4914 14.5122 17.4914C18.8981 17.4914 22.9937 15.6373 26.0573 12.279L28.8307 9.23547C29.0887 8.95561 29.0887 8.53581 28.8307 8.25595ZM25.1544 11.2995C22.3165 14.3779 18.5433 16.0921 14.5122 16.0921C10.4811 16.0921 6.70801 14.3779 3.87011 11.2995L1.54819 8.74571L3.87011 6.19196C6.70801 3.11347 10.4811 1.39931 14.5122 1.39931C18.5433 1.39931 22.3165 3.11347 25.1544 6.19196L27.4763 8.74571L25.1544 11.2995Z"
                                    fill="#818181" />
                            </svg>

                            <span class="tool-tip-texts">
                                <p class="p-3">
                                    {{$t(textview)}}
                                </p>
                            </span>
                        </a>

                        <p class="tool-tip" v-if="deck.status === 1 || deck.status === 3 || deck.status === 4">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.2 19.1V10.7M12.2 7.99995V5.19995M12.2 23.4C6.026 23.4 1 18.374 1 12.2C1 6.026 6.026 1 12.2 1C18.374 1 23.4 6.026 23.4 12.2C23.4 18.374 18.374 23.4 12.2 23.4Z"
                                    stroke="#4B4453" stroke-width="2" stroke-linejoin="round" />
                            </svg>
                            <span class="tool-tip-text">
                                <p class="p-3">
                                    <span v-if="deck.status === 1">{{ $t(text2) }}</span>
                                    <span v-if="deck.status === 3">{{ $t(text3) }}</span>
                                    <span v-if="deck.status === 4">{{ $t(text4) }}</span>
                                </p>
                            </span>
                        </p>

                        <div class="icon ellipsis-icon tool-tip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 4.064 14">
                                <path
                                    d="M26.032,12.968A2.032,2.032,0,1,1,24,15,2.031,2.031,0,0,1,26.032,12.968ZM24,10.032A2.032,2.032,0,1,0,26.032,8,2.031,2.031,0,0,0,24,10.032Zm0,9.935a2.032,2.032,0,1,0,2.032-2.032A2.031,2.031,0,0,0,24,19.968Z"
                                    transform="translate(-24 -8)" fill="#727272" />
                            </svg>

                            <span class="tool-tip-texts">
                                <div class="delBtn" @click="deleteRequest(deck.id)">
                                    <span>{{$t(textdelete)}}</span>
                                </div>
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        text: {
            type: String,
            default: "noRecordFound",
        },

        text2: {
            type: String,
            default: "pendingApproval",
        },

        text3: {
            type: String,
            default: "rejected",
        },

        text4: {
            type: String,
            default: "expired",
        },

        textview: {
            type: String,
            default: "view"
        },

        textdelete: {
            type: String,
            default: "delete"
        }
    },

   async created() {
       await this.$store.dispatch("getPitchdeckRequested");
       console.log("this.pitchdeckRequested", this.pitchdeckRequested)

    },

    computed: {
        ...mapGetters(["pitchdeckRequested"]),
    },

    methods: {
        ...mapActions(["deleteVaultRequest", "getPitchdeckRequested"]),

        deleteRequest(id) {
            this.$store.dispatch("deleteVaultRequest", id)
                .then(() => {
                    this.getPitchdeckRequested()
                })
        }
    }
};
</script>

<style lang="scss" scoped>
.svgr {
    border-radius: 10px;
}

.td-width {
    width: 80%;
}

.followings {
    background: #fff;
}

.svg {
    width: .7rem !important;
    height: .7rem !important;
}

.svga {
    width: 1.3rem !important;
    height: 1.3rem !important;
}

.tool-tip .tool-tip-text {
    display: none;
    width: 160px;
    background-color: rgb(64, 63, 63);
    color: #ffff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    right: 2em;
    box-shadow: 2px 3px #c7c7c7;
}

.tool-tip .tool-tip-texts {
    display: none;
    width: 80px;
    background-color: rgb(64, 63, 63);
    color: #ffff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    right: 2em;
    box-shadow: 2px 3px #c7c7c7;
    cursor: pointer;
}

.tool-tip:hover .tool-tip-text {
    display: block;
}

.tool-tip:hover .tool-tip-texts {
    display: block;
}

.delBtn {
    padding: 5px;
    text-align: center;
}
</style>
