<template>
  <div class="right-side">
    <Illustration
      :blob="require('@/assets/registration/blob.svg')"
      :illustration="require('@/assets/registration/illustration.svg')"
    />
  </div>
</template>
<script>
import Illustration from "../../../../components/Auth/Illustration.vue";
export default {
  name: "RightSide",
  components: {
    Illustration,
  },
};
</script>
<style scoped>
.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  flex-basis: 0;
  flex-grow: 0.6;
  border-top-left-radius: var(--layout-radius);
  border-bottom-left-radius: var(--layout-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}
.green-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--primary-green);
  width: 90%;
  height: 80vh;
  border-radius: calc(var(--layout-radius) * 0.8);
}
.blob-img {
  position: absolute;
  height: 80%;
}
.illustration {
  height: 93vh;
  margin-bottom: 30px;
  z-index: 1000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .right-side {
    position: absolute;
    top: 0;
    width: 100vw;
    display: none;
  }
  .green-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--primary-green);
    width: 90%;
    height: 80vh;
    border-radius: calc(var(--layout-radius) * 0.8);
  }
  .blob-img {
    position: absolute;
    height: 80%;
  }
  .illustration {
    height: 93vh;
    margin-bottom: 30px;
    z-index: 1000;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>