<template>
  <div>
    <div class="py-4">
      <div class="container-fluid px-md-5">
        <div class="row" v-if="!loading">
          <aside class="col-md-4">
            <UpdateAvatar :profileAavatar="this.profile.user.avatar" />
            <form @submit.prevent="updateAbout">
              <div class="border rounded bg-white mb-3">
                <div class="box-title border-bottom p-3">
                  <h6 class="m-0 text-center">{{ $t("about") }}</h6>
                  <p class="mb-0 mt-0 small text-center">
                    {{ $t("tellAboutYourself") }}
                  </p>
                </div>
                <div class="box-body">
                  <div class="p-3 border-bottom">
                    <div class="form-group mb-0">
                      <label class="mb-1 w-100">{{ $t("headline") }}</label>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              name="headline"
                              v-model="profile.headline"
                              :placeholder="$t('headline')"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-4">
                      <label class="mb-1">BIO</label>
                      <div class="position-relative">
                        <textarea
                          class="form-control"
                          rows="10"
                          name="bio"
                          placeholder="Bio"
                          v-model="profile.about"
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div class="overflow-hidden text-center p-3">
                    <button
                      :disabled="btnLoading"
                      type="submit"
                      class="
                        font-weight-bold
                        btn btn-light
                        rounded
                        p-3
                        btn-block
                      "
                    >
                      <i
                        class="fa fa-spin fa-1x fa-spinner"
                        v-if="btnLoading"
                      ></i>
                      {{ loading ? "" : $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </aside>
          <main class="col-md-8">
            <div class="border rounded bg-white mb-3">
              <div class="box-title border-bottom p-3">
                <h6 class="m-0">{{ $t("editBasicInfo") }}</h6>
              </div>
              <div class="box-body p-3">
                <form @submit.prevent="updateBasicProfil">
                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="nameLabel" class="form-label">
                          {{ $t("lastName") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            @change="changeUsername()"
                            v-model="profile.user.name"
                            :placeholder="$t('namePlaceholder')"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label class="form-label">
                          {{ $t("firstName") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="firstname"
                            v-model="profile.user.firstname"
                            @change="changeUsername()"
                            :placeholder="$t('namePlaceholder')"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 mb-2">
                      <label id="usernameLabel" class="form-label">
                        {{ $t("username") }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          name="username"
                          disabled
                          v-model="profile.user.username"
                          :placeholder="$t('usernamePlaceholder')"
                          required
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            @click="checkUsername(profile.user.username)"
                          >
                            <i class="feather-check-circle mr-1"></i>
                            {{ $t("check") }}
                          </span>
                        </div>
                      </div>
                      <div class="match m-1" v-if="availability">
                        {{ availabilityText }}
                      </div>
                      <div class="mismatch m-1" v-else>
                        {{ availabilityText }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="emailLabel" class="form-label">
                          {{ $t("email") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            required
                            :placeholder="$t('emailPlaceholder')"
                            v-model="profile.user.email"
                          />
                          <small class="form-text text-muted">{{
                            $t("neverShareInfo")
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="phoneNumberLabel" class="form-label">
                          {{ $t("phone") }}
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="phone"
                            :placeholder="$t('phone')"
                            v-model="profile.phone"
                          />
                          <!-- <VuePhoneNumberInput
                            v-if="$i18n.locale === 'fr'"
                            :required="true"
                            :default-country-code="null"
                            :translations="{
                              countrySelectorLabel: 'Code pays',
                              countrySelectorError: 'Choisir un pays',
                              phoneNumberLabel: 'Numéro de téléphone',
                              example: 'Exemple :',
                            }"
                            v-model="profile.phone"
                          />
                          <VuePhoneNumberInput
                            v-else
                            v-model="profile.user.phone"
                          /> -->
                          <small class="form-text text-muted"
                            >{{ $t("neverShareInfo") }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("countryOfResidence") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <country-select
                            v-model="profile.residenceContry"
                            :countryName="true"
                            :autocomplete="true"
                            :disablePlaceholder="true"
                            :use188n="false"
                            :required="true"
                            :country="profile.residenceContry"
                            class="form-control"
                            :placeholder="$t('selectCountryOfResidence')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("cityOfResidence") }}
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="location"
                            v-model="profile.residenceCity"
                            :placeholder="$t('cityOfResidencePlaceholder')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("africanCountryOfOrigin") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <country-select
                            v-model="profile.birthCountry"
                            :countryName="true"
                            :autocomplete="true"
                            :usei18n="true"
                            :disablePlaceholder="true"
                            :country="profile.birthCountry"
                            class="form-control"
                            :placeholder="$t('selectAfricanCountryOfOrigin')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="locationLabel" class="form-label">
                          {{ $t("cityOfOrigin") }}
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="birthCity"
                            v-model="profile.birthCity"
                            :placeholder="$t('cityOfOriginPlaceholder')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="phoneNumberLabel" class="form-label">
                          {{ $t("dob") }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <Datepicker
                            v-if="$i18n.locale === 'fr'"
                            v-model="profile.dob"
                            :language="fr"
                            :clear-button="false"
                            input-class="form-control"
                            :required="true"
                            :typeable="true"
                            :format="customFormatter"
                            :placeholder="$t('dobPlaceholder')"
                          />
                          <Datepicker
                            v-else
                            v-model="profile.dob"
                            :language="en"
                            input-class="form-control"
                            :clear-button="false"
                            :required="true"
                            :typeable="true"
                            :format="customFormatter"
                            :placeholder="$t('dobPlaceholder')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label class="form-label">
                          {{ $t("preferredLanguage") }}
                        </label>
                        <div class="form-group">
                          <select
                            class="custom-select"
                            v-model="profile.preferedLanguage"
                          >
                            <option disabled>
                              {{ $t("selectLanguage ") }}
                            </option>
                            <option
                              :value="lang"
                              v-for="(lang, i) in $i18n.availableLocales"
                              :key="i"
                            >
                              {{ getLang(lang) }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="organizationLabel" class="form-label">
                          {{ $t("organization") }}
                        </label>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            name="organization"
                            v-model="profile.organization"
                            :placeholder="$t('organizationPlaceholder')"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                      <div class="js-form-message">
                        <label id="websiteLabel" class="form-label">
                          {{ $t("currentPosition") }}
                        </label>
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="text"
                            name="currentPosition"
                            v-model="profile.currentPosition"
                            :placeholder="$t('currentPositionPlaceholder')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="">
                      <p>
                        {{ $t("typeofTalents") }}
                        <span class="text-danger">*</span>
                      </p>
                      <form
                        @submit.prevent="createSupport"
                        v-if="showSupportTypeform"
                      >
                        <div class="border rounded bg-white mb-3">
                          <div class="box-body px-3 pt-3 pb-0">
                            <div class="row">
                              <div class="col-sm-12 mb-4">
                                <label id="TO" class="form-label"
                                  >{{ $t("Select") }}
                                  <span class="text-danger">*</span>
                                </label>
                                <div class="input-group">
                                  <VueMultiselect
                                    v-model="profile.typeofTalents"
                                    :options="types"
                                    :multiple="false"
                                    :value="profile.typeofTalents"
                                    track-by="name"
                                    :custom-label="customLabel"
                                    selectLabel="+"
                                    deselectLabel="-"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            :disabled="loadingSupport"
                            type="submit"
                            class="btn btn-success ml-3"
                          >
                            <i
                              class="fa fa-spin fa-1x fa-spinner"
                              v-if="loadingSupport"
                            ></i>
                            {{ loadingSupport ? "" : $t("save") }}
                          </button>

                          <button
                            type="button"
                            @click="hideSupportTypeForm"
                            class="btn btn-danger ml-3"
                          >
                            {{ $t("cancel") }}
                          </button>
                        </div>
                      </form>
                      <div class="card-body">
                        <div>
                          <div class="col-12 mb-2">
                            <button
                              type="button"
                              class="btn btn-info m-1 tag"
                              v-for="support in getUserSupportTypes"
                              :key="support.id"
                            >
                              {{ $t(support.typeOfSupport) }}
                              <span
                                @click="deleteSupportType(support.id)"
                                class="badge badge-light"
                                ><span class="feather-x"></span
                              ></span>
                            </button>
                          </div>
                        </div>
                        <button
                          type="button"
                          @click="showSupportTypeFormulaire"
                          class="btn btn-primary"
                        >
                          {{ $t("addSupportType") }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 text-right">
                    <div class="overflow-hidden text-center p-3">
                      <button
                        :disabled="btnLoadingBasic"
                        type="submit"
                        class="
                          font-weight-bold
                          btn btn-light
                          rounded
                          p-3
                          btn-block
                        "
                      >
                        <i
                          class="fa fa-spin fa-1x fa-spinner"
                          v-if="btnLoadingBasic"
                        ></i>
                        {{ loading ? "" : $t("save") }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../../constant";
import { mapGetters, mapActions } from "vuex";
import VueMultiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import i18n from "../../../i18n";
import { en, fr } from "vuejs-datepicker/dist/locale";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Edit Profile ",
    };
  },
  async created() {
    // this.authUser;
    await this.loadProfile();
    this.profile = this.getProfile;
    this.profile.residenceContry = this.fortmatCountry(
      this.profile.residenceContry
    );
    this.profile.birthCountry = this.fortmatCountry(this.profile.birthCountry);
    this.loading = false;
    let user_id = this.profile.user_id;
    await this.loadUserTypeOfSupport({ user_id });
    this.types = [
      {
        id: 1,
        code: "Invest",
        name: i18n.t("Investment"),
      },
      {
        id: 2,
        code: "Consulting",
        name: i18n.t("Consulting"),
      },
      {
        id: 3,
        code: "Mentoring",
        name: i18n.t("Mentoring"),
      },
    ];
  },
  computed: {
    ...mapGetters(["authUser", "getProfile", "getUserSupportTypes"]),
  },

  data() {
    return {
      result: null,
      en: en,
      fr: fr,
      profile: {},
      types: [],
      loading: true,
      btnLoading: false,
      btnLoadingBasic: false,
      btnLoadingSocials: false,
      availabilityText: null,
      availability: null,
      showSupportTypeform: false,
      interrestList: {},
      loadingSupport: false,
    };
  },
  methods: {
    ...mapActions([
      "loadProfile",
      "updateBasicInfo",
      "loadUserTypeOfSupport",
      "createUserTypeOfSupport",
      "deleteUserSupport",
    ]),
    changeUsername() {
      this.profile.user.username =
        this.profile.user.name.toLowerCase().replace(/[^\w\s]/gi, "") +
        this.profile.user.firstname.toLowerCase().replace(/[^\w\s]/gi, "");

      this.profile.user.username = this.profile.user.username.replace(
        /\s/g,
        ""
      );
    },
    showSupportTypeFormulaire() {
      
      this.showSupportTypeform = true;
    },
    async createSupport() {
      let user_id = this.profile.user_id;
      let support = this.profile.typeofTalents;
      await this.createUserTypeOfSupport({ user_id, support });
      await this.loadUserTypeOfSupport({ user_id });
      this.showSupportTypeform = false;
    },
    async deleteSupportType(support_id) {
      let user_id = this.profile.user_id;
      await this.deleteUserSupport({ user_id, support_id });
      await this.loadUserTypeOfSupport({ user_id });
    },
    hideSupportTypeForm() {
      this.showSupportTypeform = false;
    },
    customLabel(option) {
      return `${option.name}`;
    },
    getLang(locale) {
      var value = "navBar." + locale;
      return this.$i18n.t(value);
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      // country = countries.getName(country, this.$i18n.locale, {
      //   select: 'official',
      // });
      country = countries.getName(country, "en", { select: "official" });
      return country;
    },
    checkUsername(username) {
      Axios.get(APIURLS.USERS + "check-username/" + username).then(
        (response) => {
          if (this.authUser.username === username) {
            this.availability = true;
            this.availabilityText = `${this.$i18n.t("yourUsername")}`;
          } else if (response.data) {
            this.availability = true;
            this.availabilityText = `${this.$i18n.t("usernameAvailable")}`;
          } else {
            this.availability = false;
            this.availabilityText = `${this.$i18n.t("usernameNotAvailable")}`;
          }
        }
      );
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    updateBasicProfil() {
      this.btnLoadingBasic = true;
      const preferedLanguage = localStorage.getItem("preferedLanguage");
      if (preferedLanguage) {
        if (this.profile.preferedLanguage != preferedLanguage) {
          localStorage.removeItem("preferedLanguage");
          localStorage.setItem(
            "preferedLanguage",
            this.profile.preferedLanguage
          );
        }
      } else {
        if (this.profile.preferedLanguage) {
          localStorage.setItem(
            "preferedLanguage",
            this.profile.preferedLanguage
          );
        }
      }

      const basicInfo = {
        name: this.profile.user.name,
        firstname: this.profile.user.firstname,
        email: this.profile.user.email,
        username: this.profile.user.username,
      };
      
      const extendedInfo = {
        phone: this.profile.phone,
        residenceContry: this.countryCode(this.profile.residenceContry),
        residenceCity: this.profile.residenceCity,
        birthCountry: this.countryCode(this.profile.birthCountry),
        birthCity: this.profile.birthCity,
        address: this.profile.address,
        postalCode: this.profile.postalCode,
        genre: this.profile.genre,
        dob: this.customFormatter(this.profile.dob),
        preferedLanguage: this.profile.preferedLanguage,
        organization: this.profile.organization,
        currentPosition: this.profile.currentPosition,
      };
      //this.btnLoadingBasic = false;
      this.$store
        .dispatch("updateBasicInfo", {
          basicInfo: basicInfo,
          extendedInfo: extendedInfo,
        })
        .then(() => {
          let auth = JSON.parse(localStorage.getItem("auth"));
          auth.name = this.profile.user.name;
          auth.email = this.profile.user.email;
          auth.username = this.profile.user.username;
          localStorage.removeItem("auth");
          localStorage.setItem("auth", JSON.stringify(auth));
          this.$store.commit("setAuthUser", auth);
          this.$noty.success(this.$i18n.t("profilUpdated"));
          this.btnLoadingBasic = false;
          // this.$router.go(0);
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    updateAbout() {
      this.btnLoading = true;
      this.$store
        .dispatch("updateAbout", {
          headline: this.profile.headline,
          about: this.profile.about,
        })
        .then((success) => {
          this.btnLoading = false;
          this.$noty.success(this.$i18n.t("bioUpdated"));
          //this.$router.push(`/${i18n.locale}/${i18n.t('routes.dashboard')}`);
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    updateSocials() {
      this.btnLoadingSocials = true;
      this.$store
        .dispatch("updateSocials", {
          linkedin: this.profile.linkedin,
          facebook: this.profile.facebook,
          youtube: this.profile.youtube,
          instagram: this.profile.instagram,
          twitter: this.profile.twitter,
          github: this.profile.github,
          website: this.profile.website,
        })
        .then((success) => {
          this.btnLoadingSocials = false;
          this.$noty.success(`${i18n.t("success")}`);
          //this.$router.push(`/${i18n.locale}/${i18n.t('routes.dashboard')}`);
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  name: "EditProfile",
  components: {
    Datepicker,
    VueMultiselect,
  },
};
</script>
