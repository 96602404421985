<template>
  <div class="#">
    <div
      class="box shadow-sm mb-3 rounded bg-white ads-box text-center overflow-hidden"
    >
      <div class="p-2 border-bottom">
        <h6 class="font-weight-bold text-black">{{ $t('socialProfiles') }}</h6>
      </div>
      <div class="p-2">
        <div class="col">
          <a
            v-if="Linkedin"
            :href="Linkedin"
            target="_blank"
            class="btn btn-sm btn-outline-linkedin btn-block"
          >
            <i class="feather-linkedin"></i> Linkedin
          </a>
          <a
            v-if="Instagram"
            :href="Instagram"
            target="_blank"
            class="btn btn-sm btn-outline-instagram btn-block"
          >
            <i class="feather-instagram"></i> Instagram
          </a>
          <a
            v-if="Facebook"
            :href="Facebook"
            target="_blank"
            class="btn btn-sm btn-outline-facebook btn-block"
          >
            <i class="feather-facebook"></i> Facebook
          </a>
          <a
            v-if="Youtube"
            :href="Youtube"
            target="_blank"
            class="btn btn-sm btn-outline-youtube btn-block"
          >
            <i class="feather-youtube"></i> Youtube
          </a>
          <a
            v-if="Twitter"
            :href="Twitter"
            target="_blank"
            class="btn btn-sm btn-outline-twitter btn-block"
          >
            <i class="feather-twitter"></i> Twitter
          </a>
          <a
            v-if="Github"
            :href="Github"
            target="_blank"
            class="btn btn-sm btn-outline-github btn-block"
          >
            <i class="feather-github"></i> Github
          </a>
          <a
            v-if="Website"
            :href="Website"
            target="_blank"
            class="btn btn-sm btn-outline-website btn-block"
          >
            <i class="feather-globe"></i> Website
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';
export default {
  name: 'SocialMedia',
  props: {
    Linkedin: null,
    Instagram: null,
    Facebook: null,
    Youtube: null,
    Twitter: null,
    Github: null,
    Website: null,
  },
};
</script>
<style>
.btn-outline-youtube {
  border: transparent !important;
  background: #ff0000 !important;
  color: #fff;
}
.btn-outline-twitter {
  border: transparent !important;
  background: #1da1f2 !important;
  color: #fff;
}

.btn-outline-github {
  border: transparent !important;
  background: #333 !important;
  color: #fff;
}
.btn-outline-website {
  border: transparent !important;
  background: #148fa5 !important;
  color: #fff;
}
.btn-outline-linkedin {
  border: transparent !important;
  background: #0072b1 !important;
  color: #fff;
}
.btn-outline-facebook {
  border: transparent !important;
  background: #4267b2 !important;
  color: #fff;
}
.btn-outline-instagram {
  background: #f09433;
  border: transparent !important;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
</style>
