<template>
  <button
    type="button"
    class="account"
    @click="commitAccountType"
    :class="{
      active:
        (isDiaspora && getAccountType === label) ||
        (isStartup && getAccountType === label),
    }"
  >
    <div class="profile-container">
      <img :src="profileImg" />
    </div>
    <div class="account-label">{{ label }}</div>
  </button>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Account",
  mounted() {
    let accountType = localStorage.getItem("getAccountType");
    if (accountType) {
      if (accountType === "Diaspora") {
        this.isDiaspora = true;
      } else {
        this.isStartup = true;
      }
      this.$store.commit("setAccountType", accountType);
    }
  },
  data: () => {
    return {
      isActive: false,
      isDiaspora: false,
      isStartup: false,
    };
  },
  props: {
    label: String,
    profileImg: String,
  },
  methods: {
    ...mapMutations(["setAccountType"]),
    commitAccountType() {
      if (this.label === "Diaspora") {
        this.isDiaspora = true;
        this.isStartup = false;
      } else {
        this.isStartup = true;
        this.isDiaspora = false;
      }
      this.$store.commit("setAccountType", this.label);
      localStorage.setItem("getAccountType", this.label);
    },
  },
  computed: {
    ...mapGetters(["getFomData", "getAccountType"]),
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.account {
  display: flex;
  align-items: center;
  background-color: rgba(84, 155, 86, 0.27);
  background-color: rgb(255, 255, 255);
  width: 49%;
  height: 60px;
  padding-left: 15px;
  border-radius: 0.5rem;
  border: 1px solid #a9b2b745;
}
.profile-container {
  width: 45px;
  height: 45px;
  background-color: rgba(84, 155, 86, 0.27) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(0deg);
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 4px;
  padding: 0;
}
.profile-container img {
  width: 25px;
  height: 25px;
}
.account-label {
  /* font-weight: 500; */
  font-size: 22px;
  padding-left: 0px;
  color: #a9b2b7;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.account:hover {
  border-color: var(--first-color);
}
.active {
  background-color: var(--first-color);
}

.active > .profile-container {
  background-color: rgba(0, 0, 0, 0.139) !important;
}

.active > .account-label {
  color: #ffffff !important;
}
.account:hover .profile-container {
  background-color: rgba(0, 0, 0, 0.139) !important;
  transform: rotate(90deg);
}
.account:hover .account-label {
  color: rgba(0, 0, 0, 0.262) !important;
  padding-left: 20px;
}

.account:hover,
.active > .account-label {
  color: #ffffff !important;
}

/* @media only screen and (max-width: 600px) {
  .account {
    width: 100%;
    height: 80px;
    margin: 3px 0;
  }
} */

@media only screen and (max-width: 1200px) {
  .account {
    width: 100%;
    height: 55px;
    margin: 3px 0;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .account-label {
    /* font-weight: 500; */
    font-size: 20px;
    padding-left: 10px;
  }
  .profile-container {
    width: 45px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0.139);
    padding: 0;
    margin: 0;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 320px) {
  .account {
    width: 100%;
    height: 45px;
    margin: 3px 0;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
  .profile-container {
    width: 35px;
    height: 35px;
  }
  .account-label {
    font-size: 18px;
    padding-left: 15px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>