<template>
  <div>
    <section class="top-banner">
      <div class="projrow">
        <div class="proj-logo">
          <div class="logoDesktop circle c1">
            <div class="circle c2">
              <img
                class="img-profile rounded-circle avatar"
                width="50"
                :src="job.companyLogo"
                alt="company Logo"
              />
            </div>
          </div>
        </div>

        <div class="proj-title proj-title-desktop">
          <h2>{{ job.title }}</h2>

          <div class="address">
            <span class="city-country">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.866"
                height="20.981"
                viewBox="0 0 14.866 20.981"
              >
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M881.6,490c.262.033.527.052.786.1a7.34,7.34,0,0,1,5.756,4.694,7,7,0,0,1,.484,2.388,6.286,6.286,0,0,1-.4,2.285,21.75,21.75,0,0,1-1.766,3.765,64.735,64.735,0,0,1-4.334,6.536c-.3.4-.61.8-.921,1.211a.592.592,0,0,1-.061-.057,64.56,64.56,0,0,1-5.519-8.236,20.643,20.643,0,0,1-1.494-3.3,6.63,6.63,0,0,1,.7-5.772,7.243,7.243,0,0,1,5.084-3.5c.243-.046.492-.06.738-.09.05-.006.1-.015.151-.023Zm2.477,7.11a2.88,2.88,0,1,0-2.872,2.876A2.879,2.879,0,0,0,884.073,497.11Z"
                  transform="translate(-873.76 -490)"
                  opacity="0.8"
                />
              </svg>
              {{ job.city }},
              {{ fortmatCountry(job.country) }}
            </span>

            <span>
              <svg
                class="date"
                viewBox="0 0 23.844 22.34"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  transform="translate(-562.27 -1124)"
                  opacity=".8"
                  data-name="Group 36"
                >
                  <path
                    transform="translate(0 -6.364)"
                    d="m565.16 1132v1.394a0.4 0.4 0 0 0 0.453 0.454h0.981a0.388 0.388 0 0 0 0.441-0.438q0-0.629 0-1.259v-0.151h4.827v0.137 1.272a0.39 0.39 0 0 0 0.43 0.438q0.51 0 1.02 0a0.385 0.385 0 0 0 0.425-0.428v-1.415h4.827v0.136 1.285a0.382 0.382 0 0 0 0.418 0.422q0.523 0 1.047 0a0.383 0.383 0 0 0 0.411-0.417v-1.428c0.045 0 0.082-9e-3 0.12-9e-3h0.967a1.62 1.62 0 0 1 1.6 1.542v0.225q0 3.206 0 6.413a0.293 0.293 0 0 0 0.136 0.27 6.612 6.612 0 0 1 2.842 5.8 6.749 6.749 0 0 1-13.027 2.18 0.219 0.219 0 0 0-0.248-0.161q-4.445 5e-3 -8.89 0a1.63 1.63 0 0 1-1.633-1.267 1.561 1.561 0 0 1-0.04-0.354q0-6.519 0-13.037a1.62 1.62 0 0 1 1.6-1.612h1.193c0.025-1e-3 0.055 3e-3 0.1 8e-3zm14.183 19.493a5.54 5.54 0 1 0-5.517-5.345 5.547 5.547 0 0 0 5.516 5.343zm2.049-12.014v-0.056q0-1.106 0-2.213a0.919 0.919 0 0 0-0.01-0.119 1.072 1.072 0 0 0-1.12-0.9q-7.572 0.011-15.144 0a1.048 1.048 0 0 0-1.124 1.126q0 1.458 0 2.915 0 2.623 0 5.246a1.046 1.046 0 0 0 1.1 1.107h7.513v-1.022h-0.7a0.414 0.414 0 0 1-0.426-0.429v-0.5a0.427 0.427 0 0 1 0.467-0.471h0.795a0.1 0.1 0 0 0 0.12-0.089c0.065-0.211 0.141-0.418 0.212-0.626a0.5 0.5 0 0 0 0.014-0.078h-1.163a0.416 0.416 0 0 1-0.444-0.449v-0.45c0-0.345 0.157-0.5 0.5-0.5h1.312a0.627 0.627 0 0 1 0.488 0.115c0.265-0.3 0.517-0.6 0.776-0.888 0.1-0.108 0.242-0.189 0.123-0.367a0.154 0.154 0 0 1-8e-3 -0.078q0-0.252 0-0.5a0.419 0.419 0 0 1 0.464-0.469h1.325a0.78 0.78 0 0 0 0.28-0.058 6.7 6.7 0 0 1 3.464-0.464c0.399 0.044 0.789 0.138 1.189 0.215z"
                    data-name="Path 13"
                  />
                  <path
                    transform="translate(-38.74 -.004)"
                    d="m612.17 1125.6v1.153a0.387 0.387 0 0 1-0.428 0.426q-0.205 0-0.411 0a0.387 0.387 0 0 1-0.423-0.431v-1.8-0.5a0.383 0.383 0 0 1 0.416-0.424h0.451a0.375 0.375 0 0 1 0.4 0.4c-3e-3 0.387-5e-3 0.787-5e-3 1.176z"
                    data-name="Path 14"
                  />
                  <path
                    transform="translate(-12.501)"
                    d="m579.22 1125.6v1.153a0.385 0.385 0 0 1-0.428 0.425h-0.424a0.374 0.374 0 0 1-0.406-0.392q-7e-3 -1.192 0-2.385a0.366 0.366 0 0 1 0.387-0.383q0.245-8e-3 0.49 0a0.371 0.371 0 0 1 0.38 0.39c3e-3 0.397 1e-3 0.795 1e-3 1.192z"
                    data-name="Path 15"
                  />
                  <path
                    transform="translate(-64.983)"
                    d="m645.11 1125.6v1.138a0.389 0.389 0 0 1-0.432 0.434h-0.436a0.376 0.376 0 0 1-0.394-0.391q-6e-3 -1.19 0-2.381a0.372 0.372 0 0 1 0.385-0.386c0.163-6e-3 0.326 0 0.489 0a0.374 0.374 0 0 1 0.388 0.4c2e-3 0.395 0 0.789 0 1.186z"
                    data-name="Path 16"
                  />
                  <path
                    transform="translate(-62.289 -56.749)"
                    d="m642.79 1196.4a1.165 1.165 0 0 1-2.329 0.01 1.165 1.165 0 1 1 2.329-0.01z"
                    data-name="Path 17"
                  />
                  <path
                    transform="translate(-53.304 -49.202)"
                    d="M631.172,1188.093a1.3,1.3,0,0,1-.27-.146q-.788-.707-1.565-1.425a.411.411,0,0,1-.03-.611.406.406,0,0,1,.6-.024q.789.715,1.573,1.436a.388.388,0,0,1,.112.456A.517.517,0,0,1,631.172,1188.093Z"
                    data-name="Path 18"
                  />
                  <path
                    transform="translate(-70.951 -48.297)"
                    d="M651.339,1186.822a.686.686,0,0,1,.13-.184q1.216-.97,2.439-1.93a.291.291,0,0,1,.433.043.287.287,0,0,1-.046.432q-1.228.976-2.461,1.946a.273.273,0,0,1-.311.031A.353.353,0,0,1,651.339,1186.822Z"
                    data-name="Path 19"
                  />
                  <path
                    transform="translate(-36.116 -28.264)"
                    d="m608.83 1159.5h0.754a0.422 0.422 0 0 1 0.457 0.45v0.516a0.421 0.421 0 0 1-0.434 0.436q-0.781 6e-3 -1.561 0a0.419 0.419 0 0 1-0.435-0.433v-0.542a0.416 0.416 0 0 1 0.426-0.428h0.794z"
                    data-name="Path 20"
                  />
                  <path
                    transform="translate(-48.591 -28.262)"
                    d="m624.5 1159.5h0.767a0.414 0.414 0 0 1 0.441 0.438v0.529a0.414 0.414 0 0 1-0.419 0.435q-0.794 9e-3 -1.588 0a0.405 0.405 0 0 1-0.419-0.394 4.625 4.625 0 0 1 0-0.621 0.4 0.4 0 0 1 0.422-0.387c0.263-3e-3 0.527 0 0.796 0z"
                    data-name="Path 21"
                  />
                  <path
                    transform="translate(-36.118 -36.866)"
                    d="m608.84 1170.3h0.767a0.416 0.416 0 0 1 0.434 0.433v0.529a0.414 0.414 0 0 1-0.418 0.437q-0.8 0.013-1.6 0a0.411 0.411 0 0 1-0.413-0.441q0-0.258 0-0.516a0.418 0.418 0 0 1 0.437-0.443h0.794z"
                    data-name="Path 22"
                  />
                  <path
                    transform="translate(-11.12 -54.044)"
                    d="m577.44 1193.2h-0.754a0.421 0.421 0 0 1-0.453-0.443v-0.516a0.425 0.425 0 0 1 0.453-0.445q0.767 0 1.535 0a0.42 0.42 0 0 1 0.443 0.439v0.542a0.41 0.41 0 0 1-0.417 0.421h-0.807z"
                    data-name="Path 23"
                  />
                  <path
                    transform="translate(-11.115 -28.262)"
                    d="m577.45 1159.5h0.767a0.416 0.416 0 0 1 0.444 0.449v0.516a0.417 0.417 0 0 1-0.43 0.438q-0.787 6e-3 -1.574 0a0.417 0.417 0 0 1-0.425-0.418q-0.011-0.284 0-0.569a0.412 0.412 0 0 1 0.425-0.415c0.264-4e-3 0.529-1e-3 0.793-1e-3z"
                    data-name="Path 24"
                  />
                  <path
                    transform="translate(-11.119 -45.436)"
                    d="m577.46 1182.4h-0.78a0.417 0.417 0 0 1-0.445-0.435q-7e-3 -0.265 0-0.529a0.414 0.414 0 0 1 0.421-0.436q0.794-0.012 1.588 0a0.415 0.415 0 0 1 0.423 0.445v0.529a0.412 0.412 0 0 1-0.424 0.427h-0.781z"
                    data-name="Path 25"
                  />
                  <path
                    transform="translate(-61.105 -28.263)"
                    d="m640.2 1159.5h0.767a0.414 0.414 0 0 1 0.442 0.437q0 0.264 0 0.529a0.417 0.417 0 0 1-0.444 0.438q-0.767 0-1.534 0a0.419 0.419 0 0 1-0.448-0.447v-0.529a0.413 0.413 0 0 1 0.424-0.428h0.794z"
                    data-name="Path 26"
                  />
                  <path
                    transform="translate(-11.125 -36.864)"
                    d="m577.46 1171.7h-0.741a0.435 0.435 0 0 1-0.481-0.485v-0.463a0.421 0.421 0 0 1 0.452-0.455q0.767 0 1.534 0a0.414 0.414 0 0 1 0.444 0.447q0 0.251 0 0.5a0.42 0.42 0 0 1-0.443 0.453c-0.253 8e-3 -0.509 3e-3 -0.765 3e-3z"
                    data-name="Path 27"
                  />
                  <path
                    transform="translate(-23.635 -36.865)"
                    d="m593.16 1170.3h0.767a0.412 0.412 0 0 1 0.444 0.446v0.5a0.424 0.424 0 0 1-0.467 0.455h-1.045-0.463a0.421 0.421 0 0 1-0.451-0.457v-0.516a0.41 0.41 0 0 1 0.433-0.43h0.781z"
                    data-name="Path 28"
                  />
                  <path
                    transform="translate(-23.631 -28.262)"
                    d="m593.16 1159.5h0.767a0.412 0.412 0 0 1 0.437 0.44v0.529a0.41 0.41 0 0 1-0.42 0.432q-0.794 8e-3 -1.588 0a0.41 0.41 0 0 1-0.417-0.422v-0.569a0.405 0.405 0 0 1 0.413-0.41h0.807z"
                    data-name="Path 29"
                  />
                  <path
                    transform="translate(-23.631 -54.045)"
                    d="m593.16 1191.8h0.754a0.418 0.418 0 0 1 0.449 0.445q0 0.258 0 0.516a0.411 0.411 0 0 1-0.435 0.442q-0.781 0-1.561 0a0.407 0.407 0 0 1-0.428-0.422v-0.555a0.417 0.417 0 0 1 0.428-0.426h0.794z"
                    data-name="Path 30"
                  />
                  <path
                    transform="translate(-23.634 -45.436)"
                    d="m593.15 1182.4h-0.767a0.41 0.41 0 0 1-0.44-0.437q0-0.265 0-0.529a0.41 0.41 0 0 1 0.418-0.434q0.794-0.011 1.587 0a0.409 0.409 0 0 1 0.42 0.433v0.529a0.411 0.411 0 0 1-0.438 0.439c-0.26 1e-3 -0.52-1e-3 -0.78-1e-3z"
                    data-name="Path 31"
                  />
                </g>
              </svg>

              <timeago
                :datetime="job.created_at"
                :locale="$i18n.locale"
              ></timeago>
            </span>
          </div>
        </div>
        <div class="proj-head-details summary-desktop">
          <div class="details-card">
            <div class="column1">
              <h4>{{ $t("Company") }}</h4>
              <!-- <h4>Availability</h4> -->
              <h4 class="color-danger">{{ $t("Deadline") }}</h4>
            </div>
            <div class="column2">
              <h4 class="">{{ job.company }}</h4>
              <!-- <h4 class="">Full Time</h4> -->
              <h4 class="color-danger">{{ formatDate(job.deadline) }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="top-banner-mobile">
      <div class="card-icon">
        <div class="circle c1">
          <div class="circle c2">
            <img
              :src="job.companyLogo"
              class="img-profile rounded-circle avatar"
              width="100"
              alt="company Logo"
            />
          </div>
        </div>
      </div>

      <div class="proj-title text-center">
        <h2>{{ job.title }}</h2>

        <div class="address">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.866"
              height="20.981"
              viewBox="0 0 14.866 20.981"
            >
              <path
                id="Path_3"
                data-name="Path 3"
                d="M881.6,490c.262.033.527.052.786.1a7.34,7.34,0,0,1,5.756,4.694,7,7,0,0,1,.484,2.388,6.286,6.286,0,0,1-.4,2.285,21.75,21.75,0,0,1-1.766,3.765,64.735,64.735,0,0,1-4.334,6.536c-.3.4-.61.8-.921,1.211a.592.592,0,0,1-.061-.057,64.56,64.56,0,0,1-5.519-8.236,20.643,20.643,0,0,1-1.494-3.3,6.63,6.63,0,0,1,.7-5.772,7.243,7.243,0,0,1,5.084-3.5c.243-.046.492-.06.738-.09.05-.006.1-.015.151-.023Zm2.477,7.11a2.88,2.88,0,1,0-2.872,2.876A2.879,2.879,0,0,0,884.073,497.11Z"
                transform="translate(-873.76 -490)"
                opacity="0.8"
              />
            </svg>
            {{ job.city }},
            {{ fortmatCountry(job.country) }}
          </span>

          <span>
            <svg
              class="prg-5"
              xmlns="http://www.w3.org/2000/svg"
              width="23.844"
              height="22.34"
              viewBox="0 0 23.844 22.34"
            >
              <g
                id="Group_36"
                data-name="Group 36"
                transform="translate(-562.27 -1124)"
                opacity="0.8"
              >
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M565.165,1132c0,.476,0,.935,0,1.394a.4.4,0,0,0,.453.454h.981a.388.388,0,0,0,.441-.438q0-.629,0-1.259V1132h4.827v.137c0,.424,0,.848,0,1.272a.39.39,0,0,0,.43.438q.51,0,1.02,0a.385.385,0,0,0,.425-.428c0-.468,0-.935,0-1.415h4.827v.136c0,.428,0,.857,0,1.285a.382.382,0,0,0,.418.422q.523,0,1.047,0a.383.383,0,0,0,.411-.417c0-.472,0-.944,0-1.428.045,0,.082-.009.12-.009.323,0,.645,0,.967,0a1.62,1.62,0,0,1,1.6,1.542c0,.075,0,.15,0,.225q0,3.206,0,6.413a.293.293,0,0,0,.136.27,6.612,6.612,0,0,1,2.842,5.8,6.749,6.749,0,0,1-13.027,2.18.219.219,0,0,0-.248-.161q-4.445.005-8.89,0a1.63,1.63,0,0,1-1.633-1.267,1.561,1.561,0,0,1-.04-.354q0-6.519,0-13.037a1.62,1.62,0,0,1,1.6-1.612c.4,0,.8,0,1.193,0C565.09,1131.991,565.12,1131.995,565.165,1132Zm14.183,19.493a5.54,5.54,0,1,0-5.517-5.345A5.547,5.547,0,0,0,579.347,1151.491Zm2.049-12.014c0-.012,0-.034,0-.056q0-1.106,0-2.213a.919.919,0,0,0-.01-.119,1.072,1.072,0,0,0-1.12-.9q-7.572.011-15.144,0a1.048,1.048,0,0,0-1.124,1.126q0,1.458,0,2.915,0,2.623,0,5.246a1.046,1.046,0,0,0,1.1,1.107h7.513v-1.022c-.243,0-.472,0-.7,0a.414.414,0,0,1-.426-.429c0-.168,0-.336,0-.5a.427.427,0,0,1,.467-.471c.265,0,.53,0,.795,0a.1.1,0,0,0,.12-.089c.065-.211.141-.418.212-.626a.5.5,0,0,0,.014-.078c-.395,0-.779,0-1.163,0a.416.416,0,0,1-.444-.449c0-.15,0-.3,0-.45,0-.345.157-.5.5-.5.437,0,.874,0,1.312,0a.627.627,0,0,1,.488.115c.265-.3.517-.6.776-.888.1-.108.242-.189.123-.367a.154.154,0,0,1-.008-.078q0-.252,0-.5a.419.419,0,0,1,.464-.469c.442,0,.883,0,1.325,0a.78.78,0,0,0,.28-.058,6.7,6.7,0,0,1,3.464-.464C580.61,1139.306,581,1139.4,581.4,1139.477Z"
                  transform="translate(0 -6.364)"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M612.167,1125.589c0,.384,0,.769,0,1.153a.387.387,0,0,1-.428.426q-.205,0-.411,0a.387.387,0,0,1-.423-.431c0-.6,0-1.2,0-1.8,0-.167,0-.336,0-.5a.383.383,0,0,1,.416-.424c.15,0,.3,0,.451,0a.375.375,0,0,1,.4.4C612.169,1124.8,612.167,1125.2,612.167,1125.589Z"
                  transform="translate(-38.74 -0.004)"
                />
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M579.225,1125.586c0,.384,0,.768,0,1.153a.385.385,0,0,1-.428.425c-.141,0-.283,0-.424,0a.374.374,0,0,1-.406-.392q-.007-1.192,0-2.385a.366.366,0,0,1,.387-.383q.245-.008.49,0a.371.371,0,0,1,.38.39C579.227,1124.791,579.225,1125.189,579.225,1125.586Z"
                  transform="translate(-12.501 0)"
                />
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M645.112,1125.591c0,.379,0,.758,0,1.138a.389.389,0,0,1-.432.434c-.145,0-.291,0-.436,0a.376.376,0,0,1-.394-.391q-.006-1.19,0-2.381a.372.372,0,0,1,.385-.386c.163-.006.326,0,.489,0a.374.374,0,0,1,.388.4C645.114,1124.8,645.112,1125.194,645.112,1125.591Z"
                  transform="translate(-64.983 0)"
                />
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M642.794,1196.406a1.165,1.165,0,0,1-2.329.01,1.165,1.165,0,1,1,2.329-.01Z"
                  transform="translate(-62.289 -56.749)"
                />
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M631.172,1188.093a1.3,1.3,0,0,1-.27-.146q-.788-.707-1.565-1.425a.411.411,0,0,1-.03-.611.406.406,0,0,1,.6-.024q.789.715,1.573,1.436a.388.388,0,0,1,.112.456A.517.517,0,0,1,631.172,1188.093Z"
                  transform="translate(-53.304 -49.202)"
                />
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M651.339,1186.822a.686.686,0,0,1,.13-.184q1.216-.97,2.439-1.93a.291.291,0,0,1,.433.043.287.287,0,0,1-.046.432q-1.228.976-2.461,1.946a.273.273,0,0,1-.311.031A.353.353,0,0,1,651.339,1186.822Z"
                  transform="translate(-70.951 -48.297)"
                />
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M608.831,1159.482c.252,0,.5,0,.754,0a.422.422,0,0,1,.457.45c0,.172,0,.344,0,.516a.421.421,0,0,1-.434.436q-.781.006-1.561,0a.419.419,0,0,1-.435-.433c0-.181,0-.362,0-.542a.416.416,0,0,1,.426-.428h.794Z"
                  transform="translate(-36.116 -28.264)"
                />
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M624.5,1159.48c.256,0,.512,0,.767,0a.414.414,0,0,1,.441.438c0,.176,0,.353,0,.529a.414.414,0,0,1-.419.435q-.794.009-1.588,0a.405.405,0,0,1-.419-.394,4.625,4.625,0,0,1,0-.621.4.4,0,0,1,.422-.387C623.967,1159.477,624.231,1159.48,624.5,1159.48Z"
                  transform="translate(-48.591 -28.262)"
                />
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M608.843,1170.28c.256,0,.512,0,.767,0a.416.416,0,0,1,.434.433c0,.176,0,.353,0,.529a.414.414,0,0,1-.418.437q-.8.013-1.6,0a.411.411,0,0,1-.413-.441q0-.258,0-.516a.418.418,0,0,1,.437-.443h.794Z"
                  transform="translate(-36.118 -36.866)"
                />
                <path
                  id="Path_23"
                  data-name="Path 23"
                  d="M577.439,1193.25c-.251,0-.5,0-.754,0a.421.421,0,0,1-.453-.443c0-.172,0-.344,0-.516a.425.425,0,0,1,.453-.445q.767,0,1.535,0a.42.42,0,0,1,.443.439c0,.181,0,.361,0,.542a.41.41,0,0,1-.417.421c-.269,0-.538,0-.807,0Z"
                  transform="translate(-11.12 -54.044)"
                />
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M577.447,1159.48c.256,0,.512,0,.767,0a.416.416,0,0,1,.444.449c0,.172,0,.344,0,.516a.417.417,0,0,1-.43.438q-.787.006-1.574,0a.417.417,0,0,1-.425-.418q-.011-.284,0-.569a.412.412,0,0,1,.425-.415C576.918,1159.477,577.183,1159.48,577.447,1159.48Z"
                  transform="translate(-11.115 -28.262)"
                />
                <path
                  id="Path_25"
                  data-name="Path 25"
                  d="M577.457,1182.446c-.26,0-.52,0-.78,0a.417.417,0,0,1-.445-.435q-.007-.265,0-.529a.414.414,0,0,1,.421-.436q.794-.012,1.588,0a.415.415,0,0,1,.423.445c0,.176,0,.353,0,.529a.412.412,0,0,1-.424.427c-.26,0-.52,0-.781,0Z"
                  transform="translate(-11.119 -45.436)"
                />
                <path
                  id="Path_26"
                  data-name="Path 26"
                  d="M640.2,1159.481c.256,0,.511,0,.767,0a.414.414,0,0,1,.442.437q0,.264,0,.529a.417.417,0,0,1-.444.438q-.767,0-1.534,0a.419.419,0,0,1-.448-.447c0-.177,0-.353,0-.529a.413.413,0,0,1,.424-.428h.794Z"
                  transform="translate(-61.105 -28.263)"
                />
                <path
                  id="Path_27"
                  data-name="Path 27"
                  d="M577.458,1171.684c-.247,0-.494,0-.741,0a.435.435,0,0,1-.481-.485c0-.154,0-.309,0-.463a.421.421,0,0,1,.452-.455q.767,0,1.534,0a.414.414,0,0,1,.444.447q0,.251,0,.5a.42.42,0,0,1-.443.453C577.97,1171.689,577.714,1171.684,577.458,1171.684Z"
                  transform="translate(-11.125 -36.864)"
                />
                <path
                  id="Path_28"
                  data-name="Path 28"
                  d="M593.155,1170.28c.256,0,.511,0,.767,0a.412.412,0,0,1,.444.446c0,.168,0,.335,0,.5a.424.424,0,0,1-.467.455c-.348,0-.7,0-1.045,0-.155,0-.309,0-.463,0a.421.421,0,0,1-.451-.457c0-.172,0-.344,0-.516a.41.41,0,0,1,.433-.43h.781Z"
                  transform="translate(-23.635 -36.865)"
                />
                <path
                  id="Path_29"
                  data-name="Path 29"
                  d="M593.158,1159.481c.256,0,.511,0,.767,0a.412.412,0,0,1,.437.44c0,.177,0,.353,0,.529a.41.41,0,0,1-.42.432q-.794.008-1.588,0a.41.41,0,0,1-.417-.422c0-.19,0-.379,0-.569a.405.405,0,0,1,.413-.41c.269,0,.538,0,.807,0Z"
                  transform="translate(-23.631 -28.262)"
                />
                <path
                  id="Path_30"
                  data-name="Path 30"
                  d="M593.161,1191.847c.251,0,.5,0,.754,0a.418.418,0,0,1,.449.445q0,.258,0,.516a.411.411,0,0,1-.435.442q-.781,0-1.561,0a.407.407,0,0,1-.428-.422c0-.185,0-.37,0-.555a.417.417,0,0,1,.428-.426h.794Z"
                  transform="translate(-23.631 -54.045)"
                />
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M593.148,1182.446c-.256,0-.511,0-.767,0a.41.41,0,0,1-.44-.437q0-.265,0-.529a.41.41,0,0,1,.418-.434q.794-.011,1.587,0a.409.409,0,0,1,.42.433c0,.177,0,.353,0,.529a.411.411,0,0,1-.438.439C593.668,1182.448,593.408,1182.446,593.148,1182.446Z"
                  transform="translate(-23.634 -45.436)"
                />
              </g>
            </svg>
            <timeago
              :datetime="job.created_at"
              :locale="$i18n.locale"
            ></timeago>
          </span>
        </div>
      </div>
    </section>
    <section class="proj-details-mobile">
      <div class="proj-head-details-mobile">
        <div class="details-card-mobile">
          <div class="column1 float-left">
            <h4>{{ $t("Company") }}</h4>
            <!-- <h4>Availability</h4> -->
            <h4 class="color-danger">{{ $t("Deadline") }}</h4>
          </div>
          <div class="column2 float-right">
            <h4 class="">{{ job.company }}</h4>
            <!-- <h4 class="">Full Time</h4> -->
            <h4 class="color-danger">{{ formatDate(job.deadline) }}</h4>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import moment from "moment";
export default {
  name: "JobTopBanner",
  methods: {
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date).format("MM/DD/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  props: {
    job: null,
  },
};
</script>

<style scoped>
.prg-5 {
  margin-right: 5px;
}
.logoDesktop {
  position: absolute !important;
  top: 10px !important;
}
.summary-desktop {
  width: auto !important;
  margin-top: 20px;
  /* margin-left: 100px; */
  margin-left: 150px;
}
.city-country {
  margin-left: 15px !important;
}
.circle {
  border-radius: 100%;
  background: #f2f2f2;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.1) !important;
}

.c2 {
  width: 70px;
  height: 70px;
  border: 2px solid white;
}
body section {
  /* padding: 60px; */
  padding: 15px 0;
  font-family: "Roboto", sans-serif;
}
.top-banner {
  border-bottom: 4px solid var(--first-color);
}

@media (max-width: 770px) {
  body section {
    padding: 0px;
  }
  .logoDesktop {
    position: absolute !important;
    top: 0px !important;
  }
  .top-banner {
    display: none;
  }
  .top-banner-mobile {
    background: url("~@/assets/images/banner-bg-mobile.png");
    margin-top: 0px !important;
    background-repeat: no-repeat;
    background-position: right;
    box-shadow: 5px 5px 20px #f5f5f7;
    background-size: 100% 100%;
    padding-top: 0px !important;
    padding-bottom: 20px;
    height: 180px !important;
  }

  .top-banner-mobile .card-icon .circle {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .top-banner-mobile .card-icon .circle .c2 {
    width: 70px;
    height: 70px;
    margin: 0 auto;
  }
  .top-banner-mobile .card-icon .circle img {
    width: 30px !important;
  }

  .proj-head-details-mobile .details-card-mobile {
    width: 100%;
    margin-top: 20px;
    padding: 5px 25px 25px 55px;
    display: inline-block;
  }
  .proj-details-mobile .proj-head-details-mobile {
    background: #f5f6f7 !important;
    width: 100%;
  }

  .proj-details-mobile .proj-head-details-mobile h4 {
    color: #687486;
  }
  .proj-details-mobile .proj-head-details-mobile h4:nth-child(2) {
    margin-top: 15px;
  }
  .proj-details-mobile .proj-head-details-mobile h4:nth-child(3) {
    margin-top: 15px;
  }
  .top-banner-mobile .proj-title {
    margin-top: 10px !important;
    padding: 1px !important;
    width: 100%;
    position: relative !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    left: 0px !important;
  }
  .top-banner-mobile .proj-title h2 {
    font-size: 18px;
    left: 0px !important;
    text-align: center !important;
  }
  .top-banner-mobile,
  .proj-details-mobile {
    display: block !important;
  }
  .top-banner-mobile,
  .proj-details-mobile {
    display: block !important;
  }
}
.main-content .description .contact-det .info {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.top-banner-mobile,
.proj-details-mobile {
  display: none;
}
@media (max-width: 375px) {
  .top-banner-mobile .proj-title h2 {
    font-size: 18px !important;
  }
  .top-banner-mobile .proj-title {
    font-size: 14px;
  }
  .proj-head-details-mobile .details-card-mobile {
    width: 100%;
    margin-top: 20px;
    padding: 5px 25px 25px 25px;
    display: inline-block;
  }
}
.card {
  align-items: center;
}
.card-icon {
  width: 100%;
}

.circle {
  border-radius: 50%;
  background: #f2f2f2;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c2 {
  width: 110px;
  height: 110px;
  border: 2px solid white;
}
.card a {
  margin-top: 50px !important;
  height: 300px;
}
.top-banner {
  background: url("~@/assets/images/banner-bg.png");
  margin-top: 0px;
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 5px 5px 20px #f5f5f7;
  padding-left: 50px;
  padding-right: 50px;
  background-size: 100% 100%;
}
.projrow {
  display: inline-block;
  width: 100%;
  max-width: 990px;
}

.projrow .proj-logo,
.proj-title,
.proj-head-details {
  float: left;
}

.projrow .proj-title {
  width: 30%;
}
.projrow .proj-head-details {
  width: 50%;
}

.top-banner .address {
  margin-top: 10px !important;
}
* {
  margin: 0;
  padding: 0;
}
.proj-logo {
  position: relative;
  width: 50px !important;
  height: 50px !important;
  /* padding: 50px !important; */
}

.proj-title {
  padding-left: 50px;
  margin-top: 30px;
}

.proj-title-desktop {
  justify-content: left !important;
  align-items: left !important;
  padding-left: 50px;
  margin-top: 30px;
  margin-left: 30px;
  width: 45% !important;
  text-align: left !important;
}
.details-card {
  background: rgba(255, 255, 255, 0.8);
  width: 300px;
  border-radius: 10px !important;
  height: 70%;
  padding: 35px 20px;
  display: inline-block;
}
.proj-title h2 {
  position: relative;
  font-weight: bold !important;
  font-size: 25px !important;
  left: 20px;
}

.column1 h4,
.column2 h4 {
  padding-bottom: 5px;
}
.proj-title svg {
  height: 13px;
  width: 13px;
}
.proj-title .address span:nth-child(2) {
  margin-left: 23px !important;
}
.date {
  margin-right: 3px !important;
}
.proj-title .address {
  margin-top: 20px !important;
}
.color-danger {
  color: #fe2828 !important;
}

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}

.details-card .column1 {
  float: left;
}
.details-card .column2 {
  float: right;
}
.details-card h4:nth-child(2) {
  margin-top: 15px;
}
.details-card h4:nth-child(3) {
  margin-top: 15px;
}
</style>

