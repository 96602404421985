<template>
  <div>
    <div class="bg-white loginBackground">
      <div class="container">
        <div
          class="row justify-content-center align-items-center d-flex vh-100"
        >
          <div class="col-md-8 mx-auto loginbox pb-3 pr-5 pl-5">
            <div class="osahan-login py-4">
              <div class="text-center mb-4">
                <h5 class="font-weight-bold mt-3">{{ $t('join') }} Bantaba</h5>
                <p class="text-muted">
                  {{ $t('contributeToThedev') }}
                </p>
              </div>
              <form action="#" @submit.prevent="submit">
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1">{{ $t('name') }}</label>
                      <div class="position-relative icon-form-control">
                        <i class="feather-user position-absolute"></i>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('name')"
                          required
                          v-model="form.name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1">{{ $t('username') }}</label>
                      <div class="position-relative icon-form-control">
                        <i class="feather-user position-absolute"></i>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('username')"
                          required
                          v-model="form.username"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="mb-1">Email</label>
                      <div class="position-relative icon-form-control">
                        <i class="feather-at-sign position-absolute"></i>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="email"
                          required
                          v-model="form.email"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="mb-1">{{ $t('password') }}</label>
                      <div class="position-relative icon-form-control">
                        <i class="feather-unlock position-absolute"></i>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="......"
                          required
                          v-model="form.password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="mb-1"
                        >{{ $t('countryOfResidence') }}
                      </label>
                      <div class="position-relative icon-form-control">
                        <select
                          class="custom-select my-1 mr-sm-2"
                          v-model="form.CountryofResidence"
                          required
                        >
                          <option selected>{{ $t('choose') }}...</option>
                          <option
                            :value="country"
                            v-for="(country, index) in countries"
                            :key="index"
                            >{{ country }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="mb-1"
                        >{{ $t('AfricanCountryofOrigin') }}
                      </label>
                      <div class="position-relative icon-form-control">
                        <select
                          class="custom-select my-1 mr-sm-2"
                          v-model="form.AfricanCountryofOrigin"
                          required
                        >
                          <option selected>{{ $t('choose') }}...</option>
                          <option
                            :value="country"
                            v-for="(country, index) in countries"
                            :key="index"
                            >{{ country }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label id="positionLabel" class="form-label"
                      >{{ $t('interrestedToSupport') }}

                      <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                      <select
                        class="custom-select my-1 mr-sm-2"
                        v-model="form.supportStartup"
                        required
                        @change="setSupport"
                      >
                        <option selected>{{ $t('choose') }}...</option>
                        <option value="1">{{ $t('yes') }}</option>
                        <option value="0">{{ $t('no') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4" v-if="showSupport">
                    <label id="positionLabel" class="form-label">
                      {{ $t('TypeOfSupport') }}
                      <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                      <select
                        class="custom-select my-1 mr-sm-2"
                        v-model="interrested"
                      >
                        <option selected>{{ $t('choose') }}...</option>
                        <option value="Invest">{{ $t('Invest') }}</option>
                        <option value="Consulting">{{
                          $t('Consulting')
                        }}</option>
                        <option value="Mentoring">{{ $t('Mentoring') }}</option>
                        <option value="Coaching">{{ $t('Coaching') }}</option>
                        <option value="Advisory">{{ $t('Advisory') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  :disabled="loading"
                  type="submit"
                  class="btn btn-primary btn-block text-uppercase"
                >
                  <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                  {{ loading ? '' : $t('JoinNow') }}
                </button>
                <!-- <div class="text-center mt-3 border-bottom pb-3">
                  <p class="small text-muted">Or login with</p>
                  <div class="row">
                    <div class="col-4">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-instagram btn-block"
                      >
                        <i class="feather-instagram"></i> Instagram
                      </button>
                    </div>
                    <div class="col-4">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-linkedin btn-block"
                      >
                        <i class="feather-linkedin"></i> Linkedin
                      </button>
                    </div>
                    <div class="col-4">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-facebook btn-block"
                      >
                        <i class="feather-facebook"></i> Facebook
                      </button>
                    </div>
                  </div>
                </div> -->
                <div class="py-3 d-flex align-item-center">
                  <a
                    :href="
                      `/${$i18n.locale}/${this.$i18n.t(
                        'routes.forgotPassword'
                      )}`
                    "
                  >
                    {{ $t('forgotPassword') }}</a
                  >
                  <span class="ml-auto">
                    {{ $t('newToBantaba') }}
                    <a
                      class="font-weight-bold"
                      :href="
                        `/${$i18n.locale}/${this.$i18n.t('routes.register')}`
                      "
                      >{{ $t('joinNow') }}</a
                    ></span
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import i18n from '../../i18n';
import store from '../../store/';
import APIURLS from '../../constant';
import moment from 'moment';
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { mapGetters, mapActions } from 'vuex';
export default {
  beforeRouteEnter: (to, from, next) => {
    if (store.getters.authUser) {
      return next(`/${i18n.locale}/${i18n.t('routes.dashboard')}`);
    }
    next();
  },
  mounted() {
    var countries = require('i18n-iso-countries');
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
    this.countries = countries.getNames(i18n.locale, { select: 'official' });
  },
  computed: {
    ...mapGetters(['getSectorstList']),
    ...mapActions(['loadSectorsList']),
  },
  data() {
    return {
      countries: null,
      result: null,
      diaspora: true,
      startup: false,
      corporations: false,
      showSupport: false,
      form: {
        name: '',
        email: '',
        password: '',
      },
      error: null,
      success: null,
      loading: false,
    };
  },
  methods: {
    setSupport() {
      if (this.form.supportStartup === '1') {
        this.showSupport = true;
      } else {
        this.showSupport = false;
      }
    },
    selectDiaspora() {
      this.diaspora = true;
      this.startup = false;
      this.corporations = false;
    },
    selectStartup() {
      this.diaspora = false;
      this.startup = true;
      this.corporations = false;
    },
    selectCorporations() {
      this.diaspora = false;
      this.startup = false;
      this.corporations = true;
    },
    submit() {
      this.loading = true;
      const data = {
        name: this.form.name,
        email: this.form.email,
        password: this.form.password,
      };
      Axios.post(APIURLS.AUTH + 'auth/register', data)
        .then((response) => {
          this.loading = false;
          this.form.email = '';
          this.form.password = '';
          localStorage.setItem('auth', JSON.stringify(response.data));
          this.$root.auth = response.data;
          //this.$router.push('/');
        })
        .catch((error) => {
          console.log('error', error);
          this.loading = false;
          this.$noty.error('Informations Erronées!');
          this.error = 'Informations Erronées!';
        });
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Register',
};
</script>

<style>
.showform {
  display: hide;
}
.hideform {
  display: none;
}
</style>
