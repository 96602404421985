<template>
  <li
    v-if="authUser"
    class="nav-item dropdown no-arrow mx-1 osahan-list-dropdown"
  >
    <a
      class="nav-link dropdown-toggle"
      href="#"
      role="button"
      @click="markReadNotifs"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="feather-inbox"></i>

      <span class="badge badge-warning badge-counter" v-if="newNotifs.length">{{
        newNotifs.length
      }}</span>
    </a>

    <div
      v-if="notifications.length"
      class="
        dropdown-list dropdown-menu dropdown-menu-right
        shadow-sm
        notifications
        notification-panel
      "
    >
      <div class="cust-title p-3">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0">Notifications</h5>
          <a
            v-if="newNotifs.length"
            class="badge badge-success badge-card notif-badge"
            href="#"
            >{{ newNotifs.length }}</a
          >
        </div>
      </div>
      <a
        v-for="(notification, index) in notifications.slice(0, 5)"
        :key="index"
        :class="notification.viewed ? 'viewedNotif' : ''"
        class="dropdown-item d-flex align-items-center"
        @click="getRoute(notification)"
      >
        <div class="dropdown-list-image mr-3">
          <img
            class="rounded-circle"
            :src="notification.sender_avatar"
            alt=""
          />
        </div>
        <div class="font-weight-bold overflow-hidden">
          <div class="text-truncate">
            {{ notification.name }}
          </div>
          <div class="small text-gray-900">
            {{ $t(notification.text) }} ·
            <timeago
              :datetime="notification.created_at"
              :locale="$i18n.locale"
            ></timeago>
          </div>
        </div>
      </a>
      <a
        class="
          dropdown-item
          text-center
          small
          font-weight-bold
          text-gray-900
          all-notifs-btn
        "
        :href="`/${$i18n.locale}/notifications`"
        >{{ $t("allNotifications") }}</a
      >
    </div>
  </li>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
export default {
  watch: {
    async $route(to, from) {
      this.getNotifs();
    },
  },
  async mounted() {
    this.getNotifs();
  },
  computed: {
    ...mapGetters(["authUser", "getUserNotifications", "getMsgNewNotifs"]),
    path() {
      return this.$route.path;
    },
  },
  data() {
    return {
      notifications: {},
      newNotifs: 0,
      form: {},
      isShown: false,
    };
  },
  sockets: {
    async private_chat(data) {
      // await this.getMsgNewNotifs();
    },
    new_notification(data) {
    },
  },
  methods: {
    ...mapActions([
      "loadUserNotifications",
      "setViewedNotif",
      "destroyChatBox",
      "getUserById",
      "openChatBoxWithUser",
      "setAllNotifsToViewed",
    ]),
    async markReadNotifs() {
      let user_id = this.authUser.id;
      this.newNotifs = [];
      await this.setAllNotifsToViewed({ user_id });
    },
    async getRoute(notification) {
      let url = "";
      let user_id = this.authUser.id;
      const notif_id = notification.id;
      if (notification.type === "request") {
        url =
          "/" +
          this.$i18n.locale +
          "/" +
          this.$i18n.t("routes.network") +
          "/" +
          this.$i18n.t("routes.pendingRequests");
      } else if (notification.type === "accept") {
        url =
          "/" +
          this.$i18n.locale +
          "/" +
          this.$i18n.t("routes.network") +
          "/connections";
      } else if (notification.type === "follow") {
        url = "/" + this.$i18n.locale + "/" + this.$i18n.t("routes.followers");
      } else if (notification.type === "message") {
        let user_id = notification.sender_id;
        let user = await this.getUserById({ user_id });
        await this.openChatBoxWithUser(user);
      } else {
        url = "/" + this.$i18n.locale + "/notifications";
      }
      await this.setViewedNotif({ user_id, notif_id });
      // this.$router.push(url).catch(err => {});
      if (this.$route.path != url) {
          this.$router.push(url);
      }

    },
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.newNotifs = this.getUserNotifications;
      type = "all";
      await this.loadUserNotifications({ user_id, type });

      this.notifications = this.getUserNotifications.filter((notif) => {
        return notif.type === "message";
      });
    },
    showDialog: function () {
      this.isShown = !this.isShown;
    },
  },
  name: "MessagesNotifications",
};
</script>
<style>
.notif-badge {
  padding: auto;
  background-color: #349e4d;
  line-height: 1.3;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.all-notifs-btn {
  background-color: #349e4d;
  border: 5px solid #fff !important;
  border-radius: 12px !important;
  color: #ffffff;
}

.all-notifs-btn:hover {
  background-color: rgba(32, 124, 70, 0.8) !important;
  border: 5px solid #ffffff;
  border-radius: 12px;
  color: #fff;
}
.cust-title {
  border-bottom: 1px solid #ffffff;
}

.notification-panel {
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  background: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 10px 1px 20px 0px #8b8bb736 !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>

