import axios from 'axios';
import i18n from '../../i18n';
import APIURLS from '../../constant';
import currentUser from './auth';

const state = {
  users: [],
  userConnections: {},
  connectionsCounts: {},
  connected: {},
  blocked: {},
  mentors: {},
  consultants: {},
  diaspora: [],
  savedDiaspora: [],
  followers: {},
  followersCount: 0,
  interrested: {},
  interrestedCount: 0,
  sent: {},
  received: {},
  feeds: [],
  comments: [],
  connectionStatus: '',
  notifications: [],
  userNewNotifsCount: 0,
  newNotifications: [],
  msgNotifs: {},
  searchFilters: {
    countries: [],
    areas: [],
    types: [],
    years: [],
  },

  startupSearchFilters: {
    countries: [],
    sectors: [],
    stages: [],
  },
  startupsOptions: {
    countries: {
      description: 'Location',
      contents: [],
      code: 'countries',
    },
    sectors: {
      description: 'Sectors',
      contents: [],
      code: 'sectors',
    },
  },
  savedStartupsOptions: {},
  diasporaLimit: 16,
  startupsLimit: 15,
  diasporaPage: 1,
  startupsPage: 1,
  diasporaTotalCount: 0,
  startupsTotalCount: 0,
  showPagination: true,
  showLoadMore: true,
  showStartupsLoadMore: true,
  showDiasporaLoadMore: true,
  lastPage: false,
  filterCode: '',
  userFollowing: [],
  savedStartups: [],
  userBrowsingPosts: [],
  userBrowsingDigest: [],
  isNewPost: false,
};

const getters = {
  getIsNewPost: (state) => {
    return state.isNewPost;
  },
  getUsers: (state) => {
    return state.users;
  },
  getLastPage: (state) => {
    return state.lastPage;
  },
  getFilterCode: (state) => {
    return state.filterCode;
  },
  getUserBrowsingPosts: (state) => {
    return state.userBrowsingPosts;
  },
  getUserBrowsingBantabaDigest: (state) => {
    return state.userBrowsingDigest;
  },
  getSavedStartupsOptions: (state) => {
    return state.savedStartupsOptions;
  },
  getStartupsOptions: (state) => {
    return state.startupsOptions;
  },
  getShowPagination: (state) => {
    return state.showPagination;
  },
  getShowLoadMore: (state) => {
    return state.showLoadMore;
  },
  getShowStartupLoadMore: (state) => {
    return state.showStartupsLoadMore;
  },
  getShowDiasporaLoadMore: (state) => {
    return state.showDiasporaLoadMore;
  },
  getDiasporaLimit: (state) => {
    return state.diasporaLimit;
  },
  getStartupsLimit: (state) => {
    return state.startupsLimit;
  },
  getDiasporaPage: (state) => {
    return state.diasporaPage;
  },
  getStartupsPage: (state) => {
    return state.startupsPage;
  },
  getDiasporaTotalCount: (state) => {
    return state.diasporaTotalCount;
  },
  getStartupsTotalCount: (state) => {
    return state.startupsTotalCount;
  },
  getSearchFilters: (state) => {
    return state.searchFilters;
  },
  getStartupSearchFilters: (state) => {
    return state.startupSearchFilters;
  },
  getConnections: (state) => {
    return state.userConnections;
  },
  getConnectionsCounts: (state) => {
    return state.connectionsCounts;
  },
  getConnectionStatus: (state) => {
    return state.connectionStatus;
  },
  getDiaspora: (state) => {
    return state.diaspora;
  },
  getSavedDiaspora: (state) => {
    return state.savedDiaspora;
  },
  getSavedStartups: (state) => {
    return state.savedStartups;
  },
  getMentors: (state) => {
    return state.mentors;
  },
  getConsultants: (state) => {
    return state.consultants;
  },
  getFollowers: (state) => {
    return state.followers;
  },
  getUserFollowing: (state) => {
    return state.userFollowing;
  },
  getFollowersCount: (state) => {
    return state.followersCount;
  },
  getInterrested: (state) => {
    return state.interrested;
  },
  getInterrestedCounts: (state) => {
    return state.interrestedCount;
  },
  getConnected: (state) => {
    return state.connected;
  },
  getSent: (state) => {
    return state.sent;
  },
  getReceived: (state) => {
    return state.received;
  },
  getBlocked: (state) => {
    return state.blocked;
  },

  getFeeds: (state) => {
    return state.feeds;
  },
  getUserNotifications: (state) => {
    return state.notifications;
  },
  getUserNewNotifications: (state) => {
    return state.newNotifications;
  },

  getUserNewNotifsCount: (state) => {
    return state.userNewNotifsCount;
  },


  getMsgNewNotifs: (state) => {
    return state.msgNotifs
  }
};

const actions = {

  loadUsers({ commit }, user_id) {

    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.CONNECTIONS + 'all/skip/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setUsers', data.users);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadMoreUsers({ commit }, user_id) {
    // alert(user_id);
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.CONNECTIONS + 'all/skip/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setUsers', data.users);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadDiaspora({ commit }, { user_id, page, limit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.USERS + 'diaspora/' + user_id + '/' + page + '/' + limit)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setDiaspora', data.diaspora);
            commit('setDiasporaTotalCount', data.totalCount);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadMentors({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.USERS + 'mentors/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setMentors', data.mentors);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadConsultants({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.USERS + 'consultants/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setConsultants', data.consultants);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadFollowers({ commit }, startup_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.FOLLOWERS + 'startup/' + startup_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setFollowers', data.followers);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadUserFollowingStartups({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.FOLLOWERS + 'user/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setUserFollowing', data.followers);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadFollowersCount({ commit }, startup_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.FOLLOWERS + 'startup/' + startup_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setFollowersCounts', data.count);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadInterrested({ commit }, startup_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.INTERRESTED + 'startup/' + startup_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setInterrested', data.interrested);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadInterrestedCount({ commit }, startup_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.INTERRESTED + 'startup/' + startup_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setInterrestedCounts', data.count);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadConnections({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.CONNECTIONS + 'connected/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setConnected', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadReqested({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.CONNECTIONS + 'requested/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setSent', data.sent);
            commit('setReceived', data.received);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadBlocked({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.CONNECTIONS + 'blocked/' + user_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setBlocked', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadConnCounts({ commit }) {
    return new Promise((resolve, reject) => {
      const user_id = currentUser.state.user.id;
      axios
        .get(APIURLS.CONNECTIONS + user_id + '/counts')
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setconnCounts', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  loadUserNotifications({ commit }, { user_id, type }) {

    return new Promise((resolve, reject) => {
      // console.log("url = ", APIURLS.NOTIFICATIONS + 'user/' + user_id + '/' + type)
      axios
        .get(APIURLS.NOTIFICATIONS + 'user/' + user_id + '/' + type)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setUserNotifications', data.notifications);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },


  loadUserLatestNotifications({ commit }, { user_id, take }) {

    return new Promise((resolve, reject) => {
      // console.log("url = ", APIURLS.NOTIFICATIONS + 'user/' + user_id + '/' + type)
      axios
        .get(APIURLS.NOTIFICATIONS + 'user/' + user_id + '/' + take)
        .then(({ data, status }) => {
          if (status === 200) {

            console.log('data.notifications', data.notifications);
            commit('setUserNotifications', data.notifications);
            commit('setUserNewNotificationsCount', data.count);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  loadUserMsgNotifs({ commit }, { user_id }) {

    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.NOTIFICATIONS + 'user/' + user_id + '/new-messages')
        .then(({ data, status }) => {
          if (status === 200) {
            commit('setUserMsgNotifs', data.notifications);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  setAllNotifsToViewed({ commit, getters }, { user_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.NOTIFICATIONS + 'user/' + user_id + '/mark-all')
        .then(({ data, status }) => {
          //console.log('setAllNotifsToViewed', data);
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  setViewedNotif({ commit }, { user_id, notif_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURLS.NOTIFICATIONS +
          'user/' +
          user_id +
          '/notif/' +
          notif_id +
          '/set-viewed'
        )
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  loadFeeds({ commit, getters }) {
    return new Promise((resolve, reject) => {
      const user_id = currentUser.state.user.id;
      axios.get(APIURLS.FEEDS + user_id + '/' + getters.getUserLang).then(({ data, status }) => {
        if (status === 200) {
          commit('setFeeds', data.feeds);
          resolve(true);
        }
      })
        .catch(error => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  // loadUserBrowsingPosts({ commit }) {
  //   state.userBrowsingPosts = posts;
  // },
  // setUserBrowsingBantabaDigest: (state, posts) => {
  //   state.userBrowsingDigest = posts;
  // },

  createFeed({ commit }, post) {
    console.log({ postFromStore: post })
    return new Promise((resolve, reject) => {
      const user_id = currentUser.state.user.id;
      post.user_id = user_id;

      axios
        .post(APIURLS.FEEDS + 'create', post)
        .then(({ data, status }) => {
          if (status === 201 || status === 201) {
            commit('pushFeeds', data.feed);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  // deleteFeed({ commit }, { feed_id, user_id, index }) {
  deleteFeed({ commit }, { feed_id, user_id }) {
    return new Promise((resolve, reject) => {
      let url = APIURLS.FEEDS + 'delete/' + feed_id + '/user/' + user_id;
      axios
        .get(url)
        .then(({ data, status }) => {
          if (status === 200) {
            // commit('deleteFeed', index);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  deleteNotif({ commit }, { notif_id }) {
    const token = currentUser.state.user.token;
    const user_id = currentUser.state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.NOTIFICATIONS +
          'user/' +
          user_id +
          '/notif/' +
          notif_id +
          '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('deleteNotif', notif_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  deleteAllNotif({ commit }, { user_id }) {
    const token = currentUser.state.user.token;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.NOTIFICATIONS + 'clear-all/user/' + user_id,
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('setUserNotifications', []);
            commit('setUserNewNotificationsCount', 0);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadMoreDiaspora({ commit, getters }, { data }) {
    const token = currentUser.state.user.token;
    const user_id = currentUser.state.user.id;
    // alert(getters.getUserLang)
    data.user_id = user_id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.CONNECTIONS + 'loadmore/' + user_id + '/page/' + data.page + '/' + data.limit + '/' + getters.getUserLang,
        headers: { 'auth-token': token },
        data,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            commit('setDiaspora', [
              ...getters.getDiaspora,
              ...response.data.diaspora,
            ]);
            commit('setDiasporaTotalCount', response.data.totalCount);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadMoreStartups({ commit, getters }, { data }) {
    const token = currentUser.state.user.token;
    const user_id = currentUser.state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.STARTUP + 'filter',
        headers: { 'auth-token': token },
        data,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            commit('setStartups', [
              ...getters.getStartups,
              ...response.data.startups,
            ]);
            commit('setStartupsTotalCount', response.data.totalCount);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  searchWithFilters({ commit, getters }, { data }) {
    const token = currentUser.state.user.token;
    const user_id = currentUser.state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.USERS + 'filter',
        headers: { 'auth-token': token },
        data,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            commit('setDiaspora', response.data.diaspora);
            if (response.data.diaspora.length < getters.getDiasporaLimit) {
              if (getters.getLastPage) {
                commit('setShowPagination', true);
              } else {
                commit('setShowPagination', false);
              }
            } else {
              commit('setShowPagination', true);
            }
            commit('setDiasporaTotalCount', response.data.totalCount);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  getStartupOptionField({ commit, getters }, field) {
    return getters.getStartupsOptions[field];
  },

  searchStartupsWithFilters({ commit, getters }, { data }) {
    const token = currentUser.state.user.token;
    const user_id = currentUser.state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.STARTUP + 'filter',
        headers: { 'auth-token': token },
        data,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            commit('setStartups', response.data.startups);
            commit('setStartupsTotalCount', response.data.totalCount);
            // if (response.data.diaspora.length < getters.getDiasporaLimit) {
            //   if (getters.getLastPage) {
            //     commit('setShowPagination', true)
            //   } else {
            //     commit('setShowPagination', false)
            //   }

            // } else {
            //   commit('setShowPagination', true)
            // }

            //commit('setDiasporaTotalCount', response.data.totalCount)
            //localStorage.
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  sendConnectionRequest({ commit, getters }, { data }) {
    const token = currentUser.state.user.token;
    const user_id = currentUser.state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.CONNECTIONS + 'request',
        headers: { 'auth-token': token },
        data,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createComment({ commit }, comment) {
    return new Promise((resolve, reject) => {
      const user_id = currentUser.state.user.id;
      // /comment.user_id = user_id;
      axios
        .post(APIURLS.COMMENTS + 'feeds/create', comment)
        .then(({ data, status }) => {
          if (status === 201 || status === 201) {
            commit('pushComments', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });

    // http://localhost:3010/comments/feeds/create
  },
  deleteComment({ commit }, { comment_id, user_id }) {
    return new Promise((resolve, reject) => {
      let url = APIURLS.COMMENTS + comment_id + '/user/' + user_id + '/feeds/delete';
      axios
        .get(url)
        .then(({ data, status }) => {
          if (status === 200) {
            // commit('deleteFeed', index);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  loadComments({ commit }, feedId) {
    return new Promise((resolve, reject) => {
      // comments/feeds/161
      axios
        .get(APIURLS.COMMENTS + 'feeds/' + feedId)
        .then(({ data, status }) => {

          if (status === 200) {
            // commit('pushComments', data);
            // resolve(true);
            // return data;
            // commit('setFeeds', data.feeds);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },
  likeAFeed({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.LIKES + 'LikeFeed', data)
        .then(({ data, status }) => {

          if (status === 201 || status === 201) {
            // commit('pushComments', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });

    // http://localhost:3010/comments/feeds/create
  },
  dislikeAFeed({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.LIKES + 'user/' + data.user_id + '/feeds/' + data.feed_id + '/delete', data)
        .then(({ data, status }) => {

          if (status === 201 || status === 201) {
            // commit('pushComments', data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });

    // http://localhost:3010/comments/feeds/create
  },
  loadASingleUser({ commit }, userId) {
    // http://localhost:3010/comments/feeds/create

    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.USERS + 'single-user/' + userId)
        .then(({ data, status }) => {

          // if (status === 201 || status === 201) {
          // commit('pushComments', data);
          resolve(data.user);
          return data.user
          // }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },


}

const mutations = {
  setIsNewPost: (state, label) => {
    state.isNewPost = label;
  },
  setUsers: (state, users) => {
    state.users = users;
  },
  setSearchFilters: (state, { field, filters }) => {
    state.searchFilters[field] = filters;
  },
  setSavedStartupsOptions: (state, options) => {
    state.savedStartupsOptions = options;
  },
  setStartupsOptions: (state, { field, contents }) => {
    state.startupsOptions[field].contents = contents;
  },

  setStartupSearchFilters: (state, { field, filters }) => {
    state.startupSearchFilters[field] = filters;
  },
  setNewStartupSearchFilters: (state, options) => {
    state.searchFilters = options;
  },
  resetStartupSearchFilters: (state) => {
    state.startupSearchFilters = {
      countries: [],
      sectors: [],
      stages: [],
    };
  },
  setConnected: (state, connected) => {
    state.connected = connected;
  },

  setShowPagination: (state, value) => {
    state.showPagination = value;
  },

  setShowLoadMore: (state, value) => {
    state.showLoadMore = value;
  },
  setShowStartupLoadMore: (state, value) => {
    state.showStartupsLoadMore = value;
  },
  setShowDiasporaLoadMore: (state, value) => {
    state.showDiasporaLoadMore = value;
  },
  resetStartupsOptions: (state) => {
    state.startupsOptions = {
      countries: {
        description: 'Location',
        contents: [],
        code: 'countries',
      },
      sectors: {
        description: 'Sectors',
        contents: [],
        code: 'sectors',
      },
    };
  },

  setDiasporaLimit: (state, limit) => {
    state.diasporaLimit = limit;
  },
  setStartupsLimit: (state, limit) => {
    state.startupsLimit = limit;
  },

  setDiasporaPage: (state, page) => {
    state.diasporaPage = page;
  },

  setStartupsPage: (state, page) => {
    state.startupsPage = page;
  },

  setConnectionStatus: (state, { status }) => {
    state.connectionStatus = status;
  },
  setDiaspora: (state, diaspora) => {
    state.diaspora = diaspora;
  },
  setDiasporaTotalCount: (state, count) => {
    state.diasporaTotalCount = count;
  },
  setStartupsTotalCount: (state, count) => {
    state.startupsTotalCount = count;
  },
  setLastPage: (state, value) => {
    state.lastPage = value;
  },
  setFilterCode: (state, value) => {
    state.filterCode = value;
  },
  setSavedDiaspora: (state, diaspora) => {
    state.savedDiaspora = diaspora;
  },
  setSavedStartups: (state, startups) => {
    state.savedStartups = startups;
  },
  setMentors: (state, mentors) => {
    state.mentors = mentors;
  },
  setUserBrowsingPosts: (state, posts) => {
    state.userBrowsingPosts = posts;
  },
  setUserBrowsingBantabaDigest: (state, posts) => {
    state.userBrowsingDigest = posts;
  },
  setConsultants: (state, consultants) => {
    state.consultants = consultants;
  },
  setFollowers: (state, followers) => {
    state.followers = followers;
  },
  setUserFollowing: (state, followers) => {
    state.userFollowing = followers;
  },
  setFollowersCounts: (state, count) => {
    state.followersCount = count;
  },
  increaseFollowersCounts: (state, count) => {
    state.followersCount = count + 1;
  },
  decreaseFollowersCounts: (state, count) => {
    state.followersCount = count - 1;
  },
  setInterrested: (state, interrested) => {
    state.interrested = interrested;
  },
  setInterrestedCounts: (state, count) => {
    state.interrestedCount = count;
  },
  setconnCounts: (state, counts) => {
    state.connectionsCounts = counts;
  },
  increaseConnected: (state) => {
    state.connectionsCounts.connected++;
  },
  decreaseConnected: (state) => {
    state.connectionsCounts.connected--;
  },
  increaseSentRequests: (state) => {
    state.connectionsCounts.sentRequests++;
  },
  decreaseSentRequests: (state) => {
    state.connectionsCounts.sentRequests--;
  },
  increaseReceivedRequests: (state) => {
    state.connectionsCounts.receivedRequests++;
  },
  decreaseReceivedRequests: (state) => {
    state.connectionsCounts.receivedRequests--;
  },
  increaseBlocked: (state) => {
    state.connectionsCounts.blocked++;
  },
  decreaseBlocked: (state) => {
    state.connectionsCounts.blocked--;
  },
  setConnectionsCounts: (state, connectionsCounts) => {
    state.connectionsCounts = connectionsCounts;
  },
  setSent: (state, sent) => {
    state.sent = sent;
  },
  setReceived: (state, received) => {
    state.received = received;
  },
  setBlocked: (state, blocked) => {
    state.blocked = blocked;
  },
  setFeeds: (state, data) => {
    state.feeds = data;
  },
  setUserNotifications: (state, notifications) => {
    state.notifications = notifications;
  },
  setUserNewNotificationsCount: (state, count) => {
    state.userNewNotifsCount = count;
  },
  setUserNewNotifications: (state, notifications) => {
    state.newNotifications = notifications;
  },

  setUserMsgNotifs: (state, notifications) => {
    state.msgNotifs = notifications;
  },
  pushFeeds: (state, feed) => {
    state.feeds.unshift(feed);
  },
  deleteFeed: (state, index) => {
    state.feeds.splice(index, 1);
  },
  pushComments: (state, comment) => {
    state.comments.unshift(comment);
  },
  deleteNotif: (state, notif_id) => {
    const index = state.notifications.findIndex(
      (notif) => notif.id === notif_id
    );
    state.notifications.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
