<template>
  <div class="placeholder">
    <div v-if="news">
      <!-- <div class="py-3">
        <form class="job-search" v-if="motrerSearch">
          <div class="input-group">
            <vue-typeahead-bootstrap
              class="col-12"
              v-model="query"
              :data="allNews"
              :serializer="(item) => item.login"
              highlightClass="special-highlight-class"
              @hit="selectedNews = $event"
              :minMatchingChars="3"
              :autocomplete="false"
              :placeholder="$t('search')"
              inputClass="special-input-class form-control"
              :disabledValues="selectedNews ? [selectedNews.login] : []"
              @input="lookupNews"
            >
            </vue-typeahead-bootstrap>
          </div>
        </form>
      </div> -->
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="">
          <div class="row2 min-layout"
           :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
          >
            <div
              class="col-lg-4 col-md-4"
              v-for="(article, index) in allNews"
              :key="index"
            >
              <NewsCard :article="article" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-info m-4 text-center" role="alert">
        <h4 class="alert-heading pt-4">{{ $t("emptyJobMessage") }}</h4>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import NewsCard from "./NewsCard.vue";
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";
import i18n from "../../i18n";
export default {
  mounted() {
    // this.getNextNews();
  },

  async created() {
    this.savedNews = this.news;
    this.allNews = this.news;
    this.counter = this.perpage;
  },
  computed: {
    ...mapGetters(["authUser", "getInterrestList"]),
    ...mapActions(["loadInterrestList"]),
  },
  data() {
    return {
      tags: {},
      showFilter: false,
      search: {},
      query: "",
      allNews: [],
      summary: "",
      showLike: true,
      showAddComment: true,
      isShow: false,
      selectedNews: null,
      notFound: false,
      savedNews: [],
      sector: "",
      location: "",
      sectors: {},
      pagination: 6,
      page: 1,
      loadingMoreNews: false,
      motrerSearch: true,
      counter: 0,
    };
  },
  methods: {
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    lookupNews: debounce(function () {
      // in practice this action should be debounced
      if (this.query) {
        fetch(`${APIURLS.NEWS}lookupNews/${this.query}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (!data.items.length) {
              this.notFound = true;
            }
            this.allNews = data.items;
          });
      } else {
        this.allNews = this.savedNews;
      }
    }, 500),

    searchNews() {
      if (this.search.location) {
        this.search.location = this.countryCode(this.search.location);
      }
      const data = {
        location: this.search.location,
      };
      if (this.search.location) {
        Axios.post(`${APIURLS.NEWS}searchNews`, data)
          .then(({ data }) => {
            this.allNews = data.news;
          })
          .catch((error) => {
            this.$noty.error(error.data.message);
          });
      }
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    showFilters() {
      this.showFilter = true;
      this.motrerSearch = false;
    },
    clearFilters() {
      this.search.location = null;
      this.allNews = this.savedNews;
    },
    HideFilters() {
      this.showFilter = false;
      this.motrerSearch = true;
      this.search = {};
    },
  },
  name: "NewsList",
  components: {
    NewsCard,
  },
  props: {
    news: {},
    newsCount: Number,
    perpage: Number,
    type: {
      type: String,
      default: "all",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    sent: {
      type: Boolean,
      default: false,
    },
    received: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: "NoConnections",
    },
  },
};
</script>

<style scoped>
.connection {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
}
.connectionTitle {
  height: 25px !important;
}
.small.text-secondary {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  padding-top: 5px;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  padding-top: 10px;
}
.d-flex.align-items-center.p-3.job-item-header {
  padding: 0.5rem 1rem !important;
}
.py-4.px-3.job-item-footer {
  padding: 0.5rem 1rem !important;
  padding: 0.5rem 1rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.small.text-secondary.mt-2.pt-4 {
  height: 100%;
  /* background: orange; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  font-size: 16px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-4.px-3.job-item-footer[data-v-3e63e926] {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
a.btn.btn-primary.btn-sm.float-right.mb-3 {
  margin: 0 !important;
}
.connection {
  box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
  -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.4rem;
  height: 350px;
}
.showNotVerifiedNotfication{
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .container-fluid {
    max-width: 67vw;
  }
  .col-lg-4.col-md-4 {
    padding: 0;
    padding-right: 10px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 10px !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0rem !important;
  }
  .connection {
    box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.4rem;
    height: 350px;
  }
}
@media (max-width: 600px) {
  .col-lg-4.col-md-4 {
    /* border-radius: 10px; */
    width: 100vw;
  }
  .connection {
    box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 1.4rem;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0.4rem !important;
  }
}
</style>

<style>
@media (min-width: 1200px) {
  .min-layout {
    /* min-width: min(78vw, 1140px); */
    /* min-width: 990px; */
    max-width: 990px;
  }
  main.col.col-xl-12.order-xl-2.col-lg-12.order-lg-1.col-md-12.col-sm-12.col-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-width: 67%; */
    /* max-width: min(78vw, 1140px) !important; */
    max-width: 990px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0rem !important;
  }
}
@media (max-width: 600px) {
  .container-fluid {
    min-height: 100vh;
    width: 98vw;
  }
  .pt-4,
  .py-4 {
    padding-top: 0rem !important;
  }
  .row2 {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    justify-content: center;
  }
}
</style>
