<template>
    <transition v-if="isOpened" name="modal">
        <div class="modal-mask error">
            <div class="modal-wrapper" @click="close">
                <div class="modal-container modal-container-small">
                    <div class="modal-header-pd">
                        <div class="btn-modal-header">
                            <button class="modal-default-button-pd" @click="close">
                                X
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body-error-text">
                            <div class="please">
                                {{modalText}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import modal from "@/mixins/modal";
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        modalText: {
            type: String,
            default: "This startup has not uploaded a pitch deck yet, we will notify them that  you have requested for their pitch deck",
        },
    },
    data() {
        return {
            isOpened: true,
        };
    },
    computed: {
        ...mapGetters(["authUser", "getApplyResults", "getUserLang"]),
    },
    name: "ModalError",
    mixins: [modal],
    methods: {
        ...mapActions(["checkStartupCompleted", "getStartupByUserId"]),
        close() {
            this.isOpened = false;
            this.$emit("close");
        },

    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modal";

.error {
    .modal-header-pd {
        display: flex;
        align-items: center;
    }

    .modal-body-error-text {
        text-align: start;
        margin-bottom: 60px;
        font-size: 20px;
    }

    .modal-mask {
        position: fixed;
        z-index: 10002;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container.modal-container-small {
        max-width: 690px;
    }

    .modal-container {
        width: 80%;
        height: auto !important;
        max-height: 500px;
        overflow-y: scroll !important;
        margin: 0 auto;
        padding: 0 !important;
        background-color: rgb(255, 255, 255);
        border-radius: 15px !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .btn-modal-header {
        width: 99%;
    }

    .modal-default-button-pd {
        border: none !important;
        position: relative;
        top: 10px !important;
        width: 30px;
        height: 30px;
        border-radius: 1500px !important;
        background: #ececec !important;
        cursor: pointer !important;
        right: 0;
        float: right;
        font-size: 10px;
        z-index: 10000;
    }

    .modal-default-button-pd img {
        max-width: 100%;
        max-height: 100%;
    }

    .modal-default-button-pd:hover {
        background: #cecccc !important;
    }

    @media screen and (min-width: 375px) and (max-width: 800px) {
        .modal-container {
            width: 380px;
            height: 327px !important;
            max-height: 1000px !important;
            overflow: scroll !important;
        }

        .header-text .text-mobile {
            display: block;
            color: #ffffff;
        }

        .modal-header-pd {
            // background: #e31c39;
            color: #ffffff;
        }


        .modal-container.modal-container-small {
            max-width: 690px;
            width: calc(100% - 40px);
            height: auto !important;
            margin: 0 auto;
        }

        .modal-body-error-text {
            font-size: 16px;
        }
    }

    @media (max-width: 600px) {

        .line,
        .please {
            font-size: 14px;
        }

        .modal-header-pd {
            // background: #e31c39;
        }
    }

    @media screen and (max-width: 375px) {
        .modal-container {
            width: 300px;
            height: 400px !important;
            max-height: 500px !important;
            overflow: scroll !important;
        }

        .modal-container.modal-container-small {
            width: calc(100% - 30px);
            height: auto !important;
        }
    }

    .modal-header-pd h3 {
        margin-top: 0;
        text-align: center !important;
        color: #42b983;
    }

    .modal-default-button-pd {
        border: none !important;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        background: rgb(148, 148, 148);
        float: right;
    }
}
</style>
