<template>
  <label class="check-container">
    <input type="checkbox" checked="checked" required />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px !important;
}
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.check-container input:checked ~ .checkmark {
  background-color: var(--first-color);
}
.checkmark:after {
  content: "✔";
  font-size: 0.8em;
  color: #fff;
  padding-left: 5px;
  position: absolute;
  display: none;
}
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
