<template>
  <button class="expertise-card" :class="active ? 'active' : ''">
    <div class="check-icon-container">
      <img class="check-icon" :src="checkIcon" />
    </div>
    {{ expertise }}
  </button>
</template>

<script>
export default {
  name: "AreaofExpertise",
  props: {
    active: Boolean,
    expertise: String,
    checkIcon: String,
  },
};
</script>
<style scoped>
.expertise-card {
  position: relative;
  width: 100%; 
  /* height: 65px; */
  background-color: white;
  border: 2px solid #dcdcdc;
  border: 2px solid #232327;
  border-radius: 5px;
  display: flex;
  align-items:center;
  justify-content: center;
  border-radius: 25px;
  padding: 5px;
  overflow-wrap: break-word;
  
  padding: 4px 30px;
  /* margin-left: 10px; */
  font-size: 13.5px;
  color: #232327;
}
.check-icon-container {
  position: absolute;
  background-color: #ffffff0f;
  display: none;
  right: 0px;
  top: 4px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  
}
.check-icon {
  width: 12px;
  height: 12px;
  display: none;
 
}

.expertise-card.active {
  border-color: var(--first-color);
  color: var(--first-color);
}
.expertise-card.active > .check-icon-container > .check-icon {
  display: flex;
}

.expertise-card.active > .check-icon-container {
  display: flex;
}

/* .expertise-card:hover {
  border-color: var(--first-color);
  color: var(--first-color);
} */
.expertise-card:hover .check-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .expertise-card {
    position: relative;
    width: 100%;
    height: 65px;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    /* border-radius: 15px; */
    font-size: 13.5px;
    padding: 5px;
    overflow-wrap: break-word;
  }
  .check-icon-container {
  position: absolute;
  background-color: #ffffff0f;
  display: none;
  right: 0px;
  top: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
}
@media only screen and (max-width: 600px) {
  .expertise-card {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    /* border-radius: 15px; */
    font-size: 13.5px;
    padding: 5px;
    /* color: #232327; */
    overflow-wrap: break-word;
  }
}
@media only screen and (max-width: 320px) {
  .expertise-card {
    position: relative;
    width: 100%;
    height: 52px;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    font-size: 13.5px;
    padding: 10px;
    overflow-wrap: break-word;
  }
  #ul-expertise{
    
    /* grid-gap: 4px;
    row-gap: 4px; */
}
}
</style>