<template>
  <div 
  v-if="warningZone"
  class="auto-logout-modal"
  >
  <!--  -->
    <div class="alert alert-warning text-center" role="alert">
      {{ $t("autoLogout") }}
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AutoLogout",
  data: () => {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
      logoutZone: false,
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  destroyed() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
  },
  methods: {
    ...mapActions["destroyChatBox"],
    setTimers() {
      this.warningTimer = setTimeout(this.warningMessage, 30 * 60 * 1000); // 15 minutes
      this.logoutTimer = setTimeout(this.logoutUser, 35 * 60 * 1000); // 10 mins
      this.warningZone = false;
      this.logoutZone = false;
    },

    warningMessage() {
      this.warningZone = true;
    },
    async logoutUser() {
      await this.destroyChatBox;
      this.$store.commit("logUserOut");
      this.$router.go(0);
      // this.$router.push("/").catch(err => {});
      if (this.$route.path != "/") {
          this.$router.push("/");
      }
    },

    resetTimer: function () {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
  },
};
</script>

<style>
.auto-logout-modal{
  position: absolute;
  font-size: 1rem;

  top: 65px;
 right: 0px;
  z-index: 1000000;
  backdrop-filter: blur(100%);

}
.alert-warning{
  background: rgb(252,201,1);
background: linear-gradient(0deg, rgba(252, 202, 1, 0.7) 0%, rgba(254, 153, 12, 0.76) 100%);
border-radius: 5px;
border-top-left-radius: 0px;
border-top-right-radius: 0px;
text-align: left !important;
}
@media (max-width: 650px) {
  .auto-logout-modal{
  position: absolute;
  font-size: 1rem;
  
  top: 0%;
 left: 0px;
  z-index: 1000000;
  backdrop-filter: blur(100%);

}
.alert-warning{
  background: rgb(252,201,1);
background: linear-gradient(0deg, rgba(252, 202, 1, 0.7) 0%, rgba(254, 153, 12, 0.76) 100%);

}
}
</style>
