<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <form class="form-inline-2 searchbar" @submit.prevent="lookupUser">
      <img
        src="@/assets/svg/search.svg"
        alt="Search"
        width="20"
        height="20"
        class="search-img"
      />
      <vue-typeahead-bootstrap
        v-model="query"
        id="diasporaTypeHead"
        :data="getDiaspora"
        :serializer="(item) => item.login"
        highlightClass="special-highlight-class"
        @hit="selecteduser = $event"
        :minMatchingChars="3"
        :autocomplete="false"
        :placeholder="$t('searchDiaspora')"
        inputClass="form-control search-input-2"
        class="search-input-2"
        :disabledValues="selecteduser ? [selecteduser.login] : []"
        @input="lookupUser"
      >
      </vue-typeahead-bootstrap>
      <div class="clear-icon-container">
        <button
          class=""
          @click="clearSearch"
          :style="`opacity:${query ? 1 : 0}`"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            data-supported-dps="16x16"
            fill="currentColor"
            class="mercado-match"
            width="16"
            height="16"
            focusable="false"
          >
            <path
              d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
            ></path>
          </svg>
        </button>
      </div>
      <!-- <input
        type="submit"
        class="btn btn-search"
        :value="`${$i18n.t('search')}`"
      /> -->
    </form>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "../../i18n";
export default {
  mounted() {},
  computed: {
    ...mapGetters(["authUser", "getDiaspora", "getSavedDiaspora"]),
  },
  data() {
    return {
      search: {},
      notFound: false,
      selecteduser: null,
      query: "",
    };
  },
  methods: {
    ...mapActions(["openChatBoxWithUser"]),
    clearSearch() {
      this.query = "";

      this.$store.commit("setDiaspora", this.getSavedDiaspora);
      this.$store.commit("setShowPagination", true);
      this.$store.commit("setShowLoadMore", true);
    },
    lookupUser: debounce(function () {
      // in practice this action should be debounced

      if (this.query) {
        this.$store.commit("setShowLoadMore", false);
        fetch(
          `${APIURLS.USERS}lookupUser/${this.query}/user/${this.authUser.id}`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log("data", data);
            if (!data.items.length) {
              this.notFound = true;
            }
            this.$store.commit("setDiaspora", data.items);
            this.$store.commit("setShowPagination", false);
          });
      } else {
        this.$store.commit("setDiaspora", this.getSavedDiaspora);
        this.$store.commit("setShowPagination", true);
        this.$store.commit("setShowLoadMore", true);
      }
    }, 500),
  },
};
</script>


<style>
.search-input-2 {
  padding-left: 30px;
  border: 5px solid blue !important;
}

.search-input-2 .form-control[type="text"],
select,
textarea {
  border: none !important;
}

.search-input-2 {
  border: none !important;
  width: 100% !important;
  background-color: none;
}
</style>

<style scoped>
.form-inline-2 {
  width: 100% !important;
}
.clear-icon-container {
  position: relative;
  right: 30px;
  z-index: 9999;
}
.clear-icon-container button {
  background: rgb(176, 187, 186);
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 1000px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.clear-icon-container :hover {
  background: rgb(146, 146, 146);
}
.clear-icon-container button svg {
  color: rgba(0, 0, 0, 0.6);
  width: 10px;
  height: 10px;
}

.btn {
  cursor: pointer;
  border: none !important;
}

img.search-img {
  width: 20px !important;
  height: 20px !important;
}

.searchbar {
  position: relative;
  box-sizing: border-box;
  padding: 0.3rem !important;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.search-img {
  margin-left: 1.5rem;
}
.searchbar input[type="text"] {
  border: none !important;
  font-size: 0.85rem;
  padding: 0 1.25rem !important;
  color: #000;
}
.searchbar input[type="text"]::placeholder {
  color: #8c8c8c;
  font-size: 0.85rem;
}
.searchbar input[type="text"]:focus {
  outline: none !important;
}

.btn-search {
  position: absolute;
  right: 10px;
  background-color: #39a251;
  color: #fff;
  border: 1px solid rgb(255 255 255 / 27%);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.7rem 3.5rem;
}
.btn-search:hover {
  background: #18792f;
}

@media (max-width: 991px) {
  .searchbar {
    padding: 0.7rem;
  }
  .search-img {
    padding: 0 0.5rem 0 0;
  }

  .searchbar input[type="text"] {
    padding: 0 0.5rem 0 0;
    font-size: 16px;
  }
  .btn-search {
    display: none;
    /* font-size: .875rem;
    padding: .65rem 2.5rem; */
  }
}
@media (max-width: 574px) {
  .searchbar {
    padding: 0 !important;
    /* border: 1px solid red; */
  }
  /* .btn-search {
    font-size: .75rem;
    padding: .5rem 1.2rem;
    border-radius: .75rem;
  } */
}
@media (max-width: 600px) {
  .searchbar {
    height: 45px;
    padding-left: 10px !important;
  }
  .search-img {
    position: absolute;
    z-index: 999;
    left: 0;
    padding: 0;
  }
}
</style>
