<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t('talentNeeded') }}</h6>
    </div>
    <div class="box-body p-3">
      <div
        class="btn-group"
        role="group"
        v-for="talent in talents"
        :key="talent.id"
      >
        <button type="button" class="btn btn-outline-success btn-sm m-1">
          {{ talent.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TalentsNeeded',
  props: {
    talents: {},
  },
};
</script>
