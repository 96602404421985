<template>
  <div class="basic-info-container">
    <div class="startup-name">
      <request-pitch-deck-button v-if="!getStartupEditingMode" class="request-pitch-deck verify-user"
        text="requestPitchDeck" :startup="startup" />
      <span class="editable" v-if="!editingStartupName" contenteditable="false" id="startupname">{{ startupname
      }}</span>
      <div v-if="showEditButtons" class="btn-wrapper">
        <div class="row">
          <input v-if="editingStartupName" type="text" name="startupname" id="startupname"
            class="col profile-controls mx-2" v-model="startupname" :size="startup.startupname.length" />

          <edit-button v-if="!editingStartupName" class="btn btn-edit" @click.native="editName" />

          <save-cancel-button-group v-else class="justify-content-end" @save="saveName" @cancel="cancelStartupName" />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-item">
        <img class="cover-icon" src="@/assets/images/startups/page/sector.svg" />
        <label contenteditable="false" v-if="!editingSector" id="sector">{{
        sector
        }}</label>

        <div v-if="showEditButtons" class="btn-wrapper">
          <edit-button class="btn btn-edit" @click.native="editSector" v-if="!editingSector" />

          <save-cancel-button-group v-else class="justify-content-end" @save="saveSector" @cancel="cancelSector" />

          <CustomSelect v-if="editingSector" :options="allSectors" :default="sector ? sector : allSectors[0].code"
            class="col form-control mx-2" @input="changeSector" />
        </div>
      </div>
      <div class="list-item" v-if="location || getStartupEditingMode">
        <img class="cover-icon" src="@/assets/images/startups/page/location.svg" />
        <label contenteditable="false" v-if="!editingLocation" id="location">{{
        location
        }}</label>
        <div v-if="showEditButtons" class="btn-wrapper">
          <edit-button class="btn btn-edit" @click.native="editLocation" v-if="!editingLocation" />
          <save-cancel-button-group v-else class="justify-content-end" @save="saveLocation" @cancel="cancelLocation" />
          <CustomSelect v-if="editingLocation" :options="africanCountries"
            :default="location ? location : africanCountries[0].code" class="col select form-control"
            @input="changeCountry" />
        </div>
      </div>
      <div class="rowww">
        <div class="list-item follows-container">
          <img class="cover-icon" src="@/assets/images/startups/page/followers.svg" />
          <label>{{ getFollowersCount }} {{ $t("followers") }}</label>
        </div>

        <div class="basic-footer" v-if="!getStartupEditingMode">
          <chat-button class="chat" @click.native="chat()" />
          <follow-button v-if="followed === 'unfollowed'" class="follow" text="follow" icon="follow"
            @click.native="follow" />
          <follow-button v-else class="follow" text="followed" icon="followed" @click.native="unfollow" />
        </div>
      </div>
    </div>
    <template v-if="getStartupEditingMode">
      <labeled-edit-button class="edit-mode" text="editMode" v-if="!showEditButtons" @click.native="viewEditButtons" />
      <labeled-edit-button class="edit-mode " v-else text="exitEditMode" @click.native="hideEditButtons" />
      <labeled-request-verification-button class="verify-user" text="requestVerification"
        @click.native="hideEditButtons" />
    </template>
    <div class="profileImg" :class="{ fixedOverflow: showEditButtons }">
      <Logo class="avatar" :avatar="
        this.startup.user && this.startup.user.avatar
          ? this.startup.user.avatar
          : '@/assets/images/startups/page/profile.svg'
      " />
    </div>
  </div>
</template>
<script>
import CustomSelect from "./ShareElements/CustomSelect.vue";
import SaveClose from "./ShareElements/SaveClose.vue";
import Logo from "./Logo.vue";
import LabeledEditButton from "../../Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import LabeledRequestVerificationButton from "../../Profile/Diaspora/profile/buttons/LabeledRequestVerificationButton.vue";
import ChatButton from "../../Profile/Diaspora/profile/buttons/ChatButton.vue";
import FollowButton from "../../Profile/Diaspora/profile/buttons/FollowButton";
import RequestPitchDeckButton from "../../Profile/Diaspora/profile/buttons/RequestPitchDeckButton";
import EditButton from "../../Profile/Diaspora/profile/buttons/EditButton";
import SaveCancelButtonGroup from "../../Profile/Diaspora/profile/buttons/SaveCancelButtonGroup.vue";
import APIURLS from "../../../constant";
import i18n from "../../../i18n";
import { mapGetters, mapActions } from "vuex";
import Axios from "axios";
import { io } from "socket.io-client";
export default {
  watch: {
    async $route() {
      await this.getSector();
    },
  },
  async mounted() {
    await this.checkFollowed();
    await this.getSector();
    await this.getAfricanCountries();
    await this.loadFollowersCount(this.startup.id);
    await this.getAllSectors();
    this.startupname = this.startup.startupname;
    this.location = this.startup.location;
    this.sockets = io(APIURLS.CHATLINK);
    this.sockets.on("follow", (data) => {
      if (data.startup_id === this.startup.id) {
        this.getNotifs();
      }
    });
  },
  name: "BasicInfo",
  props: {
    startup: Object,
    followersCount: Number,
  },
  components: {
    CustomSelect,
    ChatButton,
    FollowButton,
    LabeledEditButton,
    EditButton,
    Logo,
    SaveCancelButtonGroup,
    LabeledRequestVerificationButton,
    RequestPitchDeckButton
  },
  data() {
    return {
      sockets: {},
      startupname: "",
      location: "",
      editing: false,
      editingStartupName: false,
      editingSector: false,
      editingLocation: false,
      logo: "",
      followed: "",
      interrest: "",
      countries: null,
      africanCountries: [],
      countriesLists: [],
      allSectors: [],
      sector: "",
      sectors: [],
    };
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getFollowers",
      "getStartup",
      "getFollowersCount",
      "getStartupEditingMode",
      "showEditButtons",
      "getForeignCountriesList",
      "getCountriesList",
      "getSectorsFilter",
      "getSectorstList",
      "getUserLang",
      "getUserNotifications",
    ]),
    followStatus() {
      return this.followed;
    },
  },
  methods: {
    ...mapActions([
      "loadFollowers",
      "loadFollowersCount",
      "loadInterrested",
      "loadStartupNeeds",
      "loadInterrestedCount",
      "openChatBoxWithUser",
      "getUserById",
      "getSectorByLang",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "loadCountriesList",
      "loadStartupBySlug",
      "loadSectorsFilter",
      "loadSectorsList",
      "loadUserNotifications",
      "requestPitchDeck",
    ]),
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.$store.commit("setUserNewNotifications", this.getUserNotifications);
    },
    async getAllSectors() {
      var lang = this.getUserLang;
      await this.loadSectorsList({ lang });
      this.getSectorstList.forEach((sector) => {
        this.allSectors.push({
          id: sector.id,
          code: sector.code,
          name: sector.name,
        });
      });
    },
    async getAfricanCountries() {
      var lang = this.getUserLang;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.africanCountries.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    viewEditButtons() {
      this.$store.commit("setShowEditButtons", true);
    },
    hideEditButtons() {
      this.$store.commit("setShowEditButtons", false);
      this.cancelStartupName();
      this.cancelSector();
      this.cancelLocation();
    },
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    editName() {
      this.editingStartupName = true;
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = true;
    },
    saveName() {
      this.$store
        .dispatch("updateStartup", {
          startupname: this.startupname,
        })
        .then(async () => {
          // eslint-disable-next-line vue/no-mutating-props
          this.startup = this.getStartup;
          this.editingStartupName = false;
          const startupname = document.getElementById("startupname");
          startupname.contentEditable = false;
          this.$snotify.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    cancelStartupName: function () {
      this.editingStartupName = false;
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = false;
    },

    saveSector() {
      this.$store
        .dispatch("updateStartup", {
          sector: this.sector,
        })
        .then(async () => {
          this.editingSector = false;
          this.$snotify.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    editSector() {
      this.editingSector = true;
      const sector = document.getElementById("sector");
      sector.contentEditable = true;
    },

    changeSector(option) {
      this.sector = option;
    },
    cancelSector() {
      this.editingSector = false;
      const sector = document.getElementById("sector");
      sector.contentEditable = false;
    },
    saveLocation() {
      this.$store
        .dispatch("updateStartup", {
          location: this.location,
        })
        .then(async () => {
          this.editingLocation = false;
          // eslint-disable-next-line vue/no-mutating-props
          this.startup = this.getStartup;
          this.location = this.fortmatCountry(this.location);
          this.$snotify.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    changeCountry(option) {
      this.location = option;
    },
    editLocation() {
      this.editingLocation = true;
      const location = document.getElementById("location");
      location.contentEditable = true;
    },
    cancelLocation() {
      this.editingLocation = false;
      const location = document.getElementById("location");
      location.contentEditable = false;
    },
    save() {
      this.editing = false;
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = false;
    },
    closeSave() {
      this.editing = false;
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = false;
    },
    editStartupName() {
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = true;
    },
    toggleEditMode() {
      this.editing = true;
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = true;
    },
    saveEdits() {
      this.editing = false;
      const startupname = document.getElementById("startupname");
      startupname.contentEditable = false;
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    async chat() {
      let user_id = this.startup.user_id;
      let user = await this.getUserById({ user_id });
      await this.openChatBoxWithUser(user);
    },
    scrollTo(selector) {
      document.querySelector(selector).scrollIntoView({ behavior: "smooth" });
    },
    async getSector() {
      let lang = this.getUserLang;
      let code = this.startup.sector;
      if (this.startup.sector != null) {
        this.sector = await this.getSectorByLang({ code, lang });
      } else {
        this.sector = "";
      }
    },
    async follow() {
      let sector, countryOfOrigin, livesIn, headquarters, sender_avatar;
      let lang = this.getUserLang;
      if (this.authUser.type === 1) {
        let originCode = this.authUser.profile
          ? this.authUser.profile.birthCountry
          : "";
        countryOfOrigin = await this.getAFricanCountryByLang({
          code: originCode,
          lang,
        });
        let livesInCode = this.authUser.profile
          ? this.authUser.profile.residenceContry
          : "";
        livesIn = await this.getForeignCountryByLang({
          code: livesInCode,
          lang,
        });
      } else {
        let code = this.authUser.startup ? this.authUser.startup.sector : "";
        sector = await this.getSectorByLang({ code, lang });
        let countryCode = this.authUser.startup
          ? this.authUser.startup.location
          : "";
        headquarters = await this.getAFricanCountryByLang({
          code: countryCode,
          lang,
        });
      }

      let name =
        this.authUser.type === 1
          ? (this.authUser.firstname ? this.authUser.firstname : "") +
          " " +
          this.authUser.name
          : this.authUser.startup.startupname;
      const data = {
        startup_id: this.startup.id,
        user_id: this.authUser.id,
        name,
        to: this.startup.user_id,
        sender_avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        lang,
        headquarters,
        sector,
        countryOfOrigin,
        livesIn,
      };

      Axios({
        method: "post",
        url: APIURLS.FOLLOWERS + "follow",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          if (response.data.statusMsg === "Success") {
            this.followed = "followed";
            Axios.get(
              `${APIURLS.LINKEDINSERVERLINK}/follow/` + this.startup.id
            );
            this.$store.commit(
              "increaseFollowersCounts",
              this.getFollowersCount
            );
            this.$noty.success(this.$i18n.t("followingStartup"));
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
    unfollow() {
      Axios.get(
        APIURLS.FOLLOWERS +
        "user/" +
        this.authUser.id +
        "/startup/" +
        this.startup.id +
        "/unfollow"
      )
        .then((response) => {
          if (response.data.message === "Unfollowed") {
            this.followed = "unfollowed";
            this.$store.commit(
              "decreaseFollowersCounts",
              this.getFollowersCount
            );
            this.$noty.success(this.$i18n.t("unFollowingStartup"));
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
    checkFollowed() {
      Axios.get(
        APIURLS.FOLLOWERS +
        "user/" +
        this.authUser.id +
        "/startup/" +
        this.startup.id
      )
        .then((response) => {
          if (response.data.message === "Not Followed") {
            this.followed = "unfollowed";
          } else {
            this.followed = "followed";
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: 1px solid var(--first-color) !important;
  border-radius: 0.3rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.basic-info-container {
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  /* background: #cfead9;
  background: linear-gradient(180deg, #eafff2 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%); */
  /* box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.08),
      0px -2px 4px rgba(44, 43, 42, 0.1),
      0px -40px 50px #1ba54b2e; */
}

.startup-name {
  font-family: Helvetica;
  font-size: 25px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  font-weight: bold;
  width: auto !important;
  color: #1f2430;
}

.startup-name span {
  background: #ffffff;
  box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915;
  border-radius: 23px;
  width: auto;
  height: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bolder;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editable[contenteditable="true"] {
  padding: 0 10px;
  border: 2px solid #00853c40;
}

.list-item {
  display: flex;
  align-items: center;
}

.list-item label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 134.99%;
  letter-spacing: 0.02em;
  color: #333333;
  padding-left: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  text-align: left;
}

img.cover-icon {
  /* height: 15px; */
  height: 25px;
  border: 2px solid #1ba54b24;
  background-color: #ffffff;
  box-shadow: 0 0px 2px #0f442105, 0px 0 4px #006d3610, 0px 0px 6px #00461715;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
}

.list {
  /* background-color: red; */
  padding: 1rem 5rem;
  position: relative;
}

.rowww {
  display: flex;
  align-items: center;
}

.follows-container {
  width: 50%;
}

button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  width: 140px;
  height: 40px;
  background: white;
  color: black;
  border-radius: 22px;
  border: none;
  position: relative;
  border: 2px solid #00853c;
}

button img {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 2px;
}

button label {
  padding-left: 10px;
  color: black;
  font-size: 18px;
  line-height: 25px;
}

.follow {
  margin-left: 10px;
}

.follow-icon-container {
  width: 32px;
  height: 32px;
  background-color: #41ac66;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 2px;
}

.follow-icon-container img {
  width: 22px;
  height: 22px;
}

.edit-mode {
  cursor: pointer;
  position: absolute;
  right: 5rem;
  top: 2rem;
  width: auto;
}

.mode-btn {
  margin-left: 0;
  /* margin-right: calc(100% - 12.5px); */
}

.edit-mode .btn-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-mode .btn-wrapper .btn-edit {
  position: absolute;
  left: 0;
}

.basic-footer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding: 20px 0; */
}

.profileImg {
  position: absolute;
  top: -78px;
  left: 5rem;
  width: 120px;
  height: 120px;
  background: #c4c4c4;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixedOverflow {
  overflow: inherit !important;
}

.profile-bg {
  width: 100%;
  height: 100%;
}

.btn-wrapper {
  margin-left: 5px;
  /* background-color:  orange; */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.btn-wrapper .btn-edit {
  width: 25px;
  height: 25px;
  padding: 5px;
  line-height: 0;
  border: 1px solid hsla(0, 0%, 43.9%, 0.002);
  border-radius: 15px;
  background-color: hsla(0, 0%, 54.9%, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-user {
  cursor: pointer;
  position: absolute;
  right: 5rem;
  top: 4rem;
  width: auto;
}

.request-pitch-deck {
  top: 1.5rem;
}

@media (max-width: 600px) {
  .verify-user {
    display: none
  }

  .basic-info-container {
    position: relative;
    padding-top: 60px;
    padding-bottom: 20px;
    position: relative;
    /* padding-top: 80px; */
    padding-bottom: 20px;
    background-color: rgb(255, 255, 255);
    /* background: #cfead9;
background: linear-gradient(180deg, #eafff2 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%); */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -50px;
    width: 100vw;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.08),
      0px -2px 4px rgba(44, 43, 42, 0.1), 0px -40px 50px #6868682e;
  }

  .rowww {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    /* width: 100vw; */
  }

  .basic-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 1.5rem;
    padding-left: 1.5rem;
  }

  .basic-info-container {
    padding-bottom: 0px;
  }

  .profileImg {
    position: absolute;
    top: -78px;
    left: calc(50% - 77.5px);
    width: 125px;
    height: 125px;
    background: #c4c4c4;
    border-radius: 50%;
    overflow: hidden;
  }

  .startup-name {
    font-size: 1.3rem;
    font-weight: bold;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .startup-name span {
    background: #ffffff;
    box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915;
    border-radius: 23px;
    width: 90%;
    height: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list {
    /* background-color: red; */
    padding: 1rem 0rem;
    padding-top: 0;
    position: relative;
    margin-top: 1rem;
    border-top: 1px solid #00800016;
  }

  .list-item {
    border-bottom: 1px solid #00800016;
    padding: 8px 1.5rem;
    width: 100vw !important;
  }

  .edit-mode {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 14px;
    line-height: 22px;
    padding: 0px 0px;
  }
}

@media (max-width: 320px) {
  .startup-name {
    font-size: 1.1rem;
  }

  .startup-name span[data-v-14d85ee0] {
    height: 2.5rem;
  }
}
</style>