let LINK = 'http://localhost:3010/';
//let CHATLINK = 'https://chat-prod.ourbantaba.com/';
//let CHATLINK = 'https://chat-dev.ourbantaba.com/';
let CHATLINK = 'https://community.prod.chat.apis.bantaba.com/';
const PRODLINK = 'https://community.prod.apis.bantaba.com/';
const DEVLINK = 'https://api-dev.ourbantaba.com/';

const SITELINK = 'https://community.ourbantaba.com/';
// const LINKEDINSERVERLINK = 'http://localhost:3003';
const LINKEDINSERVERLINK = 'https://linkedin-dev.ourbantaba.com';
//const SITELINK = 'http://localhost:8080/';
const CLOUDINARY_USERSFEEDSIMAGES = 'userFeedsImages';

const IP_API_KEY = 'mpuy5rv94ve8xth1';

const VAULTDEVLINK = 'https://vault-dev-api.ourbantaba.com/';

const NOTIFICATIONS_LIMIT = 100;

LINK = PRODLINK;

//LINK = DEVLINK;


//LINK = VAULTDEVLINK;

const AUTH = LINK + 'auth/';
const PROFILE = LINK + 'profile/';
const USERS = LINK + 'users/';
const CONNECTIONS = LINK + 'connections/';
const SKILLS = LINK + 'skills/';
const LANGUAGES = LINK + 'languages/';
const EXPERIENCES = LINK + 'experiences/';
const EDUCATION = LINK + 'education/';
const INTERRESTS = LINK + 'interrests/';
const INTERRESTLIST = LINK + 'interrests-list/';
const TALENTS = LINK + 'talents/';
const SECTORSLIST = LINK + 'sectors-list/';
const STARTUP = LINK + 'startups/';
const USERSSUPORTSTARTUPS = LINK + 'users/support-startups';
const USERSTALENTS = LINK + 'users-talents/';
const CORPORATIONS = LINK + 'corporations/';
const JOBS = LINK + 'jobs/';
const NEWS = LINK + 'news/';
const COMMENTS = LINK + 'comments/';
const LIKES = LINK + 'likes/';
const FEEDS = LINK + 'feeds/';
const SETTINGS = LINK + 'settings/';
const FOLLOWERS = LINK + 'followers/';
const INTERRESTED = LINK + 'interrested/';
const USERSSECTOROFINTEREST = LINK + 'users-interests-sectors/';
const DEFAULT_FEEDS = LINK + 'default-startup-feeds/';
const TEAM = LINK + 'teams/';
const COUNTRIES = LINK + 'countries/';
const FOREIGNCOUNTRIES = LINK + 'foreign-countries/';
const NOTIFICATIONS = LINK + 'notifications/';
const VIEWS = LINK + 'views';
const USERSTYPEOFSUPPORT = LINK + 'users-type-support/';
const GROUPSCHAT = CHATLINK + 'groups/';
const PRIVATECHAT = CHATLINK + 'pm/';
const PARTNERSHIPS = LINK + 'partnerships/';
const EXCLUOFFERSCLICKS = LINK + 'exclu-offers-clicks/';
const LOGIN = LINK + 'login/';

const DOCUMENT = LINK + 'documents/';
const VAULT = LINK + 'vaults/';
const VAULTREQUEST = LINK + 'vault-requests/';
const VAULTREQUESTNOTAVAILABLE = LINK + 'pitch-decks/';


export default {
  LINK,
  SITELINK,
  PRODLINK,
  LINKEDINSERVERLINK,
  AUTH,
  PROFILE,
  USERS,
  CONNECTIONS,
  SKILLS,
  LANGUAGES,
  EDUCATION,
  EXPERIENCES,
  INTERRESTS,
  INTERRESTLIST,
  TALENTS,
  SECTORSLIST,
  STARTUP,
  USERSSUPORTSTARTUPS,
  JOBS,
  NEWS,
  CORPORATIONS,
  COMMENTS,
  LIKES,
  FEEDS,
  FOLLOWERS,
  INTERRESTED,
  CHATLINK,
  GROUPSCHAT,
  PRIVATECHAT,
  SETTINGS,
  NOTIFICATIONS,
  TEAM,
  DEFAULT_FEEDS,
  COUNTRIES,
  FOREIGNCOUNTRIES,
  USERSTYPEOFSUPPORT,
  VIEWS,
  USERSTALENTS,
  USERSSECTOROFINTEREST,
  CLOUDINARY_USERSFEEDSIMAGES,
  PARTNERSHIPS,
  IP_API_KEY,
  EXCLUOFFERSCLICKS,
  LOGIN,
  NOTIFICATIONS_LIMIT,
  DOCUMENT,
  VAULT,
  VAULTREQUEST,
  VAULTREQUESTNOTAVAILABLE,
};
