<template>
  <div>
    <div
      v-if="showLikesComments"
      class="p-3 ml-2 osahan-post-footer feedFooter"
      ref="feedFooter"
      id="feedFooter"
    >
      <a
        href="javascript:;"
        class="mr-3 text-secondary likeButton"
        v-if="showLike"
        @click="likePost(newsId)"
      >
        <i class="feather-heart text-secondary"></i>
        {{
          allLikes.count > 0
            ? allLikes.count === 1
              ? allLikes.count + `  ${$t('Like')}`
              : formatCount(allLikes.count) + `  ${$t('Likes')}`
            : `${$t('Like')}`
        }}
      </a>

      <a
        href="javascript:;"
        class="mr-3 text-secondary likedButton"
        v-if="!showLike"
        @click="unLikePost(newsId, index)"
      >
        <i class="fas fa-heart text-danger"></i>
        {{
          allLikes.count > 0
            ? allLikes.count === 1
              ? allLikes.count + `  ${$t('Liked')}`
              : formatCount(allLikes.count) + `  ${$t('Liked')}`
            : `  ${$t('Liked')}`
        }}
      </a>
      <a
        href="javascript:;"
        @click="toggleComment()"
        class="mr-3 text-secondary commentButton "
        ><i class="feather-message-square"></i>
        {{
          allComments.count >= 1
            ? allComments.count === 1
              ? allComments.count + `  ${$t('comment')}`
              : formatCount(allComments.count) + `  ${$t('comments')}`
            : `  ${$t('toComment')}`
        }}</a
      >
    </div>
    <VueSkeleton
      v-else
      type="rect"
      class="p-3 osahan-post-footer feedFooter"
      :rounded="true"
      :width="200"
      :height="20"
      animation="fade"
    />
    <transition name="slide">
      <div v-if="showComments">
        <div class="row p-2  col-md-12">
          <div class="col-md-11">
            <ResizeAuto>
              <template v-slot:default="{ resize }">
                <textarea
                  class="textarea pl-4 pt-2 pr-4 mx-4  shadow-none bg-gray-800"
                  :placeholder="$t('addYourComment') + ' ...'"
                  v-model="comment"
                  @input="resize"
                  v-on:keyup.enter="addComment"
                ></textarea>
              </template>
            </ResizeAuto>
          </div>
        </div>
        <div v-if="loadingMore">
          <div class="comment p-3 d-flex align-items-top  osahan-post-comment">
            <div class="dropdown-list-image">
              <VueSkeleton
                type="circle"
                class="dropdown-list-image mr-3"
                :rounded="true"
                :size="40"
                animation="fade"
              />
            </div>
            <div class="font-weight-bold ml-3">
              <div class="text-truncate">
                <VueSkeleton
                  type="rect"
                  class="mb-2"
                  :rounded="true"
                  :width="100"
                  :height="5"
                  animation="fade"
                />
              </div>
              <div class="small text-gray-500" style="width:100% !important;">
                <VueSkeleton
                  type="rect"
                  :rounded="true"
                  :width="200"
                  class=" mb-2"
                  :height="5"
                  animation="fade"
                />
                <VueSkeleton
                  type="rect"
                  :rounded="true"
                  :width="50"
                  :height="5"
                  animation="fade"
                />
              </div>
            </div>
          </div>
          <div class="comment p-3 d-flex align-items-top  osahan-post-comment">
            <div class="dropdown-list-image">
              <VueSkeleton
                type="circle"
                class="dropdown-list-image mr-3"
                :rounded="true"
                :size="40"
                animation="fade"
              />
            </div>
            <div class="font-weight-bold ml-3">
              <div class="text-truncate">
                <VueSkeleton
                  type="rect"
                  class=" mb-2"
                  :rounded="true"
                  :width="100"
                  :height="5"
                  animation="fade"
                />
              </div>
              <div class="small text-gray-500" style="width:100% !important;">
                <VueSkeleton
                  type="rect"
                  :rounded="true"
                  :width="200"
                  class=" mb-2"
                  :height="5"
                  animation="fade"
                />
                <VueSkeleton
                  type="rect"
                  :rounded="true"
                  :width="50"
                  :height="5"
                  animation="fade"
                />
              </div>
            </div>
          </div>
          <div class="comment p-3 d-flex align-items-top  osahan-post-comment">
            <div class="dropdown-list-image">
              <VueSkeleton
                type="circle"
                class="dropdown-list-image mr-3"
                :rounded="true"
                :size="40"
                animation="fade"
              />
            </div>
            <div class="font-weight-bold ml-3">
              <div class="text-truncate">
                <VueSkeleton
                  type="rect"
                  class=" mb-2"
                  :rounded="true"
                  :width="100"
                  :height="5"
                  animation="fade"
                />
              </div>
              <div class="small text-gray-500" style="width:100% !important;">
                <VueSkeleton
                  type="rect"
                  :rounded="true"
                  :width="200"
                  class=" mb-2"
                  :height="5"
                  animation="fade"
                />
                <VueSkeleton
                  type="rect"
                  :rounded="true"
                  :width="50"
                  :height="5"
                  animation="fade"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="comment in allComments.comments"
            :key="comment.id"
            class="commentWrapper px-3 pb-3 align-items-top osahan-post-comment"
          >
            <div
              class="col-md-12 comment p-2 d-flex align-items-top osahan-post-comment"
            >
              <div class="dropdown-list-image mr-3">
                <img
                  class="rounded-circle"
                  :src="comment.user.avatar"
                  alt="user avatar"
                />
              </div>
              <div class="font-weight-bold commentText p-2">
                <div class="text-truncate">
                  <i
                    v-if="feedAuthId === comment.user.id"
                    class="feather-check-circle text-info"
                  >
                  </i>
                  {{ comment.user.name }}

                  <span class="float-right small">
                    <a
                      v-if="comment.user.id === authUser.id"
                      href="javascript:;"
                      @click="deleteComment(comment.id, index)"
                      class="ml-3 badge badge-pill badge-secondary text-secondary trashButton "
                    >
                      <i class="feather-trash text-danger"></i>
                      {{ $t('delete') }}
                    </a>
                  </span>
                </div>
                <div
                  class="small commentTitle text-gray-500"
                  style="width:100% !important;"
                >
                  {{ comment.text }}
                </div>
                <timeago
                  class="text-secondary small"
                  :datetime="comment.created_at"
                  :locale="$i18n.locale"
                  :auto-update="60"
                  :converter="convertTime"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12"
          v-if="
            allComments.count > allComments.pagination &&
              allComments.comments.length < allComments.count
          "
        >
          <button
            type="button"
            @click="loadmore(allComments.pagination)"
            class="loadMore font-weight-bold btn btn-light rounded p-3 btn-block"
          >
            <span v-if="loadingMore"
              ><i class="fas fa-spinner fa-pulse"></i>
              {{ $t('loadingMore') }} ...</span
            >
            <span v-else>{{ $t('loadMore') }}</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Axios from 'axios';
import Swal from 'sweetalert2';
import APIURLS from '../../constant';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import i18n from '../../i18n';
export default {
  async created() {
    await this.getComments(this.newsId);
    await this.getLikes(this.newsId);
    await this.formatLikes();
    this.showLikesComments = true;
  },
  computed: {
    ...mapGetters(['authUser', 'getUserLang',
      'getAFricanCountryByLang',
      'getForeignCountryByLang',
      'getSectorByLang']),
    ...mapActions(['loadInterrestList']),
  },
  data() {
    return {
      showLike: '',
      showLikesComments: false,
      showComments: false,
      allComments: {},
      allLikes: {},
      comment: '',
      loadingMore: false,
      feedFooterSize: 0,
      commentSize: 0,
      page: 1,
    };
  },
  methods: {
    loadmore(pagination) {
      this.loadingMore = true;
      Axios.get(
        `${APIURLS.COMMENTS}news/${this.newsId}/page/${this.page}/take/10/pagination/${pagination}`
      )
        .then(({ data }) => {
          this.page++;
          if (data.comments.length) {
            [...this.allComments.comments] = [
              ...this.allComments.comments,
              ...data.comments,
            ];
          }
          this.loadingMore = false;
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });

      this.loadingMore = false;
    },
    toggleComment() {
      this.showComments = !this.showComments;
    },

    convertTime(date, locale, converterOptions) {
      let year = ' y';
      let day = ' d';
      if (this.$i18n.locale === 'fr') {
        year = ' an';
        day = ' j';
      }

      const prefix = converterOptions.prefix || '';
      const suffix = converterOptions.suffix || '';
      const yearSuffix = converterOptions.yearSuffix || year;
      const monthSuffix = converterOptions.monthSuffix || ' m';
      const daySuffix = converterOptions.daySuffix || day;
      const hourSuffix = converterOptions.hourSuffix || ' h';
      const minuteSuffix = converterOptions.minuteSuffix || ' min';
      const secondsSuffix = 's';
      const diffSpacer = converterOptions.diffSpacer || '';

      let difference =
        new Date().getTime() - new Date(Date.parse(date)).getTime();

      const yearsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30 / 12
      );
      difference -= yearsDifference * 1000 * 60 * 60 * 24 * 30 * 12;

      if (yearsDifference > 0) {
        return `${prefix} ${yearsDifference}${diffSpacer}${yearSuffix} ${suffix}`;
      }

      const monthsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30
      );
      difference -= monthsDifference * 1000 * 60 * 60 * 24 * 30;

      if (monthsDifference > 0) {
        return `${prefix} ${monthsDifference}${diffSpacer}${monthSuffix} ${suffix}`;
      }

      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      if (daysDifference > 0) {
        return `${prefix} ${daysDifference}${diffSpacer}${daySuffix} ${suffix}`;
      }

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      if (hoursDifference > 0) {
        return `${prefix} ${hoursDifference}${diffSpacer}${hourSuffix} ${suffix}`;
      }

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      if (minutesDifference > 0) {
        return `${prefix} ${minutesDifference}${diffSpacer}${minuteSuffix} ${suffix}`;
      }

      const secondsDifference = Math.floor(difference / 1000);
      difference -= secondsDifference * 1000;

      if (secondsDifference > 0) {
        return `${prefix} ${secondsDifference}${diffSpacer}${secondsSuffix} ${suffix}`;
      }
      return `${prefix} ${secondsSuffix} ${suffix}`;
    },
    async getComments(newsId) {
      let url = `${APIURLS.COMMENTS}news/${this.newsId}`;
      if (this.isUserFeed) {
        url = `${APIURLS.COMMENTS}feeds/${this.newsId}`;
      }
      await Axios.get(url)
        .then(({ data }) => {
          this.allComments = data.results;
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    },
    formatCount(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
    },
    async getLikes(newsId) {
      let url = `${APIURLS.LIKES}news/${this.newsId}`;
      if (this.isUserFeed) {
        url = `${APIURLS.LIKES}feeds/${this.newsId}`;
      }
      await Axios.get(url)
        .then(({ data }) => {
          this.allLikes = data.results;
          //this.allLikes.count = 9900000;
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    },
    async addComment() {
      if (!/^\s*$/.test(this.comment)) {
        let isUser;
        let sector, countryOfOrigin, livesIn, headquarters, sender_avatar;
        let lang = this.getUserLang;

        if (this.authUser.type === 1) {
          let originCode = this.authUser.profile ? this.authUser.profile.birthCountry : "";
          countryOfOrigin = await this.getAFricanCountryByLang({
            code: originCode,
            lang,
          });
          let livesInCode = this.authUser.profile ? this.authUser.profile.residenceContry : "";
          livesIn = await this.getForeignCountryByLang({
            code: livesInCode,
            lang,
          });
        } else {
          let code = this.authUser.startup ? this.authUser.startup.sector : "";
          sector = await this.getSectorByLang({ code, lang });
          let countryCode = this.authUser.startup
            ? this.authUser.startup.location
            : "";
          headquarters = await this.getAFricanCountryByLang({
            code: countryCode,
            lang,
          });
        }

        let name = this.authUser.type === 1 ? (this.authUser.firstname ? this.authUser.firstname : "") +
            " " +  this.authUser.name : this.authUser.startup.startupname;

        if (this.feedAuthId === this.authUser.id) {
          isUser = true;
        } else {
          isUser = false;
        }
        let url = `${APIURLS.COMMENTS}create`;
        let data = {
          feed_id: this.newsId,
          newsId: this.newsId,
          user_id: this.authUser.id,
          text: this.comment,
          isUser: true,
        };

        if (this.isUserFeed) {
          url = `${APIURLS.COMMENTS}feeds/create`;
          data = {
            feed_id: this.newsId,
            user_id: this.authUser.id,
            text: this.comment,
            name,
            isUser,
            to: this.feedAuthId,
            post: this.feedPost.substr(0, APIURLS.NOYIFICATIONS_LIMIT),
            sender_avatar: this.authUser.avatar,
            sender_id: this.authUser.id,
            lang,
            headquarters,
            sector,
            countryOfOrigin,
            livesIn,
          };
        }
        Axios.post(url, data)
          .then(async ({ data }) => {
            this.allComments.comments = [
              data.comment,
              ...this.allComments.comments,
            ];
            this.allComments.count++;
            //this.allComments.unshift(data.comment);
            this.comment = null;
          })
          .catch((error) => {
            //console.log('error', error);
            this.$noty.error(error);
          });
      }
    },
    deleteComment(commentId, index1) {
      Swal.fire({
        title: this.$i18n.t('deleteComment'),
        text: this.$i18n.t('sureToDeleteComment'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
        timerProgressBar: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let url = `${APIURLS.COMMENTS}${commentId}/user/${this.authUser.id}/delete`;
          if (this.isUserFeed) {
            url = `${APIURLS.COMMENTS}${commentId}/user/${this.authUser.id}/feeds/delete`;
          }
          return Axios.get(url)
            .then((response) => {
              const index = this.allComments.comments.findIndex(
                (comment) => comment.id === commentId
              );
              this.allComments.comments.splice(index, 1);
              this.allComments.count--;
            })
            .catch((error) => {
              Swal.fire({
                title: 'Request failed',
                text: error,
                icon: 'error',
              });
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },

    formatLikes() {
      if (this.allLikes) {
        const index = this.allLikes.likes.findIndex((like) => {
          if (like.user) {
            return like.user.id === this.authUser.id;
          }
        });
        if (index === -1) {
          this.showLike = true;
        } else {
          this.showLike = false;
        }
      }
    },

    async likePost(id) {
      let isUser;
      if (this.feedAuthId === this.authUser.id) {
        isUser = true;
      } else {
        isUser = false;
      }
      let url = `${APIURLS.LIKES}create`;
      let data = {
        newsId: id,
        user_id: this.authUser.id,
        isUser: true,
      };
      if (this.isUserFeed) {
        url = `${APIURLS.LIKES}LikeFeed`;
        data = {
          feed_id: id,
          user_id: this.authUser.id,
          name: this.authUser.name + ' ' + this.authUser.firstname,
          isUser,
          to: this.feedAuthId,
          post: this.feedPost.substr(0, 40),
        };
      }
      await Axios.post(url, data)
        .then(({ data }) => {
          this.allLikes.likes = [data.like, ...this.allLikes.likes];
          this.allLikes.count++;
          //this.allLikes = data.results;
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
      this.showLike = false;
    },
    async unLikePost(id, index) {
      if (this.showLike) {
        this.likePost(id);
      } else {
        let url = `${APIURLS.LIKES}user/${this.authUser.id}/news/${id}/delete`;
        if (this.isUserFeed) {
          url = `${APIURLS.LIKES}user/${this.authUser.id}/feeds/${id}/delete`;
        }
        await Axios.get(url)
          .then(() => {
            this.allLikes.likes.splice(index, 1);
            this.allLikes.count--;
          })
          .catch((error) => {
            this.$noty.error(error.message);
          });
        this.showLike = true;
      }
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Comment',
  props: {
    newsId: Number,
    index: Number,
    isUserFeed: Boolean,
    comments: {},
    feedAuthId: Number,
    feedPost: String,
  },
};
</script>

<style>
.bg-gray-100 {
  background-color: rgba(27, 103, 255, 0.5) !important;
}

.feedFooter {
  font-size: 17px;
}

.feedFooter a,
.commentLikeAction a {
  font-size: 16px !important;
}

.feedFooter i {
  font-weight: bold;
}

.likeButton i {
  color: #6c757d !important;
}
.likeButton i:hover,
.likedButton {
  color: red !important;
}

.trashButton {
  display: block;
  color: transparent !important;
  visibility: hidden;
}
.trashButton i {
  color: white !important;
}
.commentWrapper:hover .trashButton {
  display: block;
  color: white !important;
  visibility: visible;
}
.commentButton i:hover {
  color: #0f7f12 !important;
}
.shareButton i:hover {
  color: #0f7f12 !important;
}

.loadMore {
  border-color: #edf2f7;
  background-color: #edf2f7;
  border-width: 0px;
  border-radius: 5px;
}
.commentText {
  border-radius: 8px;
  /* background-color: rgba(240, 240, 240, 0.9); */
  background-color: rgba(240, 240, 240, 0.9);
  border: 2px solid !important;
  border-color: rgba(240, 240, 240, 0.9) !important;
}

.commentTitle {
  font-size: 14px !important;
  color: rgb(104, 104, 104) !important;
}
.comment {
  display: block;
}
.timeago {
  display: flexbox !important;
  text-align: right;
}
.textarea:focus {
  border-color: #edf2f7 !important;
  border-width: 0px;
  outline: none;
}
.textarea {
  overflow-y: hidden;
  resize: none;
  border-color: #edf2f7;
  background-color: #edf2f7;
  border-width: 0px;
  border-radius: 35px;
  width: 100%;
  appearance: none;
}
</style>
