<template>
  <div>
    <Navigation />
    <AutoLogout v-if="authUser" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["authUser"]),
  },
  head() {
    return {
      script: [
        {
          src: "landing-assets/js/app.js",
          type: "text/javascript",
        },
      ],
    };
  },
  name: "LandingLayout",
};
</script>
<!--style src="@/../public/landing-assets/fonts/icofont/icofont.min.css"></style>
<style src="@/../public/landing-assets/css/app.css"></style>
<style src="@/../public/landing-assets/css/sal.css"></style-->
<!--style src="@/../public/landing-assets/css/magnific-popup.css"></style>
<style src="@/../public/landing-assets/css/sal.css"></style>
<style src="@/../public/landing-assets/css/select2.min.css"></style-->
<style></style>
