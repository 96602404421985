<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="origin" v-if="!editing && origin && !getProfileEditingMode">
      <div class="icon">
        <img
          src="@/assets/images/profile/street-view.svg"
          alt=""
          width="14"
          height="14"
        />
      </div>
      <p class="info">
        <span class="label"
          >{{ $t("Origin") }} <span v-if="origin">: </span>
        </span>
        <span class="text">{{ origin }}</span>
      </p>
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div class="origin" v-if="!editing && getProfileEditingMode">
      <div class="icon">
        <img
          src="@/assets/images/profile/street-view.svg"
          alt=""
          width="14"
          height="14"
        />
      </div>
      <p class="info">
        <span class="label"
          >{{ $t("Origin") }} <span v-if="origin">: </span>
        </span>
        <span class="label">{{ origin }} </span>
      </p>
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div class="origin" v-if="editing">
      <select
        v-model="countryCode"
        class="col-md-5 mr-2 profile-controls"
        name="location"
        @input="handleSelect($event)"
      >
        <option
          v-for="(country, index) in countriesLists"
          :key="index"
          :value="country.code"
          :selected="
            getProfile.profile.birthCountry === country.code ? 'selected' : ''
          "
        >
          {{ country.name }}
        </option>
      </select>
      <save-cancel-button-group @save="save" @cancel="cancel()" />
    </div>
  </div>
</template>

<script>
import EditButton from "../../buttons/EditButton";
import SaveCancelButtonGroup from "../../buttons/SaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../../../../i18n";
export default {
  async mounted() {
    this.countryCode = this.getProfile.profile
      ? this.getProfile.profile.birthCountry
      : "";
    this.selected = await this.getCountries();
  },
  props: {
    origin: String,
  },
  components: {
    EditButton,
    SaveCancelButtonGroup,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "getProfileEditingMode",
      "showEditButtons",
      "getCountriesList",
    ]),
  },
  data() {
    return {
      countriesLists: [],
      editing: false,
      selected: "",
      countryCode: "",
    };
  },
  methods: {
    ...mapActions(["loadCountriesList"]),
    save() {
      this.$store
        .dispatch("updateUserProfile", {
          birthCountry: this.countryCode,
        })
        .then(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.origin = this.fortmatCountry(this.countryCode);
          this.$noty.success(this.$i18n.t("success"));
          this.editing = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    async getCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.countriesLists.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
};
</script>


<style scoped>
.profile-controls {
  width: auto !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: 1px solid var(--first-color) !important;
  border-radius: 0.3rem !important;
  outline: none !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>


<style lang="scss" scoped>
.origin {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .icon {
    line-height: 0;
  }

  .info {
    // flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    // text-align: center;

    .label {
      color: #666668;

      @include max-width(1125) {
        display: none;
      }

      @include max-width(991) {
        display: inline;
      }

      @include max-width(510) {
        display: none;
      }
    }

    .text {
      color: #48484a;
    }
  }
}
</style>
