<template>
  <div
    class="page"
    :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
  >
    <div class="page-container" v-if="!loading">
      <div class="partnership-card-wrapper">
        <template v-if="partnerships.length">
          <div
            v-for="partnership in partnerships"
            :key="partnership.id"
            class="partnership-card-block"
          >
            <partnership-card
              :description="partnership.description"
              :img-src="partnership.image"
              :title="partnership.title"
              :active="partnership.active"
              @view-offer="showModal(partnership)"
            />
          </div>
        </template>
        <template v-else>
          <div class="partnership-card-block">Empty</div>
        </template>
      </div>
      <Modal
        ref="modal"
        :description="activePartnership.description"
        :featured-company="activePartnership.featured_company"
        :img-src="activePartnership.image"
        :title="activePartnership.title"
        :video-link="activePartnership.video_link"
        :company_code="activePartnership.company_code"
        :link="activePartnership.link"
        :logo="activePartnership.logo"
        :active="activePartnership.active"
        :offer_code="activePartnership.offer_code"
      />
      <ModalError ref="errorModal" />
      <ModalSuccess ref="successModal" />
    </div>
    <Loader v-else />
  </div>
</template>
<script>
import PartnershipCard from "@/components/Partnership/PartnershipCard";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Bantaba | Exclusives Partner Offers",
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Partnerships",
  components: { PartnershipCard },
  async beforeMount() {
    await this.getPartnerships(this.getUserLang);
    this.loading = false;
  },
  computed: {
    ...mapGetters(["getUserLang", "authUser"]),
    ...mapState({
      activePartnership: (state) => state.Partnerships.activePartnership || {},
      partnerships: (state) => state.Partnerships.partnerships || [],
    }),
  },
  methods: {
    ...mapMutations({
      setActivePartnership: "SET_ACTIVE_PARTNERSHIP",
    }),
    ...mapActions(["getPartnerships"]),
    showModal(partnership) {
      this.setActivePartnership(partnership);
      this.$refs.modal
        .show()
        .then((modalHide) => {
          this.$refs.successModal.show().catch((hide) => {
            modalHide();
            hide();
          });
        })
        .catch((modalHide) => {
          this.$refs.errorModal
            .show()
            .then((hide) => {
              hide();
              modalHide();
            })
            .catch((hide) => {
              hide();
              modalHide();
            });
        });
    },
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>
<style scoped>
.showNotVerifiedNotfication {
  padding-top: 70px;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.page {
  background-color: #f3f4f5;
  position: relative;
  height: calc(100vh - 72px);
  width: 100vw;
  margin-top: 0;
  margin-left: 0;
  display: flex;
  justify-content: center;
}

.page-container {
  width: 100%;
  min-height: 100%;
  padding: 0 10px;
}

.partnership-card-block {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  flex: 100%;
  max-width: 100%;
}

.partnership-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  overflow-y: auto;
  margin-right: -10px;
  margin-left: -10px;
  /* padding: 40px 0px 0; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
  .page {
    background-color: #f3f4f5;
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    margin-top: 0;
    margin-left: 0;
    padding: 20px;
  }

  .page-container {
    width: 100%;
    max-width: 990px;
  }

  .partnership-card-block {
    flex: 50%;
    max-width: 50%;
    padding: 0 10px;
  }

  .partnership-card-wrapper {
    padding: 0px;
  }

  .showNotVerifiedNotfication {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .showNotVerifiedNotfication {
    padding-top: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .partnership-card-block {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 10px;
  }
  .partnership-card-wrapper {
    padding: 20px 0px 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .partnership-card-block {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .partnership-card-block {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 10px;
  }
}
</style>
