import { render, staticRenderFns } from "./StepButton.vue?vue&type=template&id=4ed95662&scoped=true&"
import script from "./StepButton.vue?vue&type=script&lang=js&"
export * from "./StepButton.vue?vue&type=script&lang=js&"
import style0 from "./StepButton.vue?vue&type=style&index=0&id=4ed95662&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed95662",
  null
  
)

export default component.exports