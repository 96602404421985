<template>
  <div class="rightContainer">
    <div>
      <SectionHeader
        text="Help us match you to "
        emphasize=" the right diaspora!"
      />
      <div
        class="experience-container-startup"
        v-if="getAccountType === 'Startup'"
      >
        <EngagementTypes
          title="What is your startup looking for?*"
          :options="engagementTypes"
          :err_msg="err_msg.supportType"
        />
      </div>
      <SelectExpertise
        searchTitle="What type of expertise is your startup looking for?*"
        searchPlaceHolder="Search for area of expertise"
        :err_msg="err_msg.expertises"
        :expertises="expertises"
      />
    </div>
    <div class="step-footer">
      <StepButton
        text="previous"
        @click.native="goPreviousBis(3)"
        :icon="require('@/assets/registration/icons/previous.svg')"
        class="previous"
      />
      <StepButton
        text="Next"
        @click.native="nextStep"
        :icon="require('@/assets/registration/icons/next.svg')"
        class="step3_startup_next"
      />
    </div>
  </div>
</template>
<script>
import SectionHeader from "../../../../components/Auth/SectionHeader.vue";
import EngagementTypes from "../../../../components/Auth/EngagementTypes.vue";
import SelectExpertise from "../../../../components/Auth/SelectExpertise.vue";
import StepButton from "../../../../components/Auth/StepButton.vue";
import i18n from "../../../../i18n";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  async mounted() {
    var lang = this.getUserLang;
    if (this.getAccountType === "Diaspora") {
      await this.loadSectorsList({ lang });
      this.getSectorstList.forEach((sector) => {
        this.sectors.push({
          id: sector.id,
          code: sector.code,
          name: sector.name,
        });
      });
    } else {
      await this.loadTalents({ lang });
      this.talents = this.getTalents;
      this.talents.forEach((talent) => {
        this.expertises.push({
          talent_id: talent.id,
          code: talent.code,
          name: talent.name,
        });
      });
    }
  },
  computed: {
    ...mapGetters([
      "getFomData",
      "getAccountType",
      "getLevelOfEducation",
      "getFordataField",
      "getCountriesList",
      "getForeignCountriesList",
      "getSectorstList",
      "getTalents",
      "getSector_err",
      "getUserLang",
    ]),
  },

  data: () => {
    return {
      startupStage: [
        {
          name: "Idea",
          icon: require("@/assets/registration/icons/light-bulb.svg"),
        },
        {
          name: "Product/MVP",
          icon: require("@/assets/registration/icons/new-product.svg"),
        },
        {
          name: "Go to Market",
          icon: require("@/assets/registration/icons/diagram.svg"),
        },
        {
          name: "Growth",
          icon: require("@/assets/registration/icons/growth.svg"),
        },
      ],
      engagementTypes: [
        {
          name: "Invest",
        },
        {
          name: "Consulting",
        },
        {
          name: "Mentoring",
        },
      ],
      error: false,
      hasError: false,
      expertises: [],
      msg: null,
      errors: {},
      sectors: [],
      err_msg: {
        expertises: "",
        supportType: "",
      },
    };
  },
  components: {
    SectionHeader,
    StepButton,
    EngagementTypes,
    SelectExpertise,
  },
  methods: {
    ...mapActions([
      "loadSectorsList",
      "loadTalents",
      "loadCountriesList",
      "loadForeignCountriesList",
    ]),
    ...mapMutations(["gotoNext", "goPrevious", "goPreviousBis", "goNextBis"]),
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async nextStepStartup() {
      let supportType = this.getFordataField("supportType");
      let expertises = this.getFordataField("expertises");

      if (supportType && supportType.length) {
        this.errors.supportType = false;
        this.err_msg.supportType = null;
      } else {
        this.errors.supportType = true;
        this.err_msg.supportType = "Select an option";
      }

      if (expertises && expertises.length) {
        this.errors.expertises = false;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_exp_diasp",
          value: null,
        });
      } else {
        this.errors.expertises = true;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_exp_diasp",
          value: "Select an option",
        });
        //this.err_msg.expertises = "Select an option";
      }
      let containsError = Object.values(this.errors).includes(true);
      if (!containsError) {
        this.goNextBis(4);
      }
    },
    async nextStep() {
      this.nextStepStartup();
      window.dataLayer.push({ event: "step3_startup_next" });
    },
  },
  name: "StepThreeContent",
};
</script>
<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}
.rightContainer {
  background-color: rgb(255, 255, 255);
  height: 100%;
  flex-grow: 0.6;
  padding: 20px 0px;
  padding-bottom: 0;
  align-items: flex-start;
  position: relative;
  text-align: left;
}
.right-header {
  display: flex;
  padding: 0px 0px;
  align-items: flex-start;
}
.select-country-container {
  display: flex;
  padding: 0px 50px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 50px;
}

.areaOfExpertise {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  align-items: flex-start;

  /* background: #00800011; */
  border-radius: 44px;
}
.areaOfExpertiseListConainter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 128, 0, 0.016);

  /* padding: 20px; */
  width: 100%;
  padding-left: 0;
}
#ul-expertise {
  /* display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 14.5px; */
  row-gap: 8px;
  width: 100%;
  align-items: center;
  height: 370px;
  overflow-y: auto;
  /* border-top: 1px solid rgba(0, 128, 0, 0.201);
  border-bottom: 1px solid rgba(0, 128, 0, 0.201); */
  padding: 10px 0;
  place-content: baseline;

  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
#ul-expertise li {
  list-style-type: none;
  padding-left: 10px;
  /* background-color: blue; */
}

.searchContainer {
  width: 100%;
  padding-bottom: 10px;
  font-size: 22px;
}
.search-input {
  height: 55px;
  border: 1px solid #ccc !important;
  width: 50%;
  padding: 12px 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
input:focus {
  outline: none !important;
  border: 1px solid #39a251 !important;
}

.step-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* width: 62%; */
  height: 80px;
  padding: 10px 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
}
.step-button {
  width: 195px;
  height: 55px;
  border-radius: 32px;
  padding: 4px;
  background-color: #f4f4f4;
  border: none;
}
.step-button div {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 24px; */
  background-color: #ffffff;
  color: #000000;
  /* background-color: #F4F4F4; */
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.step-btn-icon {
  width: 14px;
  height: 14px;
}
span.previous {
  padding-left: 15px;
}
span.next {
  padding-right: 15px;
}
.step-button div:hover {
  background-color: #589f5a;
  color: #ffffff;
}
.emphasize {
  color: #349e4d;
  font-weight: bold;
}
.experience-container-startup {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px;
  row-gap: 15px;
  padding: 20px 50px;
}

@media only screen and (max-width: 600px) {
  .rightContainer {
    background-color: rgb(255, 255, 255);
    height: calc(100% - 0.4vh);
    width: 100%;
    flex-grow: 0.6;
    padding: 10px 0px;
    align-items: flex-start;
    position: relative;
    overflow-y: scroll;
    z-index: 10000;
  }
  .right-header {
    display: flex;
    padding: 0px 15px;
    align-items: flex-start;
    text-align: center;
  }
  .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    padding: 25px 0px;
    margin-top: -40px;
    text-align: center;
    width: 100vw;
    z-index: 1000;
    border-radius: 64px;
    background-color: #ffffff;
  }

  .experience-container {
    padding: 0px 15px;
  }
  #ul {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 5px;
    width: 100%;
  }

  li {
    list-style: none;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .areaOfExpertise {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    align-items: flex-start;
  }
  .areaOfExpertiseListConainter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(0, 255, 0); */
    width: 100%;
    padding-left: 0;
    padding-bottom: 60px;
  }
  #ul-expertise {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 14.5px;
    row-gap: 10px;
    width: 100%;
    align-items: center;
    height: 370px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;

    /* overflow-y: visible !important;
    height: auto !important; */
  }
  #ul-expertise li {
    list-style-type: none;
    /* background-color: blue; */
  }

  .see-more-btn {
    width: 50px;
    height: 70px;
    border-radius: 50px;
    border: none;
    position: absolute;
    left: calc(50% - 25px);
    bottom: 35px;
    color: #808896;
    border: 1px solid rgba(0, 0, 0, 0.283);
    background-color: rgba(255, 255, 255, 0.577);
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
  }
  .see-more-btn span {
    padding: 0 10px;
  }
  .see-more-btn:hover {
    background-color: #39a251;
    color: white;
  }

  .searchContainer {
    width: 100%;
    padding-bottom: 10px;
  }
  .search-input {
    height: 45px;
    border: 1px solid #ccc !important;
    width: 100%;
    padding: 12px 20px;
    border-radius: 10px;
    box-sizing: border-box;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #39a251 !important;
  }

  .step-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 80px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);

    height: 90px;
    z-index: 999;
  }
  .step-button {
    width: 150px;
    height: 50px;
    border-radius: 32px;
    padding: 4px;
    background-color: #f4f4f4;
    border: none;
  }
  .step-button div {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .step-btn-icon {
    width: 14px;
    height: 14px;
  }
  span.previous {
    padding-left: 15px;
  }
  span.next {
    padding-right: 15px;
  }
  .step-button div:hover {
    background-color: #589f5a;
    color: #ffffff;
  }
  .experience-container-startup {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0px;
    row-gap: 15px;
    padding: 0px 15px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 320px) {
  #ul-expertise {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 8px;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    height: 290px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>