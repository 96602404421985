// this is for the startup searchbar
<template>
  <div class="container">
    <form class="form-inline-2 searchbar" @submit.prevent="lookupStartup">
      <img
        src="@/assets/svg/search.svg"
        alt="Search"
        width="20"
        height="20"
        class="search-img"
      />
      <vue-typeahead-bootstrap
        v-model="query"
        id="diasporaTypeHead"
        :data="getStartups"
        :serializer="(item) => item.login"
        highlightClass="special-highlight-class"
        @hit="selecteduser = $event"
        :minMatchingChars="3"
        :autocomplete="false"
        :placeholder="$t('searchStartups')"
        inputClass="form-control search-input-2 "
        class="search-input-2"
        :disabledValues="selecteduser ? [selecteduser.login] : []"
        @input="lookupStartup"
      >
      </vue-typeahead-bootstrap>
      <div class="clear-icon-container">
        <button
          class=""
          @click="clearSearch"
          :style="`opacity:${query ? 1 : 0}`"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            data-supported-dps="16x16"
            fill="currentColor"
            class="mercado-match"
            width="16"
            height="16"
            focusable="false"
          >
            <path
              d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
            ></path>
          </svg>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "../../i18n";
export default {
  metaInfo() {
    return {
      //  title: 'Default App Title',
      //  titleTemplate: '%s | vue-meta Example App',
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  mounted() {
   
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartups",
      "getSavedStartups",
      "getStartupsPage",
      "getStartupsLimit",
      "getStartupSearchFilters",
    ]),
  },
  data() {
    return {
      search: {},
      notFound: false,
      selecteduser: null,
      query: "",
    };
  },
  methods: {
    ...mapActions(["openChatBoxWithUser", "searchStartupsWithFilters"]),
    async clearSearch() {
      
      this.query = "";
      await this.searchStartupsWithFilters({
        data: {
          lang: this.$i18n.locale,
          startup_id: this.authUser.id,
          page: this.getStartupsPage,
          limit: this.getStartupsLimit,
          filters: this.getStartupSearchFilters,
        },
      });

      this.$store.commit("setStartups", this.getSavedStartups);
      this.$store.commit("setShowPagination", true);
      this.$store.commit("setShowStartupLoadMore", true);
    },
    lookupStartup: debounce(function () {
      // in practice this action should be debounced
      if (this.query) {
        this.$store.commit("setShowStartupLoadMore", false);
        fetch(`${APIURLS.STARTUP}lookupStartup/${this.query}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (!data.items.length) {
              this.notFound = true;
            }
            this.$store.commit("setStartups", data.items);
            this.$store.commit("setShowPagination", false);
          });
      } else {
        this.$store.commit("setStartups", this.getSavedStartups);
        this.$store.commit("setShowPagination", true);
        this.$store.commit("setShowStartupLoadMore", true);
      }
    }, 200),
  },
};
</script>


<style>
.search-input-2 {
  padding-left: 30px;
  border: 5px solid blue !important;
}

.search-input-2 .form-control[type="text"],
select,
textarea {
  border: none !important;
}

.search-input-2 {
  border: none !important;
  width: 100% !important;
  background-color: none;
}
</style>

<style scoped>
.form-inline-2 {
  width: 100% !important;
}
.clear-icon-container {
  position: relative;
  right: 30px;
  z-index: 9999;
}
.clear-icon-container button {
  background: rgb(176, 187, 186);
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 1000px;
}
.clear-icon-container :hover {
  background: rgb(146, 146, 146);
}
.clear-icon-container button svg {
  color: rgba(0, 0, 0, 0.6);
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px !important;
}

.btn {
  cursor: pointer;
  border: none !important;
}

img.search-img {
  width: 20px !important;
  height: 20px !important;
}

.searchbar {
  position: relative;
  box-sizing: border-box;
  padding: 0.3rem !important;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* background-color: blue; */
}

.search-img {
  margin-left: 1.5rem;
}
.searchbar input[type="text"] {
  border: none !important;
  font-size: 0.85rem;
  padding: 0 1.25rem !important;
  color: #000;
}
.searchbar input[type="text"]::placeholder {
  color: #8c8c8c;
  font-size: 0.85rem;
}
.searchbar input[type="text"]:focus {
  outline: none !important;
}

.btn-search {
  position: absolute;
  right: 10px;
  background-color: #39a251;
  color: #fff;
  border: 1px solid rgb(255 255 255 / 27%);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.7rem 3.5rem;
}
.btn-search:hover {
  background: #18792f;
}

@media (max-width: 991px) {
  .searchbar {
    padding: 0rem !important;
  }
  .search-img {
    padding: 0 0.5rem 0 0;
  }

  .searchbar input[type="text"] {
    padding: 0 0.5rem 0 0;
    font-size: 16px;
  }
  .btn-search {
    display: none;
  }
}
@media (max-width: 574px) {
  .searchbar {
    padding: 0 !important;
  }
}
@media (max-width: 600px) {
  .searchbar {
    height: 45px;
    padding-left: 10px !important;
    width: 95vw !important;
    transform: translateX(5px);
  }
  .search-img {
    position: absolute;
    z-index: 999;
    left: 0;
    padding: 0;
  }
  .clear-icon-container {
    position: relative;
    right: 5px;
    z-index: 9999;
  }
  .clear-icon-container button {
    background: rgb(176, 187, 186);
    width: 35px;
    height: 35px;
    border: 2px solid white;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* #diasporaTypeHead{
    max-height: 45px;
    max-width: 100vw;
    background-color: green !important;
    font-size: 16px !important;
  } */
}
*:focus {
  outline: none !important;
}
.special-highlight-class {
  border: orange;
}
</style>
