<template>
  <div class="card">
    <div class="card-header" :style="bgColorCss()">
      <!-- <div class="card-image">
        <cld-image
          class="img-profile rounded-circle avatar"
          cloudName:bantaba
          :publicId="user.avatar"
          alt="Image Placeholder"
        >

          <cld-transformation height="200" width="100" crop="scale" />
          <cld-transformation border="5px_solid_red" radius="20" />
          <cld-transformation quality="low" />
        </cld-image>
      </div> -->
      <!-- <div class="card-image" :class="{ skeletonLoader: !avatar_isLoaded }">
        <img
          class="img-profile rounded-circle avatar"
          :src="avatar_image"
          @load="onImgLoad"
          alt="avatar"
        /> 
      </div>
      -->

      <div class="card-image">
        <img
          class="img-profile rounded-circle avatar"
          :src="user.avatar"
          alt=""
        />
      </div>
    </div>
    <div class="card-body">
      <div class="card-name">
        <p>{{ user.firstname }} {{ user.name }}</p>
      </div>
      <ul class="author-statistics residentialInfo" v-if="showFromOrigin">
        <li class="" v-if="user.profile">
          <a href="#" class="" v-if="user.profile.birthCountry">
            <span class="item-number residentLabel">{{ $t("from") }} </span>
            <img
              class="location-icon-mobile-only"
              src="@/assets/images/profile/street-view.svg"
            />
            <span
              class="item-text residentCityName"
              v-if="user.profile && user.profile.birthCountry"
              >{{ fortmatCountry(user.profile.birthCountry) }}</span
            >
            <span class="item-text residentCityName" v-else>--</span>
          </a>
        </li>
        <li>
          <a href="#" v-if="user.profile">
            <span class="item-number residentLabel">{{ $t("livesIn") }}</span>
            <img
              class="location-icon-mobile-only"
              src="@/assets/homepage/marker.svg"
            />
            <span
              class="item-text residentCityName"
              v-if="user.profile && user.profile.residenceContry"
              >{{ fortmatCountry(user.profile.residenceContry) }}
            </span>
            <span class="item-text residentCityName" v-else>-- </span>
          </a>
        </li>
      </ul>
      <div v-if="type != 'connected'">
        <template v-if="type === 'all'">
          <a
            v-if="user.status"
            class="card-btn messenger-button requested-button"
          >
            <span> {{ $t("requested") }}</span>
            <svg class="svg-icon" viewBox="0 0 20 20">
              <path
                fill="#777"
                d="M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z"
              ></path>
            </svg>
          </a>
          <a
            v-else
            class="card-btn messenger-button conect-button"
            @click="connect(user, index)"
          >
            <span> {{ $t("connect") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 30 24"
              data-v-0793f1d1=""
            >
              <path
                d="M29.25,9.75h-3v-3A.752.752,0,0,0,25.5,6H24a.752.752,0,0,0-.75.75v3h-3a.752.752,0,0,0-.75.75V12a.752.752,0,0,0,.75.75h3v3a.752.752,0,0,0,.75.75h1.5a.752.752,0,0,0,.75-.75v-3h3A.752.752,0,0,0,30,12V10.5A.752.752,0,0,0,29.25,9.75ZM10.5,12a6,6,0,1,0-6-6A6,6,0,0,0,10.5,12Zm4.2,1.5h-.783a8.16,8.16,0,0,1-6.834,0H6.3A6.3,6.3,0,0,0,0,19.8v1.95A2.251,2.251,0,0,0,2.25,24h16.5A2.251,2.251,0,0,0,21,21.75V19.8A6.3,6.3,0,0,0,14.7,13.5Z"
                fill="#1ca54c"
                data-v-0793f1d1=""
              ></path>
            </svg>
          </a>
          <a
            class="card-btn profile-button view-profile"
            :href="`/${getUserLang}/profile/${user.username}`"
          >
            <span>{{ $t("viewProfile") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="24"
              viewBox="0 0 36 24"
            >
              <path
                id="FontAwsome_eye_"
                data-name="FontAwsome (eye)"
                d="M18,69a6.934,6.934,0,0,0-1.952.313A3.463,3.463,0,0,1,16.5,71,3.5,3.5,0,0,1,13,74.5a3.462,3.462,0,0,1-1.687-.452A6.982,6.982,0,1,0,18,69Zm17.783,6.088A20.046,20.046,0,0,0,18,64,20.049,20.049,0,0,0,.218,75.088a2.022,2.022,0,0,0,0,1.824A20.046,20.046,0,0,0,18,88,20.049,20.049,0,0,0,35.783,76.912a2.022,2.022,0,0,0,0-1.824ZM18,85A17,17,0,0,1,3.13,76,17,17,0,0,1,18,67a17,17,0,0,1,14.871,9A17,17,0,0,1,18,85Z"
                transform="translate(-0.001 -64)"
                fill="#8c8c8c"
              />
            </svg>
          </a>
        </template>
        <template v-else-if="type === 'requested'">
          <template v-if="received">
            <a
              class="card-btn messenger-button accept-button"
              @click="accept(user.id, index,user)"
            >
              <span> {{ $t("accept") }}</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#349E4D"
                class="bi bi-person-check-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                />
                <path
                  d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
            </a>

            <a
              class="card-btn messenger-button my-2 cancel-button"
              @click="cancel(user.id, index, received)"
            >
              <span> {{ $t("cancel") }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ff0000"
                class="bi bi-person-x-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </a>
            <a
              class="card-btn messenger-button mb-4 view-profile"
              :href="`/${getUserLang}/profile/${user.username}`"
            >
              <span>{{ $t("viewProfile") }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="24"
                viewBox="0 0 36 24"
              >
                <path
                  id="FontAwsome_eye_"
                  data-name="FontAwsome (eye)"
                  d="M18,69a6.934,6.934,0,0,0-1.952.313A3.463,3.463,0,0,1,16.5,71,3.5,3.5,0,0,1,13,74.5a3.462,3.462,0,0,1-1.687-.452A6.982,6.982,0,1,0,18,69Zm17.783,6.088A20.046,20.046,0,0,0,18,64,20.049,20.049,0,0,0,.218,75.088a2.022,2.022,0,0,0,0,1.824A20.046,20.046,0,0,0,18,88,20.049,20.049,0,0,0,35.783,76.912a2.022,2.022,0,0,0,0-1.824ZM18,85A17,17,0,0,1,3.13,76,17,17,0,0,1,18,67a17,17,0,0,1,14.871,9A17,17,0,0,1,18,85Z"
                  transform="translate(-0.001 -64)"
                  fill="#8c8c8c"
                />
              </svg>
            </a>
          </template>
          <template v-else>
            <a class="card-btn messenger-button requested-button">
              <span> {{ $t("requested") }}</span>
              <svg class="svg-icon" viewBox="0 0 20 20">
                <path
                  fill="#777"
                  d="M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z"
                ></path>
              </svg>
            </a>
            <a
              class="card-btn messenger-button my-2 cancel-button"
              @click="cancel(user.id, index, received)"
            >
              <span> {{ $t("cancel") }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ff0000"
                class="bi bi-person-x-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </a>
            <a
              class="card-btn messenger-button mb-4 view-profile"
              :href="`/${getUserLang}/profile/${user.username}`"
            >
              <span>{{ $t("viewProfile") }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="24"
                viewBox="0 0 36 24"
              >
                <path
                  id="FontAwsome_eye_"
                  data-name="FontAwsome (eye)"
                  d="M18,69a6.934,6.934,0,0,0-1.952.313A3.463,3.463,0,0,1,16.5,71,3.5,3.5,0,0,1,13,74.5a3.462,3.462,0,0,1-1.687-.452A6.982,6.982,0,1,0,18,69Zm17.783,6.088A20.046,20.046,0,0,0,18,64,20.049,20.049,0,0,0,.218,75.088a2.022,2.022,0,0,0,0,1.824A20.046,20.046,0,0,0,18,88,20.049,20.049,0,0,0,35.783,76.912a2.022,2.022,0,0,0,0-1.824ZM18,85A17,17,0,0,1,3.13,76,17,17,0,0,1,18,67a17,17,0,0,1,14.871,9A17,17,0,0,1,18,85Z"
                  transform="translate(-0.001 -64)"
                  fill="#8c8c8c"
                />
              </svg>
            </a>
          </template>
        </template>
        <template v-if="type === 'blocked'">
          <a
            class="card-btn messenger-button block-button mb-4"
            @click="unBlock(user.id, index, received)"
          >
            <span> {{ $t("unblock") }}</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 16 16"
            >
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z"
              />
            </svg>
          </a>
        </template>
      </div>
      <div v-else>
        <a
          class="card-btn messenger-button message-button"
          @click="openChatBoxWithUser(user)"
        >
          <span>Message</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28.44 25"
          >
            <path
              id="messenger"
              data-name="messenger"
              d="M13.714,32C6.139,32,0,36.987,0,43.143a9.89,9.89,0,0,0,3.054,7A13.629,13.629,0,0,1,.118,55.277a.426.426,0,0,0-.08.466A.42.42,0,0,0,.429,56a12.251,12.251,0,0,0,7.532-2.754,16.338,16.338,0,0,0,5.754,1.039c7.575,0,13.714-4.987,13.714-11.143S21.289,32,13.714,32ZM6.857,44.857a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,6.857,44.857Zm6.857,0a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,13.714,44.857Zm6.857,0a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,20.571,44.857Z"
              transform="translate(0.512 -31.5)"
              fill="#1BA54B"
              stroke="rgba(112,112,112,0.18)"
              stroke-width="1"
            />
          </svg>
        </a>
        <a
          class="card-btn profile-button view-profile"
          :href="`/${getUserLang}/profile/${user.username}`"
        >
          <span>{{ $t("viewProfile") }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="24"
            viewBox="0 0 36 24"
          >
            <path
              id="FontAwsome_eye_"
              data-name="FontAwsome (eye)"
              d="M18,69a6.934,6.934,0,0,0-1.952.313A3.463,3.463,0,0,1,16.5,71,3.5,3.5,0,0,1,13,74.5a3.462,3.462,0,0,1-1.687-.452A6.982,6.982,0,1,0,18,69Zm17.783,6.088A20.046,20.046,0,0,0,18,64,20.049,20.049,0,0,0,.218,75.088a2.022,2.022,0,0,0,0,1.824A20.046,20.046,0,0,0,18,88,20.049,20.049,0,0,0,35.783,76.912a2.022,2.022,0,0,0,0-1.824ZM18,85A17,17,0,0,1,3.13,76,17,17,0,0,1,18,67a17,17,0,0,1,14.871,9A17,17,0,0,1,18,85Z"
              transform="translate(-0.001 -64)"
              fill="#8c8c8c"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { io } from "socket.io-client";
export default {
  async mounted() {
    //await this.loadUsers(this.authUser.id);
    // this.sockets = io(APIURLS.LINKEDINSERVERLINK);
    // this.sockets.on("request", (data) => {
    //   if (data.user_id === this.authUser.id) {
    //     this.getNotifs();
    //   }
    // });

    let url = this.user ? this.user.avatar : "";
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // this.avatar_image = first_part + "/q_auto:low/c_fill,h_250,w_250/" + last_part;
      this.avatar_image =
        first_part + "/q_auto:low/c_fill,h_50,w_50/" + last_part;
    } else {
      this.avatar_image = this.user ? this.user.avatar : "";
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserCover",
      "getUsers",
      "getRerender",
      "getConnectionsCounts",
      "getUserNotifications",
      "getUserLang",
      "getUserTalents"
    ]),
  },
  props: {
    bgColor: String,
    user: Object,
    type: String,
    index: Number,
    showFromOrigin: {
      type: Boolean,
      default: false,
    },
    users: null,
    sent: {
      type: Boolean,
      default: false,
    },
    received: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatar_image: "",
      avatar_isLoaded: false,
      sockets: {},
    };
  },
  methods: {
    onImgLoad() {
      this.avatar_isLoaded = true;
    },
    ...mapActions([
      "getUserById",
      "openChatBoxWithUser",
      "sendConnectionRequest",
      "loadUsers",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "loadUserNotifications",
      "loadUserExpertises"
    ]),
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.$store.commit("setUserNewNotifications", this.getUserNotifications);
    },
    bgColorCss: function () {
      return {
        backgroundColor: this.bgColor,
      };
    },
    fortmatCountry(country) {
     
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, this.getUserLang, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, this.getUserLang));
    },
    async connect(user, index) {
       console.log({ user })
      let user_id = user.id;
      let countryOfOrigin, livesIn;
      let lang = this.getUserLang;
      let originCode = this.authUser.profile
        ? this.authUser.profile.birthCountry
        : "";
      countryOfOrigin = await this.getAFricanCountryByLang({
        code: originCode,
        lang,
      });
      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";

      livesIn = await this.getForeignCountryByLang({
        code: livesInCode,
        lang,
      });

      let expertise = '';
      let accept_link = `${APIURLS.SITELINK}${this.getUserLang}/${this.$i18n.t('routes.network')}/${this.$i18n.t('routes.pendingRequests')}`;
      let profile_link = `${APIURLS.SITELINK}${this.getUserLang}/profile/${this.authUser.username}`;

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });


      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }

     

      let data = {
        from: this.authUser.id,
        to: user_id,
        name: this.authUser.firstname + " " + this.authUser.name,
        receiver_name: user.firstname,
        email: user.email,
        lang,
        countryOfOrigin,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        livesIn,
        expertise,
        profile_link,
        accept_link
      };
      this.sendConnectionRequest({ data })
        .then(async () => {
          this.$store.commit("increaseSentRequests");
          let users = this.getUsers;
          user.status = true;
          users[index] = user;
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$store.commit("setUsers", users);
          // this.users.splice(index, 1);
          localStorage.setItem("networkUsers", JSON.stringify(this.getUsers));
          localStorage.setItem(
            "connectionsCount",
            JSON.stringify(this.getConnectionsCounts)
          );
          this.$noty.success("Success");
        })
        .catch((error) => {
          console.error({ error });
          this.$noty.error(error);
        });

      // Axios.post(`${APIURLS.CONNECTIONS}request`, {})
      //   .then(({ data }) => {
      //     this.$store.commit("increaseSentRequests");
      //     this.users.splice(index, 1);
      //     this.$noty.success("Success");
      //   })
      //   .catch((error) => {
      //     this.$noty.error(error.response.data.message);
      //   });
    },
    async accept(user_id, index, user) {
      let expertise;
      let location;
      let lang = this.getUserLang;
      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";
      
      location = await this.getForeignCountryByLang({
        code: livesInCode,
        lang,
      });

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });

      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }
      let data = {
        from: user_id,
        to: this.authUser.id,
        name: this.authUser.firstname + " " + this.authUser.name,
        sender_name: user.firstname + " " + user.name,
        sender_short_name: user.firstname,
        receiver_short_name: this.authUser.firstname,
        email: user.email,
        full_name: this.authUser.firstname + " " + this.authUser.name,
        lang: this.getUserLang,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        expertise,
        location,
        link: APIURLS.SITELINK,
      };
      Axios.post(`${APIURLS.CONNECTIONS}accept`, data)
        .then(async ({ data }) => {
          this.$store.commit("decreaseReceivedRequests");
          this.$store.commit("increaseConnected");
          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          localStorage.setItem("received", JSON.stringify(this.users));
          localStorage.setItem(
            "connectionsCount",
            JSON.stringify(this.getConnectionsCounts)
          );
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    unBlock(user_id, index) {
      let data = {
        from: this.authUser.id,
        to: user_id,
      };

      Axios.post(`${APIURLS.CONNECTIONS}unblock`, data)
        .then(({ data }) => {
          this.$store.commit("decreaseBlocked");
          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          localStorage.setItem("blocked", JSON.stringify(this.users));
          localStorage.setItem(
            "connectionsCount",
            JSON.stringify(this.getConnectionsCounts)
          );
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    cancel(user_id, index, received) {
      let data = {};
      if (received) {
        data = {
          from: user_id,
          to: this.authUser.id,
        };
      } else {
        data = {
          from: this.authUser.id,
          to: user_id,
        };
      }
      Axios.post(`${APIURLS.CONNECTIONS}cancel`, data)
        .then(({ data }) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.users.splice(index, 1);
          if (received) {
            this.$store.commit("decreaseReceivedRequests");
            this.$store.commit("setReceived", this.users);
            localStorage.setItem("received", JSON.stringify(this.users));
          } else {
            this.$store.commit("decreaseSentRequests");
            this.$store.commit("setSent", this.users);
            localStorage.setItem("sent", JSON.stringify(this.users));
          }
          localStorage.setItem(
            "connectionsCount",
            JSON.stringify(this.getConnectionsCounts)
          );
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    checkConnection(user_id) {
      Axios.get(
        APIURLS.CONNECTIONS +
          "connected/user/" +
          user_id +
          "/auth_user/" +
          this.authUser.id
      )
        .then((response) => {
          this.check = response.data;
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style scoped>
.author-statistics li {
  display: inline-block;
  position: relative;
  line-height: 1;
}

.author-statistics li:after {
  content: "";
  height: 31px;
  width: 1px;
  background-color: #e8e8e8;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.author-statistics li:last-child {
  margin-right: 0;
}

.author-statistics li:last-child:after {
  display: none;
}

.author-statistics li a {
  text-align: center;
  padding: 0 15px 0 12px;
}

.author-statistics .item-number {
  font-weight: 400;
  color: #9e9faf;
  display: block;
  margin-bottom: 5px;
}

.author-statistics .item-text {
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 65%;
}

.residentialInfo {
  display: flex;
  flex-direction: column;
  /* margin: 10px 0; */
  margin: 5px 0 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.057);
  border-top: 2px solid rgba(0, 0, 0, 0.057);
}
.residentialInfo > li > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px !important;
  flex-direction: row;
  font-size: 14px;

  padding: 10px 10px !important;
}
.residentLabel {
  font-size: 14px;
}
.residentCityName {
  font-size: 14px;
  font-weight: bolder !important;
  color: rgba(0, 0, 0, 0.708) !important;
  padding-left: 5px;
  /* text-align: right !important; */
}
svg {
  width: 1.125rem;
  height: 1.125rem;
}

.card-body {
  padding: 0rem;
}
.card {
  margin-top: 1rem;
  border: 1px solid #e4ece8;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  margin-top: 6px;
}

.card:hover {
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
}

.card-header {
  height: 4rem;
  background: rgba(24, 165, 75, 0.35);
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
  z-index: 3;
  position: relative;
}
.card-header::before {
  content: "";
  display: block;
  position: absolute;
  background: url("../../assets/images/bubble.png") repeat-x;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}
.card-header .card-image {
  width: 5rem;
  height: 5rem;
  border-radius: 4rem;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0rem);
  border: 0.4rem solid #fff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  font-size: 3.3rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeletonLoader {
  width: 5rem;
  height: 5rem;
  background-color: #e4e4e4 !important;
}

.skeletonLoader img {
  display: none !important;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-name {
  /* height: 6rem; */
  height: 4rem;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1.05rem;
  color: black !important;
  /* font-size: 1.3rem;
  padding-top: 40px; */

  font-size: 1.2rem;
  padding-top: 30px;
}

.card-name p {
  color: black !important;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  justify-content: center !important;
  align-items: center !important;
}
.card-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  margin-left: 8.5%;
  margin-right: 8.5%;
  border: 1px solid #1ba54b;
  border-radius: 0.8rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.view-profile {
  border: 1px solid #777;
  color: #777 !important;
}

.view-profile path {
  fill: #777 !important;
}

.view-profile:hover,
.view-profile:hover path,
.conect-button:hover,
.conect-button:hover path,
.message-button:hover,
.message-button:hover path,
.accept-button:hover,
.accept-button:hover path,
.requested-button:hover path,
.cancel-button:hover path,
.block-button:hover path {
  color: #fff !important;
  fill: #fff !important;
}
.requested-button {
  border: 1px solid #777;
  color: #777 !important;
  background: rgba(119, 119, 119, 0.04) !important;
}

.requested-button path {
  fill: #777 !important;
}
.requested-button:hover {
  background: rgb(119, 119, 119) !important;
  color: #fff !important;
  fill: #fff !important;
}

.block-button {
  border: 1px solid #777;
  color: #777 !important;
  background: rgba(119, 119, 119, 0.04) !important;
}

.block-button path {
  fill: #777 !important;
}

.block-button:hover {
  background: rgb(119, 119, 119) !important;
  color: #fff !important;
}
.cancel-button {
  background: rgba(245, 167, 167, 0.04) !important;
  border: 1px solid #ff0000;
  color: #ff0000 !important;
}
.cancel-button path {
  fill: #ff0000 !important;
}
.cancel-button:hover {
  background: #ff0000 !important;
  color: #fff !important;
}
.card-btn:hover {
  background: #1ba54b;
  color: #fff;
}
.card-btn:hover path {
  fill: #fff;
}
.messenger-button {
  color: #1ba54b;
}
.profile-button {
  background: #f6f6f6;
  color: #707070;
}
@media (max-width: 991px) {
  .card-name {
    font-size: 1.5rem;
  }
  .card-btn {
    font-size: 1.3rem;
  }
}
@media (max-width: 600px) {
  .card-name {
    font-size: 1rem;
  }
  .card-btn {
    font-size: 0.8rem;
    border-radius: 1.5rem;
  }

  .author-statistics .item-number {
    /* font-weight: 400;
  color: #9e9faf;
  display: block;
  margin-bottom: 5px; */
    display: none;
  }
  .location-icon-mobile-only {
    display: block;
    width: 10px;
    width: 20px;
    background: #2323231a;
    padding: 2.5px;
    border-radius: 50%;
    height: 20px;
  }
  .author-statistics .item-text {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    max-width: 80%;
    text-align: left;
    /* background-color: red; */
  }
  .residentialInfo {
    padding: 0 8.5%;
  }
}
@media (min-width: 1200px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0rem !important;
  }
  .location-icon-mobile-only {
    display: none;
  }
}
</style>