import { render, staticRenderFns } from "./StepThreeBis.vue?vue&type=template&id=0b481275&scoped=true&"
import script from "./StepThreeBis.vue?vue&type=script&lang=js&"
export * from "./StepThreeBis.vue?vue&type=script&lang=js&"
import style0 from "./StepThreeBis.vue?vue&type=style&index=0&id=0b481275&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b481275",
  null
  
)

export default component.exports