<template>
  <div class="container bg-loader">
    <div class="row loader">
     
      <div class="col-12 my-lg-5">
         
        <div class="centered">
          <h1 class="logo">BANTABA</h1>
           <NewLoader/>
          
         <!-- <spring-spinner
            :animation-duration="3000"
            :size="80"
            :color="'#1BA54B'"
          />  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewLoader from "./NewLoader"
import { AtomSpinner, SpringSpinner } from "epic-spinners";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Loader",
  components: {
    // SpringSpinner,
    NewLoader
  },
};
</script>
<style scoped>
.bg-loader{
   background-color: #1CA54B;
   background-color: #f9fcfa;
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000000;
}
.logo{
  font-size: 50px;
  color: #1CA54B;
  text-align: center;
  width: 100%;
  font-weight: bolder;
  margin-bottom: 200px;
  position: relative;
  

}
.logo:before{
  content: " ";
  display: inline-block;
  position: absolute;
  width: 90vw;
  height: 90vw;
  background-color: white;
  transform: translate(-13vw, -10vh) rotate(
45deg
);
border-radius: 30%;
    /* background: rgb(255,255,255); */
    background: radial-gradient(circle, rgb(255 255 255 / 18%) 0%, rgb(255 255 255 / 0%) 100%);
   box-shadow: 0 0 0 50px hsl(0deg 0% 80% / 5%), 0 0 0 100px hsl(0deg 0% 90% / 2%);
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color: #0f7f12;
}

@media  (min-width: 1200px) {
  .logo:before{
  content: " ";
  display: inline-block;
  position: absolute;
  width: 30vw;
  height: 30vw;
  /* background-color: rgb(255, 0, 0); */
  transform: translate(-7vw, -10vh) rotate(
45deg
);
border-radius: 30%;
box-shadow: 0 0 0 20px hsl(0deg 0% 80% / 5%), 0 0 0 50px hsl(0deg 0% 90% / 2%);
    /* background: rgb(255,255,255); */
}
}
</style>
