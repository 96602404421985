<template>
  <div class="va-card">
    <h4 class="vac-title capitalize">
      {{ post.title }}
    </h4>
    <div class="vac-loco-time">
      <div class="vac-location-box">
        <svg
          class="vac-icon"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 321.883 321.883"
          style="enable-background: new 0 0 321.883 321.883"
          xml:space="preserve"
        >
          <path
            d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882 C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801 c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
          />
        </svg>
        <span class="vac-location"> {{ fortmatCountry(post.country) }}</span>
      </div>
      <div class="vac-time-box">
        <svg
          class="vac-icon"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 395 395"
          style="enable-background: new 0 0 395 395"
          xml:space="preserve"
        >
          <g>
            <polygon
              points="278.333,52.5 278.333,12.5 248.333,12.5 248.333,52.5 146.667,52.5 146.667,12.5 116.667,12.5 116.667,52.5 0,52.5 0,117.5 395,117.5 395,52.5"
            />
            <path
              d="M0,382.5h395v-235H0V382.5z M262.5,195h50v50h-50V195z M262.5,285h50v50h-50V285z M172.5,195h50v50h-50V195z M172.5,285h50 v50h-50V285z M82.5,195h50v50h-50V195z M82.5,285h50v50h-50V285z"
            />
          </g>
        </svg>
        <span class="vac-time"
          ><timeago :datetime="post.created_at" :locale="$i18n.locale"></timeago
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  methods: {
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  name: "SideNewsCard",
  props: {
    post: {},
  },
};
</script>

<style scoped>
.va-card {
  padding: 15px;
  background-color: #ededed !important;
  margin: 15px 10px 0;
  box-sizing: border-box;
  border-radius: 10px !important;
  border: 1px solid #cfd4db !important;
}

.va-card:hover {
  background-color: rgb(247, 247, 247) !important;
  border: 2px solid #cfd4db !important;
  cursor: pointer;
}

.vac-title {
  margin-bottom: 10px;
  color: #53595f !important;
  font-size: 16px !important;
  text-align: left !important;
  font-weight: bold !important;
}

.vac-loco-time {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.vac-icon {
  width: 14px;
  height: auto;
  padding-right: 3px !important;
  fill: #b3b3b3 !important;
}
.vac-location-box,
.vac-time-box {
  color: #b3b3b3 !important;
  font-size: 14px;
}
</style>
