import Vue from 'vue';
const state = {
  //isNavModalOpen: false,
};

const getters = {
  getIsNavModalOpen: (state) => {
    //return state.isNavModalOpen;
  },
};

const actions = {};
const mutations = {
//   openNavModal(state) {
//     //state.isNavModalOpen = true;
//     let modal = document.getElementById('mobile-modal');
//     modal.style.transform = 'translateY(0%)';
//     modal.style.transform = 'scale(1, 1)';
//     modal.style.transformOrigin = 'bottom right';
//   },
//   closeNavModal(state) {
//     //state.isNavModalOpen = true;
//     let modal = document.getElementById('mobile-modal');
//     modal.style.transform = 'translateY(100%)';
//     modal.style.transform = 'scale(1, 1)';
//     modal.style.transformOrigin = 'bottom right';
//   },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
