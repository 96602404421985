<template>
  <div>
    <div class="card" v-if="is_team">
      <div class="m-1 box rounded profile-box">
        <div class="widget-author" id="user-view">
          <div class="author-heading">
            <div class="cover-img">
              <img :src="cover" alt="cover" />
            </div>
            <div class="profile-img">
              <a href="#">
                <img class="mx-5" :src="user.image" alt="image" />
              </a>
            </div>
            <div class="profile-name">
              <h4 class="author-name">
                <a href="#">
                  {{ user.name }}
                </a>
              </h4>
              <div class="author-location">{{ user.position }}</div>
              <p>
                {{ user.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2" v-else>
      <div class="box rounded profile-box">
        <div class="m-2 widget-author" id="user-view">
          <div class="author-heading">
            <div class="cover-img">
              <img :src="cover" alt="cover" />
            </div>
            <div class="profile-img">
              <a :href="`/${$i18n.locale}/profile/${user.username}`">
                <img
                  :class="type === 'willingToSupport' ? 'm-auto' : ''"
                  :src="user.avatar"
                  alt=""
                />
              </a>
            </div>
            <div class="profile-name">
              <h4 class="author-name">
                <a :href="`/${$i18n.locale}/profile/${user.username}`"
                  >{{ user.firstname }} {{ user.name }}
                </a>
              </h4>
              <!-- <div class="author-location">@{{ user.username }}</div> -->
            </div>
          </div>
          <div class="py-4 d-flex text-center network-item-footer">
            <button
              v-if="authUser.type === 2"
              type="button"
              @click="openChatBoxWithUser(user)"
              class="btn btn-sm btn-block inbox-btn"
            >
              <i class="fa fa-comments"></i>
              <span class="inbox">Chat</span>
            </button>

            <a
              :href="`/${$i18n.locale}/profile/${user.username}`"
              class="btn btn-sm btn-block view-profile"
            >
              <i class="feather-eye"></i>
              <span class="profileTitle">Profile</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
import Axios from "axios";
export default {
  async mounted() {
    this.routeName = this.$route.name;
    this.cover = this.getUserCover;
    if (this.showConnectButton) await this.getStatus();
  },
  data() {
    return {
      routeName: "",
      status: "",
      cover: "",
    };
  },
  computed: {
    ...mapGetters(["authUser", "getUserCover"]),
    connectionStatus() {
      return this.status;
    },
  },
  methods: {
    ...mapActions(["openChatBoxWithUser"]),
    // async openChat(user) {
    //   await this.openChatBoxWithUser(user);
    // },
  },
  name: "ProfileCard",
  props: {
    user: {},
    is_team: Boolean,
    type: String,
  },
};
</script>

<style scoped>
.widget-author {
  text-align: center;
  padding: 15px;
  position: relative;
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

.widget-author .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.widget-author .cover-img img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100px;
}

.widget-author .profile-img {
  margin-bottom: 26px;
}

.widget-author .profile-img img {
  width: 150px;
  height: 150px;
  /* border: 2px solid red !important; */
}

.widget-author .profile-img a {
  border-radius: 50%;
  position: relative;
}

.widget-author .profile-img a img {
  box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.widget-author .author-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-author .author-name a {
  color: #000000;
}

.widget-author .author-name a:hover {
  color: #0f7f12;
}

.widget-author .author-location {
  font-size: 14px;
  color: #9e9faf;
  margin-bottom: 5px;
}

.widget-author .author-badge {
  margin-bottom: 45px;
}

.widget-author .author-badge li {
  display: inline-block;
  margin-right: 8px;
}

.widget-author .author-badge li:last-child {
  margin-right: 0;
}

.widget-author .author-badge a {
  font-size: 20px;
  color: #ffffff;
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  position: relative;
}

.widget-author .author-badge a:before {
  content: "";
  height: 5px;
  width: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 8px;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}

.widget-author .author-badge .profile-circle {
  background-color: #e7ecf4;
  font-size: 16px;
  font-weight: 700;
  color: #8f8f8f;
}

.widget-author .author-statistics li {
  display: inline-block;
  position: relative;
  line-height: 1;
}

.widget-author .author-statistics li:after {
  content: "";
  height: 31px;
  width: 1px;
  background-color: #e8e8e8;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-author .author-statistics li:last-child {
  margin-right: 0;
}

.widget-author .author-statistics li:last-child:after {
  display: none;
}

.widget-author .author-statistics li a {
  text-align: center;
  padding: 0 15px 0 12px;
}

.widget-author .author-statistics .item-number {
  font-weight: 400;
  color: #9e9faf;
  display: block;
  margin-bottom: 5px;
}

.widget-author .author-statistics .item-text {
  font-weight: 400;
  color: #9e9faf;
  font-size: 12px;
}

.special-input-class {
  background-color: black !important;
  color: white !important;
}
.special-highlight-class {
  font-weight: 900;
  color: #585656;
}

.connection {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
}
.connectionTitle {
  height: 25px !important;
}
.network-item-footer {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}
.inbox-btn {
  padding: 10px 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start;
  background-color: rgba(41, 157, 2, 0.502) !important;
  color: #0f7f12;
  -moz-box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.393);
  -webkit-box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.393);
  box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.393);
  margin-right: 10px;
}
.inbox {
  padding-left: 10px;
  color: #0f7f12;
}
.view-profile {
  background-color: rgba(0, 0, 0, 0.112);
  width: 100%;
  height: 45px;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  margin-top: 15px;
  padding-left: 35px;
  -moz-box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.187);
  -webkit-box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.187);
  box-shadow: 3px 3px 5px 6px rgba(204, 204, 204, 0.187);
}
.profileTitle {
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.351);
}
@media (max-width: 768px) {
}
</style>
