<template>
  <button class="selectBtn" :class="active ? 'active' : ''">
    <img class="degree-img" v-if="icon != null" :src="icon" />
    <span>{{ $t(text) }}</span>
  </button>
</template>
<script>
export default {
  name: "SelectBtn2",
  props: {
    active: Boolean,
    text: String,
    icon: String,
  },
};
</script>
<style scoped>
.degree-img {
  width: 20px !important;
   display: none;
}
.selectBtn {
  border: 1px solid #ccd0d5;
  background-color: white;
  color: #171616;
  /* height: 48px; */
  width: 100%;
  border-radius: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 0;
}
.selectBtn span {
  padding-left: 10px;
}
.selectBtn.active {
  border-color: var(--first-color);
  color: var(--first-color);
}
/* .selectBtn:hover {
  border-color: var(--first-color);
  color: var(--first-color);
} */

@media only screen and (max-width: 600px) {
  .selectBtn {
    border: 1px solid #ccd0d5;
    background-color: white;
    color: #171616;
    height: 38px;
    width: 100%;
    border-radius: 15px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .degree-img {
    width: 12px !important;
  }
}
</style>