<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="educations">
    <career-title @add="add()" class="title">{{
      $t("Education")
    }}</career-title>
    <div class="container-box" v-if="!adding">
      <education
        v-for="(education, index) in educations"
        :key="index"
        :education="education"
      />
    </div>
    <add-education @cancel="cancel()" v-if="adding" />
  </div>
</template>

<script>
import Education from "./Education";
import CareerTitle from "./CareerTitle";
import AddEducation from "./AddEducation";

export default {
  props: {
    educations: Array,
  },
  components: {
    CareerTitle,
    Education,
    AddEducation,
  },
  data() {
    return {
      adding: false,
    };
  },
  computed: {
    // educations() {
    //   return this.$store.state.educations;
    // },
  },
  methods: {
    add: function () {
      this.adding = true;
    },
    cancel: function () {
      this.adding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.educations {
  margin-top: 15px;
}
.title {
  font-weight: bold !important;
}
.container-box{
  padding-bottom: 40px;
}
</style>
