<template>
  <div class="border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t('languages') }}</h6>
    </div>
    <div class="box-body">
      <form @submit.prevent="updateLanguages" v-if="!showLangs">
        <div class="border rounded bg-white mb-3">
          <div class="box-body px-3 pt-3 pb-0">
            <div class="row">
              <div class="col-sm-6 mb-4">
                <label id="language" class="form-label"
                  >{{ $t('language') }}
                  <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('language')"
                    v-model="form.name"
                    required
                  />
                </div>
              </div>
              <div class="col-sm-6 mb-4">
                <label id="TO" class="form-label"
                  >{{ $t('level') }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <select
                    class="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    v-model="form.level"
                    required
                  >
                    <option selected disabled>Choose...</option>
                    <option value="beginner">{{ $t('beginner') }}</option>
                    <option value="medium">{{ $t('medium') }}</option>
                    <option value="pro">{{ $t('pro') }}</option>
                    <option value="native">{{ $t('native') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <label id="companyLabel" class="form-label">Test</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="test"
                    v-model="form.test"
                  />
                </div>
              </div>
              <div class="col-sm-6 mb-4">
                <label id="positionLabel" class="form-label">{{
                  $t('grade')
                }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('grade')"
                    v-model="form.grade"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            :disabled="loading"
            type="submit"
            class="btn btn-success ml-3"
          >
            <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
            {{ loading ? '' : $t('save') }}
          </button>
          <button
            type="submit"
            @click="hideLanguages"
            class="btn btn-danger ml-3"
          >
            {{ $t('cancel') }}
          </button>
        </div>
      </form>
      <div class="card-body" v-else>
        <div v-if="langs.length">
          <div
            v-for="language in langs"
            :key="language.id"
            class="row langItem p-2"
          >
            <div class="col-12 mb-1">
              <h6 class="float-left">{{ $t(language.name) }}</h6>
              <button
                class="float-right btn btn-danger btn-xs pull-right"
                @click="deleteLang(language.id)"
              >
                <i class="feather-trash"></i>
              </button>
            </div>
            <div class="col-12">
              <p class="mb-0">
                {{ $t(language.level) }}
                <span v-if="language.test"> , {{ language.test }}</span>
                <span v-if="language.grade"> : {{ language.grade }}</span>
              </p>
            </div>
          </div>
        </div>
        <button
          type="button"
          @click="showLanguages"
          class="btn btn-primary mt-2"
        >
          {{ $t('addLang') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import APIURLS from '../../constant';
import { mapGetters, mapActions } from 'vuex';
export default {
  mounted() {
    this.langs = this.languages;
  },
  computed: {
    ...mapGetters(['getLanguages']),
    ...mapActions(['loadLanguages']),
  },
  data() {
    return {
      form: {},
      langs: {},
      loading: false,
      showLangs: true,
    };
  },
  methods: {
    showLanguages() {
      this.showLangs = false;
    },
    hideLanguages() {
      this.showLangs = true;
    },
    updateLanguages() {
      this.loading = true;
      this.$store
        .dispatch('createLang', {
          language: this.form,
        })
        .then((success) => {
          this.loading = false;
          this.showLangs = true;
          this.form = {};
          this.$noty.success(this.$i18n.t('languageAdded'));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
    deleteLang(lang_id) {
      this.$store
        .dispatch('deleteLang', {
          lang_id: lang_id,
        })
        .then((success) => {
          this.showLangs = true;
          this.$noty.success(this.$i18n.t('languageDeleted'));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  name: 'AddLanguages',
  props: {
    languages: {},
  },
};
</script>
<style>
.langItem:hover {
  background: #f6f6f6;
  cursor: pointer;
}
.langItem h6 {
  color: #0f7f12;
}
</style>
