<template>
  <div class="page-card-body">
    <template>
      <div
        class="description"
        id="about"
        v-if="type === `text` && text != `` && !editState"
        contentEditable="false"
      >
        <p
          id="discript_text"
          v-if="!isFullDescription"
          :class="{ fixedDisplay: showEditButtons }"
        >
          {{
            description ||
            "Oops! You have not description. Write a little bit about your startup"
          }}
        </p>
        <button
          class="seemore_btn"
          @click="showDetailDescription"
          v-if="showButton && !showEditButtons"
        >
          <svg
            class="list-ico"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="24"
            viewBox="0 0 36 24"
          >
            <path
              d="M35.783,75.088A20.046,20.046,0,0,0,18,64,20.049,20.049,0,0,0,.218,75.088a2.022,2.022,0,0,0,0,1.824A20.046,20.046,0,0,0,18,88,20.049,20.049,0,0,0,35.783,76.912a2.022,2.022,0,0,0,0-1.824ZM18,85a9,9,0,1,1,9-9A9,9,0,0,1,18,85Zm0-15a5.957,5.957,0,0,0-1.582.237,2.991,2.991,0,0,1-4.181,4.181A5.986,5.986,0,1,0,18,70Z"
              transform="translate(-0.001 -64)"
            />
          </svg>
          {{ $t("fullDescription") }}
        </button>
        <span
          class="dis_tooltip"
          :class="{ toggleSeeFullDiscription: isFullDescription }"
          id="dis_tooltip"
        >
          <div class="relative">
            {{ getStartupBio }}
            <button @click="closeToolTip" class="tooltip-close_btn">X</button>
          </div>
        </span>
      </div>
      <template v-if="type === `text` && editState">
        <div class="word-wrapper">
          <p class="word-left">Chars: {{ wordCount }}</p>
        </div>
        <div class="description">
          <textarea
            autofocus
            ref="description"
            rows="8"
            @keyup="setWordCount"
            class="description"
            @blur="setBio"
            v-model="bio"
          >
          </textarea>
        </div>
      </template>
    </template>

    <ul
      class="card-list"
      v-if="type === `list`"
      :class="{ fixScroll: !showEditButtons && editing }"
    >
      <div v-for="list in listData" :key="list.value">
        <template v-if="list.value || getStartupEditingMode">
          <li>
            <span class="label">{{ $t(list.label) }}</span>
            <!-- list.label === `Headquarters` || -->
            <!-- list.label === `Industry` || -->
            <span
              v-if="
                !editState &&
                (list.label === `Founded` ||
                  list.label === `Employees` ||
                  list.label === `Stage` ||
                  list.label === `SeekingFunding` ||
                  list.label === `BusinessType` ||
                  list.label === `RevenueStatus`)
              "
              contentEditable="false"
              :class="{
                emphasize: list.label === `SeekingFunding`,
                yesActive: list.value === 'Yes',
              }"
              class="value"
              ><span v-if="list.value">{{ $t(list.value) }}</span>
              <span v-else>{{ $t("Add") }}</span>
            </span>

            <span
              v-if="!editState && list.label === `website`"
              contentEditable="false"
              class="value"
            >
              <span v-if="list.value">
                <a
                  class="link"
                  @click="gotoContact(list.value)"
                  target="_blank"
                  >{{ list.value }}</a
                ></span
              >
              <span v-else>{{ $t("Add") }}</span>
            </span>

            <template v-if="getStartupEditingMode">
              <!-- list.label === `Headquarters` || -->
              <!-- list.label === `Industry` || -->
              <span
                v-if="
                  editState &&
                  !(
                    list.label === `Founded` ||
                    list.label === `Employees` ||
                    list.label === `Stage` ||
                    list.label === `website` ||
                    list.label === `SeekingFunding` ||
                    list.label === `BusinessType` ||
                    list.label === `RevenueStatus`
                  )
                "
                :id="list.label"
                contentEditable="true"
                class="value"
                >{{ $t(list.value) }}</span
              >
              <input
                v-if="editState && list.label === `website`"
                name="website"
                class="select formInput"
                :class="{error: isLinkError || isWebsiteLinkError}"
                :value="list.value"
                @change="changeWebsite"
              />
              <!-- <span class="tooltip">Please enter a valid URL</span> -->
              <!-- <CustomSelect
                v-if="editState && list.label === `Headquarters`"
                :options="africanCountries"
                :default="africanCountries[0].name"
                class="select"
                @input="changeCountry"
              /> -->
              <CustomSelect
                v-if="editState && list.label === `Founded`"
                :options="foundedYrs"
                :default="foundedYrs[0].code"
                class="select"
                @input="changeFounded"
              />
              <!-- <CustomSelect
                v-if="editState && list.label === `Industry`"
                :options="allSectors"
                :default="allSectors[0].code"
                class="select"
                @input="changeSector"
              /> -->
              <CustomSelect
                v-if="editState && list.label === `Employees`"
                :options="noOfEmployees"
                :default="noOfEmployees[0].code"
                class="select"
                @input="changeEmployees"
              />
              <CustomSelect
                v-if="editState && list.label === `Stage`"
                :options="stages"
                :default="stages[0].code"
                class="select"
                @input="changeStage"
              />
              <CustomSelect
                v-if="editState && list.label === `SeekingFunding`"
                :options="seekingFunding"
                :default="seekingFunding[0].code"
                class="select"
                @input="changeSeekingFounding"
              />
              <CustomSelect
                v-if="editState && list.label === `BusinessType`"
                :options="models"
                :default="models[0].code"
                class="select"
                @input="changeBusinessType"
              />
              <CustomSelect
                v-if="editState && list.label === `RevenueStatus`"
                :options="revenues"
                :default="`${$i18n.t(revenues[0].code)}`"
                class="select"
                @input="changeRevenueState"
              />
            </template>
          </li>
        </template>
      </div>
    </ul>
    <div></div>

    <ModalBasic v-if="showModal" @close="showModal = false">
      <template v-slot:body>
        {{ getStartupBio }}
      </template>
    </ModalBasic>
  </div>
</template>
<script>
import CustomSelect from "./CustomSelect.vue";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../../i18n";
import ModalBasic from "../../../Modal/ModalSimple";
export default {
  async mounted() {
    this.bio = this.text;
    await this.getAfricanCountries();
    await this.getAllSectors();
    if (this.type === `text` && this.getStartupBio != ``) {
      let maxChars = 440;
      if (this.getStartupBio.length < maxChars) {
        this.description = this.getStartupBio;
        this.showButton = false;
      } else {
        this.showButton = true;
        this.description = this.getStartupBio.substring(0, maxChars);
      }
    }
    var currentYear = new Date().getFullYear(),
      startYear = 1980;

    while (startYear <= currentYear) {
      let temp = startYear++;
      this.foundedYrs.push({ name: temp, code: temp, value: temp });
    }
    //get the nuber of line clamp
    // let discriptionText  = document.getElementById("discript_text");

    this.numOfLines = this.countLines();

    // this.numLine = this.calcNumberOfLines(
    //   "discript_text"
    // );
    // if (this.numLine <= 6) {
    //   this.canSeeMore = false;
    // } else {
    //   this.canSeeMore = true;
    // }
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Body",
  components: {
    CustomSelect,
    ModalBasic,
  },
  props: {
    text: String,
    type: String,
    editState: Boolean,
    listData: Array,
    startup_id: Number,
    isWebsiteLinkError: Boolean,
  },
  created() {
    // this.countLines();
    this.wordCount = this.getStartupBio.length;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getFollowers",
      "getStartup",
      "getFollowersCount",
      "getStartupEditingMode",
      "showEditButtons",
      "getForeignCountriesList",
      "getCountriesList",
      "getSectorsFilter",
      "getSectorstList",
      "getStartupBio",
      "getUserSupportTypes",
    ]),
    followStatus() {
      return this.followed;
    },
  },
  data() {
    return {
      // seekingFunding: {
      //   value: "",
      //   text: "",
      //   code: "",
      //   name: "",
      // },
      isLinkError: false,
      numOfLines: 0,

      showModal: false,
      wordCount: 0,
      bio: "",
      editing: false,
      isFullDescription: false,
      numLine: 0,
      canSeeMore: true,
      form: {},
      countries: null,
      description: "",
      showButton: false,
      africanCountries: [],
      countriesLists: [],
      allSectors: [],
      sector: "",
      sectors: [],
      seekingFundingValue: "",
      seekingFunding: [
        {
          name: "Yes",
          code: "Yes",
        },
        {
          name: "No",
          code: "No",
        },
      ],
      noOfEmployees: [
        {
          name: "0-5",
          code: "0-5",
        },
        {
          name: "6-10",
          code: "6-10",
        },
        {
          name: "11-15",
          code: "11-15",
        },
        {
          name: "16-20",
          code: "16-20",
        },
        {
          name: "21-25",
          code: "21-25",
        },
        {
          name: "50+",
          code: "50+",
        },
      ],
      stages: [
        {
          name: "Idea",
          code: "Idea",
        },
        {
          name: "Product-MVP",
          code: "ProductMVP",
        },
        {
          name: "GoToMarket",
          code: "GoToMarket",
        },
        {
          name: "Growth",
          code: "Growth",
        },
      ],
      models: [
        {
          name: "B2B",
          code: "B2B",
        },
        {
          name: "B2C",
          code: "B2C",
        },
        {
          name: "Hybrid",
          code: "Hybrid",
        },
      ],
      revenues: [
        { name: this.$i18n.t("Pre-revenue"), code: "Pre-revenue" },
        { name: this.$i18n.t("Post-revenue"), code: "Post-revenue" },
      ],

      foundedYrs: [],
    };
  },
  methods: {
    ...mapActions([
      "loadFollowers",
      "loadFollowersCount",
      "loadInterrested",
      "loadStartupNeeds",
      "loadInterrestedCount",
      "openChatBoxWithUser",
      "getUserById",
      "getSectorByLang",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "loadCountriesList",
      "loadStartupBySlug",
      "loadSectorsFilter",
      "loadSectorsList",
      "loadStartupTypeOfSupport",
    ]),
    gotoContact(link) {
      window.open("https://www." + link);
    },
    resizeTextarea: function (e) {
      let area = e.target;
      area.style.height = area.scrollHeight - 4 + "px";
    },
    setWordCount() {
      this.wordCount = this.bio.length;
    },
    setBio() {
      this.wordCount = this.bio.length;
      this.$store.commit("setStartupBio", this.bio);
    },
    async getAllSectors() {
      var lang = i18n.locale;
      await this.loadSectorsList({ lang });
      this.getSectorstList.forEach((sector) => {
        this.allSectors.push({
          id: sector.id,
          code: sector.code,
          name: sector.name,
        });
      });
    },
    async getAfricanCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.africanCountries.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    cancel: function () {
      this.editing = false;
    },
    removeHttp: function (url) {
  return url.replace(/^https?:\/\//, '');
},
removeTrailingSlash: function(str){
  return str.replace(/\/+$/, '');
},
checkURLValid: function (url) {
  //eslint-disable-next-line
  var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
},
checkUrlHasHttps: function(url){

   if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
        return true;
    }
    else{
        return false;
    }

},
    changeWebsite(option) {
      let enteredUrl  = option.target.value;
      let formattedUrl = ''
      //check
      if(enteredUrl && this.removeHttp(enteredUrl )){
        //  console.log(" change conditon remove thhp ", this.removeHttp(option.target.value))
         formattedUrl = this.removeHttp(enteredUrl)
      }else{
        formattedUrl = enteredUrl
      }
      let finalUrl = this.removeTrailingSlash(formattedUrl)
      if ( this.checkURLValid(enteredUrl) ) {
        this.isLinkError = true;
      }
      
      // console.log(" change input ", this.removeHttp(option.target.value))
      // console.log(" change input ", this.checkURLValid(option.target.value))
      // console.log(" change input", this.checkUrlHasHttps(option.target.value))
      this.$store.commit("setStartupOverviewForm", {
        property: "website",
        value: finalUrl,
      });
    },
    changeSector(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "sector",
        value: option,
      });
    },
    changeCountry(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "location",
        value: option,
      });
    },

    changeStage(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "stage",
        value: option,
      });
    },

    changeEmployees(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "numbInTeam",
        value: option,
      });
    },
    changeFounded(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "foundingYear",
        value: option,
      });
    },
    changeSeekingFounding(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "seekingFounding",
        value: option,
      });
    },
    changeBusinessType(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "business_model",
        value: option,
      });
    },
    changeRevenueState(option) {
      this.$store.commit("setStartupOverviewForm", {
        property: "revenue_state",
        value: option,
      });
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    async getSector() {
      let lang = this.$i18n.locale;
      let code = this.startup.sector;
      if (this.startup.sector != null) {
        this.sector = await this.getSectorByLang({ code, lang });
      } else {
        this.sector = "";
      }
    },
    countLines() {
      //count the number of text line
      var el = document.getElementById("discript_text");
      var lineHeight = parseInt(el.style.lineHeight);
      // return Math.round(divHeight / 20);
      const divHeight = +el.offsetHeight;
      return el.offsetHeight;
    },
    showDetailDescription() {
      
      this.showModal = true;
    },
    closeToolTip(e) {
      e.stopPropagation();
      this.isFullDescription = false;
      // let discription_tooltip = document.getElementById("dis_tooltip");
      // discription_tooltip.style.display = "none !important";
    },
    // calcNumberOfLines(elementId) {
    //   let el = document.getElementById(elementId);

    //   let divHeight = el.offsetHeight;
    //   let lineHeight = 24;
    //   let lines = divHeight / lineHeight;
    //   return el.offsetHeight;
    // },
  },
};
</script>

<style scoped>
.word-left {
  color: rgb(110, 118, 125);
  border: 1px solid rgba(0, 128, 0, 0.358);
  padding: 2px 8px;
  border-radius: 20px;
  color: rgba(0, 128, 0, 0.8);
  position: absolute;
  width: auto !important;
  z-index: 1000 !important;
}

.word-left {
  font-size: 14px;
  top: -38px;
  left: 100px;
  height: auto !important;
  padding: 3px;
}
textarea {
  width: 100%;
  overflow-y: scroll;
  font-size: 18px;
  margin: 1px;
  position: relative;
  z-index: 10 !important;
}

textarea:focus {
  border-radius: 4px !important;
  box-shadow: 0 0 5px #349e4d;
  outline: none !important;
}

.modal-body span {
  text-align: justify !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.formInput {
  border: 2px solid #00853c40;
  margin: 2px;
  padding: 5px 20px;
  width: 95%;
  height: 95%;
}
a > .link {
  color: var(--filter-color) !important;
}
.page-card-body {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #333333;
  height: calc(100% - 45px);
  padding-bottom: 15px;
}
.description {
  position: relative;
  height: 100%;
  /* max-height: calc(100% - 80px); */
  /* background-color: rgba(255, 0, 0, 0.029); */
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* overflow: hidden; */
  text-align: justify;
  text-justify: inter-word;
  max-width: 100%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 100%;
  width: 100%;
}
.description p {
  width: 100%;
  height: 80%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* overflow: hidden; */
  white-space: wrap;
  white-space: pre-line;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
  line-clamp: 9;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 6; */

  font-size: 16px;
  line-height: 134.99%;
  letter-spacing: 0.02em;
  padding: 0px 20px;
  max-width: 100%;
  overflow: hidden;
}
.fixedDisplay {
  display: flex !important;
}
/* #about span{
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 100%;

} */
.description .seemore_btn {
  /* position: absolute; */
  /* bottom: 0;
  left: 20px;
  bottom: 8px; */

  margin-left: 20px;
  margin-top: 10px;

  background: #00853c24;
  color: #00853c;
  font-size: 15px;
  padding: 2px 10px;
  border-radius: 10px;
  border: 1px solid #00853c;
}
.description .seemore_btn svg {
  fill: #00853c;
}

.description .seemore_btn:hover {
  background-color: #35a64e;
}

.description .seemore_btn:hover,
.description .seemore_btn svg {
  fill: #fff;
}

.dis_tooltip {
  position: absolute;
  /* background: #f5fff9; */
  background: white;
  /* box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915; */
  z-index: 1000000 !important;
  font-size: 16px;
  /* top: 0;
    bottom: 0; */
  left: 0px;
  right: 0px;
  height: auto;
  border-radius: 0;
  /* border-radius: 12px; */
  padding: 20px;
  padding-top: 20px;
  text-align: left;
  display: none !important;
  /* overflow: scroll !important; */
  /* visibility: hidden; */
  transition: all 0 ease-in-out;
  /* transform: translateY(-80px);  */
  transform: translateY(0);
}
.description:hover .dis_tooltip {
  /* visibility: visible; */
  /* transform: translateY(0px); */
}
.toggleSeeFullDiscription {
  display: block !important;
}

.relative {
  position: relative;
}
.tooltip-close_btn {
  position: absolute;
  top: -30px;
  right: 0px;
  width: 32px;
  height: 32px;
  z-index: 1000000 !important;
  background-color: #ff0000;
  border: 1px solid #4646461f;
  box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
  transition: all 0 ease-in-out;
}

.card-list {
  width: 100%;
  height: 100%;
  list-style: none;
  position: relative;
  /* overflow-y: scroll; */
}
.fixScroll {
  overflow: inherit;
}
.card-list li {
  font-size: 19px;
  padding: 5px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.057);
  display: flex;
  justify-content: space-between;
}
span.emphasize {
  /* background-color: #cfead9; */
  /* box-shadow: 0 0px 2px #071f0f05, 0px 0 6px #00020110, 0px 0px 18px #04130915; */
  border-radius: 5px;
  padding: 3px 0px;
  text-transform: uppercase;
  font-weight: bold !important;

  /* bottom: 0;
  left: 20px;
  right: 20px;
  margin: 10px 20px 0 20px; */
}
.yesActive {
  background-color: #1ca54b;
  color: white !important;
}
span.label {
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
span.value {
  font-weight: normal;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p[contenteditable="true"] {
  border: 2px solid #00853c40;
  margin: 2px;
  width: 95%;
  height: 95%;
}
p[contenteditable="true"]:focus {
  border: 2px solid #00853c40;
  margin: 0px;
  width: 100%;
  height: 100%;
}
.select {
  width: 200px;
  font-size: 15px;
  z-index: auto;
}

.error{
  border-color: red !important;
  position: relative;
}
.tooltip{
  display: none;
}
.error .tooltip{
  display: block;
  font-size: 14px;
  line-height:21px;
  padding: 5px 10px;
  background-color: red;
  color: white;
}
@media (max-width: 600px) {
  .word-left {
    font-size: 14px !important;
    top: -40px;
    left: 100px;
    height: auto !important;
    padding: 3px !important;
  }

  .page-card-body {
    padding-bottom: 20px !important;
  }
  .page-card-body p {
    font-size: 15px;
    padding: 15px 10px;
    padding-bottom: 30px;
    height: 95px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .fixedDisplay {
    height: auto !important;
  }
  .card-list li {
    font-size: 15px;
    padding: 5px 10px;
  }
  li.emphasize {
    left: 10px;
    right: 10px;
    margin: 10px 10px 0 10px;
  }

  .description {
    padding-bottom: 40px;
    align-items: flex-start;
  }
  .description .seemore_btn {
    bottom: 0;
    left: 10px;
  }
  .dis_tooltip {
    overflow: scroll !important;
    position: fixed;
    font-size: 16px;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0px;
    /* height: auto; */
    height: calc(100vh - 55px);
    text-align: justify;
    text-justify: inter-word;
    overflow: hidden;
    border-radius: 5px;
    padding: 0;
    text-align: left;
    transition: all 0.3 ease-in-out;
    transform: translateY(0px);
  }
  .relative {
    overflow-y: scroll;
    height: 100%;
    /* padding-bottom: 10px; */
    padding: 50px 15px;
    padding-bottom: 50px;
  }
  .tooltip-close_btn {
    position: fixed;
    top: 20px;
    right: 20px;
  }
}
@media (max-width: 370px) {
  .word-left {
    font-size: 12px !important;
    top: -35px;
    left: 80px;
    height: auto !important;
    padding: 3px;
  }
}
</style>
