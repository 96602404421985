<template>
  <div class="overlayed">
    <div class="non_authorized">
      <p>
        <span class="title">
          <span class="focus"><i class="fa fa-clock icon"></i>{{$t('Access pending…')}} </span>
          {{$t('Your profile is under review.')}}
        </span>
        <br />
        
        {{$t('This review helps create a safe community for members.')}}
        <br />
        {{$t('You will be notified by email once the review is completed.')}}
        
        <br />
        <a :href="`/${$i18n.locale}/${this.$i18n.t('routes.myActivity')}`" class="returnHome">
          {{$t('Return to Home')}}
        </a>
      </p>
      
      <!-- <p>{{ $t('NonApprovedMsg') }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PendingVerification",
  props: {
    size: Number,
    type: String,
    title: String,
    text: String,
    link: String,
  },
};
</script>
<style scoped>
.icon{
  margin-right: 4px;
}
.overlayed {
  background-image: linear-gradient(rgba(255, 255, 255, 0.667), rgb(64, 61, 61));
  width: 100%;
  height: 100vh !important;
  z-index: 10000 !important;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden !important;
}


.non_authorized {
  display: flex;
  width: 60%;
  position: relative;
  margin-left:20%;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.box {
  position: relative;
  width: 100%;
  margin: 20%;
  margin: 20px !important;
}
.non_authorized p {
  width: auto;
  border-radius: 8px;
  padding: 50px;
  font-size: 20px !important;
  background: white;
  text-align: center;
}

.focus{
  position: relative;
  font-weight: bold;
  color: black;
  text-align: center !important;
  margin-right: 10px;
}

.title{
  position: relative;
  text-align: center !important;
  bottom: 20px !important;
  
}

.returnHome{
  position: relative;
  top:20px;
  margin-left:37%;
  background: #329E4D;
  color: white;
  padding: 5px 15px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: none !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  padding:9px;

}

.returnHome:hover {
  background: #1d7734;
  color: white;
}

.is_not_confirmed {
  position: relative !important;
  z-index: 100 !important;
  height: 80vh !important;
  overflow: hidden !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .non_authorized {
      width:95%;
      margin-left: 2%;
    }
  .non_authorized p {
    position:relative;
    width: 100%;
    text-align: center;
    padding:30px;
    padding-bottom: 40px;
    font-size: 18px !important;
  }
  .returnHome {
      font-size: 17px !important;
      line-height: 20px;
      padding: 9px;
      margin-left: 25% !important;
  
    }
}

@media (max-width: 375px) {
  .non_authorized {
      width: 94%;
      margin-left: 2%;
  }
  
  .non_authorized p {
      position: relative;
      width: 100%;
      text-align: center;
      padding: 39px;
      padding-left: 30px;
      font-size: 16px !important;
    }


  .focus {
    margin-right: 5px;
  }
  
  .returnHome {
      font-size: 17px !important;
      line-height: 20px;
      padding: 9px;
      margin-left: 20% !important;
  
    }

  
}

@media (max-width: 330px) {
  .non_authorized {
    width: 94%;
    margin-left: 2%;
  }

  .non_authorized p {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 39px;
    padding-left: 30px;
    font-size: 16px !important;
  }


  .focus {
    margin-right: 3px;
  }

  .returnHome {
    font-size: 17px !important;
    line-height: 20px;
    padding: 9px;
    margin-left: 15% !important;

  }


}
</style>
