<template>
  <div class="header">
    <section class="left-header">
      <div class="chat-user-profile">
        <img
          class="rounded-circle gravatar"
          :src="currentlyChattingAvatar"
          @load="onImgLoad"
          alt=""
        />
        <!-- <div
          class="status-circle status-circle-online"
          v-if="getOtherUserIsOnline"
        ></div> -->
      </div>
      <button class="header-message-btn active-toggle title">
        <a class="view-profile" @click="viewProfile">
          {{ title }}
        </a>
      </button>
    </section>
    <section class="right-header">
      <button class="header-message-btn right-btn" @click="showActionModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path
            d="M3 9.5A1.5 1.5 0 114.5 8 1.5 1.5 0 013 9.5zM11.5 8A1.5 1.5 0 1013 6.5 1.5 1.5 0 0011.5 8zm-5 0A1.5 1.5 0 108 6.5 1.5 1.5 0 006.5 8z"
          ></path>
        </svg>
        <ActionModal v-if="getIsShowActionModal" />
      </button>
      <button
        class="header-message-btn mx-1 right-btn"
        @click="toggleBubbleActive"
      >
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path
            d="M5 1h2v6H1V5h2.59L0 1.41 1.41 0 5 3.59zm7.41 10H15V9H9v6h2v-2.59L14.59 16 16 14.59z"
          ></path>
        </svg> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          data-supported-dps="20x20"
          fill="currentColor"
          class="mercado-match"
          width="20"
          height="20"
          focusable="false"
        >
          <path
            d="M18.783,13.198H15.73c-0.431,0-0.78-0.35-0.78-0.779c0-0.433,0.349-0.78,0.78-0.78h2.273V3.652H7.852v0.922
							c0,0.433-0.349,0.78-0.78,0.78c-0.431,0-0.78-0.347-0.78-0.78V2.872c0-0.43,0.349-0.78,0.78-0.78h11.711
							c0.431,0,0.78,0.35,0.78,0.78v9.546C19.562,12.848,19.214,13.198,18.783,13.198z"
          ></path>
          <path
            d="M12.927,17.908H1.217c-0.431,0-0.78-0.351-0.78-0.78V7.581c0-0.43,0.349-0.78,0.78-0.78h11.709
							c0.431,0,0.78,0.35,0.78,0.78v9.546C13.706,17.557,13.357,17.908,12.927,17.908z M1.997,16.348h10.15V8.361H1.997V16.348z"
          ></path>
        </svg>
        <ButtonLabel :label="$t('MoreOptions')" />
      </button>
      <button class="header-message-btn right-btn" v-on:click="closeBubble">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path
            d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
          ></path>
        </svg>
      </button>
    </section>
  </div>
</template>

<script>
import ButtonLabel from "./ButtonLabel";
import ActionModal from "./ActionModal";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  // created() {
  // },
  mounted() {
    let profileUrl = this.currentlyChattingAvatar;
    if (profileUrl && profileUrl.indexOf("upload/") != -1) {
      let index = profileUrl.indexOf("upload/");
      let first_part = profileUrl.substr(0, index + 6);
      let last_part = profileUrl.substr(index + 6);
      this.profile_img =
        first_part + "/q_auto:low/c_fill,h_40,w_40/" + last_part;
    } else {
      this.profile_img = this.currentlyChattingAvatar;
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentlyChattingWith",
      "getIsShowActionModal",
      "getIsOnline",
      "getMessages",
      "getOtherUserIsOnline",
    ]),
    ...mapState(["isShowActionModal"]),
  },
  data() {
    return {
      profile_img: "",
      isLoaded: false,
    };
  },
  methods: {
    ...mapMutations(["toggleBubbleActive", "closeBubble", "showActionModal"]),
    viewProfile() {
      let url = `/${this.$i18n.locale}/profile/${this.getCurrentlyChattingWith.username}`;
      if (this.getCurrentlyChattingWith.type === 2) {
        url = `/${this.$i18n.locale}/startup/${this.getCurrentlyChattingWith.startup.slug}`;
      }
      window.location.href = url;
    },
    onImgLoad() {
      this.isLoaded = true;
    },
  },
  name: "BubbleHeader",
  props: {
    title: String,
    currentlyChattingAvatar: String,
  },
  components: {
    ActionModal,
    ButtonLabel,
  },
};
</script>

<style>
.header-message-btn.title {
  font-size: 15px !important;
}
button svg {
  width: 1rem;
  height: 1rem;
}
</style>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.view-profile {
  text-decoration: none !important;
  font-weight: 500;
  background-color: transparent;
  border: 0;
  padding: 25px 0px;
  border-radius: 15px !important;
  color: #000;
  width: 5px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.view-profile:hover {
  text-decoration: none !important;
  color: #0f7f13 !important;
  /* background-color: #0f7f132c; */
}
button {
  border: none;
  background: none;
}
*:focus {
  outline: none;
}
.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 0;
  position: relative;
  min-height: 48px;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.01);
}
.left-header {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  margin-right: 4px;
  padding: 4px;
  position: relative;
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
}
.chat-user-profile {
  width: 32px;
  height: 32px;
  /* background-color: orange; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-clip: content-box;
}
.header-message-btn {
  /* position: relative; */
  margin-left: 10px;
  max-width: 150px;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.42857;
  color: rgba(0, 0, 0, 0.9);
}
.right-btn {
  /* width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #5c5c5c; */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #5c5c5c;
}
.right-btn svg {
  width: 18px;
  height: 18px;
  font-weight: bold !important;
}

.right-btn svg > path {
  fill: black;
}
svg path {
  fill: black !important;
}
.right-btn:hover {
  background-color: #bae0c260;
  transition: all 0.2 ease-in-out;
  color: #3c9037;
}

.right-btn:hover,
.right-btn:hover path {
  fill: black !important;
}
.right-header {
  display: flex;
  justify-content: space-between;
}
.gravatar {
  width: 100%;
  height: 100%;
}
.active-toggle {
  font-size: 22px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 126px;
}
</style>