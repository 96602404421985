<script>
import { mapGetters, mapActions } from "vuex";
import { isWebUri } from 'valid-url';
export default {
    props: {
        GetModalContentCategory: String,
        GetModalMainContent: Object,
        text: {
            type: String,
            default: "founderFullName",
        },

        text1: {
            type: String,
            default: "linkedInProfileLink",
        },

        text2: {
            type: String,
            default: "enterAValidUrl",
        },

        text3: {
            type: String,
            default: "add",
        },

        text4: {
            type: String,
            default: "cancel",
        },

        text5: {
            type: String,
            default: "founderProfileText1",
        },

        text6: {
            type: String,
            default: "founderProfileText2",
        },

        text7: {
            type: String,
            default: "founderProfileText13",
        }
    },

    data() {
        return {
            docu_upload_a: "Click or drag and drop to upload a file",
            show_close_btn_a: false,
            upload_data: {
                founder_full_name: '',
                linked_in_url: ''
            },
            send_data: {
                id: null,
                value: "",
            },
            isDisable: false,
            success_mssg: false,
            isError: false,
            res: true
        }
    },

    mounted() {
        this.inputChanger();
    },

    created() {
        this.editUploadData();
    },

    methods: {
        ...mapActions(["updateVault"]),

        editUploadData() {
            this.upload_data.id = this.GetModalMainContent.id
            this.upload_data.founder_id = this.GetModalMainContent.founder_full_name_id
            this.upload_data.founder_full_name = this.GetModalMainContent.founder_full_name
            this.upload_data.linked_in_url = this.GetModalMainContent.linked_in_url
        },

        inputChanger() {
            this.checkdisable()

            if (this.upload_data.founder_full_name != '') {
                document.getElementById("inputTextA").style.background = "#B1E0C2"
            } else {
                document.getElementById("inputTextA").style.background = "#ffff"
            }

            if (this.upload_data.linked_in_url != '') {
                document.getElementById("inputTextB").style.background = "#B1E0C2"
            } else {
                document.getElementById("inputTextB").style.background = "#ffff"
            }
        },

        checkdisable() {
            if (this.upload_data.founder_full_name != '' && this.upload_data.linked_in_url != '') {
                this.isDisable = false
            } else {
                this.isDisable = true
            }
        },

        clearForm() {
            this.upload_data.founder_full_name = "",
                this.upload_data.linked_in_url = ""
        },

        closeMyModal() {
            this.$store.commit("set_show_modal", false)
        },

        submitForm() {
            if (!isWebUri(this.upload_data.linked_in_url)) {
                this.isError = true
                document.getElementById("inputTextB").style.background = "#ffff"
                this.res = false
            } else {
                Object.entries(this.upload_data).forEach(([key, value]) => {
                    if (key === "founder_full_name") {
                        this.send_data.id = this.upload_data.founder_id,
                            this.send_data.value = this.upload_data.founder_full_name
                        this.updateVault(this.send_data)
                            .then(() => {
                                this.$store.dispatch('getVault')
                            })
                    }

                    if (key === "linked_in_url") {
                        this.send_data.id = this.upload_data.id,
                            this.send_data.value = this.upload_data.linked_in_url
                        this.updateVault(this.send_data)
                            .then(() => {
                                this.$store.dispatch('getVault')
                            })
                    }


                    this.closeMyModal()

                })
            }
        }
    },
}
</script>

<template>
    <div>
        <div class="form-wrapper" v-if="!success_mssg">
            <div class=" text-left margin_top">
                <label class="label-bold remove_margin_bottom">{{ $t(text) }}</label>
                <div class="input-control">
                    <input type="text" class="inputText" id="inputTextA" v-model="upload_data.founder_full_name"
                        @change="inputChanger">
                </div>
            </div>

            <div class=" text-left margin_top">
                <label class="label-bold remove_margin_bottom">{{ $t(text1) }}</label>
                <div class="input-control" :class="{ fieldErr: isError }">
                    <input type="text" class="inputText" id="inputTextB" v-model="upload_data.linked_in_url"
                        @change="inputChanger">
                </div>
                <p class="danger" v-if="res === false">{{ $t(text2) }}</p>
            </div>

            <div class="margin_top d-flex justify-content-between pl-3 pr-3">
                <div>
                    <button class="button" :class="{ disable: isDisable }" @click="submitForm">{{ $t(text3) }}</button>
                </div>
                <div>
                    <button class="button button-color" @click="closeMyModal">{{ $t(text4) }}</button>
                </div>
            </div>
        </div>

        <div class="success_modal" v-else>
            <h3 class="text-left"><b>{{ $t(text5) }}</b></h3><br />
            <p>
                {{ $t(text6) }}
                <br /><br />
                {{ $t(text7) }}
            </p>
        </div>
    </div>
</template>

<style scoped>
.form-wrapper,
.success_modal {
    padding: 0 2em 2em 2em
}

.label-bold {
    font-weight: bold;
}

.input-control {
    width: 100%;
    border: 1px solid green;
    border-radius: .2em;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.fieldErr {
    border: 1px solid red;
}

.danger {
    color: red;
}

.inputText {
    padding: .5em;
    width: 100%;
    background-color: #fff;
    display: inline-block;
}

.inputfile+label {
    /* font-size: 1.25em;
        font-weight: 700; */
    padding: .5em;
    background-color: #fff;
    display: inline-block;
}

.cancel-upload-btn {
    width: 10px;
    height: 9px;
}

.margin_top {
    margin-top: 1.3em;
    z-index: 1000;
}

.remove_margin_bottom {
    margin-bottom: -2em;
}

.button {
    color: #131313;
    background: #B1E0C2;
    border: none;
    padding: .5em 2em .5em 2em;
    border-radius: 7px;
    width: 132px;
}

.button-color {
    color: #888;
    background: #fff;
    border: 1px solid #B1E0C2;
}


.disable {
    opacity: 0.5;
}

/* .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: red;
    } */
</style>