<template>
  <div class="green-container">
    <img class="blob-img" :src="blob" />
    <img alt="illustration" :src="illustration" class="illustration" />
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Illustration",
  props: {
    illustration: String,
    blob: String,
  },
};
</script>
<style scoped>
.green-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--primary-green);
  width: 90%;
  height: 80vh;
  border-radius: calc(var(--layout-radius) * 0.8);
}
.blob-img {
  position: absolute;
  height: 80%;
}
.illustration {
  height: 60vh;
  margin-bottom: 30px;
  z-index: 1000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .green-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--primary-green);
    width: 90%;
    height: 80vh;
    border-radius: calc(var(--layout-radius) * 0.8);
  }
  .blob-img {
    position: absolute;
    height: 80%;
  }
  .illustration {
    height: 50vh;
    margin-bottom: 30px;
    z-index: 1000;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>