import Axios from "axios";
import APIURLS from '../../constant';

const state = () => ({
    partnerships: [],
    activePartnership: {},
    offer_code: null,
    apply_results: null,
    company_code: null,
    loading: false,
});

const getters = {
    getOfferCode: (state) => state.offer_code,
    getCompanyCode: (state) => state.company_code,
    getApplyResults: (state) => state.apply_results,
    getLoading: (state) => state.loading
};

const actions = {
    getPartnerships({ commit }, lang) {
        return Axios.get(APIURLS.PARTNERSHIPS + 'lang/' + lang + '/all')
            .then(({ data: { partnerships } }) => {
                commit('SET_PARTNERSHIPS', partnerships)
            })
            .catch(() => {
                commit('SET_PARTNERSHIPS', [{
                    description: '',
                    image: '',
                    title: ''
                }])
            })
    },

    async getStartupByUserId({ commit }, user_id) {

        return new Promise((resolve, reject) => {
            Axios.get(APIURLS.STARTUP + 'user/' + user_id)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data.startup);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    async checkStartupCompleted({ commit }, startuo_id) {
        return new Promise((resolve, reject) => {
            Axios.get(APIURLS.STARTUP + 'check-startup-completed/' + startuo_id)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    async createClickTrack({ commit }, data) {
        return new Promise((resolve, reject) => {
            Axios.post(APIURLS.EXCLUOFFERSCLICKS + 'create', data)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });

    }
};

const mutations = {
    SET_PARTNERSHIPS: (state, partnerships) => state.partnerships = partnerships,
    SET_ACTIVE_PARTNERSHIP: (state, partnership) => state.activePartnership = partnership,
    SET_OFFER_CODE: (state, offer_code) => state.offer_code = offer_code,
    SET_COMPANY_CODE: (state, code) => state.company_code = code,
    SET_APPLY_RESULTS: (state, apply_results) => state.apply_results = apply_results,
    SET_LOADING: (state, loading) => state.loading = loading
};



export default {
    state,
    getters,
    mutations,
    actions,
};
