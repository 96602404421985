<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0 font-weight-bold text-black text-center">Experiences</h6>
    </div>

    <div v-if="experiences.length">
      <div
        class="box-body p-3 border-bottom"
        v-for="(experience, index) in experiences"
        :key="index"
      >
        <div class="d-flex align-items-top job-item-header pb-2">
          <div class="mr-2">
            <div class="">
              <h6 class="font-weight-bold text-dark mb-0">
                {{ experience.title }}
                <span
                  class="badge badge-pill badge-secondary"
                  v-if="experience.type"
                >
                  {{ $t(experience.type) }}
                </span>
              </h6>
            </div>
            <div class="text-truncate text-primary">
              {{ experience.company }}
              <br />
              <span class="text-secondary">
                <i class="feather-map-pin"></i> {{ experience.city }} -
                {{ fortmatCountry(experience.country) }}
              </span>
            </div>
            <div class="small text-black" v-if="$i18n.locale === 'fr'">
              {{ $d(new Date(experience.startDate), 'short', 'fr') }}
              -
              <span v-if="experience.endDate === 'now'">
                {{ $t(experience.endDate) }}
              </span>
              <span v-else>
                {{ $d(new Date(experience.endDate), 'short', 'fr') }}
              </span>
            </div>
            <div class="small text-black" v-else>
              {{ $d(new Date(experience.startDate), 'short', 'en-US') }}
              -
              <span v-if="experience.endDate === 'now'">
                {{ $t(experience.endDate) }}
              </span>
              <span v-else>
                {{ $d(new Date(experience.endDate), 'short', 'en-US') }}
              </span>
            </div>
          </div>
          <!-- <img
          class="img-fluid ml-auto mb-auto"
          src="https://res.cloudinary.com/yanfomaweb/image/upload/v1611151894/Bantaba/jobs.png"
          alt="Experience"
        /> -->
        </div>
        <p class="mb-0 text-justify">
          {{ experience.description }}
        </p>
      </div>
    </div>
    <NoData
      class="p-4"
      v-else
      text="noExperience"
      buttonText="addExperience"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.cursus')}`"
    />
  </div>
</template>

<script>
import Axios from 'axios';
import APIURLS from '../../constant';

import i18n from '../../i18n';
export default {
  methods: {
    fortmatCountry(country) {
      var countries = require('i18n-iso-countries');
      countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
      return (country = countries.getName(country, i18n.locale, {
        select: 'official',
      }));
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Experiences',
  props: {
    experiences: {},
  },
};
</script>
