import Login from '../views/auth/Login/Index.vue';
import Register from '../views/auth/SignUpDiaspora/Register.vue';
import ForgotPassword from '../views/auth/ForgotPassword/Index.vue';


// import Register from '../views/auth/Register.vue';
import RegisterDiaspora from '../views/auth/RegisterDiaspora.vue';
// import Login from '../views/auth/Login';
import CompleteDiaspora from '../views/auth/complete/diaspora';
import CompleteStartup from '../views/auth/complete/startup';
// import ForgotPassword from '../views/auth/ForgotPassword.vue';
import PendingConf from '../views/emails/pendingEmailConfirmation.vue';
import ConfirmEmail from '../views/emails/ConfirmEmail.vue';
import EmailConfirmed from '../views/emails/EmailConfirmed.vue';
import RecoverPassword from '../views/auth/RecoverPassword.vue';
import Activate from '../views/emails/Activate.vue';

export default [

  {
    path: "login",
    name: "LoginEn",
    component: Login,
    meta: {
      layout: "LoginLayout"
    }
  },
  {
    path: "se-connecter",
    name: "LoginFr",
    component: Login,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "register",
    name: "RegisterEn",
    component: Register,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "créer-un-compte",
    name: "RegisterFr",
    component: Register,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "activate/:verification_key",
    name: "ActivateEn",
    component: Activate,

  },
  {
    path: "activate/:verification_key",
    name: "ActivateFr",
    component: Activate,

  },
  {
    path: "new-pass/:verification_key",
    name: "RecoverPasswordEn",
    component: RecoverPassword,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "new-pass/:verification_key",
    name: "RecoverPasswordFr",
    component: RecoverPassword,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "recover-password",
    name: "ForgotPasswordEn",
    component: ForgotPassword,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "mot-de-passe-oublier",
    name: "ForgotPasswordFr",
    component: ForgotPassword,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "confirmation",
    name: "ConfirmEmailEn",
    component: ConfirmEmail,

  },
  {
    path: "confirmation",
    name: "ConfirmEmailFr",
    component: ConfirmEmail,

  },

  {
    path: "done",
    name: "EmailConfirmedEn",
    component: EmailConfirmed,

  },
  {
    path: "done",
    name: "EmailConfirmedFr",
    component: EmailConfirmed,

  },

  {
    path: "pending",
    name: "PendingConfEn",
    component: PendingConf,

  },
  {
    path: "pending",
    name: "PendingConfFr",
    component: PendingConf,

  },

  {
    path: "register/diaspora",
    name: "RegisterDiasporaEn",
    component: RegisterDiaspora,
    meta: {
      layout: "LoginLayout"
    }

  },
  {
    path: "creer-un-compte/diaspora",
    name: "RegisterDiasporaFr",
    component: RegisterDiaspora,

  },
  {
    path: "register-diaspora-final-steps",
    name: "CompleteDiasporaEn",
    component: CompleteDiaspora,
  },
  {
    path: "register-diaspora-etapes-finales",
    name: "CompleteDiasporaFr",
    component: CompleteDiaspora,
  },
  {
    path: "register-startup-final-steps",
    name: "CompleteStartupEn",
    component: CompleteStartup,
  },
  {
    path: "register-startup-etapes-finales",
    name: "CompleteStartupFr",
    component: CompleteStartup,
  },
];
