<template>
  <div>
    <div>
      <a :href="`job/${job.slug}`">
        <div class="shadow-sm border rounded bg-white job-item mb-3">
          <div class="d-flex align-items-center p-3 job-item-header">
            <div class="overflow-hidden mr-2">
              <h6 class="font-weight-bold text-dark mb-0 text-truncate">
                {{ job.title }}
              </h6>
              <div class="text-truncate text-primary">{{ job.company }}</div>
              <div class="small text-gray-500">
                <i class="feather-map-pin"></i> {{ job.city }},
                {{ fortmatCountry(job.country) }}
                <br />
                <span class="text-danger">
                  <i class="feather-calendar"></i>
                  {{ job.deadline | formatDateEnglish }}
                </span>
              </div>
            </div>
            <img
              class="img-fluid ml-auto"
              :src="job.companyLogo"
              alt="company Logo"
            />
          </div>
          <div
            class="
              d-flex
              align-items-center
              p-3
              border-top border-bottom
              job-item-body
            "
          >
            <p class="text-left">
              {{ job.summary | str_limit(118) }}
            </p>
          </div>
          <div class="p-3 job-item-footer">
            <div class="row">
              <div class="col-md-6">
                <small class="text-secondary"
                  ><i class="feather-clock"></i> Posted

                  {{ job.created_at | formatDate }}
                </small>
              </div>
              <div class="col-md-6">
                <a
                  :href="`job/${job.slug}`"
                  class="btn btn-primary btn-sm float-right"
                  >{{ $t("readMore") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  methods: {
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  name: "SideJobOffer",
  props: {
    job: {},
  },
};
</script>

<style scoped>
.connection {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
}
.border,
.border-top,
.border-bottom {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.connectionTitle {
  height: 25px !important;
}
</style>
