<template>
  <div class="right-header">
    <h1>
      {{ $t(text) }}
      <br class="hideOnDesktop" />
      <span class="emphasize">{{ $t(emphasize) }}</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    text: {
      type: String,
      default: "",
    },
    emphasize: {
      type: String,
      default: "",
    },
  },
};
</script>

<style >
/* h1{ 
  background-color: #00ff5e0b;
} */
.right-header h1 {
  color: rgba(0, 0, 0, 0.536);
  font-size: 30px;
  font-family: inherit;
  padding: 20px 50px;
  margin-top: 0px;
  text-align: left;
  width: 100%;
  z-index: 1000;
  font-weight: normal;
  border-radius: 24px;
  /* background-color: #00ff5e0b; */
}
.right-header h1 {
  font-family: inherit !important;
  /* font-size:  2em !important; */
}
.emphasize {
  color: var(--first-color) !important;
  font-weight: bold;
}
.hideOnDesktop {
  display: none;
}
@media only screen and (max-width: 600px) {
  /* .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    font-family: inherit;
    margin-top: 0px;
    text-align: left;
    width: 100%;
    z-index: 1000;
    font-weight: normal;
    border-radius: 24px;
    font-size: 18px;
  } */

  .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    font-family: inherit;
    padding: 0px 0px;
    margin-top: 0px;
    text-align: left;
    width: 100%;
    z-index: 1000;
    font-weight: normal;
    border-radius: 24px;
    /* background-color: #00ff5e0b; */
    font-size: 18px;

    position: sticky;
    top: 0;
    background: #effaf0;
    width: 100vw;
    margin: 0;
    /* height: 80px; */
    left: 0;
    border-radius: 0;
    text-align: center;
    padding: 0;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
  }
  .emphasize {
    color: var(--first-color) !important;
    font-weight: bold;
    padding-left: 3px;
  }
}
@media only screen and (max-width: 320px) {
  .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 16px;
    height: 40px;
  }
}
/* @media only screen and (max-width: 600px) {
 .right-header h1 {
   
 }} */
</style>