<template>
  <div>
    <div class="mb-3 shadow-sm rounded box bg-white osahan-slider-main p-3">
      <div class="osahan-slider">
        <carousel
          :responsive="responsive"
          :dots="true"
          :nav="false"
          :autoplayLoop="true"
          :autoplay="true"
          :autoplayTimeout="10000"
        >
          <div
            class="osahan-slider-item"
            v-for="(startup, index) in startups"
            :key="index"
          >
            <div
              class="connection-item custom-card connection shadow-sm h-100 m-1"
            >
              <div class="custom-card-image">
                <a :href="`startup/${startup.slug}`">
                  <img class="img-fluid item-img" :src="startup.cover" />
                </a>
                <div class="button-g-btn button-g-btn-up">
                  <span>{{ $t(startup.sector) }}</span>
                </div>
              </div>
              <div class="p-3 pt-4">
                <div class="custom-card-body">
                  <h6 class="mt-2 mb-1">
                    <span class="text-gray-900 startup-name" href="#"
                      >{{ startup.startupname }}
                    </span>
                  </h6>
                  <span class="text-gray-500" href="#">
                    <i class="feather-map-pin"></i>
                    {{ fortmatCountry(startup.location) }}
                  </span>
                  <p class="text-black text-left startup-summary mt-1">
                    {{ startup.description | str_limit(60) }}
                  </p>
                </div>
                <div class="custom-card-footer d-flex align-items-center">
                  <span class="text-black">
                    <i class="feather-clock"></i>
                    {{ $t("founded") }}: {{ formatDate(startup.foundingYear) }}
                  </span>

                  <a
                    :href="`startup/${startup.slug}`"
                    class="btn btn-sm btn-primary ml-auto"
                  >
                    <i class="feather-eye"></i> Page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import i18n from "../../i18n";
export default {
  data() {
    return {
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
      },
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  name: "FeaturedStartups",
  props: {
    startups: {},
  },
};
</script>

<style>
/* p {
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.75);
} */
.startup-summary {
  height: 70px;
  font-size: 15px;
  line-height: 22px;
  /* color: rgba(0, 0, 0, 0.75); */
}
.startup-name {
  width: 100px;
}
</style>
