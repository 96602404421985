<template>
  <router-link class="form__button col-md-6" :to="buttonLink">
    <span>{{ $t(buttonText) }}</span> 
  </router-link>
</template>

<script>
export default {
  name: "AuthSimpleBtn",
  props: {
    buttonText: String,
    buttonLink: String,
  },
};
</script>

<style scoped>
.form__button {
  display: block;
  margin-left: auto;
  padding: 0.5rem;
  outline: none;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.08);
  background-color: white;
  color: #1e2531;
  font-size: 12px;
  font-weight: normal;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: auto;
  height: auto;
  letter-spacing: 1px;
  margin-top: 10px;
}
.form__button span {
  font-weight: bold;
  color: var(--primary-green);
}
.form__button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}
</style>