<template>
  <div class="talents" :class="{fixOverflow: editing}">
    <Header title="TalentsNeeded" />
    <div class="talent-neeed-body" :class='{smartFlex : startup_talents.length > minTag }'>
      <div :class="{ fullWidth: startup_talents.length === 0, wHundred: startup_talents.length > minTag }"
        class="looking-for-container" v-if="lookingFor.length ||editing ">
        <div class="lk-for-header">{{ $t("WereLookingFor") }}</div>
        <div v-if="lookingFor.length > 0" class="lk-for-body" :class="{ wHundred: startup_talents.length > minTag }">
          <button v-for="(support, index) in supports" :key="support.id" class="talent-btn wx-40"
            :class="{ center: editing === false, editBorder: editing }">
            {{ $t(support.name) }}
            <div v-if="editing" @click="DeleteLookingForItem(support.id, index)" class="close-icon">
              <svg viewBox="0 0 24 24" aria-hidden="true" class="
                  r-19zomfu
                  r-4qtqp9
                  r-yyyyoo
                  r-1xvli5t
                  r-dnmrzs
                  r-bnwqim
                  r-1plcrui
                  r-lrvibr
                ">
                <g>
                  <path
                    d="M13.414 12l5.793-5.793c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0L12 10.586 6.207 4.793c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414L10.586 12l-5.793 5.793c-.39.39-.39 1.023 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.793 5.793c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L13.414 12z">
                  </path>
                </g>
              </svg>
            </div>
          </button>
          <button v-if="lookingFor.length > 0 && lookingFor.length < 3 && editing" class="talent-add-btn">
            <div @click="AddLookingForItem()" class="add-icon">
              {{$t('Add')}}
            </div>
          </button>
        </div>
        <div v-if="getStartupEditingMode && lookingFor.length <= 0" class="lk-for-body">
          <p class="call-to-action">
            {{ $t("AddWhatYouAreLookingFor") }}
          </p>
          <button class="talent-add-btn">
            <div v-if="editing" @click="AddLookingForItem()" class="add-icon">
              {{$t('Add')}}
            </div>
          </button>
        </div>
        <div v-if="isAddItem" class="add-item-modal">
          <CustomSelect :options="AllLookingFor" :default="AllLookingFor[0].name" class="select"
            @input="addLookingFor" />
          <save-cancel-button-group class="justify-content-end space-top" @save="SaveLookingForItem"
            @cancel="hideForm" />
        </div>
      </div>
      <div :class="{ fullWidth: lookingFor.length === 0, wHundred: startup_talents.length > minTag }"
        class="looking-for-container">
        <div class="lk-for-header" :class="{ remove: this.Slength === 0 }">
          {{ $t("Within") }}...
        </div>
        <div v-if="startup_talents.length > 0 || editing" class="lk-for-body"
          :class="{ wHundred: startup_talents.length > minTag}">
          <button class="talent-btn wx-40" :class="{ center: editing === false, editBorder: editing }"
            v-for="(talent, index) in startup_talents" :key="index">
            {{ talent.name }}
            <div v-if="editing" @click="DeleteWithItem(talent.id, index)" class="close-icon">
              <svg viewBox="0 0 24 24" aria-hidden="true" class="
                  r-19zomfu
                  r-4qtqp9
                  r-yyyyoo
                  r-1xvli5t
                  r-dnmrzs
                  r-bnwqim
                  r-1plcrui
                  r-lrvibr
                ">
                <g>
                  <path
                    d="M13.414 12l5.793-5.793c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0L12 10.586 6.207 4.793c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414L10.586 12l-5.793 5.793c-.39.39-.39 1.023 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.793 5.793c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L13.414 12z">
                  </path>
                </g>
              </svg>
            </div>
          </button>
          <button v-if="talents.length  && editing" class="talent-add-btn">
            <div @click="AddWithInItem()" class="add-icon">
              {{$t('Add')}}
            </div>
          </button>
        </div>
        <div v-if="talents.length <= 0" class="lk-for-body">
          <p class="call-to-action">
            {{ $t("addSector") }}
          </p>
          <button class="talent-add-btn">
            <div @click="AddWithInItem()" class="add-icon">
              {{$t('Add')}}
            </div>
          </button>
        </div>
        <div v-if="isAddItemWithIn" class="add-item-modal">
          <CustomSelect :options="AllWithIn" :default="AllWithIn[0].name" :selected="AllWithIn[0].id"
            selectedOption="id" class="select" @input="addWithIn" />
          <save-cancel-button-group class="justify-content-end" @save="SaveWithIn" @cancel="hideFormWithIn" />
        </div>
      </div>
    </div>
    <template v-if="getStartupEditingMode">
      <edit-button class="edit-button" v-if="showEditButtons && !editing" @click.native="toggleEditMode" text="edit" />
      <edit-button class="edit-button" v-if="editing" @click.native="cancel" text="cancel" :isCancel="true" />
    </template>
  </div>
</template>
<script>
import Header from "./ShareElements/Header.vue";
// import Body from './ShareElements/Body.vue';
import i18n from "../../../i18n";
import EditButton from "../../../components/Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import SaveCancelButtonGroup from "../../../components/Profile/Diaspora/profile/buttons/SaveCancelButtonGroup.vue";
import CustomSelect from "./ShareElements/CustomSelect.vue";
import CustomSelectId from "./ShareElements/CustomSelectId.vue";
import SaveClose from "./ShareElements/SaveClose.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  async mounted() {
    const startup_id = this.startup_id;
    const lang = this.getUserLang;
    this.startup_talents = this.getStartupNeededTalents;
    this.supports = this.lookingFor;
    await this.loadStartupNeeds({ startup_id, lang });
    await this.loadTalents({ lang });
    this.AllWithIn = this.getTalents;
    this.getUserSupportTypes.forEach((support) => {
      if (support.typeOfSupport != "Invest") {
        this.lookingFor.push({
          id: support.id,
          name: support.typeOfSupport,
        });
      }
    });

    this.Slength = this.lookingFor.length;
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Talents",
  props: {
    talents: Array,
    startup_id: Number,
  },
  components: {
    Header,
    //CustomSelectId,
    CustomSelect,
    EditButton,
    SaveCancelButtonGroup,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartupEditingMode",
      "showEditButtons",
      "getUserSupportTypes",
      "getStartupNeededTalents",
      "getTalents",
      "getUserLang",
    ]),
  },
  data() {
    return {
      minTag: 4,
      lookingFor: [],
      startup_talents: [],
      supports: [],
      Slength: 0,
      support: "",
      talent: "",
      AllLookingFor: [
        // {
        //   name: "Invest",
        //   code: "Invest",
        // },
        {
          name: "Consulting",
          code: "Consulting",
        },
        {
          name: "Mentoring",
          code: "Mentoring",
        },
      ],
      AllWithIn: [
        "Frontend Dev",
        "UI/UX Design",
        "Marketing",
        "Backend Dev",
        "Backend Engineer",
        "Writer",
        "Data analyst",
      ],
      isAddItem: false,
      isAddItemWithIn: false,
      editing: false,
    };
  },
  methods: {
    ...mapActions([
      "loadStartupNeeds",
      "loadStartupTypeOfSupport",
      "loadTalents",
    ]),
    AddLookingForItem() {
      this.isAddItem = true;
    },

    SaveLookingForItem() {
     // alert(this.support);
      if (this.support) {
        let startup_id = this.startup_id;
        let support = this.support;
        this.$store
          .dispatch("createStartupTypeOfSupport", {
            startup_id,
            support,
          })
          .then(async () => {
            await this.loadStartupTypeOfSupport({ startup_id });
            this.supports = [];
            this.getUserSupportTypes.forEach((support) => {
              this.supports.push({
                id: support.id,
                name: support.typeOfSupport,
              });
            });
            this.isAddItem = false;
            this.$noty.success(this.$i18n.t("success"), "Success");
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      } else {
        this.$noty.error('Something went wrong !!!');
      }

    },

    AddWithInItem() {
      this.isAddItemWithIn = true;
    },

    hideForm() {
      this.isAddItem = false;
    },
    hideFormWithIn() {
      this.isAddItemWithIn = false;
    },

    SaveWithIn() {
      if (this.talent) {
        let startup_id = this.startup_id;
        let talent_id = this.talent;
        this.$store
          .dispatch("createStartupTalent", {
            startup_id,
            talent_id,
          })
          .then(async () => {
            const lang = i18n.locale;
            await this.loadStartupNeeds({ startup_id, lang });
            this.startup_talents = this.getStartupNeededTalents;
            this.isAddItem = false;
            this.$noty.success(this.$i18n.t("success"), "Success");
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
      this.isAddItemWithIn = false;
      this.withIn.push("Backend Engineer");
    },

    toggleEditMode() {
      this.editing = true;
    },
    saveEdits() {
      this.editing = false;
    },
    cancel: function () {
      this.editing = false;
    },
    addLookingFor(option) {
      // alert(option);
      this.support = option;
    },
    addWithIn(option) {
      this.talent = option;
    },
    DeleteLookingForItem(support_id, index) {
      let startup_id = this.startup_id;
      this.$store
        .dispatch("deleteStartupSupport", {
          startup_id,
          support_id,
        })
        .then(async () => {
          this.supports.splice(index, 1);
          this.$noty.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    DeleteWithItem(talent_id, index) {
      let startup_id = this.startup_id;
      this.$store
        .dispatch("deleteStartupTalent", {
          startup_id,
          talent_id,
        })
        .then(async () => {
          this.startup_talents.splice(index, 1);
          this.$noty.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.talents {
  position: relative !important;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
}
.fixOverflow{
  overflow: inherit;
}
.talent-neeed-body {
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
.looking-for-container {
  /* background-color: rgb(136, 255, 0); */
  width: 50%;
  position: relative;
}
.lk-for-header {
  /* font-family: Helvetica; */
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 134.99%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #333333;
}
.lk-for-body {
  display: flex;
  padding: 15px 0;
  flex-wrap: wrap;
}
.add-item-modal {
  position: absolute;
  background-color: white;
  z-index: 100;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 20px;
  border-radius: 15px;
  border: 2px solid #00853c30;
  box-shadow: 0 3px 6px 2px rgb(0 0 0 / 10%);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 15px;
}
/* .relativeContainer{
  position: relative;
  width: 100%;
  height: 100%;
} */
/* .customSelectWrapper{
  position: absolute;
  left: 0;
  right: 0;
  background-color: red;
} */
.add-item-modal .select {
  width: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  /* justify-content: flex-end; */
}
p.call-to-action {
  font-size: 19px;
  padding: 10px;
  color: #0000004d;
}
.talent-add-btn {
  width: 87px;
  height: 36px;
  border-radius: 2px;
  font-size: 14.5px;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.1);
  border: none;
  /* border: 1px solid #00853c; */
  /* background-color: white; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.talent-add-btn:hover {
  background-color: var(--green-color);
  color: #fff;
}
.add-icon {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* position: absolute;
    right: 25px; */
  /* transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); */
  /* transform: rotate(45deg); */
}
.add-icon svg {
  width: 19px;
  height: 19px;
  fill: #00853c;
}
.talent-btn {
  width: auto !important;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 25px !important;
  box-sizing: border-box;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12.5px;
  letter-spacing: 0.02em;
  /* color: #4f4f4f; */
  outline: none;
  position: relative;
  /* box-shadow: 0 3px 6px 2px rgb(0 0 0 / 10%); */
  font-weight: bold;
  background: #00000014;
  border: 1px solid #00000026;
  border-radius: 3px;
  color: var(--green-color);
  background: transparent;
  max-height: 36px;
  color: #000000b0;
  border: 1px solid #25252529;
}
.wx-40 {
  /* width: 43%; */
  width: auto;
  padding: 8px;
}

.close-icon {
  /* background-color: red !important; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  position: absolute;
  right: 25px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.talent-btn svg {
  width: 19px;
  height: 19px;
  position: relative;
  fill: #00853c;
}
.talent-btn:hover {
  /* background-color: #00853c10; */
  /* color: #00853c; */
  cursor: auto !important;
}
.talent-btn:hover .close-icon {
  transform: rotate(40deg);
  /* transform-origin: center center;
    transform-origin: center;
transform-origin: 50% 50%;
transform-origin: 300px 500px; */
}
.center {
  justify-content: center;
}
.edit-button {
  position: absolute !important;
  right: 20px;
  /* top: 20px; */
   top: 5px;
  padding: 5px 20px;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  z-index: 99;
}
.btn-wrapper {
  position: relative;
  background: white;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrapper .btn-edit {
  width: 25px;
  height: 25px;
  padding: 5px;
  line-height: 0;
  border: 1px solid hsla(0, 0%, 43.9%, 0.002);
  border-radius: 15px;
  background-color: hsla(0, 0%, 54.9%, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrapper .btn-edit {
  position: absolute;
  left: 2px;
}

.editBorder {
  color: #c31e3e;
  border-color: #c31e3e;
  width: 200px;
}
.editBorder:hover {
  color: #c31e3e;
  border-color: #c31e3e;
}
.editBorder > div > svg {
  fill: #c31e3e;
}
.editBorder:hover .editBorder > div > svg {
  fill: #c31e3e;
}
.fullWidth {
  width: 100%;
}
.remove {
  display: none;
}
.editBorder > .close-icon {
  right: 5px;
}
.space-top {
  padding-top: 10px;
}
.lk-for-header {
  font-size: 17px !important;
}
.smartFlex{
  flex-direction: column;
}
.wHundred{
  width: 100%;
}
@media (max-width: 600px) {
  .talents {
    margin-top: 0px;
    border-radius: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
  }
  .talent-neeed-body {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* flex-direction: row; */
    justify-content: space-between;
    padding: 15px 10px;
    width: 100vw;
  }
  .looking-for-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .talent-btn {
    /* width: 46%; */
    /* height: 45px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
    /* padding: 6px 12px; */
    /* background: #ffffff; */
    /* border: 1px solid #00853c; */
    box-sizing: border-box;
    /* border-radius: 100px; */
    /* margin-right: 2%; */
    margin-bottom: 10px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14.5px;
    line-height: 134.99%;
    letter-spacing: 0.02em;
    /* color: #4f4f4f; */
    outline: none;
    position: relative;
    box-shadow: 0 3px 6px 2px rgb(0 0 0 / 10%);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-right: 5px;
    width: auto;
    height: 40px;
    width: auto;
    padding: 8px;
    min-width: 28vw;
  }
  .close-icon {
    /* background-color: red !important; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    position: absolute;
    right: 5px;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .edit-button {
    right: 20px;
    top: 8px;
  }

  .lk-for-header {
    font-size: 20px;
  }
  .editBorder {
    color: #c31e3e;
    border-color: #c31e3e;
    width: 46vw;
    font-size: 13px;
  }
  .editBorder > .close-icon {
    right: 5px;
    width: 15px;
  }
  .name-position-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .input-col {
    width: 100%;
  }
}
</style>