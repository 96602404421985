<template>
  <div class="illustation-side">
    <div class="green-container">
      <img class="blob-img" src="@/assets/registration/blob.svg" />
      <img alt="illustration" :src="illustrationImg" class="illustration" />
    </div>
    <!-- <div class="side-header">
      <img
        alt="Bantaba-logo"
        class="logo-img"
        src="https://res.cloudinary.com/yanfomaweb/image/upload/v1608662753/Bantaba/bantabalogo.png"
      />
    </div> -->
  </div>
</template>
<script>
export default {
  name: "IllustrationLayout",
  props: {
    illustrationImg: String,
  },
};
</script>
<style scoped>
.illustation-side {
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  flex-basis: 0;
  flex-grow: 0.4;
  background-color: var(--primary-green);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 0px;
  width: 45%;
}
.green-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--primary-green);
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border-radius: 100%; */
  /* border-radius: calc(var(--layout-radius)*0.8); */
}
.blob-img {
  position: absolute;
  width: 95%;
  margin-top: 10px;
}
.illustration {
  height: 70%;  
  margin-bottom: 30px;
  z-index: 1000;
}
.side-header {
  position: absolute;
  top: 50px;
  left: 0%;
  width: 90%;
  /* padding-top: 50px;
    padding-left: 10%; */
}

.logo-img {
  width: 200px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .illustation-side {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    flex-basis: 0;
    flex-grow: 9;
    background-color: var(--primary-green);
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
    padding: 10px 0px;
    height: 10vh;
    /* overflow:hidden */
    display: none;
  }
  .green-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* border-radius: calc(var(--layout-radius)*0.8); */
  }
  .illustration {
    width: 70%;
    height: auto;
    margin-bottom: -85px;
    z-index: 1000;
  }
  .side-header {
    position: absolute;
    left: 5vw;
    width: 35%;
    width: 45%;
    border-radius: 5%;
    background-color: #00000014;
    z-index: 1000;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>