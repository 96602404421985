var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aside-filter-tabs",class:{offsetLayoutMargin: _vm.authUser.is_verified === 0}},[_c('div',{staticClass:"bg-white border p-2"},[_c('ul',{staticClass:"nav nav-justified",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link connection-aside",class:_vm.activeMenu('NetworkFr') || _vm.activeMenu('NetworkEn'),attrs:{"to":`/${_vm.$i18n.locale}/${this.$i18n.t('routes.network')}`,"role":"tab","aria-controls":"home","aria-selected":"true"}},[_c('i',{staticClass:"feather-users"}),_c('span',{staticClass:"navItemLable"},[_vm._v("Diaspora")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link connection-aside",class:_vm.activeMenu('ConnectedFr') || _vm.activeMenu('ConnectedEn'),attrs:{"to":`/${_vm.$i18n.locale}/${this.$i18n.t(
            'routes.network'
          )}/connections`,"role":"tab","aria-selected":"false"}},[_c('i',{staticClass:"feather-user-check text-dark"}),_c('span',{staticClass:"navItemLable"},[_vm._v("Connections ")]),_c('span',{staticClass:"mx-1 font-weight-bold counts"},[_vm._v("("+_vm._s(_vm.getConnectionsCounts.connected)+")")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link connection-aside",class:_vm.activeMenu('RequestedFr') || _vm.activeMenu('RequestedEn'),attrs:{"to":`/${_vm.$i18n.locale}/${this.$i18n.t(
            'routes.network'
          )}/${this.$i18n.t('routes.pendingRequests')}`}},[_c('i',{staticClass:"feather-clock text-dark"}),_c('span',{staticClass:"navItemLable"},[_vm._v(" "+_vm._s(_vm.$t("pendingRequests"))+" ")]),_c('span',{staticClass:"mx-1 font-weight-bold counts"},[_vm._v("("+_vm._s(_vm.getConnectionsCounts.receivedRequests + _vm.getConnectionsCounts.sentRequests)+")")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link connection-aside",class:_vm.activeMenu('BlockedFr') || _vm.activeMenu('BlockedEn'),attrs:{"to":`/${_vm.$i18n.locale}/${this.$i18n.t(
            'routes.network'
          )}/${this.$i18n.t('blocked')}`}},[_c('i',{staticClass:"feather-user-x text-dark"}),_c('span',{staticClass:"navItemLable"},[_vm._v(" "+_vm._s(_vm.$t("blocked"))+" ")]),_c('span',{staticClass:"mx-1 font-weight-bold counts"},[_vm._v("("+_vm._s(_vm.getConnectionsCounts.blocked)+")")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }