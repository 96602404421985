<template>
  <transition v-if="isShow" name="modal">
    <div class="modal-mask success">
      <div class="modal-wrapper" @click.self="hide">
        <div class="modal-container modal-container-small">
          <div class="modal-header mb-1">
            <!-- <slot name="header"> </slot> -->
            <div class="header-element">
              <div class="img-size-bg">
                <img
                  alt=""
                  src="./../../assets/images/partnerships/smile.png"
                />
              </div>
              <p class="header-text">{{ $t("Your Unique Secret Code") }}</p>
            </div>

            <div class="modal-header-btn">
              <button class="modal-default-button" @click="close">
                <img
                  alt=""
                  src="./../../assets/images/partnerships/modal-close.png"
                />
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div v-if="getCompanyCode === 'aws'">
              <div class="modal-body-text-code">
                <span>CODE:</span> <b>{{ getOfferCode }}</b>
              </div>
              <div class="modal-body-text-copy">
                <img
                  class="cross"
                  alt=""
                  src="./../../assets/images/partnerships/info-circle.svg"
                />

                {{
                  $t(
                    "Copy this code and watch the video for further instructions"
                  )
                }}
              </div>
              <slot name="body"></slot>

            
            </div>
            <div v-if="getCompanyCode === 'miro'">
              <div class="modal-body-text-copy">
                <p class="text-left" v-if="getUserLang ==='fr'">
                  <ol>
                    <li>Créez un compte gratuit, cliquez ici pour créer votre compte gratuit (<a href="https://miro.com" target="_blank">miro.com</a>)</li>
                    <li>Postulez votre crédit de 1000 $ en remplissant le formulaire et sélectionnez <strong>"BANTABA"</strong> comme partenaire. </li>
                    <li>3.	Une fois que vous recevez un e-mail d'approbation de Miro, mettez votre compte d'équipe à niveau vers le plan d'équipe en suivant les instructions de l'e-mail.</li>
                    <li>4.	Pour plus d'informations sur le programme, lisez cet article ou contactez <a style="color:#175ABE" href = "mailto:startups@miro.com">startups@miro.com</a></li>
                  </ol>
                </p>
                <p class="text-left" v-else>
                  <ol>
                    <li>Set up a Free account, click here to create your free account (<a href="https://miro.com" target="_blank">miro.com</a>)</li>
                    <li>Apply for your $1000 credit by filling the form and select <strong>"BANTABA"</strong> as your partner </li>
                    <li>Once you receive an approval email from Miro, upgrade your team account to the Team plan by following the instructions from the email.</li>
                    <li>For additional information about the program, read this article or reach out to <a style="color:#175ABE" href = "mailto:startups@miro.com">startups@miro.com</a></li>
                  </ol>
                </p>
              </div>
            </div>
             <div v-if="getCompanyCode === 'sendgrid'">
              <div class="modal-body-text-copy">
                <p class="text-left" v-if="getUserLang ==='fr'">
                  <ol>
                    <li>CLiquez sur le lien suivant  (<a 
href="https://ahoy.twilio.com/twilio-startups-application-1?utm_source=startups-referral&utm_medium=partner&utm_campaign=twstartups&utm_content=ourbantaba.com-community" 
style="color:blue" target="_blank">Lien</a>)</li>
                    <li>Tapez <strong>"Bantaba"</strong> comme partenaire en remplissant le formulaire à la section 3, question b.</li>
                    <li>Suivez les instructions de la section 4 pour créer un compte SendGrid et continuez.</li>
                    <li>Sélectionnez les deux offres (A&B) ou celle qui vous intéresse (A ou B) à la section 5, question c et soumettez.</li>
                  </ol>
                </p>
                <p class="text-left" v-else>
                  <ol>
                    <ol>
                    <li>Click the following link  (<a style="color:blue" 
href="https://ahoy.twilio.com/twilio-startups-application-1?utm_source=startups-referral&utm_medium=partner&utm_campaign=twstartups&utm_content=ourbantaba.com-community" 
target="_blank">Link</a>)</li>
                    <li>Type <strong>"Bantaba"</strong> as your partner while filling the form in section 3, question b.</li>
                    <li>Follow instruction in section 4 to create a SendGrid account and proceed</li>
                    <li>•	Select both offers (A&B) or the one you are interested in (A or B) at section 5, question c and submit.</li>
                  </ol>
                  </ol>
                </p>
              </div>
            </div>
              <div v-if="getCompanyCode === 'notion'">
              <div class="modal-body-text-copy">
                <p class="text-left" v-if="getUserLang ==='fr'">
                  <ol>
                    <li>Commencez par remplir ce formulaire : (<a style="color:blue" href="https://www.notion.so/startups?utm_medium=partner&utm_source=startup_partner&utm_campaign=startup-program-partner-bantabaab" target="_blank">Lien</a>)</li>
                    <li>Dans la section "Êtes-vous affilié à un partenaire de Notion for Startups ?" Sélectionnez "oui" et choisissez "Bantaba AB" comme votre partenaire.</li>
                    <li>Votre clé de partenaire est : <strong>Bantaba1000</strong></li>
                  </ol>
                </p>
                <p class="text-left" v-else>
                  <ol>
                    <ol>
                    <li>Get started by filling this form (<a style="color:blue" href="https://www.notion.so/startups?utm_medium=partner&utm_source=startup_partner&utm_campaign=startup-program-partner-bantabaab" target="_blank">Link</a>)</li>
                    <li>In the section “Are you affiliated with a Notion for Startups Partner?” Select “yes” and chose “Bantaba AB” as your partner</li>
                    <li>Your partner key is: <strong>Bantaba1000</strong></li>
                  </ol>
                  </ol>
                </p>
              </div>
            </div>
            <div class="modal-footer-partnership">
              <div class="modal-footer-partnership-text">
                {{ $t("Please do not share this code with anyone.") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import modal from "@/mixins/modal";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "authUser",
      "getApplyResults",
      "getUserLang",
      "getOfferCode",
      "getCompanyCode",
    ]),
  },
  name: "ModalSuccess",
  mixins: [modal],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modal";

ol li {
  list-style: decimal;
  list-style-position: inside;
}

.success {
  .header-element {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .modal-header-btn {
    display: flex;
    align-items: center;
    //margin-right: 10px;
  }

  .header-text {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 26px;
    font-weight: 400;
  }

  .img-size-bg {
    margin-right: 20px;
    border-radius: 50%;
  }

  .img-size-bg img {
    border-radius: 50%;
  }

  .modal-header {
    background: #e1efe1;
  }

  .modal-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    column-gap: 20px;
  }

  .modal-body {
    padding-bottom: 0;
    font-size: 20px;
    line-height: 30px;
  }

  .modal-body-text-code {
    margin-bottom: 50px;
    font-size: 30px;
  }

  .modal-body-text-code b {
    font-weight: 700;
    font-size: 42px;
  }

  .modal-body-text-copy {
    font-family: Helvetica, Oblique, sans-serif;
    margin-bottom: 60px;
    font-size: 16px;
  }

  .modal-footer-partnership {
    background: #fbf7d4;
    padding: 0 10px;
    width: 100% !important;
  }

  .modal-footer-partnership {
    border-radius: 0px;
  }

  .modal-footer-partnership-text {
    padding: 5px 0;
    text-align: center;
  }

  .text-center {
    text-align: center !important;
  }

  .btn-action:hover {
    background-color: var(--first-color);
    color: #fff !important;
  }

  .btn-action {
    border: 1px solid var(--first-color);
    border-radius: 15px !important;
    color: #707070;
  }

  .modal-mask {
    position: fixed;
    z-index: 10002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container.modal-container-small {
    max-width: 690px;
  }

  .modal-container {
    height: auto !important;
    max-height: 500px;
    border-bottom: 0 solid !important;
    overflow-y: scroll !important;
    margin: 0 auto;
    /* padding: 20px !important; */
    padding: 0 !important;
    background-color: rgb(255, 255, 255);
    border-radius: 15px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-default-button {
    border: none !important;
    position: relative;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 1500px !important;
    background: #ececec !important;
    cursor: pointer !important;
    right: 0;
    float: right;
    font-size: 10px;
    z-index: 10000;
  }

  .modal-default-button img {
    max-width: 100%;
    max-height: 100%;
  }

  .modal-default-button:hover {
    background: #cecccc !important;
  }

  .btn-icon .svg {
    fill: #707070 !important;
    color: #707070 !important;
  }

  @media (max-width: 800px) {
    //@media screen and (min-width: 375px) and (max-width: 800px) {
    .img-size-bg img {
      width: 40px;
      height: 40px;
    }
    .modal-container {
      width: 380px;
      height: 327px !important;
      max-height: 1000px !important;
      border-bottom: 30px solid #fff;
      overflow: scroll !important;
    }

    .modal-container.modal-container-small {
      max-width: 690px;
      width: calc(100% - 40px);
      height: auto !important;
      margin: 0 auto;
    }

    .modal-body-text-code span {
      display: none;
    }

    .modal-footer-partnership-text {
      /*margin-left: 35px;*/
    }
  }

  @media (min-width: 1200px) {
    .img-size-bg svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 600px) {
    .modal-container.modal-container-small {
      width: calc(100% - 30px);
      height: auto !important;
    }
    .modal-body-text-code {
      margin-bottom: 34px;
    }
    .header-text {
      font-size: 16px;
    }
    .modal-body-text-copy {
      font-size: 16px;
      margin-bottom: 52px;
    }
  }

  @media (max-width: 375px) {
    .header-text {
      font-size: 16px;
    }

    .img-size-bg img {
      width: 40px;
      height: 40px;
    }

    .modal-container {
      width: 300px;
      height: 400px !important;
      max-height: 500px !important;
      overflow: scroll !important;
      font-size: 16px;
    }

    .modal-container.modal-container-small {
      width: 90%;
      height: auto !important;
    }
  }

  .modal-header h3 {
    margin-top: 0;
    text-align: center !important;
    color: #42b983;
  }

  .modal-default-button {
    border: none !important;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    background: rgb(148, 148, 148);
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
