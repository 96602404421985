<template>
  <div>
    <div class="messages-body" v-if="!getIsLoadingMessage">
      <div v-if="getMessages.length" ref="messagesBody">
        <ul class="messages" id="messages" ref="msgContainer">
          <li class="order-message-btn-container" v-if="getHasMoreMessages">
            <button v-if="getLoadingMoreChats">
              <i class="fa fa-1x fa-spin fa-spinner mx-1"></i>
              {{ $t("loadingMore") }}
            </button>
            <button type="button" v-else @click.prevent="loadMoreMessages">
              {{ $t("oldMessages") }}
            </button>
          </li>
          <li
            class="message"
            v-for="(message, index) in getMessages.slice().reverse()"
            :key="'A' + index"
            v-bind:class="{ ismeLi: message.senderId === authUser.id }"
          >
            <div
              v-if="message.text != ``"
              class="message-right"
              v-bind:class="{ isme: message.senderId === authUser.id }"
            >
              <div class="message-header">
                <p class="message-from">{{ message.senderName }}</p>
                <p class="message-time">
                  <timeago
                    class="text-secondary small"
                    :datetime="message.createdAt"
                    :locale="$i18n.locale"
                    :auto-update="60"
                    :converter="convertTime"
                  />
                </p>
              </div>

              <div
                class="message-content"
                v-html="message.text"
                v-linkified
              ></div>
            </div>
            <div class="message-left">
              <div class="reciever-profile">
                <img
                  v-if="message.senderId === authUser.id"
                  class="rounded-circle gravatar"
                  :src="authUser.avatar"
                />
                <img
                  v-else
                  class="rounded-circle gravatar"
                  :src="getCurrentlyChattingWith.avatar"
                />
              </div>
            </div>
            <!-- <SentStatus /> -->
          </li>
        </ul>
      </div>
      <EmptyNewConvo
        :avatar="getCurrentlyChattingWith.avatar"
        :showButton="false"
        :fullname="
          getCurrentlyChattingWith.type === 2
            ? getCurrentlyChattingWith.startup.startupname
            : getCurrentlyChattingWith.firstname +
              ' ' +
              getCurrentlyChattingWith.name
        "
        :text="introMsg"
        v-else
      />
    </div>
    <ChatLoader v-else />
  </div>
</template>
<script>
import EmptyNewConvo from "./EmptyNewConvo";
import ChatLoader from "./ChatLoader";
import SentStatus from "./SentStatus";

import { mapState, mapMutations, mapGetters } from "vuex";
import Axios from "axios";
import APIURLS from "../../constant";
import { debounce } from "lodash";
import moment from "moment";
import i18n from "../../i18n";
export default {
  // watch: {
  //   count: function () {
  //     this.$nextTick(function () {
  //       this.ScrollMessagesToBottom();
  //     });
  //   },
  // },
  mounted() {
    if (!this.getLoadingMoreChats) {
      this.ScrollMessagesToBottom();
    } else {
      this.ScrollToTop();
    }
  },
  updated() {
    if (this.srollTop) {
      this.ScrollToTop();
    } else {
      this.ScrollMessagesToBottom();
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getMessages",
      "getIsActive",
      "getMyAvatar",
      "getIsBubbleActive",
      "getCurrentlyChattingWith",
      "getChatList",
      "getMessages",
      "getIsNewChatConvo",
      "getIsBubbleClosed",
      "getOnlineUsers",
      "getLoadingMoreChats",
      "getHasMoreMessages",
      "getChatPagination",
      "getChatMessagesCount",
      "getAllMessagesCount",
      "getIsBubbleActive",
      "getIsLoadingMessage",
      "getPage",
      "getCount",
      "getChatServerStatus",
    ]),
    introMsg() {
      if (this.authUser.type === 2) return "newConvoStartup";
      else return "newConvoDiaspora";
    },
  },

  data() {
    return {
      messageContainerToScroll: null,
      page: 1,
      introduction: "",
      count: 0,
      srollTop: false,
    };
  },
  methods: {
    async loadMoreMessages() {
      this.$store.commit("setLoadingMoreChats", true);
      this.$store.commit("setLoadMoreClicked", true);
      await Axios.get(
        APIURLS.PRIVATECHAT +
          "sender/" +
          this.authUser.id +
          "/receiver/" +
          this.getCurrentlyChattingWith.id +
          "/pagination/" +
          this.getChatPagination +
          "/page/" +
          this.getPage
      )
        .then(({ data, status }) => {
          let page = this.getPage + 1;
          this.$store.commit("setPage", page);
          //this.page += 1;
          let chatMessages = [...this.getMessages, ...data.messages];
          this.$store.commit("setMessages", chatMessages);
          let count = this.getCount + data.messagesCount;
          this.$store.commit("setCount", count);
          if (
            this.getCount + 100 <= this.getAllMessagesCount &&
            data.messagesCount != 0
          ) {
            this.$store.commit("setHasMoreMessages", true);
          } else {
            this.$store.commit("setHasMoreMessages", false);
          }
          this.srollTop = true;

          this.$store.commit("setLoadingMoreChats", false);
        })
        .catch((error) => {
          this.$store.commit("setLoadingMoreChats", false);
          //console.log('error.response', error.response);
        });
    },
    ScrollToTop() {
      const el = this.$refs.messagesBody;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    ScrollMessagesToBottom() {
      var content = this.$refs.msgContainer;
      if (content) {
        content.scrollTop = content.scrollHeight;
      }
    },
    ScrollTo(value) {
      var content = this.$refs.msgContainer;
      if (content) {
        content.scrollTop = value;
      }
    },
    // ...mapMutations(["ScrollMessagesToBottom"]),
    convertTime(date, locale, converterOptions) {
      let year = " y";
      let day = " d";
      if (this.$i18n.locale === "fr") {
        year = " an";
        day = " j";
      }
      const prefix = converterOptions.prefix || "";
      const suffix = converterOptions.suffix || "";
      const yearSuffix = converterOptions.yearSuffix || year;
      const monthSuffix = converterOptions.monthSuffix || " m";
      const daySuffix = converterOptions.daySuffix || day;
      const hourSuffix = converterOptions.hourSuffix || " h";
      const minuteSuffix = converterOptions.minuteSuffix || " min";
      const secondsSuffix = "s";
      const diffSpacer = converterOptions.diffSpacer || "";

      let difference =
        new Date().getTime() - new Date(Date.parse(date)).getTime();

      const yearsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30 / 12
      );
      difference -= yearsDifference * 1000 * 60 * 60 * 24 * 30 * 12;

      if (yearsDifference > 0) {
        return moment.utc(date).format("DD,MMM,YYYY");
        //return `${prefix} ${yearsDifference}${diffSpacer}${yearSuffix} ${suffix}`;
      }

      const monthsDifference = Math.floor(
        difference / 1000 / 60 / 60 / 24 / 30
      );
      difference -= monthsDifference * 1000 * 60 * 60 * 24 * 30;

      if (monthsDifference > 0) {
        return moment.utc(date).format("MMM DD");
        //return `${prefix} ${monthsDifference}${diffSpacer}${monthSuffix} ${suffix}`;
      }

      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      if (daysDifference > 0) {
        return moment.utc(date).format("MMM DD");
        //return `${prefix} ${daysDifference}${diffSpacer}${daySuffix} ${suffix}`;
      }

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      if (hoursDifference > 0) {
        return `${prefix} ${hoursDifference}${diffSpacer}${hourSuffix} ${suffix}`;
      }

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      if (minutesDifference > 0) {
        return `${prefix} ${minutesDifference}${diffSpacer}${minuteSuffix} ${suffix}`;
      }

      const secondsDifference = Math.floor(difference / 1000);
      difference -= secondsDifference * 1000;

      if (secondsDifference > 0) {
        return `${prefix} ${secondsDifference}${diffSpacer}${secondsSuffix}  ${suffix}`;
      }

      if (secondsDifference === 0) {
        return this.$i18n.t("justNow");
      }

      //console.log('difference', yearsDifference, monthsDifference, daysDifference, hoursDifference, minutesDifference,secondsDifference)
      return `${prefix} ${secondsSuffix} ${suffix}`;
    },
  },

  name: "BubbleMessages",
  components: {
    EmptyNewConvo,
    ChatLoader,
    // SentStatus,
  },
};
</script>


<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.messages-body {
  background-color: rgb(255, 255, 255) !important;
  padding-top: 0px;
  height: calc(100% - 100px);
  overflow: hidden;
  /* overflow-y: auto; */
}
.reciever-profile {
  width: 32px;
  height: 32px;
  border-radius: 20px;
}
.reciever-name {
  margin-left: 10px;
  font-weight: bolder;
  color: green;
  font-size: 14px;
}
.messages {
  height: 300px;
  overflow-y: auto;
  /* padding-bottom: 15px; */
  padding-bottom: 55px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.messages::-webkit-scrollbar {
  display: none;
}
.message {
  padding: 10px 7px;
  display: flex;
  border-bottom: none !important;
  /* align-items: center; */
  flex-direction: row-reverse;
  word-wrap: break-word;
  white-space: pre-line;
  position: relative;
}
.ismeLi {
  display: flex;
  flex-direction: row;
}
.message-left {
  padding: 0 10px;
  display: flex;
  align-items: flex-end;

  position: absolute;
  /* z-index: 99999999; */
  bottom: 0px;
  display: none;
}
.message-right {
  position: relative;
  padding-bottom: 0;
  width: 95%;
  border-radius: 10px !important;
  /* border: 2px solid #e4e6ec; */
  background-color: rgba(228, 230, 234, 0.63);
  background-color: #eee;
  border-radius: 20px;
  /* padding: 8px 15px; */
}
.message-header {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
  padding: 8px 15px;
  padding-bottom: 5px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.105); */
}
.message-from {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.message-time {
  /* margin-left: 30px; */
  color: rgba(0, 0, 0, 0.5);
  font-weight: lighter;
}
.message-content {
  color: #000000c7;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 15px;
  line-height: 20px;
  text-align: left;
  padding-bottom: 20px;
}
.message-input {
  position: absolute;
  z-index: 10000;
  width: 80%;
  height: 40px;
  bottom: 0px;
}
.message-form {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.messages-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.messages-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.gravatar {
  width: 100%;
  height: 100%;
}
.isme {
  background-color: #e5efe4;
}

/* .isme  .message-content {
  color: #000000c7;
  text-align: right;
} */

.order-message-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  border: none;
  outline: none;
  background-color: #eeeeee;
  color: #0e0b22;
  padding: 5px;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 60%;
  height: 30px;
}
button:hover {
  background-color: #dddddd;
  color: #0d0c22;
}
::-webkit-scrollbar {
  display: block !important;
  width: 8px !important;
  background: #dfdfdf52 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #49af66 !important;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #49af66 !important;
}

@media (max-width: 650px) {
  .messages {
    height: 75vh;
    padding-bottom: 35px;
  }
}
</style>