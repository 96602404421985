<template>
  <div class="#">
    <div class="box shadow-sm border rounded bg-white mb-3">
      <div class="widget widget-user-about">
        <div class="widget-heading">
          <h3 class="widget-title">{{ $t('Interrests') }}</h3>
        </div>
        <div class="box-body" v-if="interrests.length">
          <div class="border-bottom people-list col-md-12">
            <button
              type="button"
              class="btn btn-success m-1 tag"
              v-for="interrest in interrests"
              :key="interrest.id"
            >
              {{ $t(interrest.name) }}
            </button>
          </div>
        </div>
        <NoData
          v-else
          text="noInterrest"
          buttonText="addInterrests"
          :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.cursus')}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Interrests',
  props: {
    interrests: {},
  },
};
</script>
