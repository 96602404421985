<!-- eslint-disable vue/multi-word-component-names -->
// TODO:the community tab container when logged in as startup 
<template>
  <div class="main-container">
    <div class="row" v-if="getDiaspora && getDiaspora.length">
      <div class="card-col" v-for="(user, i) in getDiaspora" :key="i">
        <Card bg-color="#ffc107" :user="user" />
      </div>
    </div>
    <div class="row" v-else>
      <NoDataFound
        :svg="require('../../assets/images/noData/no_diaspora.svg')"
        title="Oops, no diaspora found"
        text="It seems like the person is not yet on Bantaba, invite them to join."
        buttonText="Reset Filters"
        :buttonLink="`/${$i18n.locale}/diaspora`"
        :hasTitle="true"
        :hasText="true"
        :hasButton="true"
      />
    </div>

    <LoadMore
      v-if="getShowLoadMore"
      @click.native="loadMore"
      :loadingMore="loadingMore"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClearFiltersBtn from "../Buttons/ClearFilter.vue";
import moment from "moment";
import i18n from "../../i18n";
import Card from "./Card.vue";
import Pagination from "./Pagination.vue";
import LoadMore from "../Loaders/LoadingMore.vue";
import NoDataFound from "../NoDataFound/Version-1.vue";

export default {
  mounted() {
    if (
      this.getDiaspora.length >= this.getDiasporaLimit &&
      this.getDiaspora.length < this.getDiasporaTotalCount
    ) {
      this.$store.commit("setShowLoadMore", true);
    } else if (this.getDiaspora.length === this.getDiasporaTotalCount) {
      this.$store.commit("setShowLoadMore", false);
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getDiaspora",
      "getShowPagination",
      "getShowLoadMore",
      "getDiasporaTotalCount",
      "getDiasporaPage",
      "getDiasporaLimit",
      "getSearchFilters",
    ]),
  },

  data() {
    return {
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions(["loadDiaspora", "searchWithFilters", "loadMoreDiaspora"]),
    async loadMore() {
      this.loadingMore = true;
      let page = this.getDiasporaPage + 1;
      await this.loadMoreDiaspora({
        data: {
          user_id: this.authUser.id,
          page,
          limit: this.getDiasporaLimit,
          filters: this.getSearchFilters,
        },
      });
      this.$store.commit("setDiasporaPage", page);
      // alert(
      //   "length " +
      //     this.getDiaspora.length +
      //     " total " +
      //     this.getDiasporaTotalCount
      // );
      if (this.getDiaspora.length === this.getDiasporaTotalCount) {
        this.$store.commit("setShowLoadMore", false);
      }
      this.loadingMore = false;
    },
  },
  components: {
    Card,
    NoDataFound,
    //Pagination,
    LoadMore,
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f4f2 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75b38c !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1ba54b !important;
}
.main-container {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  /* padding: 0 2%; */
  box-sizing: border-box;
  min-height: 83vh;

  padding: 0 1%;
}
.pagiation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 1%;
  box-sizing: border-box;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
}
.card-col {
  box-sizing: border-box;
  width: 33.333333333333%;
  padding: 0 1%;

  width: 25%;
  padding: 0 0.4%;
}
@media (max-width: 1199px) {
  .card-col {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .card-col {
    width: 50%;
  }
  .main-container {
    padding: 0 7%;
  }
}
@media (max-width: 767px) {
  .card-col {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start !important;
  }
  .alert.alert-info.m-4.text-center {
    /* height: 300px; */
    border-radius: 25px;
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    background: white;
    height: 100%;
    width: 100%;
  }
  .alert.alert-info.m-4.text-center div.alert-message-warning {
    background-color: #fcf8f2;
    border-color: transparent !important;
  }
}
@media (max-width: 600px) {
  .alert {
    background: red;
    height: 300px;
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%) !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%) !important;
    background: white !important;
    height: 60vh !important;
    width: 90%;
    margin: 0 !important;
    margin-top: 0px;
    margin-top: 20px !important;
    border-radius: 25px !important;
    display: flex;
    align-content: center;
    align-items: center;
  }
}
</style>