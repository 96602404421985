<template>
 <div class="d-flex" >
    <div class="btn btn-save"
    @click="$emit('save')">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10.501 9.804" >
            <path d="M549.854,902.189l2.611,2.959,4-5.4,2.147,2.553-5.935,7.252-4.566-4.989Z" transform="translate(-548.114 -899.752)" fill="#707070" >
            </path>
        </svg>
    </div>
    <div class="btn btn-cancel" 
    @click="$emit('close')"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 12 12" >
            <path d="M8.275,86l3.411-3.411a1.072,1.072,0,0,0,0-1.516l-.758-.758a1.072,1.072,0,0,0-1.516,0L6,83.725,2.589,80.314a1.072,1.072,0,0,0-1.516,0l-.758.758a1.072,1.072,0,0,0,0,1.516L3.725,86,.314,89.411a1.072,1.072,0,0,0,0,1.516l.758.758a1.072,1.072,0,0,0,1.516,0L6,88.275l3.411,3.411a1.072,1.072,0,0,0,1.516,0l.758-.758a1.072,1.072,0,0,0,0-1.516Z" transform="translate(0 -80)" fill="#707070">
            </path>
        </svg>
    </div>
</div>
</template>
<script>
export default {
    name: 'SaveClose'
}
</script>
<style scoped>
.d-flex {
    display: flex;
}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    line-height: 0;
    border: 1px solid hsla(0,0%,43.9%,.002);
    background-color: hsla(0,0%,54.9%,.22);
}
.btn-save{
    margin-left: 10px;
    
}
.btn-cancel{
    margin-left: 10px;
}
</style>