import { render, staticRenderFns } from "./Searchbar_startups.vue?vue&type=template&id=36d742da&scoped=true&"
import script from "./Searchbar_startups.vue?vue&type=script&lang=js&"
export * from "./Searchbar_startups.vue?vue&type=script&lang=js&"
import style0 from "./Searchbar_startups.vue?vue&type=style&index=0&id=36d742da&prod&lang=css&"
import style1 from "./Searchbar_startups.vue?vue&type=style&index=1&id=36d742da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d742da",
  null
  
)

export default component.exports