<template>
  <div class="section">
    <label
      >{{ $t(title) }}
      <span class="error_msg" v-if="err_msg"> {{ $t(err_msg) }} </span></label
    >
    <div class="section-list">
      <!-- <SelectBtn2
        v-for="(option, index) in options"
        :key="index"
        :text="option.name"
        :icon="option.icon"
        @click.native="setStartupStage(option.name)"
        :active="getFordataField('startupStage') === option.name"
      /> -->
     
     
       <ModelSelect
        @input="setStartupStage($event)"
        :options="
          options.map((d) => {
            let field = d.name;
            let value = d.name;
            let name = d.name;
            let text = d.name;
           
            let item = {
              field,
              value,
              name,
              text
            };
            return item;
          })
        "
        v-model="stageOrigin"
        placeholder="Select"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import SelectBtn2 from "./SelectBtn2.vue";
import { ModelSelect } from "vue-search-select";
export default {
  mounted() {
    let startupStage = localStorage.getItem("startupStage");
    if (startupStage) {
      this.$store.commit("setFormData", {
        field: "startupStage",
        value: startupStage,
      });
    }
  },
  computed: {
    ...mapGetters(["getFordataField"]),
  },
  data: () => {
    return {
      selectedname: "",
      stageOrigin: {
        field: "startupStage",
        value: "",
        name: "",
         text: "",
      },
    };
  },
  methods: {
    ...mapMutations(["setAccountType"]),
    setStartupStage(e) {
       console.log("cococococ", e.field)
      // this.selectedname = e;
      
      this.$store.commit("setFormData", {
        field: "startupStage",
        value: e.field,
      });
      localStorage.setItem("startupStage", e.field);
    },
  },
  name: "StartupStage",
  props: {
    title: String,
    err_msg: String,
    options: Array,
  },
  components: {
    // SelectBtn2,
     ModelSelect,
  },
};
</script>
<style scoped>
.error_msg {
  /* color: var(--error-color) !important; */
  /* background-color: #fe4c5f42; */
  color: var(--error-color);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 15px;
}
.section {
  width: 100%;
}
.section label {
  font-size: 22px;
}
.section-list {
  display: grid;
  /* grid-template-columns: 23.5% 23.5% 23.5% 23.5%; */
  grid-gap: 10px;
  row-gap: 10px;
}

.section-list li {
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.section-list li:hover {
  border: 1px solid #349e4d;
  color: #349e4d;
}

@media only screen and (max-width: 600px) {
  .section {
    width: 100%;
    text-align: left;
  }
  .section-list {
    display: grid;
    /* grid-template-columns: 49% 49%; */
    grid-gap: 10px;
    row-gap: 10px;
  }
  .section label {
    font-size: 18px !important;
    /* color: #717171; */
  }
}
@media only screen and (max-width: 320px) {
  .section label {
    font-size: 15px !important;
  }
}
</style>