<template>
  <a href="#" class="btn-chat">
    <span class="btn-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 28.44 25"
      >
        <path
          d="M13.714,32C6.139,32,0,36.987,0,43.143a9.89,9.89,0,0,0,3.054,7A13.629,13.629,0,0,1,.118,55.277a.426.426,0,0,0-.08.466A.42.42,0,0,0,.429,56a12.251,12.251,0,0,0,7.532-2.754,16.338,16.338,0,0,0,5.754,1.039c7.575,0,13.714-4.987,13.714-11.143S21.289,32,13.714,32ZM6.857,44.857a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,6.857,44.857Zm6.857,0a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,13.714,44.857Zm6.857,0a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,20.571,44.857Z"
          transform="translate(0.512 -31.5)"
          fill="#1BA54B"
          stroke="rgba(112,112,112,0.18)"
          stroke-width="1"
        />
      </svg>
    </span>
    <span class="btn-label">Chat</span>
  </a>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn-chat {
  display: flex;
  align-items: center;
  width: 140px;
  height: 34px;
  border: 1px solid #4646461f;
  border-radius: 17px;
  padding-right: 10px;
  color: var(--green-color);
  font-size: 16px;

  &:hover {
    background: var(--green-color);
    border: 1px solid var(--green-color);
    color: #fff;
    border-color: rgba(0, 90, 31, 0.27);

    path {
      fill: #fff;
    }

    .btn-icon {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .btn-icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.07);
  }

  .btn-label {
    flex-grow: 1;
    text-align: center;
  }
}
@media (max-width:600px){
.btn-chat {
  font-size: 14px;
}
}
</style>
