<template>
  <div class="notification">
    <header>
      <h1>
        <span class="icon-circle">
          <i class="'icon fa fa-bell'"></i>
        </span>
        Notification Settings
      </h1>
    </header>

    <div class="notification-settings-wrapper">
      <div class="notification-settings">
        <h4>Diaspora</h4>

        <div class="notification-settings-list">
          <SettingItem
            iconName="envelope"
            topColor="#56F2F9"
            bottomColor="#73C0FE"
            settingName="Direct Message"
            withToggles
          ></SettingItem>
          <SettingItem
            iconName="chart-bar"
            topColor="#B5FD59"
            bottomColor="#38C545"
            settingName="Connection Request"
            withToggles
          ></SettingItem>
          <SettingItem
            iconName="eye"
            topColor="#D2A3FF"
            bottomColor="#7DA5F8"
            settingName="New Startups"
            withToggles
          ></SettingItem>

          <SettingItem
            iconName="star"
            topColor="#FCEF50"
            bottomColor="#E8B75B"
            settingName="In Your Sector of Interest"
            singleToggle
          ></SettingItem>
          <SettingItem
            iconName="user-friends"
            topColor="#F774A4"
            bottomColor="#AE6FA3"
            settingName="Looking for people with your type of expertise"
            singleToggle
          ></SettingItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingItem from "../../components/SettingItem.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notification",
  components: {
    SettingItem,
  },
};
</script>

<style>
.icon-circle {
  padding: 10px 15px;
  background: #ffffff33;
  border-radius: 30px;
}
.notification-settings h4 {
  padding-left: 10px;
  font-weight: 600;
}

.notification-settings {
  background-color: #ffffff;
  width: 100%;
  min-height: 400px;
  border-radius: 20px;
}

.notification {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification header {
  background-color: #1eb65b;
  width: 100%;
  height: 90px;
  display: block;
}
.notification header h1 {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
  color: white;
  font-weight: 500;
}
.notification-settings-wrapper {
  width: 95%;
  background-color: transparent;
  position: relative;
  top: -40px;
  min-height: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

@media only screen and (min-width: 600px) {
  .notification {
    background-color: #1eb65b;
    border-radius: 20px !important;
  }

  .notification header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notification header h1 {
    font-size: 24px;
  }

  .notification-settings h4 {
    padding-left: 20px;
  }
  .notification-settings-wrapper {
    width: 90%;
    min-height: 50%;
    padding: 10px;
    background-color: #ffffff22;
    position: static;
  }
}

@media only screen and (min-width: 992px) {
  .notification-settings-wrapper {
    width: 60%;
  }
}
</style>
