<template>
  <form action="" class="form" v-on:submit.prevent="login">
    <h1 class="form__title py-3">{{ $t("welcomeBack") }}</h1>
    <Alert v-if="error" :text="error_msg" type="danger" :size="12" />
    <Input
      v-model="form.email"
      typeName="email"
      label="Email"
      name="Email"
      @change="checkInput('email')"
      :isRequired="true"
      :hasError="errors.email"
      :error_msg="msg.email"
      class="py-4"
    />

    <Input
      v-model="form.password"
      typeName="password"
      @change="checkInput('password')"
      label="password"
      name="password"
      :isPassword="true"
      :isRequired="true"
      :hasError="errors.password"
      :error_msg="msg.password"
      class="py-4"
    />
    <SubmitBtn
      value="login"
      class="my-4"
      :hasLoader="true"
      :loading="loading"
    />
    <LoginBtn
      text="forgotPassword"
      buttonText="recoverPassword"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.forgotPassword')}`"
    />
    <LoginBtn
      text="newToBantaba"
      buttonText="joinNow"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.register')}`"
      class="my-4"
    />
  </form>
</template>
<script>
import Input from "./Input";
import SubmitBtn from "./SubmitBtn";
import LoginBtn from "./LoginBtn";
import CheckBox from "./CheckBox.vue";
import moment from "moment";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Form",
  data() {
    return {
      form: {
        email: "",
        password: "",
        login: {},
      },
      loading: false,
      errors: {},
      error: false,
      error_msg: null,
      msg: {
        email: "",
        password: "",
      },
    };
  },
  components: {
    Input,
    SubmitBtn,
    LoginBtn,
  },
  methods: {
    ...mapActions(["logUserIn", "getUserGeoInfos", "checkEmailExist"]),
    async login() {
      this.loading = true;
      const ipRegistry = await this.getUserGeoInfos();
      this.form.login = {
        country_name: ipRegistry.location.country.name,
        country_code: ipRegistry.location.country.code,
        city: ipRegistry.location.city,
        startDate: moment(new Date()).format("MM-DD-YYYY"),
        startTime: moment(new Date()).format("h:mm A"),
        fullStartTime: moment().toISOString(),
        startTimestamp: moment().unix(),
        ip: ipRegistry.ip,
        latitude: ipRegistry.location.latitude,
        longitude: ipRegistry.location.longitude,
        postal_code: ipRegistry.location.postal,
        region: ipRegistry.location.region.name,
        timezone_name: ipRegistry.time_zone.name,
        continent_name: ipRegistry.location.continent.name,
        continent_code: ipRegistry.location.continent.code,
        is_ref_implemented: 1,
      };
      this.logUserIn(this.form)
        .then((response) => {
          this.error_msg = response;
          this.loading = false;
          this.error = true;
          // this.$router.push(`/${i18n.locale}/${i18n.t("routes.myActivity")}`).catch(err => {});
          let url = sessionStorage.getItem("redirectPath")
            ? sessionStorage.getItem("redirectPath")
            : `/${i18n.locale}/${i18n.t("routes.myActivity")}`;
          if (this.$route.path != url) {
            this.$router.push(url).catch((err) => {});
            sessionStorage.removeItem("redirectPath");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          if (error === "Invalid username/password") {
            this.errors.email = true;
            this.errors.password = true;
            this.error_msg = error;
          }
          console.log(" error", error);
        });
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async checkInput(name) {
      if (this.isEmpty(this.form[name])) {
        this.errors[name] = true;
        this.msg[name] = `${this.$i18n.t(name)} is required`;
      } else {
        this.errors[name] = null;
        this.msg[name] = "";
      }

      //remove empty space at the beginning and the end
      let formValue = this.form[name].trim();

      //validate email
      if (name === "email") {
        if (this.validateEmail(this.form[name])) {
          this.errors[name] = null;
        } else {
          this.errors[name] = true;
          this.msg[name] = "Email address not valid!";
        }
      }
    },
  },
};
</script>
<style scoped>
.form {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  border-radius: 1rem;
  box-shadow: none;
}
.form__title {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 1.5rem;
}
.row-inputs {
  display: flex;
  justify-content: space-between;
}
.input-div {
  position: relative;
  width: 48%;
}
.term-and-service-container {
  display: flex;
  padding: 12px 0;
  padding-top: 25px;
  font-size: 14px;
}

.term-highlight {
  color: var(--primary-green);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    padding-bottom: 100px;
    border-radius: 1rem;
    box-shadow: none;
  }
  .form__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 1rem;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 0rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 0.5rem !important;
  }
  h1.form__title.py-3 {
    margin-top: 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>