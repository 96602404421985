<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="followings">
    <following
      v-for="(startup, index) in getUserFollowing.slice(0, numToShow)"
      :key="index"
      :startup="startup"
    />
    <a
      href="#"
      v-if="getUserFollowing.length > 8 && wantMore"
      class="btn btn-more"
      @click.prevent="seeMore()"
    >
      {{ $t("more") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="7"
        viewBox="0 0 11.092 6.732"
        class="chevron-arrow"
      >
        <path
          d="M10.771,130.048l-4.937-4.937a.61.61,0,0,1,0-.862l.576-.576a.61.61,0,0,1,.861,0l3.931,3.912,3.931-3.912a.61.61,0,0,1,.861,0l.576.576a.61.61,0,0,1,0,.862l-4.937,4.937a.61.61,0,0,1-.862,0Z"
          transform="translate(-5.656 -123.495)"
          fill="#1ba54b"
        />
      </svg>
    </a>
    <a
      href="#"
      v-if="getUserFollowing.length > 4 && !wantMore"
      class="btn btn-more"
      @click.prevent="seeLess()"
    >
      {{ $t("less") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="7"
        viewBox="0 0 12.786 7.759"
        class="chevron-arrow"
      >
        <path
          d="M11.552,131.048l-5.69-5.69a.7.7,0,0,1,0-.994l.664-.664a.7.7,0,0,1,.993,0l4.531,4.51,4.531-4.51a.7.7,0,0,1,.993,0l.664.664a.7.7,0,0,1,0,.994l-5.69,5.69a.7.7,0,0,1-.994,0Z"
          transform="translate(18.442 131.254) rotate(180)"
          fill="#1ba54b"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import Following from "./Following";
import { mapGetters, mapActions } from "vuex";
export default {
  async mounted() {
    this.user_id = this.getProfile.id;
    await this.loadUserFollowingStartups({
      user_id: this.user_id,
    });
    this.numToShow = this.limit;
  },
  computed: {
    ...mapGetters(["authUser", "getProfile", "getUserFollowing"]),
  },
  data() {
    return {
      user_id: 0,
      numToShow: 0,
      wantMore: true,
      limit: 8,
    };
  },
  methods: {
    ...mapActions(["loadProfile", "loadUserFollowingStartups"]),
    seeMore: function () {
      this.numToShow = this.getUserFollowing.length;
      this.wantMore = false;
    },
    seeLess: function () {
      this.numToShow = this.limit;
      this.wantMore = true;
    },
  },
  components: {
    Following,
  },
};
</script>

<style lang="scss" scoped>
.followings {
  background: #fff;
}

svg {
  width: 0.625rem !important;
  height: 0.625rem !important;
}

.btn-more {
  display: block;
  background: #cfead9;
  padding: 0.5rem;
  margin: 1rem;
  color: #1ba54b;
  border-radius: 1rem;
  font-size: 0.75rem;
  text-align: center;
  text-decoration: none;
}
.btn-more:hover {
  background: #1ba54b;
  color: #fff;
}
.btn-more:hover path {
  fill: #fff;
}
.btn-more::before {
  display: inline-block;
}
.chevron-arrow {
  vertical-align: middle;
  margin-left: 1rem;
}
</style>
