<template>
  <div>
  <div class="fluid-container">
    <CookieLaw
      theme="base"
      :buttonDecline="false"
      buttonText="Accept all"
      :message="$t('cookiesMessage')"
      class="cookiePopUp"
    >
    </CookieLaw>
    </div>
    <footer class="footer">
      <div class="footer-copyright p-1 ">
        <p class="pt-3">
          &copy; {{ $t('since') }} {{ new Date().getFullYear() }}
          {{ $t('allRightsReserved') }} Bantaba ||
          <a target="_blank" :href="`/${$i18n.locale}/cookies`">{{
            $t('cookiesPolicies')
          }}</a>
          ||
          <a target="_blank" :href="`/${$i18n.locale}/privacy`">{{
            $t('privacyPolicies')
          }}</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import i18n from '../../i18n';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
};
</script>
<style scoped>
.fluid-container{
  max-width: 990px;
}
button.Cookie__button {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 80vw;
    border-radius: 18px !important;
}
.Cookie__content {
    font-size: 14px;
    color: #0000007a;
}
@media (max-width: 768px) {
  .cookiePopUp{
    position: fixed;
    bottom: 50px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    padding-bottom: 20px;
    left: 0;
    right: 0;
 }
}
 
</style>
