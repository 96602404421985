<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="name">
    <div class="info-wrapper" v-if="!editing">
      <span class="info">{{ firstname + " " + name }}</span>
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div class="info-wrapper" v-if="editing">
      <input
        type="text"
        name="firstname"
        id="firstname"
        class="info col-md-5 profile-controls"
        v-model="firstname"
        :size="firstname.length"
      />
      <input
        type="text"
        name="lastname"
        id="lastname"
        class="info col-md-5 profile-controls"
        v-model="name"
        :size="name.length"
      />
      <save-cancel-button-group @save="save" @cancel="cancel()" />
    </div>
    <div class="feature-button">
      <template v-if="getProfileEditingMode">
        <labeled-edit-button
          v-if="!showEditButtons"
          text="editMode"
          @click.native="viewEditButtons"
        />
        <labeled-edit-button
          v-else
          text="exitEditMode"
          @click.native="hideEditButtons"
        />
      </template>

      <template v-else>
        <chat-button
          v-if="authUser.type === 2"
          @click.native="openChatBoxWithUser(user)"
        />
        <template v-else-if="authUser.type === 1">
          <connect-button
            @click.native="connect(getProfile)"
            v-if="getConnectionStatus === ''"
          />
          <chat-button
            v-else-if="getConnectionStatus === 'connected'"
            @click.native="openChatBoxWithUser(user)"
          />
          <connect-button
            text="sent"
            icon="sent"
            v-if="getConnectionStatus === 'sent'"
          />
          <connect-button
            text="accept"
            icon="received"
            v-if="getConnectionStatus === 'received'"
            @click.native="accept(getProfile)"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import EditButton from "../buttons/EditButton";
import ChatButton from "../buttons/ChatButton";
import ConnectButton from "../buttons/ConnectButton";
import SaveCancelButtonGroup from "../buttons/SaveCancelButtonGroup";
import LabeledEditButton from "../buttons/LabeledEditButton";
import { mapGetters, mapActions } from "vuex";
import Axios from "axios";
import APIURLS from "../../../../../constant";
import { io } from "socket.io-client";
export default {
  async mounted() {
    this.firstname = this.getProfile.firstname;
    this.name = this.getProfile.name;
    if (this.authUser.type === 1) {
      await this.getStatus();
    }
    this.sockets = io(APIURLS.CHATLINK);
    this.sockets.on("request", async (data) => {
      if (data.user_id === this.authUser.id) {
        await this.getStatus();
        await this.getNotifs();
      }
    });
  },
  props: {
    showConnectButton: Boolean,
    user: Object,
    status: String,
  },
  components: {
    EditButton,
    ChatButton,
    ConnectButton,
    SaveCancelButtonGroup,
    LabeledEditButton,
  },
  data() {
    return {
      sockets: {},
      editing: false,
      username: "",
      firstname: "",
      name: "",
    };
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "getConnectionStatus",
      "getProfileSections",
      "getUserSupportTypes",
      "getProfileEditingMode",
      "showEditButtons",
      "getUserLang",
      "getUserNotifications",
      "getUserTalents"
    ]),
  },
  methods: {
    ...mapActions([
      "openChatBoxWithUser",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "sendConnectionRequest",
      "loadUserNotifications",
      "loadUserExpertises"
    ]),
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.$store.commit("setUserNewNotifications", this.getUserNotifications);
    },
    viewEditButtons() {
      this.$store.commit("setShowEditButtons", true);
    },
    hideEditButtons() {
      this.$store.commit("setShowEditButtons", false);
    },
    save() {
      this.$store
        .dispatch("updateProfile", {
          name: this.name,
          firstname: this.firstname,
        })
        .then(() => {
          
          this.$snotify.simple({
            body: "My Notification Body",
            title: "Notification Title",
            config: {},
          });

          let auth = JSON.parse(localStorage.getItem("auth"));
          auth.name = this.name;
          auth.firstname = this.firstname;
          localStorage.removeItem("auth");
          localStorage.setItem("auth", JSON.stringify(auth));
          this.$store.commit("setAuthUser", auth);
          this.$noty.success(this.$i18n.t("profilUpdated"));
          this.editing = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    async connect(user) {
      console.log({ user });
      let user_id = user.id;
      let countryOfOrigin, livesIn;
      let lang = this.getUserLang;
      let originCode = this.authUser.profile
        ? this.authUser.profile.birthCountry
        : "";
      countryOfOrigin = await this.getAFricanCountryByLang({
        code: originCode,
        lang,
      });
      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";

      livesIn = await this.getForeignCountryByLang({ code: livesInCode, lang });
      let expertise = '';
      let accept_link = `/${this.$i18n.locale}/${this.$i18n.t('routes.network')}/${this.$i18n.t('routes.pendingRequests')}`;
      let profile_link = `/${this.getUserLang}/profile/${this.authUser.username}`;

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });


      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }


      let data = {
        from: this.authUser.id,
        to: user_id,
        name: this.authUser.firstname
          ? this.authUser.firstname
          : "" + " " + this.authUser.name,

        receiver_name: user.firstname,
        email: user.email,
        lang,
        countryOfOrigin,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        livesIn,
        expertise,
        profile_link,
        accept_link
      };
      this.sendConnectionRequest({ data })
        .then(async () => {
          await this.getStatus();
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          console.error({ error });
          this.$noty.error(error);
        });
    },
    async accept(user) {
      let user_id = user.id;
      let expertise = '';
      let lang = this.getUserLang;

      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";
      let livesIn = await this.getForeignCountryByLang({ code: livesInCode, lang });

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });


      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }
      let data = {
        from: user_id,
        to: this.authUser.id,
        sender_short_name: user.firstname,
        receiver_short_name: this.authUser.firstname,
        full_name: this.authUser.firstname
          ? this.authUser.firstname + " " + this.authUser.name
          : this.authUser.name,
        email: user.email,
        lang,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        expertise,
        location: livesIn,
        link: APIURLS.SITELINK 
      };
      Axios.post(`${APIURLS.CONNECTIONS}accept`, data)
        .then(async ({ data }) => {
          await this.getStatus();
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    async getStatus() {
      Axios.get(
        APIURLS.CONNECTIONS +
          "connected/user/" +
          this.getProfile.id +
          "/auth_user/" +
          this.authUser.id
      )
        .then((response) => {
          console.log({ response });
          this.$store.commit("setConnectionStatus", {
            status: response.data,
          });
          //this.connectionStatus = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: 1px solid var(--first-color) !important;
  border-radius: 0.3rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>


<style lang="scss" scoped>
.name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @include max-width(991) {
    justify-content: center;
  }

  .info-wrapper {
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    color: #1f2430;

    .info {
      margin-right: 10px;
    }
  }

  .feature-button {
    @include max-width(991) {
      display: none;
    }
  }
}
</style>
