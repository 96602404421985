<template>
  <div class="boxShadow flex-column justify-content-center text-center">
    <div class="container-layout">
      <div class="avatar avatar-lg mb-2">
        <!-- <img class="svg3" :src="require(`@/assets/chat/${svg}`)" /> -->

        <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
          class="svg3"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 488.6 488.6"
          style="enable-background: new 0 0 488.6 488.6"
          xml:space="preserve"
        >
          <g stroke="rgba(0,75,0,0.4)" stroke-width="15">
            <path
              d="M129.2,449.803l77.3-77.3h114c92.8,0,168.1-75.3,168.1-168.1s-75.2-168.1-168.1-168.1H168.1
		c-92.9,0-168.1,75.3-168.1,168.1c0,74.3,48.2,137.2,115,159.4v80.1C115,451.403,123.9,455.103,129.2,449.803z M313.4,183.603
		c11.5,0,20.8,9.3,20.8,20.8s-9.3,20.8-20.8,20.8s-20.8-9.3-20.8-20.8S301.9,183.603,313.4,183.603z M244.3,183.603
		c11.5,0,20.8,9.3,20.8,20.8s-9.3,20.8-20.8,20.8c-11.5,0-20.8-9.3-20.8-20.8S232.8,183.603,244.3,183.603z M175.2,183.603
		c11.5,0,20.8,9.3,20.8,20.8s-9.3,20.8-20.8,20.8s-20.8-9.3-20.8-20.8S163.7,183.603,175.2,183.603z"
            />
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </div>
      <p class="text-muted">
        {{ $t(text) }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "ChatCardInfo",
  props: {
    text: String,
    svg: String,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getCurrentlyChattingWith",
      "getIsBlocked",
      "getBlockedUser",
      "getIsNewChatConvo",
    ]),
  },
};
</script>

<style scoped>
.svg3 {
  width: 120px;
  height: 120px;
  width: 45vw;
  max-width: 200px;
  height: 120px;
  fill: #57b466;
  margin-top: 20px;
}
.svg2 {
  width: 140px;
  height: 140px;
}
</style>
<style scoped>
.gravatar2 {
  width: 52px;
  height: 52px;
}

.boxShadow {
  background-color: white;
  /* -webkit-box-shadow: 0px 0px 5px 1px rgba(15, 15, 15, 0.2);
  box-shadow: 0px 0px 5px 1px rgba(15, 15, 15, 0.2); */
  margin: 18px;
  margin-top: 10px;
  padding: 5px;
  height: auto;
  border-radius: 8px;
}
.text-muted {
  /* background: red; */
  text-align: center !important;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  padding: 20px 0;
  font-size: 14px;
  /* background-color: red; */
  border-radius: 50%;
  width: 100%;
}
.container-layout {
  background-color: red;
  background: linear-gradient(180deg, #00000014 0%, rgb(255 255 255 / 9%) 100%);
  border-radius: 40px;

  box-shadow: 0 0 0 5px #ffe2af1c, 0 0 0 10px #5dda9b14, 0 0 0 15px #69fd9c00;
}
</style>