<template>
  <div class="education-level">
    <experience :experience="basicInfos.experienceYears" />
    <certificate :educationLevel="basicInfos.educationLevel" />
  </div>
</template>

<script>
import Experience from "../showcase/Experience";
import Certificate from "../showcase/indicators/Certificate";

export default {
  props: {
    basicInfos: Object,
  },
  components: {
    Experience,
    Certificate,
  },
};
</script>

<style lang="scss" scoped>
.education-level {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 13px 30px;
  background-color: rgba(232, 232, 232, 0.38);
  border-radius: 25px;

  @include max-width(350) {
    padding: 13px 20px;
  }
}
</style>
