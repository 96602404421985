<template>
  <div class="d-flex">
    <div class="btn btn-save" @click="save">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 10.501 9.804"
      >
        <path
          d="M549.854,902.189l2.611,2.959,4-5.4,2.147,2.553-5.935,7.252-4.566-4.989Z"
          transform="translate(-548.114 -899.752)"
          fill="#707070"
        />
      </svg>
    </div>
    <div class="btn btn-cancel" @click="cancel">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 12 12"
      >
        <path
          d="M8.275,86l3.411-3.411a1.072,1.072,0,0,0,0-1.516l-.758-.758a1.072,1.072,0,0,0-1.516,0L6,83.725,2.589,80.314a1.072,1.072,0,0,0-1.516,0l-.758.758a1.072,1.072,0,0,0,0,1.516L3.725,86,.314,89.411a1.072,1.072,0,0,0,0,1.516l.758.758a1.072,1.072,0,0,0,1.516,0L6,88.275l3.411,3.411a1.072,1.072,0,0,0,1.516,0l.758-.758a1.072,1.072,0,0,0,0-1.516Z"
          transform="translate(0 -80)"
          fill="#707070"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    save: function () {
      this.$emit("save");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn svg {
  height: 11px !important;
  width: 11px !important;
}
@mixin btn-props {
  width: 22px;
  height: 22px;
  padding: 5px;
  line-height: 0;
  border: 1px solid rgba(112, 112, 112, 0.002);
  border-radius: 15px;
  background-color: rgba(140, 140, 140, 0.22);

  &:hover {
    background-color: rgba(140, 140, 140, 0.4);
  }
}

.btn-save {
  @include btn-props;
}

.btn-cancel {
  @include btn-props;
  margin-left: 10px;

  @include max-width(510) {
    margin-left: 5px;
  }
}
</style>
