<template>
  <div class="rightContainer">
    <div v-if="getAccountType === 'Diaspora'">
      <SectionHeader
        class="reseth1"
        text="Help us match you to"
        emphasize="the right startups!"
      />
      <EngageWithStartups
        engageText="Want to engage with startups?"
        typeOfEngagementText="What type of engagement?"
        :hasError="hasError"
        :msg="msg"
      />
      <SelectInterest
        searchTitle="Select sector of interest"
        searchPlaceHolder="Search for more sectors "
        :err_msg="err_msg.expertises"
        :sectors="sectors"
      />
    </div>
    <div v-else>
      <SectionHeader text="Let’s set the scene" emphasize="for success!" />
      <div
        class="experience-container-startup"
        v-if="getAccountType === 'Startup'"
      >
        <div class="section-col">
          <StartupStage
            title="What stage is your startup *"
            :options="startupStage"
            :err_msg="err_msg.startupStage"
          />
          <NumberInTeams
            title="Number of team members *"
            :options="numberInTeams"
            :err_msg="err_msg.numberInTeams"
          />
        </div>
        <div class="section-col">
          <BusinessModel
            title="Business Model*"
            :options="businessModel"
            :err_msg="err_msg.business_model"
          />
          <RevenueState
            title="Revenue State*"
            :options="revenueState"
            :err_msg="err_msg.revenue_state"
          />
        </div>
      </div>
      <SelectInterest
        searchTitle="Select Sector*"
        searchPlaceHolder="Search for more sectors "
        :err_msg="err_msg.sector"
        :sectors="sectors"
      />
    </div>
    <div class="step-footer">
      <StepButton
        text="previous"
        @click.native="goPrevious"
        :icon="require('@/assets/registration/icons/previous.svg')"
        class="previous"
      />
      <StepButton
        class="step3_next"
        text="Next"
        @click.native="nextStep"
        :icon="require('@/assets/registration/icons/next.svg')"
      />
    </div>
  </div>
</template>
<script>
import SectionHeader from "../../../../components/Auth/SectionHeader.vue";
import EngageWithStartups from "../../../../components/Auth/EngageWithStartups.vue";
import SelectInterest from "../../../../components/Auth/SelectInterest.vue";
import StartupStage from "../../../../components/Auth/StartupStage.vue";
import BusinessModel from "../../../../components/Auth/BusinessModel.vue";
import RevenueState from "../../../../components/Auth/RevenueState.vue";
import NumberInTeams from "../../../../components/Auth/NumberInTeams.vue";
import SelectExpertise from "../../../../components/Auth/SelectExpertise.vue";
import StepButton from "../../../../components/Auth/StepButton.vue";
import i18n from "../../../../i18n";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  async mounted() {
    var lang = this.getUserLang;
    await this.loadSectorsList({ lang });
    // await this.getStartupStages();
    //await this.getBusinessModel();
    //await this.getRevenueState();
    this.getSectorstList.forEach((sector) => {
      this.sectors.push({
        id: sector.id,
        sector_id: sector.id,
        code: sector.code,
        name: sector.name,
      });
    });
  },
  computed: {
    ...mapGetters([
      "getFomData",
      "getAccountType",
      "getLevelOfEducation",
      "getFordataField",
      "getCountriesList",
      "getForeignCountriesList",
      "getSectorstList",
      "getTalents",
      "getSector_err",
      "getUserLang",
    ]),
  },

  data: () => {
    return {
      startupStage: [
        {
          name: i18n.t("Idea"),
          icon: require("@/assets/registration/icons/light-bulb.svg"),
        },
        {
          name: i18n.t("Product/MVP"),
          icon: require("@/assets/registration/icons/new-product.svg"),
        },
        {
          name: i18n.t("Go to Market"),
          icon: require("@/assets/registration/icons/diagram.svg"),
        },
        {
          name: i18n.t("Growth"),
          icon: require("@/assets/registration/icons/growth.svg"),
        },
      ],
      // startupStage: [],
      businessModel: [
        { name: "B2B" },
        { name: "B2C" },
        { name: i18n.t("Hybrid") },
      ],
      revenueState: [
        { name: i18n.t("Pre-revenue") },
        { name: i18n.t("Post-revenue") },
      ],
      numberInTeams: [
        {
          name: "1 - 5",
        },
        {
          name: "6 - 10",
        },
        {
          name: "11 - 20",
        },
        {
          name: "21 - 50",
        },
      ],
      error: false,
      hasError: false,
      expertises: [],
      msg: null,
      errors: {
        supportType: false,
        sectorOfInterrest: false,
        supportStartup: false,
        business_model: false,
        revenue_state: false,
      },
      sectors: [],
      err_msg: {
        levelOfEducation: "",
        yearsOfExperience: "",
        startupStage: "",
        numberInTeams: "",
        business_model: "",
        revenue_state: "",
        sector: "",
      },
    };
  },
  components: {
    SectionHeader,
    EngageWithStartups,
    StepButton,
    SelectInterest,
    StartupStage,
    NumberInTeams,
    BusinessModel,
    RevenueState,
    // SelectExpertise,
  },
  methods: {
    async getStartupStages() {},
    async getBusinessModel() {},
    async getRevenueState() {},
    ...mapActions([
      "loadSectorsList",
      "loadTalents",
      "loadCountriesList",
      "loadForeignCountriesList",
    ]),
    ...mapMutations(["gotoNext", "goPrevious", "goNextBis", "goPreviousBis"]),

    async nextStepDiaspora() {
      let supportStartup = this.getFordataField("supportStartup");

      if (supportStartup) {
        let supportType = this.getFordataField("supportType");
        if (!(supportType && supportType.length)) {
          this.$store.commit("setErrorMsg", {
            field: "err_msg_supportType",
            value: "select option",
          });
          this.errors.supportType = true;
        } else {
          this.hasError = false;
          this.errors.supportType = false;
          this.$store.commit("setErrorMsg", {
            field: "err_msg_supportType",
            value: null,
          });
        }
        let sectorOfInterrest = this.getFordataField("sectorOfInterrest");
        if (!(sectorOfInterrest && sectorOfInterrest.length)) {
          this.errors.sectorOfInterrest = true;
          //this.$store.commit("setSector_err", true);
          // this.hasError = true;
          this.$store.commit("setErrorMsg", {
            field: "err_msg_sectorOfInterest",
            value: "select option",
          });
          // this.err_msg.expertises = "select option";
        } else if (sectorOfInterrest.length) {
          // this.hasError = false;
          this.errors.sectorOfInterrest = false;
          this.$store.commit("setErrorMsg", {
            field: "err_msg_sectorOfInterest",
            value: null,
          });
        }
      } else {
        if (supportStartup === 0) {
          //this.$store.commit("setSector_err", false);
          // this.hasError = false;
          this.errors.supportType = false;
          this.errors.sectorOfInterrest = false;
          this.errors.supportStartup = false;
          this.$store.commit("setErrorMsg", {
            field: "err_msg_sectorOfInterest",
            value: null,
          });
          this.$store.commit("setErrorMsg", {
            field: "err_msg_supportType",
            value: null,
          });
        }
        // else {
        //   // this.error = true;
        //   //this.$store.commit("setSector_err", true);
        //   this.errors.supportType = false;
        //   // this.hasError = true;
        //   // this.msg = "select option";
        // }
      }

      let containsError = Object.values(this.errors).includes(true);
      console.log("errors", this.errors);
      if (!containsError) {
        this.gotoNext();
      }

      // if (!this.getSector_err) {
      //   this.gotoNext();
      // }
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async nextStepStartup() {
      let startupStage = this.getFordataField("startupStage");
      let numberInTeams = this.getFordataField("numberInTeams");
      let sector = this.getFordataField("sector");
      let business_model = this.getFordataField("business_model");
      let revenue_state = this.getFordataField("revenue_state");

      if (this.isEmpty(startupStage)) {
        this.errors.startupStage = true;
        this.err_msg.startupStage = "Select an option";
      } else {
        this.errors.startupStage = false;
        this.err_msg.startupStage = null;
      }

      if (this.isEmpty(numberInTeams)) {
        this.errors.numberInTeams = true;
        this.err_msg.numberInTeams = "Select an option";
      } else {
        this.errors.numberInTeams = false;
        this.err_msg.numberInTeams = null;
      }

      if (this.isEmpty(sector)) {
        this.errors.sector = true;
        this.err_msg.sector = "Select an option";
      } else {
        this.errors.sector = false;
        this.err_msg.sector = null;
      }

      if (this.isEmpty(business_model)) {
        this.errors.business_model = true;
        this.err_msg.business_model = "Select an option";
      } else {
        this.errors.business_model = false;
        this.err_msg.business_model = null;
      }

      if (this.isEmpty(revenue_state)) {
        this.errors.revenue_state = true;
        this.err_msg.revenue_state = "Select an option";
      } else {
        this.errors.revenue_state = false;
        this.err_msg.revenue_state = null;
      }

      let containsError = Object.values(this.errors).includes(true);
      if (!containsError) {
        this.goNextBis(3.5);
      }
    },
    async nextStep() {
      if (this.getAccountType === "Diaspora") {
        this.nextStepDiaspora();
      } else {
        this.nextStepStartup();
      }

      window.dataLayer.push({ event: "step3_next" });
    },
  },
  name: "StepThreeContent",
};
</script>
<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}
.rightContainer {
  background-color: rgb(255, 255, 255);
  height: 100%;
  flex-grow: 0.6;
  /* padding: 20px 0px; */
  padding-bottom: 0;
  align-items: flex-start;
  position: relative !important;
  overflow-y: hidden;
  z-index: 1000;
}
.right-header {
  display: flex;
  /* padding-left: 0 !important; */
  /* padding: 0px 50px; */
  align-items: flex-start;
  /* background-color:  orange; */
}
.select-country-container {
  display: flex;
  padding: 0px 50px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 50px;
}

.areaOfExpertise {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  align-items: flex-start;

  /* background: #00800011; */
  border-radius: 44px;
}
.areaOfExpertiseListConainter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 128, 0, 0.016);

  /* padding: 20px; */
  width: 100%;
  padding-left: 0;
}
#ul-expertise {
  /* display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 14.5px; */
  row-gap: 8px;
  width: 100%;
  align-items: center;
  height: 370px;
  overflow-y: auto;
  /* border-top: 1px solid rgba(0, 128, 0, 0.201);
  border-bottom: 1px solid rgba(0, 128, 0, 0.201); */
  padding: 10px 0;
  place-content: baseline;

  display: flex;
  flex-wrap: wrap;

  padding-bottom: 100px;
}
#ul-expertise li {
  list-style-type: none;
  padding-right: 10px;
  /* background-color: blue; */
}

.searchContainer {
  width: 100%;
  padding-bottom: 10px;
  font-size: 22px;
}
.search-input {
  height: 55px;
  border: 1px solid #ccc !important;
  width: 50%;
  padding: 12px 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
input:focus {
  outline: none !important;
  border: 1px solid #39a251 !important;
}

.step-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* width: 62%; */
  height: 80px;
  padding: 10px 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
}

.emphasize {
  color: #349e4d;
  font-weight: bold;
}
.experience-container-startup {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;
  row-gap: 15px;
  padding: 20px 50px;
  text-align: left;
  /* background-color: orange; */
}
.section-col:first-child {
  margin-right: 20px;
  /* background-color: red; */
}
.section:nth-child(2) {
  /* margin-left: 20px; */
  /* background-color:blue; */
}
@media only screen and (max-width: 1200px) {
  .reseth1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    /* padding: 25px 0px !important; */
  }
}

@media only screen and (max-width: 600px) {
  .rightContainer {
    background-color: rgb(255, 255, 255);
    height: calc(100% - 0.4vh);
    width: 100%;
    flex-grow: 0.6;
    padding: 10px 0px;
    align-items: flex-start;
    position: relative;
    overflow-y: scroll;
    z-index: 10000;
  }
  .right-header {
    display: flex;
    padding: 0px 15px;
    align-items: flex-start;
    text-align: center;
  }
  .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    padding: 25px 0px;
    margin-top: -40px;
    text-align: center;
    width: 100vw;
    z-index: 1000;
    border-radius: 64px;
    background-color: #ffffff;
  }

  .experience-container {
    padding: 0px 15px;

    padding-top: 0px !important;
  }
  #ul {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 5px;
    width: 100%;
  }

  li {
    list-style: none;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .areaOfExpertise {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    align-items: flex-start;
  }
  .areaOfExpertiseListConainter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(0, 255, 0); */
    width: 100%;
    padding-left: 0;
    padding-bottom: 60px;
  }
  #ul-expertise {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 14.5px;
    row-gap: 10px;
    width: 100%;
    align-items: center;
    height: 340px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;

    /* overflow-y: visible !important;
    height: auto !important; */
  }
  #ul-expertise li {
    list-style-type: none;
    /* background-color: blue; */
  }

  .see-more-btn {
    width: 50px;
    height: 70px;
    border-radius: 50px;
    border: none;
    position: absolute;
    left: calc(50% - 25px);
    bottom: 35px;
    color: #808896;
    border: 1px solid rgba(0, 0, 0, 0.283);
    background-color: rgba(255, 255, 255, 0.577);
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
  }
  .see-more-btn span {
    padding: 0 10px;
  }
  .see-more-btn:hover {
    background-color: #39a251;
    color: white;
  }

  .searchContainer {
    width: 100%;
    padding-bottom: 10px;
  }
  .search-input {
    height: 45px;
    border: 1px solid #ccc !important;
    width: 100% !important;
    padding: 12px 20px;
    border-radius: 10px;
    box-sizing: border-box;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #39a251 !important;
  }

  .step-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);

    /* position: absolute; */
    height: 90px;
    z-index: 999;
    z-index: 100000;
    left: 0;
    right: 0;
  }

  .experience-container-startup {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0px;
    row-gap: 15px;
    padding: 20px 15px;
  }
  .section-col:first-child {
    margin-right: 0px;
  }
  .section:nth-child(2) {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 320px) {
  #ul-expertise {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 8px;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    height: 340px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;
  }
  .experience-container-startup {
    padding: 0px 15px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>