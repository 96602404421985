<template>
  <div class="experience-container" v-if="getAccountType === 'Diaspora'">
    <div class="education">
      <p class="section-header-exp">
        {{ $t(eduTitle) }}
        <span class="msg_error" v-if="err_msg.levelOfEducation"
          >: {{ $t(err_msg.levelOfEducation) }}</span
        >
      </p>
      <ul id="ul">
        <li
          v-for="degree in levelOfEducation"
          :key="degree.id"
          @click="selectDegree(degree.name)"
        >
          <SelectBtn
            :text="degree.name"
            :icon="degree.icon"
            :active="getLevelOfEducation === degree.name"
          />
        </li>
      </ul>
    </div>
    <div class="experience">
      <p class="section-header-exp">
        {{ $t(expTitle) }}
        <span class="msg_error" v-if="err_msg.yearsOfExperience"
          >: {{ $t(err_msg.yearsOfExperience) }}</span
        >
      </p>
      <ul id="ul">
        <li
          v-for="experience in yearsOfExperience"
          :key="experience.id"
          @click="selectExperience(experience)"
        >
          <SelectBtn
            :text="experience"
            :icon="null"
            :active="getFordataField('yearsOfExperience') === experience"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SelectBtn from "./SelectBtn.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  mounted() {
    let levelOfEducation = localStorage.getItem("levelOfEducation");
    if (levelOfEducation) {
      this.$store.commit("setFormData", {
        field: "levelOfEducation",
        value: levelOfEducation,
      });
    }
    let yearsOfExperience = localStorage.getItem("yearsOfExperience");
    if (yearsOfExperience) {
      this.$store.commit("setFormData", {
        field: "yearsOfExperience",
        value: yearsOfExperience,
      });
    }
  },
  computed: {
    ...mapGetters(["getLevelOfEducation", "getAccountType", "getFordataField"]),
  },
  methods: {
    selectDegree(degree) {
      this.$store.commit("setFormData", {
        field: "levelOfEducation",
        value: degree,
      });
      localStorage.setItem("levelOfEducation", degree);
      // eslint-disable-next-line vue/no-mutating-props
      this.err_msg.levelOfEducation = false;
    },
    selectExperience(experience) {
      this.$store.commit("setFormData", {
        field: "yearsOfExperience",
        value: experience,
      });
      localStorage.setItem("yearsOfExperience", experience);
      // eslint-disable-next-line vue/no-mutating-props
      this.err_msg.yearsOfExperience = false;
    },
  },
  data: () => {
    return {
      degreeIcon: require("../../assets/registration/icons/degree.svg"),
      active: false,
    };
  },
  name: "UserExperience",
  props: {
    eduTitle: {
      type: String,
      default: "",
    },
    expTitle: {
      type: String,
      default: "",
    },
    err_msg: {},
    yearsOfExperience: {},
    levelOfEducation: {},
  },
  components: {
    SelectBtn,
  },
};
</script>

<style scoped>
.experience-container {
  display: flex;
  /* padding: 20px 0; */
  justify-content: space-between;
  padding: 20px 50px;
  padding-top: 0 !important;
  align-items: flex-start;
}

.education {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 20px;
  /* background-color: red; */
}
.btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.experience {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 20px;
}
#ul {
  display: grid;
  /* grid-template-columns: 50% 50%; */
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 5px;
  width: 100%;
}

li {
  list-style: none;
  /* background-color: #444;
    color: #fff; */
  border-radius: 5px;
  /* padding: 20px; */
  /* font-size: 150%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-header-exp {
  text-align: left;
  font-size: 18px !important;
  padding-bottom: 10px;
}
.emphasize {
  color: var(--first-color) !important;
  font-weight: bold;
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
}
@media only screen and (max-width: 1200px) {
  * {
    text-align: left;
  }
  .experience-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 15px !important;
    padding-top: 0 !important;
    align-items: flex-start;
  }
  .education {
    width: 100% !important;
    padding-right: 0px !important;
    padding-bottom: 10px !important;
  }
  .experience {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .experience p,
  .education p,
  .searchContainer p {
    /* padding-top: 10px !important; */
    font-size: 22px !important;
    color: #717171;
  }
}
@media only screen and (max-width: 600px) {
  .experience-container {
    /* border-top: 0.5px solid #6161611a; */
    padding: 15px 15px !important;
    padding-top: 0px !important;
  }
  .education {
    width: 100% !important;
    padding-right: 0px !important;
    padding-bottom: 5px !important;
  }
  .section-header-exp {
    padding-top: 8px;
  }
  .experience p,
  .education p,
  .searchContainer p {
    font-size: 18px !important;
    color: #717171;
    margin-bottom: 0.3rem;
  }
  #ul {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-gap: 5px;
    width: 100%;
  }
  .selectBtn {
    font-size: 12px;
    border-radius: 15px;
  }
  .selectBtn span {
    padding-left: 5px;
  }
}
@media only screen and (max-width: 320px) {
  #ul {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 5px;
    width: 100%;
  }
}
</style>
