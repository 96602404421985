<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t(name) }}</h6>
    </div>
    <div class="box-body p-3">
      <form @submit.prevent="update">
        <div class="row">
          <div class="col-sm-12 mb-2">
            <div class="js-form-message">
              <label class="form-label">
                <!-- {{ $t('Description') }}
                <span class="text-danger">*</span> -->
              </label>
              <div class="form-group">
                <editor
                  apiKey="frsb2ky82bt6d80huabhezpgj47c9ktx01iha9lm1kpav85q"
                  :init="{
                    height: this.height,
                    menubar: true,
                    themes: 'inlite',
                    plugins: [
                      'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                      'searchreplace wordcount visualblocks visualchars code fullscreen',
                      'insertdatetime media nonbreaking save table contextmenu directionality',
                      'emoticons template paste textcolor colorpicker textpattern',
                    ],
                    toolbar:
                      'insertfile undo redo paste  spellchecker pagebreak visualblocks visualchars anchor autolink charmap help linkchecker nonbreaking legacyoutput tabfocus | styleselect | bold italic | forecolor backcolor | restoredraft |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | advlist | link image media | emoticons | fullpage | preview | hr | insertdatetime | searchreplace',
                  }"
                  v-model="content"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-right">
          <div class="overflow-hidden text-center p-3">
            <button
              :disabled="loading"
              type="submit"
              class="font-weight-bold btn btn-light rounded p-3 btn-block"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
              {{ loading ? '' : $t('save') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      loading: false,
      init: {
        themes: 'modern',
        mobile: {
          theme: 'mobile',
          plugins: ['autosave', 'lists', 'autolink'],
        },
        height: this.height,
        selector: 'textarea',
        plugins: [
          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars code fullscreen',
          'insertdatetime media nonbreaking save table contextmenu directionality',
          'emoticons template paste textcolor colorpicker textpattern',
        ],
        toolbar:
          'insertfile undo redo paste  spellchecker pagebreak visualblocks visualchars anchor autolink charmap help linkchecker nonbreaking legacyoutput tabfocus | styleselect | bold italic | forecolor backcolor | restoredraft |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | advlist | link image media | emoticons | fullpage | preview | hr | insertdatetime | searchreplace',
        relative_urls: false,
        file_browser_callback: function(field_name, url, type, win) {
          var x =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.getElementsByTagName('body')[0].clientWidth;
          var y =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName('body')[0].clientHeight;
        },
      },
    };
  },
  methods: {
    update() {
      this.btnLoading = true;
      this.$store
        .dispatch(this.updateMethod, { [this.updateField]: this.content })
        .then((success) => {
          this.$noty.success(this.updateField + ' Updated Successfully !');
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          this.$noty.error(error);
        });
    },
  },
  name: 'FullEditor',
  props: {
    name: String,
    content: String,
    height: {
      type: Number,
      default: 200,
    },
    updateMethod: String,
    updateField: String,
  },
};
</script>
<style>
.hide {
  display: none;
}
.widget2 {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid #dddddd;
  padding: 10px !important;
  padding-top: 20px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

.bg-gray {
  background: rgb(231, 231, 231) none repeat scroll 0 0;
}

.bg-stats {
  background: rgb(6, 145, 115) none repeat scroll 0 0;
}
</style>
