<template>
  <div>
    <div class="py-2">
      <div class="container-fluid">
        <div class="row" v-if="!loading">
          <main class="col-md-10">
            <div class="row box bg-white rounded my-4 p-2">
              <div class="col-md-12 mb-4">
                <UpdateAvatar
                  class="shadow-md"
                  :profileAavatar="authUser.avatar"
                />
              </div>
              <div class="col-md-12 mb-4">
                <UpdateCover class="shadow-md" :cover="startup.cover" />
              </div>
              <div class="col-md-12 mb-4">
                <UpdateVideo class="shadow-md" :link="startup.video" />
              </div>
            </div>
          </main>
        </div>
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../../constant";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "../../../i18n";
import { en, fr } from "vuejs-datepicker/dist/locale";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Branding ",
    };
  },
  async created() {
    await this.loadStartup();
    this.startup = this.getStartup;
    this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getStartup"]),
  },
  data() {
    return {
      startup: {},
      loading: true,
    };
  },
  methods: {
    ...mapActions(["loadStartup"]),
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Branding",
};
</script>
