<template>
  <div
    class="chat-bubble"
    :class="{ activeBubble: getIsBubbleActive, closed: getIsBubbleClosed }"
    ref="MessageBubble"
    v-if="!getIsNewChatConvo"
  >
    <BubbleHeader
      :title="title"
      :currentlyChattingAvatar="getCurrentlyChattingWith.avatar"
    />
    <Block />
    <BubbleMessages ref="BubbleMessages" />
    <div class="message-input" v-if="!getIsBlocked">
      <form v-on:submit.prevent="commitSendMessage" class="message-form">
        <p class="input">
          <Typing :user="authUser.firstname" />
          <textarea
            @keydown="onTyping"
            @blur="handleKeyUp"
            @keyup.enter="commitSendMessage"
            :placeholder="$t('writeAMessage')"
            ref="myTextarea"
            v-model="message"
            :min-height="30"
            :max-height="350"
            class="textarea"
            @input="resize($event)"
            id="textarea"
          />
        </p>
        <button class="send-message" type="submit" @click="commitSendMessage">
          <svg class="crt8y2ji" width="20px" height="20px" viewBox="0 0 24 24">
            <path
              d="M16.6915026,12.4744748 L3.50612381,13.2599618 C3.19218622,13.2599618 3.03521743,13.4170592 3.03521743,13.5741566 L1.15159189,20.0151496 C0.8376543,20.8006365 0.99,21.89 1.77946707,22.52 C2.41,22.99 3.50612381,23.1 4.13399899,22.8429026 L21.714504,14.0454487 C22.6563168,13.5741566 23.1272231,12.6315722 22.9702544,11.6889879 C22.8132856,11.0605983 22.3423792,10.4322088 21.714504,10.118014 L4.13399899,1.16346272 C3.34915502,0.9 2.40734225,1.00636533 1.77946707,1.4776575 C0.994623095,2.10604706 0.8376543,3.0486314 1.15159189,3.99121575 L3.03521743,10.4322088 C3.03521743,10.5893061 3.34915502,10.7464035 3.50612381,10.7464035 L16.6915026,11.5318905 C16.6915026,11.5318905 17.1624089,11.5318905 17.1624089,12.0031827 C17.1624089,12.4744748 16.6915026,12.4744748 16.6915026,12.4744748 Z"
              fill-rule="evenodd"
              stroke="none"
            ></path>
          </svg>
        </button>
      </form>
    </div>

    <audio id="noty_audio">
      <source src="@/assets/audio/notify.mp3" />
      <!-- <source src="@/assets/audio/notify.ogg" />
      <source src="@/assets/audio/notify.wav" /> -->
    </audio>

    <ModalChat v-if="showModal" @close="showModal = false" />
  </div>
</template>
<script>
import Axios from "axios";
import APIURLS from "../../constant";
import moment from "moment";
import i18n from "../../i18n";
import BubbleHeader from "./BubbleHeader";
import BubbleMessages from "./BubbleMessages";
import Typing from "./Typing";
import Block from "./Block";
import { debounce, _ } from "lodash";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { langs } from "i18n-iso-countries";
import ModalChat from "@/components/Modal/ModalChat";

export default {
  metaInfo() {
    return {
      //  title: 'Default App Title',
      //  titleTemplate: '%s | vue-meta Example App',
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  mounted() {
    if (this.getIsBubbleActive) {
      let bodyname = document.getElementsByTagName("body")[0];
      bodyname.style.overflow = "hidden";
    } else {
      let bodyname = document.getElementsByTagName("body")[0];
      bodyname.style.overflow = "auto";
    }
  },
  computed: {
    cssVars() {
      return {
        "--defaultPositionStyles": `${this.MessageBubbleHeight}`,
      };
    },
    title() {
      if (this.getCurrentlyChattingWith.type === 2) {
        return this.getCurrentlyChattingWith.startup.startupname;
      } else {
        return (
          this.getCurrentlyChattingWith.firstname +
          " " +
          this.getCurrentlyChattingWith.name
        );
      }
    },
    ...mapGetters([
      "authUser",
      "getIsBubbleClosed",
      "getIsBubbleActive",
      "getCurrentlyChattingWith",
      "getIsBlocked",
      "getBlockedUser",
      "getIsNewChatConvo",
      "getUserNotifications",
      "getChatList",
      "getNotifs",
      "getLoadMoreClicked",
      "getUserLang",
      "getUserTalents"
    ]),
  },
  // watch() {
  //   if (this.getIsBubbleActive) {
  //     let bodyname = document.getElementsByTagName("body")[0];
  //     bodyname.style.overflow = "hidden";
  //   } else {
  //     let bodyname = document.getElementsByTagName("body")[0];
  //     bodyname.style.overflow = "auto";
  //   }
  // },

  data: () => {
    return {
      message: "",
      timer: 1000,
      timeoutVal: 1000,
      showModal: false,
    };
  },
  sockets: {
    async private_chat(data) {
      const newMessage = data.params;
      if (data.params.senderId === this.getCurrentlyChattingWith.id) {
        this.$store.commit("addNewMessage", newMessage);
      }
      this.$store.commit(
        "incrementUserUnreadMsg",
        this.getCurrentlyChattingWith.id
      );
      const user_id = this.authUser.id;
      await this.loadChatList(user_id);
      await this.sortMessaging(this.getChatList);
      //this.getNotifs();
      //this.playSound();
    },
    async new_notification(data) {
      // let type = "new";
      // let user_id = this.authUser.id;
      //await this.loadUserMsgNotifs({ user_id });
      this.playSound();
    },
    async typing(data) {
      this.$store.commit("setTyping", true);
      this.$store.commit("setTypingUser", data.params);
    },
    async end_typing(data) {
      this.$store.commit("setTyping", false);
      this.$store.commit("setTypingUser", {});
    },

    async block_user(data) {
      const { from, to } = data;
      this.$store.commit("setIsBlocked", true);
      this.$store.commit("setBlockedUser", { from });
    },
    async un_block_user(data) {
      this.$store.commit("setIsBlocked", false);
      this.$store.commit("setBlockedUser", {});
    },
  },
  methods: {
    ...mapMutations(["setChatList"]),
    ...mapActions([
      "unblockUser",
      "markUnreadMessages",
      "loadUserNotifications",
      "loadUserMsgNotifs",
      "loadChatList",
      "sortMessaging",
      "getSectorByLang",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "checkCanMessage",
      "loadUserExpertises"
    ]),
    resize(e) {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
      // var container = this.$el.querySelector("#container");
      e.target.scrollTop = e.target.scrollHeight;
      // e.target.style.margin = "0px 0px 30px  0px";
    },
    handleChange($event) {
      console.log($event.target);
    },
    onTyping: debounce(function () {
      window.clearTimeout(this.timer);
      this.$socket.emit("typing", {
        to: this.getCurrentlyChattingWith.username,
        params: {
          user_id: this.authUser.id,
          name: this.authUser.firstname,
        },
      });
    }, 500),
    handleKeyUp(e) {
      window.clearTimeout(this.timer); // prevent errant multiple timeouts from being generated
      this.timer = window.setTimeout(() => {
        this.$socket.emit("end_typing", {
          to: this.getCurrentlyChattingWith.username,
          params: {
            user_id: this.authUser.id,
            name: this.authUser.firstname,
          },
        });
      }, this.timeoutVal);
    },
    playSound() {
      document.getElementById("noty_audio").play();
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    ScrollMessagesToBottom() {
      var content = this.$refs.BubbleMessages.$refs.msgContainer;
      if (content) {
        content.scrollTop = content.scrollHeight;
      }
    },
    async commitSendMessage() {
      let canMessage = await this.checkCanMessage({
        user_id: this.authUser.id,
        type: this.authUser.type,
      });
      if (!canMessage) {
        this.showModal = true;
      } else {
        if (this.message === "" || this.message === null) return;
        if (!this.isEmpty(this.message)) {
          let sector,
            countryOfOrigin,
            livesIn,
            headquarters,
            lang = this.getUserLang;
          let expertise;
          let currentdate = new Date();
          let hours = currentdate.getHours();
          let minutes = currentdate.getMinutes();
          let time = hours + ":" + minutes;
          let senderName = this.authUser.firstname + " " + this.authUser.name;
          let type = this.authUser.type;
          if (this.authUser.type === 2) {
            senderName = this.authUser.startup.startupname;
            let code = this.authUser.startup
              ? this.authUser.startup.sector
              : "";
            sector = await this.getSectorByLang({ code, lang });
            let countryCode = this.authUser.startup
              ? this.authUser.startup.location
              : "";
            headquarters = await this.getAFricanCountryByLang({
              code: countryCode,
              lang,
            });
          } else if (this.authUser.type === 1) {
            let originCode = this.authUser.profile
              ? this.authUser.profile.birthCountry
              : "";
            countryOfOrigin = await this.getAFricanCountryByLang({
              code: originCode,
              lang,
            });
            let livesInCode = this.authUser.profile
              ? this.authUser.profile.residenceContry
              : "";

            livesIn = await this.getForeignCountryByLang({
              code: livesInCode,
              lang,
            });
            await this.loadUserExpertises({
              lang,
              user_id: this.authUser.id,
            });

            let talents = this.getUserTalents;
            if (talents.length && talents[0].talents_name) {
              expertise = talents[0].talents_name;
            }

            //console.log({ talents });
          }
          let receiverName =
            this.getCurrentlyChattingWith.firstname +
            " " +
            this.getCurrentlyChattingWith.name;

          if (this.getCurrentlyChattingWith.type === 2) {
            receiverName = this.getCurrentlyChattingWith.startup.startupname;
          }
          let newMessage = {
            senderAvatar: this.authUser.avatar,
            senderId: this.authUser.id,
            senderName,
            receiverId: this.getCurrentlyChattingWith.id,
            receiverAvatar: this.getCurrentlyChattingWith.avatar,
            receiverName,
            time: time,
            text: this.message,
            createdAt: new Date(),
            lang: this.$i18n.locale,
            email: this.getCurrentlyChattingWith.email,
            expertise,
            headquarters,
            sector,
            countryOfOrigin,
            livesIn,
            type
          };
          
          // alert(this.getCurrentlyChattingWith.email);
          var currentUser = this.authUser.username.toLowerCase();
          var otherChatUser =
            this.getCurrentlyChattingWith.username.toLowerCase();
          moment.locale(i18n.locale);
          var currentDate = moment();
          var users = [];
          users.push(this.authUser.username.toLowerCase());
          users.push(otherChatUser.toLowerCase());
          let room = users.sort().join(".");
          const params = {
            senderId: this.authUser.id,
            senderName,
            senderAvatar: this.authUser.avatar,
            receiverId: this.getCurrentlyChattingWith.id,
            receiverAvatar: this.getCurrentlyChattingWith.avatar,
            receiverName,
            room,
            userId: this.authUser.id,
            withUserId: this.getCurrentlyChattingWith.id,
            isRead: 0,
            text: this.message,
            time: currentDate.format("hh A:mm"),
            createdAt: new Date(),
          };
          this.$socket.emit("private_chat", {
            to: this.getCurrentlyChattingWith.username,
            params: newMessage,
          });

          this.$store.commit("addNewMessage", newMessage);
          this.message = "";
          this.ScrollMessagesToBottom();
          this.$nextTick(() => {
            var element = document.getElementById("textarea");
            if (element) {
              element.style.height = "auto";
              element.style.height = `${element.scrollHeight}px`;
            }
          });
          // if (!this.getChatList.length || this.getLoadMoreClicked) {
          //   await this.loadChatList(this.authUser.id);
          // }
          await this.loadChatList(this.authUser.id);
          await this.sortMessaging(this.getChatList);
        }
      }
    },
  },
  name: "ChatBubble",
  components: {
    BubbleHeader,
    BubbleMessages,
    Block,
    Typing,
    ModalChat,
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none !important;
}
*:focus {
  outline: 0 !important;
}
.chat-bubble {
  position: fixed;
  z-index: 9000;
  /* bottom: calc(50px - var(--defaultPositionStyles));  */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 336px;
  height: 400px;
  right: calc(288px + 20px);
  bottom: -350px;
  max-height: calc(100vh - 100px);
  background-color: white;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.8rem 0.8rem 0 0;
  transition-duration: 167ms;
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
  display: block;
}

.activeBubble {
  bottom: 0;
  display: block;
}
.closed {
  display: none;
}
.message-input {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  padding-bottom: 50px;
}
.message-form {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px !important;
}

.input,
.textarea {
  font-family: inherit;
  font-size: inherit;
  padding: 6px;
  padding: 15px 6px;
  box-sizing: border-box;
}
p.input {
  width: 100%;
  background-color: white;
  text-align: left;
}

.textarea {
  display: block;
  width: 100%;
  resize: both;
  max-height: 100px !important;
  min-height: 40px;
  line-height: 20px;
  border: none !important;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.42857;
  border-radius: 8px;
  padding-right: 45px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll !important;
}

textarea:focus {
  outline: none !important;
  border: 1px solid rgba(15, 127, 18, 0.5) !important;
}
.textarea::active .message-form {
  border-top: 1px solid blue;
}
button.send-message {
  position: absolute;
  top: 25px;
  right: 5px;
  height: 100%;
  width: 45px;
  border-radius: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent !important;
}
button.send-message svg {
  width: 20px;
  fill: #c4c8cb;
  fill: #3d8f38;
}
textarea:focus + button.send-message svg {
  fill: pink !important;
}

.testing {
  position: absolute;
  top: -50%;
}
@media (max-width: 650px) {
  .chat-bubble {
    width: 100vw !important;
    height: 100vh;
    bottom: 0px;
    left: 0px !important;
    max-height: calc(100vh - 0px);
    padding-bottom: 100px;
    border-radius: 0;
    background-color: #ffffff;
    backdrop-filter: blur(10px);

    z-index: 100000;
  }
  /* .activeBubble {
  bottom: 0px;
}  */

  .message-input {
    bottom: 60px;
    height: 100px;
  }
  .textarea {
    border: none;
    line-height: 1.2;
    border-radius: 15px;
    padding-right: 45px;
    box-sizing: border-box;
  }
  button.send-message {
    position: absolute;
    right: 0px;
    height: 100%;
    width: 35px;
    border-radius: 35px;
    max-height: 35px;
    right: 10px;
    top: 5px;
  }
  button.send-message svg {
    width: 16px;
  }
}
</style>