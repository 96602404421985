<template>
  <div class="#">
    <div class="singleJob single-layout" v-if="!loading">
      <JobTopBanner :job="job" />
      <section class="main-content">
        <div class="description">
          <h2 class="title">{{ $t("Job Description") }}</h2>
          <p v-html="job.fullText"></p>
          <!-- <div class="contact-det">
            <div class="info">
              <center>
                <h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41.321"
                    height="41.321"
                    viewBox="0 0 41.321 41.321"
                  >
                    <g
                      id="Group_57"
                      data-name="Group 57"
                      transform="translate(838.996 -871)"
                    >
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M-839,891.653A20.646,20.646,0,0,1-818.314,871a20.647,20.647,0,0,1,20.639,20.7,20.646,20.646,0,0,1-20.707,20.626A20.647,20.647,0,0,1-839,891.653Zm1.815,0a18.868,18.868,0,0,0,18.832,18.852,18.867,18.867,0,0,0,18.858-18.85,18.869,18.869,0,0,0-18.844-18.84A18.873,18.873,0,0,0-837.181,891.654Z"
                        fill="#00b074"
                      />
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M-772.918,941.176c-.039.159-.067.322-.119.478a5.76,5.76,0,0,1-1.865,2.739,9.737,9.737,0,0,1-2.907,1.7,2.192,2.192,0,0,1-1.868-.144,41.9,41.9,0,0,1-11.638-9.3,43.7,43.7,0,0,1-6.8-10.1,2.031,2.031,0,0,1-.149-1.429,6.922,6.922,0,0,1,5.345-5.292c.323-.092.509-.008.743.333a16.638,16.638,0,0,1,1.935,3.472c.328.868.643,1.742.937,2.622.164.492.086.665-.334.949q-.776.526-1.562,1.037c-.373.242-.5.494-.314.9a10.062,10.062,0,0,0,.759,1.419,28.4,28.4,0,0,0,3.367,4,35.171,35.171,0,0,0,3.73,3.482,10.619,10.619,0,0,0,1.483.959c.5.27.663.2,1.013-.25.3-.391.606-.782.9-1.178a.776.776,0,0,1,.933-.291,16.59,16.59,0,0,1,4.41,2.022c.564.355,1.092.769,1.625,1.172A.831.831,0,0,1-772.918,941.176Z"
                        transform="translate(-33.662 -40.392)"
                        fill="#00b074"
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M-713.852,932.207c0,.165,0,.33,0,.495a.907.907,0,0,1-.91.964.9.9,0,0,1-.907-.96,11.543,11.543,0,0,0-3.95-8.7,11.4,11.4,0,0,0-7.671-2.968.918.918,0,0,1-.986-1.144.891.891,0,0,1,.933-.674,12.77,12.77,0,0,1,6.044,1.447,13.379,13.379,0,0,1,7.345,10.423c.027.189.043.38.057.57s.02.364.029.546Z"
                        transform="translate(-91.622 -39.914)"
                        fill="#00b074"
                      />
                      <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M-717.213,948.4c0,.171,0,.3,0,.432a.9.9,0,0,1-.9.915.885.885,0,0,1-.908-.906,8.682,8.682,0,0,0-.473-2.76,8.394,8.394,0,0,0-7.849-5.619.916.916,0,0,1-.974-.9.907.907,0,0,1,.986-.912,10.247,10.247,0,0,1,1.117.066,10.2,10.2,0,0,1,8.912,8.742C-717.257,947.779-717.24,948.109-717.213,948.4Z"
                        transform="translate(-91.614 -55.993)"
                        fill="#00b074"
                      />
                      <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M-720.551,964.715a2.463,2.463,0,0,1-.034.423.888.888,0,0,1-.885.669.864.864,0,0,1-.864-.69c-.056-.306-.045-.623-.091-.931a5.035,5.035,0,0,0-4.927-4.32.908.908,0,0,1-.92-1.171.9.9,0,0,1,.933-.645,6.744,6.744,0,0,1,5.326,2.63A6.728,6.728,0,0,1-720.551,964.715Z"
                        transform="translate(-91.619 -72.056)"
                        fill="#00b074"
                      />
                    </g>
                  </svg>
                  <span class="text">+88 01903-053300</span>
                </h3>
              </center>
            </div>

            <div class="info">
              <center>
                <h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48.62"
                    height="32.094"
                    viewBox="0 0 48.62 32.094"
                  >
                    <g
                      id="Group_58"
                      data-name="Group 58"
                      transform="translate(538.94 -901.165)"
                    >
                      <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M-514.66,933.258q-10.7,0-21.408,0c-2.076,0-2.87-.784-2.87-2.832q0-13.217,0-26.434c0-2.045.8-2.826,2.878-2.826q21.458,0,42.917,0c2,0,2.82.8,2.821,2.782q.005,13.267,0,26.534c0,1.965-.822,2.773-2.83,2.774Q-503.906,933.262-514.66,933.258Zm-.013-13.48,21.008-16.229a8.113,8.113,0,0,0-1.572-.367q-19.859-.025-39.719-.013l-.08.344Zm-5.316-.933L-535.8,930.729a13.2,13.2,0,0,0,1.954.338q19.134.025,38.268,0a13.636,13.636,0,0,0,1.937-.32l-15.585-11.923-5.482,4.227Zm-16.774-13.4v22.789l14.7-11.052Zm44.171,22.857V905.981L-507.11,917.2Z"
                        transform="translate(0 0)"
                        fill="#00b074"
                      />
                    </g>
                  </svg>
                  <span class="text">yourmail@gmal.com</span>
                </h3>
              </center>
            </div>
          </div> -->
        </div>
        <JobSideBar :job="job" />
      </section>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import Axios from "axios";
import APPURL from "../../constant";
import JobTopBanner from "../../components/Jobs/JobTopBanner.vue";
import JobSideBar from "../../components/Jobs/JobSideBar.vue";
import i18n from "../../i18n";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Careers | " + this.job.title,
    };
  },
  mounted() {
    this.getJob();
  },
  computed: {},
  methods: {
    getJob() {
      var slug = `${this.$route.params.slug}`;
      var request = APPURL.JOBS + "slug/" + slug;
      Axios.get(request).then((response) => {
        this.loading = false;
        this.job = response.data.job;
      });
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  data() {
    return {
      loading: true,
      users: {},
      counts: {},
      job: {},
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Jobs",
  components: {
    JobTopBanner,
    JobSideBar,
  },
};
</script>
<style scoped>
.single-layout {
  background: white;
  width: 100vw;
}
.title {
  text-align: left !important;
  color: #000000;
  font-size: 30px;
  font-weight: bold;
}
section {
  padding: 30px;
}
.main-content .description .contact-det .info {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.main-content {
  display: inline-block;
  /* padding-right: 150px; */
  /* padding-left: 150px; */
  /* background-color: white !important; */

  padding-right: 0px;
  padding-left: 0px;
  background-color: white !important;
  max-width: 990px;
}
.main-content .description {
  width: 70%;
  float: left;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.1) !important;
}

.main-content .description p {
  margin-top: 20px;
  line-height: 30px;
}
.main-content .description h3 {
  margin-top: 20px;
}
.main-content .description .contact-det {
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 100%;
  box-shadow: 1px 1px 5px 1px #a7cbf7;
  margin-top: 80px;
}
.main-content .description .contact-det .info {
  width: 45%;
  float: left;
}
.main-content .description .contact-det .info:nth-child(1) {
  border-right: 2px solid #e8e8e8;
}
.main-content .description .contact-det .info svg {
  width: 30px !important;
  position: absolute;
}
.main-content .description .contact-det .info:nth-child(1) svg {
  margin-top: -8px;
}
.main-content .description .contact-det .info:nth-child(2) svg {
  margin-top: -3px;
}
.main-content .description .contact-det .info .text {
  margin-left: 50px;
}
.btn-green {
  background: #00b074;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  font-weight: bold;
}

@media (max-width: 770px) {
  body section {
    padding: 30px;
  }

  .title {
    font-size: 20px;
  }

  .main-content {
    padding: 0px 5px 0px 5px !important;
    display: block !important;
    background-color: white !important;
  }
  .main-content .description {
    width: 100% !important;
    padding: 10px;
    border-radius: 0px;
    display: block !important;
  }
  .main-content .description .contact-det .info h3 {
    font-size: 12px;
  }
  .main-content .description .contact-det {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .main-content .description .contact-det .info {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .main-content .description .contact-det .info h3 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .main-content .description .contact-det .info:nth-child(1) svg {
    height: 20px !important;
    margin-top: -4px !important;
  }
  .main-content .description .contact-det .info:nth-child(2) svg {
    height: 15px !important;
    margin-top: -0px !important;
  }
}
</style>
