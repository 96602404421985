<template>
    <div class="toasty">
        <div class="toast-container"> Check your email</div>
    </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Toast'
}
</script>
<style scoped>
  .toasty{
      position: absolute;
      z-index: 100;
      top:  95px;
      right: 20px;
      max-width: 350px
  }
  .toast-container{
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #57b366;
      color: white;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
       border-radius: 8px;
       font-size: 14px;
  }
  .toast-container::before{
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 10%;
      background: #57b366;
      /* bottom:10px; */
      right: -8px;
      transform: rotate(45deg);
      z-index:  -1;
  }

  @media (min-width:600px){
   .toasty{
      top:  60px;
      right: 100px;
  }
  .toast-container::before{
      right: inherit;
      top: -8px;
  }
}

</style>