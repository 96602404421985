<template>
  <div class="final-step" id="final-step">
    <div class="container">
      <div class="balloon-container2">
        <div class="balloon" style="left: 50px">
          <div class="balloon1"></div>
        </div>

        <div class="balloon" style="left: 150px">
          <div class="balloon2"></div>
        </div>

        <div class="balloon" style="left: 250px">
          <div class="balloon3"></div>
        </div>

        <div class="balloon" style="left: 350px">
          <div class="balloon4"></div>
        </div>
      </div>

      <img
        alt="background-image"
        src="@/assets/registration/bg.svg"
        class="bg"
      />
      <div class="main-content">
        <!-- v-if="getAccountType === 'Diaspora'" -->
        <img
          alt="at the office"
          src="@/assets/registration/At the office.svg"
          class="illustation"
        />
        <!--  -->
        <!-- <img
            v-if="getAccountType === 'Startup'"
            alt="at the office"
            src="@/assets/registration/startup-finalstep-illustration2.svg"
            class="illustation s-illustration"
          /> -->

        <div class="welcome-messages">
          <p class="first-text">
            {{ $t("We are thrilled to have you on Bantaba!") }}
          </p>
          <h1 class="bantaba-text">BANTABA</h1>
        </div>
        <!-- <img
            alt="background-image"
            src="@/assets/registration/balloons-bg.svg"
            class="balloons-bg"
          /> -->
        <div class="balloon-container">
          <div class="balloon" style="left: 50px">
            <div class="balloon1"></div>
          </div>

          <div class="balloon" style="left: 150px">
            <div class="balloon2"></div>
          </div>

          <div class="balloon" style="left: 250px">
            <div class="balloon3"></div>
          </div>

          <div class="balloon" style="left: 350px">
            <div class="balloon4"></div>
          </div>
        </div>
        <div class="progress-bar-container">
          <label v-if="percentage < 100" for="file">{{
            $t("Sit tight! We're setting you up")
          }}</label>
          <label v-else for="file"
            >{{ $t("You're all set up!") }} {{ goToDashboard() }}</label
          >
          <div class="loading-bar">
            <div class="percentage" :style="{ width: percentage + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import Axios from "axios";
import i18n from "../../../../i18n";
import store from "../../../../store/";
import APIURLS from "../../../../constant";

export default {
  name: "FinalStep2",
  created() {
    var intval = setInterval(() => {
      if (this.percentage < 100) this.percentage += 0.5;
      else {
        clearInterval(intval);
        this.slidePannelDown();
      }
    }, 50);
  },
  data() {
    return {
      percentage: 0,
    };
  },
  computed: {
    ...mapGetters(["getAccountType", "getFomData", "getFordataField"]),
    percent() {
      return this.percentage.toFixed();
    },
  },
  mounted() {
    // if (this.percentage === 100) {
    //   this.$router.push(`/${i18n.locale}/${i18n.t("routes.myActivity")}`);
    // }
  },
  methods: {
    goToDashboard() {
      //clear all localstorage except the language
      // this.$router.push(`/${i18n.locale}/${i18n.t("routes.myActivity")}`).catch(err => {});
      let url = `/${i18n.locale}/${i18n.t("routes.myActivity")}`;
      if (this.$route.path != url) {
        this.$router.push(url).catch((err) => {});
      }
    },
    slidePannelDown() {
      let panel = document.getElementById("final-step");
      //panel.style.transform = "translateY(100vh)";
      //this.loginUser()
    },
  },
};
</script>
<style scoped>
* {
  font-family: inherit;
}
.final-step {
  /* width: 60vw; */
  background-color: rgb(255, 255, 255);
  height: 100vh;
  overflow-x: hidden;
  position: absolute;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
  transition: all 0.4s ease-in-out;
}
.container {
  position: relative !important;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  /* z-index: 999999999; */
}
.balloons-bg {
  position: absolute;
  left: -230px;
  top: 50px;
  display: flex;
  justify-content: center;
  /* width: 1524px;
  height: 800px; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.main-content {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.illustation {
  height: 65vh;
  margin-top: -120px;
  animation: scaleUp 1s ease 0s infinite alternate both;
}
.welcome-messages {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
}
.first-text {
  margin-bottom: -10px;
  margin-top: -100px;
  z-index: 3000;
  /* font-size: 22px;
  letter-spacing: 1.4px; */
  font-weight: lighter;
  font-size: 18px;
  letter-spacing: 3.1px;
  /* animation: bounce 1s ease 0.2s infinite alternate both; */
}
.bantaba-text {
  margin: 0;
  font-weight: bold;
  font-size: 96px !important;
  z-index: 3000;
  text-align: center;
  color: #39943a;
}
.paragraph {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  z-index: 3000;
  /* font-size: 18px;
  letter-spacing: 1px;
  font-weight: lighter;
  width: 45vw; */
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: lighter;
  width: 65vw;
  margin-bottom: 15px;
  /* animation: bounce 1s ease 0s infinite alternate both; */
}
.welcome-messages::before {
  content: "";
  background: url("../../../../assets/registration/fade.svg");
  position: absolute;
  width: 100vw;
  height: 400px;
  z-index: 1000;
  top: -400px;
  left: -20vw;
}
.happy-emoji {
  width: 18px;
  height: auto;
}

.balloon-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: -12%;
  top: 25%;
  /* background: url(../../../../assets/registration/balloon.svg) no-repeat; */
  background-size: cover;
  cursor: crosshair;
  position: absolute;
  display: flex;
}
.balloon-container2 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: -12%;
  top: 5%;
  /* background: url(../../../../assets/registration/balloon.svg) no-repeat; */
  background-size: cover;
  cursor: crosshair;
  position: absolute;
  display: flex;
  opacity: 0.9;
}

.balloon {
  width: 100px;
  display: inline-block;
  position: relative;
  top: 200px;
  left: 80px;
  -webkit-animation: inflatable 6s ease-in-out infinite;
  -moz-animation: inflatable 6s ease-in-out infinite;
  -o-animation: inflatable 6s ease-in-out infinite;
  animation: inflatable 6s ease-in-out infinite;
}

/* .percentage {
  margin-top: 20px;
  font-family: robot;
  font-size: 20px;
  color:  black;
  padding: 7px 25px;
  padding-top: 6px;
  border-radius: 25px;
  margin: auto;
  width: 50px;
} */

.balloon {
  width: 38px;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
  top: 22%;
}
.balloon > div {
  width: 100px;
  height: 130px;
  border-radius: 0;
  border-radius: 80% 80% 80% 80%;
  margin: 0 auto;
  position: absolute;
  padding: 10px;
  -webkit-transform-origin: bottom center;
}
.balloon > div {
  background: rgb(30, 144, 255);
  background: rgb(244 244 244);
  background-color: #17a3b86b;
  background: #f4f4f4;
  left: 0;
  -webkit-animation: balloon1 6s ease-in-out infinite;
  -moz-animation: balloon1 6s ease-in-out infinite;
  -o-animation: balloon1 6s ease-in-out infinite;
  animation: balloon1 6s ease-in-out infinite;
  /* &:before {
    color: rgba(182, 15, 97, 0.9);
  } */
}
.balloon > div:before {
  color: rgb(30, 144, 255);
  color: #f4f4f4;
  position: absolute;
  bottom: -11px;
  left: 52px;
  content: "▲";
  font-size: 1em;
}
.balloon > :after {
  display: inline-block;
  top: 153px;
  top: 167px;
  position: absolute;
  height: 180px;
  width: 3px;
  margin: 0 auto;
  content: "";
  background: rgba(0, 0, 0, 0.2);
  background: #f4f4f4;
  left: 60px;
  pointer-events: none;
}

/*BALLOON 1 4*/
@-webkit-keyframes balloon1 {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(-6deg);
  }
  50% {
    -webkit-transform: translateY(0px) rotate(8deg);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(5px);
  }
}
@keyframes scaleUp {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(2px);
  }
}
.progress-bar-container {
  width: 100vw;
  width: 60vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

/* progress[value] {
  width: 250px;
  height: 20px;
  background-color: orange;
} */
.loading-bar {
  position: relative;
  width: 400px;
  height: 10px;
  border-radius: 15px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4), 0 -1px 1px #fff, 0 1px 0 #fff;
}
.percentage {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  display: block;
  height: 100%;
  border-radius: 15px;
  background-color: #a5df41;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  animation: animate-stripes 3s linear infinite;
}
@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1200px) {
  .bantaba-text {
    font-size: 96px !important;
  }
  .first-text {
    margin-bottom: -10px;
    margin-top: -100px;
    z-index: 3000;
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: lighter;
    width: 90vw;
  }
  .paragraph {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    z-index: 3000;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: lighter;
    width: 90vw;
  }
  .welcome-messages {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2000;
    margin-top: 150px;
  }
  welcome-messages::before {
    content: "";
    /* background: url(/img/fade.b2d2f43d.svg); */
    width: 100vw;
    height: 400px;
    z-index: 1000;
    top: -350px;
    left: 0px !important;
  }
  .illustation {
    /* width: 95vw; */
    height: 50vh;
    /* margin-top: -150px;  */
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .bantaba-text {
    font-size: 56px !important;
    padding: 15px 0 !important;
  }
  .first-text {
    margin-bottom: -10px;
    margin-top: -100px;
    z-index: 3000;
    font-size: 16px;
    letter-spacing: 1.2px;
    font-weight: lighter;
    width: 100vw;
    text-align: center !important;
  }
  .paragraph {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center !important;
    /* z-index: 3000;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: lighter;
    width: 95vw; */
    z-index: 3000;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: lighter;
    width: 90vw;
  }
  .welcome-messages {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2000;
    margin-top: 150px;
  }
  .welcome-messages::before {
    content: "";
    /* background: url(/img/fade.b2d2f43d.svg); */
    width: 150vw;
    height: 400px;
    z-index: 1000;
    top: -350px;
    left: 0px !important;
  }
  .illustation {
    /* width: 95vw; */
    height: 30vh;
    /* margin-top: -150px;  */
  }
  .progress-bar-container {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    position: absolute;
    bottom: 20px;
  }
  .loading-bar {
    width: 95%;
  }
}

@media only screen and (max-width: 320px) {
  .progress-bar-container label {
    display: none;
  }
  .first-text {
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: lighter;
    width: 97vw;
  }
  .paragraph {
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: lighter;
    width: 90vw;
  }
  .bantaba-text {
    font-size: 46px !important;
  }
}
</style>