import currentUser from './auth';
import axios from 'axios';
import i18n from '../../i18n';
import Vue from 'vue';
import APIURLS from '../../constant';
import { auth } from 'firebase';

const state = {
    showModal: false,
    modalContentCategory: '',
    vault: [],
    vaultRequest: [],
    modalMainContent: {},
    responseStatus: '',
    modalContentValue: {},
    pitchdeckRequesteds: [],
    currStartup: null,
    currStartupPitchdeckNotAvail: null,
    startupIDVault: null
}

const getters = {
    getShowModal: (state) => state.showModal,
    getModalContentCategory: (state) => state.modalContentCategory,
    getVault: (state) => state.vault,
    getVaultRequest: (state) => state.vaultRequest,
    getModalMainContent: (state) => state.modalMainContent,
    getModalContentValue: (state) => state.modalContentValue,
    pitchdeckRequested: (state) => state.pitchdeckRequesteds,
    getCurrStartup: (state) => state.currStartup,
    getCurrStartupPitchdeckNotAvail: (state) => state.currStartupPitchdeckNotAvail,
    getStartupIDVault: (state) => state.startupIDVault
}

const mutations = {
    set_show_modal(state, val) {
        state.showModal = val.show,
            state.modalContentCategory = val.category,
            state.modalContentValue = val.value,
            state.modalMainContent = val.content
    },

    set_vault(state, val) {
        state.vault = val;
    },

    set_vault_request(state, val) {
        state.vaultRequest = val
    },

    set_response_status(state, val) {
        state.responseStatus = val
    },

    set_pitchdeck_requested(state, val) {
        state.pitchdeckRequesteds = val
    },

    set_curr_startup(state, val) {
        state.currStartup = val
    },

    set_curr_startup_not_available(state, val) {
        state.currStartupPitchdeckNotAvail = val
    },

    set_vault_by_startup_id(state, val) {
        state.startupIDVault = val
    },
}

const actions = {
    createVault({ commit }, data) {
        return new Promise((resolve, reject) => {
            data.user_id = currentUser.state.user.id;
            data.startup_id = currentUser.state.startup.id;
            axios({ url: APIURLS.VAULT, data: data, method: 'post', })
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_response_status', 201);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    updateVault({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: APIURLS.VAULT, data: data, method: 'patch', })
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_response_status', 201);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    getVault({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(APIURLS.VAULT + currentUser.state.user.id)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_vault', data);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    getVaultByStartupId({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(APIURLS.VAULT + 'pitchdeck/' + currentUser.state.startup.id)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_vault_by_startup_id', data);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    currStartup({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(APIURLS.VAULTREQUEST + "my-vaults-request-checker/" + currentUser.state.user.id + "/" + currentUser.state.startup.id)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_curr_startup', data)
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    currStartupPitchdeckNotAvailable({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(APIURLS.VAULTREQUESTNOTAVAILABLE + "my-pitch-deck-not-available-checker/" + currentUser.state.user.id + "/" + currentUser.state.startup.id)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_curr_startup_not_available', data)
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    requestPitchDeck({ commit }, data) {
        return new Promise((resolve, reject) => {
            data.requester_id = currentUser.state.user.id;
            data.startup_id = currentUser.state.startup.id;
            axios({ url: APIURLS.VAULTREQUEST, data: data, method: 'post' })
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_response_status', 201);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    requestPitchDeckNotAvailable({ commit }, data) {
        return new Promise((resolve, reject) => {
            data.requester_id = currentUser.state.user.id;
            data.startup_id = currentUser.state.startup.id;
            axios({ url: APIURLS.VAULTREQUESTNOTAVAILABLE, data: data, method: 'post' })
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_response_status', 201);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    getVaultRequest({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(APIURLS.VAULTREQUEST + "all-vaults-request/" + currentUser.state.startup.id)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_vault_request', data);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    getPitchdeckRequested({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(APIURLS.VAULTREQUEST + "my-vaults-request/" + currentUser.state.user.id)
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_pitchdeck_requested', data);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    updateRequestVault({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: APIURLS.VAULTREQUEST, data: data, method: 'patch' })
                .then(({ data, status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_response_status', 201);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    deleteVaultRequest({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios({ url: APIURLS.VAULTREQUEST + `${id}`, method: 'delete', })
                .then(({ status }) => {
                    if (status === 200 || status === 201) {
                        commit('set_response_status', 201);
                        resolve(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error.response', error.response);
                        reject(error.response.data.message);
                    } else {
                        reject(error);
                    }
                });
        });
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
};