<template>
  <div>
    <PendingVerification v-if="!authUser.confirmed" />
    <div class="py-4">
      <div class="container-fluid px-md-5 min-layout">
        <div class="row center-on-desktop" :class="{ offsetLayoutMargin: authUser.is_verified === 0 }" v-if="!loading">
          <main class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          ">
            <ConnectionAside />
            <div class="mb-3 w-100 col-md-12" :class="!authUser.confirmed ? 'is_not_confirmed': ''">
              <Connections :key=" getRerender" :users="getDiaspora" />
        </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
  </div>
</template>

<script>
import PendingVerification from "../../components/PendingVerification.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Community | Diaspora",
    };
  },
  async created() {
    this.$store.commit("setDiasporaPage", 0);
    this.$store.commit("setUsers", []);
    this.$store.commit("setShowDiasporaLoadMore", true);
    await this.loadMoreDiaspora({
      data: {
        lang: this.$i18n.locale,
        user_id: this.authUser.id,
        page: this.getDiasporaPage,
        limit: this.getDiasporaLimit,
        filters: this.getDiasporaSearchFilters,
      },
    });

      this.loading = false;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUsers",
      "getDiaspora",
      "getConnectionsCounts",
      "getRerender",
      "getDiasporaPage",
      "getShowDiasporaLoadMore",
      "getDiasporaLimit",
      "getDiasporaSearchFilters",
    ]),
  },
  methods: {
    ...mapActions([
      "loadUsers",
      "loadConnCounts",
      "loadMoreUsers",
      "loadMoreDiaspora",
    ]),
  },

  data() {
    return {
      loading: true,
      users: {},
      counts: {},
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Network",
  components: {
    PendingVerification
  },
};
</script>

<style scoped>
.diaspora {
  position: relative !important;
  z-index: 100 !important;
}
.overlayed {
  background-image: linear-gradient(rgba(255, 255, 255, 0.667), rgb(64, 61, 61));
  width: 100%;
  height: 100vh !important;
  z-index: 10000 !important;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden !important;

}
.non_authorized {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.non_authorized p {
  width: auto;
  border-radius: 8px;
  padding: 40px;
  font-size: 20px !important;
  text-align: center;
  background: white;
}

.is_not_confirmed {
  position: relative !important;
  z-index: 100 !important;
  height: 80vh !important;
  overflow: hidden !important;
}
</style>

<style>
@media (min-width: 1200px) {
  .min-layout {
    /* min-width: min(78vw, 1140px); */
    min-width: 990px;
  }
  main.col.col-xl-12.order-xl-2.col-lg-12.order-lg-1.col-md-12.col-sm-12.col-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-width: 67%; */
    /* max-width: min(78vw, 1140px) !important; */
    max-width: 990px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0rem !important;
  }
  .center-on-desktop {
    display: flex;
    justify-content: center;
  }
}
.offsetLayoutMargin {
  margin-top: 30px;
}
@media (max-width: 600px) {
  .container-fluid {
    min-height: 100vh;
    width: 98vw;
  }
  .pt-4,
  .py-4 {
    padding-top: 0rem !important;
  }
}
</style>
