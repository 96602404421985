<template>
  <div class="team" v-if="getStartupTeam.length || showEditButtons" :class="{ fixedOverflow: showEditButtons }">
    <Header title="team" />
    <div class="team-container">
      <ul class="team-list">
        <li v-for="(member, index) in getStartupTeam" :key="index">
          <TeamMeberCard @emitDelete="deleteMember" @emitEdit="editMember" :data="member" :isEditMode="isEditMode"
            :index="index" />
        </li>
        <template v-if="getStartupEditingMode">
          <add-button v-if="showEditButtons" @click.native="AddMember" class="member-add-btn" />
        </template>
      </ul>
      <div class="empty-skeleton" v-if="getStartupEditingMode && !getStartupTeam.length">
        <p class="call-to-action">
          {{ $t("youHaveNoTeam") }}
        </p>
      </div>
    </div>
    <template v-if="getStartupEditingMode">
      <edit-button class="edit-button" v-if="showEditButtons && !isEditMode" @click.native="toggleEditMode"
        text="edit" />
      <edit-button class="edit-button" v-if="showEditButtons && isEditMode" @click.native="saveEdits" text="cancel"
        :isCancel="true" />
    </template>
    <div v-if="isAddMemember" class="addMemberOverlay">
      <AddTeamMemberModal :isEditMember="isEditMember" :memberToEdit="memberToEdit" @uploadMember="uploadMember"
        @cancelMember="cancelMember" :startup_id="startup_id" />
    </div>
    <!-- <p>{{isEditMode}}</p> -->
  </div>
</template>
<script>
import Header from "./ShareElements/Header.vue";
import TeamMeberCard from "./ShareElements/TeamMeberCard.vue";
import AddTeamMemberModal from "./ShareElements/AddTeamMemberModal.vue";
import EditButton from "../../../components/Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import AddButton from "../../../components/Profile/Diaspora/profile/buttons/AddButton.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Team",
  components: {
    Header,
    TeamMeberCard,
    AddTeamMemberModal,
    EditButton,
    AddButton,
  },
  props: {
    // members: Array,
    startup_id: Number,
    user_id: Number,
  },
  async mounted() {
    await this.loadStartupTeam({ user_id: this.user_id });
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartupTeam",
      "getStartupEditingMode",
      "showEditButtons",
      "getStartupEditingMode",
    ]),
  },

  data() {
    return {
      memberToEdit: {},
      isEditMember: false,
      isEditMode: false,
      isAddMemember: false,
    };
  },
  methods: {
    ...mapActions(["loadStartupTeam"]),
    cancelMember() {
      this.isAddMemember = false;
      this.memberToEdit = {};
    },
    uploadMember() {
      this.isAddMemember = false;
    },
    AddMember() {
      this.isAddMemember = true;
    },
    toggleEditMode() {
      this.isEditMode = true;
    },
    saveEdits() {
      this.isEditMode = false;
      this.isAddMemember = false;
    },
    editMember(member) {
      this.isAddMemember = true;
      this.isEditMember = true;
      this.memberToEdit = member;
    },
    deleteMember(member_id, index) {
      // this.teamMembers.splice(index, 1);
      this.$store
        .dispatch("deleteTeamMember", { member_id })
        .then(async (success) => {
          await this.loadStartupTeam({ user_id: this.user_id });
          this.$snotify.success(this.$i18n.t("success"), "Success");
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

ul li {
  list-style: none !important;
}

.team {
  width: 100%;
  background-color: white;
  margin-top: 16px;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow-x: hidden;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 8%), 0px 2px 4px rgb(44 43 42 / 10%);
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fixedOverflow {
  overflow: inherit;
}

.team-container {
  /* background-color: green; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
  /* ; */
}

.team-container ul {
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  grid-row-gap: 4%;
  /* padding-bottom: 120px; */

  /* overflow: hidden;
    overflow-y: auto; */
}

.team-container ul li {
  width: 100%;
}

.cancel-button {
  background-color: #000;
}

.edit-button {
  position: absolute !important;
  right: 20px;
  /* top: 20px; */
  top: 5px;
  padding: 5px 20px;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  z-index: 99;
}

.btn-wrapper {
  position: relative;
  background: white;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-wrapper .btn-edit {
  width: 25px;
  height: 25px;
  padding: 5px;
  line-height: 0;
  border: 1px solid hsla(0, 0%, 43.9%, 0.002);
  border-radius: 15px;
  background-color: hsla(0, 0%, 54.9%, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-wrapper .btn-edit {
  position: absolute;
  left: 2px;
}

.member-add-btn {
  width: auto !important;
  height: auto !important;
  background: none !important;
  position: absolute;
  right: 150px;
  top: 5px;
  z-index: 99;
}

.call-to-action {
  font-size: 19px;
  padding: 10px;
  color: #0000004d;
}

.empty {
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: calc(50% - 22.5px);
}

.empty-skeleton {
  padding: 20px 10px;
  width: 100%;
  text-align: center;
}

.addMemberOverlay {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #eeeeee90;
  padding: 0px;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .team {
    width: 100vw;
  }

  .team-container ul {
    padding: 10px;
    width: 100%;
    grid-template-columns: 100%;
    grid-column-gap: 10%;
    grid-row-gap: 1%;
    /* text-align: center; */
    place-content: center;
  }

  .team-container ul li {
    margin-bottom: 10px;
  }

  .edit-button {
    right: 20px;
    top: 8px;
  }

  .member-add-btn {
    top: 5px;
  }

  .addMemberOverlay {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100vh;
    background-color: #eeeeee90;
    padding: 0px;
    border-radius: 20px;
    z-index: 999999999999;
  }
}
</style>