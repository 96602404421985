<template>
  <div class="col-12 border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t("Educations") }}</h6>
    </div>
    <div class="box-body p-3">
      <div class="border rounded bg-white mb-3">
        <form @submit.prevent="submit" v-if="!showedu">
          <div class="box-body px-3 pt-3 pb-0">
            <div class="row">
              <div class="col-sm-6 mb-4">
                <label id="positionLabel" class="form-label"
                  >{{ $t("fieldOfStudy") }} <span class="text-danger">*</span>
                </label>

                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('title')"
                    v-model="form.title"
                    required
                  />
                </div>
              </div>
              <div class="col-sm-6 mb-4">
                <label id="companyLabel" class="form-label"
                  >{{ $t("university") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('university')"
                    v-model="form.university"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <label id="companyLabel" class="form-label"
                  >{{ $t("level") }}
                  <span class="text-danger">*</span>
                </label>

                <div class="input-group">
                  <select
                    class="custom-select my-1 mr-sm-2"
                    v-model="form.level"
                    required
                  >
                    <option value="" disabled>{{ $t("choose") }}...</option>
                    <option value="Bachelor">{{ $t("Bachelor") }}</option>
                    <option value="Master">Master</option>
                    <option value="Licence">Licence</option>
                    <option value="Doctorat">{{ $t("Doctorat") }}</option>
                    <option value="Post-Doc">{{ $t("Post-Doc") }}</option>
                    <option value="Other">{{ $t("Other") }}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 mb-4">
                <label id="positionLabel" class="form-label"
                  >{{ $t("currently") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <select
                    class="custom-select my-1 mr-sm-2"
                    v-model="form.currently"
                    required
                    @change="setEndDate"
                  >
                    <option value="" disabled>{{ $t("choose") }}...</option>
                    <option value="1">{{ $t("yes") }}</option>
                    <option value="0">{{ $t("no") }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <label id="FROM" class="form-label"
                  >{{ $t("country") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <country-select
                    v-model="form.country"
                    :required="true"
                    :countryName="true"
                    :autocomplete="true"
                    :disablePlaceholder="true"
                    :usei18n="true"
                    :country="fortmatCountry(form.country)"
                    class="input-text custom-select"
                    :placeholder="$t('choose')"
                  />
                </div>
              </div>
              <div class="col-sm-6 mb-4">
                <label class="form-label"
                  >{{ $t("city") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('city')"
                    v-model="form.city"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <label id="FROM" class="form-label"
                  >{{ $t("from") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <Datepicker
                    v-if="$i18n.locale === 'fr'"
                    v-model="form.startDate"
                    :language="fr"
                    :clear-button="false"
                    input-class="form-control"
                    :required="true"
                    :typeable="true"
                    minimum-view="month"
                    :format="customFormatter"
                    :placeholder="$t('from')"
                  />
                  <Datepicker
                    v-else
                    v-model="form.startDate"
                    :language="en"
                    input-class="form-control"
                    :clear-button="false"
                    :required="true"
                    :typeable="true"
                    minimum-view="month"
                    :format="customFormatter"
                    :placeholder="$t('from')"
                  />
                </div>
              </div>
              <div class="col-sm-6 mb-4" v-if="!selectedNow">
                <label id="TO" class="form-label"
                  >{{ $t("to") }} (or expected)</label
                >
                <div class="input-group">
                  <Datepicker
                    v-if="$i18n.locale === 'fr'"
                    v-model="form.endDate"
                    :language="fr"
                    :clear-button="false"
                    input-class="form-control"
                    :required="true"
                    :typeable="true"
                    minimum-view="month"
                    :format="customFormatter"
                    :placeholder="$t('to')"
                  />
                  <Datepicker
                    v-else
                    v-model="form.endDate"
                    :language="en"
                    input-class="form-control"
                    :clear-button="false"
                    :required="true"
                    :typeable="true"
                    minimum-view="month"
                    :format="customFormatter"
                    :placeholder="$t('to')"
                  />
                </div>
              </div>
              <div class="col-sm-6 mb-4" v-else>
                <label id="TO" class="form-label"
                  >{{ $t("to") }} (or expected)</label
                >
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.endDate"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mb-4">
                <div class="form-group mb-4">
                  <label class="mb-1">Description </label>
                  <div class="position-relative">
                    <textarea
                      class="form-control"
                      rows="4"
                      name="description"
                      v-model="form.description"
                      spellcheck="true"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <button
              :disabled="loading"
              type="submit"
              class="btn btn-success ml-3"
              v-if="save"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
              {{ loading ? "" : $t("save") }}
            </button>

            <button
              v-else
              :disabled="loading"
              type="submit"
              class="btn btn-success ml-3"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
              {{ loading ? "" : $t("create") }}
            </button>

            <button
              type="submit"
              @click="hideEducations"
              class="btn btn-danger ml-3"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
        <div v-else>
          <div class="box-body p-3 border-bottom" v-if="edus.length">
            <div v-for="education in edus" :key="education.id" class="#">
              <div class="box-body p-0">
                <div
                  class="
                    p-3
                    d-flex
                    align-items-center
                    border-bottom
                    osahan-post-header
                  "
                >
                  <div class="font-weight-bold mr-3">
                    <div class="text-truncate">
                      <h6 class="font-weight-bold text-dark mb-0">
                        {{ education.title }}
                        <span
                          class="badge badge-pill badge-success"
                          v-if="education.level"
                        >
                          {{ $t(education.level) }}
                        </span>
                      </h6>
                    </div>
                    <div class="small">
                      <div class="text-truncate text-primary">
                        {{ education.university }} - {{ education.city }},
                        {{ fortmatCountry(education.country) }}
                        <!-- {{ education.country }} -->
                      </div>
                      <div
                        class="small text-gray-500"
                        v-if="$i18n.locale === 'fr'"
                      >
                        {{ $d(new Date(education.startDate), "short", "fr") }}
                        -
                        <span v-if="education.endDate === 'now'">
                          {{ $t(education.endDate) }}
                        </span>
                        <span v-else>
                          {{ $d(new Date(education.endDate), "short", "fr") }}
                        </span>
                      </div>
                      <div class="small text-gray-500" v-else>
                        {{
                          $d(new Date(education.startDate), "short", "en-US")
                        }}
                        -
                        <span v-if="education.endDate === 'now'">
                          {{ $t(education.endDate) }}
                        </span>
                        <span v-else>
                          {{
                            $d(new Date(education.endDate), "short", "en-US")
                          }}
                        </span>
                      </div>
                      {{ education.description }}
                    </div>
                  </div>
                  <span class="ml-auto mb-auto">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm rounded m-1"
                        @click="editEdu(education)"
                      >
                        <i class="feather-edit"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm rounded m-1"
                        @click="deleteEdu(education.id)"
                      >
                        <i class="feather-trash"></i>
                      </button>
                    </div>
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            @click="showEducations"
            class="btn btn-primary mt-2"
          >
            {{ $t("addEdu") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../i18n";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";

export default {
  mounted() {
    this.edus = this.educations;
  },
  computed: {},
  data() {
    return {
      en: en,
      fr: fr,
      form: {
        level: "",
        currently: "",
      },
      edus: {},
      loading: false,
      showedu: true,
      selectedNow: false,
      save: false,
    };
  },
  methods: {
    ...mapActions(["loadEducation"]),
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      // country = countries.getName(country, this.$i18n.locale, {
      //   select: 'official',
      // });
      country = countries.getName(country, "en", { select: "official" });
      return country;
    },
    showEducations() {
      this.showedu = false;
    },
    hideEducations() {
      this.showedu = true;
      this.form = {};
      this.save = false;
    },
    editEdu(experience) {
      this.loading = false;
      this.showedu = false;
      this.save = true;
      this.form = experience;
      if (this.form.endDate === "now") {
        this.selectedNow = true;
        this.form.currently = 1;
      }
    },
    submit() {
      if (!this.save) {
        this.createEducation();
      } else {
        this.updateEducation();
      }
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    setEndDate() {
      if (this.form.currently === "1") {
        this.selectedNow = true;
        this.form.endDate = "now";
      } else {
        if (!this.form.endDate) {
          this.form.endDate = "";
        }
        this.selectedNow = false;
      }
    },
    createEducation() {
      this.loading = true;
      this.form.country = this.countryCode(this.form.country);
      this.$store
        .dispatch("createEducation", {
          education: this.form,
        })
        .then(async (success) => {
          this.showedu = true;
          this.form = {};
          await this.loadEducation();
          this.loading = false;
          this.$noty.success(this.$i18n.t("educationAdded"));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
    updateEducation() {
      this.loading = true;
      this.form.country = this.countryCode(this.form.country);
      this.$store
        .dispatch("updateEducation", {
          education: this.form,
        })
        .then(async (success) => {
          this.showedu = true;
          this.save = false;
          this.form = {};
          await this.loadEducation();
          this.loading = false;
          this.$noty.success(this.$i18n.t("educationUpdated"));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
    deleteEdu(edu_id) {
      this.loading = true;
      this.$store
        .dispatch("deleteEducation", {
          edu_id,
        })
        .then(async (success) => {
          await this.loadEducation();
          this.loading = false;
          this.$noty.success(this.$i18n.t("educationDeleted"));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
  },
  name: "AddEducations",
  props: {
    educations: {},
  },
  components: {
    Datepicker,
  },
};
</script>

<style></style>
