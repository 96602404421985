<template>
  <div class="career-title">
    <slot></slot>
    <add-button @add="add()" />
  </div>
</template>

<script>
import AddButton from "../buttons/AddButton";

export default {
  components: {
    AddButton,
  },
  methods: {
    add: function () {
      this.$emit("add");
    },
  },
};
</script>

<style lang="scss" scoped>
.career-title {
  display: flex;
  justify-content: space-between;
  background-color: rgba(232, 232, 232, 0.38);
  padding: 20px 30px 10px;
  border-radius: 15px 15px 0 0;
  font-size: 20px;

  @include max-width(415) {
    padding: 20px 15px 10px;
    font-size: 18px;
    justify-content: center;
    width: 100%;
    transform: translate(0px);
    background-color: #00000015;
    border-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}
</style>
