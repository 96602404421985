<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0 text-center">Educationxx</h6>
    </div>

    <div v-if="educations.length">
      <div
        class="box-body p-3 border-bottom"
        v-for="(education, index) in educations"
        :key="index"
      >
        <div class="d-flex align-items-top job-item-header pb-2">
          <div class="mr-2">
            <h6 class="font-weight-bold text-dark mb-0">
              {{ education.title }}
              <span
                class="badge badge-pill badge-secondary"
                v-if="education.level"
                >{{ $t(education.level) }}
              </span>
            </h6>
            <div class="text-truncate text-primary">
              {{ education.university }} -
              <span class="text-success">
                <i class="feather-map-pin"></i>
                {{ fortmatCountry(education.country) }}
              </span>
            </div>
            <div class="small text-gray-500" v-if="$i18n.locale === 'fr'">
              {{ $d(new Date(education.startDate), 'short', 'fr') }}
              -
              <span v-if="education.endDate === 'now'">
                {{ $t(education.endDate) }}
              </span>
              <span v-else>
                {{ $d(new Date(education.endDate), 'short', 'fr') }}
              </span>
            </div>
            <div class="small text-gray-500" v-else>
              {{ $d(new Date(education.startDate), 'short', 'en-US') }}
              -
              <span v-if="education.endDate === 'now'">
                {{ $t(education.endDate) }}
              </span>
              <span v-else>
                {{ $d(new Date(education.endDate), 'short', 'en-US') }}
              </span>
            </div>
          </div>
          <!-- <img
          class="img-fluid ml-auto mb-auto"
          src="https://res.cloudinary.com/yanfomaweb/image/upload/v1611151907/Bantaba/educat.png"
          alt="Education"
        /> -->
        </div>
        <p class="mb-0 text-justify">
          {{ education.description }}
        </p>
      </div>
    </div>
    <NoData
      class="p-4"
      v-else
      text="noEducation"
      buttonText="addEdu"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.cursus')}`"
    />
  </div>
</template>

<script>
import i18n from '../../i18n';
export default {
  methods: {
    fortmatCountry(country) {
      var countries = require('i18n-iso-countries');
      countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
      return (country = countries.getName(country, i18n.locale, {
        select: 'official',
      }));
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Education',
  props: {
    educations: {},
  },
};
</script>

<style></style>
