<template>
  <div class="container">
    <div class="card">
      <div class="card-panel one">
        <div class="card-content">
          <img :src="svg" alt="illustration" class="img" />
          <h2 class="title">{{ title }}</h2>
          <p class="text" v-if="hasText">
            {{ text }}
          </p>
          <a v-if="hasButton" :href="buttonLink" class="btn-link">{{
            buttonText
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDataFound",
  props: {
    size: Number,
    type: String,
    title: String,
    text: String,
    buttonText: String,
    buttonLink: String,
    hasButton: Boolean,
    hasTitle: Boolean,
    hasText: Boolean,
    svg: String,
  },
};
</script>

<style scoped>
.card {
  z-index: 15;
  position: relative;
  background: #ffffff;
  width: 100% !important;
  border-radius: 20px;
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  overflow: hidden;
  align-items: center !important;
  margin: 0px !important;
}

.card-panel {
  padding: 20px calc(5% + 20px) 20px 20px;
  box-sizing: border-box;
}
.card-panel.one:before {
  content: "";
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.card-content .img {
  width: 200px !important;
  margin: 20px !important;
}

.title {
  color: #131313 !important;
  font-weight: bold;
  font-size: 1.5rem !important;
  text-align: center !important;
}
.text {
  color: #131313 !important;
  font-weight: 300;
  font-size: 1.3rem !important;
  text-align: center !important;
}
.btn-link {
  display: inline-block !important;
  width: auto !important;
  padding: 0.4rem 0.9rem !important;
  margin: 20px !important;
  align-items: center !important;
  color: var(--first-color);
  border: 1px solid var(--first-color) !important;
  border-radius: 0.7rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
}
.btn-link:hover {
  background: var(--first-color);
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 90% !important;
  }

  .title {
    font-size: 1.1rem !important;
    margin-bottom: 7px;
  }
  .text {
    font-size: 1rem !important;
  }
}
</style>