<template>
  <div class="section">
    <label
      >{{ $t(title) }}
      <span class="error_msg" v-if="err_msg"> {{ $t(err_msg) }} </span>
    </label>
    <div class="section-list">
      <SelectBtn2
        v-for="(option, index) in options"
        :key="index"
        :text="option.name"
        :icon="option.icon"
        @click.native="setSupportType(option.name)"
        :active="selectedTypes.includes(option.name)"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import SelectBtn2 from "./SelectBtn2.vue";
export default {
  mounted() {
    let selectedTypes = JSON.parse(localStorage.getItem("supportType"));
    if (selectedTypes) {
      this.$store.commit("setFormData", {
        field: "supportType",
        value: selectedTypes,
      });
      this.selectedTypes = selectedTypes;
    }
  },
  computed: {
    ...mapGetters([
      "getFomData",
      "getAccountType",
      "getFordataField",
      "getShowTypes",
    ]),
  },
  data: () => {
    return {
      error: false,
      selectedTypes: [],
    };
  },
  methods: {
    ...mapMutations(["setAccountType"]),
    setSupportType(type) {
     
      // this.err_msg.supportType = "";
      let error = false;
      if (this.selectedTypes.includes(type)) {
        this.selectedTypes = this.selectedTypes.filter((item) => {
          return item !== type;
        });
      } else {
        this.selectedTypes.push(type);
      }
      this.$store.commit("setFormData", {
        field: "supportType",
        value: this.selectedTypes,
      });
      localStorage.setItem("supportType", JSON.stringify(this.selectedTypes));
    },
  },
  name: "EngagementTypes",
  props: {
    title: String,
    err_msg: String,
    options: [],
  },
  components: {
    SelectBtn2,
  },
};
</script>
<style scoped>
.error_msg {
  /* color: var(--error-color) !important; */
  /* background-color: #fe4c5f42; */
  color: var(--error-color);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 15px;
}
.section {
  width: 100%;
}
.section label {
  font-size: 22px;
}
.section-list {
  display: grid;
  grid-template-columns: 32.5% 32.5% 32.5%;
  grid-gap: 10px;
  row-gap: 10px;
}

.section-list li {
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.section-list li:hover {
  border: 1px solid #349e4d;
  color: #349e4d;
}

@media only screen and (max-width: 600px) {
  .section {
    width: 100%;
    text-align: left;
  }
  .section-list {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 10px;
    row-gap: 10px;

    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-gap: 5px;
    row-gap: 10px;
  }
  .section label {
    font-size: 18px;
    /* color: #717171; */
  }
}
@media only screen and (max-width: 320px) {
  .section label {
    font-size: 15px;
  }
}
</style>