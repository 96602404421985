<template>
  <div class="form">
    <div class="form-panel">
      <div class="alert alert-danger text-center" role="alert" v-if="error">
        {{ errorMsg }}
      </div>
      <div class="form-header">
        <h1>{{ $t("selectYourLanguage") }}</h1>
      </div>
      <div class="form-content">
        <div class="box">
          <select @change="onChange($event)">
            <option
              v-for="(lang, i) in $i18n.availableLocales"
              :key="`Lang${i}`"
              :value="lang"
              :selected="getUserLang === lang ? true : false"
            >
              {{ getLang(lang) }}
            </option>
          </select>
        </div>
        <!-- <div class="languages-list">
          <LanguageSelect
            v-for="(lang, i) in $i18n.availableLocales"
            :key="`Lang${i}`"
            :name="getLang(lang)"
            :code="lang"
            :imageUrl="require('@/assets/images/flag-' + lang + '.png')"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import LanguageSelect from "../../components/LanguageSelect.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  async created() {
    //this.authUser;
  },

  async mounted() {
    this.form = {};
    this.userLang = this.locale;
  },
  computed: {
    ...mapGetters(["authUser", "getProfile", "getUserLang"]),
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMsg: "",
      userLang: "",
    };
  },
  methods: {
    ...mapActions(["changeUserPreferedLang"]),
    async onChange(event) {
      var originalName = this.$route.name;
      // alert(this.$route.name);
      // console.log("this.$route", this.$route);
      var name = originalName.substring(0, originalName.length - 2);
      let lang = event.target.value;
      if (lang === "En") {
        name += "Fr";
      } else {
        name += "En";
      }
      this.$store.commit("setUserLang", lang);
      localStorage.setItem("userLang", lang);
      localStorage.setItem("preferedLanguage", lang);
      await this.changeUserPreferedLang(lang);
      this.$i18n.locale = lang;
      // this.$router.push({ name: name, params: { lang } }).catch(err => {});
      let url = originalName;
      if (this.$route.path != url) {
        this.$router.push({ name: name, params: { lang } });
      }
    },
    getLang(lang) {
      var value = "navBar." + lang;
      return this.$i18n.t(value);
    },
  },
  name: "ChangePass",
  components: {
    // LanguageSelect,
  },
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box select {
  background-color: #808080;
  color: white;
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}
</style>

<style scoped>
.setting-content {
  margin-bottom: 60px !important;
}

.overlay,
.form-panel.one:before {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.form {
  z-index: 15;
  position: relative;
  background: #ffffff;
  width: 600px;
  border-radius: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 100%;
  min-height: 300px;
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 0px;
}
.form-group:last-child {
  margin: 0;
}
.form-group label {
  display: block;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.disabled {
  background: #7d7e7dd7 !important;
}

.form-group input {
  outline: none;
  display: block;
  background: #cfead98f;
  width: 100%;
  border: 1px solid var(--first-color) !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  padding: 1px 20px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  transition: 1s ease;
}
input:focus {
  color: rgba(0, 0, 0, 0.8) !important;
  outline: none !important;
  border: 1px solid var(--first-color) !important;
  box-shadow: none !important;
}

.form-group button {
  outline: none;
  background: var(--first-color);
  width: 100%;
  border: 0;
  border-radius: 20px;
  padding: 12px 20px;
  color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.form-panel {
  padding: 20px calc(5% + 20px) 20px 20px;
  box-sizing: border-box;
}
.form-panel.one:before {
  content: "";
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.form-header {
  margin: 0 0 40px;
}
.form-header h1 {
  padding: 4px 0;
  color: var(--first-color) !important;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .form-header h1 {
    font-size: 15px;
  }
  .form-panel {
    padding: 30px calc(5% + 20px) 20px 20px;
  }

  .form {
    width: 500px;
    height: 100%;
    min-height: 300px;
  }

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box select {
    background-color: #808080;
    color: white;
    padding: 8px;
    width: 250px;
    height: 40px;
    font-size: 15px;
  }

  .box::before {
    font-size: 19px;
  }
  .box:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
