<template>
  <NavBar :notifications="notifications" :newNotifs="newNotifs" />
  
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import MessagesNotifications from "./MessagesNotifications.vue";
import { mapGetters, mapActions } from "vuex";
import NavBar from "./NavBar.vue";
export default {
  watch: {
    async $route(to, from) {
      this.getNotifs();
    },
  },
  async mounted() {
    this.getNotifs();
  },
  computed: {
    ...mapGetters(["authUser", "getUserNotifications"]),
    path() {
      return this.$route.path;
    },
  },
  data() {
    return {
      notifications: {},
      newNotifs: [],
      form: {},
      isShown: false,
    };
  },
  sockets: {
    async private_chat(data) {
      await this.getNotifs();
    },
  },
  methods: {
    ...mapActions([
      "loadUserNotifications",
      "setViewedNotif",
      "destroyChatBox",
      "getUserById",
      "openChatBoxWithUser",
      "setAllNotifsToViewed",
    ]),
    async markReadNotifs() {
      let user_id = this.authUser.id;
      this.newNotifs = [];
      await this.setAllNotifsToViewed({ user_id });
    },
    async getRoute(notification) {
      let url = "";
      let user_id = this.authUser.id;
      const notif_id = notification.id;
      if (notification.type === "request") {
        url =
          "/" +
          this.$i18n.locale +
          "/" +
          this.$i18n.t("routes.network") +
          "/" +
          this.$i18n.t("routes.pendingRequests");
      } else if (notification.type === "accept") {
        url =
          "/" +
          this.$i18n.locale +
          "/" +
          this.$i18n.t("routes.network") +
          "/connections";
      } else if (notification.type === "follow") {
        url = "/" + this.$i18n.locale + "/" + this.$i18n.t("routes.followers");
      } else if (notification.type === "message") {
        let user_id = notification.sender_id;
        let user = await this.getUserById({ user_id });
        await this.openChatBoxWithUser(user);
      } else {
        url = "/" + this.$i18n.locale + "/notifications";
      }
      await this.setViewedNotif({ user_id, notif_id });
      // this.$router.push(url).catch(err => {});
      if (this.$route.path != url) {
          this.$router.push(url);
      }

    },
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.newNotifs = this.getUserNotifications;
      type = "all";
      await this.loadUserNotifications({ user_id, type });
      //seperate message notifs.

      this.notifications = this.getUserNotifications.filter((notif) => {
        return notif.type != "message";
      });
    },
    activeMenu(name) {
      let urlName = this.$route.name;
      if (urlName === name) return "active ";
    },
    async signOut() {
      await this.destroyChatBox();
      this.$store.dispatch("logOut").then((response) => {
        if (response) {
          this.$router.go(0);
          // this.$router.push("/").catch(err => {});
          if (this.$route.path != "/") {
            this.$router.push("/");
          }

        }
      });
    },
    showDialog: function () {
      this.isShown = !this.isShown;
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "navigation",
  components: {
    // MessagesNotifications,
    NavBar,
  },
};
</script>
<style scoped>
.dropdownHeader {
  background: rgb(175, 241, 175) !important;
}
.dropdown-item:hover {
  color: #0f7f12 !important;
}
.avatar {
  border: 1px solid white !important;
}
.nav-link.active {
  font-weight: bold;
  color: #fccc2f !important;
}
.nav-link:hover {
  color: #fccc2f !important;
}
button {
  border: none !important;
  background: none;
  border-radius: 50% !important;
}
button:focus {
  outline: 0;
}
.shown {
  top: 0px !important;
  transition-duration: 167ms;
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.close-btn {
  display: none;
}
.profile-dropdown {
  right: 0;
  left: auto;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.shown {
  top: 50px !important;
  transition-duration: 167ms;
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .shown {
    top: -310px !important;
    transition-duration: 167ms;
    animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .close-btn {
    display: block;
    width: 45px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0.269) !important;
    position: absolute;
    right: 20px;
    font-size: 25px;
    color: white;
  }
  .profile-dropdown {
    width: 80vw !important;
    height: 300px;
    right: 10px;
    display: flex;
    border-radius: 50px;
    flex-direction: column;
    overflow: hidden;
  }
  .notifications {
    position: absolute;
    top: -85vh;
    z-index: 1000;
  }
}
</style>

<style>
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
</style>
