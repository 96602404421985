
import Jobs from '../views/jobs/All.vue';
import News from '../views/news/All.vue';
import SingleJob from '../views/jobs/Single.vue';
import SingleNews from '../views/news/Single.vue';
import { authGuard } from './guard';
import i18n from "../i18n";

var userLang = navigator.language || navigator.userLanguage;

var defaultLang = userLang.substring(0, 2);

i18n.locale = defaultLang;

export default [

  {
    path: "news",
    name: "NewsEn",
    component: News,
    beforeEnter: authGuard
  },
  {
    path: "nouvelles",
    name: "NewsFr",
    component: News,
    beforeEnter: authGuard
  },
  {
    path: "news/:slug",
    name: "SingleNewsEn",
    component: SingleNews,
    beforeEnter: authGuard
  },
  {
    path: "nouvelles/:slug",
    name: "SingleNewsFr",
    component: SingleNews,
    beforeEnter: authGuard
  },
  {
    path: "jobs",
    name: "JobsEn",
    component: Jobs,
    beforeEnter: authGuard
  },
  {
    path: "offres-d'emplois",
    name: "JobsFr",
    component: Jobs,
    beforeEnter: authGuard
  },
  {
    path: "job/:slug",
    name: "SingleJobEn",
    component: SingleJob,
    beforeEnter: authGuard
  },
  {
    path: "offre/:slug",
    name: "SingleJobFr",
    component: SingleJob,
    beforeEnter: authGuard
  },
];
