<template>
  <div>
    <div class="box shadow-sm border rounded bg-white mb-3">
      <div class="widget widget-user-about">
        <div class="widget-heading">
          <h3 class="widget-title">Basic Info</h3>
        </div>
        <div class="user-info">
          <ul class="info-list">
            <li>
              <span>{{ $t('headline') }}</span
              >{{ headline ? headline : '-' }}
            </li>
            <li><span>Position</span>{{ position ? position : '-' }}</li>
            <li>
              <span>{{ $t('company') }}</span
              >{{ organization ? organization : '-' }}
            </li>
            <li>
              <span>{{ $t('livesIn') }}</span
              >{{ country ? country : '-' }}
            </li>
            <li>
              <span>{{ $t('city') }}</span
              >{{ city ? city : '-' }}
            </li>
            <li>
              <span>{{ $t('origin') }}</span
              >{{ origin ? origin : '-' }}
            </li>
            <li>
              <span>{{ $t('joined') }}</span
              >{{ created_at ? created_at : '-' }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIURLS from '../../constant';
import i18n from '../../i18n';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
export default {
  mounted() {
    this.routeName = this.$route.name;
  },
  data() {
    return {
      routeName: '',
      status: '',
    };
  },
  computed: {
    ...mapGetters(['authUser']),
    ...mapActions(['checkStatus']),
  },
  methods: {},
  name: 'ProfileDetails',
  props: {
    headline: {
      type: String,
      default: '',
    },
    organization: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    created_at: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    },
    city: {
      type: String,
      default: '',
    },

    origin: {
      type: String,
      default: '',
    },
    user_id: {
      type: Number,
    },
  },
};
</script>

<style></style>
