<template>
  <transition name="modal">
    <div v-if="showModal" class="modal-mask referral-modal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div>
              <h2>{{ $t("shareWithAFriend") }}</h2>
            </div>
            <button class="modal-default-button" @click="close">
              <svg height="22.654" viewBox="0 0 21.654 22.654" width="21.654" xmlns="http://www.w3.org/2000/svg">
                <g id="Group_2" data-name="Group 2" transform="translate(-1237.673 -235.673)">
                  <line id="Line_1" data-name="Line 1" fill="none" stroke="#707070" stroke-linecap="round"
                    stroke-width="4" transform="translate(1240.5 238.5)" x2="16" y2="17" />
                  <line id="Line_2" data-name="Line 2" fill="none" stroke="#707070" stroke-linecap="round"
                    stroke-width="4" transform="translate(1240.5 238.5)" x1="16" y2="17" />
                </g>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center text-bold">
              {{ $t("shareWithAFriendText") }}
            </p>
            <div class="icons-container">
              <a :href="twitterShareLink" class="twitter" target="_blank"></a>
              <a :href="linkedinShareLink" class="linkedin" target="_blank"></a>
              <a :href="fbShareLink" class="fb" target="_blank"></a>
              <a :href="whatsappSharedLink" class="whatsapp" target="_blank"></a>
              <a :href="instagramSharedLink" class="instagram" target="_blank"></a>
            </div>
            <div class="row col-md-12">
              <span :class="copied ? 'showCopy':''" class="copiedTooltip">{{
              $t("copied") }} !</span>
            </div>
            <div class="col-md-12">
              <div class="copy-link-container">
                <p>
                  {{ $t("copySharableLink") }}
                </p>

                <div>
                  <span ref="url" class="url">{{ copyUrl }}</span>
                  <button class="copy-btn" @click="copyLink"></button>
                </div>
              </div>
            </div>
            <div class="button-container">
              <button class="close-button" @click="close">
                {{ $t("close") }}
              </button>
              <button class="never-show" @click="closeAndNeverShow">
                {{ $t("neverShowAgain") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Cookie from "js-cookie";
import i18n from "../../i18n";
import { mapGetters, mapActions, mapMutations } from "vuex";

// this.$i18n.locale = lang;
const link = "https://bantaba.com";
const user_id = 16565;

export default {
  name: "ReferralModal",
  data: function () {
    return {
      showModal: false,
      cookieShow: null,
      copied:false,
      cookieShowModalOnce: null,
      text: this.$i18n.t("sharedText"),
      copyUrl: this.utm_build(link, "referral", "referral", 0, user_id),
    };
  },
  props: {
    timeOutShow: {
      default: 45000,
    },
  },
  created() {
    //this.text = i18n.t("sharedText");
  },
  beforeMount() {
    this.cookieShow = Cookie.get("newer-show") || false;
    this.cookieShowModalOnce = localStorage.getItem("show-once") || false;
  },
  mounted() {
    // if (!this.cookieShow)
    if (!this.cookieShow && !this.cookieShowModalOnce)
      setTimeout(() => {
        this.showModal = true;
        localStorage.setItem("show-once", true);
      }, this.timeOutShow);
  },
  computed: {
    ...mapGetters(["authUser", "getUserLang"]),
    fbShareLink() {
      return this.generateLink(
        "https://www.facebook.com/sharer/sharer.php",
        ["u", this.copyUrl],
        ["quote", this.text]
      );
    },
    twitterShareLink() {
      return this.generateLink(
        "https://twitter.com/intent/tweet",
        ["url", this.copyUrl],
        ["text", this.text]
      );
    },
    linkedinShareLink() {
      return this.generateLink(
        "https://www.linkedin.com/sharing/share-offsite",
        ["url", this.copyUrl],
        ["title", this.text]
      );
    },
    whatsappSharedLink() {
      return this.generateLink("https://wa.me/", [
        "text",
        this.text + " " + this.copyUrl,
      ]);
    },
    instagramSharedLink() {
      return "https://www.instagram.com/ourbantaba/";
    },
  },
  methods: {
    ...mapActions(["referralShared"]),
    async saveReferralClick(channel, link) {
      let referrer_type = this.authUser.type;
      let referrer_id = this.authUser.id;
      let referral_link = link;
      await this.referralShared(referrer_id, referrer_type, referral_link);
    },
    generateLink(link = "", ...params) {
      const url = new URL(link);
      for (const [key, value] of params) {
        url.searchParams.append(key, value);
      }
      return url.toString();
    },
    utm_build(
      link,
      source = "referral",
      medium = "referral",
      campaign = "0",
      id = 2
    ) {
      return link.concat(
        "?utm_source=",
        source,
        "&utm_medium=",
        medium,
        "&utm_campaign=",
        campaign,
        "&utm_id=",
        id
      );
    },
    copyLink() {
      let url = this.$refs.url.innerText;
      navigator.clipboard.writeText(url);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 5000);

     
    },
    close() {
      this.showModal = false;
    },
    closeAndNeverShow() {
      Cookie.set("newer-show", true);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>

.showCopy{
  opacity: 1 !important;
}
.copiedTooltip{
  opacity: 0;
  background-color:black;
  width:auto;
  height:auto;
  position:relative;
  float:right;
  left:80%;
  top:30px;
  padding:5px 10px;
  border-radius:10px;
  color:white;
  font-size: 14px;
}
.referral-modal {
  .url {
    color: black;
    font-weight: 500 !important;
  }

  .modal {
    &-wrapper {
      position: fixed;
      width: 95%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 100px;
      z-index: 9000 !important;
      padding-left: 25px;
      padding-right: 25px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: initial;
      height: auto !important;
      border: none;
      padding: 0px !important;
      border-radius:25px !important;
      position: absolute;
      bottom: 22%;
    }

    &-header {
      border: none;
      position: relative;
      padding-bottom: 24px !important;

      h2 {
        font: normal normal bold 12px/15px Helvetica Neue;
        font-size: 18px;
      }

      div {
        margin: 0 auto;
      }
    }

    &-default-button {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 10px;
      right: 10px;

      svg {
        width: 7px;
        height: 7px;
        vertical-align: baseline;
      }

      &:hover {
        background: #acabab;

        line {
          stroke: white;
        }
      }

      &:focus,
      &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    &-body {
      padding: 0 17px 18px !important;

      > p {
        font: normal normal normal 14px/15px Helvetica Neue;
      }
    }
  }

  .modal-body > p {
    text-align: center;
    font-weight: 450 !important;
    line-height: 1.4;
  }

  .icons-container {
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    column-gap: 12px;

    a {
      display: block;
      width: 38px;
      height: 38px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 50%;
      transition: none;

      &.twitter {
        background-image: url("../../assets/svg/awesome-twitter.svg");
        &:hover {
          background-image: url("../../assets/svg/awesome-twitter-2.svg");
        }
      }

      &.linkedin {
        background-image: url("../../assets/svg/awesome-linkedin-in.svg");

        &:hover {
          background-image: url("../../assets/svg/awesome-linkedin-in-2.svg");
        }
      }

      &.fb {
        background-image: url("../../assets/svg/awesome-facebook.svg");

        &:hover {
          background-image: url("../../assets/svg/awesome-facebook-2.svg");
        }
      }

      &.whatsapp {
        background-image: url("../../assets/svg/ionic-logo-whatsapp.svg");

        &:hover {
          background-image: url("../../assets/svg/ionic-logo-whatsapp-2.svg");
        }
      }

      &.instagram {
        background-image: url("../../assets/svg/awesome-instagram.svg");

        &:hover {
          background-image: url("../../assets/svg/awesome-instagram-2.svg");
        }
      }
    }
  }

  .copy-link-container {
    margin-bottom: 12px;
    text-align: left;

    div {
      background: #e3e3e37b;
      position: relative;
      padding: 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover .copy-btn {
        background-image: url("../../assets/svg/copyBtn-2.svg");
      }
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font: normal normal normal 10px/12px Helvetica Neue;
    }
  }

  .copy-btn {
    background-color: transparent;
    background-image: url("../../assets/svg/copyBtn.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    width: 15px;
    min-width: 15px;
    height: 15px;
    border: none;
    margin: 0 7px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;

    button {
      flex: 1 1 100%;
      border: none;
      border-radius: 7px;
      top: 0;
      left: 0;
      padding: 12px;
      height: auto;
      position: relative;
      font: normal normal bold 10px/12px Helvetica Neue;

      &:focus,
      &:active {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .close-button {
    background: #ffe1e4;
    color: #ff6168;
    //transition: none;
    border: 1px solid transparent !important;

    &:hover {
      background: #db1f28;
      border-color: #fff !important;
      color: #fff !important;
      opacity: 1;
    }
  }

  .never-show {
    background: #f0f0f0;
    color: #7c7c7c;
    color: #7c7c7c;

    &:hover {
      background-color: #111010;
      color: white;
    }
  }

  @media screen and (min-width: 600px) {
    .modal-wrapper {
      justify-content: center;
      padding: 0;
      width: 100% !important;
    }

    .modal-container {
      max-width: 500px;
      position: absolute;
      top: 0% !important;
      bottom: auto;
    }

    .modal-header h2 {
      font: normal normal bold 20px/25px Helvetica Neue;
    }

    .modal-default-button {
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      margin: 10px;
      padding: 8px 0;
    }

    .modal-default-button svg {
      height: 15px;
      width: 15px;
      //vert-align: middle;
    }

    .modal-body {
      padding: 0 35px 18px !important;
    }

    .modal-body > p {
      text-align: center;
      font-weight: 500 !important;
      font-size: 18px;
      line-height: 1.4;
    }

    .icons-container {
      column-gap: 18px;
      margin-top: 25px;
      margin-bottom: 30px;
    }

    .icons-container a {
      //margin-right: 18px;
      width: 68px;
      height: 68px;
    }

    .copy-link-container div {
      padding: 10px;
    }

    .copy-link-container span {
      font: normal normal normal 12px/15px Helvetica Neue;
    }

    .copy-btn {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }

    .button-container {
      column-gap: 40px;

      button {
        //width: 180px;
        font: normal normal bold 12px/15px Helvetica Neue;
      }
    }
  }
}
</style>
