<template>
  <label class="switch">
    <input type="checkbox" v-model="checked" @change="emitChecked" />
    <span class="slider round" :class="{ 'with-check': withCheck }"></span>
  </label>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: {
    withCheck: Boolean,
    code: String,
  },
  data: function () {
    return {
      checked: false,
      lang: "",
    };
  },
  methods: {
    setLanguage(lang) {
      this.$store.commit("setUserLanguage", lang);
    },
    //EMIT SIWTCH TOGGLE STATE WITH CUSTOM  EVENT
    emitChecked() {
      this.setLanguage(this.code);
      this.$emit("switchChecked", { checked: this.checked, code: this.code });
    },
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1eb65b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1eb65b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

input:checked + .with-check.slider::after {
  position: absolute;
  left: 5px;
  top: 2px;
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  font-weight: 500;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: white;
  font-size: 12px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}
@media screen and (min-width: 600px) {
  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
}
</style>
