import Vue from 'vue';
const state = {
  isLinkedinLoggedin: false,
  linkedInData: {},
};

const getters = {
  getisLinkedinLoggedin: (state) => {
    return state.isLinkedinLoggedin;
  },
  getlinkedInData: (state) => {
    return state.linkedInData;
  },
};

const actions = {};
const mutations = {
  setLinkedInData: (state, payload)=>{
        state.linkedInData = payload;
        localStorage.setItem('linkedInData', JSON.stringify(state.linkedInData));
    },
    setIsLinkedinLoggedin: (state, payload) => {
        state.isLinkedinLoggedin  = true;
    }
  //   openNavModal(state) {
  //     //state.isNavModalOpen = true;
  //     let modal = document.getElementById('mobile-modal');
  //     modal.style.transform = 'translateY(0%)';
  //     modal.style.transform = 'scale(1, 1)';
  //     modal.style.transformOrigin = 'bottom right';
  //   },
  //   closeNavModal(state) {
  //     //state.isNavModalOpen = true;
  //     let modal = document.getElementById('mobile-modal');
  //     modal.style.transform = 'translateY(100%)';
  //     modal.style.transform = 'scale(1, 1)';
  //     modal.style.transformOrigin = 'bottom right';
  //   },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
