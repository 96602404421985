<template>
  <div class="container">
    <div
      class="box mb-3 shadow-sm border rounded bg-white profile-box text-center"
    >
      <div class="py-4 px-3 border-bottom">
        <img
          :src="startup.user.avatar"
          class="img-fluid mt-2 rounded-circle"
          alt=""
        />
        <h5 class="font-weight-bold text-dark mb-1 mt-4">
          {{ startup.startupname }}
        </h5>
        <small> @{{ startup.user.username }}</small>
      </div>
      <div class="p-3">
        <div class="d-flex align-items-top mb-2">
          <p class="mb-0 text-dark font-weight-bold">Support Network</p>
          <p class="font-weight-bold text-info mb-0 mt-0 ml-auto">358</p>
        </div>
        <div class="d-flex align-items-top">
          <p class="mb-0 text-dark font-weight-bold">All Employees</p>
          <p class="font-weight-bold text-info mb-0 mt-0 ml-auto">
            {{ startup.numbInTeam }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
import { mapGetters } from 'vuex';
export default {
  name: 'ProfileWidget',
  props: {
    startup: {},
    user: {},
  },
  computed: mapGetters(['authUser']),
};
</script>
