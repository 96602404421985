<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">Similar pages</h6>
    </div>
    <div class="box-body p-3">
      <div
        class="d-flex align-items-center osahan-post-header mb-3 people-list"
      >
        <div class="dropdown-list-image mr-3">
          <img
            class="rounded-circle"
            src="https://askbootstrap.com/preview/osahanin/light/img/l3.png"
            alt=""
          />
        </div>
        <div class="font-weight-bold mr-2">
          <div class="text-truncate">Spotify</div>
          <div class="small text-gray-500">Music</div>
        </div>
        <span class="ml-auto"
          ><button type="button" class="btn btn-light btn-sm text-nowrap">
            <i class="feather-eye"></i> Page
          </button>
        </span>
      </div>
      <div
        class="d-flex align-items-center osahan-post-header mb-3 people-list"
      >
        <div class="dropdown-list-image mr-3">
          <img
            class="rounded-circle"
            src="https://askbootstrap.com/preview/osahanin/light/img/l1.png"
            alt=""
          />
        </div>
        <div class="font-weight-bold mr-2">
          <div class="text-truncate">Envato</div>
          <div class="small text-gray-500">
            Digital
          </div>
        </div>
        <span class="ml-auto"
          ><button type="button" class="btn btn-light btn-sm text-nowrap">
            <i class="feather-eye"></i> Page
          </button>
        </span>
      </div>
      <div
        class="d-flex align-items-center osahan-post-header mb-3 people-list"
      >
        <div class="dropdown-list-image mr-3">
          <img
            class="rounded-circle"
            src="https://askbootstrap.com/preview/osahanin/light/img/l5.png"
            alt=""
          />
        </div>
        <div class="font-weight-bold mr-2">
          <div class="text-truncate">Paypal</div>
          <div class="small text-gray-500">Computer Software</div>
        </div>
        <span class="ml-auto"
          ><button type="button" class="btn btn-light btn-sm text-nowrap">
            <i class="feather-eye"></i> Page
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimilarPages',
  props: {
    startup: {},
  },
};
</script>

<style></style>
