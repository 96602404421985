<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="education" v-if="!editing">
      <div class="experience-row exp-title">
        <div class="title">
          <div class="school">{{ education.university }}</div>
          <div class="certificate">{{ education.level }}</div>
          <div class="control-buttons" v-if="showEditButtons">
            <edit-close-button-group class="justify-content-end" v-if="showEditButtons && !editing"
              @edit="edit(education)" @remove="deleteEdu(education.id)" />
          </div>
        </div>

      </div>

      <div class="experience-row">
        <p class="major">{{ education.title }}</p>
        <p class="location">
          <span v-if="education.city"> {{ education.city }} -</span>
          {{ fortmatCountry(education.country) }}
        </p>
        <p class="period">
          {{ formatDate(education.startDate) }}
          -
          <span v-if="education.endDate === 'now'">
            {{ $t(education.endDate) }}
          </span>

          <span v-else>
            {{ formatDate(education.endDate) }}
          </span>
        </p>
      </div>
      <p class="description">{{ education.description }}</p>
    </div>

    <form @submit.prevent="updateEducation" class="education form" v-if="editing">
      <div class="control">
        <label class="form-label">{{ $t("fieldOfStudy") }} <span class="text-danger">*</span>
        </label>
        <input type="text" class="profile-controls" :placeholder="$t('title')" v-model="form.title" required />
      </div>
      <div class="control">
        <label class="form-label">{{ $t("university") }}
          <span class="text-danger">*</span>
        </label>
        <input type="text" class="profile-controls" :placeholder="$t('university')" v-model="form.university"
          required />
      </div>
      <div class="control">
        <label class="form-label">{{ $t("level") }}
          <span class="text-danger">*</span>
        </label>
        <select class="profile-controls my-1 mr-sm-2" v-model="form.level" required>
          <option value="" disabled>{{ $t("choose") }}...</option>
          <option value="Bachelor">{{ $t("Bachelor") }}</option>
          <option value="Master">Master</option>
          <option value="Licence">Licence</option>
          <option value="Doctorat">{{ $t("Doctorat") }}</option>
          <option value="Post-Doc">{{ $t("Post-Doc") }}</option>
          <option value="Other">{{ $t("Other") }}</option>
        </select>
      </div>
      <div class="control">
        <label class="form-label">{{ $t("currently") }}
          <span class="text-danger">*</span>
        </label>
        <select class="profile-controls my-1 mr-sm-2" v-model="form.currently" required @change="setEndDate">
          <option value="" disabled>{{ $t("choose") }}...</option>
          <option value="1">{{ $t("yes") }}</option>
          <option value="0">{{ $t("no") }}</option>
        </select>
      </div>
      <div class="control w-100">
        <label class="form-label">{{ $t("country") }}
          <span class="text-danger">*</span>
        </label>
        <country-select v-model="form.country" :required="true" :countryName="true" :autocomplete="true"
          :disablePlaceholder="true" :usei18n="true" :country="fortmatCountry(form.country)" class="profile-controls"
          :placeholder="$t('choose')" />
      </div>
      <!-- <div class="control">
        <label class="form-label"
          >{{ $t("city") }}
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="profile-controls"
          :placeholder="$t('city')"
          v-model="form.city"
          required
        />
      </div> -->
      <div class="control">
        <label class="form-label">{{ $t("from") }}
          <span class="text-danger">*</span>
        </label>
        <Datepicker v-if="$i18n.locale === 'fr'" v-model="form.startDate" :language="fr" :clear-button="false"
          input-class="form-control profile-controls" :required="true" :typeable="false" minimum-view="month"
          :format="customFormatter" :placeholder="$t('from')" />
        <Datepicker v-else v-model="form.startDate" :language="en" input-class="form-control profile-controls"
          :clear-button="false" :required="true" :typeable="false" minimum-view="month" :format="customFormatter"
          :placeholder="$t('from')" />
      </div>
      <div class="control">
        <label class="form-label">{{ $t("to") }} (or expected)
          <span class="text-danger">*</span>
        </label>
        <div v-if="!selectedNow">
          <Datepicker v-if="$i18n.locale === 'fr'" v-model="form.endDate" :language="fr" :clear-button="false"
            input-class="form-control profile-controls" :required="true" :typeable="false" minimum-view="month"
            :format="customFormatter" :placeholder="$t('to')" />
          <Datepicker v-else v-model="form.endDate" :language="en" input-class="form-control profile-controls"
            :clear-button="false" :required="true" :typeable="false" minimum-view="month" :format="customFormatter"
            :placeholder="$t('to')" />
        </div>
        <div v-else>
          <input type="text" class="profile-controls" v-model="form.endDate" disabled />
        </div>
      </div>
      <div class="w-100">
        <label>Description </label>
        <textarea class="profile-controls profile-controls" rows="4" placeholder="Describe your position"
          name="description" v-model="form.description" spellcheck="true"></textarea>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <button :disabled="loading" type="submit" class="btn-save">
            {{ $t("save") }}
          </button>
          <button :disabled="loading" @click="cancel()" type="button" class="btn-cancel">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditCloseButtonGroup from "../buttons/EditCloseButtonGroup";
import LabeledSaveCancelButtonGroup from "../buttons/LabeledSaveCancelButtonGroup";
import i18n from "../../../../../i18n";
import { mapGetters, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { en, fr } from "vuejs-datepicker/dist/locale";
export default {
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getUserSectorOfInterest",
      "getSectorstList",
    ]),
  },
  components: {
    EditCloseButtonGroup,
    //LabeledSaveCancelButtonGroup,
    Datepicker,
  },
  data() {
    return {
      en: en,
      fr: fr,
      form: {
        level: "",
        currently: "",
        city: "",
      },
      edus: {},
      editing: false,
      loading: false,
      selectedNow: false,
    };
  },
  props: {
    education: Object,
  },
  methods: {
    ...mapActions(["loadEducation"]),
    edit(education) {
      this.form = education;
      if (this.form.endDate === "now") {
        this.selectedNow = true;
        this.form.currently = 1;
      }
      this.editing = true;
    },
    cancel: function () {
      this.editing = false;
    },
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date).format("MM/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    setEndDate() {
      if (this.form.currently === "1") {
        this.selectedNow = true;
        this.form.endDate = "now";
      } else {
        if (!this.form.endDate) {
          this.form.endDate = "";
        }
        this.selectedNow = false;
      }
    },
    updateEducation() {
      this.loading = true;
      this.form.country = this.countryCode(this.form.country);
      this.$store
        .dispatch("updateEducation", {
          education: this.form,
        })
        .then(async (success) => {
          this.form = {};
          await this.loadEducation({ user_id: this.authUser.id });
          this.editing = false;
          this.loading = false;
          this.$noty.success(this.$i18n.t("educationUpdated"));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
    deleteEdu(edu_id) {
      this.loading = true;
      this.$store
        .dispatch("deleteEducation", {
          edu_id,
        })
        .then(async (success) => {
          await this.loadEducation({ user_id: this.authUser.id });
          this.loading = false;
          this.$noty.success(this.$i18n.t("educationDeleted"));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: none !important;
  border-bottom: 2px solid var(--first-color) !important;
  border-radius: 0rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>


<style lang="scss" scoped>
.education {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(236, 97, 97, 0.2) !important;

  .experience-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;

    @include max-width(415) {
      /* background-color: green;
      flex-direction: column; */
      flex-wrap: wrap;
    }
  }

    .exp-title {
      background: #fff;
      padding: 0px 5px;
    }
  
    .title {
      width: 100% !important;
      display: flex;
    }

  & > p {
    padding: 5px 0;
  }

  &:last-child {
    border-bottom: none;

    @include max-width(991) {
      border-radius: 0 0 15px 15px;
    }
  }

  @include max-width(991) {
    justify-content: space-between;
    background-color: rgba(232, 232, 232, 0.38);
  }

  @include max-width(415) {
    padding: 5px 15px;
    border-radius: 0;
  }

  .school {
    order: 1;
    font-size: 20px;
    font-weight: 700;
    width: 75%;
    color: #000;

    @include max-width(991) {
      width: 75%;
    }

    @include max-width(415) {
      font-size: 16px;
      /* width: 88%; */
      height: 40px;
      // background: #57b46624;
      display: flex;
      align-items: center;

      width: 78%;
    }

    @include max-width(325) {
      font-size: 16px;
    }
  }

  .certificate {
    order: 2;
    width: 25%;
    font-size: 14px;
    color: #727272;
    text-align: right !important;

    @include max-width(991) {
      order: 5;
      width: 30%;
      text-align: right;
    }

    @include max-width(415) {
      font-size: 14px;
      /* width: 12%; */
      height: 40px;
      // background: #57b46624;
      display: flex;
      align-items: center;

      width: 22%;
    }
  }

  .control-buttons {
    order: 3;
    width: 25%;

    @include max-width(415) {
        display: flex;
        align-items: center;
      }
  }

  .major {
    order: 4;
    width: 44%;
    font-size: 19px;
    color: #000;
    @include max-width(991) {
      width: 70%;
    }

    @include max-width(415) {
      font-size: 18px;
      width: 60vw;
    }
  }

  .location {
    order: 6;
    width: 28%;
    font-size: 15px;
    color: #727272;
    text-align: center !important;

    @include max-width(991) {
      width: 50%;
    }

    @include max-width(415) {
      width: 30vw;
      font-size: 15px;
      text-align: right !important;
    }
  }

  .period {
    order: 7;
    width: 28%;
    font-size: 15px;
    color: #727272;
    text-align: right !important;

    @include max-width(991) {
      width: 50%;
      text-align: right;
    }

    @include max-width(415) {
      width: 100vw;
      font-size: 15px;
      text-align: left !important;
    }
  }

  .description {
    order: 8;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    @include max-width(415) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.form {
    justify-content: space-between;
    font-size: 16px;

    @include max-width(500) {
      font-size: 14px;
    }

    & > div {
      padding: 6px 0;

      @include max-width(500) {
        padding: 3px 0;
      }
    }

    .control {
      width: 47.5%;
    }

    textarea {
      width: 100%;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 3px;
    }
  }
}

@mixin btn-props {
  display: block;
  color: var(--green-color);
  font-size: 15px;
  background-color: #fff;
  padding: 5px 15px;
  border: 1px solid var(--green-color);
  border-radius: 15px;

  &:hover {
    background-color: var(--green-color);
    color: #fff;
  }
}

.btn-save {
  @include btn-props;
}

.btn-cancel {
  @include btn-props;
  margin-left: 10px;
  border: 1px solid var(--error-color);
  color: var(--error-color);

  &:hover {
    background-color: var(--error-color);
    color: #fff;
  }
}
</style>
