<template>
  <div
    class="header"
    :class="[getTotalNumberOfUnreadMessages > 0 ? 'hasUnreadMessages' : '']"
  >
    <section class="left-header">
      <div class="chat-user-profile">
        <img
          class="rounded-circle gravatar"
          :src="profile_img"
          @load="onImgLoad"
          alt=""
        />
        <div
          class="status-circle status-circle-online"
          v-if="getIsOnline"
        ></div>
      </div>
      <button
        class="header-message-btn active-toggle title hideOnMobile-btn"
        v-on:click="handleToggle"
      >
        {{ title }}
        <ButtonLabel :label="$t('ToggleChat')" />
      </button>
      <div
        v-if="getTotalNumberOfUnreadMessages"
        class="center-center unreadMessages"
      >
        {{ getTotalNumberOfUnreadMessages }}
        <ButtonLabel :label="$t('NewMessage')" />
      </div>
    </section>
    <section class="right-header">
      <!-- <button class="header-message-btn right-btn hideOnMobile-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path
            d="M3 9.5A1.5 1.5 0 114.5 8 1.5 1.5 0 013 9.5zM11.5 8A1.5 1.5 0 1013 6.5 1.5 1.5 0 0011.5 8zm-5 0A1.5 1.5 0 108 6.5 1.5 1.5 0 006.5 8z"
          ></path>
        </svg>
        <ButtonLabel :label="$t('MoreOptions')" />
      </button> -->
      <button
        class="header-message-btn right-btn new-message-btn"
        @click="openNewChatBox"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path
            d="M15 2.53a1.51 1.51 0 01-.44 1L9.15 9 6 10l1-3.12 5.44-5.44A1.5 1.5 0 0115 2.53zM12 11a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h3V2H5a3 3 0 00-3 3v6a3 3 0 003 3h6a3 3 0 003-3V8h-2z"
          ></path>
        </svg>
        <ButtonLabel :label="$t('NewMessage')" />
      </button>
      <button class="header-message-btn right-btn" v-on:click="handleToggle">
        <svg
          v-if="getIsActive"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path d="M15 11L8 6.39 1 11V8.61L8 4l7 4.61z"></path>
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          data-supported-dps="16x16"
          fill="currentColor"
          class="mercado-match"
          width="16"
          height="16"
          focusable="false"
        >
          <path d="M1 5l7 4.61L15 5v2.39L8 12 1 7.39z"></path>
        </svg>
        <ButtonLabel :label="$t('ToggleChat')" />
      </button>
    </section>
  </div>
</template>

<script>
import ButtonLabel from "./ButtonLabel";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    let profileUrl = this.authUser.avatar;
    if (profileUrl && profileUrl.indexOf("upload/") != -1) {
      let index = profileUrl.indexOf("upload/");
      let first_part = profileUrl.substr(0, index + 6);
      let last_part = profileUrl.substr(index + 6);
      this.profile_img =
        first_part + "/q_auto:good/c_fill,h_50,w_50" + last_part;
    } else {
      this.profile_img = this.authUser.avatar;
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getMyAvatar",
      "getIsOnline",
      "getCurrentlyChattingWith",
      "getTotalNumberOfUnreadMessages",
      "getIsNewChatConvo",
      "getIsBubbleClosed",
      "getIsBubbleActive",
      "getIsActive",
    ]),
    ...mapState(["totalNumberOfUnreadMessages", "isActive"]),
  },
  data: () => {
    return {
      profile_img: "",
      isLoaded: false,
    };
  },
  methods: {
    ...mapActions(["openNewChatBox"]),
    ...mapMutations(["handleToggle"]),
    onImgLoad() {
      this.isLoaded = true;
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  props: {
    title: String,
    Userimg: String,
    avatar: String,
  },
  components: {
    ButtonLabel,
  },
};
</script>

<style>
.chat-user-profile {
  width: 50px;
  height: 50px;
  position: relative;
}

.status-circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid white;
  bottom: 0px;
  right: -7px;
  position: absolute;
}
.status-circle-online {
  background-color: rgb(4, 179, 91);
}
.status-circle-offline {
  background-color: rgb(133, 132, 132);
}
</style>



<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
button {
  border: none;
  background: none;
}
*:focus {
  outline: none;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 0;
  position: relative;
  min-height: 48px;
  background-clip: padding-box;
  background-color: white;
  border-radius: 0.8rem 0.8rem 0 0;
  position: relative;
  color: #191919;
}
.header .right-btn {
  color: #5c5c5c;
}
.hasUnreadMessages {
  background-color: #0067c9;
  color: white;
}
.hasUnreadMessages .header-message-btn {
  color: white;
}

.left-header {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  margin-right: 4px;
  padding: 4px;
  position: relative;
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
}
.user-profile {
  width: 32px;
  height: 32px;
  background-color: rgba(255, 555, 255, 0.4);
  border: 1px solid rgba(255, 3, 3, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-clip: content-box;
}
.gravatar {
  width: 100%;
  height: 100%;
  border: 0.4px solid rgb(146, 146, 146);
}
.header-message-btn {
  display: block;
  position: relative;
  max-width: 230px;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.42857;

  /* color: rgba(255, 255, 255, 0.9); */
}
.right-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #5c5c5c;
}
.right-btn svg {
  width: 22px;
  height: 22px;
}

svg path {
  color: #fff !important;
  fill: rgba(0, 0, 0, 0.756) !important;
}
.right-btn:hover {
  /* background: #cfead9 !important; */
  background-color: #e5e6e7;
  transition: all 0.2 ease-in-out;
}
.header-message-btn:hover .label-btn {
  opacity: 1;
}
.unreadMessages:hover .label-btn {
  opacity: 1;
}
.active-toggle {
  font-size: 22px;
  margin-left: 10px;
}
.active-toggle:hover .label-btn {
  opacity: 1;
  left: -50%;
}
.right-header {
  display: flex;
  justify-content: space-between;
}
.unreadMessages {
  font-size: 14px;
  font-weight: 500px;
  position: absolute;
  right: 0px;
  background-color: #dd0000;
  border: 1px solid #e37b79;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 650px) {
  .hideOnMobile-btn {
    display: none;
  }
  .right-header {
    position: relative;
  }

  .right-btn svg {
    width: 18px;
    height: 18px;
    /* top: -50px; */
  }
  .new-message-btn {
    position: absolute;
    top: -100px;
    left: -40px;
    font-size: 1rem;
    width: 60px;
    height: 60px;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.1);
    color: white;
    border-radius: 20px;
    background-color: #51aa63;
  }
  .new-message-btn svg {
    width: 20px;
    height: 20px;
    color: #3d8f38;
    color: white;
  }
}
</style>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
button {
  border: none;
  background: none;
}
*:focus {
  outline: none;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 0;
  position: relative;
  min-height: 48px;
  background-clip: padding-box;
  background-color: white;
  border-radius: 0.8rem 0.8rem 0 0;
  position: relative;
  color: #191919;
}
.header .right-btn {
  color: #5c5c5c;
}
.hasUnreadMessages {
  background-color: var(--first-color);
  color: white;
}
.hasUnreadMessages .header-message-btn {
  color: white;
}

.left-header {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  margin-right: 4px;
  padding: 4px;
  position: relative;
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
}
.chat-user-profile {
  width: 32px;
  height: 32px;
  background-color: rgba(255, 555, 255, 0.4);
  /* border: 1px solid rgba(255, 3, 3, 0.2); */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-clip: content-box;
}
.gravatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.header-message-btn {
  position: relative;
  max-width: 230px;
  cursor: pointer;
  font-size: 0.4rem;
  line-height: 1.42857;
}
.right-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #5c5c5c;
}
.right-btn svg {
  width: 17px;
  height: 17px;
}
.right-btn:hover {
  background-color: #cccccccc;
  transition: all 0.2 ease-in-out;
}
.header-message-btn:hover .label-btn {
  opacity: 1;
  z-index: 3000 !important;
}
.unreadMessages:hover .label-btn {
  opacity: 1;
}
.active-toggle {
  font-size: 22px;
  margin-left: 10px;
}
.active-toggle:hover .label-btn {
  opacity: 1;
  left: -50%;
}
.right-header {
  display: flex;
  justify-content: space-between;
}
.unreadMessages {
  font-size: 12px;
  font-weight: 500px;
  position: absolute;
  margin-top: 2px;
  right: 30px;
  background-color: #dd0000;
  border: 1px solid #e37b79;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 650px) {
  .unreadMessages {
    right: -5px;
    top: 0px;
  }
  .hideOnMobile-btn {
    display: none;
  }
  .right-header {
    position: relative;
  }

  .right-btn {
    width: 30px;
    height: 30px;
  }

  .right-btn svg {
    width: 14px;
    height: 14px;
    /* top: -50px; */
  }
  .new-message-btn {
    position: absolute;
    top: -100px;
    left: -40px;
    font-size: 1rem;
    width: 60px;
    height: 60px;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.1);
    color: white;
    border-radius: 100%;
    background-color: #57b366;
  }
  .new-message-btn svg {
    width: 20px;
    height: 20px;
    color: #3d8f38;
    color: white;
  }
}
</style>