<template>
  <div class="StepOne">
    <LeftSide />
    <RightSide />
    <LanguageSwitcherRegister />
  </div>
</template>
<script>
import RightSide from "./RightSide";
import LeftSide from "./LeftSide";
import i18n from "../../../../i18n";
import store from "../../../../store/";
import LanguageSwitcherRegister from "../../../../components/LanguageSwitcherRegister.vue";
export default {
  beforeRouteEnter: (to, from, next) => {
    if (this.$store.getters.authUser) {
      return next(`/${i18n.locale}/${i18n.t("routes.dashboard")}`);
    }
    next();
  },
  metaInfo() {
    return {
      title: `Bantaba | ${this.$i18n.t("register")} | Step 1`,
      meta: [
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  name: "StepOne",
  components: {
    RightSide,
    LeftSide,
    LanguageSwitcherRegister,
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.StepOne {
  background-color: var(--bg-color);
  position: relative;
  display: flex;
  height: 100%;
  width: 100vw;
  margin-left: 0;
  overflow: hidden;
  margin-top: 0 auto !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .StepOne {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    margin-top: 0 auto !important;
    overflow: hidden !important;
  }
  .languageSwitcher {
    position: absolute;
    z-index: 1000;
    position: fixed;
    z-index: 9000;
    top: auto;
    left: 100px;
    right: 100px;
    bottom: 0px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6d6969;
    font-size: 18px;
    background-color: #f6faf770;
    backdrop-filter: blur(10px);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>