<template>
  <div>
    <div class="py-5 background">
      <div class="container" v-if="!loading">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="row">
              <div class="col mb-4">
                <!-- <router-link
                  :to="{
                    name: 'AdminHomeEn',
                  }"
                  class="btn btn btn-success"
                >
                  <i class="icofont-home mr-1"></i>
                  Home
                </router-link> -->
              </div>
            </div>
            <div class="bg-white shadow-sm box border rounded p-5">
              <h3 class="mb-4">Add Jobs</h3>
              <form method="post" @submit="submit">
                <div class="form-row mb-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Job Title
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <input
                        v-model="form.title"
                        type="text"
                        class="form-control"
                        id="title"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="form-row mb-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Company
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <input
                        v-model="form.company"
                        type="text"
                        class="form-control"
                        id="title"
                        required
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Company Logo
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <input
                        v-model="form.companyLogo"
                        type="text"
                        class="form-control"
                        id="title"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="form-row mb-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="selectType"
                        >Country
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <country-select
                        v-model="form.country"
                        :required="true"
                        :countryName="true"
                        :autocomplete="true"
                        :disablePlaceholder="true"
                        :use188n="false"
                        :country="form.country"
                        class="input-text custom-select"
                        :placeholder="$t('choose')"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label">City</label>
                      <input
                        v-model="form.city"
                        type="text"
                        class="form-control"
                        id="title"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-row mb-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Deadline
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <Datepicker
                        v-model="form.deadline"
                        :language="en"
                        :clear-button="false"
                        input-class="input-text form-control"
                        :required="true"
                        :typeable="true"
                        :disabledDates="disabledDates"
                        :format="customFormatter"
                        placeholder="Deadline"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Language
                        <span class="text-danger requiredStar">*</span>
                      </label>

                      <div class="form-group">
                        <select
                          v-model="form.lang"
                          class="form-control custom-select"
                          required=""
                          data-msg="Please select month."
                          data-error-class="u-has-error"
                          data-success-class="u-has-success"
                        >
                          <option selected>Select Language</option>
                          <option value="en">English</option>
                          <option value="fr">French</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row mb-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Source Link
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <input
                        v-model="form.link"
                        type="link"
                        class="form-control"
                        placeholder="Source Link"
                        id="title"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="form-row mb-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="title" class="form-label"
                        >Summary
                        <span class="text-danger requiredStar">*</span>
                      </label>
                      <textarea
                        v-model="form.summary"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="title" class="form-label"
                    >Full Text
                    <span class="text-danger requiredStar">*</span>
                  </label>
                  <editor
                    v-model="form.fullText"
                    api-key="frsb2ky82bt6d80huabhezpgj47c9ktx01iha9lm1kpav85q"
                    :init="init"
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-success btn-block text-uppercase"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../../i18n";
import store from "../../../store/";
import APIURLS from "../../../constant";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";
import Editor from "@tinymce/tinymce-vue";

export default {
  mounted() {
    var countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    this.countries = countries.getNames(i18n.locale, { select: "official" });
  },
  computed: {
    ...mapGetters([]),
  },
  data() {
    return {
      form: {},
      disabledDates: {
        to: new Date(),
      },
      DatePickerFormat: "MM/DD/YYYY",
      en: en,
      fr: fr,
      loading: false,
      init: {
        themes: "modern",
        mobile: {
          theme: "mobile",
          plugins: ["autosave", "lists", "autolink"],
        },
        height: 500,
        selector: "textarea",
        plugins: [
          "advlist autolink lists link image charmap print preview hr anchor pagebreak",
          "searchreplace wordcount visualblocks visualchars code fullscreen",
          "insertdatetime media nonbreaking save table contextmenu directionality",
          "emoticons template paste textcolor colorpicker textpattern",
        ],
        toolbar:
          "insertfile undo redo paste  spellchecker pagebreak visualblocks visualchars anchor autolink charmap help linkchecker nonbreaking legacyoutput tabfocus | styleselect | bold italic | forecolor backcolor | restoredraft |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | advlist | link image media | emoticons | fullpage | preview | hr | insertdatetime | searchreplace",
        relative_urls: false,
        file_browser_callback: function (field_name, url, type, win) {
          var x =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.getElementsByTagName("body")[0].clientWidth;
          var y =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName("body")[0].clientHeight;
        },
      },
    };
  },
  methods: {
    ...mapActions(["logUserIn"]),
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },

    submit() {
      this.loading = true;
      this.form.slug = this.convertToSlug(this.form.title);
      this.form.country = this.countryCode(this.form.country);
      Axios.post(APIURLS.JOBS + "create", this.form)
        .then((response) => {
          this.loading = false;
          if (response.data.statusMsg != "Success") {
            this.$noty.error(response.data.statusMsg);
          } else {
            this.form = {};
            this.$noty.success("Success!");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error.response.data.message);
          this.error = true;
          this.errorMsg = error.data.message;
          this.$noty.error(this.errorMsg);
        });
    },
  },
  name: "CreateNews",
  components: {
    Datepicker,
    editor: Editor,
  },
};
</script>

<style scoped>
.form-control {
  border: 1px solid #747474 !important;
}
label[for="inlineRadio2"]::before,
label[for="inlineRadio1"]::before {
  content: "";
  display: none;
}
label[for="inlineRadio2"]::after,
label[for="inlineRadio1"]::after {
  content: "";
  display: none;
}

label[for="inlineRadio2"],
label[for="inlineRadio1"] {
  padding-left: 0;
  margin-bottom: 0;
}
</style>

<style>
.requiredStar {
  font-size: 15px !important;
}
._vue-flash-msg-body._vue-flash-msg-body_success {
  border: 1px solid #08ff54;
  background-color: rgb(30 173 34);
  bottom: 120px !important;
  color: white;
}

._vue-flash-msg-body._vue-flash-msg-body_success p {
  color: #fff !important;
}
</style>
