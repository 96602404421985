<template>
  <button class="header-button" :class="{ active: isActive }">
    <span class="icon-wrapper">
      <i :class="'icon fa fa-' + iconName"></i>
    </span>
    <span class="btn-text mobile-only">
      {{ btnText }}
    </span>
  </button>
</template>


<script>
export default {
  name: "HeaderButton",
  props: {
    isActive: Boolean,
    iconName: String,
    btnText: String,
  },
  methods: {
    isDuplicate(text) {
      return text.split(" ").length === 1;
    },
  },
};
</script>


<style >
button.header-button {
  padding: 5px;
  /* border-radius: 50px; */
  border: 2px solid rgba(71, 71, 71, 0.15);
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 33%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
button.header-button .icon-wrapper {
  padding: 2px 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
button.header-button .btn-text {
  padding: 0 10px;
}

button.header-button .btn-text .optional-text.duplicate {
  display: none;
}

button.header-button.active {
  background-color: #1eb65b;
  border: 2px solid #fff;
  color: white;
}

@media only screen and (max-width: 600px) {
  button.header-button .btn-text .optional-text,
  .mobile-only {
    /* background: blue;  */
    width: 100%;
  }
  button.header-button {
    align-items: center !important;
  }
  button.header-button .icon-wrapper {
    padding: 5px;
    margin: 4px;
  }

  button.header-button .icon-wrapper svg {
    font-size: 12px;
  }

  button.header-button .btn-text {
    padding: 0 0px;
    font-size: 13px;
    width: 100% !important;
  }
}
/* 

@media only screen and (max-width: 768px) {
 button.header-button {
  padding: 10px 5px;
  border-radius: 20px;
}
button.header-button .icon-wrapper {
  padding: 8px 10px;
}
button.header-button .btn-text {
  padding: 0 5px;
  font-size: 12px;
}

} */
</style>
