<template>
  <img :src="avatar_image" class="gravatar rounded-circle" />
</template>
<script>
export default {
  name: "OptimizeImg",
  props: {
    src: String,
  },
  data() {
    return {
      avatar_image: "",
      avatar_isLoaded: false,
    };
  },
  mounted() {
    let url = this.src ? this.src : "";
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // this.avatar_image = first_part + "/q_auto:low/c_fill,h_250,w_250/" + last_part;
      this.avatar_image =
        first_part + "/q_auto:low/c_fill,h_50,w_50/" + last_part;
    } else {
      this.avatar_image = this.chat ? this.chat.fromAvartar : "";
    }
  },
  methods: {
    onImgLoad() {
      this.avatar_isLoaded = true;
    },
  },
};
</script>
<style scoped>
.gravatar {
  width: 100%;
  height: 100%;
}
</style>