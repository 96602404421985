<template>
  <div class="page">
    <div class="page-container showNotVerifiedNotfication">
      <NoDataFound
        class=""
        :svg="require('../../assets/images/noData/email_verified.svg')"
        :hasTitle="true"
        :title="$t('emailDone')"
        :hasText="true"
        :text="$t('doneText')"
        :hasButton="true"
        :buttonText="$t('navBar.home')"
        :buttonType="1"
        :buttonLink="`/${this.$i18n.locale}/${this.$i18n.t('routes.home')}`"
        :hasButton2="true"
        :buttonText2="$t('login')"
        :buttonLink2="`/${this.$i18n.locale}/${this.$i18n.t('routes.login')}`"
      />
    </div>
  </div>
</template>
<script>
import NoDataFound from "../../components/NoDataFound/Version-1.vue";
export default {
  name: "EmailConfirmed",
  components: {
    NoDataFound,
  },
};
</script>
<style scoped>
.showNotVerifiedNotfication {
  padding-top: 70px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.page {
  background-color: white;
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  margin-top: 0px;
  margin-left: 0;
  padding: 20px;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-container {
  width: 100%;
  min-height: 100%;
  /* height: calc(100% - 70px); */
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
  .page {
    background-color: white;
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    margin-top: 0px;
    margin-left: 0;
    padding: 20px;

    /* padding-top: 70px;  */
  }
  .page-container {
    width: 100%;
    max-width: 990px;
    /* height: calc(100% - 70px); */
  }
}

@media only screen and (max-width: 600px) {
  .showNotVerifiedNotfication {
    padding-top: 60px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
