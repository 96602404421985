<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div class="border rounded bg-white mb-3">
      <Alert
        v-if="!startup.confirmed"
        type="warning"
        :title="this.$i18n.t('yourStartupHidden')"
        :text="this.$i18n.t('yourStartupHiddenText')"
      />
      <div class="box-title border-bottom p-3">
        <h6 class="m-0">
          {{ $t("editStartupInfo") }}
        </h6>
      </div>
      <div class="box-body p-3">
        <form @submit.prevent="updateStartupInfos">
          <div class="row">
            <div class="col-sm-6 mb-2">
              <div class="js-form-message">
                <label id="nameLabel" class="form-label">
                  {{ $t("startupName") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    v-model="startup.startupname"
                    :placeholder="$t('namePlaceholder')"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-2">
              <div class="js-form-message">
                <label id="locationLabel" class="form-label">
                  {{ $t("city") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="birthCity"
                    v-model="startup.city"
                    :placeholder="$t('city')"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-sm-6 mb-2">
              <div class="js-form-message">
                <label id="emailLabel" class="form-label">
                  {{ $t("contactEmail") }}
                </label>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    :placeholder="$t('email')"
                    v-model="startup.contactEmail"
                  />
                  <small class="form-text text-muted">{{
                    $t("publicEmail")
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-2">
              <div class="js-form-message">
                <label id="phoneNumberLabel" class="form-label">
                  {{ $t("contactPhone") }}
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="phone"
                    :placeholder="$t('phone')"
                    v-model="startup.contactPhone"
                  />
                  <small class="form-text text-muted"
                    >{{ $t("publicPhone") }}
                  </small>
                </div>
              </div>
            </div> -->
            <div class="col-sm-6 mb-2">
              <div class="js-form-message">
                <label id="locationLabel" class="form-label">
                  {{ $t("country") }} ({{ $t("Headquarters") }})
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <VueMultiselect
                    v-model="startup.location"
                    :options="countriesLists"
                    :multiple="false"
                    track-by="name"
                    :custom-label="customLabel"
                    selectLabel="+"
                    deselectLabel="-"
                    required
                  />
                  <!-- <country-select
                    v-model="startup.location"
                    :countryName="true"
                    :autocomplete="true"
                    :disablePlaceholder="true"
                    :use188n="false"
                    :country="startup.location"
                    class="form-control custom-select"
                    :placeholder="$t('country')"
                  /> -->
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-2">
              <div class="js-form-message">
                <label id="locationLabel" class="form-label">
                  {{ $t("sector") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <VueMultiselect
                    v-model="startup.sector"
                    :options="allSectors"
                    :multiple="false"
                    track-by="name"
                    :custom-label="customLabel"
                    selectLabel="+"
                    deselectLabel="-"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 mb-2">
              <div class="js-form-message">
                <label id="nameLabel" class="form-label">
                  {{ $t("website") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="address"
                    v-model="startup.website"
                    :placeholder="$t('website')"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-4 mb-2">
              <div class="js-form-message">
                <label id="nameLabel" class="form-label">
                  {{ $t("numbInTeam") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="address"
                    v-model="startup.numbInTeam"
                    :placeholder="$t('numbInTeam')"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-4 mb-2">
              <div class="js-form-message">
                <label id="nameLabel" class="form-label">
                  {{ $t("foundingYear") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <Datepicker
                    v-if="$i18n.locale === 'fr'"
                    v-model="startup.foundingYear"
                    :language="fr"
                    :clear-button="false"
                    input-class="input-text form-control"
                    :required="true"
                    :disabledDates="disabledDates"
                    :typeable="true"
                    minimum-view="year"
                    :format="DatePickerFormat"
                    :placeholder="$t('foundingYear')"
                  />
                  <Datepicker
                    v-else
                    v-model="startup.foundingYear"
                    :language="en"
                    :clear-button="false"
                    input-class="input-text form-control"
                    :required="true"
                    :typeable="true"
                    :disabledDates="disabledDates"
                    minimum-view="year"
                    :format="DatePickerFormat"
                    :placeholder="$t('foundingYear')"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-2">
              <div class="">
                <p>
                  {{ $t("typeofTalents") }}
                  <span class="text-danger">*</span>
                </p>
                <form
                  @submit.prevent="createSupport"
                  v-if="showSupportTypeform"
                >
                  <div class="border rounded bg-white mb-3">
                    <div class="box-body px-3 pt-3 pb-0">
                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <label id="TO" class="form-label"
                            >{{ $t("Select") }}
                            <span class="text-danger">*</span>
                          </label>
                          <div class="input-group">
                            <VueMultiselect
                              v-model="startup.typeofTalents"
                              :options="types"
                              :multiple="false"
                              :value="startup.typeofTalents"
                              track-by="name"
                              :custom-label="customLabel"
                              selectLabel="+"
                              deselectLabel="-"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      :disabled="loadingSupport"
                      type="submit"
                      class="btn btn-success ml-3"
                    >
                      <i
                        class="fa fa-spin fa-1x fa-spinner"
                        v-if="loadingSupport"
                      ></i>
                      {{ loadingSupport ? "" : $t("save") }}
                    </button>

                    <button
                      type="button"
                      @click="hideSupportTypeForm"
                      class="btn btn-danger ml-3"
                    >
                      {{ $t("cancel") }}
                    </button>
                  </div>
                </form>
                <div class="card-body">
                  <div>
                    <div class="col-12 mb-2">
                      <button
                        type="button"
                        class="btn btn-info m-1 tag"
                        v-for="support in getUserSupportTypes"
                        :key="support.id"
                      >
                        {{ $t(support.typeOfSupport) }}
                        <span
                          @click="deleteSupportType(support.id)"
                          class="badge badge-light"
                          ><span class="feather-x"></span
                        ></span>
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="showSupportTypeFormulaire"
                    class="btn btn-primary"
                  >
                    {{ $t("addSupportType") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-2">
              <div class="">
                <p>
                  {{ $t("talentNeeded") }}
                  <span class="text-danger">*</span>
                </p>
                <form @submit.prevent="createTalent" v-if="showTalentform">
                  <div class="border rounded bg-white mb-3">
                    <div class="box-body px-3 pt-3 pb-0">
                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <label id="TO" class="form-label"
                            >{{ $t("Select") }}
                            <span class="text-danger">*</span>
                          </label>
                          <div class="input-group">
                            <VueMultiselect
                              v-model="startup.talents"
                              :options="allTalents"
                              :multiple="false"
                              :value="startup.talents"
                              track-by="name"
                              :custom-label="customLabel"
                              selectLabel="+"
                              deselectLabel="-"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      :disabled="loadingTalents"
                      type="submit"
                      class="btn btn-success ml-3"
                    >
                      <i
                        class="fa fa-spin fa-1x fa-spinner"
                        v-if="loadingTalents"
                      ></i>
                      {{ loadingTalents ? "" : $t("save") }}
                    </button>

                    <button
                      type="button"
                      @click="hideTalentsForm"
                      class="btn btn-danger ml-3"
                    >
                      {{ $t("cancel") }}
                    </button>
                  </div>
                </form>
                <div class="card-body">
                  <div>
                    <div class="col-12 mb-2">
                      <button
                        type="button"
                        class="btn btn-info m-1 tag"
                        v-for="talent in getStartupNeededTalents"
                        :key="talent.id"
                      >
                        {{ $t(talent.name) }}
                        <span
                          @click="deleteTalent(talent.id)"
                          class="badge badge-light"
                          ><span class="feather-x"></span
                        ></span>
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="showTalentFormulaire"
                    class="btn btn-primary"
                  >
                    {{ $t("addTalent") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mb-2">
              <div class="js-form-message">
                <label id="phoneNumberLabel" class="form-label">
                  {{ $t("specialties") }}
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="3"
                    required
                    v-model="startup.specialties"
                    spellcheck="false"
                    :placeholder="$t('specialties')"
                  ></textarea>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-12 mb-2">
              <div class="js-form-message">
                <label id="description" class="form-label">
                  {{ $t('talentNeeded') }} (max:5)
                  <span class="text-danger">*</span>
                </label>
                <VueMultiselect
                  v-model="startup.talents"
                  :options="allTalents"
                  :multiple="true"
                  track-by="name"
                  :custom-label="customLabel"
                  :max="10"
                  selectLabel="+"
                  deselectLabel="-"
                  required
                />
              </div>
            </div> -->
            <div class="col-sm-12 mb-2">
              <div class="js-form-message">
                <label id="description" class="form-label">
                  {{ $t("descriptionStartup") }} (Elevator Pitch)
                  <span class="text-danger">*</span>
                </label>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="10"
                    required
                    v-model="startup.description"
                    spellcheck="false"
                    :placeholder="`${$t(
                      'descriptionStartup'
                    )} (Elevator Pitch)`"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 text-right">
            <div class="overflow-hidden text-center p-3">
              <button
                :disabled="btnLoading"
                type="submit"
                class="font-weight-bold btn btn-light rounded p-3 btn-block"
              >
                <i class="fa fa-spin fa-1x fa-spinner" v-if="btnLoading"></i>
                {{ btnLoading ? $t("save") + "..." : $t("save") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";
import VueMultiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
export default {
  watch: {
    async $route() {
      await this.getCountries();
    },
  },

  async mounted() {
    var lang = i18n.locale;
    await this.getCountries();
    await this.loadSectorsList({ lang });
    await this.loadTalents({ lang });
    let startup_id = this.startup.id;
    await this.loadStartupNeeds({ startup_id });
    await this.loadStartupTypeOfSupport({ startup_id });
    this.talents = this.getTalents;
    this.talentsNeeded = this.getStartupNeededTalents;
    // this.sectors = this.getSectorstList;
    this.sectors.forEach((sector) => {
      this.allSectors.push({
        id: sector.id,
        code: sector.code,
        name: sector.name,
      });
    });
    this.types = [
      {
        id: 1,
        code: "Invest",
        name: i18n.t("Investment"),
      },
      {
        id: 2,
        code: "Consulting",
        name: i18n.t("Consulting"),
      },
      {
        id: 3,
        code: "Mentoring",
        name: i18n.t("Mentoring"),
      },
    ];
    // eslint-disable-next-line vue/no-mutating-props
    this.startup.typeofTalents = this.types.find(
      (type) => type.code === this.startup.typeofTalents
    );
    // eslint-disable-next-line vue/no-mutating-props
    this.startup.sector = this.allSectors.find(
      (sector) => sector.code === this.startup.sector
    );
    // eslint-disable-next-line vue/no-mutating-props
    this.startup.location = this.countriesLists.find(
      (country) => country.code === this.startup.location
    );
    this.talents.forEach((talent) => {
      this.allTalents.push({
        talent_id: talent.id,
        code: talent.code,
        name: talent.name,
      });
    });
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartup",
      "getSectorstList",
      "getProfile",
      "getTalents",
      "getCountriesList",
      "getStartupNeededTalents",
      "getUserSupportTypes",
    ]),
  },
  data() {
    return {
      avatar: null,
      en: en,
      fr: fr,
      DatePickerFormat: "yyyy",
      sectorsList: {},
      countriesLists: [],
      countries: null,
      talents: {},
      allSectors: [],
      allTalents: [],
      talentsNeeded: [],
      types: [],
      loading: true,
      loadingSupport: false,
      loadingTalents: false,
      btnLoading: false,
      disabledDates: {
        from: new Date(),
      },
      form: {},
      btnLoadingSocials: false,
      availabilityText: null,
      availability: null,
      inters: {},
      showSupportTypeform: false,
      showTalentform: false,
      interrestList: {},
    };
  },
  methods: {
    ...mapActions([
      "loadStartup",
      "loadSectorsList",
      "loadProfile",
      "updateBasicInfo",
      "loadTalents",
      "loadStartupNeeds",
      "loadCountriesList",
      "loadStartupTypeOfSupport",
      "deleteStartupSupport",
      "createStartupTypeOfSupport",
      "deleteStartupSupportNeed",
      "createStartupTalent",
    ]),
    async getCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.countriesLists.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },

    showSupportTypeFormulaire() {
      this.showSupportTypeform = true;
    },
    showTalentFormulaire() {
      this.showTalentform = true;
    },
    async createSupport() {
      let startup_id = this.startup.id;
      let support = this.startup.typeofTalents;
      await this.createStartupTypeOfSupport({ startup_id, support });
      await this.loadStartupTypeOfSupport({ startup_id });
      this.showSupportTypeform = false;
    },
    async createTalent() {
      let startup_id = this.startup.id;
      let talent = this.startup.talents;
      await this.createStartupTalent({ startup_id, talent });
      await this.loadStartupNeeds({ startup_id });
      this.showTalentform = false;
    },
    async deleteSupportType(support_id) {
      
      let startup_id = this.startup.id;
      await this.deleteStartupSupport({ startup_id, support_id });
      await this.loadStartupTypeOfSupport({ startup_id });
    },
    async deleteTalent(talent_id) {
      let startup_id = this.startup.id;
      await this.deleteStartupSupportNeed({ startup_id, talent_id });
      await this.loadStartupNeeds({ startup_id });
    },
    hideSupportTypeForm() {
      this.showSupportTypeform = false;
    },
    hideTalentsForm() {
      this.showTalentform = false;
    },
    customLabel(option) {
      return `${option.name}`;
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, i18n.locale));
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
    },
    updateStartupInfos() {
      this.btnLoading = true;

      let confirmed = 0;
      if (
        this.startup.startupname &&
        this.startup.location &&
        this.startup.sector &&
        this.startup.numbInTeam &&
        this.startup.typeofTalents &&
        this.startup.foundingYear &&
        this.startup.description
      ) {
        confirmed = 1;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.startup.startupname = this.startup.startupname.trim();
      let data = {
        startupname: this.startup.startupname,
        description: this.startup.description,
        slug: this.convertToSlug(this.startup.startupname),
        specialties: this.startup.specialties,
        foundingYear: this.startup.foundingYear,
        numbInTeam: this.startup.numbInTeam,
        website: this.startup.website,
        city: this.startup.city,
        confirmed: confirmed,
        // talents: this.startup.talents,
        typeofTalents: this.startup.typeofTalents.code,
        sector: this.startup.sector.code,
        location: this.startup.location.code,
      };

      this.$store
        .dispatch("updateStartup", data)
        .then((success) => {
          this.$noty.success(`${i18n.t("success")}`);
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          this.$noty.error(error);
        });
    },
  },
  name: "UpdateStartupInfos",
  components: {
    Datepicker,
    VueMultiselect,
  },
  props: {
    startup: {},
    profile: {},
    sectors: {},
  },
};
</script>

<style scopeed>
input.form-control[type="text"],
select,
textarea {
  padding-left: 10px !important;
  border: 1px solid rgb(116, 116, 116) !important;
}
</style>
