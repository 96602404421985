var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-card",class:{
  hideStyles: _vm.avatar.includes(`https://res.cloudinary.com/bantaba/`),
}},[_c('imageUploadModal',{attrs:{"isShow":_vm.isUploadModal,"loading":_vm.loading,"showPreview":_vm.showPreview,"startVal":_vm.startVal,"endVal":_vm.endVal,"decimals":_vm.decimals,"options":_vm.options},on:{"closeModal":_vm.closeModal,"showPreviewTrue":_vm.showPreviewTrue,"submit":_vm.submit}}),_c('form',{staticClass:"cropview",attrs:{"enctype":"multipart/form-data","action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('label',{staticClass:"imgage-container",staticStyle:{"cursor":"pointer"},attrs:{"for":"image"},on:{"click":function($event){_vm.isUploadModal = true}}},[(!_vm.showPreview)?_c('img',{staticClass:"img-fluid mt-2",class:{
        previewAvatar: _vm.avatar.includes(
          `https://res.cloudinary.com/bantaba/`
        ),
      },attrs:{"src":_vm.avatar,"alt":"avatar"}}):_vm._e()]),_c('p',{staticClass:"profile-text",class:{ hideTextPlaceholder: _vm.isShowPreview }},[_c('span',{staticClass:"profile-text-placeholder"},[_vm._v(_vm._s(_vm.$t("Add Photo"))+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }