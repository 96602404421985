<template>
  <div class="constainer2 rounded text-center">
    <div class="col-md-12">
      <div class="overflow-hidden video-frame">
        <!-- <vue-plyr>
          <div 

          class="plyr__video-embed">
            <iframe
              class="player"
              :src="link"
              allowfullscreen
              allowtransparency
              allow="autoplay"
              data-plyr-provider="youtube"
            ></iframe>
          </div>
        </vue-plyr> -->
        <!-- <p>Link {{link}}</p> -->
        <!-- <p>mylink {{myLink}}</p> -->
        <!-- <p>vimeo spllit {{vimeoSplit}}</p> -->
        <!-- <p>new vimeo link {{newVimeoUrl}}</p> -->
        <!-- <p v-if="isMP">format is mp4</p> -->
         <video
         class="mpvideo"
         v-if="isMP"
          width='100%'
          height='auto'
          :src='link'
          autoPlay
          preLoad
          muted 
          loop
          defaultmuted
          playsinline
        />
        <iframe 
        v-if="isVimeo"
        allowfullscreen
        frameborder="0"
        showinfo="0"
        modestbranding=0
        allow="autoplay"
        width="420" height="315"
        :src="newVimeoUrl">
        </iframe>
        
        <iframe 
        v-if="isYoutube"
        allowfullscreen
        frameborder="0"
        showinfo="0"
        modestbranding=0
        allow="autoplay"
        width="420" height="315"
        :src="myLink">
        </iframe>
        <p v-if="isNonUrl">
          Please Provide a Valid Youtube, Vimeo Or MP4 Link
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroVideo",
  props: {
    link: String,
  },
  mounted(){
    this.myId = this.getVideoId(this.link)
    this.myLink = `https://www.youtube.com/embed/` + this.myId;

    // if( !this.myId &&  !this.isYoutube && !this.isVimeo && !this.isMP ){
    //      this.isNonUrl = true;
    // }
  },
  data(){
    return{ 
      myId: '',
      myLink: '',
      isMP: false,
      isVimeo: false,
      vimeoUrl: '',
      vimeoSplit: [],
      newVimeoUrl: '',
      isYoutube: false,
      isNonUrl: false,
    }
  },
  methods: {
    getVideoId(url){
    
    if(url.endsWith(".mp4")){
      this.isMP = true;
      return
    }
     //eslint-disable-next-line
    if(/^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(url)){
     this.isVimeo = true;
     this.vimeoSplit = url.split('/');
     this.newVimeoUrl = `${this.vimeoSplit[0]}//player.${this.vimeoSplit[2]}/video/${this.vimeoSplit[3]}`
      return;
      
    }
    //eslint-disable-next-line
    var i, r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    this.isYoutube = true;
    return url.match(rx)[1]; 
    // return  url.split("v=")[1]
    // return url.split('v=')
    // [1].substring(0,11);
  },
  },
};

</script>

<style scoped>
/* .plyr--video {
  width: 100% !important ;
  height: auto !important ;
  border-radius: 24px !important ;
} */
.overflow-hidden {
  overflow: hidden !important;
}
.video-frame{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.video-frame iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px
}
.video-frame .mpvideo{
  position: absolute;
  top:-300px;
  left: 0;
  /* width: 100%; */
  object-fit: cover;
  object-fit: fill;
  /* object-fit: contain; */
  /* min-height: auto; */
  border-radius: 12px;
  right: 0;
}
@media (max-width: 600px) {
  /* .plyr--video {
  width: 100% !important ;
  height: auto !important ;
  border-radius: 8px !important ;
} */
.video-frame iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px
}

  .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }
}
/* .player {
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  height: 400px;
} */
</style>
<style>
:root {
  --plyr-color-main: #0f7f12;
}
</style>
