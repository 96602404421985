<template>
  <div class="card">
    <span
      class="badge badge-danger float-right text-danger"
      v-if="job.deadline"
    >
      {{ formatDate(job.deadline) }}
    </span>

    <div class="card-icon">
      <center>
        <div class="circle c1">
          <div class="circle c2">
            <img
              class="img-profile rounded-circle avatar"
              width="50"
              :src="job.companyLogo"
              alt="company Logo"
            />
          </div>
        </div>
      </center>
    </div>
    <h2 class="fw-bold card-title" id="limited">
      {{ job.title }}
    </h2>

    <div class="card-buttons">
      <span class="badge-mute text-mute">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.12"
          height="17.105"
          viewBox="0 0 12.12 17.105"
        >
          <g
            id="Group_197"
            data-name="Group 197"
            transform="translate(-873.76 -490)"
          >
            <path
              id="Path_3"
              data-name="Path 3"
              d="M880.149,490c.214.027.429.043.641.082a5.984,5.984,0,0,1,4.693,3.827,5.709,5.709,0,0,1,.4,1.947,5.129,5.129,0,0,1-.324,1.863,17.74,17.74,0,0,1-1.44,3.069,52.77,52.77,0,0,1-3.533,5.329c-.245.33-.5.654-.751.987a.482.482,0,0,1-.049-.047,52.617,52.617,0,0,1-4.5-6.715,16.816,16.816,0,0,1-1.218-2.69,5.405,5.405,0,0,1,.569-4.705,5.9,5.9,0,0,1,4.145-2.855c.2-.037.4-.049.6-.074.041,0,.082-.013.123-.019Zm2.019,5.8a2.348,2.348,0,1,0-2.342,2.344A2.347,2.347,0,0,0,882.168,495.8Z"
            />
          </g>
        </svg>
        {{ job.city }},
        {{ fortmatCountry(job.country) }}
      </span>
    </div>

    <p class="card-text">
      {{ job.summary | str_limit(140) }}
    </p>

    <a :href="`job/${job.slug}`" class="card-footer-btn btn-success">
      {{ $t("readMore") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.811"
        height="13.874"
        viewBox="0 0 20.811 13.874"
      >
        <path
          id="Icon_awesome-eye"
          data-name="Icon awesome-eye"
          d="M20.685,10.909A11.588,11.588,0,0,0,10.405,4.5,11.59,11.59,0,0,0,.126,10.91a1.169,1.169,0,0,0,0,1.055,11.588,11.588,0,0,0,10.28,6.409,11.59,11.59,0,0,0,10.28-6.41A1.169,1.169,0,0,0,20.685,10.909Zm-10.28,5.73a5.2,5.2,0,1,1,5.2-5.2A5.2,5.2,0,0,1,10.405,16.64Zm0-8.671a3.444,3.444,0,0,0-.914.137,1.729,1.729,0,0,1-2.417,2.417,3.461,3.461,0,1,0,3.332-2.554Z"
          transform="translate(0 -4.5)"
          fill="#fff"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import moment from "moment";
export default {
  name: "JobCard",
  methods: {
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date).format("LL");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  props: {
    job: null,
  },
};
</script>

<style scoped>
.circle {
  border-radius: 100%;
  background: #f2f2f2;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c2 {
  width: 70px;
  height: 70px;
  border: 2px solid white;
}
.float-right {
  position: inherit;
  right: 10px !important;
}
.card {
  background: white;
  border: 1px solid white !important;
  border-radius: 15px;
  box-shadow: 4px 4px 4px 4px #f5f5f7;
  border: 1px solid #9b9b9b;
  padding: 20px 15px;
  position: relative;
  text-align: center;
  transition: 0.5s;
}

.card:hover {
  border: 1px solid #1ba54a !important;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1) !important;
}
.card .badge {
  position: absolute;
  right: 20px;
  /* top: 20px; */
  /* top: 0px; */
}
.badge {
  padding: 7px 20px 7px 20px;
  border-radius: 8px;
}

.card-title {
  height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  display: inline-block;
  text-transform: capitalize;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-title:first-letter {
  text-transform: uppercase;
}

.card-text {
  height: 100px !important;
  font-weight: normal !important;
  text-align: left !important;
  margin-bottom: 15px;
}
.badge-success {
  background: #d4f2e7;
}
.badge-danger {
  background: #ffeee9;
}
.text-success {
  color: #00b074;
  font-weight: bold;
}

.text-danger {
  color: #fa582c;
  font-weight: bold;
}

.fill-success {
  fill: #00b074;
  stroke: #00b074;
}
.fill-danger {
  fill: #fa582c !important;
  stroke: #fa582c;
}
p {
  color: #4c4c4c;
  font-weight: bold;
  line-height: 25px;
}
.fw-bold {
  font-weight: bold !important;
}
.card .card-buttons {
  width: 100%;
  margin-bottom: 10px;
}

.badge-info {
  width: 100%;
  display: inline-block;
  background: #dfeff7;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  font-size: 17px;
  margin-top: 20px;
  font-weight: 600;
}

.text-info {
  color: #129ceca6 !important;
}

.badge-info svg {
  width: 10px;
  fill: #129cec71 !important;
  stroke: #129cecf6 !important;
}

.badge-mute {
  width: auto;
  display: inline-block;
  background: #90909048;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  margin-top: 15px;
  margin-bottom: 0px !important;
  font-weight: 600;
  max-width: 100% !important;
}

.text-mute {
  color: #a0a0a0 !important;
}

.badge-mute svg {
  width: 7px;
  fill: #a0a0a0c0 !important;
  stroke: #a0a0a0 !important;
}

button {
  cursor: pointer;
}

.btn-success {
  background: #cfead9;
  padding: 0.5rem;
  color: #1ba54b;
  border-radius: 1rem;
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;
}

.btn-success svg {
  fill: #00b074 !important;
  stroke: #00b074 !important;
}

.btn-success:hover {
  color: #fff;
  background: #1ba54a;
}
.text-center {
  text-align: center;
}
.btn-see-all {
  background: none;
  border: none;
  font-size: 17px;
}

@media (max-width: 600px) {
  .card {
    padding: 60px 20px 40px 20px !important;
  }
  .card .badge {
    right: 12px;
    top: 15px;
  }
  .badge {
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
  }
  .card-footer-btn {
    width: 100%;
    border-radius: 10px;
  }
  .cards-grid {
    grid-gap: 2rem;
  }
}
@media (min-width: 600px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
