<template>
  <div class="btn-add-more btn">
    <!-- <span class="tag-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 16 16"
      >
        <path
          d="M14.857,38.286H9.714V33.143A1.143,1.143,0,0,0,8.571,32H7.429a1.143,1.143,0,0,0-1.143,1.143v5.143H1.143A1.143,1.143,0,0,0,0,39.429v1.143a1.143,1.143,0,0,0,1.143,1.143H6.286v5.143A1.143,1.143,0,0,0,7.429,48H8.571a1.143,1.143,0,0,0,1.143-1.143V41.714h5.143A1.143,1.143,0,0,0,16,40.571V39.429A1.143,1.143,0,0,0,14.857,38.286Z"
          transform="translate(0 -32)"
          fill="#8c8c8c"
        />
      </svg>
    </span> -->
    <span>{{ $t("Add") }}</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn-add-more svg {
  width: 11px !important;
  height: 11px !important;
}
.btn-add-more {
  display: none;
  width: 100%;
  height: 36px;
  color: rgb(140, 140, 140);
  font-size: 14.5px;
  border: none;
  text-align: center;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  .edit-profile & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tag-icon {
    position: absolute;
    line-height: 0;
    transform: translateX(-50px);
  }

  &:hover {
    background-color: var(--green-color);
    color: #fff;

    path {
      fill: #fff;
    }
  }
}
</style>
