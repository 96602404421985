<template>
  <div v-if="editor" class="editor-wrapper">
    <!-- <floating-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor"> -->
    <button
      @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
    >
      <Icon><H1 /></Icon>
    </button>
    <!-- <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
      <Icon><H2/></Icon>
    </button> -->
    <!-- <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
     <Icon><H3/></Icon>
    </button> -->
    <button
      @click="editor.chain().focus().toggleBulletList().run()"
      :class="{ 'is-active': editor.isActive('bulletList') }"
    >
      <Icon>
        <List />
      </Icon>
    </button>
    <button
      @click="editor.chain().focus().toggleOrderedList().run()"
      :class="{ 'is-active': editor.isActive('orderedList') }"
    >
      <Icon>
        <ListNumbers />
      </Icon>
    </button>
    <button
      @click="editor.chain().focus().toggleBold().run()"
      :class="{ 'is-active': editor.isActive('bold') }"
    >
      <Icon>
        <BoldIcon />
      </Icon>
    </button>
    <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
      <Icon>
        <LinkIcon />
      </Icon>
    </button>
    <button
      @click="editor.chain().focus().unsetLink().run()"
      :disabled="!editor.isActive('link')"
    >
      <Icon>
        <Unlink />
      </Icon>
    </button>
    <!-- </floating-menu> -->
    <editor-content :editor="editor" :id="id" />
    <br />
    <p class="footer-tip">
      Chars: {{ editor.storage.characterCount.characters() }}
    </p>
    <!-- {{ editor.storage.characterCount.words() }} words -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import { Editor, EditorContent, FloatingMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CharacterCount from "@tiptap/extension-character-count";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import Heading from "@tiptap/extension-heading";

//icons
import { Unlink, List, H1, H2, H3, ListNumbers } from "@v2icons/tabler";
import { Link as LinkIcon } from "@v2icons/tabler";
import { Bold as BoldIcon } from "@v2icons/tabler";
import { Icon } from "@v2icons/utils";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Editor",
  props: {
    text: String,
    id: String,
  },
  components: {
    EditorContent,
    // FloatingMenu,
    Unlink,
    List,
    LinkIcon,
    BoldIcon,
    H1,
    // H2,
    // H3,
    ListNumbers,
  },
  watch: {
    value() {
      let html = this.editor.getHTML();
      if (html !== this.value) {
        this.editor.setContent(this.value);
      }
    },
    text() {
      this.editor.setContent(this.text);
    },
  },
  // watch: {
  //   text() {
  //     this.editor.setContent(this.text);
  //   },
  // },
  data() {
    return {
      editor: null,
      limit: 1200, // set minimun not limit,
      editorChange: false,
    };
  },

  mounted() {
    const that = this;
    //alert(this.text);
    this.editor = new Editor({
      content: this.text,
      autofocus: true,
      editable: true,
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Text,
        BulletList,
        OrderedList,
        ListItem,
        Bold,
        Heading.configure({
          levels: [1],
        }),
        CharacterCount.configure({
          limit: this.limit,
        }),
        Link.configure({
          openOnClick: false,
        }),
      ],
      onBlur({ editor, event }) {
        const html = editor.getHTML();
        that.$store.commit("setTractions", { traction: html });
      },
      onUpdate({ editor }) {
        var element = document.getElementById("inner-container");
        element.scrollTop = element.scrollHeight - element.clientHeight;
        // The content has changed.
        const json = editor.getJSON();
        const html = editor.getHTML();
       // alert(html)
        //console.log({ editor });
        that.$store.commit("setTractions", { traction: html });
        // console.log("content updated", html)
      },
    });
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    ...mapMutations(["setTractions"]),
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
/* .has-focus {
  border-radius: 3px;
  box-shadow: 0 0 0 3px #68cef8;
 min-height: 100%;
} */


/* .ProseMirror:focus{
  border: 1px solid rgba(0, 128, 0, 0.358);
  min-height: 100px;
} */
.ProseMirror {
  > * + * {
    /* margin-top: 0.75em; */
    margin: 0;
    padding-top: 0;
  }
  p {
    padding-top: 2px;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }

  ul,
  ol {
    padding: 0 1rem;
  }
  li {
    list-style: square;
  }
  ol li {
    list-style: unset;
  }
  a {
    color: #68cef8;
  }
  h1,
  h2,
  h3 {
    width: 100%;
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    color: black;
  }
  .character-count {
    margin-top: 1rem;
    color: #868e96;
  }
}
</style>
<style scoped>
.editor-wrapper {
  position: relative;
}
.footer-tip {
  position: absolute;
  /* bottom: 0;
    left: 0; */
  top: -40px;
  right: 0;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border: 1px solid rgba(0, 128, 0, 0.358);
  padding: 2px 8px;
  border-radius: 20px;
  display: block;
  color: rgba(0, 128, 0, 0.8);
}
button {
  margin: 0 2px !important;
  border: none;
  padding: 0 4px;
  border-radius: 50%;
}
button.is-active {
  color: #329e4d;
  background-color: #329e4d50;
}
</style>
