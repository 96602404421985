<template>
  <div class="#">
    <div class="#" v-if="!loading">
      <div class="container">
        <NewsHeader :post="post" />
        <div class="row">
          <div class="col-md-10 mx-auto">
            <section class="view container-lg">
              <main class="view-main my-3">
                <p class="vm-para" v-html="$sanitize(post.fullText)"></p>
                <div class="card-footer">
                  <div class="#">
                    <span class="mr-2">{{ post.sourceName }}</span>
                    <a
                      :href="post.source"
                      target="_blank"
                      class="btn view-source"
                    >
                      View Source <i class="feather-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </main>
              <SideNews />
            </section>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import Axios from "axios";
import APPURL from "../../constant";
import i18n from "../../i18n";
import NewsHeader from "../../components/News/NewsHeader.vue";
import SideNews from "../../components/News/SideNews.vue";
import RelatedNews from "../../components/News/RelatedNews.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  async mounted() {
    await this.getNews();
  },
  computed: {
    ...mapGetters(["authUser", "getSimilarNews"]),
  },
  methods: {
    ...mapActions(["loadNews", "loadSimilarNews"]),
    readingTime(text) {
      let minutes = 0;
      const contentString = JSON.stringify(text);
      const words = contentString.split(" ").length;
      const wordsPerMinute = 200;
      minutes = Math.ceil(words / wordsPerMinute);
      return minutes;
    },
    async getNews() {
      var slug = `${this.$route.params.slug}`;
      var request = APPURL.NEWS + "slug/" + slug;
      Axios.get(request)
        .then(async (response) => {
          this.post = response.data.news;
          let pagination = this.readingTime(this.post.fullText);
          await this.loadSimilarNews(pagination);
          this.loading = false;
         
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    async loadSimilarNews(pagination) {
      Axios.get(
        APPURL.NEWS +
          "load-similar-news/take/" +
          (pagination ? pagination * 3 : 9)
      )
        .then((response) => {
          this.similarNews = response.data.news;
          this.$store.commit("setSimilarNews", this.similarNews);
          
          this.loading = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  data() {
    return {
      loading: true,
      users: {},
      counts: {},
      post: {},
      similarNews: [],
    };
  },
  name: "SingleNews",
  components: {
    NewsHeader,
    SideNews,
  },
};
</script>
<style >
.view.container-lg {
  display: flex !important;
  flex-flow: row !important;
  justify-content: space-between !important;
  align-items: stretch  !important;


  padding: 0px calc((100% - 990px)/2);
}

.container {
  max-width: var(--container-size);
  margin: auto;
}
.container-lg {
  margin-left: auto !important;
  margin-right: auto !important;
  width: var(--container-lg-size);
  max-width: 100%;
}

.background {
  background-color: var(--theme-background);
}
.background-white {
  background-color: #fff;
}

/* Spaces */
/* @- for Containers & Cards  */

.space-one {
  padding: var(--space-one);
}

.space-one-top-bottom {
  padding-top: var(--space-one);
  padding-bottom: var(--space-one);
}

/* Alignments & Positions */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}

/* Typography */
/* @- General Purpsoe */
.capital {
  text-transform: capitalize;
}
.upper {
  text-transform: uppercase;
}

/* Elevations */
.shadow-small {
  box-shadow: 0 0 calc(3 * var(--elements-unit));
}

.over-hidden {
  overflow: hidden;
}

.small-mobile-display-only {
  display: none !important;
}
.mobile-display-only {
  display: none !important;
}
.tab-display-only {
  display: none !important;
}

@media screen and (max-width: 950px) {
  .tab-display-none {
    display: none !important;
  }

  .tab-display-only {
    display: block !important;
  }
}
@media screen and (max-width: 750px) {
  .container.space-one {
    padding: 10px;
  }

  .mobile-no-background {
    background-color: #fff;
  }

  .container-mobile {
    width: 80%;
    margin: auto;
  }

  .card-mobile-size {
    max-width: 400px;
  }

  .mobile-display-none {
    display: none !important;
  }

  .mobile-display-only {
    display: block !important;
  }

  .small-mobile-display-only {
    display: none;
  }
  .view-main {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 500px) {
  .container-mobile {
    width: 100%;
  }

  .small-mobile-display-none {
    display: none !important;
  }

  .small-mobile-display-only {
    display: block !important;
  }
  .view-main {
    margin-bottom: 80px !important;
  }
}
</style>

<style scoped>
.view-main {
  flex-basis: 500px !important;
  flex-grow: 1 !important;
  display: flex !important;
  flex-flow: column !important;
  align-items: stretch !important;
  justify-content: flex-start !important;
  padding: 20px !important;
  border-radius: 1rem;
  box-shadow: 1px 1px 4px 3px #8888884f;
}

.view-main > * + * {
  margin-top: 10px !important;
}

.view-main img {
  max-width: 100% !important;
}

.vm-para {
  font-size: 18px !important;
  margin-bottom: 20px !important;
}
.card-footer {
  position: relative;
  background: #cecfce52 !important;
  border-radius: 1rem;
  border: none !important;
}
.view-source {
  background: #cfead9;
  padding: 5px 20px;
  color: #717b83;
  border-radius: 1rem;
  font-size: 1rem;
}
.view-source:hover {
  color: #fff !important;
  background-color: var(--first-color);
}
/* Related */

.related {
  padding: 40px;
  box-sizing: border-box;
}
.related-title {
  color: #333;
  font-size: 25px;

  border-bottom: 1px solid #777;
}
</style>

