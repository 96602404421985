<template>
  <div class="input-email">
    <label class="label">{{ $t(label) }}</label>
    <div class="control">
      <input :value="value" type="email" @input="input" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputEmail",
  props: {
    value: {},
    label: {},
  },
  methods: {
    input(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style scoped>
.control {
  position: relative;
  margin: 0 auto;
}

.input-email {
  font-size: 20px;
  text-align: left;
}

.label {
  margin: 10px 0;
}

input {
  font-weight: bold;
  padding-left: 10px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid #aaaaaa;
  width: 100%;
}

.button {
  background: none;
  border: none;
  border-radius: 50%;
  left: 150px;
  bottom: 1px;
}

button:focus {
  border: none;
  outline: none;
}

.valid input {
  /* background: #e4f4e9 no-repeat padding-box; */
  border: 1px solid #349e4d;
}

.invalid input {
  /* background: #f5d6d6 no-repeat padding-box; */
  border: 1px solid #f80000;
}

.form {
  width: 50px;
  height: 200px;
}
</style>
