<template>
  <div>
    <div class="chat-loader">
      <div class="centered">
        <i class="fa fa-3x fa-spin fa-spinner"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatLoader",
  props: {
    user: Object,
    selected: Boolean,
  },
};
</script>

<style scoped>
.centered {
  position: absolute;
  top: 30%;
  left: 40%;
  color: #929191;
}
.chat-loader {
  position: absolute;
  width: 100%;
  z-index: 105000;
  background-color: #f3f3f3;
  height: 400px;
}
</style>
