<template>
  <div class="btn-labeled-edit d-flex">
    <div class="icon pen-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12.19 12.19"
      >
        <path
          d="M6.912,2.227,9.96,5.275,3.341,11.894l-2.718.3a.571.571,0,0,1-.631-.631L.3,8.843,6.912,2.227Zm4.933-.454L10.414.342A1.143,1.143,0,0,0,8.8.342L7.45,1.689,10.5,4.737,11.845,3.39a1.143,1.143,0,0,0,0-1.617Z"
          transform="translate(0.011 -0.008)"
          fill="#8c8c8c"
        />
      </svg>
    </div>
    <p class="label d-flex align-items-center">
      <span>{{ $t(text) }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "edit",
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-labeled-edit {
  display: none;
  width: auto !important; 
  padding-left: 10px !important;
  background: rgba(140, 140, 140, 0.22);
  color: rgb(114, 114, 114);
  border-radius: 5px;

  .edit-profile & {
    display: flex;
  }

  &:hover {
    background-color: rgba(140, 140, 140, 0.42);
    cursor: pointer;
  }

  .icon {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pen-icon {
    border-radius: 13px;
    background: rgba(255, 255, 255, 0.19);
  }

  .pen-icon svg,
  .ellipsis-icon svg {
    height: 11px !important;
    width: 11px !important;
  }
  .label {
    padding: 0 7.5px 0 13px;
    font-size: 16px !important;
    color: #727272;
    @include max-width(415) {
      display: none;
    }
  }

  .ellipsis-icon {
    @include max-width(415) {
      display: none;
    }
  }
}
</style>
