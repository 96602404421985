import CreateNews from '../views/admin/News/Create';
import CreateJobs from '../views/admin/Jobs/Create';

export default [

  {
    path: "admin/upload-news",
    name: "CreateNewsEn",
    component: CreateNews,

  },
  {
    path: "admin/upload-news",
    name: "CreateNewsFr",
    component: CreateNews,

  },
  {
    path: "admin/upload-jobs",
    name: "CreateJobsEn",
    component: CreateJobs,

  },
  {
    path: "admin/upload-jobs",
    name: "CreateJobsFr",
    component: CreateJobs,

  },
];
