<template>
  <div class="partnership-card">
    <span class="badge badge-danger float-right text-danger" v-if="!active">
      {{ $t("closed") }}
    </span>
    <span class="badge badge-success float-right text-success" v-if="active">
      {{ $t("open") }}
    </span>
    <div class="partnership-card-img">
      <template v-if="imgSrc">
        <img :alt="title" :src="imgSrc" />
      </template>
      <template v-else>
        <img :alt="title" src="./../../assets/images/partnerships/no-img.png" />
      </template>
    </div>
    <div class="partnership-card-bottom">
      <h3 class="partnership-card-title">{{ title }}</h3>
      <p class="partnership-card-text">{{ shortDescription }}</p>
      <button
        class="partnership-card-btn"
        type="button"
        @click="$emit('view-offer')"
      >
        {{ $t("View offer") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnershipCard",
  computed: {
    shortDescription() {
      return document
        .createRange()
        .createContextualFragment(this.description)
        .textContent.substring(0, 90);
    },
  },
  props: {
    active: {
      type: Number,
    },
    imgSrc: {
      type: [String, null],
      default: null,
    },
    title: {
      type: String,
      default: "test title",
    },
    description: {
      type: String,
      default: "test description",
    },
  },
};
</script>

<style lang="scss" scoped>

.badge {
  top: 5px;
  right: 30px;
  padding: 7px 20px 7px 20px;
  border-radius: 8px;
  z-index: 10000;
}

.badge-success {
  background: #d4f2e7;
}
.badge-danger {
  background: #ffeee9;
}
.text-success {
  color: #00b074;
  font-weight: bold;
}

.text-danger {
  color: #fa582c;
  font-weight: bold;
}

.fill-success {
  fill: #00b074;
  stroke: #00b074;
}
.fill-danger {
  fill: #fa582c !important;
  stroke: #fa582c;
}

.partnership-card-img {
  overflow: hidden;
}
.partnership-card {
  color: var(--text-common-color);
  //   border: 1px solid #03030342;
  background-color: #ffffff;
  border-radius: 15px;
  opacity: 1;
  margin-bottom: 20px;
  overflow: hidden;
  transition: 0.2s;

  &:hover {
    box-shadow: 0 10px 10px #00000029;
  }

  &-img {
    text-align: center;
    overflow-y: hidden;
    min-height: 130px;
    position: relative;
  }

  &-img img {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }

  &-bottom {
    padding: 25px 20px 30px;
    text-align: center;
  }

  &-title {
    font: normal normal bold 18px/22px Helvetica;
    margin-bottom: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
  }

  &-text {
    min-height: 40px;
    max-height: 40px;
    overflow: hidden;
    margin-bottom: 39px;
    font: normal normal normal 16px/19px Helvetica;
    text-align: center !important;
    font-size: 16px;
    line-height: 1.4;
  }

  &-btn {
    font: normal normal bold 18px/22px Helvetica;
    padding: 12px 10px;
    max-width: 208px;
    width: 100%;
    background-color: #ffffff;
    border: 2px solid var(--first-color);
    border-radius: 15px;
  }

  &-btn:hover {
    box-shadow: 0 3px 6px #00000029;
    background-color: var(--first-color);
    color: #ffffff;
  }
}
</style>
