<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="card">
    <div class="card-header">
      <div
        class="card-image"
        :class="{ skeletonLoader: !avatar_isLoaded }"
        :style="bgColorCss()"
      >
        <!-- <cld-image
          class="img-profile rounded-circle avatar"
          cloudName:bantaba
          :publicId="user.avatar"
          alt=""
        >
          <cld-transformation height="100" width="100" />
          <cld-transformation border="5px_solid_red" radius="20" />
        </cld-image> -->
        <!-- <div> avatar_image:  {{ avatar_image }}</div> -->
        <img
          class="img-profile rounded-circle avatar"
          :src="avatar_image"
          @load="onImgLoad"
          alt=""
        />
      </div>
    </div>
    <div class="card-body">
      <div class="card-name">
        <p>{{ user.firstname }} {{ user.name }}</p>
      </div>
      <div class="card-buttons">
        <a class="card-btn messenger-button" @click="openChatBoxWithUser(user)">
          <span>Message</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28.44 25"
          >
            <path
              id="messenger"
              data-name="messenger"
              d="M13.714,32C6.139,32,0,36.987,0,43.143a9.89,9.89,0,0,0,3.054,7A13.629,13.629,0,0,1,.118,55.277a.426.426,0,0,0-.08.466A.42.42,0,0,0,.429,56a12.251,12.251,0,0,0,7.532-2.754,16.338,16.338,0,0,0,5.754,1.039c7.575,0,13.714-4.987,13.714-11.143S21.289,32,13.714,32ZM6.857,44.857a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,6.857,44.857Zm6.857,0a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,13.714,44.857Zm6.857,0a1.714,1.714,0,1,1,1.714-1.714A1.712,1.712,0,0,1,20.571,44.857Z"
              transform="translate(0.512 -31.5)"
              fill="#1BA54B"
              stroke="rgba(112,112,112,0.18)"
              stroke-width="1"
            />
          </svg>
        </a>
        <a
          class="card-btn profile-button"
          :href="`/${$i18n.locale}/profile/${user.username}`"
        >
          <span>{{ $t("View Profile") }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              id="profile"
              data-name="profile"
              d="M12,13.5A6.75,6.75,0,1,0,5.25,6.75,6.752,6.752,0,0,0,12,13.5ZM18,15H15.417a8.16,8.16,0,0,1-6.834,0H6a6,6,0,0,0-6,6v.75A2.251,2.251,0,0,0,2.25,24h19.5A2.251,2.251,0,0,0,24,21.75V21A6,6,0,0,0,18,15Z"
              fill="rgba(105,105,105,0.92)"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    bgColor: String,
    user: Object,
  },
  mounted() {
    let url = this.user ? this.user.avatar : "";
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // this.avatar_image = first_part + "/q_auto:low/c_fill,h_250,w_250/" + last_part;
      this.avatar_image =
        first_part + "/q_auto:low/c_fill,h_50,w_50/" + last_part;
    } else {
      this.avatar_image = this.user ? this.user.avatar : "";
    }
  },
  data() {
    return {
      avatar_image: "",
      avatar_isLoaded: false,
      sockets: {},
    };
  },
  methods: {
    ...mapActions(["openChatBoxWithUser"]),
    bgColorCss: function () {
      return {
        background: this.bgColor,
      };
    },
    onImgLoad() {
      this.avatar_isLoaded = true;
    },
    
  },
   watch: { 
     user: function(newUser, oldUser) { 
       let url = newUser ? newUser.avatar : "";
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // this.avatar_image = first_part + "/q_auto:low/c_fill,h_250,w_250/" + last_part;
      this.avatar_image =
        first_part + "/q_auto:low/c_fill,h_50,w_50/" + last_part;
    } else {
      this.avatar_image = newUser? newUser.avatar : "";
    }
   }
  },
};
</script>

<style scoped>
svg {
  width: 1.125rem;
  height: 1.125rem;
}

.card-body {
  padding: 0rem;
}
.card {
  margin-top: 1rem;
  border: 1px solid #e4ece8;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 0.7rem !important;
  margin-top: 0.5rem;
}

.card:hover {
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
}

.card-header {
  height: 9rem;
  background: rgba(24, 165, 75, 0.35);
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
  z-index: 3;
  position: relative;
  border-top-right-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
  height: 4rem !important;
}
.card-header::before {
  content: "";
  display: block;
  position: absolute;
  background: url("../../assets/images/bubble.png") repeat-x;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.card-header .card-image {
  width: 7rem;
  height: 7rem;
  border-radius: 4rem;
  position: relative;
  left: 50%;
  transform: translate(-50%, 2.25rem);
  border: 0.4rem solid #fff;

  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  font-size: 3.3rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 5rem;
  height: 5rem;
  left: 0 auto;
  transform: translate(-50%, 2.25rem);
  /* box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%) !important; */
  box-shadow: 0 0 2px 1px #1ca54c3c;
  background-color: #eee !important;
}

.skeletonLoader {
  width: 5rem;
  height: 5rem;
  background-color: #e4e4e4 !important;
}

.skeletonLoader img {
  display: none !important;
}
.img-profile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-name {
  height: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1.05rem;
  color: black !important;
}

.card-name p {
  color: black !important;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  justify-content: center !important;
  align-items: center !important;
}
.card-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  margin-left: 3.5%;
  margin-right: 3.5%;
  border: 1px solid #1ba54b;
  border-radius: 0.8rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.8rem;
}
.card-btn:hover {
  background: #1ba54b;
  color: #fff;
}
.card-btn:hover path {
  fill: #fff;
}
.messenger-button {
  color: #1ba54b;
}
.profile-button {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  background: #f6f6f6;
  color: #707070;
}

@media (max-width: 991px) {
  .card-name {
    font-size: 1.5rem;
  }
  .card-btn {
    font-size: 1.3rem;
  }
}
@media (max-width: 600px) {
  .card-name {
    font-size: 1rem;
  }
  .card-btn {
    font-size: 1.3rem;
  }
}
@media (min-width: 320px) {
  .card {
    -webkit-box-shadow: 0 5px 50px rgb(0 0 0 / 10%);
    box-shadow: 0 5px 50px rgb(0 0 0 / 10%);
    border-radius: 1.4rem;
    overflow: hidden;
    position: relative;
  }
  .card-header {
    height: 6rem;
    background: rgba(24, 165, 75, 0.35);
    /* border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0; */
    z-index: 3;
    position: relative;
  }
  .card-header .card-image {
    border-radius: 4rem;
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, 2.25rem);
    transform: translate(-50%, 1rem);
  }
  .card-header {
    height: 5rem;
    background: rgba(24, 165, 75, 0.35);
    /* border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0; */
    /* border-radius: 0 0 40px 40px; */
  }
  .card-name {
    /* height: 6rem;
    padding-top: 3.4rem; */

    height: 5rem;
    padding-top: 2.4rem;
  }
  .card-buttons {
    margin-top: 10px;
  }
  .card-btn {
    margin-left: 5.5%;
    margin-right: 5.5%;
    border: 1px solid #1ba54b;
    border-radius: 0.8rem;
    border-radius: 1.5rem;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    font-size: 0.8rem;
  }
}
@media (max-width: 600px) {
  .card {
    margin-top: 0.4rem;
    border-radius: 1.4rem;
  }
  .card-col {
    width: 100%;
    background: #e7e9e8;
  }
}
</style>