<template>
  <div class="#">
    <div class="py-5 bg-success">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <h1 class="text-white font-weight-light">
              <span class="font-weight-bold">Bantaba</span> Terms &amp;
              conditions
            </h1>
            <p class="mb-0">Effective date: 1 January 2020</p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="bg-white p-5 shadow-sm box border rounded">
              <div id="intro" class="mb-4">
                <!-- Title -->
                <div class="mb-3">
                  <h2 class="h5 text-primary">Introduction</h2>
                </div>
                <!-- End Title -->
                <p class="text-justify">
                  Thanks for using our products and services ("Services"). The
                  Services are provided by Pixeel Ltd. ("Osahanin"), located at
                  153 Williamson Plaza, Maggieberg, MT 09514, England, United
                  Kingdom.
                </p>
                <p class="text-justify">
                  By using our Services, you are agreeing to these terms. Please
                  read them carefully.
                </p>
                <p class="text-justify">
                  Our Services are very diverse, so sometimes additional terms
                  or product requirements (including age requirements) may
                  apply. Additional terms will be available with the relevant
                  Services, and those additional terms become part of your
                  agreement with us if you use those Services.
                </p>
              </div>
              <div id="services" class="mb-4">
                <!-- Title -->
                <div class="mb-3">
                  <h3 class="h5 text-primary">1. Using our services</h3>
                </div>
                <!-- End Title -->
                <p class="text-justify">
                  You must follow any policies made available to you within the
                  Services.
                </p>
                <p class="text-justify">
                  Don't misuse our Services. For example, don't interfere with
                  our Services or try to access them using a method other than
                  the interface and the instructions that we provide. You may
                  use our Services only as permitted by law, including
                  applicable export and re-export control laws and regulations.
                  We may suspend or stop providing our Services to you if you do
                  not comply with our terms or policies or if we are
                  investigating suspected misconduct.
                </p>
                <p class="text-justify">
                  Using our Services does not give you ownership of any
                  intellectual property rights in our Services or the content
                  you access. You may not use content from our Services unless
                  you obtain permission from its owner or are otherwise
                  permitted by law. These terms do not grant you the right to
                  use any branding or logos used in our Services. Don't remove,
                  obscure, or alter any legal notices displayed in or along with
                  our Services.
                </p>
                <!-- Title -->
                <div id="personal-data" class="mb-3 active">
                  <h4 class="h6 text-primary">
                    A. Personal Data that we collect about you.
                  </h4>
                </div>
                <!-- End Title -->
                <p class="text-justify">
                  Personal Data is any information that relates to an identified
                  or identifiable individual. The Personal Data that you provide
                  directly to us through our Sites will be apparent from the
                  context in which you provide the data. In particular:
                </p>
                <ul class="text-secondary">
                  <li class="pb-2">
                    When you register for a Osahanin account we collect your
                    full name, email address, and account log-in credentials.
                  </li>
                  <li class="pb-2">
                    When you fill-in our online form to contact our sales team,
                    we collect your full name, work email, country, and anything
                    else you tell us about your project, needs and timeline.
                  </li>
                  <li class="pb-2">
                    When you use the "Remember Me" feature of Osahanin Checkout,
                    we collect your email address, payment card number, CVC code
                    and expiration date.
                  </li>
                </ul>
                <p class="text-justify">
                  When you respond to Osahanin emails or surveys we collect your
                  email address, name and any other information you choose to
                  include in the body of your email or responses. If you contact
                  us by phone, we will collect the phone number you use to call
                  Osahanin. If you contact us by phone as a Osahanin User, we
                  may collect additional information in order to verify your
                  identity.
                </p>
                <!-- Title -->
                <div id="information" class="mb-3 active">
                  <h4 class="h6 text-primary">
                    B. Information that we collect automatically on our Sites.
                  </h4>
                </div>
                <!-- End Title -->
                <p class="text-justify">
                  We also may collect information about your online activities
                  on websites and connected devices over time and across
                  third-party websites, devices, apps and other online features
                  and services. We use Google Analytics on our Sites to help us
                  analyze Your use of our Sites and diagnose technical issues.
                </p>
                <p class="text-justify">
                  To learn more about the cookies that may be served through our
                  Sites and how You can control our use of cookies and
                  third-party analytics, please see our Cookie Policy.
                </p>
              </div>
              <div id="privacy" class="mb-4">
                <!-- Title -->
                <div class="mb-3">
                  <h3 class="h5 text-primary">
                    2. Privacy and copyright protection
                  </h3>
                </div>
                <!-- End Title -->
                <p class="text-justify">
                  Osahanin's privacy policies explain how we treat your personal
                  data and protect your privacy when you use our Services. By
                  using our Services, you agree that Osahanin can use such data
                  in accordance with our privacy policies.
                </p>
                <p class="text-justify">
                  We respond to notices of alleged copyright infringement and
                  terminate accounts of repeat infringers according to the
                  process set out in the U.S. Digital Millennium Copyright Act.
                </p>
                <p class="text-justify">
                  We provide information to help copyright holders manage their
                  intellectual property online. If you think somebody is
                  violating your copyrights and want to notify us, you can find
                  information about submitting notices and Osahanin's policy
                  about responding to notices in
                  <a href="help.html">our Help Center</a>.
                </p>
              </div>
              <div id="yourContent" class="active">
                <!-- Title -->
                <div class="mb-3">
                  <h3 class="h5 text-primary">
                    3. Your content in our services
                  </h3>
                </div>
                <!-- End Title -->
                <p class="text-justify">
                  Some of our Services allow you to upload, submit, store, send
                  or receive content. You retain ownership of any intellectual
                  property rights that you hold in that content. In short, what
                  belongs to you stays yours.
                </p>
                <p class="text-justify">
                  When you upload, submit, store, send or receive content to or
                  through our Services, you give Osahanin (and those we work
                  with) a worldwide license to use, host, store, reproduce,
                  modify, create derivative works (such as those resulting from
                  translations, adaptations or other changes we make so that
                  your content works better with our Services), communicate,
                  publish, publicly perform, publicly display and distribute
                  such content. The rights you grant in this license are for the
                  limited purpose of operating, promoting, and improving our
                  Services, and to develop new ones. This license continues even
                  if you stop using our Services (for example, for a business
                  listing you have added to Osahanin Maps). Some Services may
                  offer you ways to access and remove content that has been
                  provided to that Service. Also, in some of our Services, there
                  are terms or settings that narrow the scope of our use of the
                  content submitted in those Services. Make sure you have the
                  necessary rights to grant us this license for any content that
                  you submit to our Services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "Bantaba | Terms ",
    };
  },
  mounted() {},
  computed: {},
  methods: {},
  data() {
    return {};
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Terms",
};
</script>
