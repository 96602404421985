<template>
  <form @submit.prevent="createEducation" class="add-education form">
    <div class="control">
      <label class="form-label"
        >{{ $t("fieldOfStudy") }} <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        class="profile-controls"
        :placeholder="$t('title')"
        v-model="form.title"
        required
      />
    </div>
    <div class="control">
      <label class="form-label"
        >{{ $t("university") }}
        <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        class="profile-controls"
        :placeholder="$t('university')"
        v-model="form.university"
        required
      />
    </div>
    <div class="control">
      <label class="form-label"
        >{{ $t("level") }}
        <span class="text-danger">*</span>
      </label>
      <select
        class="profile-controls my-1 mr-sm-2"
        v-model="form.level"
        required
      >
        <option value="" disabled>{{ $t("choose") }}...</option>
        <option value="Bachelor">{{ $t("Bachelor") }}</option>
        <option value="Master">Master</option>
        <option value="Licence">Licence</option>
        <option value="Doctorat">{{ $t("Doctorat") }}</option>
        <option value="Post-Doc">{{ $t("Post-Doc") }}</option>
        <option value="Other">{{ $t("Other") }}</option>
      </select>
    </div>
    <div class="control">
      <label class="form-label"
        >{{ $t("currently") }}
        <span class="text-danger">*</span>
      </label>
      <select
        class="profile-controls my-1 mr-sm-2"
        v-model="form.currently"
        required
        @change="setEndDate"
      >
        <option value="" disabled>{{ $t("choose") }}...</option>
        <option value="1">{{ $t("yes") }}</option>
        <option value="0">{{ $t("no") }}</option>
      </select>
    </div>
    <div class="control w-100">
      <label class="form-label"
        >{{ $t("country") }}
        <span class="text-danger">*</span>
      </label>
      <country-select
        v-model="form.country"
        :required="true"
        :countryName="true"
        :autocomplete="true"
        :disablePlaceholder="true"
        :usei18n="true"
        :country="fortmatCountry(form.country)"
        class="profile-controls"
        :placeholder="$t('choose')"
      />
    </div>
    <!-- <div class="control">
      <label class="form-label">{{ $t("city") }} </label>
      <input
        type="text"
        class="profile-controls"
        :placeholder="$t('city')"
        v-model="form.city"
      />
    </div> -->
    <div class="control">
      <label class="form-label"
        >{{ $t("from") }}
        <span class="text-danger">*</span>
      </label>
      <Datepicker
        v-if="$i18n.locale === 'fr'"
        v-model="form.startDate"
        :language="fr"
        :clear-button="false"
        input-class="form-control profile-controls"
        :required="true"
        :typeable="false"
        minimum-view="month"
        format="MM/yyyy"
        :placeholder="$t('from')"
      />
      <Datepicker
        v-else
        v-model="form.startDate"
        :language="en"
        input-class="form-control profile-controls"
        :clear-button="false"
        :required="true"
        :typeable="false"
        minimum-view="month"
        format="MM/yyyy"
        :placeholder="$t('from')"
      />
    </div>
    <div class="control">
      <label class="form-label"
        >{{ $t("to") }} (or expected)
        <span class="text-danger">*</span>
      </label>
      <div v-if="!selectedNow">
        <Datepicker
          v-if="$i18n.locale === 'fr'"
          v-model="form.endDate"
          :language="fr"
          :clear-button="false"
          input-class="form-control profile-controls"
          :required="true"
          :typeable="false"
          minimum-view="month"
          format="MM/yyyy"
          :placeholder="$t('to')"
        />
        <Datepicker
          v-else
          v-model="form.endDate"
          :language="en"
          input-class="form-control profile-controls"
          :clear-button="false"
          :required="true"
          :typeable="false"
          minimum-view="month"
          format="MM/yyyy"
          :placeholder="$t('to')"
        />
      </div>
      <div v-else>
        <input
          type="text"
          class="profile-controls"
          v-model="form.endDate"
          disabled
        />
      </div>
    </div>
    <div class="w-100">
      <label>Description </label>
      <textarea
        class="profile-controls"
        rows="4"
        placeholder="Describe your position"
        name="description"
        v-model="form.description"
        spellcheck="true"
      ></textarea>
    </div>
    <div class="w-100">
      <div class="d-flex">
        <button :disabled="loading" type="submit" class="btn-save">
          {{ $t("save") }}
        </button>
        <button
          :disabled="loading"
          @click="cancel()"
          type="button"
          class="btn-cancel"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import LabeledSaveCancelButtonGroup from "../buttons/LabeledSaveCancelButtonGroup";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["authUser", "getProfile"]),
  },
  components: {
    //LabeledSaveCancelButtonGroup,
    Datepicker,
  },
  data() {
    return {
      en: en,
      fr: fr,
      form: {
        level: "",
        currently: "",
        city: "",
        startDate: Date(),
        endDate: Date(),
      },
      edus: {},
      loading: false,
      selectedNow: false,
      save: false,
    };
  },
  methods: {
    ...mapActions(["loadEducation"]),
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      country = countries.getName(country, "en", { select: "official" });
      return country;
    },
    cancel: function () {
      this.$emit("cancel");
    },
    setEndDate() {
      if (this.form.currently === "1") {
        this.selectedNow = true;
        this.form.endDate = "now";
      } else {
        if (!this.form.endDate) {
          this.form.endDate = "";
        }
        this.selectedNow = false;
      }
    },
    createEducation() {
      this.loading = true;
      this.form.country = this.countryCode(this.form.country);
      this.$store
        .dispatch("createEducation", {
          education: this.form,
        })
        .then(async (success) => {
          await this.loadEducation({ user_id: this.authUser.id });
          this.form = {};
          this.loading = false;
          this.editing = false;
          this.$emit("cancel");
          this.$noty.success(this.$i18n.t("educationAdded"));
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
          this.$noty.error(error);
        });
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: none !important;
  border-bottom: 2px solid var(--first-color) !important;
  border-radius: 0rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>

<style lang="scss" scoped>
.add-education {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  font-size: 16px;

  @include max-width(500) {
    font-size: 14px;
  }

  & > div {
    padding: 6px 0;

    @include max-width(500) {
      padding: 3px 0;
    }
  }

  .control {
    width: 47.5%;
  }

  textarea {
    width: 100%;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 3px;
  }
}

@mixin btn-props {
  display: block;
  color: var(--green-color);
  font-size: 15px;
  background-color: #fff;
  padding: 5px 15px;
  border: 1px solid var(--green-color);
  border-radius: 15px;

  &:hover {
    background-color: var(--green-color);
    color: #fff;
  }
}

.btn-save {
  @include btn-props;
}

.btn-cancel {
  @include btn-props;
  margin-left: 10px;
  border: 1px solid var(--error-color);
  color: var(--error-color);

  &:hover {
    background-color: var(--error-color);
    color: #fff;
  }
}
</style>
