<template>
  <div class="languageSwitcher">
    <div v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`">
      <a
        :class="lang === $i18n.locale ? 'selectedLang' : ''"
        @click.prevent="setLocale(lang)"
        >{{ getLang(lang) }}</a
      >
      <span class="spacer" v-if="i != $i18n.availableLocales.length - 1"
        >|</span
      >
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APPURL from "../constant";
export default {
  name: "lLanguageSwitcherRegister",
  props: {
    mobile: String,
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      //var name = this.$route.name;
      var originalName = this.$route.name;
      var name = originalName.substring(0, originalName.length - 2);
      var lang = originalName.slice(-2);
      if (lang === "En") {
        name += "Fr";
      } else {
        name += "En";
      }

      this.$store.commit("setUserLang", locale);
      localStorage.setItem("userLang", locale);
      localStorage.setItem("preferedLanguage", locale);
      this.$store.commit("setFormData", {
        field: "preferedLanguage",
        value: locale,
      });
      locale = this.$i18n.t(locale);

      //set the user preferedLanguage

      var request = "";
      var slug = `${this.$route.params.slug}`;
      // this.$router.push({ name: name, params: { lang: locale } }).catch(err => {});
      if (this.$route.path != originalName) {
        this.$router.push({ name: name, params: { lang: locale } });
      }
    },
    getLang(locale) {
      //return locale.charAt(0).toUpperCase() + locale.slice(1);
      var value = "navBar." + locale;
      return this.$i18n.t(value);
    },
  },
};
</script>

<style scoped>
.languageSwitcher {
  position: absolute;
  z-index: 1000;
  position: fixed;
  z-index: 9000;
  top: 0;
  right: 50px;
  bottom: 0px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6d6969;
  font-size: 18px;
  background-color: transparent;
}
.spacer {
  padding: 0 10px;
}
.selectedLang {
  color: var(--first-color) !important;
}

@media only screen and (max-width: 600px) {
  .languageSwitcher {
    position: absolute;
    z-index: 1000;
    position: fixed;
    z-index: 9000;
    top: auto;
    left: 100px;
    right: 100px;
    bottom: 0px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6d6969;
    font-size: 18px;
    background-color: #f6faf770;
    backdrop-filter: blur(10px);
  }
}
</style>
