<template>
  <aside
    v-if="!authUser.is_entrepreneur"
    class="rightside col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-12 col-12 bg-white pt-3"
  >
    <a
      :href="`/${$i18n.locale}/${this.$i18n.t('routes.jobs')}`"
      class="btn btn-lg btn-block btn-primary mb-3"
    >
      <i class="feather-briefcase"></i>
      {{ $t('navBar.africanCareers') }}
    </a>
    <div class="rounded bg-white mb-3">
      <div class="box-title border-bottom p-3">
        <h6 class="m-0 text-center">{{ $t('RecommendedCareers') }}</h6>
      </div>
      <div class="box-body p-3">
        <SideJobOffer v-for="(job, index) in jobs" :key="index" :job="job" />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  async mounted() {},
  computed: {
    ...mapGetters(['authUser', 'getJobs']),
  },
  methods: {
    ...mapActions(['loadJobs']),
  },
  data() {
    return {
      loading: true,
    };
  },
  name: 'RightAside',
  props: {
    jobs: {},
  },
};
</script>
