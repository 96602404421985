<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="following w-100">
    <img
      class="img-profile rounded-circle avatar"
      :src="startup.user.avatar"
      alt="avatar"
      width="100"
    />
    <div class="information">
      <p class="company-name">{{ startup.startupname }}</p>
      <p class="company-business">{{ sector }}</p>
    </div>

    <router-link
      class="icon-eye btn"
      :to="{ name: routename, params: { slug: startup.slug } }"
      ><img src="@/assets/images/profile/eye.svg" width="20" height="20" alt=""
    /></router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    this.routename = "StartupEn";
    if (this.$i18n.locale === "fr") {
      this.routename = "StartupFr";
    }

    this.getSector(this.startup.sector);
  },
  computed: {
    ...mapGetters(["authUser", "getUserLang"]),
  },
  data() {
    return {
      routename: "",
      sector: "",
    };
  },
  methods: {
    ...mapActions(["getSectorByLang"]),
    async getSector(code) {
      let lang = this.getUserLang;
      if (code != null) {
        this.getSectorByLang({ code, lang }).then((response) => {
          this.sector = response;
        });
      }
    },
  },
  props: {
    startup: Object,
  },
};
</script>



<style lang="scss" scoped>
.following {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  @include max-width(991) {
    background-color: rgba(232, 232, 232, 0.38);
    border: 1px solid rgba(112, 112, 112, 0.1);
    border-radius: 25px;
    margin-bottom: 15px;
  }
  @include max-width(600) {
    width: 90% !important;
    transform: translateX(5%);
    /* background: rgba(255, 255, 255, 0.125); */
  }

  &:hover {
    background: #f0f4f2;
    border: 1px solid rgba(112, 112, 112, 0.1);
  }

  .avatar {
    width: 48px;
    height: 48px;
    font-weight: 700;
    border-radius: 25px;
    border: 2px solid #cecdcd;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include max-width(330) {
      margin-right: 8px;
    }
  }

  .information {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .company-name {
      font-size: 16px;
      line-height: 1;
      color: #323435;

      @include max-width(410) {
        font-size: 15px;
      }
    }

    .company-business {
      font-size: 17px;
      line-height: 1;
      color: #666668;

      @include max-width(410) {
        font-size: 16px;
      }
    }
  }

  .icon-eye {
    display: flex;
    align-items: center;
    line-height: 0;
  }
}
</style>
