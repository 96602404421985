<template>
  <div class="py-3">
    <div class="page-content">
      <div class="container-fluid">
        <div class="banner-user">
          <div class="banner-content">
            <div class="media">
              <div class="item-img">
                <img
                  :src="avatar"
                  class="mt-2 rounded-circle"
                  alt="Avatar"
                  width="150"
                  height="150"
                />
              </div>
              <div class="media-body">
                <h3 class="item-title">{{ firstname }} {{ name }}</h3>
                <!-- <div>@{{ username }}</div> -->
                <div class="item-subtitle">
                  {{ position }}
                  <span
                    class="badge badge-pill badge-success"
                    v-if="connectionStatus"
                  >
                    {{ connectionStatus }}
                  </span>
                </div>
                <ul class="user-meta">
                  <span
                    v-if="connectionStatus != 'blocked' && dob"
                    class="mr-1"
                  >
                    <li>
                      <i aria-hidden="true" class="fa fa-birthday-cake"></i>:
                      <span>{{ dob }}</span>
                    </li>
                  </span>
                  <li class="pb-3">
                    <div class="dropdown">
                      <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ $t("moreOptions") }}
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          v-if="
                            routeName === 'ProfileEn' ||
                            routeName === 'ProfileFr'
                          "
                        >
                          <a
                            class="dropdown-item"
                            :href="`/${$i18n.locale}/${this.$i18n.t(
                              'routes.editProfile'
                            )}`"
                          >
                            <i class="fa fa-edit" aria-hidden="true"></i>
                            {{ $t("editProfile") }}</a
                          >
                        </div>
                        <div v-else>
                          <div v-if="authUser.type === 1">
                            <div v-if="status === ''">
                              <a
                                class="dropdown-item"
                                @click="connect(user_id)"
                              >
                                <i class="feather-user-plus"></i>
                                {{ $t("connect") }}</a
                              >
                            </div>
                            <div v-else-if="status === 'connected'">
                              <a class="dropdown-item" href="#">
                                <i class="feather-inbox"></i>
                                {{ $t("message") }}</a
                              >
                              <a
                                class="dropdown-item text-danger"
                                @click="block"
                              >
                                <i class="feather-user-x"></i>
                                {{ $t("block") }}</a
                              >
                            </div>
                            <div v-else-if="status === 'sent'">
                              <a class="dropdown-item" href="#">
                                <i class="feather-send"></i>
                                {{ $t("requested") }}</a
                              >
                              <a
                                class="dropdown-item text-danger"
                                @click="cancel"
                              >
                                <i class="feather-x-circle"></i>
                                {{ $t("cancel") }}</a
                              >
                            </div>

                            <div v-else-if="status === 'received'">
                              <a
                                class="dropdown-item text-primary"
                                @click="accept"
                              >
                                <i class="feather-check-circle"></i>
                                {{ $t("accept") }}</a
                              >
                              <a
                                class="dropdown-item text-danger"
                                @click="cancel"
                              >
                                <i class="feather-x-circle"></i>
                                {{ $t("cancel") }}</a
                              >
                            </div>
                            <div v-else-if="status === 'blocked'">
                              <a class="dropdown-item" @click="unBlock">
                                <i class="feather-user-x"></i>
                                {{ $t("unblock") }}</a
                              >
                            </div>
                          </div>
                          <div v-if="status != 'blocked'">
                            <a
                              v-clipboard="profileLink"
                              v-clipboard:success="clipboardSuccessHandler"
                              v-clipboard:error="clipboardErrorHandler"
                              class="dropdown-item"
                            >
                              <i class="feather-copy" aria-hidden="true"></i>
                              {{ $t("copyLink") }}
                            </a>
                            <!-- <a href="#" class="dropdown-item">
                              <i class="feather-share-2" aria-hidden="true"></i>
                              {{ $t('shareProfile') }}
                            </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
import Axios from "axios";
export default {
  async mounted() {
    this.routeName = this.$route.name;
    if (this.showConnectButton) await this.getStatus();
  },
  data() {
    return {
      routeName: "",
      status: "",
    };
  },
  computed: {
    ...mapGetters(["authUser"]),
    connectionStatus() {
      return this.status;
    },
  },
  methods: {
    accept() {
      const data = {
        from: this.user_id,
        to: this.authUser.id,
      };
      Axios({
        method: "post",
        url: APIURLS.CONNECTIONS + "accept",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          // console.log('response', response);
          if (response.data.message === "Connection Successfull") {
            this.status = "connected";
            this.$store.commit("setConnectionStatus", {
              status: "connected",
            });
            this.$noty.success("Success");
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$noty.error(error.response.data.message);
        });
    },
    connect() {
      const data = {
        from: this.authUser.id,
        to: this.user_id,
      };
      Axios({
        method: "post",
        url: APIURLS.CONNECTIONS + "request",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          //console.log('response', response);
          if (response.data.message === "Request Sent Successfully") {
            this.status = "sent";
            this.$store.commit("setConnectionStatus", {
              status: "sent",
            });
            this.$noty.success("Success");
          }
        })
        .catch((error) => {
          //console.log('error', error.response.data.message);
          this.$noty.error(error.response.data.message);
        });
    },
    unBlock() {
      let data = {
        from: this.authUser.id,
        to: this.user_id,
      };
      Axios({
        method: "post",
        url: APIURLS.CONNECTIONS + "unblock",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          console.log("response", response);
          if (response.data.message === "Connection unblocked") {
            this.status = "";
            this.$store.commit("setConnectionStatus", {
              status: "",
            });
            this.$noty.success("Success");
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    block() {
      let data = {
        from: this.authUser.id,
        to: this.user_id,
      };
      Axios({
        method: "post",
        url: APIURLS.CONNECTIONS + "block",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          if (response.data.message === "Connection Blocked") {
            this.status = "blocked";
            this.$store.commit("setConnectionStatus", {
              status: "blocked",
            });
            this.$noty.success("Success");
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    cancel() {
      let data = {};
      if (this.status === "received") {
        data = {
          from: this.user_id,
          to: this.authUser.id,
        };
      } else {
        data = {
          from: this.authUser.id,
          to: this.user_id,
        };
      }
      Axios({
        method: "post",
        url: APIURLS.CONNECTIONS + "cancel",
        headers: { "auth-token": this.authUser.token },
        data: data,
      })
        .then((response) => {
          console.log("response", response);
          if (response.data.message === "Request Canceled") {
            this.status = "";
            this.$store.commit("setConnectionStatus", {
              status: "",
            });
            this.$noty.success("Success");
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
    getStatus() {
      Axios.get(
        APIURLS.CONNECTIONS +
          "connected/user/" +
          this.user_id +
          "/auth_user/" +
          this.authUser.id
      )
        .then((response) => {
          if (response.data === "blocked") {
            this.$store.commit("setConnectionStatus", {
              status: response.data,
            });
            // this.$router.push(`/${i18n.locale}/${i18n.t('routes.error')}`);
          }
          this.status = response.data;
          //console.log('response', response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    profileLink() {
      return this.link;
    },
    clipboardSuccessHandler({ value, event }) {
      this.$noty.success(`${i18n.t("copyLinkSuccess")}`);
    },

    clipboardErrorHandler({ value, event }) {
      this.$noty.error(`${i18n.t("copyLinkError")}`);
    },
  },
  name: "ProfileCover",
  props: {
    Linkedin: {
      type: String,
      default: "",
    },
    Instagram: {
      type: String,
      default: "",
    },
    Facebook: {
      type: String,
      default: "",
    },
    Youtube: {
      type: String,
      default: "",
    },
    Twitter: {
      type: String,
      default: "",
    },
    Github: {
      type: String,
      default: "",
    },
    Website: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    firstname: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: "",
    },
    sex: {
      type: String,
      default: "",
    },
    dob: {
      type: String,
      default: "",
    },
    headline: {
      type: String,
      default: "",
    },
    organization: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
    country: {
      type: String,
      default: "",
    },
    city: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    showConnectButton: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
    },
  },
};
</script>

<style>
h3.item-title {
  color: #0000009c !important;
}
.item-subtitle {
  color: #0000006d !important;
}
.dropdown-item:hover {
  background: rgb(208, 241, 198);
}
/*------------------- 6.2 Banner -------------------*/
.page-content {
  padding: 10px 0 0;
}
.newsfeed-banner {
  background-image: -webkit-linear-gradient(right, #ffea00, #ff9800);
  background-image: linear-gradient(to left, #ffea00, #ff9800);
  border-radius: 20px !important;
  padding: 60px 50px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  z-index: 1;
}

[class*=" icofont-"],
[class^="icofont-"] {
  font-family: IcoFont !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 767px) {
  .newsfeed-banner {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .newsfeed-banner {
    text-align: center;
    padding: 20px;
  }
}

.newsfeed-banner .media {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.newsfeed-banner .item-icon {
  font-size: 80px;
  color: #ffffff;
  margin-right: 55px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .newsfeed-banner .item-icon {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .newsfeed-banner .item-icon {
    font-size: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .newsfeed-banner .item-icon {
    display: none;
  }
}

.newsfeed-banner .item-icon:before {
  content: "";
  height: 54px;
  width: 3px;
  background-color: #ffffff;
  border-radius: 2px;
  position: absolute;
  top: 46%;
  right: -35px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.newsfeed-banner .media-body .item-title {
  font-size: 30px;
  color: #ffffff;
  font-weight: 800;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1199px) {
  .newsfeed-banner .media-body .item-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .newsfeed-banner .media-body .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  .newsfeed-banner .media-body .item-title {
    font-size: 24px;
  }
}

.newsfeed-banner .media-body p {
  margin-bottom: 0;
  color: #ffffff;
}

.newsfeed-banner .animation-img li {
  position: absolute;
  z-index: -1;
}

.newsfeed-banner .animation-img li:nth-child(1n) {
  top: 0;
  right: 150px;
}

.newsfeed-banner .animation-img li:nth-child(2n) {
  top: auto;
  bottom: 0;
  right: 30px;
}

@media only screen and (max-width: 991px) {
  .newsfeed-banner .animation-img li:nth-child(2n) {
    display: none;
  }
}

.banner-user {
  /* background-image: url('https://res.cloudinary.com/bantaba/image/upload/v1623803798/bantaba/banner_user_yhycph.jpg'); */
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 35px 35px 50px;
  position: relative;
  margin-left: 20px;
  border-radius: 20px !important;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .banner-user {
    padding: 100px 35px 35px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-user {
    padding: 60px 30px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-user {
    padding: 60px 20px 35px;
  }
}

.banner-user:before {
  content: "";
  height: 100%;
  width: 100%;
  /* background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.9),
    transparent
  );
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  border-radius: 20px !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.banner-user .media {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .banner-user .media {
    display: block;
    text-align: center;
  }
}

.banner-user .media .media-body {
  position: relative;
}

.banner-user .media .item-img {
  position: relative;
  margin-right: 30px;
  border-radius: 50%;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .banner-user .media .item-img {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.banner-user .media .item-img:after {
  /* content: url('../../media/banner/round_shape1.png'); */
  position: absolute;
  top: -9px;
  left: -8px;
}

.banner-user .media .item-img img {
  border-radius: 50%;
}

.banner-user .media .item-title {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 2px;
  position: relative;
  display: inline-block;
}

.banner-user .media .item-subtitle {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

.banner-user .media .item-social li {
  display: inline-block;
  margin-right: 2px;
}

.banner-user .media .item-social li:last-child {
  margin-right: 0;
}

.banner-user .media .item-social li a {
  text-align: center;
  padding: 10px;
  margin: 5px;
  font-size: 18px !important;
  color: #ffffff;
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.banner-user .media .item-social li a:hover {
  opacity: 0.8;
}

.banner-user .media .user-meta {
  position: absolute;
  bottom: 0;
  right: 0;
}
.banner-user .media .user-meta li {
  display: inline-block;
  color: #d7d7d7;
  margin-right: 24px;
}

@media only screen and (max-width: 991px) {
  .banner-user .media .user-meta {
    position: relative;
    margin-top: 20px;
  }
}

.banner-user .media .user-meta li {
  display: inline-block;
  color: #d7d7d7;
  margin-right: 24px;
}

.banner-user .media .user-meta li:last-child {
  margin-right: 0;
}

.banner-user .media .user-meta li span {
  font-weight: 600;
  color: #ffffff;
}
</style>
<style>
.bg-common {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-fb {
  background-color: #365493;
}

.bg-linkedin {
  background-color: #365493;
}

.bg-github {
  background-color: #000;
}

.bg-github {
  background-color: #000;
}

.bg-website {
  background-color: #148fa5 !important;
}

.bg-twitter {
  background-color: #38b8ff;
}

.bg-instagram {
  background-color: #ff3b6e;
}

.bg-youtube {
  background-color: #ff0000;
}

.color-fb {
  color: #1877f2;
}

.color-twitter {
  color: #1da1f2;
}

.color-instagram {
  color: #c32aa3;
}

.color-messenger {
  color: #00b2ff;
}

.color-whatsapp {
  color: #25d366;
}

/* .cover {
  display: block;
  width: 100vw;
  height: 30vh;
  object-fit: cover;
} */
</style>
