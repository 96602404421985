<template>
  <div class="cover-card">
    <imageUploadModal :isShow="isUploadModal" :loading="loading" :showPreview="showPreview" @closeModal="closeModal"
      @showPreviewTrue="showPreviewTrue" @submit="submit" :startVal="startVal" :endVal="endVal" :decimals="decimals"
      :options="options" />
    <form class="cover-form" :class="{ coverFormBorder: isShowPreview }" enctype="multipart/form-data" action="#"
      @submit.prevent="submit">
      <label :class="{ labelPreview: isShowPreview }" @click="isUploadModal = true" style="cursor: pointer;"
        for="fileAttachmentBtn2">
        <!-- <input
          type="file"
          @change="onCoverChange"
          name="image"
          id="fileAttachmentBtn2"
          style="display: none"
        /> -->
        <img :src="cover" :class="{ addCoverImg: !isShowPreview }" class="profile-icon cover-img" alt="cover"
          v-if="!showPreview" />
        <!-- <div v-else>
          <VueCropper
            class="Showmobile"
            ref="cropper2"
            :aspect-ratio="16 / 9"
            :dragMode="none"
            :autoCrop="false"
            :src="cover"
            :viewMode="1"
            :img-style="{ width: '400px', height: '300px' }"
            preview=".preview"
          />
          <VueCropper
            class="ShowDesktop"
            ref="cropper2"
            :aspect-ratio="16 / 9"
            :dragMode="none"
            :autoCrop="false"
            :src="cover"
            :viewMode="1"
            :img-style="{ width: '400px', height: '300px' }"
            preview=".preview"
          />
        </div> -->
      </label>
      <p class="cover-text" :class="{ hideTextPlaceholder: isShowPreview }">
        <span class="cover-text-placeholder">{{ $t("Add Cover") }}</span>
      </p>
      <!-- <div class="row upload-options-container">
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload"
          type="submit"
          :disabled="loading"
          class="btn btn-success ml-3 upload-option-btn"
        >
          <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
          <i class="feather-upload-cloud" v-else></i>
          {{ loading ? "..." + $t("upload") : $t("upload") }}
        </button>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete"
          type="submit"
          @click="cancel"
          class="btn btn-danger ml-3 upload-option-btn"
        >
          <i class="feather-trash-2"></i> {{ $t("cancel") }}
        </button>
      </div> -->
    </form>
  </div>
</template>
<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import imageUploadModal from "./../Profile/Diaspora/profile/main/imageUploadModal.vue";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  mounted() {
    let cover = localStorage.getItem("cover");
    if (cover) {
      this.cover = cover;
      this.isShowPreview = true;
      this.$store.commit("setFormData", {
        field: "cover",
        value: cover,
      });
    }
  },
  computed: {
    ...mapGetters(["getFomData", "getAccountType"]),
  },
  data: () => {
    return {
      isActive: false,
      startVal: 0,
      endVal: 0,
      decimals: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 3,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      showPreview: false,
      isShowPreview: false,
      cropImg: "",
      cropCover: "",
      cover: require("@/assets/registration/icons/camera.svg"),
      buttonChoice: "select",
      image: null,
      file: "",
      fileContents: null,
      cloudName: "bantaba",
      preset: "Startups-logos",
      formData: null,
      loading: false,
      isUploadModal: false,
    };
  },

  methods: {
    showPreviewTrue(value) {
      this.showPreview = value;
    },
    closeModal() {
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isUploadModal = false;
      console.log(this.isUploadModal)
      var percentageComplete = 0;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
      this.startVal = 0;
      this.endVal = 0;
      this.cropImg = "";
      this.image = null;
      this.formData = null;
    },
    ...mapMutations(["setAccountType"]),
    onCoverChange(e) {
      this.showPreview = true;
      this.isShowPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.cover = URL.createObjectURL(file);
      this.buttonChoice = "upload";
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropCover);
    },
    cancel() {
      this.cover = require("@/assets/registration/icons/camera.svg");
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isShowPreview = false;
    },
    submit(obj) {
      // this.cropCover = this.$refs.cropper2.getCroppedCanvas().toDataURL();
      this.cropCover = obj.cropedImageData;
      this.startVal = 0;
      this.endVal = 0;
      this.loading = true;
      var zeroPercentageComplete = 0;
      var zeroStrokeDashOffsetValue = 100 - zeroPercentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${zeroStrokeDashOffsetValue}`;
      var percentageComplete = 0.5;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var afterPercentageComplete = 1;
      var afterStrokeDashOffsetValue = 100 - afterPercentageComplete * 100;
      setTimeout(() => {
        this.startVal = 0;
        this.endVal = 50;
        progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
        console.log(this.startVal, this.endVal);
      }, 100);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.startVal = 50;
              this.endVal = 100;

              progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
              setTimeout(() => {
                this.results = response.data;
                this.image = this.results.secure_url;
                this.showPreview = false;
                this.cover = this.results.secure_url;
                this.$noty.success(this.$i18n.t("coverUpdated"));
                this.loading = false;
                this.buttonChoice = "select";
                this.$store.commit("setFormData", {
                  field: "cover",
                  value: this.cover,
                });
                localStorage.setItem("cover", this.cover);
                this.closeModal();
              }, 3800);
            })
            .catch((error) => {
              this.startVal = 50;
              this.endVal = 100;
              setTimeout(() => {
                this.loading = false;
                this.errors.push(error);
                this.closeModal();
              }, 3800);
            });
        }.bind(this),
        false
      );
      // reader.readAsDataURL(this.file);
      reader.readAsDataURL(obj.file);
    },
  },
  name: "UlpoadCover",
  components: { imageUploadModal },
};
</script>
<style lang="scss">
.drag-button {
  background-color: #011108;
  color: #ffffff;
  font-size: 14px;
  z-index: 500;
  margin-top: -60px;
  cursor: default;
  position: absolute;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-image-modal-footer {
  border-top: 1px solid rgb(222 226 230);
}

$progress-bar-stroke-width: 3;
$progress-bar-size: 166px;

svg {
  height: $progress-bar-size;
  //transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #c9e9d7;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #c9e9d7;
  stroke: #009743;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 3s ease-in-out;
}

.modal-trigger-heading {
  font-size: 20px;
}

.feed-modal-title {
  font-size: 20px;
  color: #011108;
  font-weight: 700 !important;
  font-family: poppins, sans-serif !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.cross-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #5b615e;
}

.cross-svg-icon {
  width: 16px;
  height: 16px;
  color: #009743;
}

.create-feed-modal {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex;
  z-index: 15000 !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.feed-modal {
  border-radius: 20px;
  width: 645px;
}

.upload-img {
  background-color: transparent !important;
  border: 1px solid rgb(238 238 238);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.upload-img:hover {
  background-color: rgb(233, 230, 230) !important;
}

.publish-now {
  background-color: #009743;
  color: white;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.publish-now:hover {
  background-color: #09b657;
  color: white;
}

.modal-custom-body {
  padding: 0px !important;
}

.hideTextPlaceholder {
  display: none;
}

.profile-card {
  width: min(305px, 48%);
  height: 225px;
  background-color: #f0f4f7;
  border: 1px solid #888c93;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.profile-icon {
  width: 50px;
}

.profile-text {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover-text {
  position: relative;
}

.cover-text-placeholder {
  position: absolute;
  top: -90px;
  left: -59px;
  background-color: #00000054;
  color: white;
  border-radius: 60px;
  width: 125px;
  height: 125px;
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cover-form {
  background-color: rgba(0, 128, 0, 0.084);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-style: dashed solid;
}

.cover-img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.upload-options-container {
  position: absolute;
  z-index: 5000000;
  bottom: 20px;
  left: 0;
  right: 0;
  /* display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center; */
}

.upload-option-btn {
  width: 45%;
  height: 40px;
  margin: 0px 2px !important;
}

.Showmobile {
  display: none;
}

.ShowDesktop {
  display: flex;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Showmobile {
    display: block;
    width: 100vw;
    margin-bottom: 400px;
  }

  .ShowDesktop {
    display: none;
  }

  .cover-card {
    width: 100% !important;
    height: 100% !important;
    padding-left: 0 !important;
    /* background-color: orange; */
  }

  .profile-card {
    width: 48%;
    height: 180px;
    border-radius: 8px;
    background-color: #f0f4f7;
    border: 1px solid #888c93;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .profile-icon {
    width: 50px;
    background-color: #eee;
  }

  .profile-upload h3 {
    font-size: 20px;
    color: #808896;
    padding: 0;
  }

  .profile-upload h5 {
    font-size: 16px;
    padding: 0;
    color: #b4b9c3;
    font-weight: lighter;
  }

  .cover-text {
    position: relative;
  }

  .cover-text-placeholder {
    position: absolute;
    top: -65px;
    left: -39px;
    background-color: #00000054;
    color: white;
    border-radius: 60px;
    width: 165px;
    height: 65px;
    pointer-events: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 19px;
  }

  .profile-text {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cover-form {
    /* background-color: green; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cover-img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-style: dashed solid;
    /* opacity: 0.8; */
    backdrop-filter: blur(10%);
  }

  .addCoverImg {
    width: 70% !important;
    padding: 5px;
    border-style: none;
  }

  .coverFormBorder {
    background-color: white;
    border: none;
    height: auto;
    padding: auto;
  }

  .labelPreview {
    height: 100%;
    margin-bottom: 0rem;
  }
}
</style>