<template>
  <button class="btn btn-more" @click="clearFilters">Clear Filters</button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  methods: {
    clearFilters() {
      alert("clear filters");
    },
  },
  name: "ClearFiltersBtn",
  props: {
    buttonText: String,
    text: String,
    buttonLink: String,
  },
};
</script>

<style scoped>
.btn-more {
  display: block;
  background: #989998;
  padding: 0.2rem 0.8rem;
  color: #fff;
  border-radius: 1rem;
  font-size: 0.75rem;
  text-align: center;
  text-decoration: none;
}
.btn-more:hover {
  background: #1ba54b;
  color: #fff;
}
.btn-more:hover path {
  fill: #fff;
}
.btn-more::before {
  display: inline-block;
}
</style>