<template>
  <div class="Dashbboard" :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }">
    <div class="layout">
      <main class="main-content main-content-custom">
        <WritePostCard @showTheNewPostOnTheFeed="showTheNewPostOnTheFeed" />
        <!-- <button  @click="scrollMeTo()">scroll to</button> -->
        <!-- <p v-if="$route.params.post_uuid">router params {{  $route.params.post_uuid.split("=")[1]}}</p> -->
        <div class="news-feed" v-if="!loading">
          <SCarousel :talents="relevantTalents" v-if="authUser.type === 2" />
          <ul>
            <Observer @fetchData="fetchPageData" v-for="(feed, index) in batchPosts" :key="index" :name="
              index === batchPosts.length - 1 ? 'last-item' : `item-${index}`
            ">
              <Postcard :feed="feed" :posts="feed" :key="feed.id" :ref="feed.uuid" :id="feed.uuid" />
              <DCarousel v-if="authUser.type === 1 && index % 2 === 0" :startup="completeStartups[index]"
                :randIndex="feed.randIndex" />

              <SCarousel :talents="relevantTalents" v-if="authUser.type === 2 && index % 2 === 0 && feed.hide !== 1" />
            </Observer>
          </ul>
        </div>
        <!-- <div class="news-feed">
          <SkeletonLoadersDashboard/>
        </div> -->
        <div v-if="getIsNewPost && canShowNotifcation" @click="newPostNotyClick" class="newPostNoty">
          <span class="circle"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330"
              style="enable-background: new 0 0 330 330" xml:space="preserve">
              <path id="XMLID_29_"
                d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394  C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75  c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z" />
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </span>
          <span class="noty-title"> {{ $t("A new post is available!") }}</span>
        </div>
      </main>
      <DashboardAside :jobs="getJobs" :news="getNews" :talents="relevantTalents" />
    </div>
    <Loader v-if="loading" />
    <!-- <ReferalModal :time-out-show="450" v-if="showRef" /> -->
  </div>
</template>


<script>
import i18n from "../i18n";
import Axios from "axios";
import _ from "lodash";
import { mapGetters, mapActions, mapMutations } from "vuex";

import ReferalModal from "./../components/Modal/ReferalModal";

const WritePostCard = () =>
  import("./../components/WritePost/WritePostCard.vue");
const DashboardAside = () => import("./../components/Sidebar/Aside.vue");
const DCarousel = () => import("./../components/Carousels/DCarousal.vue");
const Postcard = () => import("./../components/Postcard/Postcard.vue");
const SCarousel = () => import("./../components/Carousels/SCarousel.vue");
const Observer = () => import("./../components/Observers/Observer.vue");
const SkeletonLoadersDashboard = () =>
  import("../components/SkeletonLoaders/SkeletonLoadersDashboard.vue");
import { io } from "socket.io-client";
import APIURLS from "../constant";


// import { scrollTo } from 'vue-scrollto'

export default {
  components: {
    WritePostCard,
    DashboardAside,
    DCarousel,
    Postcard,
    SCarousel,
    Observer,
    // SkeletonLoadersDashboard
    //ReferalModal,
  },
  metaInfo() {
    return {
      title: `Bantaba | ${this.$i18n.t("navBar.home")}`,
    };
  },
  async mounted() {
    // this.$nextTick(function () {
    //   window.setInterval(() => {
    //     this.changeStartup();
    //   }, 1000);
    // });

  // this.$nextTick(() => {
  //   console.log("coool", this.$route.hash);
  //   const el = document.querySelector(this.$route.hash);
  //   console.log(el);
  //   el && el.scrollIntoView();
  // })

    console.log("user = ", this.authUser)

    this.showRef = await this.loadShowReferral({ user_id: this.authUser.id });
    this.username = this.authUser.username;
    if (this.authUser.type === 1) {
      const lang = this.getUserLang;
      await this.loadNews(4);
      await this.loadPaginatedJobs(4);

      if (!JSON.parse(localStorage.getItem("featuredStartups"))) {
        await this.loadFeaturedStarups({ lang, pagination: 20 });
        this.completeStartups = this.getFeaturedStartups;
        //save to locale storage
        localStorage.setItem(
          "featuredStartups",
          JSON.stringify(this.completeStartups)
        );
      } else {
        this.completeStartups = JSON.parse(
          localStorage.getItem("featuredStartups")
        );
      }
      this.number = Math.floor(Math.random() * this.completeStartups.length);
    } else if (this.authUser.type === 2) {
      const startup_id = this.authUser.startup ? this.authUser.startup.id : "";
      if (!JSON.parse(localStorage.getItem("relevantTalents"))) {
        await this.loadRelevantTalents({ startup_id });
        this.relevantTalents = this.getRelevantTalents;
        //save to locale storage
        localStorage.setItem(
          "relevantTalents",
          JSON.stringify(this.relevantTalents)
        );
      } else {
        this.relevantTalents = JSON.parse(
          localStorage.getItem("relevantTalents")
        );
      }
    }
    this.loading = false;
    await this.loadInitialPost(this.feedPageNo, 10);
      // this.isTimeOut = true; 

  // this.$nextTick(() => {
  //       // this.scrollMePost();
  //   this.loadInitialAllPost(this.feedPageNo, 100).then(
  //         ()=>{
  //           this.scrollMeTo();
  //         }
  //       );
  //     });
    
  },
  sockets: {
    isNewPost(data) {
      if (data.id === this.authUser.id) {
        this.canShowNotifcation = false;
        this.setIsNewPost(false);
      }
      if (data.status === true) {
        this.batchPosts = [data.data, ...this.batchPosts];
      }
    },
    deleteAFeed(data) {
      this.batchPosts = this.batchPosts.filter(
        (post) => post.id != data.feedId
      );
    },
  },
  async created() {
    //set the user lang to the store
    let lang;
    let preferedLanguage = "";
    if (
      this.authUser.profile &&
      this.authUser.profile.preferedLanguage !== "null"
    ) {
      preferedLanguage = this.authUser.profile.preferedLanguage;
    }

    //check if the lang as been selected from the login page
    if (preferedLanguage != this.getUserLang) {
      //set the user preferedLanguage.
      lang = this.getUserLang;
      if (lang != "fr" && lang != "en") {
        lang = "en";
      }
      await this.changeUserPreferedLang(lang);
    } else {
      if (preferedLanguage && preferedLanguage !== "null") {
        lang = this.authUser.profile.preferedLanguage;
      } else {
        let locale = this.$i18n.locale;
        if (locale != "en" || locale != "fr") {
          locale = "en";
        }
        lang = locale;
      }
    }
    this.langUrl = lang;

    this.$store.commit("setUserLang", lang);
    localStorage.setItem("userLang", lang);
    this.$i18n.locale = lang;

    var originalName = this.$route.name;
    var name = originalName.substring(0, originalName.length - 2);
    if (lang === "En") name += "Fr";
    else name += "En";
    // this.$router.push({ name: name, params: { lang } }).catch(err => {});
    if (this.$route.path != originalName) {
      // this.$router.push("/");
      this.$router.push({ name: name, params: { lang } }).catch(() => {});
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserProfile",
      "getStartup",
      "getConnected",
      "getConnectionsCounts",
      "getUsersSupportStartups",
      "getNews",
      "getJobs",
      "getFeeds",
      "getStartups",
      "getRelevantTalents",
      "getFeaturedStartups",
      "getUserBrowsingPosts",
      "getUserBrowsingBantabaDigest",
      "getIsNewPost",
      "getUserLang",
    ]),
  },
  
  methods: {
    async scrollMePost() {
      let routerId = this.$route.params.post_uuid;
     if(routerId){
       routerId.split("=")[1].toString();
      this.scrollToPost = document.getElementById(routerId);
      while(this.scrollToPost === null && this.NscrollPost  >= 0){
         await this.fetchPageByOneData();
         this.NscrollPost--
        //  if(this.NscrollPost === 0){
        //    alert("scroll to post")
        //  }
      }
     
    }else{
      this.isTimeOut = true; 
    }

  //   window.setInterval(() => {
  //    if(this.scrollToPost !== null){
  //        this.scrollMeTo();
  //     }
  // }, 30000)
        setTimeout(() =>
     {
      if(this.scrollToPost !== null){
         this.scrollMeTo();
      }
       this.isTimeOut = true;
     
   }, 1000);

//    document.onreadystatechange = () => {
//   if (document.readyState === "complete") {
//     alert("cool")
//      this.isTimeOut = true;
//     if(this.scrollToPost){
//         this.scrollMeTo();
//       }
//     console.log('Page completed with image and files!')
//     // fetch to next page or some code
//   }
// }

    },
    async scrollMeTo() {
      let id = this.$route.params.post_uuid;
      if(id !== null || id !== undefined){
        id = id.split("=")[1].toString();
        this.scrollToPost = document.getElementById(id);
        const yOffset = -185; 
        this.scrollToPost = document.getElementById(id);
        const y = this.scrollToPost.getBoundingClientRect().top + window.pageYOffset + yOffset;
        // const y = 150;
        window.scrollTo({top: y, behavior: 'smooth'});
      }else{
        alert("element does not exist")
      }
  },
  async fetchPageByOneData(batch = 1) {
      this.feedPageNo += 1;
      await Axios.get(
        `${APIURLS.FEEDS}page/${this.feedPageNo}/${batch}/${this.getUserLang}`
      ).then((response) => {
        this.perSessionPosts = response.data.feeds;
        this.batchPosts = this.batchPosts.concat(this.perSessionPosts);
      });
      this.perSessionPosts = [];
    },
    async fetchPageData(batch = 10) {
      this.feedPageNo += 1;
      await Axios.get(
        `${APIURLS.FEEDS}page/${this.feedPageNo}/${batch}/${this.getUserLang}`
      ).then((response) => {
        this.perSessionPosts = response.data.feeds;
        this.batchPosts = this.batchPosts.concat(this.perSessionPosts);
      });
      this.perSessionPosts = [];
    },
    async loadInitialAllPost(page = 0, batch = 50) {
      await Axios.get(
        `${APIURLS.FEEDS}page/${page}/${batch}/${this.getUserLang}`
      ).then((response) => {
        //console.log("loadInitialPost res", response);

        this.batchPosts = response.data.feeds;
      });
    },
    async loadInitialPost(page = 0, batch = 10) {
      await Axios.get(
        `${APIURLS.FEEDS}page/${page}/${batch}/${this.getUserLang}`
      ).then((response) => {
        //console.log("loadInitialPost res", response);

        this.batchPosts = response.data.feeds;
      });
    },

    ...mapMutations(["setIsNewPost"]),
    async newPostNotyClick() {
      this.setIsNewPost(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async showTheNewPostOnTheFeed(data) {},
    getCommentUserInfo(id) {},
    /* restructureComments(comments) {
      return comments.map((comment) => {
        let name = null;
        let profile = null;
        if (comment.hasOwnProperty("user")) {
          name = comment.user.firstname + " " + comment.user.name; // user chain option to check if user exist otherwise then catch the error  ==> if user.name  || users.firstname + user.lastname is both valid  ==> fallback is always user.name
          profile = comment.user.avatar;
        }
        // let Time = postname.created_at
        let commentText = comment.text;
        let commentId = comment.id;
        let commentUserId = comment.user_id;
        return {
          name,
          profile,
          commentText,
          commentId,
          commentUserId,
        };
      });
    },*/
    fetchData() {
      //fetch 3 posts.
      let posts = this.getUserBrowsingPosts;
      posts.map((post) => {
        this.userFeed.push({ type: "P", data: post });
      });
      let bantabaDigest = this.getUserBrowsingBantabaDigest;
      bantabaDigest.map((digest) => {
        this.userFeed.push({ type: "D", data: digest });
      });
      if (this.authUser.type === 1) {
        let number = Math.floor(Math.random() * this.completeStartups.length);
        //fetch 1 DC
        let DcData = this.completeStartups[number];
        this.userFeed.push({ type: "Dc", data: DcData });
      } else {
        //fetch 1 Sc
        let ScData = this.relevantTalents;
        this.userFeed.push({ type: "Sc", data: ScData });
      }

      this.DummyPosts.push("P", "P", "Dc");
    },
    changeStartup() {},
    ...mapActions([
      "loadProfile",
      "loadStartup",
      "loadConnections",
      "loadConnCounts",
      "loadUsersSupportStartups",
      "loadStartups",
      "loadNews",
      "loadPaginatedJobs",
      "loadFeeds",
      "loadJobs",
      "createFeed",
      "deleteFeed",
      "loadRelevantTalents",
      "loadFeaturedStarups",
      "changeUserPreferedLang",
      "loadShowReferral",
    ]),
  },
  data() {
    return {
      NscrollPost:30,
      scrollToPost: null,
      isTimeOut: false,
      feedPageNo: 0,
      batchPosts: [],
      perSessionPosts: [],
      langUrl: "",
      canShowNotifcation: true,
      number: 0,
      loading: true,
      pagination: 10,
      DummyPosts: ["Dc", "Sc", "P"],
      userFeed: [],
      dummyLoadN: 0,
      connections: {},
      jobs: {},
      users: {},
      news: {},
      post: [],
      AllPosts: [],
      mergedFeeds: [],
      post3: [
        {
          posterName: "Kemo Jallow",
        },
      ],
      counts: 0,
      typing: false,
      creatingPost: false,
      feeds: {},
      shareUpdate: false,
      startups: {},
      completeStartups: {},
      relevantTalents: {},
      sockets: {},
      showRef: false,
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.Dashbboard {
  /* zoom: 90%; */
  /* height: 110vh; */
  width: 100vw;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 200px;
}
.showNotVerifiedNotfication {
  padding-top: 70px;
}
.layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100%;
  max-width: 78%;
  position: relative;
  padding-top: 10px;
  /* transform: scale(0.85) */
}

.main-content {
  height: 100%;
  width: 60%;
  padding-top: 0px;
  overflow-y: auto;
  padding-bottom: 100px;
  position: relative;
}
.newPostNoty {
  position: fixed;
  top: 0;
  z-index: 100;
  top: 100px;
  /* background: black; */
  background-color: #38a64ecf;
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  color: white;
  left: calc(40% - 12px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 7px rgb(0 0 0 / 5%);
}
#Layer_1 {
  fill: white;
  width: 28px;
  height: 22px;
}
.circle {
  background: #ffffff2b;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noty-title {
  padding-left: 5px;
}
aside {
  height: 99%;
  width: 30%;
  margin-left: 3rem;
  overflow-y: auto;
}

/* .card{
  padding: 16px;
  border-radius: 18px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.01), 
  0 3px 6px rgba(0,0,0,0.015);
} */
.posts-ul {
  background-color: green;
}
ol li, ul li {
    list-style: none !important; 
    /* padding: 5px 0; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Dashbboard {
    padding-top: 10px;
  }
  .layout {
    max-width: 100%;
  }
  aside {
    display: none;
  }
  .main-content {
    width: 100%;
    padding-top: 50px;
  }
  .showNotVerifiedNotfication {
    padding-top: 70px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .layout {
    max-width: 100%;
  }
  .main-content {
    /* width: 60%; */
    width: calc(70% - 2rem);
    /* padding-top: 10px; */
    padding-top: 33px;
    overflow-y: auto;
  }
  aside {
    width: 40%;
    margin-left: 0.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .layout {
    max-width: 90%;
  }
  aside {
    width: 30%;
    margin-left: 1.5rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .layout {
    /* max-width: 73%; */
    /* max-width: min(78%, 1140px); */
    max-width: 990px !important;
  }
  aside {
    margin-left: 2rem;
    /* padding-top: 10px; */
    padding-top: 31px;
  }
}
@media only screen and (min-width: 1500px) {
  .layout {
    max-width: 73%;
    max-width: min(78%, 1140px);
  }
  aside {
    margin-left: 2.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .Dashbboard {
    zoom: 100%;
  }
  .layout {
    max-width: 90%;
    padding-top: 0;
  }
  aside {
    display: none;
  }
  .main-content {
    width: 100%;
    padding-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .layout {
    max-width: 100%;
    padding-top: 0;
  }
}
</style>



// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
  
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {}ktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {}