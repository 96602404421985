<template>
  <div class="nav-bar">
    <div class="nav-container">
      <div class="nav-left">
        <img @click="goHome" class="desktop-logo" src="./NavComponents/icons/bantaba2.svg" />
        <img class="mobile-logo" src="./NavComponents/icons/bantaba-mobile-logo.svg" />
      </div>
      <div class="nav-right">
        <ul v-if="authUser">
          <NavItem :title="$t('navBar.home')" :active="activeMenu('DashboardFr') || activeMenu('DashboardEn')"
            :link="`/${$i18n.locale}/${this.$i18n.t('routes.myActivity')}`" />
          <NavItem :title="$t('navBar.browseStartups')" :active="activeMenu('StartupsEn') || activeMenu('StartupsFr')"
            :link="`/${$i18n.locale}/startups`" />

          <NavItem :title="$t('navBar.community')" :active="activeMenu('NetworkFr') || activeMenu('NetworkEn')"
            :link="`/${$i18n.locale}/${this.$i18n.t('routes.network')}`" v-if="authUser && authUser.type === 1" />

          <NavItem :title="$t('navBar.community')" :active="activeMenu('DiasporaEn') || activeMenu('DiasporaFr')"
            :link="`/${$i18n.locale}/diaspora`" v-if="authUser && authUser.type === 2" />
          <NavItem :title="$t('navBar.messaging')" @click.native="handleToggle" />
          <NavItem @clicked="onTabClick('Notification')" title="Notifications" link="" />
          <li @click="openModal" class="nav-profile">
            <div class="profile-pic">
              <img class="rounded-circle" v-if="profile_img" :src="profile_img" alt="" @load="onImgLoad" />
              <svg class="ellipsis" xmlns="http://www.w3.org/2000/svg" width="6.968" height="24" viewBox="0 0 6.968 24">
                <path
                  d="M27.484,16.516A3.484,3.484,0,1,1,24,20,3.482,3.482,0,0,1,27.484,16.516ZM24,11.484A3.484,3.484,0,1,0,27.484,8,3.482,3.482,0,0,0,24,11.484Zm0,17.032a3.484,3.484,0,1,0,3.484-3.484A3.482,3.482,0,0,0,24,28.516Z"
                  transform="translate(-24 -8)" />
              </svg>
            </div>
            <span class="profile-label">Options</span>
          </li>
        </ul>
        <ul v-else>
          <NavItem :title="$t('navBar.login')" code="login"
            :link="`/${$i18n.locale}/${this.$i18n.t('routes.login')}`" />
          <NavItem :title="$t('navBar.register')" code="register"
            :link="`/${$i18n.locale}/${this.$i18n.t('routes.register')}`" />
        </ul>
        <template v-if="authUser">
          <NavModal />
          <NavDesktopModal />
          <button @click="closeDesktopNotification" :class="{ toggleVisibility: this.isNotifcationModal }"
            class="close-modal" />
          <div id="desktopNoty" class="notification-panel" :class="{
            toggleVisibility: this.isNotifcationModal,
            transition: this.isNotifcationModal,
          }">
            <ul>
              <li class="see-more-noty-li notification-header">
                <span class="noty-title-mobile">
                  <span class="text noty-title">Notifications</span>
                  <span class="noty-num">
                    <span class="unread">{{ $t('Unread')}}</span>
                    <span class="noty-title-badge"> {{
                    getUserNewNotifsCount
                    }}</span>
                  </span>
                </span>
              </li>

              <li class="notificationItem" v-for="(notification, index) in getUserNewNotifications.slice(
                0,
                10
              )" :key="index" :class="notification.viewed ? 'viewedNotif' : 'notViewedNotif'"
                @click="getRoute(notification)">
                <div class="noty-left">
                  <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                  <img class="noty-avatar"
                    src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                    v-else />
                </div>
                <div class="noty-right">
                  <span class="noty-text">
                    <span class="noty-text-bold">
                      {{ notification.name }}
                    </span>
                    <p>{{ $t(notification.text) }}</p>
                    <!-- <p v-html="$t(notification.text)"></p> -->

                    <span v-if="
                      notification.type === 'comment' ||
                      notification.type === 'like'
                    ">
                      <div class="post">
                        <!-- <span class="post-text" v-html="$t(notification.post.substring(0,30))+'...'"></span> -->
                        <span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0, 50)+'...'))
                        }}</span>
                      </div>

                    </span>

                  </span>
                  <!-- :href="`/${$i18n.locale}/profile/${notification.name}`" -->
                  <span v-if="notification.type === 'request'" class="request-btns-container">

                    <span class="noty-request-btn accept" @click="acceptRequest(notification.sender_id,notification,index)">
                      {{ $t('accept')}}
                    </span>
                    <!-- user_is {{notification.user_id}} -->
                    <button @click="goToProfile(notification.sender_id)" class="noty-request-btn view">{{
                    $t('viewProfile')}}
                    </button>
                  </span>
                  <span class="noty-time">
                    <Icon size="30" color="green">
                      <Clock />
                    </Icon>
                    <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                  </span>
                </div>
                <div v-if="!notification.viewed" class=" noty-dot"></div>
              </li>

              <template v-if="getUserNewNotifications.length === 0">
                <img class="no-notifs" src="../../assets/images/noData/no_notification.svg" />
                <p class="no-notif-text">{{ $t("You have no notifications") }}</p>
              </template>
              <li class="see-more-noty-li" v-if="getUserNewNotifications.length >= 0">
                <button @click="closeNotyPanelDesktop" type="button" class="see-more-noty">
                  {{ $t("allNotifications") }}
                </button>
              </li>
            </ul>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import APIURLS from "../../constant";
const NavItem = () => import("./NavComponents/NavItem.vue");
const NavModal = () => import("./NavModal.vue");
const NavDesktopModal = () => import("./NavDesktopModal.vue");
import i18n from "../../i18n";
import { mapGetters, mapActions, mapMutations } from "vuex";

import { io } from "socket.io-client";


import { Inbox, Clock } from "@v2icons/tabler";
import { Icon } from "@v2icons/utils";
export default {
  name: "NavBar",
  components: {
    NavItem,
    NavModal,
    NavDesktopModal,
    Clock,
    // Inbox,
    // Icon,
  },
  data() {
    return {
      profile_img: "",
      isLoaded: false,
      sockets: {},
      window: {
        width: 0,
        height: 0,
      },
      isNotifcationModal: false,
      isMobiletoggle: false,
      isDesktop: null,
      minValue: 4,
      newNotifs: [],
      msgNotifs: [],
      showNotificationPanel: false,
      check: null,
    };
  },
  sockets: {
    async private_chat(data) {
      await this.getNotifs();
    },
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserNotifications",
      "getisEmailVarified",
      "getUserNewNotifications",
      "getUserNewNotifsCount",
      "getUserLang",
      "getUserTalents"
    ]),
    path() {
      return this.$route.path;
    },
  },
  async mounted() {
    let profileUrl = this.authUser.avatar;
    if (profileUrl && profileUrl.indexOf("upload/") != -1) {
      let index = profileUrl.indexOf("upload/");
      let first_part = profileUrl.substr(0, index + 6);
      let last_part = profileUrl.substr(index + 6);
      this.profile_img =
        first_part + "/q_auto:low/c_fill,h_40,w_40" + last_part;
    } else {
      this.profile_img = this.posterAvatar;
    }

    this.getNotifs();

    this.sockets = io(APIURLS.CHATLINK);
    // console.log("linkedin socket = ", this.$socket);
    this.sockets.on("follow", (data) => {
      let startup_id = this.authUser.startup ? this.authUser.startup.id : "";
      if (data.startup_id === startup_id) {
        this.getNotifs();
      }
    });
    this.sockets.on("request", (data) => {
      if (data.user_id === this.authUser.id) {
        this.getNotifs();
      }
    });
    // await this.loadUserMsgNotifs({ user_id: this.authUser.id });

    this.minValue = 4;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  },
  created() {
    // window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }

    // window.addEventListener("beforeunload", () => alert("logouttt "));

    // window.addEventListener("beforeunload", this.leaving);
    //check if outside of the notifications modal is clicked

    // let divToHide = document.getElementById("desktopNoty");
    // document.onclick = function (e) {

    //   if (e.target.id !== "desktopNoty") {
    //     //element clicked wasn't the div; hide the div
    //     // divToHide.style.visibility = "hidden";
    //     //this.showNotificationPane = true;
    //   }
    // };
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions([
      "loadUserNotifications",
      "loadUserLatestNotifications",
      "setViewedNotif",
      "setAllNotifsToViewed",
      "destroyChatBox",
      "getUserById",
      "openChatBoxWithUser",
      "loadUserMsgNotifs",
      "getUsernameById",
      "deleteNotif",
      "loadUserExpertises",
      "getForeignCountryByLang",
      "getSingleUserById"
    ]),
    ...mapMutations(["handleToggle"]),

    async checkConnection(user_id) {
      await Axios.get(
        APIURLS.CONNECTIONS +
        "connected/user/" +
        user_id +
        "/auth_user/" +
        this.authUser.id
      )
        .then((response) => {
          this.check = response.data;
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },


    removeHtmlTags(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.innerText;
    },

    async acceptRequest(user_id, notification, index) {
      let data = {
        from: user_id,
        to: this.authUser.id,
        name: this.authUser.firstname + " " + this.authUser.name,
        lang: this.getUserLang,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
      };
      //check if users already connected
      await this.checkConnection(user_id);

      if (this.check === 'connected') {
        //if they are already connected
        this.$noty.success(this.$t('alreadyConnected'));
      } else {
        let expertise;
        let location;
        let lang = this.getUserLang;

        let livesInCode = this.authUser.profile
          ? this.authUser.profile.residenceContry
          : "";

        location = await this.getForeignCountryByLang({
          code: livesInCode,
          lang,
        });

        await this.loadUserExpertises({
          lang,
          user_id: this.authUser.id,
        });

        let user = await this.getSingleUserById({ user_id });
        let talents = this.getUserTalents;
        if (talents.length && talents[0].talents_name) {
          expertise = talents[0].talents_name;
        }
        data.email = user.email;
        data.expertise = expertise;
        data.location = location;
        data.sender_name = notification.name;
        data.sender_short_name = notification.name.split(" ")[0];
        data.receiver_short_name = this.authUser.firstname;
        data.full_name = this.authUser.firstname + ' ' + this.authUser.name;
        data.link = APIURLS.SITELINK;

        Axios.post(`${APIURLS.CONNECTIONS}accept`, data)
          .then(async ({ data }) => {
            //console.log('data', data);
            Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
            this.$noty.success("Success");
           
          })
          .catch((error) => {
            this.$noty.error(error.response.data.message);
          });
      }
      //then  delete the notification
      await this.deleteNotif({ notif_id: notification.id });
      //delete it from store
      this.getUserNewNotifications.splice(index, 1);


    },
    async goToProfile(user_id) {
      if (user_id) {
        let request = await this.getUsernameById({ user_id });
        // console.log({ request })
        let username = request.user;
        let slug = request.slug;
        let type = request.type;
        let url;
        if (type === 1) {
          url = `/${this.$i18n.locale}/profile/${username}`;
        } else {
          url = `/${this.$i18n.locale}/startup/${slug}`;
        }

        window.location.href = url;

        // alert(username);

      }
    },

    leaving(e) {
      //e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      // e.returnValue = "";
      // alert("leaving noww????");
      // try {
      //   //localStorage.removeItem("doneLogout");
      //   this.$auth.logout({ returnTo: home });
      //   window.alert("Sure to leave?");
      // } catch (e) {
      //   console.log(`Error while logging out: ${e}`);
      // }
    },
    async markReadNotifs() {
      let user_id = this.authUser.id;
      this.newNotifs = [];
      //this.$store.commit("setUserNewNotifications", []);
      await this.setAllNotifsToViewed({ user_id });
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserLatestNotifications({ user_id, take: 6 });

      // console.log({ getUserNotifications: this.getUserNotifications })
      this.$store.commit("setUserNewNotifications", this.getUserNotifications);
    },
    async getNotyUserById(userId) {
      new Promise((resolve, reject) => {
        Axios.get(APIURLS.USERS + "user-id/" + userId)
          .then(({ data, status }) => {
            // console.log({ data })
            if (status === 200) {
              const link = "/" + this.$i18n.locale + '/' + "profile/" + data.user;
              window.location.href = link;
              if (this.$route.path != link) {
                this.$router.push({ path: link });
              }
              resolve(true);

            }
          })
      });
    },
    async getRoute(notification) {

      // this.isMobiletoggle = !this.isMobiletoggle;
      this.isNotifcationModal = false;
      let url = "";
      let user_id = this.authUser.id;
      const notif_id = notification.id;
      await this.setViewedNotif({ user_id, notif_id });
      //alert(notification.type)
      if (notification.type === "like" || notification.type === "comment") {
        if (notification.post_uuid) {
          url = "/" + this.$i18n.locale + "/home/post_uuid=" + notification.post_uuid;
          window.location.href = url;
          if (this.$route.path != url) {
            this.$router.push({ path: url });
          }
        } else {
          return;
        }

      }
      else if (notification.type === "request") {
        return;
      }
      else if (notification.type === "view" || notification.type === "follow") {
        await this.goToProfile(notification.sender_id)
      }
      else {
        if (notification.post_uuid) {
          url = "/" + this.$i18n.locale + "/home/post_uuid=" + notification.post_uuid;
          // await this.setViewedNotif({ user_id, notif_id });
          // this.$router.push(url).catch(err => {}).catch(err => {});
          if (this.$route.path != url) {
            this.$router.push(url);
          }
        } else {
          this.$noty.error(this.$i18n.t("postNotFound"));
        }

      }
      // if (notification.type === "request") {
      //   url =
      //     "/" +
      //     this.$i18n.locale +
      //     "/" +
      //     this.$i18n.t("routes.network") +
      //     "/" +
      //     this.$i18n.t("routes.pendingRequests");
      // } else if (notification.type === "accept") {
      //   url =
      //     "/" +
      //     this.$i18n.locale +
      //     "/" +
      //     this.$i18n.t("routes.network") +
      //     "/connections";
      // } else if (notification.type === "follow") {
      //   url = "/" + this.$i18n.locale + "/" + this.$i18n.t("routes.followers");
      // }
      // //   else if (notification.type === "message") {
      // //     //console.log("notification", notification);
      // //     let user_id = notification.sender_id;
      // //     let user = await this.getUserById({ user_id });
      // //     await this.openChatBoxWithUser(user);
      // //   }
      // else {
      //   url = "/" + this.$i18n.locale + "/notifications";
      // }
    },
    activeMenu(name) {
      let urlName = this.$route.name;
      if (urlName === name) return "active";
    },
    goHome() {
      // this.$router.push("/").catch(err => {}).catch(err => {});
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    // openMessagePanel() {
    // },
    async onTabClick() {
      this.showNotificationPane = true;
      this.isNotifcationModal = !this.isNotifcationModal;
      if (this.isDesktop) {
        this.minValue = 4;
      } else {
        // this.isMobiletoggle = !this.isMobiletoggle;
        this.minValue = this.notifications.length;
      }

      let modal = document.getElementById("desktop-modal");
      // alert(modal)
      if (modal) {
        modal.style.visibility = "hidden";
      }
    },
    async closeDesktopNotification() {

      this.isNotifcationModal = !this.isNotifcationModal;
      //alert(this.isNotifcationModal)
      if (this.getUserNewNotifications.length) {
         await this.markReadNotifs();

      }
    },
    async closeNotyPanelDesktop() {
      this.isNotifcationModal = !this.isNotifcationModal;
      this.$router
        .push(`/${this.$i18n.locale}/notifications`)
        .catch((err) => { });
      if (this.minValue < this.notifications.length) {
        this.minValue = this.notifications.length;
      } else {
        this.minValue = 4;
      }
    },
    openModal() {

      if (this.window.width <= 600) {
        let modal = document.getElementById("mobile-modal");
        if (modal) {
          modal.style.visibility = "visible";
          modal.style.transform = "translateY(100%)";
          modal.style.transform = "scale(1, 1)";
          modal.style.transformOrigin = "bottom right";
        }
      } else {
        let modal = document.getElementById("mobile-modal");
        if (modal) {
          modal.style.visibility = "hidden";
        }
        let modalDesktop = document.getElementById("desktop-modal");
        if (modalDesktop) {
          modalDesktop.style.visibility = "visible";
        }
      }
    },
    openModalDesktop() {
      let notificationPanel = document.getElementById("desktopNoty");

      if (notificationPanel) {
        notificationPanel.style.display = "none !important";
        notificationPanel.style.visibility = "visible";
      }

      //   this.isDesktop  = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width > 600) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
    },
  },
};
</script>
<style scoped>
/* .viewedNotif{
  background-color: rgba(216, 216, 216, 0.488) !important;
} */
.post-text {
  color: #000000;
  font-weight: 500;
  font-style: italic;
}

.post {
  background-color: #cccccc6c;
  padding: 2px 5px;
  border: 1px solid #dddddd65;
  border-radius: 5px;
  margin-top: 3px;
}


.notification-header {
  border-bottom: 1px solid rgba(236, 236, 236, 0.767) !important;
}

.no-notifs {
  height: 30%;
  max-width: 200px;
  margin: 20px 0;
}

.no-notif-text {
  /* padding-bottom: 10px; */
  padding: 10px 0;
  font-weight: bold;
}

.no-notifs-web {
  width: 50%;
}

* {
  transition: all 0s ease-in-out;
}

*,
:after,
:before,
html {
  box-sizing: border-box;
}

#mobileNoty {
  visibility: hidden;
}

#desktopNoty {
  visibility: hidden;
}

.center-notification {
  width: 100%;
  position: absolute;
  max-width: 990px;
  top: 0;
  bottom: 0;
  pointer-events: none;
  /* position: relative; */
}

.center-notification .relative {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
}

.close-modal {
  position: fixed;
  background: rgba(198, 198, 198, 0.3);
  backdrop-filter: blur(0.2px);
  width: 100vw;
  height: 100vh;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  width: 100vw;
  visibility: hidden;
  border: none;
}

.toggleVisibility {
  visibility: visible !important;
}

.transition3 {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
  -webkit-transform: translateY(100%) !important;
  transform: translateY(100%) !important;
  box-shadow: 0px 0px 20px 0px #8b8bb736;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.center-v {
  display: flex;
  align-items: center;
}

.center-h {
  display: flex;
  justify-content: center;
}

.nav-bar {
  position: relative;
  width: 100%;
  background-color: var(--bg-white-color);
  max-height: var(--navbar-height-color);
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  overflow: hidden !important;
  border: 1px solid #7070701b;
  padding: 5px 16.5% !important;
  /* max-width: min(78%, 1140px); */
  zoom: 90%;
}

@media (min-width: 1200px) {
  .emailNotVerified {
    margin-top: 34px;
  }

  /* margin-top: 35px !important; */
}

/* .emailNotVerified {
} */

/* .nav-container{
    width: 100%;
    height: 100%;
    background-color: red;
} */
.flex-row {
  display: flex;
  flex-direction: row;
}

.nav-left {
  /* border: 1px solid rgba(255, 0, 0, 0.173); */
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  /* padding-left: calc((100% - 75%) / 2); */
}

.desktop-logo {
  display: block;
}

.mobile-logo {
  display: none;
}

.nav-right {
  /* border: 1px solid rgba(0, 0, 255, 0.125); */
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  /* padding-right: calc((100% - 68%) / 2); */
}

.nav-right ul {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}

.nav-profile {
  height: 100%;
  /* width: 100px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 15px;
  padding-right: 0;
  cursor: pointer;
}

.nav-profile:hover {
  color: #38a64e;
}

.nav-profile:hover .profile-pic img {
  border: 1px solid #38a64e;
}

.profile-pic {
  background-color: var(--skeleton-bg-color);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pic img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
}

.profile-pic svg {
  display: none;
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}

.notification-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* background-color: rgb(243, 243, 243); */
  /* height: 800px;  */
  /* width: 300px; */
  width: 360px;
  padding: 0;
  z-index: 1000 !important;
  top: 75px;
  right: calc((100vw - 990px) / 2);
  border-radius: 10px;
  /* overflow-x: hidden; */
  /* max-height: 85vh; */
  overflow-y: auto;
  box-shadow: 0 3px 7px rgb(0 0 0 / 5%);
  overflow-y: scroll;
  /* max-height: 550px; */
  max-height: 85vh;
  overflow-x: hidden;

}

.notification-panel ul {
  display: flex;
  flex-direction: column;
  background-color: white;
  /* background-color: rgb(243, 243, 243); */
  /* height: 800px;  */
  /* width: 300px; */
  width: 360px;
  padding: 0px 15;
  padding-top: 45px;
  /* top: 75px; */
  /* right: 320px; */
  border-radius: 10px;
  /* overflow-x: hidden; */
  /* max-height: 85vh; */
  overflow-y: auto;
  box-shadow: 0 3px 7px rgb(0 0 0 / 5%);
  overflow-y: scroll;
  /* max-height: 600px; */
  height: 100%;
  max-height: 800px;
  overflow-x: hidden;
  z-index: 1000 !important;
}

.notification-panel ul::-webkit-scrollbar {
  -webkit-appearance: inherit;
  width: 4px;
}

.notification-panel ul::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.notification-panel>ul>li {
  list-style: none;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  background-color: white;
  /* background-color: rgb(255, 255, 255); */
  margin: 2px 0;
  /* padding: 5px 0px; */
  padding: 5px 0px;
  width: 100%;
  cursor: pointer !important;
  width: 100%;
  /* background-color: red; */
}

.notification-panel>ul>li:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  padding: 0 5px;
  position: absolute;
  top: -2px;
  z-index: 9999;
  display: flex;
  align-items: center;
  background-color: white !important;
  left: 0;
  right: 0;
  /* position: ab */

}

.notification-panel li:first-child:hover {
  background-color: white;
}

.notification-panel li:hover {
  background-color: rgba(0, 128, 0, 0.064);
}

.notification-panel li:hover .noty-left svg {
  fill: #57b366;
}

.notification-panel li:hover .noty-right {
  color: #000000;
}

.noty-dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #57b366;
  margin: 0 5px;
}

.noty-left {
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #D9D9D9;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationItem {
  border-bottom: 1px solid rgba(153, 153, 153, 0.1) !important;
}

.noty-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  /* border: 1px solid rgba(0, 0, 0, 0.01); */
}

.noty-left svg {
  fill: #20412550;
}

.noty-right {
  /* background-color: rgb(255, 255, 255); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 105px);
  padding-left: 15px;
}

.noty-right span {
  text-align: left;
  /* font-size: 13px; */
  font-size: 15px;
  line-height: 19px;
  /* color: red !important; */
}

/* .noty-text {
  color: red !important;
  font-size: 10px !important;
} */
.noty-text>span {
  font-weight: bold;
}

.noty-text-bold {
  font-weight: bold;
}

.noty-time {
  color: rgb(141, 140, 140);
  display: flex;
  align-items: center;

}

.noty-time time {
  font-size: 14px;
}

.noty-time icon svg {
  width: 16px;
  margin-right: 4px;
}

.request-btns-container {
  display: flex;
  padding: 5px 0;
}

.noty-request-btn {
  padding: 3px 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 9px;

  font-weight: 400;
  font-size: 13.5px;
  line-height: 16px;
  margin-right: 5px;
}

.accept {
  background: #329E4D;
  color: white;

}

.view {
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
}

.view:hover {
  color: #FFFFFF;
  background: #329E4D;
}

.see-more-noty-li {
  /* background-color: hsl(0, 0%, 98%) !important; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 0px;
  width: 100% !important;
  background: rgba(50, 158, 77, 0.589) !important;
  height: 45px !important;
  /* padding: 10px 0; */
  /* position: absolute; */
  /* bottom: 0; */
}

.see-all-noty {
  background-color: transparent !important;
  color: #57b366;
  width: 100% !important;
}

.see-more-noty {
  font-size: 16px;
  width: 100%;
  height: 45px;
  color: white !important;
  font-size: 16px;
  line-height: 19px;
  background-color: transparent;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  /* position: absolute; */
  /* bottom: 0; */
  border: none;
  background: rgba(50, 158, 77, 0.25);
}

.see-more-noty-li:hover {
  /* background-color: #57b366 !important; */
  color: white !important;
  background: rgba(50, 158, 77, 1);

}

.see-more-noty:hover {
  color: white !important;
  background: rgb(50, 158, 77);
}

.noty-title {
  text-align: left !important;
  color: black !important;
  font-weight: bold !important;
  padding: 10px !important;
  /* background-color: #d0e9d4 !important; */
  width: 100% !important;
  font-size: 18px;

}

.text {
  position: relative;
  top: 3.5px;
  text-align: left !important;
  color: black !important;
  font-weight: bold !important;
  padding: 8px !important;
  line-height: 1;
  font-size: 21px;
}

.noty-title {
  font-weight: 700 !important;
  font-size: 23px !important;
  line-height: 28px !important;
  color: #329E4D !important;
}

.unread {
  font-weight: bold;
}

.noty-title-badge {
  display: inline-block;
  /* padding: 0.25em 0.4em; */
  padding: 2px 7px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #329E4D !important;
  /* background-color: var(--first-color) !important; */

  border-radius: 5px;

  font-size: 12px;
  line-height: 15px;



  /* background: #329E4D; */
  border-radius: 16px;
}

.noty-title-mobile {
  text-align: center !important;
  width: 100% !important;
  font-size: 16px;
  color: black !important;
  font-weight: bold !important;
  padding: 10px !important;
  display: flex;
  justify-content: space-between;
}

.noty-num {
  display: flex;
  align-items: center;
  color: #329E4D !important;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 16px;
  line-height: 25px;
  width: 88px;
  height: 24px;
  background: rgba(50, 158, 77, 0.21);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  /* background-color: green; */
}

/* skeleton animation keyframes*/
@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }

  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}

@media (min-width: 1200px) {
  .nav-bar {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    zoom: 100%;
    padding: 0 !important;
    max-height: 68px;
  }

  .nav-container {
    /* max-width: 1140px; */
    /* max-width: min(78%, 1140px); */
    max-width: 990px;
    /* max-width: 12267px; */
    width: 100%;
    height: 100%;
    position: relative;
    /* background: red; */
    display: flex;
  }
}

@media (min-width: 601px) and (max-width: 1199px) {
  .nav-right {
    padding-right: 0 !important;
  }

  .nav-left {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 1199px) {

  /* .nav-bar {
  } */
  .nav-right {
    padding-right: calc((100% - 73%) / 2);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

@media only screen and (max-width: 1200px) {
  .nav-left {
    width: 100%;
    height: 60px;
    padding-left: 0;
    overflow: hidden;
    padding: 0px 0;
    /* background-color: green; */
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .nav-bar {
    width: 100%;
    background-color: var(--bg-white-color);
    max-height: var(--navbar-height-color);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: auto;
    bottom: 0 !important;
    left: 0;
    z-index: 10000;
    overflow: hidden !important;
    border: 1px solid #7070701b;
    padding: 5px 0 !important;
  }

  .nav-left {
    width: 100%;
    height: 60px;
    padding-left: 0;
    overflow: hidden;
    padding: 0px 0;
    display: none !important;
    /* background-color: green; */
  }

  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .nav-right {
    width: 100%;
    padding-right: 0;
    position: fixed;
    bottom: 0;
    background-color: white;
    display: -webkit-box;
    justify-content: space-between !important;
    -webkit-transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: inset 0px -1px 1px #eaeef3;
    background-color: rgba(255, 255, 255, 0.72);
    /* z-index: 900000 !important; */
  }

  .nav-right ul {
    width: 100%;
    justify-content: space-between;
  }

  .profile-label {
    display: none;
  }

  .rounded-circle {
    display: none;
  }

  .profile-pic {
    border: none;
    background-color: transparent;
  }

  .profile-pic svg {
    display: block;
  }

  .profile-pic path {
    fill: #8d8d8d;
  }

  .nav-profile:hover .profile-pic img {
    border: 1px solid #38a64e;
  }

  ul.notification-panel {
    position: absolute !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: rgb(243, 243, 243);
    height: 100vh;
    border-radius: 10px;
    overflow-y: auto;
    z-index: 9990000000;
    top: calc(-100vh + 70px);
    /* transform: translateY(90vh); */
    right: 0;
    left: 0;
    /* padding: 10px; */
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding-bottom: 50px;
    justify-content: normal;

    /* position: fixed !important; */
    /* top: 0; */
    transition: all 0.2s ease-in-out;



  }

  ul.notification-panel li:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  ul.notification-panel li:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  /* .see-more-noty-li {
    background-color: #ffffff !important;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    text-align: right !important;
    padding-right: 20px !important;
  } */

  #mobileNoty {
    visibility: hidden;
  }

  #desktopNoty {
    visibility: hidden;
  }

  .notification-panel {
    padding: 0;
    top: -100vh !important;
    right: 0 !important;
    left: 0 !important;
    border-radius: 0px !important;
    overflow-y: auto;
    overflow-y: scroll;
    max-height: 100vh;
    width: auto !important;
    min-height: 100vh;
  }

  .notification-panel ul {
    max-height: 100vh;
  }

  .see-more-noty {
    font-size: 16px;
    width: 100%;
    height: 45px;
    color: #329E4D;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
    /* position: absolute; */
    /* bottom: 0; */
    border: none;
    border-radius: 0;
    background: rgba(50, 158, 77, 0.25);
  }

  .see-more-noty-li {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 0px;
    width: 100% !important;
    background: rgba(50, 158, 77, 0.25) !important;
    height: 45px !important;
    padding-left: 0;
    padding-right: 0;

  }
}
</style>

<style>
::-webkit-scrollbar {
  -webkit-appearance: inherit;
  width: 4px !important;
}

.notification-panel>ul::-webkit-scrollbar {
  display: inherit !important;
}

.notification-panel>ul::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  border: none !important;
  background-color: #9a9a9a !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>