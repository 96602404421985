import { render, staticRenderFns } from "./JobsList.vue?vue&type=template&id=db8829ea&scoped=true&"
import script from "./JobsList.vue?vue&type=script&lang=js&"
export * from "./JobsList.vue?vue&type=script&lang=js&"
import style0 from "./JobsList.vue?vue&type=style&index=0&id=db8829ea&prod&scoped=true&lang=css&"
import style1 from "./JobsList.vue?vue&type=style&index=1&id=db8829ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db8829ea",
  null
  
)

export default component.exports