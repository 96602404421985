<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="showcase">
    <div class="contents">
      <Avatar
        :avatar="getProfile.avatar"
        class="avatar"
        initials="SB"
        bg-color="#ffc107"
      />
      <div class="intro">
        <Name
          :name="name"
          :showConnectButton="basicInfos.showConnectButton"
          :user="user"
          :status="status"
        />
        <Experience
          :experience="basicInfos.experienceYears"
          class="desktop-only"
        />
      </div>
      <div class="indicators">
        <div v-if="basicInfos.living" class="indicator">
          <Location :livesIn="basicInfos.living" />
        </div>
        <div v-if="basicInfos.origin" class="indicator">
          <Origin :origin="basicInfos.origin" />
        </div>
        <div class="indicator desktop-only">
          <Certificate
            v-if="basicInfos.educationLevel"
            :educationLevel="basicInfos.educationLevel"
          />
        </div>
      </div>

      <Outline class="mobile-only" />
      <FeatureButton
        :showConnectButton="basicInfos.showConnectButton"
        :user="user"
        :status="status"
        class="mobile-only d-flex justify-content-center"
      />
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar";
import Outline from "../showcase/Outline";
import Name from "../showcase/Name";
import Experience from "../showcase/Experience";
import Location from "../showcase/indicators/Location";
import Origin from "../showcase/indicators/Origin";
import Certificate from "../showcase/indicators/Certificate";
import FeatureButton from "../buttons/FeatureButton";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    FeatureButton,
    Avatar,
    Name,
    Experience,
    Location,
    Origin,
    Certificate,
    Outline,
  },
  props: {
    avatar: String,
    name: String,
    experience: String,
    basicInfos: Object,
    user: Object,
    status: String,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "getConnectionStatus",
      "getProfileSections",
      "getUserSupportTypes",
    ]),
    connected: function () {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.showcase {
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  padding: 0 30px 10px;

  @include max-width(991) {
    border-radius: 30px; // This value must be the same as the border-radius value of .main-wrapper (991) class in Profile.vue
    padding: 0 0 10px;
  }

  .contents {
    // .avatar {
    //   position: absolute;
    //   transform: translateY(-115px);
    //   z-index: 5;

    //   @include max-width(991) {
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     box-shadow: none;
    //   }
    // }

    .intro {
      position: relative;
      z-index: 1000;
      margin-top: -120px;
      padding-top: 15px;
      padding-bottom: 5px;
      // border: 1px solid red !important;
      @include max-width(991) {
        padding-top: 75px;
      }
    }

    .indicators {
      display: flex;
      justify-content: space-between !important;
      flex-wrap: wrap;
      border: 1px solid rgba(112, 112, 112, 0.2);
      border-radius: 10px;
      padding: 7.5px;
      margin-bottom: 5px;
      position: relative;
      z-index: 1000;

      @include max-width(410) {
        padding: 0;
      }

      @include max-width(991) {
        border: none;
      }

      .indicator {
        width: 33.33333%;
        // padding: 0 10px;

        @include max-width(991) {
          width: 50%;
        }

        @include max-width(410) {
          padding: 0 5px;
        }
      }
    }
  }
}
</style>
