<template>
  <div>
    <div class="alert alert-danger m-0" v-if="showVerified">
      {{ $t('profileNonVerifiedText') }}
      <!-- <a href="#">
        {{ $t('verify') }}
      </a> -->
    </div>
  </div>
</template>
<script>
import Axios from 'axios';
import APIURLS from '../constant';
export default {
  created() {},
  computed: {},
  data() {
    return {};
  },
  props: {
    showVerified: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  name: 'ActivateAccount',
};
</script>
