<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="camera">
    <imageUploadModal :isShow="isUploadModal" :loading="loading" :showPreview="showPreview" @closeModal="closeModal"
      @showPreviewTrue="showPreviewTrue" @submit="submit" :startVal="startVal" :endVal="endVal" :decimals="decimals"
      :options="options" />
    <!-- <cld-image
      v-if="!showPreview"
      class="img-profile rounded-circle"
      cloudName:bantaba
      :publicId="userAvatar"
      alt="Image Placeholder"
    >
      <cld-transformation height="auto" width="auto" crop="scale" />
      <cld-transformation border="5px_solid_red" radius="20" />
    </cld-image> -->

    <img v-if="!showPreview && !showEditButtons" class="img-profile rounded-circle" :src="userAvatar" alt="logo"
      width="100" />

    <template v-if="showEditButtons">
      <label class="camera btn absolute-pose" for="avatar-file" v-if="!showPreview" @click="triggerImgModalTrue">
        <img src="@/assets/images/profile/camera.svg" width="24" height="24" />
      </label>

      <VueCropper ref="cropper" :aspect-ratio="1 / 1" :src="userAvatar" :viewMode="1" preview=".preview"
        :img-style="{ width: '400px', height: '300px', marginTop: '101px' }" v-else />
      <!-- <input
        id="avatar-file"
        name="file"
        type="file"
        accept="image/*"
        class="avatar-file"
        @change="onFileChange"
      /> -->
      <form enctype="multipart/form-data" action="#" @submit.prevent="submit">
        <div class="row upload-btn-row">
          <div class="col-md-6 mr-2">
            <button v-if="buttonChoice === 'upload'" data-toggle="tooltip" data-placement="top"
              data-original-title="Upload" type="submit" :disabled="loading" class="btn upload-btn btn-success">
              <i class="fa fa-spin fa-2x fa-spinner" v-if="loading"></i>
              <i class="feather-upload-cloud" v-else></i>
            </button>
          </div>
          <div class="col-md-6">
            <button v-if="buttonChoice === 'upload'" data-toggle="tooltip" data-placement="top"
              data-original-title="Delete" type="submit" @click="cancel" class="btn upload-btn btn-danger">
              <i class="feather-trash-2"></i>
            </button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../../constant";
import store from "../../../store";
import { mapGetters, mapActions } from "vuex";
import imageUploadModal from "../../Profile/Diaspora/profile/main/imageUploadModal.vue";
export default {
  mounted() {
    store.commit("SET_IMAGE_MODAL", false);
    this.savedAvatar = this.avatar;
    this.userAvatar = this.avatar;

    let url = this.avatar;
    if (url && url.indexOf("upload/") > -1) {
      var index = url.indexOf("upload/");
      var first_part = url.substr(0, index + 6);
      var last_part = url.substr(index + 6);
      // eslint-disable-next-line vue/no-mutating-props
      this.avatar = first_part + "/q_auto:low/c_fill,h_50,w_50" + last_part;
      this.userAvatar = this.avatar;
    } else {
      this.userAvatar = this.avatar;
    }
  },
  props: {
    initials: String,
    bgColor: String,
    avatar: String,
  },
  computed: {
    ...mapGetters(["authUser", "getProfile"]),
    bgColorCss: function () {
      return {
        background: this.bgColor,
      };
    },
    ...mapGetters([
      "authUser",
      "getProfile",
      "getProfileEditingMode",
      "showEditButtons",
      "getStartupEditingMode",
    ]),
  },
  data() {
    return {
      isActive: false,
      startVal: 0,
      endVal: 0,
      decimals: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 3,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      showPreview: false,
      cropImg: "",
      buttonChoice: "select",
      image: null,
      file: "",
      fileContents: null,
      cloudName: "bantaba",
      preset: "Startups-logos",
      formData: null,
      loading: false,
      savedAvatar: "",
      userAvatar: "",
      isUploadModal: false
    };
  },
  methods: {
    triggerImgModalTrue() {
      store.commit("SET_IMAGE_MODAL", true);
      this.isUploadModal = true;
      console.log(store.state.isImageModal.open);
    },
    showPreviewTrue(value) {
      this.showPreview = value;
    },
    closeModal() {
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isUploadModal = false;
      store.commit("SET_IMAGE_MODAL", false);
      console.log(this.isUploadModal)
      var percentageComplete = 0;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
      this.startVal = 0;
      this.endVal = 0;
      this.cropImg = "";
      this.image = null;
      this.formData = null;
    },
    ...mapActions(["loadProfile", "updateProfile"]),
    onFileChange(e) {
      this.showPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.userAvatar = URL.createObjectURL(file);
      this.buttonChoice = "upload";
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropImg);
    },
    submit(obj) {
      // perform the crop
      this.cropImg = obj.cropedImageData;
      this.startVal = 0;
      this.endVal = 0;
      this.loading = true;
      var zeroPercentageComplete = 0;
      var zeroStrokeDashOffsetValue = 100 - zeroPercentageComplete * 100;
      var progressBar = document.querySelector(".js-progress-bar");
      progressBar.style = `stroke-dashoffset: ${zeroStrokeDashOffsetValue}`;
      var percentageComplete = 0.5;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var afterPercentageComplete = 1;
      var afterStrokeDashOffsetValue = 100 - afterPercentageComplete * 100;
      setTimeout(() => {
        this.startVal = 0;
        this.endVal = 50;
        progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
        console.log(this.startVal, this.endVal);
      }, 100);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.results = response.data;
              this.image = this.results.secure_url;

              this.$store
                .dispatch("updateProfile", { avatar: this.image })
                .then((success) => {
                  this.startVal = 50;
                  this.endVal = 100;

                  progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
                  setTimeout(() => {
                    let auth = JSON.parse(localStorage.getItem("auth"));
                    auth.avatar = this.image;
                    this.userAvatar = this.image;
                    localStorage.removeItem("auth");
                    localStorage.setItem("auth", JSON.stringify(auth));
                    this.$store.commit("setAuthUser", auth);
                    this.image = null;
                    this.showPreview = false;
                    this.$noty.success(this.$i18n.t("avatarUpdated"));
                    this.loading = false;
                    this.buttonChoice = "select";
                    this.closeModal();
                  }, 3800);
                })
                .catch((error) => {
                  this.startVal = 50;
                  this.endVal = 100;
                  setTimeout(() => {
                    this.loading = false;
                    this.errors.push(error);
                    this.showPreview = false;
                    this.closeModal();
                  }, 3800);
                });
            })
            .catch((error) => {
              this.loading = false;
              this.errors.push(error);
            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(obj.file);
    },
    cancel() {
      store.commit("SET_IMAGE_MODAL", false);
      this.userAvatar = this.avatar;
      this.showPreview = false;
      this.buttonChoice = "select";
    },
  },
  components: {
    imageUploadModal
  },
};
</script>

<style scoped>
.cropper-wrap-box {
  height: 100px !important;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.cropper-point.point-se {
  top: calc(85% + 1px);
  right: 14%;
}

.cropper-point.point-sw {
  top: calc(85% + 1px);
  left: 14%;
}

.cropper-point.point-nw {
  top: calc(15% - 5px);
  left: 14%;
}

.cropper-point.point-ne {
  top: calc(15% - 5px);
  right: 14%;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
</style>
<style lang="scss" scoped>
.upload-btn-row {
  width: 100% !important;
  padding-left: 0px;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  border: 5px solid #fff;
  // box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  font-size: 45px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  // .camera {
  //   display: show;
  // }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .absolute-pose {
    position: absolute;
  }

  input {
    display: none;
  }

  .edit-profile & {
    .camera {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(233, 233, 233, 0.81);

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
