<template>
  <div class="app">
    <div>
      <main class="page-content">
        <AutoLogout v-if="authUser" />
        <MessagingList v-if="authUser" />
        <router-view :key="$route.fullPath" />
      </main>

      <GoTop :size="50" weight="bold" bgColor="#cfead9" />
      <Footer />
      <Navigation />
      <ReferalModal :time-out-show="20000" v-if="showRef" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from '../store'
import GoTop from "@inotom/vue-go-top";
import i18n from "../i18n";
import APIURLS from "../constant.js";

import ReferalModal from "./../components/Modal/ReferalModal";

import AlertBar from "../components/isEmailVerified/Alertbar.vue";

export default {
  async mounted() {
    // console.log("authuser default = ", this.authUser);

    this.showRef = await this.loadShowReferral({ user_id: this.authUser.id });
    if (this.authUser) {
      await this.loadprofileStrength();
      this.points = this.getprofileStrength;
    }
  },
  metaInfo() {
    return {
      meta: [
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  watch: {
    modalState() {
      console.log(this.modalState)
    }
  },
  data() {
    return {
      points: 0,
      showRef: false,
    };
  },
  computed: {
    ...mapGetters(["authUser", "getprofileStrength", "getUserLang"]),
    modalState() {
      return store.state.isImageModal.open
    }
  },
  methods: {
    ...mapActions(["loadprofileStrength", "sendVerificationEmail", "loadShowReferral",]),
    async sendEmail() {
      let data = {
        email: this.authUser.email,
        emailData: {
          lang: this.getUserLang,
          subject: this.$i18n.t("thanksForSignup"),
          buttonText: this.$i18n.t("verifyEmail"),
          email: this.authUser.email,
          link: `${APIURLS.SITELINK}${i18n.locale}/activate/`,
          text: this.$i18n.t("confirmEmailText"),
          title: this.$i18n.t("thanksForSignup"),
          name: this.authUser.firstname + " " + this.authUser.name,
          thanks: this.$i18n.t("thanks"),
        },
      };
      this.sendVerificationEmail({ data })
        .then((response) => {
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(this.errorMsg);
        });
    },
  },
  name: "DefaultLayout",
  components: {
    GoTop,
    ReferalModal,
  },
};
</script>
<style scoped>
.app {
  /* transform: scale(0.75); */
}
.btn-white {
  border: 1px solid #fff;
  color: #fff !important;
  padding: 2px 5px;
}

.btn-white:hover {
  background: #fff !important;
  color: #f26522 !important;
}
.colorDefault {
  background-color: #f26522 !important;
  color: #fff !important;
}
.notice {
  font-size: 14px;
  line-height: 36px;
  font-weight: 500;
  text-align: center;
  width: 100% !important;
  position: fixed !important;
  padding-left: 4px;
  padding-right: 28px;
  z-index: 1000001;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-radius: 8px 0 0;
  -webkit-box-shadow: var(--elevation-low);
  box-shadow: var(--elevation-low);
}
.vue-go-top {
  /* background: #cfead9; */
  /* background: var(--first-color) !important; */
  color: white !important;
}
.vue-go-top:hover {
  background-color: var(--first-color) !important;
}
.page-content {
  width: 100vw;
  min-height: calc(100vh - 95px);
  min-height: 100vh;
  background-color: #f0f4f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.alert {
  z-index: 1 !important;
  position: relative;
}
.page-content {
  padding-top: 50px !important;
  overflow: hidden;
}
@media (max-width: 576px) {
  .page-content {
    padding-top: 0px !important;
    overflow: hidden;
  }
}
</style>
