var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border rounded bg-white mb-3"},[_c('div',{staticClass:"box-title border-bottom p-3"},[_c('h6',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$t(_vm.name)))])]),_c('div',{staticClass:"box-body p-3"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 mb-2"},[_c('div',{staticClass:"js-form-message"},[_c('label',{staticClass:"form-label"}),_c('div',{staticClass:"form-group"},[_c('editor',{attrs:{"apiKey":"frsb2ky82bt6d80huabhezpgj47c9ktx01iha9lm1kpav85q","init":{
                  height: this.height,
                  menubar: true,
                  themes: 'inlite',
                  plugins: [
                    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                    'searchreplace wordcount visualblocks visualchars code fullscreen',
                    'insertdatetime media nonbreaking save table contextmenu directionality',
                    'emoticons template paste textcolor colorpicker textpattern',
                  ],
                  toolbar:
                    'insertfile undo redo paste  spellchecker pagebreak visualblocks visualchars anchor autolink charmap help linkchecker nonbreaking legacyoutput tabfocus | styleselect | bold italic | forecolor backcolor | restoredraft |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | advlist | link image media | emoticons | fullpage | preview | hr | insertdatetime | searchreplace',
                }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)])])]),_c('div',{staticClass:"mb-3 text-right"},[_c('div',{staticClass:"overflow-hidden text-center p-3"},[_c('button',{staticClass:"font-weight-bold btn btn-light rounded p-3 btn-block",attrs:{"disabled":_vm.loading,"type":"submit"}},[(_vm.loading)?_c('i',{staticClass:"fa fa-spin fa-1x fa-spinner"}):_vm._e(),_vm._v(" "+_vm._s(_vm.loading ? '' : _vm.$t('save'))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }