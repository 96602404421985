import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=3bb147d8&scoped=true&"
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"
import style0 from "./Create.vue?vue&type=style&index=0&id=3bb147d8&prod&scoped=true&lang=css&"
import style1 from "./Create.vue?vue&type=style&index=1&id=3bb147d8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bb147d8",
  null
  
)

export default component.exports