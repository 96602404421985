<template>
  <div class="page profile-settings">
    <div class="page-container">
      <div class="profile-settings_menu">
        <div class="profile-settings_menu-scroll">
          <template v-if="authUser.type === 1">
            <div
              v-for="item in menuItemsDiaspora"
              :key="item.key"
              :class="{ active: selected === item.key }"
              class="profile-settings_menu_item"
              @click="selectMenuItem(item)"
            >
              {{ item.label }}
            </div>
          </template>
          <template v-else-if="authUser.type === 2">
            <div
              v-for="item in menuItemsStartup"
              :key="item.key"
              :class="{ active: selected === item.key }"
              class="profile-settings_menu_item"
              @click="selectMenuItem(item)"
            >
              {{ $t(item.label) }}
            </div>
          </template>
        </div>
      </div>
      <div class="profile-settings_menu-content">
        <template v-if="selected === 'password'">
          <!--TODO: контент для паролю-->
          <h2 class="content-title">{{ $t("changePassword") }}</h2>
          <div class="menu-content-container">
            <alert
              :show="passwordAlertShow && passwordAlertShowConf"
              variant="danger"
              @hide="passwordAlertShowConf = false"
            >
              <img
                alt=""
                class="img-warn"
                src="/assets/images/profile-settings/alert-danger-icon.png"
              />
              {{ $t("passwordNotMatch") }}
            </alert>
            <input-password
              v-model="password"
              :class="passwordValidClass"
              label="newPassword"
            />
            <input-password v-model="confirmPassword"
            :class="passwordValidClass" label="confirmPassword" @paste.prevent
            onselectstart="return false" onCopy="return false" onCut="return
            false" autocomplete=off/ v-on:keyup="passwordValidClass" />
            <div class="block-btn">
              <v-button
                :disabled="passwordValidClass === 'valid' ? false : true"
                :variant="passwordValidClass === 'valid' ? 'green' : 'disabled'"
                @click="submit"
              >
                <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                {{ loading ? $t("saving") : $t("save") }}
              </v-button>
              <v-button :variant="'red'" @click="clearPasswordInputs">{{
                $t("cancel")
              }}</v-button>
            </div>
          </div>
        </template>
        <template v-else-if="selected === 'email'">
          <!--TODO: контент для емейлу-->
          <h2 class="content-title">
            {{ $t("changeEmail") }}
          </h2>

          <div class="menu-content-container">
            <alert :show="true" :show-close-button="false" variant="info">
              <b>{{ $t("currentEmail") }}: {{ authUser.email }}</b>
            </alert>
            <input-email
              v-model="email"
              :class="emailValidClass"
              label="newEmail"
            />
            <input-email v-model="confirmEmail" :class="emailConfirmValidClass"
            label="confirmEmail" @paste.prevent onselectstart="return false"
            onCopy="return false" onCut="return false" autocomplete=off/
            v-on:keyup="validConfirmEmail" />
            <div class="block-btn">
              <v-button
                :disabled="!emailButtonValid"
                :variant="emailButtonValid ? 'green' : ''"
                @click="changeEmail"
              >
                <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                {{ loading ? $t("saving") : $t("save") }}</v-button
              >
              <v-button :variant="'red'" @click="clearEmailInputs">{{
                $t("cancel")
              }}</v-button>
            </div>
          </div>
        </template>
        <template v-else-if="selected === 'language'">
          <div class="menu-content-container">
            <h2 class="mb-5 content-title">{{ $t("selectYourLanguage") }}</h2>
            <v-select
              v-model="language"
              :options="languages"
              :selectedValue="getUserLang"
            />
            <v-button variant="green" @click="saveLang">
              <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
              {{ loading ? $t("saving") : $t("save") }}</v-button
            >
          </div>
        </template>
        <template
          v-else-if="selected === 'notifications' && authUser.type === 2"
        >
          <!--TODO: контент для сповіщень-->
          <h2 class="content-title">
            {{ $t("setYourNotifs") }}
          </h2>
          <div class="container-notification">
            <div class="notification-items">
              <div class="d-flex align-items-center">
                <img
                  alt="image"
                  src="@/assets/images/profile-settings/feather-message-circle.svg"
                />
                <span class="title-notification">
                  {{ $t("DirectMessage") }}
                </span>
              </div>
              <div class="check-box-item">
                <check-box v-model="authUser.DmDaily" label="Daily" />
                <check-box v-model="authUser.DmWeekly" label="Weekly" />
              </div>
            </div>
          </div>
          <div class="container-notification">
            <div class="notification-items-content">
              <div class="notification-items-activity">
                <div>
                  <span class="title-notification"
                    >{{ $t("ActivityRoundUp") }}
                  </span>
                </div>
                <div class="check-box-item">
                  <check-box v-model="authUser.DmConReqDaily" label="Daily" />
                  <check-box v-model="authUser.DmConReqWeekly" label="Weekly" />
                </div>
              </div>
              <div class="active-group">
                <div>
                  <div class="mg-bottom">
                    <img
                      alt="image"
                      src="@/assets/images/profile-settings/awesome-eye.svg"
                    />
                    <span class="active-group-txt">
                      {{ $t("PagesViews") }}</span
                    >
                  </div>
                  <div>
                    <img
                      alt="image"
                      src="@/assets/images/profile-settings/metro-users.svg"
                    />
                    <span class="active-group-txt"
                      >{{ $t("NewFollowers") }}
                    </span>
                  </div>
                </div>
                <div>
                  <div class="mg-bottom">
                    <img
                      alt="image"
                      src="@/assets/images/profile-settings/awesome-star.svg"
                    />
                    <span class="active-group-txt">{{
                      $t("NewFollowers")
                    }}</span>
                  </div>
                  <div class="fire-item">
                    <img
                      alt="image"
                      src="@/assets/images/profile-settings/simple-codeigniter.svg"
                    />
                    <span class="active-group-txt"
                      >{{ $t("NewManagedTalents") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!--TODO: контент для сповіщень-->
          <h2 class="content-title">
            {{ $t("setYourNotifs") }}
          </h2>
          <div class="container-notification-1">
            <div class="notification-items-1">
              <div class="item-notification-1">
                <div class="d-flex align-items-center">
                  <img
                    alt=""
                    class="mr-3-img"
                    src="@/assets/images/profile-settings/feather-message-circle.svg"
                  />
                  <span class="title-notification-1"
                    >{{ $t("DirectMessage") }}
                  </span>
                </div>
                <div class="d-flex justify-content-around">
                  <check-box v-model="authUser.DmDaily" label="Daily" />
                  <check-box v-model="directMessage.weekly" label="Weekly" />
                </div>
              </div>
              <div class="item-notification-1">
                <div class="d-flex align-items-center">
                  <img
                    alt=""
                    class="mr-3-img"
                    src="@/assets/images/profile-settings/material-group-add.svg"
                  />
                  <span class="title-notification-1"
                    >{{ $t("ConnectionRequest") }}
                  </span>
                </div>
                <div class="d-flex justify-content-around">
                  <check-box v-model="connectionRequest.daily" label="Daily" />
                  <check-box
                    v-model="connectionRequest.weekly"
                    label="Weekly"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="container-notification-1">
            <div class="notification-items-1">
              <div class="item-notification-1 border-bottom">
                <div>
                  <span class="title-notification-1"
                    >{{ $t("NewStartups") }}
                  </span>
                </div>
                <div class="d-flex justify-content-around">
                  <check-box v-model="newStartups.daily" label="Daily" />
                  <check-box v-model="newStartups.weekly" label="Weekly" />
                </div>
              </div>
              <div class="item-notification-1 column">
                <div class="text-left up">
                  <img
                    alt=""
                    class="mr-2-img"
                    src="@/assets/images/profile-settings/material-wifi-tethering.svg"
                  />
                  <span class="item-text">
                    {{ $t("InYourSectorOfInterrest") }}
                  </span>
                </div>
                <div class="text-left">
                  <img
                    alt=""
                    class="mr-2-img"
                    src="@/assets/images/profile-settings/metro-search.svg"
                  />
                  <span class="item-text"
                    >{{ $t("LookingForPeopleWithYourExpertise") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InputPassword from "@/components/PasswordEmail/InputPassword";
import InputEmail from "@/components/PasswordEmail/InputEmail";
import VSelect from "@/components/Select/VSelect";
import VButton from "@/components/Buttons/VButton";
import Alert from "@/components/Alert/Alert";
import CheckBox from "@/components/CheckBox/CheckBox";

import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  metaInfo() {
    let name = "";
    let hash = window.location.hash;
    if (hash === "#password") name = "Password";
    if (hash === "#email") name = "Email";
    if (hash === "#language") name = "Language";
    if (hash === "#notifications" || hash === "#notifications1")
      name = "Notifications";
    return {
      title: "Bantaba | Personal Settings | " + name,
    };
  },
  name: "PageTemplate",
  components: { CheckBox, Alert, VSelect, InputEmail, VButton, InputPassword },
  created() {
    this.$i18n.availableLocales.map((lang) => {
      this.languages.push({
        key: lang,
        label: this.getLang(lang),
      });
    });
  },
  mounted() {
    // console.log(this.$store.state.profileSettings.directMessage);
  },
  data: () => ({
    form: {},
    error: "",
    loading: false,
    passwordAlertShowConf: false,
    selected: "password",
    languages: [],
    languages1: [
      { key: "en", label: "English" },
      { key: "fr", label: "French" },
    ],

    menuItemsDiaspora: [
      { label: "Password", key: "password" },
      { label: "Email", key: "email" },
      { label: "Language", key: "language" },
      { label: "Notifications", key: "notifications1" },
    ],
    menuItemsStartup: [
      { label: "Password", key: "password" },
      { label: "Email", key: "email" },
      { label: "Language", key: "language" },
      { label: "Notifications", key: "notifications" },
    ],
  }),
  beforeMount() {
    if (!window.location.hash) window.location.hash = this.selected;
    else this.selected = (window.location.hash || "#password").substring(1);
  },
  computed: {
    ...mapGetters(["authUser", "getProfile", "getUserLang"]),
    ...mapState({
      currentEmail: (state) => state.profileSettings.currentEmail,
    }),
    email: {
      get() {
        return this.$store.state.profileSettings.email;
      },
      set(value) {
        this.setEmail(value);
      },
    },
    confirmEmail: {
      get() {
        return this.$store.state.profileSettings.confirmEmail;
      },
      set(value) {
        this.setConfEmail(value);
      },
    },
    password: {
      get() {
        return this.$store.state.profileSettings.password;
      },
      set(value) {
        this.setPassword(value);
      },
    },
    confirmPassword: {
      get() {
        return this.$store.state.profileSettings.confirmPassword;
      },
      set(value) {
        this.setConfPassword(value);
      },
    },
    language: {
      get() {
        return this.$store.state.profileSettings.language;
      },
      set(value) {
        this.setLanguage(value);
      },
    },
    directMessage: {
      get() {
        return this.$store.state.profileSettings.directMessage;
      },
      set(value) {
        this.setDirectMessage(value);
      },
    },
    activityAroundUp: {
      get() {
        return this.$store.state.profileSettings.activityAroundUp;
      },
      set(value) {
        this.activityAroundUp(value);
      },
    },
    newStartups: {
      get() {
        return this.$store.state.profileSettings.newStartups;
      },
      set(value) {
        this.setNewStartups(value);
      },
    },
    connectionRequest: {
      get() {
        return this.$store.state.profileSettings.connectionRequest;
      },
      set(value) {
        this.setConnectionRequest(value);
      },
    },
    passwordAlertShow() {
      return !!(
        this.password &&
        this.confirmPassword &&
        this.confirmPassword !== this.password
      );
    },
    passwordValidClass() {
      if (!this.password || !this.confirmPassword) return false;
      return this.passwordAlertShow ? "invalid" : "valid";
    },
    validEmail() {
      return !!this.email && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email);
    },
    validConfirmEmail() {
      return (
        !!this.confirmEmail && /(.+)@(.+){2,}\.(.+){2,}/.test(this.confirmEmail)
      );
    },
    emailsEqual() {
      return (
        !!this.email && !!this.confirmEmail && this.email === this.confirmEmail
      );
    },
    emailButtonValid() {
      return this.validEmail && this.validConfirmEmail && this.emailsEqual;
    },
    emailValidClass() {
      if (!this.email || !this.confirmEmail) return "";
      return this.validEmail && this.emailsEqual ? "valid" : "invalid";
    },
    emailConfirmValidClass() {
      if (!this.confirmEmail) return "";
      return this.validConfirmEmail && this.emailsEqual ? "valid" : "invalid";
    },
  },
  watch: {
    ["authUser.DmDaily"]: async function (value) {
      this.connectionRequest.daily = value;
      await this.changeNotifisSettings({
        type: "DmConReqDaily",
        action: value === true ? 1 : 0,
      });
    },
    ["directMessage.weekly"]: function (value) {
      this.connectionRequest.weekly = value;
      this.authUser.DmWeekly;
      this.authUser.DmConReqWeekly;
    },
    ["connectionRequest.daily"]: function (value) {
      this.directMessage.daily = value;
      this.authUser.DmDaily;
      this.authUser.DmConReqDaily;
    },
    ["connectionRequest.weekly"]: function (value) {
      this.directMessage.weekly = value;
      this.authUser.DmWeekly;
      this.authUser.DmConReqWeekly;
    },
    ["passwordAlertShow"]: function (value) {
      if (value) this.passwordAlertShowConf = value;
    },
  },
  methods: {
    ...mapActions(["changeUserPreferedLang", "changeNotifisSettings"]),
    ...mapMutations([
      "setDirectMessage",
      "setActivityAroundUp",
      "setNewStartups",
      "setConnectionRequest",
      "setLanguage",
      "setEmail",
      "setConfEmail",
      "setPassword",
      "setConfPassword",
    ]),
    submit() {
      this.loading = true;
      // if (this.authUser) this.form.email = this.authUser.email;
      // else this.form.email = "";
      this.$store
        .dispatch("updatePassword", {
          id: this.authUser.id,
          email: this.authUser.email,
          password: this.$store.state.profileSettings.confirmPassword,
        })
        .then((success) => {
          this.password = "";
          this.confirmPassword = "";
          this.$noty.success(this.$i18n.t("passwordUpdated"));
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
    changeEmail() {
      this.loading = true;
      this.$store
        .dispatch("updateProfile", {
          email: this.confirmEmail,
        })
        .then((success) => {
          this.form = {};
          this.$noty.success(this.$i18n.t("emailUpdated"));
          this.email = "";
          this.confirmEmail = "";
          this.loading = false;
        })
        .catch((error) => {
          this.$noty.error(error);
          this.loading = false;
        });
    },
    async saveLang() {
      this.loading = true;
      var originalName = this.$route.name;
      var name = originalName.substring(0, originalName.length - 2);

      let lang = this.language;
      if (lang === "En") {
        name += "Fr";
      } else {
        name += "En";
      }
      this.$store.commit("setUserLang", lang);
      localStorage.setItem("userLang", lang);
      localStorage.setItem("preferedLanguage", lang);
      await this.changeUserPreferedLang(lang);
      this.$i18n.locale = lang;
      let path =
        "/" +
        this.$i18n.locale +
        "/" +
        this.$i18n.t("routes.profileSetting") +
        "#language";
      // this.$router.push({ name: name, params: { lang } }).catch(err => {});
      let url = originalName;
      if (this.$route.path != url) {
        this.$router.push({ path, params: { lang } });
      }
      this.loading = false;
    },
    getLang(lang) {
      var value = "navBar." + lang;
      return this.$i18n.t(value);
    },
    selectMenuItem(menuItem) {
      this.selected = menuItem.key;
      window.location.hash = menuItem.key;
    },
    clearPasswordInputs() {
      this.password = "";
      this.confirmPassword = "";
    },
    clearEmailInputs() {
      this.email = "";
      this.confirmEmail = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.up {
  margin-bottom: 20px !important;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1 {
  font: normal normal normal 25px/30px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.page {
  background-color: white;
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  margin-top: 0;
  margin-left: 0;
  padding: 0 20px 20px;
  align-items: center;
  justify-content: center;
}

.page {
  padding-right: 5px;
  padding-left: 10px;
}

.menu-content-container {
  margin: 0 auto;
  max-width: 608px;
}

.img-warn {
  max-width: 20px;
  max-height: 15px;
}

.block-btn {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 30px;
  }
}

.page-container {
  width: 100%;
  min-height: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  .profile-settings_menu {
    display: none;
    border: 1px solid #707070;
    border-radius: 25px;
    padding: 25px 36px 28px 36px;
    opacity: 1;
    position: relative;
    max-width: 100%;
    margin-top: 25px;
    font: normal normal normal 16px/30px;

    &-scroll {
      overflow: auto;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      padding-bottom: 4px;
    }

    &_item {
      &.active,
      &:hover {
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          position: relative;
          bottom: -4px;
          height: 5px;
          background-color: #1eb65b;
          border-radius: 25px;
          z-index: 1000;
        }
      }

      &:not(:first-child) {
        padding-left: 55px;
      }
    }
  }

  .container-notification-1 {
    margin-bottom: 15px;
    padding: 1px;
    border-radius: 15px;
    background: #dbdbdbaf 0 0 no-repeat padding-box;
    color: #707070;

    .notification-items-1 {
      padding: 17px 20px;
      background: #ffffff 0 0 no-repeat padding-box;
      box-shadow: inset 1px 1px 5px #00000029, 0 1px 5px #00000029;
      border-radius: 15px;

      .title-notification-1 {
        font: normal normal normal 15px/18px !important;
      }

      .item-notification-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;

        div {
          margin-bottom: 10px;

          img {
            width: 20px;
          }
        }

        .item-text {
          font: normal normal normal 15px/18px;
        }
      }

      .item-notification-1:first-child {
        padding-bottom: 8px;
      }

      .item-notification-1:last-child {
        padding-top: 8px;
      }

      .border-bottom {
        border-bottom: 1px solid #707070;
      }

      .column {
        flex-direction: column;
        font: normal normal normal 12px/14px;
      }

      img.mr-3-img {
        width: 35px;
        margin-right: 10px;
      }

      img.mr-2-img {
        width: 30px;
        margin-right: 10px;
      }
    }
  }
}

.content-title {
  margin-bottom: 20px;
  font-size: 25px;
  color: #000000;
}

.profile-settings_menu-content {
  min-height: 500px;
  flex-grow: 1;
  margin-top: 25px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 25px;
  padding: 8px;
  opacity: 1;
  overflow: auto;

  .check-box-item {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .title-notification {
    font: normal normal normal 15px/18px;
  }

  .container-notification {
    margin-bottom: 15px;
    padding: 10px 15px 20px 15px;
    border-radius: 15px;
    background: #dbdbdb38;
    color: #333333;
    font: normal normal normal 15px/18px;

    .notification-items {
      padding: 17px 20px;
      background: #ffffff;
      // -webkit-box-shadow: inset 0 1px 3px #00000029, 0 1px 3px #00000029;
      // box-shadow: inset 0 1px 3px #00000029, 0 1px 3px #00000029;
      border-radius: 15px;
      border: 1px solid #afafaf48;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      text-align: left;

      & > div:first-child {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
      }

      img {
        width: 20px;
        margin-right: 10px;
      }
    }

    .notification-items-content {
      padding: 17px 20px;
      background: #ffffff;
      // -webkit-box-shadow: inset 0 1px 3px #00000029, 0 1px 3px #00000029;
      // box-shadow: inset 0 1px 3px #00000029, 0 1px 3px #00000029;
      border-radius: 15px;
      border: 1px solid #afafaf48;

      .notification-items-activity {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;

        & > div:first-child {
          margin-bottom: 10px;
          width: 100%;
          display: flex;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -19px;
          height: 1px;
          width: 100%;
          background-color: #eaebec;
        }
      }

      .active-group {
        margin-top: 45px;
        display: block;
        justify-content: space-between;
        text-align: left;

        .active-group-txt {
          font: normal normal normal 15px/18px;
        }

        & > div:first-child {
          margin-bottom: 15px;
        }

        & > div:last-child {
          .mg-bottom {
            margin-bottom: 0;
          }
        }

        img {
          width: 20px;
          margin-right: 10px;
        }

        .mg-bottom {
          margin-bottom: 15px;
        }

        .fire-item {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .content-title {
    margin: 20px;
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
  .page {
    background-color: white;
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    margin-top: 0;
    margin-left: 0;
    padding: 0 20px;
  }
  .page-container {
    width: 100%;
    max-width: 990px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .profile-settings_menu {
    display: block !important;
  }
  .profile-settings_menu-content {
    padding: 27px 25px;

    .check-box-item {
      justify-content: end !important;
    }

    .container-notification-1 {
      .notification-items-1 {
        .item-notification-1 {
          flex-direction: row;

          div {
            margin: 0;

            img {
              width: 35px;
            }
          }

          .checkbox-toggle:first-child {
            margin-right: 20px;
          }
        }

        .item-notification-1:first-child {
          padding-bottom: 22px;
        }

        .item-notification-1:last-child {
          padding-top: 22px;
        }

        .column {
          flex-direction: column;
          font: normal normal normal 15px/18px;
        }
      }
    }
  }
  .profile-settings {
    padding-top: 65px !important;
  }
  .block-btn {
    justify-content: space-between;
    flex-direction: row;
  }
  .profile-settings_menu {
    &-scroll::after {
      content: "";
      display: block;
      position: absolute;
      top: 61px;
      width: calc(100% - 72px);
      height: 1px;
      background-color: #707070;
    }
  }

  .notification-items {
    flex-direction: row !important;
    align-items: center !important;

    & > div:first-child {
      margin-bottom: 0 !important;
    }

    img {
      width: 35px !important;
    }
  }

  .notification-items-activity {
    flex-direction: row !important;
    align-items: center !important;

    & > div:first-child {
      margin-bottom: 0 !important;
    }
  }

  .active-group {
    display: flex !important;

    & > div:first-child {
      margin-bottom: 0 !important;
      //width: 100%;
    }

    .mg-bottom {
      margin-bottom: 30px !important;
    }

    .fire-item {
      display: block !important;
    }

    img {
      width: 35px !important;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content-title {
    font-size: 30px;
  }
  .active-group-txt {
    font: normal normal normal 20px/25px;
  }
  .title-notification-1 {
    font: normal normal normal 25px/30px;
  }
  .item-notification-1.column {
    font: normal normal normal 20px/24px;
  }
  .container-notification {
    font: normal normal normal 25px/30px;
  }
  .active-group {
    font: normal normal normal 20px/24px;
  }
  .item-text {
    font: normal normal normal 20px/25px;
  }
  .notification-items-content {
    padding: 17px 43px !important;
  }
  .notification-items {
    padding: 17px 43px !important;

    img {
      margin-right: 18px !important;
    }
  }
  .title-notification {
    font: normal normal normal 25px/30px;
  }
  .profile-settings_menu-content {
    .container-notification-1 {
      .notification-items-1 {
        padding: 17px 43px !important;

        .item-notification-1 {
          .checkbox-toggle:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }

  img.mr-3-img {
    margin-right: 18px !important;
  }
}

/* Large devices (laptops/desktops, 860px and up) */
@media only screen and (min-width: 860px) {
  .profile-settings_menu-content {
    .container-notification-1 {
      .notification-items-1 {
        padding: 17px 43px !important;

        .item-notification-1 {
          .checkbox-toggle:first-child {
            margin-right: 90px;
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
