<template>
  <div>
    <div class="box mb-3 shadow-sm rounded profile-box text-center">
      <div class="container">
        <div
          class="row justify-content-center align-items-center d-flex vh-100"
        >
          <div class="col-md-5 mx-auto bg-white loginbox pb-3 pr-5 pl-5">
            <div class="osahan-login py-4">
              <div class="text-center mb-4">
                <h5 class="font-weight-bold mt-3">
                  {{ $t("changePassword") }}
                </h5>
                <p class="text-muted"></p>
              </div>
              <div
                class="alert alert-danger text-center"
                role="alert"
                v-if="error"
              >
                {{ errorMsg }}
              </div>
              <form @submit.prevent="submit">
                <div class="mb-1" style="">
                  <label>{{ $t("newPassword") }}</label>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="feather-lock"></i>
                    </span>
                  </div>
                  <input
                    :type="inputType"
                    class="form-control"
                    required
                    v-model="form.password"
                    ref="password"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i
                        :class="eyeIcon"
                        id="togglePassword"
                        @click="revealPassword"
                      ></i>
                    </span>
                  </div>
                </div>
                <div class="mb-1" style="">
                  <label>{{ $t("confirmPassword") }}</label>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="feather-lock"></i>
                    </span>
                  </div>
                  <input :type="inputType" class="form-control" required
                  @paste.prevent onselectstart="return false" onCopy="return
                  false" onCut="return false" onDrag="return false"
                  onDrop="return false" autocomplete=off/
                  v-model="form.passwordConfirm" v-on:keyup="checkMatch"
                  ref="password" />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i
                        :class="eyeIcon"
                        id="togglePassword"
                        @click="revealPassword"
                      ></i>
                    </span>
                  </div>
                </div>
                <div class="match m-3" v-if="passMacth">
                  {{ macthText }}
                </div>
                <div class="mismatch m-3" v-else>
                  {{ macthText }}
                </div>
                <button
                  :disabled="loading"
                  type="submit"
                  class="btn btn-primary btn-block text-uppercase"
                >
                  <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
                  {{ loading ? "" : $t("send") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
    //this.authUser;
  },
  data() {
    return {
      form: {},
      viewPassword: "off",
      eyeIcon: "feather-eye",
      inputType: "password",
      loading: false,
      passMacth: false,
      macthText: null,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      let verification_key = `${this.$route.params.verification_key}`;
      let data = {
        verification_key,
        password: this.form.passwordConfirm,
      };
      Axios.post(APIURLS.USERS + "recover-pass-public", data)
        .then((response) => {
          this.loading = false;
          this.form.password = "";
          this.form.passwordConfirm = "";
          this.$noty.success(this.$i18n.t("passwordUpdated"));
          let url = `/${i18n.locale}/${i18n.t("routes.login")}`
          if (this.$route.path != url) {
            this.$router.push(url);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(this.$i18n.t("linkExpired"));
          this.error = true;
          this.errorMsg = this.$i18n.t("linkExpired");
          
          let url = `/${i18n.locale}/${i18n.t("routes.login")}`
          if (this.$route.path != url) {
            this.$router.push(url);
          }
        });
    },
    checkMatch() {
      if (this.form.passwordConfirm) {
        this.form.password === this.form.passwordConfirm
          ? ((this.macthText = `${this.$i18n.t("passwordMatch")}`),
            (this.passMacth = true))
          : (this.macthText = `${this.$i18n.t("passwordNotMatch")}`);
      }
    },
    revealPassword() {
      if (this.viewPassword === "off") {
        this.inputType = "text";
        this.eyeIcon = "feather-eye-off";
        this.viewPassword = "on";
      } else {
        this.inputType = "password";
        this.eyeIcon = "feather-eye";
        this.viewPassword = "off";
      }
    },
  },
  name: "RecoverPassword",
};
</script>
<style>
.match {
  color: green !important;
}
.mismatch {
  color: red !important;
}
</style>
