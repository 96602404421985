<template>
  <div class="about">
    <Header title="about" />
    <Body :text="getStartupBio" type="text" :editState="editing" />
    <edit-button
      class="edit-button"
      v-if="showEditButtons && !editing"
      @click.native="toggleEditMode"
      text="edit"
    />
    <edit-button
      class="edit-button"
      v-if="showEditButtons && editing"
      @click.native="save"
      text="save"
      :isSave="true"
    />
  </div>
</template>
<script>
import Header from "./ShareElements/Header.vue";
import Body from "./ShareElements/Body.vue";
import EditButton from "../../../components/Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import SaveCancelButtonGroup from "../../../components/Profile/Diaspora/profile/buttons/SaveCancelButtonGroup.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",
  props: {
    text: String,
  },
  components: {
    Header,
    Body,
    EditButton,
    // SaveCancelButtonGroup,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartupEditingMode",
      "showEditButtons",
      "getStartupBio",
    ]),
  },
  data() {
    return {
      editing: false,
    };
  },
  methods: {
    toggleEditMode() {
      this.editing = true;
      let aboutParagraph = document.getElementById("about");
      //aboutParagraph.contentEditable = true;

      let discriptionText = document.getElementById("discript_text");
      discriptionText.style.webkitLineClamp = "inherit";
      discriptionText.style.webkitLineClamp = "inherit";
      discriptionText.style.webkitBoxOrient = "inherit";

      //   display: -webkit-box;
      // -webkit-line-clamp: inherit;
      // line-clamp: 6;
      // -webkit-box-orient: vertical;
    },
    save() {
      // const aboutParagraph = document.getElementById("about");
      //const aboutParagraph = document.getElementById("discript_text");
      if (this.getStartupBio && this.getStartupBio.length) {
        if (this.getStartupBio.length > 400) {
          this.$store
            .dispatch("updateStartup", {
              description: this.getStartupBio,
            })
            .then(() => {
              this.editing = false;
              // aboutParagraph.contentEditable = false;
              this.$snotify.success(this.$i18n.t("success"), "Success");
            })
            .catch((error) => {
              this.$snotify.error(this.$i18n.t("error"), "error");
            });
        } else {
          this.$noty.error(
            this.$i18n.t("Your description should be at least 400 chars long!")
          );
        }
      } else {
        this.$noty.error(this.$i18n.t("Your description cannot be empty!"));
      }

      let discriptionText = document.getElementById("discript_text");
      discriptionText.style.whiteSpace = "ellipsis";
      discriptionText.style.display = "-webkit-box";
      discriptionText.style.webkitLineClamp = "6";
      discriptionText.style.webkitBoxOrient = "vertical";
      discriptionText.style.overflow = "hidden";
      discriptionText.style.marginBottom = "10px";
    },
    cancel: function () {
      this.editing = false;
      // const aboutParagraph = document.getElementById("about");
      // aboutParagraph.contentEditable = false;
      let discriptionText = document.getElementById("discript_text");
      discriptionText.style.whiteSpace = "ellipsis";
      discriptionText.style.display = "-webkit-box";
      discriptionText.style.webkitLineClamp = "6";
      discriptionText.style.webkitBoxOrient = "vertical";
      discriptionText.style.overflow = "hidden";
      discriptionText.style.marginBottom = "10px";
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.about {
  width: 60%;
  height: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
  border-radius: 8px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.edit-button {
  position: absolute !important;
  right: 20px;
  top: 5px;
  padding: 5px 20px;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  z-index: 99;
}
.btn-wrapper {
  position: relative;
  background: white;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrapper .btn-edit {
  width: 25px;
  height: 25px;
  padding: 5px;
  line-height: 0;
  border: 1px solid hsla(0, 0%, 43.9%, 0.002);
  border-radius: 15px;
  background-color: hsla(0, 0%, 54.9%, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrapper .btn-edit {
  position: absolute;
  left: 2px;
}
@media (max-width: 600px) {
  .about {
    width: 100vw;
    border-radius: 0;
  }

  .edit-button {
    right: 20px;
    top: 8px;
  }
}
</style>