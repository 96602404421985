<template>
  <aside
    class="leftside box col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-12 col-12 mt-0 bg-white"
  >
    <ProfileWidget
      :headline="headline"
      :showLink="0"
      :avatar="avatar"
      :name="name"
      :username="username"
      :showVerified="showVerified"
      :is_verified="is_verified"
      :count="counts.connected"
    />
    <div class="rounded mb-3" v-if="!authUser.is_entrepreneur">
      <a
        :href="`/${$i18n.locale}/${this.$i18n.t('routes.news')}`"
        class="btn btn-lg btn-block btn-primary mb-3"
      >
        <i class="feather-file-text"></i>
        {{ $t('AllNews') }}
      </a>
      <div class="box-title border-bottom p-3">
        <h6 class="m-0 text-center">{{ $t('RecommendedNews') }}</h6>
      </div>
      <div class="box-body p-3">
        <SideNews
          v-for="(article, index) in news"
          :key="index"
          :news="article"
        />
      </div>
    </div>
    <RelevantTalents :talents="relevantTalents" v-else />
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  async mounted() {
    if (this.authUser.type === 1) {
      await this.loadProfile();
      this.username = this.getUserProfile.user.username;
      this.avatar = this.getUserProfile.user.avatar;
      this.name = this.getUserProfile.user.name;
      this.headline = this.getUserProfile.headline;
      if (!this.authUser.is_verified) {
        this.showVerified = true;
      } else {
        this.showVerified = false;
      }
      this.is_verified = this.getUserProfile.user.is_verified;
      await this.loadConnections(this.authUser.id);
      await this.loadConnCounts();
      this.connections = this.getConnected;
      this.counts = this.getConnectionsCounts;
    } else if (this.authUser.type === 2) {
      await this.loadStartup();
      this.username = this.authUser.username;
      this.avatar = this.authUser.avatar;
      this.name = this.getStartup.startupname;

      const startup_id = this.getStartup.id;

      await this.loadRelevantTalents({ startup_id });
      this.relevantTalents = this.getRelevantTalents;
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters([
      'authUser',
      'getUserProfile',
      'getStartup',
      'getConnected',
      'getConnectionsCounts',
      'getRelevantTalents',
    ]),
  },
  methods: {
    ...mapActions([
      'loadProfile',
      'loadStartup',
      'loadConnections',
      'loadConnCounts',
      'loadRelevantTalents',
    ]),
  },
  data() {
    return {
      connections: {},
      avatar: null,
      name: null,
      startup: null,
      username: null,
      headline: null,
      users: {},
      post: '',
      showVerified: false,
      is_verified: 0,
      counts: 0,
      loading: true,
      relevantTalents: {},
      pagination: 10,
    };
  },
  name: 'LeftAside',
  props: {
    news: {},
  },
};
</script>
