<template>
  <div class="rightContainer" ref="rightContainer">
    <div class="inner-container" v-if="getAccountType === 'Diaspora'">
      <SectionHeader text="getToKnow" emphasize="aboutYou" />
      <SelectCountries :residence="foreignCountries" :origin="countriesLists" />
      <UserExperience
        eduTitle="HighestLevelOfEducation"
        expTitle="YearsOfExp"
        :yearsOfExperience="yearsOfExperience"
        :levelOfEducation="levelOfEducation"
        :err_msg="err_msg"
      />
      <SelectExpertise
        searchTitle="SelectExpertise"
        searchPlaceHolder="SelectExpertise"
        :err_msg="err_msg.expertises"
        :expertises="expertises"
      />
    </div>
    <div
      class="inner-container"
      id="inner-container"
      v-if="getAccountType === 'Startup'"
    >
      <SectionHeader text="Let’s get " emphasize="the basics right!" />
      <div class="startup-form-container">
        <form action="">
          <div class="form-row">
            <div class="input-div">
              <Input2
                v-model="form.startupName"
                typeName="text"
                :label="`${$t('startupName') + '*'}`"
                name="startupName"
                @change="saveInput('startupName')"
                :isRequired="true"
                :hasError="err_msg.startupName"
                :error_msg="err_msg.startupName"
                :placeholder="$t('startupName')"
              />
            </div>

            <div class="input-div">
              <label
                >{{ $t("foundingYear") }}*
                <span class="error_msg" v-if="err_msg.foundingYear"
                  >{{ $t(err_msg.foundingYear) }}
                </span>
              </label>
              <Datepicker
                v-model="form.foundingYear"
                :language="fr"
                :clear-button="false"
                input-class="input"
                :required="true"
                :typeable="true"
                :disabledDates="disabledDates"
                minimum-view="year"
                :format="DatePickerFormat"
                :placeholder="$t('foundingYear')"
                class="datepicker"
                @selected="saveInput('foundingYear')"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="input-div">
              <label
                >{{ $t("country") }}*
                <span class="error_msg" v-if="err_msg.location">{{
                  $t(err_msg.location)
                }}</span>
              </label>
              <!-- <CustomSelect2
                :options="countriesLists"
                :default="form.location"
                class="select input wx-100"
                @input="saveCountry($event, 'location')"
                :hasError="err_msg.location ? true : false"
              /> -->
              <div class="select-outer left">
                <ModelSelect
                  @input="saveCountry($event, 'location')"
                  :isError="err_msg.location ? true : false"
                  :options="
                    countriesLists.map((d) => {
                      let value = d.id;
                      let text = d.name;
                      let name = d.name;
                      let code = d.code;
                      let item = {
                        value,
                        text,
                        code,
                        name,
                      };
                      return item;
                    })
                  "
                  v-model="itemCountry"
                  placeholder="Country of Headquarter"
                />
              </div>
            </div>
            <div class="input-div">
              <Input2
                v-model="form.website"
                typeName="text"
                label="website"
                name="website"
                class="input-website"
                @change="saveInput('website')"
                :isRequired="true"
                :hasError="err_msg.website"
                :error_msg="err_msg.website"
                :placeholder="$t('website')"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="startup-discription">
        <div class="startup-label-header">
          <div class="startup-discription-right">
            <label>
              {{ $t("descriptionStartup") }}*
              <span class="error_msg" v-if="err_msg.description"
                >{{ $t(err_msg.description) }}
              </span>
            </label>
            <button
              class="tool-tip-viewer"
              :content="$t(`addStartupTooltip`)"
              v-tippy
            >
              <Icon size="18"><InfoCircle /></Icon>
            </button>
          </div>
          <div>
            <p class="word-left">
              {{ $t("Chars") }}: {{ this.wordLenght + " " }}
            </p>
          </div>
        </div>
        <textarea
          ref="textarea"
          id="textarea-startup-discription"
          @input="textareaResize($event)"
          v-on:keyup="checkWordLen"
          class="textarea"
          name="descriptionStartup"
          v-model="form.description"
          :placeholder="$t('Describe your startup (elevator pitch)')"
          @change="saveInput('description')"
          :class="err_msg.description ? 'error' : ''"
          required
        >
        </textarea>
      </div>
      <Traction
        :tractions="getTractions"
        :err_msg="err_msg.traction"
        label="traction"
      />

      <!-- <div class="">
          
      </div> -->
    </div>

    <div :style="cssVars" id="step-footer" class="step-footer">
      <StepButton
        text="previous"
        @click.native="goPrevious"
        :icon="require('@/assets/registration/icons/previous.svg')"
        class="previous"
      />
      <StepButton
        class="step2_next"
        text="Next"
        @click.native="nextStep"
        :icon="require('@/assets/registration/icons/next.svg')"
      />
    </div>
  </div>
</template>
<script>
import Input2 from "../../../../components/Auth/Input2.vue";
import SelectCountries from "../../../../components/Auth/SelectCountries.vue";
import CustomSelect2 from "../../../../components/Auth/CustomSelect2.vue";
import SectionHeader from "../../../../components/Auth/SectionHeader.vue";
import UserExperience from "../../../../components/Auth/UserExperience.vue";
import SelectExpertise from "../../../../components/Auth/SelectExpertise.vue";
import Traction from "../../../../components/Auth/Traction/Traction.vue";
import i18n from "../../../../i18n";
import store from "../../../../store/";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";

import StepButton from "../../../../components/Auth/StepButton.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import { ModelSelect } from "vue-search-select";

import { InfoCircle } from "@v2icons/tabler";
import { Icon } from "@v2icons/utils";

export default {
  async mounted() {
    var lang = i18n.locale;
    await this.getCountries();
    await this.getForeignCountries();
    await this.loadTalents({ lang });
    this.talents = this.getTalents;
    this.talents.forEach((talent) => {
      this.expertises.push({
        talent_id: talent.id,
        code: talent.code,
        name: talent.name,
      });
    });

    if (this.getAccountType === "Startup") {
      const inputs = [
        "startupName",
        "website",
        "description",
        "foundingYear",
        "location",
      ];
      inputs.map((item) => {
        if (localStorage.getItem(item)) {
          this.form[item] = localStorage.getItem(item);
          //alert(localStorage.getItem(item));
          this.$store.commit("setFormData", {
            field: item,
            value: localStorage.getItem(item),
          });

          if (item === "description") {
            this.wordLenght = localStorage.getItem(item).length;
          }
          if (item === "location") {
            this.itemCountry = JSON.parse(localStorage.getItem(item));
            // alert(this.itemCountry);
            //console.log(this.itemCountry);
          }
        }
      });

      const location = JSON.parse(localStorage.getItem("location"));
      if (location) {
        this.form.location = location.name;
        this.$store.commit("setFormData", {
          field: "location",
          value: location.code,
        });
      }
    }

    // get the width of the right container
    this.$nextTick(function () {
      this.getRightContainerHeight();
    });
  },
  mount() {
    this.getRightContainerHeight();
    this.$nextTick(() => {
      var element = document.getElementById("textarea-startup-discription");
      if (element) {
        element.style.height = "auto";
        element.style.height = `${element.scrollHeight}px`;
      }
    });
  },
  computed: {
    cssVars() {
      return {
        "--right-container-width": this.rightContainerWidth + "px",
      };
    },
    ...mapGetters([
      "getFomData",
      "getAccountType",
      "getLevelOfEducation",
      "getFordataField",
      "getCountriesList",
      "getForeignCountriesList",
      "getSectorstList",
      "getTalents",
      "getErrorMsg",
      "getTractions",
    ]),
  },
  name: "StepTwoContent",
  data: () => {
    return {
      itemCountry: {
        value: "",
        text: "",
        code: "",
        name: "",
      },
      rightContainerWidth: {
        type: Number,
        default: 0,
      },
      checkIcon: require("../../../../assets/registration/icons/check.svg"),
      levelOfEducation: [
        {
          name: "Bachelors",
          icon: require("@/assets/registration/icons/bachelor.svg"),
        },
        {
          name: "Masters",
          icon: require("@/assets/registration/icons/master.svg"),
        },
        {
          name: "PhD",
          icon: require("@/assets/registration/icons/phd.svg"),
        },
        {
          name: "Other",
          icon: require("@/assets/registration/icons/degree.svg"),
        },
      ],
      yearsOfExperience: ["0-1", "2-4", "5-10", "10+"],
      countries: null,
      foreignCountries: [],
      countriesLists: [],
      expertises: [],
      talents: {},
      form: {
        startupName: "",
        website: "",
        description: "",
        foundingYear: "",
        location: "Country Headquarters",
      },
      errors: {
        startupName: false,
        website: false,
        description: false,
        foundingYear: false,
        location: false,
        expertises: false,
        traction: false,
      },
      err_msg: {
        startupName: "",
        levelOfEducation: "",
        yearsOfExperience: "",
        website: "",
        description: "",
        foundingYear: "",
        location: "",
        expertises: "",
        traction: "",
      },
      msg: {
        startupName: "",
        website: "",
        description: "",
        foundingYear: "",
        location: "",
        expertises: "",
        traction: "",
      },
      disabledDates: {
        from: new Date(),
      },
      DatePickerFormat: "yyyy",
      en: en,
      fr: fr,
      wordLenght: 0,
      minChars: 400,
      tractionMinChars: 200,
    };
  },
  methods: {
    textareaResize(e) {
      // this.$refs.textarea.style.minHeight = this.$refs.textarea.scrollHeight + 'px';
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
      // var container = this.$el.querySelector("#container");
      // e.target.scrollTop = e.target.scrollHeight;
    },
    getRightContainerHeight() {
      this.rightContainerWidth = this.$refs.rightContainer.clientWidth;
      let stepFooter = document.getElementById("step-footer");
      stepFooter.style.width = this.rightContainerHeight;
    },
    ...mapActions([
      "loadSectorsList",
      "loadTalents",
      "loadCountriesList",
      "loadForeignCountriesList",
    ]),
    ...mapMutations(["gotoNext", "goPrevious", "setStep"]),
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
    },
    async getCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.countriesLists.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    async getForeignCountries() {
      var lang = i18n.locale;
      await this.loadForeignCountriesList({ lang });
      this.getForeignCountriesList.forEach((country) => {
        this.foreignCountries.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    WordCount(str) {
      return str.split(" ").filter(function (n) {
        return n != "";
      }).length;
    },
    checkWordLen(event) {
      this.wordLenght = this.form.description.length;
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async saveInput(name) {
      if (name != "website") {
        if (this.isEmpty(this.form[name])) {
          this.errors[name] = true;
          this.msg[name] = ` is required`;
        } else {
          this.errors[name] = null;
          this.msg[name] = "";
        }
      }

      if (name === "startupName") {
        let slug = this.convertToSlug(this.form.startupName);
        localStorage.setItem("slug", slug);
        this.$store.commit("setFormData", {
          field: "slug",
          value: slug,
        });
      }
      //remove empty space at the beginning and the end
      let formValue;
      if (name != "foundingYear") formValue = this.form[name].trim();
      else formValue = this.form[name];
      localStorage.setItem(`${name}`, formValue);
      this.$store.commit("setFormData", {
        field: name,
        value: formValue,
      });
    },
    async saveCountry(e, name) {
      if (this.isEmpty(this.form[name])) {
        this.errors[name] = true;
        this.msg[name] = `${this.$i18n.t(name)} is required`;
      } else {
        this.errors[name] = null;
        this.msg[name] = "";
      }
      this.form.location = e.code;
      //remove empty space at the beginning and the end
      let formValue = JSON.stringify(e);

      //save to localstorage and store
      localStorage.setItem(`${name}`, formValue);
      this.$store.commit("setFormData", {
        field: name,
        value: e.code,
      });
    },
    async nextStepDiaspora() {
      let levelOfEducation = this.getFordataField("levelOfEducation");
      let yearsOfExperience = this.getFordataField("yearsOfExperience");
      let expertises = this.getFordataField("expertises");
      let countryOfResidence = this.getFordataField("countryOfResidence");
      let africanCountryOfOrigin = this.getFordataField(
        "africanCountryOfOrigin"
      );

      //alert(africanCountryOfOrigin);
      if (this.isEmpty(levelOfEducation)) {
        this.errors.levelOfEducation = true;
        // console("edu error = ",this.errors.levelOfEducation)
        this.err_msg.levelOfEducation = "Select an option";
      } else {
        this.errors.levelOfEducation = false;
        this.err_msg.levelOfEducation = null;
        // console("edu error 2 =",this.errors.levelOfEducation)
      }
      if (this.isEmpty(yearsOfExperience)) {
        this.errors.yearsOfExperience = true;
        this.err_msg.yearsOfExperience = "Select an option";
      } else {
        this.errors.yearsOfExperience = false;
        this.err_msg.yearsOfExperience = null;
      }
      if (this.isEmpty(africanCountryOfOrigin)) {
        this.errors.africanCountryOfOrigin = true;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_origin",
          value: "Select a country",
        });
      } else {
        this.errors.africanCountryOfOrigin = false;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_origin",
          value: null,
        });
      }
      if (this.isEmpty(countryOfResidence)) {
        this.errors.countryOfResidence = true;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_residence",
          value: "Select a country",
        });
      } else {
        this.errors.countryOfResidence = false;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_residence",
          value: null,
        });
      }
      if (expertises && expertises.length) {
        this.errors.expertises = false;
        // this.err_msg.expertises = null;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_exp_diasp",
          value: null,
        });
      } else {
        this.errors.expertises = true;
        // this.err_msg.expertises = "Select an option";
        this.$store.commit("setErrorMsg", {
          field: "err_msg_exp_diasp",
          value: "Select an option",
        });
      }

      let containsError = Object.values(this.errors).includes(true);
      if (!containsError) {
        this.gotoNext();
      }
    },
    async nextStepStartup() {
      let startupName = this.form.startupName;
      let foundingYear = this.form.foundingYear;
      //let location = this.form.location;
      let location = this.getFordataField("location");
      let description = this.form.description;
      if (!this.isEmpty(startupName)) {
        this.errors.startupName = false;
        this.err_msg.startupName = null;
        if (startupName.length > 25) {
          this.errors.startupName = true;
          this.err_msg.startupName = "Name too long. Max 25 chars";
        } else {
          this.errors.startupName = false;
          this.err_msg.startupName = null;
          let slug = this.convertToSlug(startupName);
          localStorage.setItem("slug", slug);
          this.$store.commit("setFormData", {
            field: "slug",
            value: slug,
          });
        }
      } else {
        this.errors.startupName = true;
        this.err_msg.startupName = "required";
      }
      if (foundingYear) {
        this.errors.foundingYear = false;
        this.err_msg.foundingYear = null;
        this.$store.commit("setFormData", {
          field: "foundingYear",
          value: foundingYear,
        });
      } else {
        this.errors.foundingYear = true;
        this.err_msg.foundingYear = "required";
      }

      if (!this.isEmpty(location)) {
        this.errors.location = false;
        this.err_msg.location = null;
      } else {
        this.errors.location = true;
        this.err_msg.location = "required";
      }
      if (!this.isEmpty(description)) {
        this.errors.description = false;
        this.err_msg.description = null;
        if (this.form.description.length < this.minChars) {
          this.errors.description = true;
          this.err_msg.description =
            "Min " + this.minChars + " " + this.$i18n.t("chars");
        } else {
          this.errors.description = false;
          this.err_msg.description = null;
        }
      } else {
        this.errors.description = true;
        this.err_msg.description = "required";
      }

      if (!this.isEmpty(this.getTractions)) {
        this.errors.traction = false;
        this.err_msg.traction = null;
        if (this.getTractions.length < this.tractionMinChars) {
          this.errors.traction = true;
          this.err_msg.traction =
            "Min " + this.tractionMinChars + " " + this.$i18n.t("chars");
        } else {
          this.errors.traction = false;
          this.err_msg.traction = null;
        }
      } else {
        this.errors.traction = true;
        this.err_msg.traction = "required";
      }

      let containsError = Object.values(this.errors).includes(true);
      if (!containsError) {
        this.gotoNext();
      }
    },
    async nextStep() {
      if (this.getAccountType === "Diaspora") {
        this.nextStepDiaspora();
      } else {
        this.nextStepStartup();
      }

      window.dataLayer.push({ event: "step2_next" });
    },
  },

  components: {
    SectionHeader,
    UserExperience,
    SelectExpertise,
    SelectCountries,
    StepButton,
    Datepicker,
    Input2,
    Traction,
    // CustomSelect2,
    ModelSelect,
    InfoCircle,
    Icon,
  },
};
</script>
<style scoped>
.error_msg {
  /* color: var(--error-color) !important; */
  /* background-color: #fe4c5f42; */
  color: var(--error-color);
  padding: 0px 5px;
  border-radius: 5px;
  font-size: 15px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.rightContainer {
  background-color: rgb(255, 255, 255);
  height: 100%;
  flex-grow: 0.6;
  padding: 10px 0px;
  align-items: flex-start;
  position: relative !important;
  overflow-y: scroll;
  /* overflow: hidden; */
}
/* .right-header {
  display: flex;
  padding: 0px 50px;
  align-items: flex-start;
} */

.select::-ms-expand {
  display: none;
}
.step-footer {
  transition: all 0.3s ease-in-out;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  right: 0;
  /* width: 50%; */
  /* width: var(--right-container-width);  */
  /* width: inherit; */
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  padding: 10px 50px 70px 50px;
}
.startup-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 50px; */
}
form {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 20px 50px;
}
.form-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}
@media (min-width: 1200px) {
  .form-row {
    padding-top: 0;
    padding-bottom: 0;
  }
  .form-row:nth-child(2) {
    justify-content: flex-end;
  }
  .startup-form-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  form {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0px 50px;
  }
}

.input-div,
.startup-discription {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  text-align: left;
}
.startup-label-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.startup-discription-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tool-tip-viewer {
  font-size: 14px;
  position: relative;
  border-radius: 50%;
  font-weight: 900;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  border: none;
}
.input-div {
  /* width: 80%; */
}

input:focus {
  outline: none !important;
  border: 1px solid #39a251 !important;
}

.input-div label {
  font-size: 22px;
}

.input-div input {
  width: 100%;
  padding: 15px 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;

  border-radius: 15px;
  height: 45px;
}

.datepicker {
  width: 98%;
  padding: 15px 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  /* border-radius: 15px; */
  /* height: 45px !important; */
  height: 38px !important;
  border-radius: 0.28571429rem;
  display: flex;
  align-items: center;
}

.startup-discription {
  padding: 20px 50px;
}
.startup-discription textarea {
  height: 100px;
  border-radius: 10px !important;
  padding: 10px;
  /* background-color: #349e4d17; */
  background-color: transparent;
  border: 1px solid rgb(224, 224, 230) !important;
}
.startup-discription label {
  font-size: 22px;
}

.startup-discription textarea {
  padding: 15px 20px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border: 1px solid rgb(224, 224, 230) !important;
  border-radius: 3px;
  min-height: 120px;
  /* overflow-y: scroll; */
}

.startup-discription textarea:focus {
  border: 1px solid rgba(0, 128, 0, 0.358) !important;
}

.word-left {
  color: rgb(110, 118, 125);
  font-size: 13px;
  position: relative;
  width: auto !important;
  margin-top: 10px !important;
  bottom: 10px;
  border: 1px solid rgba(0, 128, 0, 0.358);
  padding: 2px 8px;
  border-radius: 20px;
  display: block;
  color: rgba(0, 128, 0, 0.8);
}
.inner-container {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: scroll;
  /* background-color: pink; */
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .rightContainer {
    background-color: rgb(254, 254, 254);
    /* height: calc(100% - 0.4vh); */
    width: 100%;
    padding: 10px 0px;
    align-items: flex-start;
    position: relative;
    /* overflow-y: scroll; */
    z-index: 1000;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    /* margin-top: 73px; */
    padding-top: 66px;
    overflow-y: visible;

    padding-top: 0;
    border-radius: 0;
    /* background-color: orange; */

    /* overflow-y: hidden; */
    /* padding-bottom: 200px; */
    /* background-color: green; */
    height: 100%;
  }

  .inner-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-top: 0;
    position: relative;
    height: 100vh;
    padding-bottom: 100px;
    /* background-color: pink; */
  }

  /* .right-header {
    display: flex;
    padding: 0px 15px;
    align-items: flex-start;
    text-align: center;
    background: #EFFAF0;
  } */
  /* .right-header h1 {
    background-color: green !important;
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
    font-family: inherit;
    padding: 25px 0px;
    margin-top: -15px;
    text-align: left;
    width: 100vw;
    z-index: 1000;
    border-radius: 64px;
    background-color: #ffffff;
  } */
  .select-country-container {
    display: flex;
    padding: 0px 20px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 50px;
  }
  .step-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 8px 15px;
    backdrop-filter: blur(15px);
    background: #ffffff40;
    /* background: #ffffff; */
    height: 90px;
    z-index: 999;
    /* z-index: 100000000; */

    padding: 1em 1.5em;
    background: #ffffff;

    /* background-color: orange; */
    z-index: 100000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .startup-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }
  .startup-form-container form {
    background-color: rgba(255, 183, 0, 0.064);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 15px;
  }
  .form-row {
    width: 100%;
  }
  .datepicker {
    width: 100%;
    padding: 12px 8px !important;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
  }
  .input-div {
    width: 100%;
  }
  label {
    text-align: left !important;
  }
  .input-div label {
    font-size: 18px !important;
    /* color: #717171; */
  }
  .vdp-datepicker__calendar {
    color: wheat !important;
    position: absolute;
    z-index: 100;
    background: #51aa63 !important;
    width: 95vw !important;
    border-radius: 20px !important;
    border: 1px solid #51aa63 !important;
    left: calc(50% - 95vw / 2) !important;
  }
  .startup-discription {
    padding: 15px 15px;
    /* padding-bottom: 100px; */
  }
  .wx-100 {
    width: 100% !important;
  }
  .input-div input {
    height: 40px;
  }
}
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
</style>