<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="form sidebar">
    <FilterBox
      :title="countries.description"
      code="countries"
      :items="countries.contents"
    />
    <FilterBox
      :title="areas.description"
      code="areas"
      :items="areas.contents"
    />
    <FilterBox
      :title="types.description"
      code="types"
      :items="types.contents"
    />
    <!-- <FilterBox :title="years.description" code="years" :items="years.contents" /> -->
  </form>
</template>

<script>
import FilterBox from "./FilterBox.vue";

export default {
  components: {
    FilterBox,
  },
  props: {
    countries: Object,
    areas: Object,
    types: Object,
    years: Object,
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f4f2 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75b38c !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1ba54b !important;
}

.sidebar {
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.67);
  padding-top: 0.5rem;
  overflow-y: scroll;
  padding-bottom: 100px;
  max-width: 1150px;
  max-height: 90vh;
}
.sidebar {
  overflow-y: scroll !important;
  scrollbar-color: #75b38c #f0f4f2;
}
</style>