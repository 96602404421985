var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('label',[_vm._v(_vm._s(_vm.$t(_vm.title))+" "),(_vm.err_msg)?_c('span',{staticClass:"error_msg"},[_vm._v(" "+_vm._s(_vm.$t(_vm.err_msg))+" ")]):_vm._e()]),_c('div',{staticClass:"section-list"},[_c('ModelSelect',{attrs:{"options":_vm.options.map((d) => {
          let field = d.name;
          let value = d.name;
          let name = d.name;
          let text = d.name;
         
          let item = {
            field,
            value,
            name,
            text
          };
          return item;
        }),"placeholder":"Select"},on:{"input":function($event){return _vm.setNumberInTeams($event)}},model:{value:(_vm.numOfTeam),callback:function ($$v) {_vm.numOfTeam=$$v},expression:"numOfTeam"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }