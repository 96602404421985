<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="({ open: open }, hasError ? 'error' : '')"
      @click="open = !open"
    >
      {{ $t(selected) }}
    </div>
    <p></p>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :class="selected === option.name ? 'active' : ''"
        :key="i"
        @click="
          selected = option.name;
          open = false;
          $emit('input', option);
        "
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasError: Boolean,
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.selected = null;
    localStorage.removeItem("location");
    this.$store.commit("setFormData", {
      field: "location",
      value: null,
    });
    //this.$emit("input", this.selected);
  },
};
</script>

<style scoped>
*,
*:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
:focus {
  outline: none !important;
}

element {
  -webkit-tap-highlight-color: transparent; /* remove tap highlight */
}
element:focus {
  outline: none; /* remove outline */
  box-shadow: none; /* remove box shadow */
}

.select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.select:focus {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
*:focus {
  outline: none !important;
  border: none;
}
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 45px;
  line-height: 45px;
  background-color: transparent;
  border: 2px solid transparent;
  outline: none !important;
  border: none;
  background-color: transparent;
}

.custom-select .selected {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* border: 1px solid #dcdcdc;
  border: 1px solid rgba(0, 0, 0, 0.3); */
  border: 1px solid #ccd0d5;
  border-radius: 15px;
  color: #1c1c1c;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  outline: none;
  outline: none !important;
  font-size: 14px;

  /* background-color: red; */
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.custom-select .error {
  border: 2px solid var(--error-color) !important;
}
.custom-select .selected.open {
  border: 1px solid #dcdcdc;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid var(--first-color);
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 15px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: black transparent transparent transparent;
}

.custom-select .items {
  position: absolute;
  background-color: #fff;
  top: 40px;
  left: 0px;
  right: 0;
  color: #808896;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  border-right: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  max-height: 300px;
  overflow: scroll;
  z-index: 1;
}

.custom-select .items div {
  color: #808896;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  border-radius: 10px !important;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover,
.custom-select .items div.active {
  background-color: var(--first-color);
  color: #fff;
  border-radius: 8px !important;
}

.selectHide {
  display: none;
}
@media only screen and (max-width: 600px) {
  .custom-select {
    position: relative;
    width: 49%;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 40px;
    background-color: transparent;
    border: 2px solid transparent;
    outline: none !important;
    border: none;
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;
  }
}
</style>