<template>
  <div class="side-header">
    <a href="https://ourbantaba.com/">
      <img alt="Bantaba-logo" class="logo-img" :src="logo" />
    </a>
  </div>
</template>
<script>
export default {
  name: "AuthHeader",
  props: {
    logo: String,
  },
};
</script>
<style scoped>
.side-header {
  width: 90%;
  padding-top: 50px;
  padding-left: 0;
  /* background-color: green; */
  display: flex;
  align-items: center;
}
.logo-img {
  width: 200px;
}
@media (max-width: 400px){
  .side-header {
  margin-top: 50px;
}
}
</style>