<template>
  <div class="startup-profile-card">
    <imageUploadModal :isShow="isUploadModal" :loading="loading" :showPreview="showPreview" @closeModal="closeModal"
      @showPreviewTrue="showPreviewTrue" @submit="submit" :startVal="startVal" :endVal="endVal" :decimals="decimals"
      :options="options" :isProgressBar="false">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
        <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

        <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress jss-progress-bar" />
      </svg>
    </imageUploadModal>
    <form class="upload-form" :class="{ coverFormBorder: isShowPreview }" enctype="multipart/form-data" action="#"
      @submit.prevent="submit">
      <label @click="isUploadModal = true" style="cursor: pointer;">
        <!-- <input
          type="file"
          @change="onFileChange"
          name="image"
          id="image"
          style="display: none"
          class="upload-file-input"
        /> -->
        <img :src="avatar" class="
            startup-profile-icon
            img-fluid
            mt-2
            rounded-circle
            uploaded-img
          " alt="" v-if="!showPreview" />
        <!-- <VueCropper
          ref="cropper"
          :aspect-ratio="1 / 1"
          :src="avatar"
          :viewMode="1"
          :dragMode="none"
          :autoCrop="false"
          class="startup-profile-icon cropper"
          preview=".preview"
          v-else
        /> -->
      </label>
      <p class="profile-text" :class="{ hideTextPlaceholder: isShowPreview }">
        <span class="upload-placeholder">{{ $t("Add Logo") }} </span>
      </p>
      <!-- <div class="row upload-options-btn-container">
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload"
          type="submit"
          :disabled="loading"
          class="btn btn-success ml-3 upload-option-btn"
        >
          <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
          <i class="feather-upload-cloud" v-else></i>
          {{ loading ? "..." + $t("upload") : $t("upload") }}
        </button>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete"
          type="submit"
          @click="cancel"
          class="btn btn-danger ml-3 upload-option-btn"
        >
          <i class="feather-trash-2"></i> {{ $t("cancel") }}
        </button>
      </div> -->
    </form>
  </div>
</template>
<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import { mapState, mapMutations, mapGetters } from "vuex";
import imageUploadModal from "./../Profile/Diaspora/profile/main/imageUploadModal.vue";

export default {
  mounted() {
    let avatar = localStorage.getItem("avatar");
    if (avatar) {
      this.isShowPreview = true;
      this.avatar = avatar;
      this.$store.commit("setFormData", {
        field: "avatar",
        value: avatar,
      });
    }
  },
  computed: {
    ...mapGetters(["getFomData", "getAccountType"]),
  },
  data: () => {
    return {
      isActive: false,
      startVal: 0,
      endVal: 0,
      decimals: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 3,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      showPreview: false,
      isShowPreview: false,
      cropImg: "",
      avatar: require("@/assets/registration/icons/rocket.svg"),
      buttonChoice: "select",
      image: null,
      file: "",
      fileContents: null,
      cloudName: "bantaba",
      preset: "Startups-logos",
      formData: null,
      loading: false,
      isUploadModal: false
    };
  },

  methods: {
    showPreviewTrue(value) {
      this.showPreview = value;
    },
    closeModal() {
      this.showPreview = false;
      this.buttonChoice = "select";
      this.isUploadModal = false;
      console.log(this.isUploadModal)
      var percentageComplete = 0;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var progressBar = document.querySelector(".jss-progress-bar");
      progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
      this.startVal = 0;
      this.endVal = 0;
      this.cropImg = "";
      this.image = null;
      this.formData = null;
    },
    ...mapMutations(["setAccountType"]),
    onFileChange(e) {
      this.showPreview = true;
      this.isShowPreview = true;
      const file = e.target.files[0];
      this.file = file;
      this.avatar = URL.createObjectURL(file);
      this.buttonChoice = "upload";
    },
    prepareFormData: function () {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", this.cropImg);
    },
    cancel() {
      this.avatar = require("@/assets/registration/icons/rocket.svg");
      this.showPreview = false;
      this.isShowPreview = false;
      this.buttonChoice = "select";
    },
    submit(obj) {
      // perform the crop
      this.cropImg = obj.cropedImageData;
      this.startVal = 0;
      this.endVal = 0;
      this.loading = true;
      var zeroPercentageComplete = 0;
      var zeroStrokeDashOffsetValue = 100 - zeroPercentageComplete * 100;
      var progressBar = document.querySelector(".jss-progress-bar");
      console.log(progressBar)
      progressBar.style = `stroke-dashoffset: ${zeroStrokeDashOffsetValue}`;
      var percentageComplete = 0.5;
      var strokeDashOffsetValue = 100 - percentageComplete * 100;
      var afterPercentageComplete = 1;
      var afterStrokeDashOffsetValue = 100 - afterPercentageComplete * 100;
      setTimeout(() => {
        this.startVal = 0;
        this.endVal = 50;
        progressBar.style = `stroke-dashoffset: ${strokeDashOffsetValue}`;
        console.log(this.startVal, this.endVal);
      }, 100);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.startVal = 50;
              this.endVal = 100;

              progressBar.style = `stroke-dashoffset: ${afterStrokeDashOffsetValue}`;
              setTimeout(() => {
                this.results = response.data;
                this.image = this.results.secure_url;
                this.showPreview = false;
                this.avatar = this.results.secure_url;
                this.$noty.success(this.$i18n.t("avatarUpdated"));
                this.loading = false;
                this.buttonChoice = "select";
                this.closeModal();
                this.$store.commit("setFormData", {
                  field: "avatar",
                  value: this.avatar,
                });
                localStorage.setItem("avatar", this.avatar);
              }, 3800);
            })
            .catch((error) => {
              this.startVal = 50;
              this.endVal = 100;
              setTimeout(() => {
                this.loading = false;
                this.errors.push(error);
                this.closeModal();
              }, 3800);

            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(obj.file);
    },
  },
  name: "ProfileUlpoad",
  components: { imageUploadModal },
};
</script>
<style lang="scss" scoped>
.drag-button {
  background-color: #011108;
  color: #ffffff;
  font-size: 14px;
  z-index: 500;
  margin-top: -60px;
  cursor: default;
  position: absolute;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-image-modal-footer {
  border-top: 1px solid rgb(222 226 230);
}

$progress-bar-stroke-width: 3;
$progress-bar-size: 166px;

svg {
  height: $progress-bar-size;
  //transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #c9e9d7;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #c9e9d7;
  stroke: #009743;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 3s ease-in-out;
}

.modal-trigger-heading {
  font-size: 20px;
}

.feed-modal-title {
  font-size: 20px;
  color: #011108;
  font-weight: 700 !important;
  font-family: poppins, sans-serif !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.cross-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #5b615e;
}

.cross-svg-icon {
  width: 16px;
  height: 16px;
  color: #009743;
}

.create-feed-modal {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex;
  z-index: 15000 !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.feed-modal {
  border-radius: 20px;
  width: 645px;
}

.upload-img {
  background-color: transparent !important;
  border: 1px solid rgb(238 238 238);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.upload-img:hover {
  background-color: rgb(233, 230, 230) !important;
}

.publish-now {
  background-color: #009743;
  color: white;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.publish-now:hover {
  background-color: #09b657;
  color: white;
}

.modal-custom-body {
  padding: 0px !important;
}

.hideTextPlaceholder {
  display: none !important;
}

.profile-card {
  width: min(305px, 48%);
  height: 225px;
  background-color: #f0f4f7;
  border: 1px solid #888c93;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 54px;
  border: 2px solid #888c9342;
  z-index: 100;
  /* height: 125px; */
}

.profile-icon {
  width: 50px;
}

.form {
  position: relative;
}

.profile-text {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload-options-btn-container {
  z-index: 50000;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-text {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload-placeholder {
  position: absolute;
  top: -70px;
  background-color: #00000054;
  color: white;
  border-radius: 60px;
  width: 85px;
  /* height: 45px; */
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-option-btn {
  width: 80%;
  margin: 2px 0 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .profile-card {
    width: 48%;
    height: 180px;
    padding: 20px 0;
    border-radius: 28px;
    background-color: #f0f4f7;
    border: 1px solid #888c93;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .profile-icon {
    width: 30px;
  }

  .profile-upload h3 {
    font-size: 20px;
    color: #808896;
    padding: 0;
  }

  .profile-upload h5 {
    font-size: 16px;
    padding: 0;
    color: #b4b9c3;
    font-weight: lighter;
  }

  .profile-text {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 12px;
  }

  .upload-placeholder {
    position: absolute;
    top: -50px;
    left: 1px;
    background-color: #00000054;
    color: white;
    border-radius: 60px;
    width: 65px;
    /* height: 45px; */
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .startup-profile-card {
    position: absolute;
    /* background-color: #39a25176; */
    width: 100px !important;
    height: 100px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 80px;
    bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border-radius: 20px;
    width: 100vw !important;
    left: 0 !important;
    right: 0 !important;
  }

  .uploaded-img {
    border: 2px solid rgba(0, 0, 0, 0.112);
    max-width: 70px !important;
    height: auto;
  }

  .upload-form {
    /* background-color: blue; */
    width: 90vw;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    /* transform: translateX(-5vw); */
  }

  .upload-options-btn-container[data-v-26ebebaa] {
    z-index: 50000;
    /* position: absolute; */
    bottom: 20px;
    left: 0;
    right: 0;

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;
    position: relative;
  }
}
</style>