// TODO:the community list container tab when logged in as Diaspora ==> fixed
<template>
  <div id="diaspora">
    <PendingVerification v-if="!authUser.confirmed" />
    <div :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }">
      <div v-if="!loading">
        <div class="container searchbar-container">
          <div class="full-width-col">
            <SearchbarStartup />
          </div>
        </div>


        <div class="container2">
          <div class="row2 main-container-section">
            <div class="sidebar-col">
              <SidebarStartup :countries="getStartupsOptions.countries" :sectors="getStartupsOptions.sectors" />
            </div>

            <div id="options-group" class="options-group" :class="{ offsetSearchFilter: authUser.is_verified === 0 }">
              <a v-for="(filter, index) in Object.keys(getStartupsOptions)" href="#" :key="index" class="filter-button"
                @click.prevent="showModal(filter)">{{ filter }}</a>
              <div>
                <a @click="toggleFilter" :href="`/${$i18n.locale}/startups`" class="filter-button clear-button"
                  :class="{ showClearFilter: isClearFilter }">{{ $t("clearfilter") }}
                </a>
              </div>
            </div>
            <div class="container-col" :class="!authUser.confirmed ? 'is_not_confirmed' : ''">
              <Container />
            </div>
          </div>
        </div>
        <div v-if="modalMode === true" class="modal-container"
          :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }">
          <div class="modal-content">
            <p class="filter-description" :class="{ offsetVerified: authUser.is_verified === 0 }">
              {{ selected.description }}
            </p>
            <ul class="filter-set" :class="{ offsetVerifiedFilterSet: authUser.is_verified === 0 }">
              <li v-for="item in selected.contents" :key="item.name">
                <label class="checkbox-container">
                  <span :for="item.name" class="filter-name">{{ item.name }}</span>&nbsp;({{ item.count }})
                  <input type="checkbox" @click="addMobilefilter(item, selected.code)" />
                  <span class="checkmark"></span>
                </label>
              </li>
            </ul>
            <a href="#" class="apply-button" @click.prevent="applyFilters">{{
                $t("apply")
            }}</a>
            <a href="#" class="close-button" :class="{ offsetVerifiedButton: authUser.is_verified === 0 }"
              @click.prevent="closeModal">&times;</a>
          </div>

        </div>

      </div>
      <Loader v-else />
    </div>
  </div>

</template>
<script>
import SearchbarStartup from "../../components/Startups/Searchbar_startups.vue";
import SidebarStartup from "../../components/Startups/Sidebar_startups.vue";
import Container from "../../components/Startups/Container_startups.vue";
import NoDataFound from "../../components/NoDataFound/Version-1.vue";
import PendingVerification from "../../components/PendingVerification.vue";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import _ from "lodash";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Startups",
      meta: [
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  watch: {
    async $route() {
      await this.loadStartups({ lang: this.$i18n.locale });
    },
  },
  async mounted() {
    this.$store.commit("setStartupsPage", 1);
    this.$store.commit("getShowStartupLoadMore", true);
    this.$store.commit("resetStartupSearchFilters");
    let startup_id;
    if (this.authUser.type === 2) startup_id = this.authUser.startup ? this.authUser.startup.id : 0;
    else startup_id = 0;
    this.searchStartupsWithFilters({
      data: {
        lang: this.$i18n.locale,
        user_id: this.authUser.id,
        startup_id,
        page: this.getStartupsPage,
        limit: this.getStartupsLimit,
        filters: this.getStartupSearchFilters,
      },
    })
      .then((result) => {

        this.error = false;
      })
      .catch((err) => {
        this.error = true;
        this.loading = false;
      });

    await this.loadAfricanCountriesFilter({ lang: this.$i18n.locale });

    //console.log({ filterForCountries })
    this.$store.commit("setStartupsOptions", {
      field: "countries",
      contents: this.getCountriesFilter,
    });

    this.options.countries.contents = this.getCountriesFilter;
    await this.loadSectorsFilter({ lang: this.$i18n.locale });
    this.$store.commit("setStartupsOptions", {
      field: "sectors",
      contents: this.getSectorsFilter,
    });
    this.options.sectors.contents = this.getSectorsFilter;
    this.allFilters = this.getStartupSearchFilters;
    this.$store.commit("setSavedStartupsOptions", this.getStartupSearchFilters);

    this.startups = this.getStartups;

    // order by score

    _.orderBy(this.getStartups, ["score"], ["desc"]);
    this.$store.commit("setStartups", this.getStartups);
    this.$store.commit("setSavedStartups", this.getStartups);
    this.$store.commit("setShowPagination", true);

    this.count = this.users.length;
    this.loading = false;


  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUsers",
      "getConnectionsCounts",
      "getStartups",
      "getCountriesFilter",
      "getModalMode",
      "getStartupsPage",
      "getStartupsLimit",
      "getStartupTotalCount",
      "getStartupSearchFilters",
      "getFilterCode",
      "getSectorsFilter",
      "getStartupsOptions",
    ]),
  },
  methods: {
    toggleFilter() {
      this.isClearFilter = false;
    },
    ...mapActions([
      "loadUsers",
      "loadConnCounts",
      "loadStartups",
      "loadAllStartups",
      "loadCountriesFilter",
      "searchWithFilters",
      "searchStartupsWithFilters",
      "loadSectorsFilter",
      "loadAfricanCountriesFilter",
      "getStartupOptionField",
    ]),
    async loadAllStartups() {
      this.loading = true;
      // if (!JSON.parse(localStorage.getItem("startups"))) {
      //   const lang = i18n.locale;
      //   await this.loadStartups({ lang });
      //   this.startups = _.orderBy(this.getStartups, ["score"], ["desc"]);
      //   //save to locale storage
      //   localStorage.setItem("startups", JSON.stringify(this.startups));
      // } else {
      //   this.startups = JSON.parse(localStorage.getItem("startups"));
      // }
      const lang = i18n.locale;
      await this.loadStartups({ lang });
      this.startups = _.orderBy(this.getStartups, ["score"], ["desc"]);
      this.loading = false;
    },
    async addMobilefilter(item, code) {
      this.$store.commit("setStartupsPage", 1);
      if (this.allFilters[this.getFilterCode].includes(item.value)) {
        this.filters = this.filters.filter((option) => {
          return option !== item.value;
        });
      } else {
        this.filters.push(item.value);
      }
      this.$store.commit("setStartupSearchFilters", {
        field: code,
        filters: this.filters,
      });
    },
    async applyFilters() {
      this.isClearFilter = true;
      this.$store.commit("setStartupsPage", 1);
      let startup_id = this.authUser.startup ? this.authUser.startup.id : null;
      await this.searchStartupsWithFilters({
        data: {
          lang: this.$i18n.locale,
          startup_id,
          page: this.getStartupsPage,
          limit: this.getStartupsLimit,
          filters: this.getStartupSearchFilters,
        },
      });

      if (
        this.getStartups.length >= this.getStartupsLimit &&
        this.getStartups.length < this.getStartupsTotalCount
      ) {
        this.$store.commit("setShowStartupLoadMore", true);
      } else if (this.getStartups.length === this.getStartupsTotalCount) {
        this.$store.commit("setShowStartupLoadMore", false);
      } else {
        this.$store.commit("setShowStartupLoadMore", false);
      }
      this.$store.commit("setModalMode", false);
      this.modalMode = false;
    },
    inputClicked: () => {
      let modal = document.getElementById("options-group");
      modal.style.display = "block !important";
    },
    showModal: async function (filter) {
      this.selected = await this.getStartupOptionField(filter);
      // this.selected = this.options[filter];
      this.$store.commit("setModalMode", true);
      this.$store.commit("setFilterCode", filter);
      this.modalMode = true;
    },
    closeModal: function () {
      this.$store.commit("setModalMode", false);
      this.modalMode = false;
    },
  },
  data() {
    return {
      isClearFilter: false,
      loading: true,
      error: false,
      users: {},
      counts: {},
      startups: {},
      selected: [],
      count: 0,
      allFilters: [],
      filters: [],
      modalMode: false,
      options: {
        countries: {
          description: "Location",
          contents: [],
        },
        sectors: {
          description: "Sectors",
          contents: [],
        },
        // stages: {
        //   description: "Stages",
        //   contents: [
        //     {
        //       name: "Idea",
        //       icon: require("@/assets/registration/icons/light-bulb.svg"),
        //     },
        //     {
        //       name: "Product/MVP",
        //       icon: require("@/assets/registration/icons/new-product.svg"),
        //     },
        //     {
        //       name: "Go to Market",
        //       icon: require("@/assets/registration/icons/diagram.svg"),
        //     },
        //     {
        //       name: "Growth",
        //       icon: require("@/assets/registration/icons/growth.svg"),
        //     },
        //   ],
        // },
      },
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Startups",
  components: {
    SearchbarStartup,
    SidebarStartup,
    Container,
    PendingVerification
    //NoDataFound,
  },
};
</script>


<style>
* p {
  text-align: left !important;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue Bold.ttf");
  font-weight: 700;
}

* {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f4f2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75b38c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1ba54b;
}

#diaspora {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  /* background: #00ff80 !important; */
  /* max-width: 67%; */
}

.container2 {
  /* max-width: 1150px !important; */
  margin: 0 auto !important;
}

.form-inline {
  width: 100%;
  display: flex;
  align-items: center;
}

.form-control {
  width: 100%;
}

.btn {
  cursor: pointer;
  border: none;
}

.row2 {
  display: flex !important;
  flex-wrap: wrap !important;
}

.full-width-col {
  box-sizing: border-box;
  padding: 0 0.75%;
  width: 100%;
}

@media (max-width: 1400px) {
  .container2 {
    max-width: 1000px;
  }
}

@media (max-width: 1199px) {
  .container2 {
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .container2 {
    max-width: 650px;
  }
}

@media (max-width: 767px) {
  .container2 {
    max-width: 500px;
  }
}

@media (max-width: 574px) {
  .container2 {
    width: calc(100% - 1rem);
    width: 100vw;
  }
}

@media (min-width: 1200px) {
  #diaspora {
    min-height: 100vh;
    /* max-width: 67%; */
    /* max-width: min(78%, 1140px) !important; */
    max-width: 990px;
  }

  .full-width-col {
    /* max-width: min(78%, 1140px); */
    max-width: 990px;
    width: 100%;
  }
}
</style>

<style scoped>
.diaspora {
  position: relative !important;
  z-index: 100 !important;
}


.overlayed {
  background-image: linear-gradient(rgba(255, 255, 255, 0.667), rgb(64, 61, 61));
  width: 100%;
  height: 100vh !important;
  z-index: 10000 !important;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden !important;

}

.non_authorized {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.non_authorized p {
  width: auto;
  border-radius: 8px;
  padding: 40px;
  font-size: 20px !important;
  text-align: center;
  background: white;
}

.is_not_confirmed {
  position: relative !important;
  z-index: 100 !important;
  height: 80vh !important;
  overflow: hidden !important;
}

.searchbar-container {
  position: fixed;
  width: 100%;
  z-index: 30;
  background: #f0f4f2;
  padding: 0.5rem 0;
}

.main-container-section {
  padding-top: 5.5rem;
  overflow: auto;
}

.sidebar-col {
  position: fixed;
  width: 18vw;
}

.container-col {
  margin-left: 18vw;
  margin-bottom: 1rem;
}

.sidebar-col,
.container-col {
  box-sizing: border-box;
  padding: 0 1%;
}

.options-group {
  display: none;
  width: 100%;
  margin: 0 3% 1rem;
}

.apply-button {
  display: block;
  width: 45%;
  margin: 1.5rem auto;
  padding: 0.75rem;
  border-radius: 0.5rem;
  text-decoration: none;
  text-align: center;
  background: var(--first-color);
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
}

.filter-button:first-letter {
  text-transform: uppercase;
}

.filter-button:hover {
  background: #18792f;
  background: var(--first-color);
  color: #f5f5f5;
  border: 1px solid var(--border-color);
  font-size: 1rem;
}

.apply-button:hover {
  background: #18792f;
}

.clear-button {
  color: gray !important;
  border: 1px solid gray !important;
  display: none !important;
}

.showClearFilter {
  display: block !important;
}

.clear-button:hover {
  background: rgb(170, 162, 162);
  color: #f5f5f5;
}

.modal-container {
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  padding-bottom: 50px;
  width: 100%;
  height: 100% !important;
  overflow-y: scroll !important;
  background-color: #fff;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
}

.filter-description {
  margin: 0;
  padding: 2rem 0 0rem 1rem;
  font-size: 1.3rem;
  font-weight: 700;
}

.filter-set {
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1.1rem;
}

.filter-set li {
  margin-top: 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #f0f4f2;
}

.close-button {
  display: block;
  position: absolute;
  right: 0.125rem;
  top: 0.125rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: #fff;
  background: crimson;
}

.close-button:hover {
  background: red;
}

@media (max-width: 1599px) {
  .sidebar-col {
    width: 20vw;
    /* padding: 0 0.5%;
    padding-left: 0; */
  }

  .container-col {
    margin-left: 20vw;
    padding: 0%;
    /* padding-left: 0%; */
    /* background: #18792f; */
  }
}

@media (max-width: 1199px) {
  .sidebar-col {
    width: 25vw;
  }

  .container-col {
    margin-left: 25vw;
  }
}

@media (max-width: 991px) {
  .main-container-section {
    padding-top: 4rem;
  }

  .sidebar {
    overflow-y: unset;
    max-height: unset;
  }

  .sidebar-col {
    display: none;
  }

  .container-col {
    width: 100%;
    margin-left: 0;
    padding-top: 60px;
  }

  .options-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    z-index: 999;
    width: 80vw;
    left: 0;
    background-color: #f0f4f2 !important;
    padding: 5px;
    /* border: 1px solid #7070701c; */
    border-radius: 20px;
    width: calc(100vw - 2rem);
  }

  .checkbox-container {
    padding-left: 2rem;
  }

  .filter-button {
    box-sizing: border-box;
    display: inline-block;
    padding: 0.2rem 0.25rem;
    border-radius: 1rem;
    text-decoration: none;
    background: var(--first-color);
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    width: 28vw;
    background: #f5f5f5;
    color: #339f4d;
    border: 1px solid var(--border-color);
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .sidebar-col {
    width: 16vw;
    padding: 0 0.5%;
    padding-left: 0;

    width: 15vw;
  }

  .container-col {
    margin-left: 16vw;
    padding: 0%;
    /* padding-left: 0%; */
    /* background: #18792f;  */
    width: 100%;
    margin-left: 15vw;
    max-width: calc(990px - 15vw);
  }

  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .searchbar-container {
    position: fixed;
    width: 100%;
    z-index: 30;
    background: #f0f4f2;
    padding: 0.5rem 0;
    margin: 10px 0;
    /* max-width: 67%; */
    /* max-width: 100%!important ; */
    /* max-width: min(78%, 1140px) !important; */
    /* max-width: 1140px !important; */
    /* max-width: min(78%, 1140px) !important; */
    max-width: 990px !important;
  }

  .full-width-col {
    padding: 0;
    width: 99%;
  }
}

.showNotVerifiedNotfication {
  padding-top: 70px;
}

@media (max-width: 600px) {
  .close-button {
    display: block;
    position: fixed;
    right: 0.125rem;
    top: 0.9rem;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    padding: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-decoration: none;
    color: #fff;
    /* background: #1ba54b; */
    background: crimson;
    width: 45px !important;
    height: 45px;
    border-radius: 50%;
    z-index: 9999999999;
  }

  .options-group {
    /* display: none; */
    width: 100vw;
    /* background-color: red; */
    margin-left: 0;
    position: relative;
    position: fixed;
    top: 70px;
    background-color: #e7f1eb !important;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    margin-top: 0;
    padding: 5px 10px;
    /* margin: 0 3% 1rem; */
  }

  .filter-description {
    position: relative;
    /* top: -0.5px; */
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    background: rgb(255, 255, 255);
    background-color: #e7f1eb;
    color: #18792f;
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    /* border-bottom: 1px solid #00000024; */
    border: 1px solid var(--border-color);
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding-bottom: 20px;
  }

  .modal-content {
    position: relative;
    /* background-color: #a51d1b; */
    position: relative;
  }

  .filter-set {
    padding-left: 0;
    /* margin-top: 109px; */
    font-size: 1.1rem;
    overflow: hidden;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 80px;
    background: #0000000f;
    margin-top: 64px;
  }

  .apply-button {
    display: block;
    width: 78%;
    margin: 1.5rem auto;
    padding: 0.5rem;
    border-radius: 2.5rem;
    text-decoration: none;
    text-align: center;
    background: var(--first-color);
    color: #fff;
    font-size: 1.3rem;
    font-weight: 700;
    position: fixed;
    z-index: 999999;
    bottom: 50px;
    transform: translatex(0.25%);
  }

  .filter-set li {
    margin-top: 0.75rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #339f4d1c;
    width: 100%;
    padding-left: 1rem;
  }

  /* .filter-set li:first-child {
    border-top: 2px solid #339f4d1c;
} */
  .checkbox-container .checkmark {
    background-color: #1ba54b00;
    border: 2px solid #444343;
    border-radius: 8px;
  }

  .showNotVerifiedNotfication {
    padding-top: 30px;
  }

  .offsetSearchFilter {
    top: 95px;
  }
}

@media (max-width: 375px) {
  .showNotVerifiedNotfication {
    display: block;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    padding: 0;
    padding-bottom: 50px;
    width: 100%;
    height: 100%;
    overflow-y: scroll !important;
    max-width: 100vw;
    height: 100vh !important;
    margin-top: 60px;
    margin-top: -18px;
    z-index: 999;
    max-height: 100vh !important;
    padding: 0 !important;
  }

  .offsetVerified {
    top: 30px !important;
  }

  .offsetVerifiedButton {
    top: 40px !important;
  }

  .offsetVerifiedFilterSet {
    margin-top: 112px;
  }
}
</style>
<style>
.page-content {
  overflow: inherit !important;
}
</style>