<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <div v-if="getVault.result.data.length < 1" class="error-container">
            <p>{{ $t(text) }}</p>
        </div>

        <div class="tabs" v-else>
            <div class="tab-head">
                <ul class="tab-nav">
                    <li class="tab-nav-link" v-for="nav in tabContent" :key="nav.id" @click="tabToggle(nav.id)"
                        :class="{ active: activeTab ===  'tab_'+nav.id}">
                        {{ nav.tabNav }}
                    </li>
                </ul>
            </div>
            <div class="tab-body">
                <div class="tab-content" v-if="activeTab === 'tab_1'">
                    <Vault :vaults="getVault.result.data" />
                </div>
                <div class="tab-content" v-if="activeTab === 'tab_2'">
                    <Requests :vault-requests="getVaultRequest.result" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vault from './TabContent/vault';
import Requests from './TabContent/request';
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        Vault,
        Requests
    },

    props: {
        tabContent: Array,
        text: {
            type: String,
            default: "vaultTabText",
        },
    },

    created() {
        this.$store.dispatch('getVault');
        this.$store.dispatch('getVaultRequest')
    },

    data() {
        return {
            activeTab: "tab_1"
        }
    },

    computed: {
        ...mapGetters([
            "getVault",
            "getVaultRequest"
        ])
    },

    methods: {
        tabToggle(el) {
            this.activeTab = "tab_" + el;
        }
    }
}
</script>


<style scoped>
ol li {
    list-style: none !important;
}

.tab-head {
    height: 52px;
    display: flex;
    align-items: baseline;
}

.tab-nav {
    display: flex;
    padding: .5em;
    align-items: center
        /* justify-content: space-around; */
}

.tab-nav:hover {
    cursor: pointer
}

.tab-nav-link {
    color: #242424;
    height: 41px;
    padding: 0 .7em 0 .7em;
    margin-left: .3em;
    margin-left: 1.5em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    /* font-size: 28px; */
    line-height: 32.2px
}

.active {
    background-color: #EAF5EF;
}

.tab-body {
    height: 334px;
}

.tab-content {
    padding-left: 45px;
    padding-right: 70px;
}

.error-container {
    height: 70px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.error-container {
    padding-left: 2em;
}
</style>