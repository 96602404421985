<template>
  <div class="singin">
    <StepOne v-if="getStep === 1" />
    <StepTwo v-if="getStep === 2" />
    <StepThree v-if="getStep === 3" />
     <StepThreeBis v-if="getStep === 3.5" />
     <StepFour v-if="getStep === 4" />
     <!-- <FinalStep />  -->
    <FinalStep2  v-if="getStep === 5"/>
    <!-- <RegistrationProgress v-if="getStep > 1 && getStep < 5" /> -->
  </div>
</template>
<script>
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo.vue";
import StepThree from "./StepThree/StepThree.vue";
import StepThreeBis from "./StepThree_startup/StepThreeBis.vue";
import StepFour from "./StepFour/StepFour.vue";
import FinalStep from "./FinalStep/FinalStep.vue";
import FinalStep2 from "./FinalStep/FinalStep2.vue";
import RegistrationProgress from "../../../components/Auth/RegistrationProgress.vue";

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  components: {
     StepOne,
    StepTwo,
    StepThree,
    StepThreeBis,
    StepFour,
    // FinalStep,
    FinalStep2
    // RegistrationProgress,
  },
  computed: {
    ...mapGetters(["getStep"]),
    // ...mapState(["isShowActionModal"])
  },
  // mounted: {
  //   // window.scrollTo(0, 0);
  // }
  
};
</script>
<style scoped>
body { overflow: hidden; }
/* @import '../../../assets/css/Variable.css'; */
.singin {
  /* position: relative;
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between; */
  /* height: 100vh; */
  /* overflow: hidden; */ 
  /* height: 100vh; */
  position: absolute;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden; 
  width:100vw; 
  /* background-color: red; */
}
@media (max-width: 600px){
  .singin {
    height:  auto;
    height: -webkit-fill-available;
    /* background-color: lemon; */
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 0 auto;
  }
}


</style>