<template>
  <div class="label-btn">{{ label }}</div>
</template>

<script>
export default {
  name: "ButtonLabel",
  props: {
    label: String,
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.label-btn {
  position: absolute;
  z-index: 50000 !important;
  background-color: rgb(29, 33, 36);
  color: white;
  font-size: 13px;
  padding: 8px;
  width: auto;
  top: -42px;
  left: -110px;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  opacity: 0;
  pointer-events: none;
}
</style>