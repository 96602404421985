<template>
  <div>
    <div class="mb-3 shadow-sm rounded box bg-white osahan-slider-main p-1">
      <div class="supporters">
        <h4 class="header">Willing to Support</h4>
      </div>
      <div class="osahan-slider">
        <carousel
          :responsive="responsive"
          :dots="true"
          :nav="false"
          :autoplayLoop="true"
          :autoplay="true"
          :autoplayTimeout="10000"
        >
          <ProfileCard
            v-for="(user, index) in users.slice(0, 10)"
            :key="index"
            :user="user"
            type="willingToSupport"
          />
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
      },
    };
  },
  name: "WillingToSupport",
  props: {
    users: {},
  },
};
</script>

<style scoped>
.widget-author {
  text-align: center;
  padding: 15px;
  position: relative;
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

.widget-author .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.widget-author .cover-img img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100px;
}

.widget-author .profile-img {
  margin-bottom: 26px;
}

.widget-author .profile-img a {
  border-radius: 50%;
  position: relative;
}

.widget-author .profile-img a img {
  box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.widget-author .author-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-author .author-name a {
  color: #000000;
}

.widget-author .author-name a:hover {
  color: #0f7f12;
}

.widget-author .author-location {
  font-size: 14px;
  color: #9e9faf;
  margin-bottom: 5px;
}

.widget-author .author-badge {
  margin-bottom: 45px;
}

.widget-author .author-badge li {
  display: inline-block;
  margin-right: 8px;
}

.widget-author .author-badge li:last-child {
  margin-right: 0;
}

.widget-author .author-badge a {
  font-size: 20px;
  color: #ffffff;
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  position: relative;
}

.widget-author .author-badge a:before {
  content: "";
  height: 5px;
  width: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 8px;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}

.widget-author .author-badge .profile-circle {
  background-color: #e7ecf4;
  font-size: 16px;
  font-weight: 700;
  color: #8f8f8f;
}

.widget-author .author-statistics li {
  display: inline-block;
  position: relative;
  line-height: 1;
}

.widget-author .author-statistics li:after {
  content: "";
  height: 31px;
  width: 1px;
  background-color: #e8e8e8;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-author .author-statistics li:last-child {
  margin-right: 0;
}

.widget-author .author-statistics li:last-child:after {
  display: none;
}

.widget-author .author-statistics li a {
  text-align: center;
  padding: 0 15px 0 12px;
}

.widget-author .author-statistics .item-number {
  font-weight: 400;
  color: #9e9faf;
  display: block;
  margin-bottom: 5px;
}

.widget-author .author-statistics .item-text {
  font-weight: 400;
  color: #9e9faf;
  font-size: 12px;
}

.special-input-class {
  background-color: black !important;
  color: white !important;
}
.special-highlight-class {
  font-weight: 900;
  color: #585656;
}

.connection {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
}
.connectionTitle {
  height: 25px !important;
}
/* .supporters {
  padding: 0px 0;
} */
.header {
  font-size: 20px !important;
  padding-left: 20px;
  color: rgb(121, 121, 121);
  border-bottom: 2px solid rgb(169, 169, 169) !important;
}
</style>
