<template>
  <a class="card-item" :href="`/${getUserLang}/startup/${startup.slug}`">
    <div class="card">
      <div class="card-header" :style="showBackground ? bgColorCss() : ''">
        <cld-image
          class="cover-img"
          cloudName:bantaba
          :publicId="coverImg"
          alt="Cover"
          v-if="!showBackground"
        >
          <cld-transformation height="200" width="200" />
          <cld-transformation border="5px_solid_red" radius="20" />
        </cld-image>
        <!-- <img
          class="cover-img"
          :class="{ skeletonLoader: !cover_isLoaded }"
          :src="cover_img"
          @load="onpcoverImgLoad"
          alt="cover image"
          v-if="!showBackground"
        /> -->
      </div>
      <div class="card-body">
        <div class="startupname">
          <p>{{ name }}</p>
          <!-- {{ startup.score }} -->
        </div>
        <div class="startup-discription">
          <p>{{ description.substring(0, 100) }}...</p>
        </div>
        <div class="startup-sector">
          <img class="sector-icon" src="@/assets/images/startups/sector.svg" />
          <p v-if="sector">{{ sector }}</p>
          <p v-else>--</p>
        </div>
        <div class="startup-footer">
          <div class="start">
            <label>{{ $t("Founded") }}:</label>
            <p v-if="yrFounded != 'Invalid date'">
              {{ yrFounded }}
            </p>
            <p v-else>-</p>
          </div>
          <div class="end">
            <img
              class="location-icon"
              src="@/assets/images/startups/location.svg"
            />
            <p class="location-text">{{ location }}</p>
          </div>
        </div>
      </div>
      <div class="card-image">
        <cld-image
          class="img-profile rounded-circle avatar"
          cloudName:bantaba
          :publicId="profileImg"
          alt="Image Placeholder"
        >
          <cld-transformation height="200" width="200" />
          <cld-transformation border="5px_solid_red" radius="20" />
        </cld-image>
        <!-- <img
          class="img-profile rounded-circle avatar"
          :class="{ skeletonLoader: !avatar_isLoaded }"
          :src="profile_img"
          @load="onprofileImgLoad"
          alt="avatar"
        /> -->
      </div>
    </div></a
  >
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    if (
      this.coverImg ==
      "https://res.cloudinary.com/bantaba/image/upload/v1624848743/bantaba/images-5_zhv0ka.jpg"
    )
      this.showBackground = true;
    else this.showBackground = false;

    let profileUrl = this.profileImg;
    if (profileUrl && profileUrl.indexOf("upload/") != -1) {
      let index = profileUrl.indexOf("upload/");
      let first_part = profileUrl.substr(0, index + 6);
      let last_part = profileUrl.substr(index + 6);
      // this.avatar_image = first_part + "/q_auto:low/c_fill,h_250,w_250/" + last_part;
      this.profile_img =
        first_part + "/q_auto:low/c_fill,h_70,w_70" + last_part;
    } else {
      this.profile_img = this.profileImg;
    }

    //cover image
    let coverUrl = this.coverImg;
    if (coverUrl && coverUrl.indexOf("upload/") != -1) {
      let index = coverUrl.indexOf("upload/");
      let first_part = coverUrl.substr(0, index + 6);
      let last_part = coverUrl.substr(index + 6);
      this.cover_img = first_part + "/q_auto:low/c_fit,h_250,w_80" + last_part;
    } else {
      this.cover_img = coverUrl;
    }
  },
  props: {
    bgColor: String,
    name: String,
    description: String,
    sector: String,
    yrFounded: String,
    location: String,
    profileImg: String,
    coverImg: String,
    startup: Object,
  },
  data() {
    return {
      profile_img: "",
      cover_img: "",
      avatar_isLoaded: false,
      cover_isLoaded: false,
      showBackground: false,
    };
  },
  computed: {
    ...mapGetters(["authUser", "getUserLang"]),
  },
  methods: {
    onpcoverImgLoad() {
      this.cover_isLoaded = true;
    },
    onprofileImgLoad() {
      this.avatar_isLoaded = true;
    },
    bgColorCss: function () {
      return {
        background: this.bgColor,
      };
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
}
.card {
  margin-top: 1rem;
  border: 1px solid #e4ece8;
  border-radius: 0.75rem !important;
  background: #fff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0;
  height: auto;
  position: relative;
  overflow: hidden;
}
.card-header {
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 165, 75, 0.35);
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 7px rgb(0 0 0 / 5%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-header .cover-img {
  width: 100% !important;
  height: auto;
  /* object-fit: none; */
  z-index: 20;
  opacity: 0.75;
  backdrop-filter: blur(50px) !important;
}
.card-image {
  position: absolute;
  top: 1.8rem !important;
  left: 1rem !important;
  width: 100px !important;
  height: 100px !important;
  z-index: 10 !important;
}
.card-image img {
  width: 100%;
  height: 100%;
  border: 2px solid white !important;
  background-color: white !important;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
  object-fit: cover;
}
.card-name {
  height: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1.05rem;
}

a .card:hover {
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.75rem !important;
}
.card-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  margin-left: 3.5%;
  margin-right: 3.5%;
  border: 1px solid #1ba54b;
  border-radius: 0.8rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.8rem;
}
.card-btn:hover {
  background: #1ba54b;
  color: #fff;
}
.card-btn:hover path {
  fill: #fff;
}
.messenger-button {
  color: #1ba54b;
}
.profile-button {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  background: #f6f6f6;
  color: #707070;
}
.card-body {
  width: 100%;
  height: 16rem;
  position: relative;
  padding-top: 30px;
}
.startupname {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  padding: 0px 15px !important;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.startup-discription {
  width: 100%;
  font-size: 15px;
  font-weight: normal !important;
  font-weight: bold;
  line-height: 22px;
  padding: 15px 15px;
  color: #21222483;
  height: 58%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 7rem;
  overflow: hidden;

  font-size: 14px;
  padding: 10px 10px;
  text-justify: inter-word;
}
.startup-sector {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  padding: 0 15px;
  color: #888a91;
  height: 2.8rem;
  display: flex;
  align-items: center;
}
.startup-sector p {
  padding-left: 10px;
  font-size: 17px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sector-icon {
  width: 19px;
  height: 19px;
}
.startup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  position: relative;
  height: 20%;
  background-color: #57b36604;
  border-top: 1px solid #7070701f;
}
.startup-footer > div {
  display: flex;
  font-size: 14px;
  align-items: center;
  width: 50%;
  color: #1f243053;
}
.end {
  justify-content: flex-end;
}
.start {
  justify-content: flex-start;
}
.location-text {
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.location-icon {
  width: 13px;
  height: 13px;
  opacity: 0.4;
}
.skeletonLoader {
  width: 112px;
  height: 112px;
  background-color: #eee;
}
@media (max-width: 991px) {
  .card-header {
    height: auto !important;
  }
  .card-name {
    font-size: 1.5rem;
  }
  .card-btn {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  .card {
    border-radius: 1.4rem !important;
    margin-top: 0;
    margin-bottom: 0.6rem;

    border-radius: 0.4rem !important;
    overflow: hidden;
  }
  .card-header {
    height: 5rem !important;
    /* height: 4rem !important; */
    overflow: hidden;
    /* border-radius: 0 0 40px 40px; */
  }
  .card-image {
    position: absolute;
    top: 1.8rem !important;
    left: calc(50% - 3.5rem) !important;
    width: 7rem !important;
    height: 7rem !important;
    /* left: calc(50% - 2.25rem) !important;
    top: 1.5rem !important;
    width: 4.5rem !important;
    height: 4.5rem !important; */
    z-index: 10 !important;
  }
  .card-body {
    width: 100%;
    height: calc(24rem - 5rem);
    position: relative;
    padding-top: 0px;
    padding-top: 4.5rem;

    /* height: calc(19.5rem - 5rem);
    padding-top: 2.7rem; */
  }
  .startup-discription {
    height: 6rem;

    /* height: 5.4rem; */
    /* background-color: orange; */
  }
  .startup-discription p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .startupname {
    width: 90%;
    height: 3rem;
    justify-content: center;
    font-size: 1.3rem;
    background: #74747414;
    border-radius: 20px;
    text-align: center;
    margin: 0 5%;
    text-align: left;

    /* height: 2.5rem;
     font-size: 1rem; */
  }
  .startupname p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .startup-sector {
    height: auto;
    padding-bottom: 1rem;

    /* padding-bottom: 0.5rem; */
  }
  .startup-sector p {
    padding-left: 10px;
    font-size: 15px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* font-size: 14px !important; */
  }
}
@media (max-width: 600px) {
  .card {
    border-radius: 1.4rem !important;
    margin-top: 0.4rem;
    margin-bottom: 0rem;
  }
  .card-body {
    width: 100%;
    height: calc(25.5rem - 5rem);
    position: relative;
    padding-top: 0px;
    padding-top: 4.5rem;
  }
  .card-image {
    position: absolute;
    top: 1.8rem !important;
    left: calc(50% - 3.5rem) !important;
    width: 7rem !important;
    height: 7rem !important;
    z-index: 10 !important;
  }
  .card-header {
    height: 5rem !important;
    overflow: hidden;
    /* border-radius: 0 0 40px 40px; */
  }
  .startupname {
    width: 90%;
    height: 3rem;
    justify-content: center;
    font-size: 1.3rem;
    background: #74747414;
    border-radius: 20px;
    text-align: center;
    margin: 0 5%;
  }
  .startupname p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .startup-discription p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
</style>