<!-- eslint-disable vue/multi-word-component-names -->
<template >
  <ul class="pagination">
    <li class="page-indicator next-prev" @click="goPrevNext('prev')">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.53"
          height="19"
          class="icon_svg"
          viewBox="0 0 11.53 19"
        >
          <path
            id="FontAwsome_chevron-right_"
            data-name="FontAwsome (chevron-right)"
            d="M27.8,47.9l8.456,8.456a1.044,1.044,0,0,0,1.477,0l.986-.986a1.044,1.044,0,0,0,0-1.475l-6.7-6.733,6.7-6.733a1.044,1.044,0,0,0,0-1.475l-.986-.986a1.044,1.044,0,0,0-1.477,0L27.8,46.419A1.044,1.044,0,0,0,27.8,47.9Z"
            transform="translate(-27.495 -37.657)"
            fill="#39a251"
          />
        </svg>
      </span>
      Prev
    </li>
    <li
      class="page-indicator"
      :class="getDiasporaPage === ++page ? 'active' : ''"
      @click="paginate(page)"
      v-for="(index, page) in (getDiasporaTotalCount % getDiasporaLimit) - 1"
      :key="index"
    >
      <span>{{ page }} </span>
    </li>
    <li class="page-indicator next-prev" @click="goPrevNext('next')">
      Next
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.53"
          height="19"
          viewBox="0 0 11.53 19"
          class="icon_svg"
        >
          <path
            id="FontAwsome_chevron-right_"
            data-name="FontAwsome (chevron-right)"
            d="M38.719,47.9l-8.456,8.456a1.044,1.044,0,0,1-1.477,0l-.986-.986a1.044,1.044,0,0,1,0-1.475l6.7-6.733-6.7-6.733a1.044,1.044,0,0,1,0-1.475l.986-.986a1.044,1.044,0,0,1,1.477,0l8.456,8.456A1.044,1.044,0,0,1,38.719,47.9Z"
            transform="translate(-27.495 -37.657)"
            fill="#39a251"
          />
        </svg>
      </span>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    this.numberofPages =
      (this.getDiasporaTotalCount % this.getDiasporaLimit) - 1;
  },
  create() {
    this.numberofPages =
      (this.getDiasporaTotalCount % this.getDiasporaLimit) - 1;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getDiasporaTotalCount",
      "getDiasporaPage",
      "getDiasporaLimit",
      "getSearchFilters",
      "getShowPagination",
    ]),

    getPageRange: function () {
      return {
        start: 4,
        end: 7,
      };
    },
  },
  data() {
    return {
      numberofPages: 0,
      modalMode: false,
    };
  },
  methods: {
    ...mapActions([
      "loadDiaspora",
      "loadEngagementFilter",
      "loadCountriesFilter",
      "loadExpertisesFilter",
      "searchWithFilters",
    ]),
    async paginate(page) {
      
      if (this.numberofPages === page) this.$store.commit("setLastPage", true);
      else this.$store.commit("setLastPage", false);
      await this.searchWithFilters({
        data: {
          user_id: this.authUser.id,
          page: page,
          limit: this.getDiasporaLimit,
          filters: this.getSearchFilters,
        },
      });
      this.$store.commit("setDiasporaPage", page);
    },
    async goPrevNext(type) {
      let page;
      if (type === "next") {
        page = this.getDiasporaPage + 1;
      } else if (type === "prev") {
        page = this.getDiasporaPage - 1;
      }
      if (page && page <= this.numberofPages) {
        await this.searchWithFilters({
          data: {
            user_id: this.authUser.id,
            page: page,
            limit: this.getDiasporaLimit,
            filters: this.getSearchFilters,
          },
        });

        this.$store.commit("setDiasporaPage", page);
      }
    },
    showModal: function () {
      this.modalMode = true;
    },
    hideModal: function () {
      this.modalMode = false;
    },
    check: function (event) {
      if (event.target.classList.contains("modal-content"))
        this.modalMode = false;
    },
  },
};
</script>



<style scoped>
.icon_svg {
  padding: 0px 5px !important;
  width: 18px !important;
  height: 18px !important;
}

.next-prev {
  padding: 0px 30px !important;
}

.next-prev:hover {
  background-color: var(--first-color) !important;
  color: white !important;
}

.next-prev:hover path {
  fill: white !important;
}

.modal-container {
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.modal-content {
  position: relative;
  height: 100%;
}
.page-shower {
  width: 10%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  background: #39a251; /* rgba(57, 162, 81, .8); */
  color: #fff;
  font-weight: 900;
  cursor: default;
}
.page-number-range {
  text-align: center;
  padding: 0.25rem 0;
  font-weight: 900;
}
.pagination {
  width: 60%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-evenly;
}
.page-indicator {
  width: 2rem;
  height: 2rem;
  border: 1.5px solid #edeff7;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #36333d;
  font-size: 0.85rem;
}
.page-indicator:hover {
  background: #edeff7;
}
.page-indicator.active {
  background: #39a251;
  color: #fff;
}
.page-indicator.page-ellipsis {
  width: 8rem;
  background: #edeff7;
  letter-spacing: 0.75rem;
}
.page-indicator span {
  font-weight: 700;
}
@media (max-width: 1399px) {
  .pagination {
    width: 70%;
  }
}
@media (max-width: 1199px) {
  .pagination {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .pagination {
    /* display: none; */
  }
}
</style>