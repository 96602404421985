<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tag" v-if="name" :class='{ editBorder: isEditMode}'>
    <span class="tag-icon" v-if="icon">
      <svg v-if="icon === 'Invest'" xmlns="http://www.w3.org/2000/svg" width="125.612" height="110.035"
        viewBox="0 0 125.612 110.035">
        <path id="FontAwsome_seedling_" data-name="FontAwsome (seedling)"
          d="M15.576,47.576H0a54.521,54.521,0,0,0,54.518,54.518v35.047a3.906,3.906,0,0,0,3.894,3.894H66.2a3.906,3.906,0,0,0,3.894-3.894V102.094A54.521,54.521,0,0,0,15.576,47.576ZM109.035,32a54.472,54.472,0,0,0-47.63,28.038,62.4,62.4,0,0,1,14.36,26.188A54.535,54.535,0,0,0,124.612,32Z"
          transform="translate(0.5 -31.5)" fill="#1ba54b" stroke="#707070" stroke-width="1" />
      </svg>

      <svg v-else-if="icon === 'Consulting'" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 16.5 24">
        <path
          d="M8.25,3.75a4.5,4.5,0,0,0-4.5,4.5.75.75,0,1,0,1.5,0,3,3,0,0,1,3-3,.75.75,0,1,0,0-1.5ZM4.5,21.524a.745.745,0,0,0,.126.414l1.149,1.727A.75.75,0,0,0,6.4,24h3.7a.749.749,0,0,0,.624-.335l1.149-1.727A.754.754,0,0,0,12,21.524L12,19.5H4.5ZM8.25,0A8.246,8.246,0,0,0,2.042,13.677,13.415,13.415,0,0,1,4.5,18v0h2.25v-.006a2.218,2.218,0,0,0-.1-.66,14.781,14.781,0,0,0-2.914-5.141,6,6,0,1,1,9.033,0,14.814,14.814,0,0,0-2.911,5.131,2.227,2.227,0,0,0-.1.67v0H12v0a13.4,13.4,0,0,1,2.457-4.32A8.246,8.246,0,0,0,8.25,0Z"
          fill="#1ba54b" />
      </svg>

      <svg v-else-if="icon === 'Mentoring'" xmlns="http://www.w3.org/2000/svg" width="130" height="104.799"
        viewBox="0 0 130 104.799">
        <path id="FontAwsome_chalkboard-teacher_" data-name="FontAwsome (chalkboard-teacher)"
          d="M40.948,69.3a4.5,4.5,0,0,0-1.39.215,25.971,25.971,0,0,1-8.06,1.36,25.993,25.993,0,0,1-8.062-1.36,4.492,4.492,0,0,0-1.388-.215A22.051,22.051,0,0,0,0,91.472a9.426,9.426,0,0,0,9.45,9.328h44.1A9.426,9.426,0,0,0,63,91.472,22.051,22.051,0,0,0,40.948,69.3ZM31.5,63A18.9,18.9,0,1,0,12.6,44.1,18.9,18.9,0,0,0,31.5,63Zm85.05-63h-75.6A9.621,9.621,0,0,0,31.5,9.763V18.9a24.914,24.914,0,0,1,12.6,3.5V12.6h69.3V69.3H100.8V56.7H75.6V69.3H60.589A25.092,25.092,0,0,1,68.4,81.9h48.146A9.621,9.621,0,0,0,126,72.137V9.763A9.621,9.621,0,0,0,116.548,0Z"
          transform="translate(2.002 2)" fill="#1ba54b" stroke="#707070" stroke-width="4" />
      </svg>
    </span>
    <span class="tag-name">{{ name }}</span>
    <span v-if="isEditMode" :class='{showClose: isEditMode}' class="close-icon" @click="remove()">

      <svg data-v-33c4a82e="" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path data-v-33c4a82e=""
          d="M8.275,86l3.411-3.411a1.072,1.072,0,0,0,0-1.516l-.758-.758a1.072,1.072,0,0,0-1.516,0L6,83.725,2.589,80.314a1.072,1.072,0,0,0-1.516,0l-.758.758a1.072,1.072,0,0,0,0,1.516L3.725,86,.314,89.411a1.072,1.072,0,0,0,0,1.516l.758.758a1.072,1.072,0,0,0,1.516,0L6,88.275l3.411,3.411a1.072,1.072,0,0,0,1.516,0l.758-.758a1.072,1.072,0,0,0,0-1.516Z"
          transform="translate(0 -80)" fill="#c31e3e"></path>
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    name: String,
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    remove: function () {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon svg {
  width: 11px !important;
  height: 11px !important;
}
.showClose{
  display: block !important;
  cursor: pointer;
}
</style>
