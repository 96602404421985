<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0 text-center" v-if="name">{{ $t(name) }}</h6>
      <h6 class="m-0 text-center" v-else>{{ $t('about') }}</h6>
    </div>
    <div class="box-body p-3" v-if="text">
      <p class="text-justify" v-if="is_html" v-html="text"></p>
      <p class="text-justify" v-else>
        {{ text }}
      </p>
    </div>
    <NoData
      class="p-4"
      v-else
      text="noAbout"
      buttonText="addAbout"
      :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.editProfile')}`"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'About',
  props: {
    name: String,
    text: String,
    is_html: Boolean,
  },
};
</script>

<style></style>
