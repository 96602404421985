<template>
  <main class="container">
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">4</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <span class="particle">0</span>
    <article class="content">
      <p>{{ $t("pageNot") }} {{ $t("found") }}</p>
      <p>{{ $t("errorMsg") }}</p>
      <p>
        <button @click="goHome" class="goHome">{{ $t("goHome") }}</button>
      </p>
    </article>
  </main>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Error",
  methods: {
    goHome() {
      // this.$router.push("/").catch(err => {});
      if (this.$route.path != "/") {
          this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #dddddd;
  color: black;
  font-family: arial, sans-serif;
  overflow: hidden;
}

.content {
  position: relative;
  width: 600px;
  max-width: 100%;
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  padding: 60px 40px;
  text-align: center;
  box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995)
    forwards;
}

.content p {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
  color: #1d1d1d;
}
.content p:last-child {
  margin-bottom: 0;
}
.content button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  background: transparent;
  font-size: 1rem;
  color: #595959;
  background-color: #59595933;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 200px;
}

.content button:hover {
  background-color: #349e4d !important;
  color: #fff !important;
}

.particle {
  position: absolute;
  display: block;
  pointer-events: none;
  color: #349e4d !important;
}
.particle:nth-child(1) {
  top: 49.14004914%;
  left: 59.1715976331%;
  font-size: 14px;
  filter: blur(0.02px);
  animation: 30s float2 infinite;
}
.particle:nth-child(2) {
  top: 42.3124231242%;
  left: 21.7176702863%;
  font-size: 13px;
  filter: blur(0.04px);
  animation: 38s float2 infinite;
}
.particle:nth-child(3) {
  top: 38.3292383292%;
  left: 67.0611439842%;
  font-size: 14px;
  filter: blur(0.06px);
  animation: 23s float infinite;
}
.particle:nth-child(4) {
  top: 40.344403444%;
  left: 63.1786771964%;
  font-size: 13px;
  filter: blur(0.08px);
  animation: 32s float2 infinite;
}
.particle:nth-child(5) {
  top: 83.0280830281%;
  left: 93.2286555447%;
  font-size: 19px;
  filter: blur(0.1px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(6) {
  top: 53.2019704433%;
  left: 17.7865612648%;
  font-size: 12px;
  filter: blur(0.12px);
  animation: 29s floatReverse infinite;
}
.particle:nth-child(7) {
  top: 57.490864799%;
  left: 38.1978452498%;
  font-size: 21px;
  filter: blur(0.14px);
  animation: 32s floatReverse infinite;
}
.particle:nth-child(8) {
  top: 52.3002421308%;
  left: 90.6432748538%;
  font-size: 26px;
  filter: blur(0.16px);
  animation: 21s float2 infinite;
}
.particle:nth-child(9) {
  top: 2.9268292683%;
  left: 60.7843137255%;
  font-size: 20px;
  filter: blur(0.18px);
  animation: 25s floatReverse infinite;
}
.particle:nth-child(10) {
  top: 76.4705882353%;
  left: 19.6850393701%;
  font-size: 16px;
  filter: blur(0.2px);
  animation: 32s float2 infinite;
}
.particle:nth-child(11) {
  top: 12.6366950182%;
  left: 66.4711632454%;
  font-size: 23px;
  filter: blur(0.22px);
  animation: 40s float2 infinite;
}
.particle:nth-child(12) {
  top: 18.6732186732%;
  left: 10.8481262327%;
  font-size: 14px;
  filter: blur(0.24px);
  animation: 35s float2 infinite;
}
.particle:nth-child(13) {
  top: 85.8866103739%;
  left: 76.7735665695%;
  font-size: 29px;
  filter: blur(0.26px);
  animation: 37s floatReverse2 infinite;
}
.particle:nth-child(14) {
  top: 92.7536231884%;
  left: 71.0116731518%;
  font-size: 28px;
  filter: blur(0.28px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(15) {
  top: 27.0858524788%;
  left: 33.106134372%;
  font-size: 27px;
  filter: blur(0.3px);
  animation: 34s float infinite;
}
.particle:nth-child(16) {
  top: 41.2776412776%;
  left: 44.3786982249%;
  font-size: 14px;
  filter: blur(0.32px);
  animation: 26s floatReverse2 infinite;
}
.particle:nth-child(17) {
  top: 23.5582822086%;
  left: 71.921182266%;
  font-size: 15px;
  filter: blur(0.34px);
  animation: 24s floatReverse2 infinite;
}
.particle:nth-child(18) {
  top: 41.9512195122%;
  left: 49.0196078431%;
  font-size: 20px;
  filter: blur(0.36px);
  animation: 38s float infinite;
}
.particle:nth-child(19) {
  top: 64.578313253%;
  left: 76.6990291262%;
  font-size: 30px;
  filter: blur(0.38px);
  animation: 23s float2 infinite;
}
.particle:nth-child(20) {
  top: 81.6728167282%;
  left: 67.1273445212%;
  font-size: 13px;
  filter: blur(0.4px);
  animation: 22s floatReverse2 infinite;
}
.particle:nth-child(21) {
  top: 41.5458937198%;
  left: 59.3385214008%;
  font-size: 28px;
  filter: blur(0.42px);
  animation: 36s float2 infinite;
}
.particle:nth-child(22) {
  top: 70.3296703297%;
  left: 80.4710500491%;
  font-size: 19px;
  filter: blur(0.44px);
  animation: 38s float infinite;
}
.particle:nth-child(23) {
  top: 86.6180048662%;
  left: 55.7729941292%;
  font-size: 22px;
  filter: blur(0.46px);
  animation: 22s float infinite;
}
.particle:nth-child(24) {
  top: 31.884057971%;
  left: 26.2645914397%;
  font-size: 28px;
  filter: blur(0.48px);
  animation: 33s float2 infinite;
}
.particle:nth-child(25) {
  top: 5.7971014493%;
  left: 27.2373540856%;
  font-size: 28px;
  filter: blur(0.5px);
  animation: 35s float2 infinite;
}
.particle:nth-child(26) {
  top: 86.8292682927%;
  left: 56.862745098%;
  font-size: 20px;
  filter: blur(0.52px);
  animation: 40s float2 infinite;
}
.particle:nth-child(27) {
  top: 80.490797546%;
  left: 6.8965517241%;
  font-size: 15px;
  filter: blur(0.54px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(28) {
  top: 67.7966101695%;
  left: 74.0740740741%;
  font-size: 26px;
  filter: blur(0.56px);
  animation: 31s floatReverse infinite;
}
.particle:nth-child(29) {
  top: 38.8349514563%;
  left: 73.2421875%;
  font-size: 24px;
  filter: blur(0.58px);
  animation: 35s float infinite;
}
.particle:nth-child(30) {
  top: 89.1064871481%;
  left: 90.4621435595%;
  font-size: 17px;
  filter: blur(0.6px);
  animation: 37s floatReverse2 infinite;
}
.particle:nth-child(31) {
  top: 32.5123152709%;
  left: 32.6086956522%;
  font-size: 12px;
  filter: blur(0.62px);
  animation: 33s float infinite;
}
.particle:nth-child(32) {
  top: 12.5452352232%;
  left: 5.8309037901%;
  font-size: 29px;
  filter: blur(0.64px);
  animation: 37s float2 infinite;
}
.particle:nth-child(33) {
  top: 17.6039119804%;
  left: 83.4970530452%;
  font-size: 18px;
  filter: blur(0.66px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(34) {
  top: 64.7058823529%;
  left: 56.1023622047%;
  font-size: 16px;
  filter: blur(0.68px);
  animation: 26s float2 infinite;
}
.particle:nth-child(35) {
  top: 82.8915662651%;
  left: 37.8640776699%;
  font-size: 30px;
  filter: blur(0.7px);
  animation: 31s floatReverse2 infinite;
}
.particle:nth-child(36) {
  top: 74.3063932449%;
  left: 95.2380952381%;
  font-size: 29px;
  filter: blur(0.72px);
  animation: 30s floatReverse2 infinite;
}
.particle:nth-child(37) {
  top: 20.7151664612%;
  left: 74.1839762611%;
  font-size: 11px;
  filter: blur(0.74px);
  animation: 39s floatReverse infinite;
}
.particle:nth-child(38) {
  top: 59.9758162031%;
  left: 95.423563778%;
  font-size: 27px;
  filter: blur(0.76px);
  animation: 39s floatReverse2 infinite;
}
.particle:nth-child(39) {
  top: 5.8111380145%;
  left: 76.0233918129%;
  font-size: 26px;
  filter: blur(0.78px);
  animation: 36s float infinite;
}
.particle:nth-child(40) {
  top: 12.7764127764%;
  left: 44.3786982249%;
  font-size: 14px;
  filter: blur(0.8px);
  animation: 37s float2 infinite;
}
.particle:nth-child(41) {
  top: 72.9064039409%;
  left: 8.8932806324%;
  font-size: 12px;
  filter: blur(0.82px);
  animation: 30s float2 infinite;
}
.particle:nth-child(42) {
  top: 85.8536585366%;
  left: 41.1764705882%;
  font-size: 20px;
  filter: blur(0.84px);
  animation: 28s float2 infinite;
}
.particle:nth-child(43) {
  top: 60.7099143207%;
  left: 81.6125860374%;
  font-size: 17px;
  filter: blur(0.86px);
  animation: 32s float infinite;
}
.particle:nth-child(44) {
  top: 77.1084337349%;
  left: 81.5533980583%;
  font-size: 30px;
  filter: blur(0.88px);
  animation: 27s floatReverse2 infinite;
}
.particle:nth-child(45) {
  top: 85.2300242131%;
  left: 64.3274853801%;
  font-size: 26px;
  filter: blur(0.9px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(46) {
  top: 55.7457212714%;
  left: 4.9115913556%;
  font-size: 18px;
  filter: blur(0.92px);
  animation: 27s floatReverse infinite;
}
.particle:nth-child(47) {
  top: 8.7804878049%;
  left: 15.6862745098%;
  font-size: 20px;
  filter: blur(0.94px);
  animation: 25s float2 infinite;
}
.particle:nth-child(48) {
  top: 76.3285024155%;
  left: 24.3190661479%;
  font-size: 28px;
  filter: blur(0.96px);
  animation: 33s float2 infinite;
}
.particle:nth-child(49) {
  top: 29.3398533007%;
  left: 94.3025540275%;
  font-size: 18px;
  filter: blur(0.98px);
  animation: 32s float2 infinite;
}
.particle:nth-child(50) {
  top: 74.1463414634%;
  left: 14.7058823529%;
  font-size: 20px;
  filter: blur(1px);
  animation: 37s float2 infinite;
}
.particle:nth-child(51) {
  top: 0.9852216749%;
  left: 62.2529644269%;
  font-size: 12px;
  filter: blur(1.02px);
  animation: 39s floatReverse infinite;
}
.particle:nth-child(52) {
  top: 68.6274509804%;
  left: 21.6535433071%;
  font-size: 16px;
  filter: blur(1.04px);
  animation: 34s float2 infinite;
}
.particle:nth-child(53) {
  top: 17.5395858709%;
  left: 55.8276199804%;
  font-size: 21px;
  filter: blur(1.06px);
  animation: 32s floatReverse infinite;
}
.particle:nth-child(54) {
  top: 30.3178484108%;
  left: 81.5324165029%;
  font-size: 18px;
  filter: blur(1.08px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(55) {
  top: 79.6068796069%;
  left: 54.2406311637%;
  font-size: 14px;
  filter: blur(1.1px);
  animation: 31s float2 infinite;
}
.particle:nth-child(56) {
  top: 79.6068796069%;
  left: 7.8895463511%;
  font-size: 14px;
  filter: blur(1.12px);
  animation: 39s float2 infinite;
}
.particle:nth-child(57) {
  top: 32.2738386308%;
  left: 55.0098231827%;
  font-size: 18px;
  filter: blur(1.14px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(58) {
  top: 36.803874092%;
  left: 9.746588694%;
  font-size: 26px;
  filter: blur(1.16px);
  animation: 25s floatReverse infinite;
}
.particle:nth-child(59) {
  top: 89.1064871481%;
  left: 51.1307767945%;
  font-size: 17px;
  filter: blur(1.18px);
  animation: 34s floatReverse2 infinite;
}
.particle:nth-child(60) {
  top: 28.0532043531%;
  left: 90.5550146056%;
  font-size: 27px;
  filter: blur(1.2px);
  animation: 37s float2 infinite;
}
.particle:nth-child(61) {
  top: 14.5278450363%;
  left: 37.037037037%;
  font-size: 26px;
  filter: blur(1.22px);
  animation: 25s floatReverse infinite;
}
.particle:nth-child(62) {
  top: 75.9124087591%;
  left: 84.1487279843%;
  font-size: 22px;
  filter: blur(1.24px);
  animation: 32s float2 infinite;
}
.particle:nth-child(63) {
  top: 26.4058679707%;
  left: 58.9390962672%;
  font-size: 18px;
  filter: blur(1.26px);
  animation: 28s floatReverse2 infinite;
}
.particle:nth-child(64) {
  top: 20.5378973105%;
  left: 71.7092337917%;
  font-size: 18px;
  filter: blur(1.28px);
  animation: 32s floatReverse2 infinite;
}
.particle:nth-child(65) {
  top: 42.7703523694%;
  left: 23.4604105572%;
  font-size: 23px;
  filter: blur(1.3px);
  animation: 40s float2 infinite;
}
.particle:nth-child(66) {
  top: 62.8992628993%;
  left: 71.9921104536%;
  font-size: 14px;
  filter: blur(1.32px);
  animation: 34s floatReverse infinite;
}
.particle:nth-child(67) {
  top: 62.1359223301%;
  left: 43.9453125%;
  font-size: 24px;
  filter: blur(1.34px);
  animation: 39s floatReverse infinite;
}
.particle:nth-child(68) {
  top: 66.6666666667%;
  left: 48.2283464567%;
  font-size: 16px;
  filter: blur(1.36px);
  animation: 32s floatReverse2 infinite;
}
.particle:nth-child(69) {
  top: 18.7422934649%;
  left: 20.7715133531%;
  font-size: 11px;
  filter: blur(1.38px);
  animation: 25s floatReverse2 infinite;
}
.particle:nth-child(70) {
  top: 76.1904761905%;
  left: 61.8253189401%;
  font-size: 19px;
  filter: blur(1.4px);
  animation: 37s floatReverse infinite;
}
.particle:nth-child(71) {
  top: 64.6266829865%;
  left: 95.3785644051%;
  font-size: 17px;
  filter: blur(1.42px);
  animation: 21s float2 infinite;
}
.particle:nth-child(72) {
  top: 24.1254523522%;
  left: 49.5626822157%;
  font-size: 29px;
  filter: blur(1.44px);
  animation: 27s floatReverse2 infinite;
}
.particle:nth-child(73) {
  top: 50.9179926561%;
  left: 11.7994100295%;
  font-size: 17px;
  filter: blur(1.46px);
  animation: 35s float infinite;
}
.particle:nth-child(74) {
  top: 18.313253012%;
  left: 35.9223300971%;
  font-size: 30px;
  filter: blur(1.48px);
  animation: 22s float2 infinite;
}
.particle:nth-child(75) {
  top: 16.6666666667%;
  left: 32.4803149606%;
  font-size: 16px;
  filter: blur(1.5px);
  animation: 31s float infinite;
}
.particle:nth-child(76) {
  top: 74.3961352657%;
  left: 74.9027237354%;
  font-size: 28px;
  filter: blur(1.52px);
  animation: 35s float2 infinite;
}
.particle:nth-child(77) {
  top: 82.4242424242%;
  left: 56.5853658537%;
  font-size: 25px;
  filter: blur(1.54px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(78) {
  top: 3.8882138518%;
  left: 96.7741935484%;
  font-size: 23px;
  filter: blur(1.56px);
  animation: 37s float2 infinite;
}
.particle:nth-child(79) {
  top: 50.1228501229%;
  left: 56.2130177515%;
  font-size: 14px;
  filter: blur(1.58px);
  animation: 28s float2 infinite;
}
.particle:nth-child(80) {
  top: 65.127582017%;
  left: 2.9325513196%;
  font-size: 23px;
  filter: blur(1.6px);
  animation: 39s float2 infinite;
}

@keyframes apparition {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(180px);
  }
}
@keyframes floatReverse {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-180px);
  }
}
@keyframes float2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(28px);
  }
}
@keyframes floatReverse2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-28px);
  }
}
</style>
