<template>
  <button class="step-button">
    <div v-if="className != 'next'">
      <img class="step-btn-icon" :src="icon" />
      <span :class="className">{{ $t(text) }}</span>
    </div>
    <div v-else>
      <span class="next">{{ $t(text) }}</span>
      <img class="step-btn-icon" :src="icon" />
    </div>
  </button>
</template>

<script>
export default {
  name: "StepButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: String,
    className: String,
  },
};
</script>

<style scoped>
.step-button {
  width: 195px;
  height: 55px;
  border-radius: 32px;
  padding: 4px;
  background-color: #f4f4f4;
  border: none;
}
.step-button div {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.step-btn-icon {
  width: 14px;
  height: 14px;
}
span.previous {
  padding-left: 15px;
}
span.next {
  padding-right: 15px;
}
.step-button div:hover {
  background-color: var(--first-color);
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .step-button {
    width: 150px;
    height: 50px;
  }
  .step-button div {
    font-size: 16px;
  }
}
</style>
