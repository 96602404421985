<template>
  <div class="traction-wrapper">
    <h3>
      {{ $t(label) }}*
      <span class="error_msg" v-if="err_msg">{{ $t(err_msg) }} </span>
      <button class="tool-tip-viewer" :content="$t(`tractionTooltip`)" v-tippy>
        <Icon size="18"><InfoCircle /></Icon>
      </button>
    </h3>
    <div class="wrapper">
      <tiptap :text="getTractions" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { InfoCircle } from "@v2icons/tabler";
import { Icon } from "@v2icons/utils";

import Tiptap from "./Tiptap.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Traction",
  props: {
    label: String,
    tractions: String,
    err_msg: String,
  },
  components: {
    Tiptap,
    InfoCircle,
    Icon,
  },
  data: () => {
    return {
      toolTipString: `Time to brag now.... investors and mentors want to know what your startup has achieved so far. Traction includes milestones like number of users, revenue, product launch, expansions, funds raised etc.`,
      tractionInput: "",
    };
  },
  methods: {
    ...mapMutations(["setTractions"]),
  },
  computed: {
    ...mapGetters(["getTractions"]),
  },
};
</script>

<style scoped>
.error_msg {
  /* color: var(--error-color) !important; */
  /* background-color: #fe4c5f42; */
  color: var(--error-color);
  padding: 0px 5px;
  border-radius: 5px;
  font-size: 15px;
}
* {
  border: none;
  box-sizing: border-box;
}
.traction-wrapper {
  width: 100%;
  /* height: 100%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 15px 50px;
}
h3 {
  width: 100%;
  /* max-width: 560px; */
  text-align: left;
  opacity: 0.8;
  line-height: 40px;
  font-size: 22px;
  position: relative;
  display: flex;
  align-items: center;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wrapper {
  width: 100%;
  min-height: 120px;
  border: 1px solid rgb(224, 224, 230);
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 14px;
}
.wrapper > div:focus {
  border: 1px solid #329e4d50;
}
div.wrapper > div:first-child {
  width: 100%;
  min-height: 100px;
  padding: 10px;
}
ul,
li {
  width: 100%;
  text-align: left;
}
li {
  margin: 0px 0;
  background-color: #329e4d05;
  padding: 0px 15px;
  font-size: 15px;
  line-height: 18px;
  border-radius: 0px;
  border-left: 1px solid #329e4d50;
  border-radius: 8px;
  border-radius: 0;
  position: relative;
  list-style: inherit !important;
  padding-bottom: 15px;
}
li::before {
  position: absolute;
  content: "";
  top: 0;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #329e4d;
  /* border: 5px solid  #EE81EE10; */
  box-shadow: 0 0 0 0px #cd255310, 0 0 0 3px #329e4d50;
  /* z-index: 100; */
}
li span {
  position: relative;
  width: 100%;
  text-align: left;
  word-break: break-all;
}
/* form,
textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
} */
*:focus {
  outline: none;
}
.tool-tip-viewer {
  font-size: 14px;
  /* background-color: #329e4d80; */
  /* color: #329e4d; */
  position: relative;
  border-radius: 50%;
  font-weight: 900;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}
.tooltip {
  display: flex;

  position: absolute;
}
/* .tool-tip-viewer:hover .tooltip{
    display: flex;
} */
@media only screen and (max-width: 600px) {
  .traction-wrapper {
    width: 100%;
    /* height: 100%; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px;
  }
  .wrapper {
    width: 100%;
    min-height: 100px;
  }
}
</style>


