<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div
      class="certificate"
      v-if="!editing && educationLevel && !getProfileEditingMode"
    >
      <div class="icon">
        <img
          src="@/assets/images/profile/graduate.svg"
          alt=""
          width="14"
          height="14"
        />
      </div>
      <p class="info">
        <span class="label"
          >{{ $t("LevelOfEducation") }}
          <span v-if="educationLevel">: </span></span
        >
        <span class="text">{{ $t(levelEdu) }}</span>
      </p>
      <edit-button @edit="edit()" />
    </div>
    <div class="certificate" v-if="!editing && getProfileEditingMode">
      <div class="icon">
        <img
          src="@/assets/images/profile/graduate.svg"
          alt=""
          width="14"
          height="14"
        />
      </div>
      <p class="info">
        <span class="label"
          >{{ $t("LevelOfEducation") }} <span v-if="levelEdu">: </span>
        </span>
        <span class="infos">{{ levelEdu }}</span>
      </p>
      <edit-button v-if="showEditButtons" @edit="edit()" />
    </div>
    <div class="certificate" v-if="editing">
      <select name="certificates" v-model="levelEdu" class="profile-controls">
        <option
          v-for="(edu, index) in levelsOfEducation"
          :key="index"
          :value="edu.name"
          :selected="levelEdu === edu.name ? 'selected' : ''"
        >
          {{ edu.name }}
        </option>
      </select>
      <save-cancel-button-group @save="save" @cancel="cancel()" />
    </div>
  </div>
</template>

<script>
import EditButton from "../../buttons/EditButton";
import SaveCancelButtonGroup from "../../buttons/SaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    
    this.levelEdu = this.educationLevel;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getProfileEditingMode",
    ]),
  },
  props: {
    educationLevel: String,
  },
  components: {
    EditButton,
    SaveCancelButtonGroup,
  },
  data() {
    return {
      levelEdu: "",
      editing: false,
      levelsOfEducation: [
        {
          name: "Bachelors",
          icon: require("@/assets/registration/icons/bachelor.svg"),
        },
        {
          name: "Masters",
          icon: require("@/assets/registration/icons/master.svg"),
        },
        {
          name: "PhD",
          icon: require("@/assets/registration/icons/phd.svg"),
        },
        {
          name: "Other",
          icon: require("@/assets/registration/icons/degree.svg"),
        },
      ],
    };
  },
  methods: {
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    save() {
      if (this.levelEdu) {
        this.$store
          .dispatch("updateProfile", {
            educationLevel: this.levelEdu,
          })
          .then(() => {
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: auto !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: 1px solid var(--first-color) !important;
  border-radius: 0.3rem !important;
  outline: none !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>

<style lang="scss" scoped>
.certificate {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .icon {
    line-height: 0;
  }

  .info {
    // flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    // text-align: center;

    @include max-width(991) {
      margin-right: 10px;
    }

    .label {
      color: #666668;

      @include max-width(1125) {
        display: none;
      }

      // @include max-width(991) {
      //   display: inline;
      // }

      // @include max-width(410) {
      //   display: none;
      // }
    }

    .text {
      color: #48484a;
    }
  }
}
</style>
