import Vue from 'vue';
import VueRouter from 'vue-router';
import Error from '../views/Error.vue';
import AuthRoutes from './auth';
import ProfileRoutes from './profile';
import StartupsRoutes from './startups';
import NetworkRoutes from './network';
import NewsJobsRoutes from './newsJobs';
import HomectivityRoutes from './homeActivity';
import AdminRoutes from './admin';

import i18n from "../i18n";

var userLang = navigator.language || navigator.userLanguage;

var defaultLang = userLang.substring(0, 2);

i18n.locale = defaultLang;

var redirect = "/" + defaultLang + "/" + `${i18n.t("routes.home")}`;
//var redirect = "/" + defaultLang + "/login";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: redirect
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      ...HomectivityRoutes,
      ...AuthRoutes,
      ...AdminRoutes,
      ...ProfileRoutes,
      ...StartupsRoutes,
      ...NetworkRoutes,
      ...NewsJobsRoutes,
      {
        path: "*",
        component: Error,
        meta: {
          layout: "DefaultLayout"
        }
      }
    ]

  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, SavedPosition) {
  //   if (to.hash) {
  //     const el = window.location.href.split("#")[1];
  //     if (el.length) {
  //       document.getElementById(el).scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else if (SavedPosition) {
  //     return SavedPosition;
  //   } else {
  //     document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  //   }
  // },

  scrollBehavior(to, from, savedPosition) {
    console.log('to.hash' + to.hash)
    if (to.hash) {

      return {
        selector: to.hash
      };
    }
  },

});

export default router;