<template>
  <div class="Dashbboard" :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }">
    <div class="layout">
      <main class="main-content">
        <WritePostCard @showTheNewPostOnTheFeed="showTheNewPostOnTheFeed" />
        <div class="news-feed" v-if="!loading">
          <ul>
            <Observer>
               <ScrollPostcard :feed="feedData" :posts="feedData" key = "feedData.id"/>
            </Observer>
          </ul>
        </div>
      </main>
      <DashboardAside :jobs="getJobs" :news="getNews" :talents="relevantTalents" />
    </div>
    <Loader v-if="loading" />
    <!-- <ReferalModal :time-out-show="450" v-if="showRef" /> -->
  </div>
</template>


<script>
import i18n from "../i18n";
import axios from 'axios'
import _ from "lodash";
import { mapGetters, mapActions, mapMutations } from "vuex";

import ReferalModal from "./../components/Modal/ReferalModal";

const WritePostCard = () =>
  import("./../components/WritePost/WritePostCard.vue");
const DashboardAside = () => import("./../components/Sidebar/Aside.vue");
const ScrollPostcard = () => import("./../components/Postcard/ScrollPostCard.vue");
const Observer = () => import("./../components/Observers/Observer.vue");
const SkeletonLoadersDashboard = () =>
  import("../components/SkeletonLoaders/SkeletonLoadersDashboard.vue");
import { io } from "socket.io-client";
import APIURLS from "../constant";

export default {
  components: {
    WritePostCard,
    DashboardAside,
    ScrollPostcard,
    Observer,
  },
  metaInfo() {
    return {
      title: `Bantaba | ${this.$i18n.t("navBar.home")}`,
    };
  },
  async mounted() {
   

    this.showRef = await this.loadShowReferral({ user_id: this.authUser.id });
    this.username = this.authUser.username;
    // const lang = this.getUserLang;
    
    
    
    // await this.loadInitialPost(this.feedPageNo, 10);


    // this.feedData = await this.loadTheFeed(361)

    this.feedId = await this.$route.params.post_uuid;
    this.feedId = this.feedId.split("=")[1].toString();
     let that  = this;
     await axios
       .get(`${APIURLS.FEEDS}get-feed-by-uuid/${that.feedId}`)
       .then(response => ( that.feedData = response.data.feed[0]))
    // console.log("feed after comment ", this.feedData)
       this.loading = false;

     if (this.authUser.type === 1) {
      const lang = this.getUserLang;
      await this.loadNews(4);
      await this.loadPaginatedJobs(4);

      if (!JSON.parse(localStorage.getItem("featuredStartups"))) {
        await this.loadFeaturedStarups({ lang, pagination: 20 });
        this.completeStartups = this.getFeaturedStartups;
        //save to locale storage
        localStorage.setItem(
          "featuredStartups",
          JSON.stringify(this.completeStartups)
        );
      } else {
        this.completeStartups = JSON.parse(
          localStorage.getItem("featuredStartups")
        );
      }
      this.number = Math.floor(Math.random() * this.completeStartups.length);
    } else if (this.authUser.type === 2) {
      const startup_id = this.authUser.startup ? this.authUser.startup.id : "";
      if (!JSON.parse(localStorage.getItem("relevantTalents"))) {
        await this.loadRelevantTalents({ startup_id });
        this.relevantTalents = this.getRelevantTalents;
        //save to locale storage
        localStorage.setItem(
          "relevantTalents",
          JSON.stringify(this.relevantTalents)
        );
      } else {
        this.relevantTalents = JSON.parse(
          localStorage.getItem("relevantTalents")
        );
      }
    }
      // this.isTimeOut = true; 
  },
  sockets: {
    isNewPost(data) {
      if (data.id === this.authUser.id) {
        this.canShowNotifcation = false;
        // this.setIsNewPost(false);
      }
      if (data.status === true) {
        this.batchPosts = [data.data, ...this.batchPosts];
      }
    },
    deleteAFeed(data) {
      this.batchPosts = this.batchPosts.filter(
        (post) => post.id != data.feedId
      );
    },
  },
  async created() {
    //set the user lang to the store
    let lang;
    let preferedLanguage = "";
    if (
      this.authUser.profile &&
      this.authUser.profile.preferedLanguage !== "null"
    ) {
      preferedLanguage = this.authUser.profile.preferedLanguage;
    }

    //check if the lang as been selected from the login page
    if (preferedLanguage != this.getUserLang) {
      //set the user preferedLanguage.
      lang = this.getUserLang;
      if (lang != "fr" && lang != "en") {
        lang = "en";
      }
      await this.changeUserPreferedLang(lang);
    } else {
      if (preferedLanguage && preferedLanguage !== "null") {
        lang = this.authUser.profile.preferedLanguage;
      } else {
        let locale = this.$i18n.locale;
        if (locale != "en" || locale != "fr") {
          locale = "en";
        }
        lang = locale;
      }
    }
    this.langUrl = lang;

    this.$store.commit("setUserLang", lang);
    localStorage.setItem("userLang", lang);
    this.$i18n.locale = lang;

    var originalName = this.$route.name;
    var name = originalName.substring(0, originalName.length - 2);
    if (lang === "En") name += "Fr";
    else name += "En";
    // this.$router.push({ name: name, params: { lang } }).catch(err => {});
    if (this.$route.path != originalName) {
      // this.$router.push("/");
      this.$router.push({ name: name, params: { lang } }).catch(() => {});
    }
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getUserProfile",
      "getStartup",
      "getConnected",
      "getConnectionsCounts",
      "getUsersSupportStartups",
      "getNews",
      "getJobs",
      "getFeeds",
      "getStartups",
      "getRelevantTalents",
      "getFeaturedStartups",
      "getUserBrowsingPosts",
      "getUserBrowsingBantabaDigest",
      "getIsNewPost",
      "getUserLang",
    ]),
  },
  
  methods: {
    async scrollMePost() {
      let routerId = this.$route.params.post_uuid;
     if(routerId){
       routerId.split("=")[1].toString();
      this.scrollToPost = document.getElementById(routerId);
      while(this.scrollToPost === null && this.NscrollPost  >= 0){
         await this.fetchPageByOneData();
         this.NscrollPost--
        //  if(this.NscrollPost === 0){
        //    alert("scroll to post")
        //  }
      }
     
    }else{
      this.isTimeOut = true; 
    }

  //   window.setInterval(() => {
  //    if(this.scrollToPost !== null){
  //        this.scrollMeTo();
  //     }
  // }, 30000)
        setTimeout(() =>
     {
      if(this.scrollToPost !== null){
         this.scrollMeTo();
      }
       this.isTimeOut = true;
     
   }, 1000);

//    document.onreadystatechange = () => {
//   if (document.readyState === "complete") {
//     alert("cool")
//      this.isTimeOut = true;
//     if(this.scrollToPost){
//         this.scrollMeTo();
//       }
//     console.log('Page completed with image and files!')
//     // fetch to next page or some code
//   }
// }

    },
    async scrollMeTo() {
      let id = this.$route.params.post_uuid;
      if(id !== null || id !== undefined){
        id = id.split("=")[1].toString();
        this.scrollToPost = document.getElementById(id);
        const yOffset = -185; 
        this.scrollToPost = document.getElementById(id);
        const y = this.scrollToPost.getBoundingClientRect().top + window.pageYOffset + yOffset;
        // const y = 150;
        window.scrollTo({top: y, behavior: 'smooth'});
      }else{
        alert("element does not exist")
      }
  },
  async showTheNewPostOnTheFeed(data) {},
    
    async loadTheFeed(id) {
      let data = [];
      // console.log("load the feed", `${APIURLS.FEEDS}get-feed-by-id/${id}`)
      await axios.get(
        `${APIURLS.FEEDS}get-feed-by-id/${id}`
      ).then((response) => {
        // console.log("response", response.data.feed)
       data = response.data.feeds;
      });
      return data;
    },
    ...mapActions([
      "loadProfile",
      "loadStartup",
      "loadConnections",
      "loadConnCounts",
      "loadUsersSupportStartups",
      "loadStartups",
      "loadNews",
      "loadPaginatedJobs",
      "loadFeeds",
      "loadJobs",
      "createFeed",
      "deleteFeed",
      "loadRelevantTalents",
      "loadFeaturedStarups",
      "changeUserPreferedLang",
      "loadShowReferral",
    ]),
  },
  data() {
    return {
      // feedData: { "id": 361, "uuid": "Tk3fwvoWh", "user_id": 92, "post": "why did the first one not work\n", "lang": "en", "edited": 0, "hide": 0, "created_at": "2022-06-18T14:34:11.000Z", "updated_at": "2022-06-18T14:34:11.000Z", "user": { "id": 92, "name": "Darboe", "firstname": "Lamin K.", "username": "laminkdarboe", "avatar": "https://res.cloudinary.com/bantaba/image/upload/v1642577142/Users/Avatars/kimdevqrotfu15ktpa2t.png", "type": 1, "startup": null }, "likes": [], "images": [], "comments": [ { "id": 68, "user_id": 93, "feed_id": 361, "name": "Fabrice Ouedraogo", "text": "wowowo", "created_at": "2022-06-28T13:40:03.000Z", "updated_at": "2022-06-28T13:40:03.000Z", "user": { "id": 93, "name": "Ouedraogo", "firstname": "Fabrice", "username": "fabioued", "avatar": "https://res.cloudinary.com/bantaba/image/upload/v1654872142/Users/Avatars/et1vsmd7hwnnvaci3hji.jpg", "type": 1 } }, { "id": 67, "user_id": 93, "feed_id": 361, "name": "Fabrice Ouedraogo", "text": "comment ingngng", "created_at": "2022-06-28T13:39:48.000Z", "updated_at": "2022-06-28T13:39:48.000Z", "user": { "id": 93, "name": "Ouedraogo", "firstname": "Fabrice", "username": "fabioued", "avatar": "https://res.cloudinary.com/bantaba/image/upload/v1654872142/Users/Avatars/et1vsmd7hwnnvaci3hji.jpg", "type": 1 } }, { "id": 66, "user_id": 93, "feed_id": 361, "name": "Fabrice Ouedraogo", "text": "cool testunvfnvnvc", "created_at": "2022-06-28T13:39:42.000Z", "updated_at": "2022-06-28T13:39:42.000Z", "user": { "id": 93, "name": "Ouedraogo", "firstname": "Fabrice", "username": "fabioued", "avatar": "https://res.cloudinary.com/bantaba/image/upload/v1654872142/Users/Avatars/et1vsmd7hwnnvaci3hji.jpg", "type": 1 } } ] },
      feedData: {},
      feedId: null,
      NscrollPost:30,
      scrollToPost: null,
      isTimeOut: false,
      feedPageNo: 0,
      batchPosts: [],
      perSessionPosts: [],
      langUrl: "",
      canShowNotifcation: true,
      number: 0,
      loading: true,
      pagination: 10,
      DummyPosts: ["Dc", "Sc", "P"],
      userFeed: [],
      dummyLoadN: 0,
      connections: {},
      jobs: {},
      users: {},
      news: {},
      post: [],
      AllPosts: [],
      mergedFeeds: [],
      post3: [
        {
          posterName: "Kemo Jallow",
        },
      ],
      counts: 0,
      typing: false,
      creatingPost: false,
      feeds: {},
      shareUpdate: false,
      startups: {},
      completeStartups: {},
      relevantTalents: {},
      sockets: {},
      showRef: false,
    };
  },
  name: "ScrollToPostDashboard",
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.Dashbboard {
  /* zoom: 90%; */
  /* height: 110vh; */
  width: 100vw;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 200px;
}
.showNotVerifiedNotfication {
  padding-top: 70px;
}
.layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100%;
  max-width: 78%;
  position: relative;
  padding-top: 10px;
  /* transform: scale(0.85) */
}

.main-content {
  height: 100%;
  width: 60%;
  padding-top: 0px;
  overflow-y: auto;
  padding-bottom: 100px;
  position: relative;
}
.newPostNoty {
  position: fixed;
  top: 0;
  z-index: 100;
  top: 100px;
  /* background: black; */
  background-color: #38a64ecf;
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  color: white;
  left: calc(40% - 12px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 7px rgb(0 0 0 / 5%);
}
#Layer_1 {
  fill: white;
  width: 28px;
  height: 22px;
}
.circle {
  background: #ffffff2b;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noty-title {
  padding-left: 5px;
}
aside {
  height: 99%;
  width: 30%;
  margin-left: 3rem;
  overflow-y: auto;
}

/* .card{
  padding: 16px;
  border-radius: 18px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.01), 
  0 3px 6px rgba(0,0,0,0.015);
} */
.posts-ul {
  background-color: green;
}
ol li, ul li {
    list-style: none !important; 
    /* padding: 5px 0; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Dashbboard {
    padding-top: 10px;
  }
  .layout {
    max-width: 100%;
  }
  aside {
    display: none;
  }
  .main-content {
    width: 100%;
    padding-top: 50px;
  }
  .showNotVerifiedNotfication {
    padding-top: 70px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .layout {
    max-width: 100%;
  }
  .main-content {
    /* width: 60%; */
    width: calc(70% - 2rem);
    /* padding-top: 10px; */
    padding-top: 33px;
    overflow-y: auto;
  }
  aside {
    width: 40%;
    margin-left: 0.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .layout {
    max-width: 90%;
  }
  aside {
    width: 30%;
    margin-left: 1.5rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .layout {
    /* max-width: 73%; */
    /* max-width: min(78%, 1140px); */
    max-width: 990px !important;
  }
  aside {
    margin-left: 2rem;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 1500px) {
  .layout {
    max-width: 73%;
    max-width: min(78%, 1140px);
  }
  aside {
    margin-left: 2.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .Dashbboard {
    zoom: 100%;
  }
  .layout {
    max-width: 90%;
    padding-top: 0;
  }
  aside {
    display: none;
  }
  .main-content {
    width: 100%;
    padding-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .layout {
    max-width: 100%;
    padding-top: 0;
  }
}
</style>



// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
  
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {}ktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {}