<template>
  <div class="#">
    <div class="box shadow-sm border rounded bg-white mb-3">
      <div class="widget widget-user-about">
        <div class="widget-heading">
          <h3 class="widget-title">{{ $t('skills') }}</h3>
        </div>
        <div class="box-body" v-if="skills.length">
          <div
            class="d-flex align-items-center osahan-post-header py-2 m-1 border-bottom people-list"
            v-for="(skill, index) in skills"
            :key="index"
          >
            <div class="font-weight-bold">
              <div class="text-truncate">
                {{ skill.name }}
                <span class="badge badge-success text-red ml-1"
                  >{{ skill.years }} {{ $t('years') }}</span
                >
              </div>
              <div class="small text-muted">
                <span class="text-secondary">{{ $t(skill.level) }}</span>
              </div>
            </div>
          </div>
        </div>
        <NoData
          v-else
          text="noSkills"
          buttonText="addSkills"
          :buttonLink="`/${$i18n.locale}/${this.$i18n.t('routes.cursus')}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Skills',
  props: {
    skills: {},
  },
};
</script>
