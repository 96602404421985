<template>
  <div v-if="show" :class="variantClass" class="alert">
    <div class="alert-text">
      <slot :hide="hide" />
    </div>
    <button v-if="showCloseButton" class="close" type="button" @click="hide">
      <span>×</span>
    </button>
  </div>
</template>

<script>
let button;
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Alert",
  data: () => ({
    resolve: null,
    reject: null,
  }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      default: true,
      type: Boolean,
    },
    variant: {
      default: "danger",
    },
  },
  computed: {
    variantClass() {
      return `alert-${this.variant}`;
    },
  },
  methods: {
    hide() {
      this.$emit("hide", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  max-width: 608px;
  width: 100%;
  border-radius: 10px;
  position: relative;

  &-text {
    padding: 10px;
    font: normal normal normal 20px/24px Helvetica Neue;
    font-weight: bold;
  }

  button.close {
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    line-height: 0;

    &:focus {
      outline: none;
    }
  }

  $this: &;

  &#{$this}-danger {
    background: #f5d6d6;

    .text {
      color: #7c0505;
    }

    button.close {
      text-shadow: none;
      background: #e69191;
      color: #884242;
    }
  }

  &#{$this}-info {
    background: #d6eaf5;

    .text {
      color: #006490;
    }

    button.close {
      //TODO: colors
    }
  }
}

@media only screen and (max-width: 600px) {
  .alert {
    position: relative;

    &-text {
      padding: 10px;
      font-size: 14px;
    }
  }
}
</style>
