<template>
  <div class="progress-container">
    <div class="progress">
      <div
        class="circle done"
        @click="commitStep1"
        :class="{ active: getIsStepOne, done: getIsStepOneDone }"
      >
        <span class="label">1</span>
        <span class="title">Personal</span>
      </div>
      <span class="bar done"></span>
      <div class="circle done" @click="commitStep2">
        <span class="label">2</span>
        <span class="title">Adress</span>
      </div>
      <span class="bar half"></span>
      <div class="circle active" @click="commitStep3">
        <span class="label">3</span>
        <span class="title">Expertise</span>
      </div>
      <span class="bar"></span>
      <div class="circle" @click="commitStep4">
        <span class="label">4</span>
        <span class="title">Interest</span>
      </div>
      <span class="bar"></span>
      <div class="circle" @click="commitStep5">
        <span class="label">5</span>
        <span class="title">Finish</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "RegistrationProgress",
  computed: {
    ...mapGetters([
      "getIsStepOne",
      "getIsStepTwo",
      "getIsStepThree",
      "getIsStepFour",
      "getIsStepFive",

      "getIsStepOneDone",
      "getIsStepTwoDone",
      "getIsStepThreeDone",
      "getIsStepFourDone",
      "getIsStepFiveDone",
    ]),
    ...mapState([]),
  },
  methods: {
    commitStep1() {
      this.$store.commit("setStep", 1);
    },
    commitStep2() {
      this.$store.commit("setStep", 2);
    },
    commitStep3() {
      this.$store.commit("setStep", 3);
    },
    commitStep4() {
      this.$store.commit("setStep", 4);
    },
    commitStep5() {
      this.$store.commit("setStep", 5);
    },

    ...mapMutations(["gotoNext", "goPrevious", "setStep"]),
  },
};
</script>

<style scoped>
.progress-container {
  position: absolute;
  left: 1vw;
  bottom: 80px;
  width: 35vw;
  height: 100px;
  z-index: 4000;
  display: flex;
  align-items: center;
  justify-content: center;
}
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
}

/* Form Progress */
.progress {
  width: 1000px;
  height: 100px;
  border-radius: 15px;
  background-color: #f4f4f4b0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  text-align: center;
}
.progress .circle,
.progress .bar {
  display: inline-block;
  background: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
}
.progress .bar {
  position: relative;
  width: 80px;
  height: 6px;
  top: 0px;
  margin-left: -5px;
  margin-right: -5px;
  border-left: none;
  border-right: none;
  border-radius: 0;
}
.progress .circle .label {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 32px;
  margin-top: 3px;
  color: #b5b5ba;
  font-size: 17px;
}
.progress .circle .title {
  color: #b5b5ba;
  font-size: 13px;
  line-height: 30px;
  margin-left: -5px;
}

/* Done / Active */
.progress .bar.done,
.progress .circle.done {
  background: #349e4d;
}
.progress .bar.active {
  background: linear-gradient(to right, #eee 40%, #fff 60%);
}
.progress .circle.done .label {
  color: #fff;
  background: #8bc435;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
.progress .circle.done .title {
  color: #444;
}
.progress .circle.active .label {
  color: #fff;
  background: #0c95be;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
.progress .circle.active .title {
  color: #0c95be;
}
@media only screen and (max-width: 600px) {
  .progress-container {
    position: absolute;
    right: 0vw;
    bottom: 0px;
    width: 100vw;
    height: 100px;
    z-index: 4000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>