<template>
    <div>
        <div class="form-wrapper" v-if="!success_mssg">
            <div class=" text-left mt-4">
                <label class="label-bold remove_margin_bottom">{{GetModalContentCategory}}</label>
                <input type="file" class=" input-control inputfile" id="business-registration"
                    @change="fileChangerA($event)" accept="application/pdf">
                <label for="business-registration"
                    class=" input-control d-flex justify-content-between align-items-center" id="inputFileA">
                    <div>
                        {{docu_upload_a}}
                    </div>

                    <div>
                        <svg v-if="show_close_btn_a" class="cancel-upload-btn" viewBox="0 0 8 7" fill="none"
                            xmlns="http://www.w3.org/2000/svg" @click="removeUploadA">
                            <path
                                d="M7.2869 5.78068C7.36382 5.84304 7.42622 5.91962 7.47019 6.00564C7.51417 6.09166 7.53878 6.18527 7.54249 6.28061C7.5462 6.37596 7.52892 6.471 7.49175 6.5598C7.45457 6.6486 7.39829 6.72925 7.32642 6.79671C7.25455 6.86418 7.16864 6.91701 7.07404 6.95191C6.97944 6.9868 6.87819 7.00302 6.77662 6.99954C6.67505 6.99606 6.57533 6.97296 6.4837 6.93167C6.39207 6.89039 6.31048 6.83182 6.24404 6.75962L3.81071 4.47698L1.37517 6.75962C1.3081 6.82965 1.22655 6.88614 1.13548 6.92564C1.04442 6.96515 0.945737 6.98685 0.845452 6.98941C0.745167 6.99197 0.645375 6.97535 0.552151 6.94056C0.458927 6.90577 0.37422 6.85353 0.303188 6.78703C0.232156 6.72053 0.176284 6.64115 0.138974 6.55373C0.101663 6.46631 0.0836942 6.37268 0.0861611 6.27854C0.088628 6.18439 0.111479 6.0917 0.153323 6.00611C0.195167 5.92052 0.255129 5.84382 0.329558 5.78068L2.76234 3.49648L0.33011 1.21022C0.260665 1.14593 0.205455 1.06939 0.167661 0.984988C0.129866 0.900589 0.110233 0.810004 0.109893 0.718451C0.109552 0.626898 0.128511 0.536186 0.165677 0.451541C0.202843 0.366896 0.257483 0.289989 0.326447 0.225251C0.395412 0.160513 0.47734 0.109223 0.567512 0.0743346C0.657684 0.0394464 0.754318 0.0216496 0.851848 0.0219692C0.949379 0.0222887 1.04588 0.0407183 1.13579 0.0761966C1.2257 0.111675 1.30724 0.163501 1.37573 0.228689L3.81071 2.51236L6.24404 0.228689C6.31131 0.158822 6.39302 0.102535 6.4842 0.063256C6.57538 0.0239767 6.67412 0.00252561 6.77441 0.000209883C6.8747 -0.00210584 6.97445 0.0147619 7.06757 0.0497857C7.1607 0.0848095 7.24526 0.137257 7.3161 0.203936C7.38695 0.270614 7.4426 0.350129 7.47966 0.437639C7.51673 0.52515 7.53443 0.618825 7.5317 0.712964C7.52897 0.807102 7.50585 0.899734 7.46377 0.98522C7.42168 1.07071 7.36151 1.14726 7.2869 1.21022L4.85522 3.49648L7.2869 5.78068Z"
                                fill="#333333" />
                        </svg>
                    </div>
                </label>

                <p class="danger" v-if="uploadAErr === true">{{$t( filesizetext )}}</p>
            </div>

            <div class="margin_top d-flex justify-content-between pl-3 pr-3">
                <div>
                    <button class="button" :class="{ disable: isDisable }" @click="submitForm">{{ $t(text3) }} <i
                            class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i></button>
                </div>
                <div>
                    <button class="button button-color" @click="closeMyModal">{{ $t(text4) }}</button>
                </div>
            </div>
        </div>

        <div class="success_modal" v-else>
            <h3 class="text-left"><b>{{ $t(text5) }}</b></h3><br />
            <p>
                {{ $t(text6) }}
                <br /><br />
                {{ $t(text7) }}
            </p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        GetModalContentCategory: String,
        GetModalMainContent: Object,
        text: {
            type: String,
            default: "founderFullName",
        },

        text1: {
            type: String,
            default: "linkedInProfileLink",
        },

        text2: {
            type: String,
            default: "enterAValidUrl",
        },

        text3: {
            type: String,
            default: "add",
        },

        text4: {
            type: String,
            default: "cancel",
        },

        text5: {
            type: String,
            default: "founderProfileText1",
        },

        text6: {
            type: String,
            default: "founderProfileText2",
        },

        text7: {
            type: String,
            default: "founderProfileText13",
        },

        filesizetext: {
            type: String,
            default: "filesizeErorr",
        },

        textpaste: {
            type: String,
            default: "pasteURL",
        },
    },

    data() {
        return {
            docu_upload_a: this.$i18n.locale === "en" ? "Click or drag and drop to upload a file" : "Cliquez ou glissez-déposez pour télécharger un fichier",
            show_close_btn_a: false,
            upload_data: {
                file: null,
            },
            store_data: {
                id: null,
                value: "",
                status: 1,
            },
            cloudName: "bantaba",
            isDisable: true,
            success_mssg: false,
            preset: "Startups-logos",
            uploadAErr: false,
            loading: false
        }
    },

    created() {
        this.editUploadData();
    },

    methods: {
        ...mapActions(["updateVault"]),

        editUploadData() {
            // console.log("jghjf", this.GetModalMainContent)
            this.store_data.id = this.GetModalMainContent.id
        },

        fileChangerA(e) {
            if (e.target.files.length > 0) {
                if (e.target.files[0].size > 10000000) {
                    this.uploadAErr = true
                    document.getElementById("inputFileA").style.border = "1px solid red"
                } else {
                    this.docu_upload_a = e.target.files[0].name
                    this.upload_data.file = e.target.files[0]
                    this.show_close_btn_a = true
                    this.checkdisable()
                }
            }
        },

        removeUploadA() {
            this.docu_upload_a = this.$i18n.locale === "en" ? "Click or drag and drop to upload a file" : "Cliquez ou glissez-déposez pour télécharger un fichier"
            this.upload_data.file = ""
            this.show_close_btn_a = false
        },

        checkdisable() {
            if (this.upload_data.file != null) {
                this.isDisable = false
            } else {
                this.isDisable = true
            }
        },

        closeMyModal() {
            this.$store.commit("set_show_modal", false)
        },

        clearForm() {
            this.upload_data.file = null
        },

        submitForm() {
            this.loading = true

            let formData = new FormData();
            formData.append("upload_preset", this.preset);
            formData.append("file", this.upload_data.file);

            let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;

            let requestObj = {
                url: cloudinaryUploadURL,
                method: "POST",
                data: formData,
                header: {
                    Authorization: "Bearer" + "294916165529342"
                },
                onUploadProgress: function (progressEvent) {
                    this.progress = Math.round(
                        (progressEvent.loaded * 100.0) / progressEvent.total
                    );
                }.bind(this),
            };
            axios(requestObj)
                .then((response) => {
                    this.store_data.value = response.data.secure_url;
                    this.updateVault(this.store_data)
                        .then(async () => {
                            this.clearForm();
                        })
                    this.loading = false
                    this.closeMyModal()
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error);
                });

        }
    },
}
</script>

<style scoped>
.form-wrapper,
.success_modal {
    padding: 0 2em 2em 2em
}

.label-bold {
    font-weight: bold;
}

.input-control {
    width: 100%;
    border: 1px solid green;
    border-radius: .2em;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputText {
    padding: .5em;
    width: 100%;
    background-color: #fff;
    display: inline-block;
}

.inputfile+label {
    /* font-size: 1.25em;
        font-weight: 700; */
    padding: .5em;
    background-color: #fff;
    display: inline-block;
}

.cancel-upload-btn {
    width: 10px;
    height: 9px;
}

.margin_top {
    margin-top: 1.3em;
}

.remove_margin_bottom {
    margin-bottom: -2em;
}

.button {
    color: #131313;
    background: #B1E0C2;
    border: none;
    padding: .5em 2em .5em 2em;
    border-radius: 7px;
    width: 132px;
}

.button-color {
    color: #888;
    background: #fff;
    border: 1px solid #B1E0C2;
}

.disable {
    opacity: 0.5;
}

/* .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: red;
    } */
</style>