<template>
  <header class="view-header">
    <div class="container-lg">
      <h2 class="view-title">{{ post.title }}</h2>
      <div class="vuew-author tab-display-only">
        <div class="loco-time-box">
          <div class="ltb-location-box">
            <svg
              class="tlb-icon"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 321.883 321.883"
              style="enable-background: new 0 0 321.883 321.883"
              xml:space="preserve"
            >
              <path
                d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882 C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801 c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
              />
            </svg>
            <span class="tlb-location">
              {{ fortmatCountry(post.country) }}</span
            >
          </div>
          <div class="ltb-days-box">
            <svg
              class="tlb-icon"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 395 395"
              style="enable-background: new 0 0 395 395"
              xml:space="preserve"
            >
              <g>
                <polygon
                  points="278.333,52.5 278.333,12.5 248.333,12.5 248.333,52.5 146.667,52.5 146.667,12.5 116.667,12.5 116.667,52.5 0,52.5 0,117.5 395,117.5 395,52.5"
                />
                <path
                  d="M0,382.5h395v-235H0V382.5z M262.5,195h50v50h-50V195z M262.5,285h50v50h-50V285z M172.5,195h50v50h-50V195z M172.5,285h50 v50h-50V285z M82.5,195h50v50h-50V195z M82.5,285h50v50h-50V285z"
                />
              </g>
            </svg>
            <span class="tlb-days"
              ><timeago
                :datetime="post.publishedDate"
                :locale="$i18n.locale"
              ></timeago
            ></span>
          </div>
        </div>
      </div>

      <div class="view-author round-lg">
        <div class="author-box tab-display-none">
          <img
            class="ab-author-image"
            src="../../assets/images/newsIcon.png"
            alt="User"
          />
          <div class="ab-bio">
            <h3 class="ab-name">
              Source: {{ post.sourceName }}
              <a :href="post.source" target="_blank">
                <svg
                  class="tlb-icon link"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                >
                  <path
                    id="Icon_awesome-link"
                    data-name="Icon awesome-link"
                    d="M22.965,13.035a10.682,10.682,0,0,1,.025,15.088l-.025.026L18.24,32.875A10.687,10.687,0,0,1,3.126,17.76l2.609-2.609a1.124,1.124,0,0,1,1.919.746A12.956,12.956,0,0,0,8.335,19.6a1.132,1.132,0,0,1-.266,1.168l-.92.92a5.063,5.063,0,1,0,7.114,7.2l4.725-4.724a5.061,5.061,0,0,0,0-7.16,5.267,5.267,0,0,0-.727-.6,1.128,1.128,0,0,1-.488-.886,2.8,2.8,0,0,1,.823-2.1l1.48-1.48a1.13,1.13,0,0,1,1.447-.122,10.721,10.721,0,0,1,1.443,1.209Zm9.909-9.91a10.7,10.7,0,0,0-15.114,0L13.035,7.85l-.025.026a10.69,10.69,0,0,0,1.468,16.3,1.13,1.13,0,0,0,1.447-.122l1.48-1.48a2.8,2.8,0,0,0,.823-2.1,1.128,1.128,0,0,0-.488-.886,5.267,5.267,0,0,1-.727-.6,5.061,5.061,0,0,1,0-7.16L21.738,7.1a5.063,5.063,0,1,1,7.114,7.2l-.92.92a1.132,1.132,0,0,0-.266,1.168,12.956,12.956,0,0,1,.681,3.707,1.124,1.124,0,0,0,1.919.746l2.609-2.609a10.7,10.7,0,0,0,0-15.114Z"
                    transform="translate(0 0)"
                  />
                </svg>
              </a>
            </h3>
            <span class="ab-post-date"
              ><timeago
                :datetime="post.publishedDate"
                :locale="$i18n.locale"
              ></timeago
            ></span>
            <span class="ab-read-time">
              | {{ readingTime(post.fullText) }} min read</span
            >
          </div>
        </div>
        <!-- <ShareSocials /> -->
        <div class="loco-time-box tab-display-none">
          <div class="ltb-location-box">
            <svg
              class="tlb-icon"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 321.883 321.883"
              style="enable-background: new 0 0 321.883 321.883"
              xml:space="preserve"
            >
              <path
                d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882 C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801 c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
              />
            </svg>
            <span class="tlb-location">{{ fortmatCountry(post.country) }}</span>
          </div>
          <div class="ltb-days-box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.844"
              height="22.34"
              viewBox="0 0 23.844 22.34"
              class="tlb-icon"
            >
              <g id="Group_36" data-name="Group 36" opacity="0.8">
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M565.165,1132c0,.476,0,.935,0,1.394a.4.4,0,0,0,.453.454h.981a.388.388,0,0,0,.441-.438q0-.629,0-1.259V1132h4.827v.137c0,.424,0,.848,0,1.272a.39.39,0,0,0,.43.438q.51,0,1.02,0a.385.385,0,0,0,.425-.428c0-.468,0-.935,0-1.415h4.827v.136c0,.428,0,.857,0,1.285a.382.382,0,0,0,.418.422q.523,0,1.047,0a.383.383,0,0,0,.411-.417c0-.472,0-.944,0-1.428.045,0,.082-.009.12-.009.323,0,.645,0,.967,0a1.62,1.62,0,0,1,1.6,1.542c0,.075,0,.15,0,.225q0,3.206,0,6.413a.293.293,0,0,0,.136.27,6.612,6.612,0,0,1,2.842,5.8,6.749,6.749,0,0,1-13.027,2.18.219.219,0,0,0-.248-.161q-4.445.005-8.89,0a1.63,1.63,0,0,1-1.633-1.267,1.561,1.561,0,0,1-.04-.354q0-6.519,0-13.037a1.62,1.62,0,0,1,1.6-1.612c.4,0,.8,0,1.193,0C565.09,1131.991,565.12,1131.995,565.165,1132Zm14.183,19.493a5.54,5.54,0,1,0-5.517-5.345A5.547,5.547,0,0,0,579.347,1151.491Zm2.049-12.014c0-.012,0-.034,0-.056q0-1.106,0-2.213a.919.919,0,0,0-.01-.119,1.072,1.072,0,0,0-1.12-.9q-7.572.011-15.144,0a1.048,1.048,0,0,0-1.124,1.126q0,1.458,0,2.915,0,2.623,0,5.246a1.046,1.046,0,0,0,1.1,1.107h7.513v-1.022c-.243,0-.472,0-.7,0a.414.414,0,0,1-.426-.429c0-.168,0-.336,0-.5a.427.427,0,0,1,.467-.471c.265,0,.53,0,.795,0a.1.1,0,0,0,.12-.089c.065-.211.141-.418.212-.626a.5.5,0,0,0,.014-.078c-.395,0-.779,0-1.163,0a.416.416,0,0,1-.444-.449c0-.15,0-.3,0-.45,0-.345.157-.5.5-.5.437,0,.874,0,1.312,0a.627.627,0,0,1,.488.115c.265-.3.517-.6.776-.888.1-.108.242-.189.123-.367a.154.154,0,0,1-.008-.078q0-.252,0-.5a.419.419,0,0,1,.464-.469c.442,0,.883,0,1.325,0a.78.78,0,0,0,.28-.058,6.7,6.7,0,0,1,3.464-.464C580.61,1139.306,581,1139.4,581.4,1139.477Z"
                  transform="translate(-562.27 -1130.365)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M612.167,1125.589c0,.384,0,.769,0,1.153a.387.387,0,0,1-.428.426q-.205,0-.411,0a.387.387,0,0,1-.423-.431c0-.6,0-1.2,0-1.8,0-.167,0-.336,0-.5a.383.383,0,0,1,.416-.424c.15,0,.3,0,.451,0a.375.375,0,0,1,.4.4C612.169,1124.8,612.167,1125.2,612.167,1125.589Z"
                  transform="translate(-601.01 -1124.004)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M579.225,1125.586c0,.384,0,.768,0,1.153a.385.385,0,0,1-.428.425c-.141,0-.283,0-.424,0a.374.374,0,0,1-.406-.392q-.007-1.192,0-2.385a.366.366,0,0,1,.387-.383q.245-.008.49,0a.371.371,0,0,1,.38.39C579.227,1124.791,579.225,1125.189,579.225,1125.586Z"
                  transform="translate(-574.771 -1124.001)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M645.112,1125.591c0,.379,0,.758,0,1.138a.389.389,0,0,1-.432.434c-.145,0-.291,0-.436,0a.376.376,0,0,1-.394-.391q-.006-1.19,0-2.381a.372.372,0,0,1,.385-.386c.163-.006.326,0,.489,0a.374.374,0,0,1,.388.4C645.114,1124.8,645.112,1125.194,645.112,1125.591Z"
                  transform="translate(-627.253 -1124)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M642.794,1196.406a1.165,1.165,0,0,1-2.329.01,1.165,1.165,0,1,1,2.329-.01Z"
                  transform="translate(-624.559 -1180.75)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M631.172,1188.093a1.3,1.3,0,0,1-.27-.146q-.788-.707-1.565-1.425a.411.411,0,0,1-.03-.611.406.406,0,0,1,.6-.024q.789.715,1.573,1.436a.388.388,0,0,1,.112.456A.517.517,0,0,1,631.172,1188.093Z"
                  transform="translate(-615.574 -1173.202)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M651.339,1186.822a.686.686,0,0,1,.13-.184q1.216-.97,2.439-1.93a.291.291,0,0,1,.433.043.287.287,0,0,1-.046.432q-1.228.976-2.461,1.946a.273.273,0,0,1-.311.031A.353.353,0,0,1,651.339,1186.822Z"
                  transform="translate(-633.221 -1172.297)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M608.831,1159.482c.252,0,.5,0,.754,0a.422.422,0,0,1,.457.45c0,.172,0,.344,0,.516a.421.421,0,0,1-.434.436q-.781.006-1.561,0a.419.419,0,0,1-.435-.433c0-.181,0-.362,0-.542a.416.416,0,0,1,.426-.428h.794Z"
                  transform="translate(-598.386 -1152.264)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M624.5,1159.48c.256,0,.512,0,.767,0a.414.414,0,0,1,.441.438c0,.176,0,.353,0,.529a.414.414,0,0,1-.419.435q-.794.009-1.588,0a.405.405,0,0,1-.419-.394,4.625,4.625,0,0,1,0-.621.4.4,0,0,1,.422-.387C623.967,1159.477,624.231,1159.48,624.5,1159.48Z"
                  transform="translate(-610.861 -1152.262)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M608.843,1170.28c.256,0,.512,0,.767,0a.416.416,0,0,1,.434.433c0,.176,0,.353,0,.529a.414.414,0,0,1-.418.437q-.8.013-1.6,0a.411.411,0,0,1-.413-.441q0-.258,0-.516a.418.418,0,0,1,.437-.443h.794Z"
                  transform="translate(-598.388 -1160.866)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_23"
                  data-name="Path 23"
                  d="M577.439,1193.25c-.251,0-.5,0-.754,0a.421.421,0,0,1-.453-.443c0-.172,0-.344,0-.516a.425.425,0,0,1,.453-.445q.767,0,1.535,0a.42.42,0,0,1,.443.439c0,.181,0,.361,0,.542a.41.41,0,0,1-.417.421c-.269,0-.538,0-.807,0Z"
                  transform="translate(-573.39 -1178.044)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M577.447,1159.48c.256,0,.512,0,.767,0a.416.416,0,0,1,.444.449c0,.172,0,.344,0,.516a.417.417,0,0,1-.43.438q-.787.006-1.574,0a.417.417,0,0,1-.425-.418q-.011-.284,0-.569a.412.412,0,0,1,.425-.415C576.918,1159.477,577.183,1159.48,577.447,1159.48Z"
                  transform="translate(-573.385 -1152.262)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_25"
                  data-name="Path 25"
                  d="M577.457,1182.446c-.26,0-.52,0-.78,0a.417.417,0,0,1-.445-.435q-.007-.265,0-.529a.414.414,0,0,1,.421-.436q.794-.012,1.588,0a.415.415,0,0,1,.423.445c0,.176,0,.353,0,.529a.412.412,0,0,1-.424.427c-.26,0-.52,0-.781,0Z"
                  transform="translate(-573.389 -1169.436)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_26"
                  data-name="Path 26"
                  d="M640.2,1159.481c.256,0,.511,0,.767,0a.414.414,0,0,1,.442.437q0,.264,0,.529a.417.417,0,0,1-.444.438q-.767,0-1.534,0a.419.419,0,0,1-.448-.447c0-.177,0-.353,0-.529a.413.413,0,0,1,.424-.428h.794Z"
                  transform="translate(-623.375 -1152.263)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_27"
                  data-name="Path 27"
                  d="M577.458,1171.684c-.247,0-.494,0-.741,0a.435.435,0,0,1-.481-.485c0-.154,0-.309,0-.463a.421.421,0,0,1,.452-.455q.767,0,1.534,0a.414.414,0,0,1,.444.447q0,.251,0,.5a.42.42,0,0,1-.443.453C577.97,1171.689,577.714,1171.684,577.458,1171.684Z"
                  transform="translate(-573.395 -1160.865)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_28"
                  data-name="Path 28"
                  d="M593.155,1170.28c.256,0,.511,0,.767,0a.412.412,0,0,1,.444.446c0,.168,0,.335,0,.5a.424.424,0,0,1-.467.455c-.348,0-.7,0-1.045,0-.155,0-.309,0-.463,0a.421.421,0,0,1-.451-.457c0-.172,0-.344,0-.516a.41.41,0,0,1,.433-.43h.781Z"
                  transform="translate(-585.905 -1160.865)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_29"
                  data-name="Path 29"
                  d="M593.158,1159.481c.256,0,.511,0,.767,0a.412.412,0,0,1,.437.44c0,.177,0,.353,0,.529a.41.41,0,0,1-.42.432q-.794.008-1.588,0a.41.41,0,0,1-.417-.422c0-.19,0-.379,0-.569a.405.405,0,0,1,.413-.41c.269,0,.538,0,.807,0Z"
                  transform="translate(-585.901 -1152.263)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_30"
                  data-name="Path 30"
                  d="M593.161,1191.847c.251,0,.5,0,.754,0a.418.418,0,0,1,.449.445q0,.258,0,.516a.411.411,0,0,1-.435.442q-.781,0-1.561,0a.407.407,0,0,1-.428-.422c0-.185,0-.37,0-.555a.417.417,0,0,1,.428-.426h.794Z"
                  transform="translate(-585.902 -1178.046)"
                  fill="#cfd0d3"
                />
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M593.148,1182.446c-.256,0-.511,0-.767,0a.41.41,0,0,1-.44-.437q0-.265,0-.529a.41.41,0,0,1,.418-.434q.794-.011,1.587,0a.409.409,0,0,1,.42.433c0,.177,0,.353,0,.529a.411.411,0,0,1-.438.439C593.668,1182.448,593.408,1182.446,593.148,1182.446Z"
                  transform="translate(-585.904 -1169.437)"
                  fill="#cfd0d3"
                />
              </g>
            </svg>

            <span class="tlb-days"
              ><timeago
                :datetime="post.publishedDate"
                :locale="$i18n.locale"
              ></timeago
            ></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import ShareSocials from "./ShareSocials.vue";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  methods: {
    readingTime(text) {
      let minutes = 0;
      const contentString = JSON.stringify(text);
      const words = contentString.split(" ").length;
      const wordsPerMinute = 200;
      minutes = Math.ceil(words / wordsPerMinute);
      return minutes;
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  name: "NewsHeader",
  props: {
    post: null,
  },
  components: {
    // ShareSocials,
  },
};
</script>

<style scoped>
.view-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding: 50px 70px;
  background-color: #cfead9be !important;


  padding: 50px calc((100% - 990px)/2);
  padding-bottom: 0px;
}

.view-title {
  color: #000 !important;
  font-size: 40px;
  text-align: center;
  
  font-size: 32px;
  text-align: left;
}

/* Author Plate */
.view-author {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 40px 0;
  padding: 15px 20px;
  border-radius: 20px;
  border: 1px solid #e2e2e2d3 !important;
  background-color: #ffffffd3 !important;
}

.author-box {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.ab-author-image {
  width: 40px;
  height: auto;
  border-radius: 20px;
}
.link {
  padding-left: 3px !important;
}

.link:hover {
  fill: #1ba54a !important;
}
.ab-name {
  font-size: 15px;
  color: #000 !important;
}
.ab-bio {
  margin-left: 10px;
}
.ab-post-date,
.ab-read-time {
  font-size: 13px;
  font-weight: bold;
  color: #000 !important;
}

.ab-read-time {
  padding-left: 5px;
}

.social-box {
  display: flex;
}

.sb-social-link {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000 !important;
  border-radius: 50%;
}
.sb-social-link + .sb-social-link {
  margin-left: 10px;
}

.sb-social-icon {
  width: 13px;
  height: auto;
  fill: #d5e6de !important;
}
.ltb-location-box,
.ltb-days-box {
  margin-top: -30px !important;
}
.loco-time-box {
  display: flex;
}
.tab-display-only .loco-time-box {
  margin-top: 20px;
  justify-content: space-between;
}

.tlb-icon {
  width: 15px;
  height: auto;
  fill: #000 !important;
}

.tlb-location,
.tlb-days {
  font-size: 15px;
  padding-left: 4px;
  color: #000 !important;
}

.ltb-days-box {
  margin-left: 20px;
}

@media screen and (max-width: 950px) {
  .social-box {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  .view-title {
    font-size: 25px !important;
  }
  .view-header {
    padding: 30px 10px !important;
  }

  .view-author {
    display: none;
  }
  .ltb-location-box,
  .ltb-days-box {
    margin-top: 0px !important;
  }
}

@media (max-width: 375px) {
  .view-title {
    font-size: 25px !important;
  }

  .view-header {
    padding: 30px 10px !important;
  }
  .view-author {
    display: none;
  }
  .ltb-location-box,
  .ltb-days-box {
    margin-top: 0px !important;
  }
}
</style>
