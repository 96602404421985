<template>
  <div class="box mb-3 shadow-sm rounded bg-white profile-box text-center">
    <div class="py-4 px-3 border-bottom">
      <img :src="cover" class="img-fluid" alt="cover" />
    </div>
    <div class="p-4">
      <form enctype="multipart/form-data" action="#" @submit.prevent="submit">
        <label
          v-if="buttonChoice === 'select'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload New Picture"
          class="btn btn-info m-0"
          for="fileAttachmentBtn"
        >
          <i class="feather-upload "></i> {{ $t('change') }}
        </label>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Upload"
          type="submit"
          :disabled="loading"
          class="btn btn-success ml-3"
        >
          <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
          <i class="feather-upload-cloud" v-else></i>
          {{ loading ? '...' + $t('upload') : $t('upload') }}
        </button>
        <button
          v-if="buttonChoice === 'upload'"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete"
          type="submit"
          @click="cancel"
          class="btn btn-danger ml-3"
        >
          <i class="feather-trash-2"></i> {{ $t('delete') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import APIURLS from '../../constant';
import i18n from '../../i18n';
import { mapGetters, mapActions } from 'vuex';
export default {
  async created() {
    this.avatar = this.profileAavatar;
  },
  computed: {
    ...mapGetters(['authUser', 'getProfile']),
    ...mapActions(['loadProfile', 'updateProfile']),
  },
  data() {
    return {
      avatar: null,
      buttonChoice: 'select',
      image: null,
      file: '',
      fileContents: null,
      cloudName: 'yanfomaweb',
      preset: 'bantaba',
      formData: null,
      loading: false,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = file;
      this.avatar = URL.createObjectURL(file);
      this.buttonChoice = 'upload';
    },
    prepareFormData: function() {
      this.formData = new FormData();
      this.formData.append('upload_preset', this.preset);
      this.formData.append('file', this.fileContents);
    },
    submit() {
      this.loading = true;
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        function() {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: 'POST',
            data: this.formData,
            onUploadProgress: function(progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          Axios(requestObj)
            .then((response) => {
              this.results = response.data;
              this.image = this.results.secure_url;
              this.$store
                .dispatch('updateProfile', { avatar: this.image })
                .then((success) => {
                  this.image = null;
                  this.$noty.success('Avatar Updated Successfully !');
                  this.loading = false;
                  this.buttonChoice = 'select';
                })
                .catch((error) => {
                  this.loading = false;
                  this.$noty.error(error);
                });
            })
            .catch((error) => {
              this.loading = false;
              this.errors.push(error);
            });
        }.bind(this),
        false
      );
      reader.readAsDataURL(this.file);
    },
    cancel() {
      this.avatar = this.profileAavatar;
      this.buttonChoice = 'select';
    },
  },
  name: 'UpdateStartupAdmin',
  props: {
    cover: {
      type: String,
    },
  },
};
</script>

<style>
.myAvatar {
  width: 180px;
  height: 180px;
  border: 5px solid #0f7f12 !important;
}
</style>
