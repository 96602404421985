<template>
  <div class="border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t('skills') }}</h6>
    </div>
    <div class="box-body">
      <div class="box-body">
        <form @submit.prevent="updateSkills" v-if="!showSkills">
          <div class="border rounded bg-white mb-3">
            <div class="box-body px-3 pt-3 pb-0">
              <div class="row">
                <div class="col-sm-12 mb-4">
                  <label id="language" class="form-label"
                    >{{ $t('name') }} <span class="text-danger">*</span></label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('name')"
                      v-model="form.name"
                      required
                    />
                  </div>
                </div>
                <div class="col-sm-12 mb-4">
                  <label id="TO" class="form-label"
                    >{{ $t('level') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="input-group">
                    <select
                      class="custom-select my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      v-model="form.level"
                      required
                    >
                      <option selected disabled>{{ $t('choose') }}}...</option>
                      <option value="beginner">{{ $t('beginner') }}</option>
                      <option value="medium">{{ $t('medium') }}</option>
                      <option value="pro">{{ $t('pro') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-4">
                  <label id="companyLabel" class="form-label"
                    >{{ $t('numberOfYears') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      required
                      v-model="form.years"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              :disabled="loading"
              type="submit"
              class="btn btn-success ml-3"
            >
              <i class="fa fa-spin fa-1x fa-spinner" v-if="loading"></i>
              {{ loading ? '' : $t('save') }}
            </button>

            <button
              type="submit"
              @click="hideSkills"
              class="btn btn-danger ml-3"
            >
              {{ $t('cancel') }}
            </button>
          </div>
        </form>
        <div class="card-body" v-else>
          <div v-if="skills.length">
            <div
              v-for="skill in skills"
              :key="skill.id"
              class="row langItem p-2"
            >
              <div class="col-12 mb-1">
                <h6 class="float-left">{{ skill.name }}</h6>
                <button
                  class="float-right btn btn-danger btn-xs pull-right"
                  @click="deleteSkill(skill.id)"
                >
                  <i class="feather-trash"></i>
                </button>
              </div>
              <div class="col-12">
                <p class="#">
                  {{ $t(skill.level) }}
                  <span class="badge badge-success text-red ml-1"
                    >{{ skill.years }} {{ $t('years') }}</span
                  >
                </p>
              </div>
              <hr />
            </div>
          </div>
          <button
            type="button"
            @click="showskills"
            class="btn btn-primary mt-2"
          >
            {{ $t('addSkill') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  mounted() {
    this.skills = this.tags;
  },
  computed: {
    ...mapGetters(['authUser', 'getSkills']),
    ...mapActions(['loadSkills']),
  },
  data() {
    return {
      skills: [],
      loading: false,
      showSkills: true,
      form: {},
    };
  },
  methods: {
    showskills() {
      this.showSkills = false;
    },
    hideSkills() {
      this.showSkills = true;
    },
    updateSkills() {
      this.loading = true;
      this.$store
        .dispatch('createSkills', {
          skills: this.form,
        })
        .then((success) => {
          this.loading = false;
          this.form = {};
          this.showSkills = true;
          this.$noty.success(this.$i18n.t('skillsUpdated'));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    deleteSkill(skill_id) {
      this.$store
        .dispatch('deleteSkill', {
          skill_id: skill_id,
        })
        .then((success) => {
          this.showSkills = true;
          this.$noty.success(this.$i18n.t('skillsDeleted'));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  name: 'AddSkills',
  props: {
    tags: {},
  },
};
</script>
