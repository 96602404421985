<template>
  <div class="language" :class="{ active: isSelected }">
    <div class="name-flag">
      <img class="flag" :src="imageUrl" :alt="name + 'Flag'" />
      <span class="lang-name">{{ name }}</span>
    </div>
    <ToggleSwitch :code="code" @switchChecked="setIsSelected" />
  </div>
</template>

<script>
import ToggleSwitch from "./ToggleSwitch.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LanguageSelect",
  mounted() {
   
  },
  components: {
    ToggleSwitch,
  },
  props: {
    imageUrl: String,
    name: String,
    code: String,
  },
  data: function () {
    return {
      isSelected: false,
    };
  },
  computed: {
    ...mapGetters(["authUser", "getProfile", "getUserLanguage"]),
  },
  methods: {
    setIsSelected({ checked }) {
     
      this.isSelected = checked;
      let locale = this.code;
      this.$i18n.locale = locale;
      var originalName = this.$route.name;
      var name = originalName.substring(0, originalName.length - 2);
      var lang = originalName.slice(-2);
      if (lang === "En") name += "Fr";
      else name += "En";
      locale = this.$i18n.t(locale);
      var request = "";
      var slug = `${this.$route.params.slug}`;
      //make an api call to save the language to the db
      //this.$router.push({ name: name, params: { lang: locale } });
    },
  },
};
</script>

<style>
.lang-name {
  padding: 0px 20px;
}
.language .name-flag span.name {
  margin-left: 20px;
}

.language .name-flag img.flag {
  height: 20px;
}

.language {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #11111133;
}

.language.active {
  border: 2px solid var(--first-color);
  color: var(--first-color);
}
.language:not(:last-child) {
  margin-bottom: 10px;
}
</style>
