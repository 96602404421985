<template>
  <div class="right-side">
    <Illustration
      :blob="require('@/assets/registration/blob.svg')"
      :illustration="require('@/assets/registration/Forgot-password-pana.svg')"
    />
  </div>
</template>
<script>
import Illustration from "../../../components/Auth/Illustration.vue";
export default {
  name: "RightSide",
  components: {
    Illustration,
  },
};
</script>
<style scoped>
.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  flex-basis: 0;
  flex-grow: 0.6;
  border-top-left-radius: var(--layout-radius);
  border-bottom-left-radius: var(--layout-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .right-side {
    position: absolute;
    top: 0;
    width: 100vw;
    display: none;
  }
}
</style>