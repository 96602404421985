<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="interests-header" v-if="getUserTalents.length > 0 || showEditButtons">
      <span class="title">{{ $t("areasOfExpertise") }}</span>
      <labeled-edit-button v-if="showEditButtons && !editing" @click.native="edit()" />
      <save-cancel-button-group @save="save" @cancel="cancelEdit()" v-if="editing" />
    </div>
    <div class="interests-body" ref="expertise">
      <div class="show-wrapper d-flex flex-wrap" v-if="!adding">
        <div v-for="(tag, index) in getUserTalents" :key="index" class="tag-wrapper">
          <tag v-if="showEditButtons && editing" :name="tag.talents_name" :isEditMode="showEditButtons"
            @click.native="deleteExpertise(tag.usersTalents_talent_id)" />
          <tag v-else :name="tag.talents_name" :isEditMode="showEditButtons && editing" />
        </div>
        <div class="tag-wrapper" v-if="editing">
          <add-more-button @click.native="addMore()" />
        </div>
        <p v-if="showEditButtons && getUserTalents.length <= 0 && !editing">
          {{ $t("addTalents")}}</p>
      </div>
      <div class="add-wrapper" v-if="adding">
        <select name="expertise" class="w-100 profile-controls" v-model="expertise">
          <option v-for="(expertise, index) in expertises" :key="index" :value="expertise.talent_id"
            :selected="index === 0">
            {{ expertise.name }}
          </option>
        </select>
        <div class="w-100">
          <labeled-save-cancel-button-group class="justify-content-end" @save="createExpertise" @cancel="cancelAdd()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from "../main/Tag";
import LabeledEditButton from "../buttons/LabeledEditButton";
import AddMoreButton from "../buttons/AddMoreButton";
import SaveCancelButtonGroup from "../buttons/SaveCancelButtonGroup";
import LabeledSaveCancelButtonGroup from "../buttons/LabeledSaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../../../i18n";
export default {
  async mounted() {
    var lang = this.getUserLang;
    let user_id = this.getProfile.id;
    await this.loadUserExpertises({
      lang,
      user_id: user_id,
    });
    await this.loadTalents({ lang });
    this.talents = this.getTalents;
    this.talents.forEach((talent) => {
      this.expertises.push({
        talent_id: talent.id,
        code: talent.code,
        name: talent.name,
      });
    });
    this.expertise = this.expertises[0].talent_id;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getSectorstList",
      "getTalents",
      "getUserTalents",
      "getProfileEditingMode",
      "getUserLang",
    ]),
  },
  props: {
    tags: Array,
  },
  components: {
    Tag,
    AddMoreButton,
    LabeledEditButton,
    SaveCancelButtonGroup,
    LabeledSaveCancelButtonGroup,
  },
  data() {
    return {
      editing: false,
      adding: false,
      expertises: [],
      talents: {},
      expertise: "",
    };
  },
  watch: {
    editing: function (newValue) {
      if (!newValue) this.adding = false;
    },
  },
  methods: {
    ...mapActions([
      "loadSectorsList",
      "loadTalents",
      "loadUserExpertises",
      "loadUserExpertises",
      "createUserExpertise",
      "deleteUserExpertise",
    ]),
    addMore: function () {
      this.adding = true;
    },
    createExpertise() {
      if (this.expertise) {
        let user_id = this.authUser.id;
        let talent_id = this.expertise;
        this.$store
          .dispatch("createUserExpertise", {
            user_id,
            talent_id,
          })
          .then(async () => {
            await this.loadUserExpertises({ user_id, lang: i18n.locale });
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
            this.adding = true;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
    },
    async deleteExpertise(talent_id) {
      if (this.getUserTalents.length <= 1) {
        this.$noty.info(this.$i18n.t("expertiseCantBeEmpty"));
      } else {
        let user_id = this.authUser.id;
        this.$store
          .dispatch("deleteUserExpertise", {
            user_id,
            talent_id,
          })
          .then(async () => {
            await this.loadUserExpertises({ user_id, lang: i18n.locale });
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
            this.adding = false;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
    },
    cancelAdd: function () {
      this.adding = false;
    },
    edit: function () {
      this.editing = true;
      this.$refs.expertise.classList.add("editing");
    },
    save: function () {
      this.editing = false;
      this.$refs.expertise.classList.remove("editing");
    },
    cancelEdit: function () {
      this.editing = false;
      this.$refs.expertise.classList.remove("editing");
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: none !important;
  border-bottom: 2px solid var(--first-color) !important;
  border-radius: 0rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
.tag{
  color: rgba(0, 0, 0, .69)!important;
  border: 1px solid rgba(0, 0, 0, .15) !important;
  box-shadow: none !important;
}


.editBorder  {
  color: #c31e3e !important;
  border: 1px solid #c31e3e !important;
}
</style>

