import i18n from "../i18n";

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export function authGuard(to, from, next) {
    let auth = localStorage.getItem("auth");
    if (auth) {
        let token = JSON.parse(auth).token;
        const { exp } = parseJwt(token); //get expiration date which is 7 days now
        let date = Date.now() >= exp * 1000;
        if (date) {
            sessionStorage.setItem('redirectPath', to.path);
            return next({
                path: `/${i18n.locale}/${i18n.t("routes.login")}`
            });
        }
        next();
    } else {
        sessionStorage.setItem('redirectPath', to.path);
        return next({
            path: `/${i18n.locale}/${i18n.t("routes.login")}`
        });
    }
}

export function confirmedUsers(to, from, next) {
    let auth = localStorage.getItem("auth");
    let authUser = JSON.parse(auth);
    if (authUser.confirmed) {
        alert('ok')
        next();
    } else {
        alert('not ok')
        return next({
            path: `/${i18n.locale}/${i18n.t("routes.login")}`
        });
    }
}
