import axios from 'axios';
import moment from 'moment';
import APIURLS from '../../constant';
import Vue from 'vue';
const auth = JSON.parse(localStorage.getItem('auth'));

const lang = localStorage.getItem('userLang');

const state = {
  user: auth ? auth : null,
  userLang: lang ? lang : null,
  profile: null,
  profileSections: null,
  startupBio: '',
  startup: null,
  viewProfile: null,
  skills: {},
  languages: {},
  experiences: {},
  educations: {},
  interrests: [],
  interrestList: [],
  sectorstList: [],
  userSupportTypes: [],
  talents: [],
  usersSupportStartups: [],
  jobs: [],
  news: [],
  newsCount: 0,
  startups: [],
  featuredStartups: [],
  startupsCount: 0,
  corporations: {},
  registerForm: {},
  startupOverviewForm: {},
  cookiesPolicy: '',
  privacyPolicy: '',
  profileStrength: 0,
  startupTalentsNeeded: {},
  default_startup_feeds: {},
  team: [],
  countries: {},
  foreignCountries: {},
  loadedteamTempImage: false,
  teamTempImage: '',
  relevantTalents: {},
  userCover:
    'https://res.cloudinary.com/bantaba/image/upload/v1624165895/platform/cover_1_lkal9r.jpg',
  engagementFilter: {},
  countriesFilter: {},
  sectorsFilter: {},
  expertisesFilter: {},
  modalMode: false,
  profileEditingMode: false,
  startupEditingMode: false,
  showEditButtons: false,
  userTalents: [],
  userSectorOfInterest: [],
  rerender: 0,
  userlanguage: 'en',
  similarNews: [],
  postPhotoUploaded: false,
  postPhoto: {},
  showReferral: false,
};
const getters = {
  authUser: (state) => {
    return state.user;
  },

  getShowReferral: (state) => {
    return state.showReferral;
  },

  getUserLang: (state) => {
    return state.userLang;
  },
  getSimilarNews: (state) => {
    return state.similarNews;
  },
  getUserLanguage: (state) => {
    return state.userlanguage;
  },
  getRerender: (state) => {
    return state.rerender;
  },
  getStartupBio: (state) => {
    return state.startupBio;
  },
  getPostPhotoUploaded: (state) => {
    return state.postPhotoUploaded;
  },
  getPostPhoto: (state) => {
    return state.postPhoto;
  },
  getUserTalents: (state) => {
    return state.userTalents;
  },
  getUserSectorOfInterest: (state) => {
    return state.userSectorOfInterest;
  },
  getProfileEditingMode: (state) => {
    return state.profileEditingMode;
  },
  getStartupEditingMode: (state) => {
    return state.startupEditingMode;
  },
  showEditButtons: (state) => {
    return state.showEditButtons;
  },
  getModalMode: (state) => {
    return state.modalMode;
  },
  getFeaturedStartups: (state) => {
    return state.featuredStartups;
  },
  getEngagementFilter: (state) => {
    return state.engagementFilter;
  },
  getCountriesFilter: (state) => {
    return state.countriesFilter;
  },
  getSectorsFilter: (state) => {
    return state.sectorsFilter;
  },

  getExpertisesFilter: (state) => {
    return state.expertisesFilter;
  },
  getProfile: (state) => {
    return state.viewProfile;
  },
  getProfileSections: (state) => {
    return state.profileSections;
  },

  getUserSupportTypes: (state) => {
    return state.userSupportTypes;
  },
  getDefaultFeeds: (state) => {
    return state.default_startup_feeds;
  },
  getUserCover: (state) => {
    return state.userCover;
  },
  getprofileStrength: (state) => {
    return state.profileStrength;
  },
  getCountriesList: (state) => {
    return state.countries;
  },
  getForeignCountriesList: (state) => {
    return state.foreignCountries;
  },
  getStartup: (state) => {
    return state.startup;
  },
  getStartupTeam: (state) => {
    return state.team;
  },
  getRelevantTalents: (state) => {
    return state.relevantTalents;
  },
  getTeamTempImage: (state) => {
    return state.teamTempImage;
  },
  getTeamTempImgStatus: (state) => {
    return state.loadedteamTempImage;
  },

  getUserProfile: (state) => {
    return state.profile;
  },
  getUsersSupportStartups: (state) => {
    return state.usersSupportStartups;
  },
  getSkills: (state) => {
    return state.skills;
  },
  getLanguages: (state) => {
    return state.languages;
  },
  getExperiences: (state) => {
    return state.experiences;
  },
  getEducations: (state) => {
    return state.educations;
  },
  getInterrests: (state) => {
    return state.interrests;
  },
  getInterrestList: (state) => {
    return state.interrestList;
  },
  getSectorstList: (state) => {
    return state.sectorstList;
  },
  getTalents: (state) => {
    return state.talents;
  },
  getCookiesPolicy: (state) => {
    return state.cookiesPolicy;
  },
  getPrivacyPolicy: (state) => {
    return state.privacyPolicy;
  },
  getStartupNeededTalents: (state) => {
    return state.startupTalentsNeeded;
  },
  getJobs: (state) => {
    return state.jobs;
  },
  getNews: (state) => {
    return state.news;
  },
  getNewsCount: (state) => {
    return state.newsCount;
  },
  getStartupsCount: (state) => {
    return state.startupsCount;
  },
  getStartups: (state) => {
    return state.startups;
  },
  getCorporations: (state) => {
    return state.corporations;
  },
  getRegistrationForm: (state) => {
    return state.registerForm;
  },
  getStartupOverviewForm: (state) => {
    return state.startupOverviewForm;
  },
};
const actions = {
  getUserGeoInfos() {
    return new Promise((resolve, reject) => {
      axios
        .get('https://api.ipregistry.co/?key=' + APIURLS.IP_API_KEY)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadShowReferral({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURLS.LOGIN + 'show-referral/' + user_id)
        .then(({ data, status }) => {
          if (status === 201 || status === 200) {
            resolve(data.showReferral);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  logUserIn({ commit }, form) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.AUTH + 'login', {
          email: form.email,
          password: form.password,
          loginData: form.login,
        })
        .then(({ data, status }) => {
          if (status === 201 || status === 200) {
            if (data.message === 'Verify Account') {
              resolve('Verify Account');
            } else {
              localStorage.setItem('auth', JSON.stringify(data));
              commit('setAuthUser', data);
              resolve(true);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadProfile({ commit }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.PROFILE + 'user/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadprofile', response.data.profile);
          if (response.status === 200) {
            commit('setUserProfile', response.data.profile);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadprofileStrength({ commit }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.USERS + 'profile-strength/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadprofileStrength', response.data.points);
          if (response.status === 200) {
            commit('setProfileStrength', response.data.points);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadStartup({ commit }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + 'user/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadStartup', response.data.startup);
          if (response.status === 200) {
            commit('setUserStartup', response.data.startup);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadEngagementFilter({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.USERSTYPEOFSUPPORT + 'engagement-filter',
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let filters = [];
            response.data.filters.map((filter) => {
              filters.push({
                name: filter.typeOfSupport,
                value: filter.typeOfSupport,
                count: filter.count,
              });
            });
            commit('setEngagementFilter', filters);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadCountriesFilter({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.FOREIGNCOUNTRIES + 'filters/' + lang,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let filters = [];
            response.data.filters.map((filter) => {
              filters.push({
                name: filter.countries_name,
                value: filter.countries_code,
                count: filter.count,
              });
            });
            commit('setCountriesFilter', filters);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadAfricanCountriesFilter({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.COUNTRIES + 'filters/' + lang,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let filters = [];
            response.data.filters.map((filter) => {
              filters.push({
                name: filter.countries_name,
                value: filter.countries_code,
                count: filter.count,
              });
            });
            commit('setCountriesFilter', filters);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadExpertisesFilter({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.TALENTS + 'filters/' + lang,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let filters = [];
            response.data.filters.map((filter) => {
              filters.push({
                name: filter.talents_name,
                value: filter.talents_id,
                count: filter.count,
              });
            });
            commit('setExpertisesFilter', filters);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadSectorsFilter({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SECTORSLIST + 'filters/' + lang,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let filters = [];
            response.data.filters.map((filter) => {
              filters.push({
                name: filter.sectorList_name,
                value: filter.sectorList_code,
                count: filter.count,
              });
            });
            commit('setSectorsFilter', filters);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  viewedPage({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'post',
        url: APIURLS.VIEWS + '/viewed-page',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          //console.log('viewed response', response)
          if (response.status === 200 || response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadStartupTeam({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      // const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + 'user/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setStartupTeam', response.data.startup.teams);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getStartupById({ commit }, { startup_id }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + startup_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadprofile', response.data.profile);
          if (response.status === 200) {
            commit('setUserStartup', response.data.startup);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getSingleUserById({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.USERS + 'single-user/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadprofileStrength', response.data);
          if (response.status === 200) {
            resolve(response.data.user);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getUsernameById({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.USERS + 'user-id/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          console.log({ 'getUsernameById response': response })
          //console.log('loadprofileStrength', response.data);
          if (response.status === 200) {
            console.log({ 'getUsernameById': response.data })
            resolve(response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getUserById({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.USERS + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadprofileStrength', response.data);
          if (response.status === 200) {
            resolve(response.data.user);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadStartupNeeds({ commit }, { startup_id, lang }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + startup_id + '/talents',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          let talents = response.data.talents;
          const data = { startup_id, talents, lang };
          let finalTalents = await axios({
            method: 'post',
            url: APIURLS.TALENTS + 'get-startup-talents',
            data,
            headers: { 'auth-token': token },
          });
          if (finalTalents.status === 200 || finalTalents.status === 201) {
            commit('setStartupNeeds', finalTalents.data.talents);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadStartupTypeOfSupport({ commit }, { startup_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.USERSTYPEOFSUPPORT + 'startup_id/' + startup_id,
      })
        .then(async ({ data, status }) => {
          if (status === 200 || status === 201) {
            commit('setSupportTypes', data.supportTypes);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  createStartupTypeOfSupport({ commit }, { startup_id, support }) {
    //console.log({ startup_id, support })
    return new Promise((resolve, reject) => {
      const data = { startup_id, support };
      axios
        .post(APIURLS.USERSTYPEOFSUPPORT + 'startup/create', data)
        .then(async ({ data, status }) => {
          console.log({ data, status });
          if (status === 200 || status === 201) {
            // commit('setSupportTypes', data.supportTypes);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  createStartupTalent({ commit }, { startup_id, talent_id }) {
    // console.log({ startup_id, talent })
    return new Promise((resolve, reject) => {
      const data = { startup_id, talent_id };
      axios
        .post(APIURLS.TALENTS + 'startup/create', data)
        .then(async ({ data, status }) => {
          // console.log({ data, status });
          if (status === 200 || status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  deleteStartupSupport({ commit }, { startup_id, support_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.USERSTYPEOFSUPPORT +
          'delete/startup/' +
          startup_id +
          '/support/' +
          support_id,
      })
        .then(async ({ data, status }) => {
          //console.log('data', data);
          if (data.message === 'support type deleted') {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  deleteStartupSupportNeed({ commit }, { startup_id, talent_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.TALENTS +
          'delete/startup/' +
          startup_id +
          '/talent/' +
          talent_id,
      })
        .then(async ({ data, status }) => {
          //console.log('deleteStartupSupportNeed', data);
          if (data.message === 'talent deleted') {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadUserTypeOfSupport({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.USERSTYPEOFSUPPORT + 'user_id/' + user_id,
      })
        .then(async ({ data, status }) => {
          //console.log('data', data)
          if (status === 200 || status === 201) {
            commit('setSupportTypes', data.supportTypes);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadUserSectorOfInterest({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.USERSTYPEOFSUPPORT + 'user_id/' + user_id,
      })
        .then(async ({ data, status }) => {
          //console.log('data', data)
          if (status === 200 || status === 201) {
            commit('setSupportTypes', data.supportTypes);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadUserExpertises({ commit }, { user_id, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.USERSTALENTS + 'user/' + user_id + '/lang/' + lang,
      })
        .then(async ({ data, status }) => {
          //console.log('USERSTALENTS', data)
          if (status === 200 || status === 201) {
            commit('setUserTalents', data.talents);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadUserSectorsOfInterest({ commit }, { user_id, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.USERSSECTOROFINTEREST + 'user/' + user_id + '/lang/' + lang,
      })
        .then(async ({ data, status }) => {
          //console.log('loadUserSectorsOfInterest', data)
          if (status === 200 || status === 201) {
            commit('setUserSectorOfInterest', data.sectors);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  deleteUserSector({ commit }, { user_id, sector_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.USERSSECTOROFINTEREST +
          'delete/user/' +
          user_id +
          '/sector/' +
          sector_id,
      })
        .then(async ({ data, status }) => {
          //console.log('data', data);
          if (data.message === 'Sector deleted') {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            //console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  createUserSectorOfInterest({ commit }, { user_id, sector_id }) {
    // console.log({ user_id, talent_id })
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.USERSSECTOROFINTEREST + 'create/users', {
          user_id,
          sector_id,
        })
        .then(async ({ data, status }) => {
          //console.log({ data, status });
          if (status === 200 || status === 201) {
            //commit('setUserSectorOfInterest', data.sectors);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createUserExpertise({ commit }, { user_id, talent_id }) {
    // console.log({ user_id, talent_id })
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.USERSTALENTS + 'create/users', { user_id, talent_id })
        .then(async ({ data, status }) => {
          console.log({ data, status });
          if (status === 200 || status === 201) {
            commit('setUserTalents', data.talents);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createUserTypeOfSupport({ commit }, { user_id, support }) {
    //console.log({ user_id, support })
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.USERSTYPEOFSUPPORT + 'user/create', { user_id, support })
        .then(async ({ data, status }) => {
          console.log({ data, status });
          if (status === 200 || status === 201) {
            commit('setSupportTypes', data.supportTypes);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  deleteUserSupport({ commit }, { user_id, support_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.USERSTYPEOFSUPPORT +
          'delete/user/' +
          user_id +
          '/support/' +
          support_id,
      })
        .then(async ({ data, status }) => {
          //console.log('data', data);
          if (data.message === 'support type deleted') {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            //console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  deleteUserExpertise({ commit }, { user_id, talent_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.USERSTALENTS +
          'delete/user/' +
          user_id +
          '/talent/' +
          talent_id,
      })
        .then(async ({ data, status }) => {
          //console.log(' deleteUserExpertise data', data);
          if (data.message === 'talent deleted') {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            //console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  deleteStartupTalent({ commit }, { startup_id, talent_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.TALENTS +
          'delete/startup/' +
          startup_id +
          '/talent/' +
          talent_id,
      })
        .then(async ({ data, status }) => {
          if (data.message === 'talent deleted') {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            //console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadStartupBySlug({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + 'slug/' + slug,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('loadStartupBySlug', response.data.startup);
          if (response.status === 200) {
            commit('setUserStartup', response.data.startup);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadUsersSupportStartups({ commit }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.USERSSUPORTSTARTUPS,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setUsersSupportStartups', response.data.users);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadDefaultFeeds({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.DEFAULT_FEEDS + 'children/',
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setDefaultFeeds', response.data.feeds);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadUserByUsername({ commit }, { username }) {
    return new Promise((resolve, reject) => {
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.USERS + 'username/' + username,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          //console.log('response.data.user', response.data.user);
          if (response.data.user) {
            commit('setUserProfile', response.data.user);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          console.log(
            'Bantaba| auth module | loadUserByUsername: error',
            error
          );
          reject(error);
        });
    });
  },
  loadUserProfileSections({ commit }, { username }) {
    return new Promise((resolve, reject) => {
      // const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.USERS + 'profile-sections/' + username,
      })
        .then((response) => {
          if (response.data.user) {
            commit('setUserProfileSections', response.data.user);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          console.log(
            'Bantaba| auth module | loadUserByUsername: error',
            error
          );
          reject(error);
        });
    });
  },

  loadSkills({ commit }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.SKILLS + 'user/' + user_id,
      })
        .then((response) => {
          //console.log('response', response.status);
          if (response.status === 200) {
            commit('setSkills', response.data.skills);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadLanguages({ commit }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.LANGUAGES + 'user/' + user_id,
      })
        .then((response) => {
          // console.log('LANGUAGES', response.data.languages);
          if (response.status === 200) {
            commit('setLanguages', response.data.languages);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadExperiences({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      //const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.EXPERIENCES + 'user/' + user_id,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setExperiences', response.data.experiences);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadEducation({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      //const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.EDUCATION + 'user/' + user_id,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setEducation', response.data.educations);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadInterrests({ commit }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      axios({
        method: 'get',
        url: APIURLS.INTERRESTS + 'user/' + user_id,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setInterrests', response.data.interrests);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadRelevantTalents({ commit }, { startup_id }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.STARTUP + 'relevant-talents/' + startup_id,
      })
        .then((response) => {
          // console.log('startup_id', startup_id);
          if (response.status === 200) {
            commit('setRelevantTalents', response.data.data);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadInterrestList({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.INTERRESTLIST + 'all',
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setInterrestList', response.data.interrests);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadSupportType({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SUPPORTTYPES + 'all',
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setInterrestList', response.data.interrests);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getSectorByLang({ commit }, { code, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SECTORSLIST + 'code/' + code + '/lang/' + lang,
      })
        .then((response) => {
          if (response.status === 200) {
            // response.data.sector.name;
            if (response.data.sector) {
              resolve(response.data.sector.name);
            } else {
              resolve(null);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getAFricanCountryByLang({ commit }, { code, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.COUNTRIES + 'code/' + code + '/lang/' + lang,
      })
        .then((response) => {
          if (response.status === 200) {
            // response.data.sector.name;
            let name = response.data.country ? response.data.country.name : '';
            resolve(name);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  getForeignCountryByLang({ commit }, { code, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.FOREIGNCOUNTRIES + 'code/' + code + '/lang/' + lang,
      })
        .then((response) => {
          if (response.status === 200) {
            // response.data.sector.name;
            resolve(response.data.country.name);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadSectorsList({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SECTORSLIST + lang,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setSectorstList', response.data.sectors);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadTalents({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.TALENTS + lang,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setTalents', response.data.talents);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadCookiesPolicy({ commit }, { field, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SETTINGS + field + '/' + lang,
      })
        .then((response) => {
          let newField = response.data.field;
          let text = response.data.setting[0][newField];
          // console.log('SETTINGS response', response.data.field);
          // console.log('SETTINGS response', text);
          if (response.status === 200) {
            commit('setCookiesPolicy', text);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadPrivacyPolicy({ commit }, { field, lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SETTINGS + field + '/' + lang,
      })
        .then((response) => {
          let newField = response.data.field;
          let text = response.data.setting[0][newField];
          if (response.status === 200) {
            commit('setPrivacyPolicy', text);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },

  loadPaginatedJobs({ commit, getters }, pagination) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.JOBS + 'pagination/' + pagination + '/' + getters.getUserLang,
        // url: APIURLS.JOBS + 'all'
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setJobs', response.data.jobs);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('loadPaginatedJobs: error ', error.request);
          } else {
            reject(error);
          }
          console.log('loadPaginatedJobs: error ', error);
        });
    });
  },
  loadJobs({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.JOBS + 'all/' + getters.getUserLang,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setJobs', response.data.jobs);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadNews({ commit, getters }, pagination) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.NEWS +
          'all/pagination/' +
          pagination +
          '/' +
          getters.getUserLang,
        // url: APIURLS.NEWS + 'all'
      })
        .then((response) => {
          if (response.status === 200) {
            //console.log('response.data.count', response.data.count)
            commit('setNews', {
              news: response.data.news,
              count: response.data.count,
            });
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadFeaturedStarups({ commit }, { lang, pagination }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      const token = state.user.token;
      axios({
        method: 'get',
        url:
          APIURLS.STARTUP +
          'featured-startups/' +
          user_id +
          '/lang/' +
          lang +
          '/paginate/' +
          pagination,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 200) {
            //console.log('response.data', response.data.startups);
            commit('setFeaturedStartups', response.data.startups);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadStartups({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + 'all/skip/' + user_id + '/' + lang,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 200) {
            // console.log('response.data', response.data);
            commit('setStartups', response.data.startups);
            commit('setStartupsCount', response.data.count);
            // console.log('response.data.startups', response.data.startups);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadAllStartups({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.STARTUP + 'all/skip/' + user_id + '/' + lang,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 200) {
            // console.log('response.data', response.data);
            commit('setStartups', response.data.startups);
            commit('setStartupsCount', response.data.count);
            //console.log('response.data.startups', response.data.startups);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadCountriesList({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.COUNTRIES + lang,
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setCountriesList', response.data.countries);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadForeignCountriesList({ commit }, { lang }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.FOREIGNCOUNTRIES + lang,
      })
        .then((response) => {
          // console.log('loadForeignCountriesList response', response);
          if (response.status === 200) {
            commit('setForeignCountriesList', response.data.countries);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  loadCorporations({ commit, state }) {
    return new Promise((resolve, reject) => {
      const user_id = state.user.id;
      const token = state.user.token;
      axios({
        method: 'get',
        url: APIURLS.CORPORATIONS + 'all/skip/' + user_id,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 200) {
            commit('setCorporations', response.data.corporations);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  async logOut({ commit, dispatch }) {
    const ipRegistry = await dispatch('getUserGeoInfos');
      await axios.post(APIURLS.AUTH + 'logout', {
        loginId: state.user.loginId,
        logoutData: {
          endDate: moment(new Date()).format('MM-DD-YYYY'),
          endTime: moment(new Date()).format('h:mm A'),
          fullEndTime: moment().toISOString(),
          endTimestamp: moment().unix(),
        },
      }).catch(e=>{
        console.log(e);
      })
      ;

      const inputs = [
        'auth',
        'featuredStartups',
        'profileSections',
        'consultants',
        'diaspora',
        'followers',
        'mentors',
        'startups',
        'willingToSupport',
        'relevantTalents',
        'connected',
        'blocked',
        'networkUsers',
        'requested',
        'africanCountryOfOrigin',
        'bio',
        'countryOfResidence',
        'email',
        'expertises',
        'firstName',
        'getAccountType',
        'lastName',
        'levelOfEducation',
        'sectorOfInterrest',
        'supportType',
        'username',
        'yearsOfExperience',
        'description',
        'location',
        'numberInTeams',
        'sector',
        'slug',
        'startupName',
        'website',
        'avatar',
        'cover',
        'startupStage',
        'diasporaTotalCount',
        'connectionsCount',
        'sent',
        'homeNews',
        'homeJobs',
        'allNews',
        'allJobs',
        'supportStartup',
        'received',
        'show-once'
      ];
      inputs.map((item) => {
        if (localStorage.getItem(item)) {
          localStorage.removeItem(item);
        }
      });
      commit('logUserOut');
      return true;
  },
  recoverPassword({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.AUTH + 'recover-password', {
          data,
        })
        .then((response) => {
          console.log('response', response);
          resolve(true);
        })
        .catch((error) => {
          if (error.response) {
            //console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  sendVerificationEmail({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(APIURLS.USERS + 'send-verification-email', {
          data,
        })
        .then((response) => {
          console.log('response', response);
          resolve(true);
        })
        .catch((error) => {
          if (error.response) {
            //console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  updatePassword({ commit }, { id, password, email }) {
    const token = state.user.token;
    const data = {
      id,
      email,
      password,
    };
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.USERS + 'update',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  updateBasicInfo({ commit }, { basicInfo, extendedInfo }) {
    const token = state.user.token;
    basicInfo.id = state.user.id;
    extendedInfo.user_id = state.user.id;

    const basicPromise = new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.USERS + 'update',
        headers: { 'auth-token': token },
        data: basicInfo,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });

    const extendedPromise = new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.PROFILE + 'update',
        headers: { 'auth-token': token },
        data: extendedInfo,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });

    return Promise.allSettled([basicPromise, extendedPromise]);
    // return new Promise.allSettled((resolve, reject) => {

    // });
  },

  updateProfile({ commit }, data) {
    const token = state.user.token;
    data.id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.USERS + 'update',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  updateUserProfile({ commit }, data) {
    const token = state.user.token;
    data.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.PROFILE + 'update',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  updateStartup({ commit }, data) {
    const token = state.user.token;
    data.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.STARTUP + 'update',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          //console.log('response', response);
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  updateAbout({ commit }, data) {
    const token = state.user.token;
    data.user_id = state.user.id;
    console.log('data', data);
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.PROFILE + 'update',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  updateSocials({ commit }, socials) {
    const token = state.user.token;
    socials.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.PROFILE + 'update',
        headers: { 'auth-token': token },
        data: socials,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createSkills({ commit }, { skills }) {
    const token = state.user.token;
    skills.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.SKILLS + 'create',
        headers: { 'auth-token': token },
        data: skills,
      })
        .then((response) => {
          if (response.status === 201) {
            commit('newSkill', response.data.skill);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            // client never received a response, or request never left
          } else {
            reject(error);
          }
        });
    });
  },
  deleteSkill({ commit }, { skill_id }) {
    const token = state.user.token;
    const user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.SKILLS + skill_id + '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('deleteSkill', skill_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createLang({ commit }, { language }) {
    const token = state.user.token;
    language.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.LANGUAGES + 'create',
        headers: { 'auth-token': token },
        data: language,
      })
        .then(async (response) => {
          if (response.status === 201) {
            commit('newLang', response.data.language);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  deleteLang({ commit }, { lang_id }) {
    const token = state.user.token;
    const user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.LANGUAGES +
          'user/' +
          user_id +
          '/lang_id/' +
          lang_id +
          '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('deleteLang', lang_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  createExperience({ commit }, { experience }) {
    const token = state.user.token;
    experience.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.EXPERIENCES + 'create',
        headers: { 'auth-token': token },
        data: experience,
      })
        .then(async (response) => {
          if (response.status === 201 || response.status === 200) {
            commit('newExperience', response.data.experience);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  updateExperience({ commit }, { experience }) {
    const token = state.user.token;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.EXPERIENCES + 'update',
        headers: { 'auth-token': token },
        data: experience,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  deleteExperience({ commit }, { expx_id }) {
    const token = state.user.token;
    const user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.EXPERIENCES +
          'user/' +
          user_id +
          '/exp_id/' +
          expx_id +
          '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('deleteExp', expx_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createEducation({ commit }, { education }) {
    const token = state.user.token;
    education.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.EDUCATION + 'create',
        headers: { 'auth-token': token },
        data: education,
      })
        .then(async (response) => {
          if (response.status === 201) {
            commit('newEducation', response.data.education);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  updateEducation({ commit }, { education }) {
    const token = state.user.token;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.EDUCATION + 'update',
        headers: { 'auth-token': token },
        data: education,
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  deleteEducation({ commit }, { edu_id }) {
    const token = state.user.token;
    const user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.EDUCATION +
          'user/' +
          user_id +
          '/edu_id/' +
          edu_id +
          '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          //console.log('delete expx response', response);
          if (response.status === 200) {
            commit('deleteEducation', edu_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            reject(error);
          }
        });
    });
  },
  createInterrest({ commit }, { interrest }) {
    const token = state.user.token;
    interrest.user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.INTERRESTS + 'create',
        headers: { 'auth-token': token },
        data: interrest,
      })
        .then((response) => {
          if (response.status === 201) {
            commit('newInterrest', response.data.interrest);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            reject(error);
          }
        });
    });
  },
  deleteInterrest({ commit }, { interrest_id }) {
    const token = state.user.token;
    const user_id = state.user.id;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.INTERRESTS + interrest_id + '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('deleteInterrest', interrest_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createStartup({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.STARTUP + 'create',
        data: state.registerForm,
      })
        .then(({ data, status }) => {
          if (data.statusMsg === 'Success') {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  createTeamMember({ commit }, { member }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.TEAM + 'create',
        data: member,
      })
        .then(({ data, status }) => {
          if (data.statusMsg === 'Success') {
            // console.log('startup', data.startup);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            // console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            // console.log('error', error);
            reject(error);
          }
        });
    });
  },
  updateTeamMember({ commit }, { member }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.TEAM + 'update',
        data: member,
      })
        .then(({ data, status }) => {
          if (data.statusMsg === 'Success') {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            // client never received a response, or request never left
          } else {
            reject(error);
          }
        });
    });
  },
  deleteTeamMember({ commit }, { member_id }) {
    const token = state.user.token;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: APIURLS.TEAM + 'member/' + member_id + '/delete',
        headers: { 'auth-token': token },
      })
        .then(async (response) => {
          if (response.status === 200) {
            commit('deleteMember', member_id);
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  addOrDeleteStartupSupport({ commit }, { startup_id, action }) {
    const token = state.user.token;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          APIURLS.USERSTYPEOFSUPPORT +
          'add-or-delete/startup/' +
          startup_id +
          '/action/' +
          action,
        headers: { 'auth-token': token },
      })
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },
  changeUserPreferedLang({ commit }, lang) {
    const token = state.user.token;
    let data;
    data = {
      user_id: state.user.id,
      preferedLanguage: lang,
    };
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.PROFILE + 'update',
        headers: { 'auth-token': token },
        data: data,
      })
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            resolve(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  changeNotifisSettings({ commit }, notifs) {
    const token = state.user.token;
    let data;
    data = {
      id: state.user.id,
      type: notifs.type,
      action: notifs.action,
    };
    console.log('Notifs data', data);
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: APIURLS.USERS + 'set-notifs',
        headers: { 'auth-token': token },
        data,
      })
        .then((response) => {
          console.log({
            response,
          });
          // if (response.status === 201 || response.status === 200) {
          //   resolve(true);
          // }
        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response', error.response);
            reject(error.response.data.message);
          } else if (error.request) {
            console.log('error.request', error.request);
            // client never received a response, or request never left
          } else {
            console.log('error', error);
            reject(error);
          }
        });
    });
  },

  //users/set-notifs/user/:id/type/:type/action/:action
};
const mutations = {
  setAuthUser: (state, data) => {
    state.user = data;
  },
  setSimilarNews: (state, news) => {
    state.similarNews = news;
  },
  setUserLanguage: (state, data) => {
    state.userlanguage = data;
  },
  setUserTalents: (state, data) => {
    state.userTalents = data;
  },
  setRerender: (state) => {
    state.rerender++;
  },
  setUserSectorOfInterest: (state, data) => {
    state.userSectorOfInterest = data;
  },

  setModalMode: (state, value) => {
    state.modalMode = value;
  },

  setProfileEditingMode: (state, value) => {
    state.profileEditingMode = value;
  },
  setStartupEditingMode: (state, value) => {
    state.startupEditingMode = value;
  },
  setShowEditButtons: (state, value) => {
    state.showEditButtons = value;
  },
  setPostPhotoUploaded: (state, value) => {
    state.postPhotoUploaded = value;
  },
  setStartupBio: (state, bio) => {
    state.startupBio = bio;
  },
  setPostPhoto: (state, file) => {
    state.postPhoto = file;
  },
  setUserProfileSections: (state, sections) => {
    state.profileSections = sections;
  },
  setUserProfile: (state, profile) => {
    state.viewProfile = profile;
    state.profile = profile;
  },
  setUserStartup: (state, startup) => {
    state.startup = startup;
  },
  setSkills: (state, skills) => {
    state.skills = skills;
  },
  setLanguages: (state, languages) => {
    state.languages = languages;
  },
  setExperiences: (state, experiences) => {
    state.experiences = experiences;
  },
  setEducation: (state, educations) => {
    state.educations = educations;
  },
  setInterrests: (state, interrests) => {
    state.interrests = interrests;
  },
  setInterrestList: (state, interrests) => {
    state.interrestList = interrests;
  },
  setSectorstList: (state, sectors) => {
    state.sectorstList = sectors;
  },
  setTalents: (state, talents) => {
    state.talents = talents;
  },
  setUserLang: (state, lang) => {
    state.userLang = lang;
  },
  setEngagementFilter: (state, filters) => {
    state.engagementFilter = filters;
  },
  setCountriesFilter: (state, filters) => {
    state.countriesFilter = filters;
  },
  setSectorsFilter: (state, filters) => {
    state.sectorsFilter = filters;
  },
  setExpertisesFilter: (state, filters) => {
    state.expertisesFilter = filters;
  },
  setSupportTypes: (state, types) => {
    state.userSupportTypes = types;
  },
  setUsersSupportStartups: (state, users) => {
    state.usersSupportStartups = users;
  },
  setCookiesPolicy: (state, cookiesPolicy) => {
    state.cookiesPolicy = cookiesPolicy;
  },
  setPrivacyPolicy: (state, privacyPolicy) => {
    state.privacyPolicy = privacyPolicy;
  },
  setJobs: (state, jobs) => {
    state.jobs = jobs;
  },
  setCountriesList: (state, countries) => {
    state.countries = countries;
  },
  setForeignCountriesList: (state, countries) => {
    state.foreignCountries = countries;
  },
  setNews: (state, { news, count }) => {
    state.news = news;
    state.newsCount = count;
  },
  setFeaturedStartups: (state, startups) => {
    state.featuredStartups = startups;
  },
  setStartups: (state, startups) => {
    state.startups = startups;
  },
  setRelevantTalents: (state, relevantTalents) => {
    state.relevantTalents = relevantTalents;
  },
  setStartupNeeds: (state, talents) => {
    state.startupTalentsNeeded = talents;
  },

  setStartupsCount: (state, count) => {
    state.startupsCount = count;
  },

  setStartupTeam: (state, team) => {
    state.team = team;
  },

  setTeamTempImage: (state, image) => {
    state.teamTempImage = image;
    if (image) {
      state.loadedteamTempImage = true;
    } else {
      state.loadedteamTempImage = false;
    }
  },
  setProfileStrength: (state, points) => {
    state.profileStrength = points;
  },
  setDefaultFeeds: (state, feeds) => {
    state.default_startup_feeds = feeds;
  },
  setCorporations: (state, corporations) => {
    state.corporations = corporations;
  },

  newSkill: (state, skill) => {
    state.skills.unshift(skill);
  },
  newLang: (state, language) => {
    state.languages.unshift(language);
  },
  newExperience: (state, experience) => {
    state.experiences.unshift(experience);
  },
  newEducation: (state, education) => {
    state.educations.unshift(education);
  },
  newInterrest: (state, interrest) => {
    state.interrests.unshift(interrest);
  },

  deleteSkill: (state, skill_id) => {
    const index = state.skills.findIndex((skill) => skill.id === skill_id);
    state.skills.splice(index, 1);
  },
  deleteLang: (state, lang_id) => {
    const index = state.languages.findIndex((lang) => lang.id === lang_id);
    state.languages.splice(index, 1);
  },
  deleteExp: (state, expx_id) => {
    const index = state.experiences.findIndex((exp) => exp.id === expx_id);
    state.experiences.splice(index, 1);
  },
  deleteEducation: (state, education_id) => {
    const index = state.educations.findIndex(
      (education) => education.id === education_id
    );
    state.educations.splice(index, 1);
  },
  deleteInterrest: (state, interrest_id) => {
    const index = state.interrests.findIndex(
      (interrest) => interrest.id === interrest_id
    );
    state.interrests.splice(index, 1);
  },

  deleteMember: (state, member_id) => {
    const index = state.team.findIndex((member) => member.id === member_id);
    state.team.splice(index, 1);
  },

  logUserOut: (state) => {
    localStorage.removeItem('auth');
    state.user = null;
  },
  setRegister: (state, { property, value }) => {
    state.registerForm[property] = value;
  },
  setStartupOverviewForm: (state, { property, value }) => {
    state.startupOverviewForm[property] = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
