<template>
  <div :class="hasError ? 'error' : ''">
    <label :for="label"
      >{{ $t(label) }}
      <span class="error_msg" v-if="hasError"> {{ $t(error_msg) }}</span></label
    >

    <input
      class="s-input-2"
      v-on="$listeners"
      :type="typeName"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      :required="isRequired"
      @input="$emit('update', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Input2",
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    typeName: {
      type: String,
      default: "text",
    },
    error_msg: {
      type: String,
      default: " ",
    },
    placeholder: {
      type: String,
      default: " ",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
};
</script>

<style scoped>
.s-input-2 {
  width: 98%;
}
.error_msg {
  /* color: var(--error-color) !important; */
  /* background-color: #fe4c5f42; */
  color: var(--error-color);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 15px;
}
.input-div,
.startup-discription {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
}

input:focus {
  outline: none !important;
  border: 1px solid #39a251 !important;
}

.input-div label {
  font-size: 22px;
}

.input-div input {
  width: 98%;
  padding: 15px 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  text-align: left;

  /* border-radius: 15px; */
  border-radius: .28571429rem;
  height: 38px !important; 
  /* height: 36px !important; */
}
label {
  text-align: left !important;
}
@media only screen and (max-width: 600px) {
  label {
    text-align: left !important;
  }
  .input-div input {
    width: 100%;
    padding: 12px 8px !important;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
    height: 40px;
  }
  .input-div label {
    font-size: 18px;
    /* color: #717171; */
  }
}
</style>