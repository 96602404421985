<template>
  <div class="checkbox-toggle">
    <label :class="[value ? 'checked' : 'unchecked']" class="checkbox-slide">
      <input
        :id="id"
        :checked="value ? true : false"
        type="checkbox"
        @change="change"
      />
      <span v-if="value" class="checkbox-check">
        <img alt="" src="./../../assets/registration/icons/check.svg" />
      </span>
      <span
        :class="[value ? 'checked' : 'unchecked']"
        class="checkbox-switch"
      ></span>
    </label>
    <label :for="id" class="checkbox-label">{{ $t(label) }}</label>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  data: () => ({}),
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    id() {
      return "radio-btn-" + this._uid;
    },
  },
  methods: {
    change(e) {
      // alert(e.target.checked);
      this.$emit("input", e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-toggle {
  display: flex;
  align-items: center;
  position: relative;

  &:first-child {
    margin-right: 20px;
  }

  .checkbox-check {
    position: absolute;
    top: 0;
    left: 5px;
    bottom: 0;
    margin: auto 0;
    height: 18px;
    display: flex;
    align-items: center;

    img {
      width: 15px;
    }
  }

  .checkbox-slide {
    width: 40px;
    height: 20px;
    padding: 2px 3px;
    border: 1px solid #e2e2e2;
    border-radius: 25px !important;
    position: relative;
    margin: 0;

    input {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
    }
  }

  .checkbox-slide.unchecked {
    background: #d5d5d594;
  }

  .checkbox-slide.checked {
    background: #1eb65b;
  }

  .checkbox-switch {
    width: 14px;
    height: 14px;
    background: #ffffff;
    border-radius: 25px;
    display: block;
    position: relative;
  }

  .checkbox-switch.checked {
    transform: translateX(24px);
    background: #c4f8d4;
  }

  .checkbox-label {
    font: normal normal normal 15px/18px;
    color: #333333;
    position: relative;
    margin: 0 0 0 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) {
  .checkbox-check {
    position: absolute;
    top: 0;
    left: 11px;
    bottom: 0;
    margin: auto 0;
    height: 18px;
    display: flex;
    align-items: center;
  }

  .checkbox-slide {
    width: 75px !important;
    height: 28px !important;
    padding: 5px 7px !important;
  }

  .checkbox-switch {
    width: 16px !important;
    height: 16px !important;
  }

  .checkbox-switch.checked {
    transform: translateX(45px) !important;
  }
}

/* Medium devices (landscape tablets, 860px and up) */
@media only screen and (min-width: 860px) {
  .checkbox-toggle {
    &:first-child {
      margin-right: 90px;
    }

    .checkbox-label {
      font: normal normal normal 20px/24px;
    }
  }
}
</style>
