<template>
  <div>
    <div class="work-experience" v-if="!editing">
      <div class="experience-row exp-title">
        <div class="title">
          <div class="designation">{{ experience.title }}</div>
          <div class="type">{{ $t(experience.type) }}</div>
          <div class="control-buttons" v-if="showEditButtons">
            <edit-close-button-group class="justify-content-end" v-if="showEditButtons && !editing"
              @edit="edit(experience)" @remove="deleteExp(experience.id)" />
          </div>
        </div>

      </div>

      <div class="experience-row">
        <p class="company">{{ experience.company }}</p>
        <p class="location">
          <span v-if="experience.city"> {{ experience.city }} -</span>
          {{ fortmatCountry(experience.country) }}
        </p>
        <p class="period">
          {{ formatDate(experience.startDate) }}
          -
          <span v-if="experience.endDate === 'now'">
            {{ $t(experience.endDate) }}
          </span>
          <span v-else>
            {{ formatDate(experience.endDate) }}
          </span>
        </p>
      </div>
      <p v-if="experience.description" class="description">
        {{ experience.description }}
      </p>
    </div>

    <form @submit.prevent="updateExperience" class="work-experience form" v-if="editing">
      <div class="control">
        <label id="positionLabel" class="form-label">Position
          <span class="text-danger">*</span>
        </label>
        <input class="profile-controls" type="text" placeholder="Position*" v-model="form.title" required />
      </div>
      <div class="control">
        <label class="form-label">{{ $t("company") }}
          <span class="text-danger">*</span>
        </label>
        <input class="profile-controls" type="text" :placeholder="$t('company')" v-model="form.company" required />
      </div>

      <div class="control w-100">
        <label class="form-label">{{ $t("country") }}
          <span class="text-danger">*</span>
        </label>
        <country-select required v-model="form.country" :countryName="true" :autocomplete="true" :usei18n="true"
          :disablePlaceholder="true" :country="fortmatCountry(form.country)" class="profile-controls"
          :placeholder="$t('country')" />
      </div>
      <!-- <div class="control">
        <label class="form-label">{{ $t("city") }} </label>
        <input
          type="text"
          class="profile-controls"
          :placeholder="$t('city')"
          v-model="form.city"
        />
      </div> -->
      <div class="control">
        <label class="form-label">{{ $t("currentlyWorking") }} </label>
        <select class="custom-select my-1 mr-sm-2 profile-controls" v-model="form.currentlyWorking" required
          @change="setEndDate">
          <option value="" disabled>{{ $t("choose") }}...</option>
          <option value="1">{{ $t("yes") }}</option>
          <option value="0">{{ $t("no") }}</option>
        </select>
      </div>
      <div class="control">
        <label class="form-label">Type
          <span class="text-danger">*</span>
        </label>
        <select v-model="form.type" class="profile-controls">
          <option value="" disabled>{{ $t("Type") }} ...</option>
          <option value="CDI">CDI</option>
          <option value="CDD">CDD</option>
          <option value="FullTime">{{ $t("FullTime") }}</option>
          <option value="PartTime">{{ $t("PartTime") }}</option>
          <option value="Consultant">{{ $t("Consultant") }}</option>
          <option value="Internship">{{ $t("Internship") }}</option>
          <option value="Apprenticeship">
            {{ $t("Apprenticeship") }}
          </option>
          <option value="Other">{{ $t("Other") }}</option>
        </select>
      </div>
      <div class="control">
        <label class="form-label">{{ $t("from") }} </label>
        <Datepicker v-if="$i18n.locale === 'fr'" v-model="form.startDate" :language="fr" :clear-button="false"
          input-class="form-control  profile-controls" :required="true" :typeable="false" minimum-view="month"
          :format="customFormatter" :placeholder="$t('from')" />
        <Datepicker v-else v-model="form.startDate" :language="en" input-class="form-control profile-controls"
          :clear-button="false" :required="true" :typeable="false" minimum-view="month" :format="customFormatter"
          :placeholder="$t('from')" />
      </div>
      <div class="control">
        <label class="form-label">{{ $t("to") }}
          <span class="text-danger">*</span>
        </label>
        <input v-if="form.endDate === 'now'" type="text" disabled class="profile-controls" :placeholder="$t('to')"
          v-model="form.endDate" />
        <template v-else>
          <Datepicker v-if="$i18n.locale === 'fr'" v-model="form.endDate" :language="fr" :clear-button="false"
            input-class="form-control  profile-controls" :required="true" :typeable="false" minimum-view="month"
            :format="customFormatter" :placeholder="$t('to')" />
          <Datepicker v-else v-model="form.endDate" :language="en" input-class="form-control  profile-controls"
            :clear-button="false" :required="true" :typeable="false" minimum-view="month" :format="customFormatter"
            :placeholder="$t('to')" />
        </template>
      </div>

      <div class="w-100">
        <label class="form-label">Description
          <!-- <span class="text-danger">*</span> -->
        </label>
        <textarea class="profile-controls" rows="4" placeholder="Describe your position" name="description"
          v-model="form.description" spellcheck="true"></textarea>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <button :disabled="loading" type="submit" class="btn-save">
            {{ $t("save") }}
          </button>
          <button :disabled="loading" @click="cancel()" type="button" class="btn-cancel">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditCloseButtonGroup from "../buttons/EditCloseButtonGroup";
import LabeledSaveCancelButtonGroup from "../buttons/LabeledSaveCancelButtonGroup";
import i18n from "../../../../../i18n";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getUserSectorOfInterest",
      "getSectorstList",
    ]),
  },
  components: {
    EditCloseButtonGroup,
    //LabeledSaveCancelButtonGroup,
    Datepicker,
  },
  data() {
    return {
      editing: false,
      en: en,
      fr: fr,
      form: {
        type: "",
        currentlyWorking: "",
        startDate: "",
        endDate: "",
      },
      loading: false,
      selectedNow: false,
    };
  },
  props: {
    experience: Object,
  },
  methods: {
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date).format("MM/YYYY");
    },
    edit(experience) {
      this.form = experience;
     
      if (this.form.endDate === "now") {
        this.selectedNow = true;
        this.form.currentlyWorking = 1;
      }
      this.editing = true;
    },
    cancel: function () {
      this.editing = false;
    },
    ...mapActions(["loadExperiences"]),
    customFormatter(date) {
      return moment(date).format("MM/YYYY");
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      country = countries.getName(country, "en", { select: "official" });
      return country;
    },
    countryCode(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getAlpha2Code(country, "en"));
    },
    setEndDate() {
      if (this.form.currentlyWorking === "1") {
        this.selectedNow = true;
        this.form.endDate = "now";
      } else {
        if (!this.form.endDate) {
          this.form.endDate = "";
        }
        this.selectedNow = false;
      }
    },
    updateExperience() {
      this.loading = true;
      this.form.country = this.countryCode(this.form.country);
      this.$store
        .dispatch("updateExperience", {
          experience: this.form,
        })
        .then(async (success) => {
          await this.loadExperiences({ user_id: this.authUser.id });
          this.form = {};
          this.loading = false;
          this.editing = false;
          this.$noty.success(this.$i18n.t("experienceUpdated"));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    deleteExp(expx_id) {
      this.loading = true;
      this.$store
        .dispatch("deleteExperience", {
          expx_id,
        })
        .then(async (success) => {
          await this.loadExperiences({ user_id: this.authUser.id });
          this.$noty.success(this.$i18n.t("experienceUpdated"));
        })
        .catch((error) => {
          this.loading = false;
          this.$noty.error(error);
        });
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: none !important;
  border-bottom: 2px solid var(--first-color) !important;
  border-radius: 0rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}
</style>
<style lang="scss" scoped>
.work-experience {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(236, 97, 97, 0.2) !important;

  .experience-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;

    @include max-width(415) {
      /* background-color: green;
      flex-direction: column; */
      flex-wrap: wrap;
    }
  }
  & > p {
    padding: 5px 0;
  }

  &:last-child {
    border-bottom: none;

    @include max-width(991) {
      border-radius: 0 0 15px 15px;
    }
  }

  @include max-width(991) {
    justify-content: space-between;
    background-color: rgba(232, 232, 232, 0.38);
  }

  @include max-width(415) {
    padding: 10px 15px;
  }

  .exp-title{
    background: #fff;
    padding: 0px 5px;
  }
  .title{
    width: 100% !important;
    display: flex;
  }
  .designation {
    order: 1;
    width: 60%;
    font-size: 20px;
    font-weight: 700;
    color: #000;

    @include max-width(991) {
      width: 75%;
    }

    @include max-width(415) {
      font-size: 16px;
      /* width: 85%; */
      height: 40px;
      // background: #57b46624;
      display: flex;
      align-items: center;

       width: 75%;
    }
    @include max-width(320) {
      font-size: 15px;
    }
  }

  .type {
    order: 2;
    width: 30%;
    font-size: 14px;
    color: #727272;
    text-align: right !important;
    padding-right: 10px !important;
    float: right;

    @include max-width(991) {
      order: 5;
      width: 30%;
      text-align: right;
    }

    @include max-width(415) {
      font-size: 14px;
      /* width: 15%; */
      width: 30%;
      height: 40px;
      // background: #57b46624;
      display: flex;
      align-items: center;
      left: -30px !important;
    }
    @include max-width(320) {
      font-size: 12px;
    }
  }

  .control-buttons {
    order: 3;
    width: 25%;

    @include max-width(415) {
        display: flex;
        align-items: center;
      }
  }

  .company {
    order: 4;
    width: 44%;
    font-size: 19px;
    color: #000;
    @include max-width(991) {
      width: 70%;
    }

    @include max-width(415) {
      width: 60%;
      font-size: 16px;
    }
  }

  .location {
    order: 6;
    width: 28%;
    font-size: 15px;
    color: #727272;
    text-align: center !important;

    @include max-width(991) {
      width: 50%;
    }

    @include max-width(415) {
      width: 40%;
      text-align: right !important;
      font-size: 13px;
    }
    @include max-width(320) {
      font-size: 12px;
    }
  }

  .period {
    order: 7;
    width: 28%;
    font-size: 15px;
    color: #727272;
    text-align: right !important;

    @include max-width(991) {
      width: 50%;
      text-align: right;
    }

    @include max-width(415) {
      width: 100%;
      text-align: left !important;
      font-size: 13px;
    }
  }

  .description {
    order: 8;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    @include max-width(415) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.form {
    justify-content: space-between;
    font-size: 16px;

    @include max-width(500) {
      font-size: 14px;
    }

    & > div {
      padding: 6px 0;

      @include max-width(500) {
        padding: 3px 0;
      }
    }

    .control {
      width: 47.5%;
    }

    textarea {
      width: 100%;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 3px;
    }
  }

  @mixin btn-props {
    display: block;
    color: var(--green-color);
    font-size: 15px;
    background-color: #fff;
    padding: 5px 15px;
    border: 1px solid var(--green-color);
    border-radius: 15px;

    &:hover {
      background-color: var(--green-color);
      color: #fff;
    }
  }

  .btn-save {
    @include btn-props;
  }

  .btn-cancel {
    @include btn-props;
    margin-left: 10px;
    border: 1px solid var(--error-color);
    color: var(--error-color);

    &:hover {
      background-color: var(--error-color);
      color: #fff;
    }
  }
}
</style>
