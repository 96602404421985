<template>
  <div class="resourcesCard">
    <span class="header">
      <span class="title">Startup Resources</span>
    </span>
    <ul class="news">
      <li v-for="feed in defaultFeeds" :key="feed.id" class="news-item">
        <h1 class="news-title">{{ feed.name }}</h1>
        <a :href="feed.link" class="source" target="_blank">{{
          $t('readMore')
        }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  async mounted() {
    await this.loadDefaultFeeds();
    this.defaultFeeds = this.getDefaultFeeds;
  },
  computed: {
    ...mapGetters(['authUser', 'getDefaultFeeds']),
  },
  methods: {
    ...mapActions(['loadDefaultFeeds']),
  },
  data: function() {
    return {
      defaultFeeds: [],
    };
  },
  name: 'StarupNewsResources',
};
</script>
<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: white;
  padding: 20px 10px;
  padding-bottom: 200px;
}
.news-item {
  border-bottom: 1px solid rgba(228, 227, 227, 0.598);
  padding: 10px;
  padding-right: 40px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
h1 {
  font-size:  14px !important;
   color: rgba(0, 0, 0, 0.344);
   padding-left: 20px !important;
}

.source {
  background: #b7d9fb;
  padding: 5px 15px;
  border-radius: 3px;
  color: #1e90ff;
}
.header {
  padding: 20px 0;
  padding-bottom: 0;
}
.title {
  width: 100%;
  font-size: 22px !important;
  line-height: 28px;
  padding-left: 20px;
  color: rgb(121, 121, 121);
}
.resourcesCard {
}
@media (max-width: 1200px) {
  .news-item {
    flex-direction: column !important;
    align-items: flex-start;
    padding: 20px 5px;
  }
  .news-title {
    font-size: 18px !important;
    padding: 10px 0;
  }
  .source {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: center;
    /* background-color: transparent; */
    border-top: 1px solid rgba(228, 227, 227, 0.598);
    border-radius: 0;
    padding-top: 20px;
  }
}
@media (max-width: 576px) {
ul{ 
  padding-bottom: 200px;
}
 .news-title{
   font-size:  18px !important;
   line-height: 22px;
   color: rgba(0, 0, 0, 0.344);
 }
.source{
  margin-top: 10px;
}
}
</style>
