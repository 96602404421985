<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
};
</script>
<style scoped>
#app {
  background: #edeef0;
}
</style>
