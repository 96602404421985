<template>
  <div class="action-modal">
    <button @click="viewProfile">
      {{ $t("Profile") }}
    </button>
    <button @click="openReportModal">
      {{ $t("Report") }}
    </button>
    <button @click="blockThisUser" v-if="!getIsBlocked">
      {{ $t("Block") }}
    </button>
    <button
      @click="unBlock"
      v-if="getIsBlocked && getBlockedUser.from === authUser.id"
    >
      {{ $t("UnBlock") }}
    </button>
    <button @click="closeBubble">
      <p>{{ $t("Close this chat") }}</p>
    </button>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  computed: {
    ...mapGetters([
      "authUser",
      "getCurrentlyChattingWith",
      "getBlockedUser",
      "getIsBlocked",
    ]),
  },
  methods: {
    ...mapMutations([
      "openBlockModal",
      "closeBlockModal",
      "openReportModal",
      "closeBubble",
    ]),
    ...mapActions(["blockUser", "unblockUser"]),
    async blockThisUser() {
      const from = this.authUser.id;
      const to = this.getCurrentlyChattingWith.id;
      await this.blockUser({ from, to });
      this.$socket.emit("block_user", {
        from,
        to: this.getCurrentlyChattingWith.username,
      });
    },
    async unBlock() {
      const from = this.authUser.id;
      const to = this.getCurrentlyChattingWith.id;
      await this.unblockUser({ from: this.authUser.id, to });
      this.$socket.emit("un_block_user", {
        from,
        to: this.getCurrentlyChattingWith.username,
      });
    },
    viewProfile() {
      let url = `/${this.$i18n.locale}/profile/${this.getCurrentlyChattingWith.username}`;
      if (this.getCurrentlyChattingWith.type === 2) {
        url = `/${this.$i18n.locale}/startup/${this.getCurrentlyChattingWith.startup.slug}`;
      }
      window.location.href = url;
    },
  },
  name: "ActionModal",
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.action-modal {
  position: absolute;
  left: 0;
  top: 46px;
  width: 100%;
  height: 100vh;
  /* height: 205px; */
  /* padding: 10px 0; */
  /* border-radius: 12px; */
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #fff;
  color: #666666;
  z-index: 1000;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 8%), 0px 4px 4px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  animation: all 167ms cubic-bezier(0.4, 0, 1, 1);
}
.action-modal button {
  border: none;
  text-align: left;
  padding: 15px 5px;
  width: 100%;
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;
  padding-left: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  font-size: 15px;
  color: #504f4f;
}
.action-modal :hover {
  background-color: rgba(226, 226, 226, 0.637);
  color: #349e4d;
}
/* .action-modal button :hover {
  background-color: transparent;
  margin: none;
  border-radius: none;
} */
.action-modal button:focus {
  outline: none;
}
</style>