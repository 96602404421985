<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <ul class="tab-heads" v-bind="$attrs">
      <li
        class="tab-head"
        v-for="tab in tabs"
        :key="tab"
        :class="{
          'tab-head--active': activeTab === tab,
        }"
        @click="switchTab(tab)"
      >
        <slot :name="tabHeadSlotName(tab)"></slot>
      </li>
      <li class="add-button btn">
        <add-button v-if="showEditButtons" @add="add()" />
      </li>
    </ul>
    <div class="tab-panel" v-bind="$attrs">
      <slot :name="tabPanelSlotName" v-if="!adding"></slot>
      <component :is="addPanelSlotName" @cancel="cancel()" v-if="adding" />
    </div>
  </div>
</template>

<script>
import AddButton from "../buttons/AddButton";
import AddExperience from "./AddExperience";
import AddEducation from "./AddEducation";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../../../i18n";

export default {
  components: {
    AddButton,
    AddExperience,
    AddEducation,
  },
  props: {
    initialTab: String,
    tabs: Array,
  },
  data() {
    return {
      activeTab: this.initialTab,
      adding: false,
    };
  },

  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getUserSectorOfInterest",
      "getSectorstList",
    ]),
    tabPanelSlotName() {
      return `tab-panel-${this.activeTab}`;
    },
    addPanelSlotName() {
      return `add-${this.activeTab}`;
    },
  },
  methods: {
    tabHeadSlotName(tabName) {
      return `tab-head-${tabName}`;
    },
    switchTab(tabName) {
      this.activeTab = tabName;
    },
    add() {
      this.adding = true;
    },
    cancel() {
      this.adding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
ul li {
  list-style: none !important;
}

.add-button {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include max-width(991) {
    display: none;
  }
}
</style>
