<template>
  <div class="areaOfExpertise">
    <div class="searchContainer">
      <p class="search-header-title">
        {{ $t(searchTitle) }}
        <span class="msg_error" v-if="getErrorMsg('err_msg_exp_diasp')">{{
          $t(getErrorMsg("err_msg_exp_diasp"))
        }}</span>
        <span class="msg_error" v-else>{{ $t(max_msg) }}</span>
      </p>
      <input
        class="search-input"
        type="text"
        :placeholder="$t(searchPlaceHolder)"
        v-model="search"
      />
    </div>
    <div class="areaOfExpertiseListConainter">
      <ul id="ul-expertise" ref="areaOfExpertise">
        <li v-for="expertise in filteredExpertise" :key="expertise.id">
          <AreaofExpertise
            @click.native="selectExpertise(expertise)"
            :expertise="expertise.name"
            :checkIcon="checkIcon"
            :active="selectedExpertises.includes(expertise.talent_id)"
          />
        </li>
      </ul>
    </div>
    <SeeMoreButton :hasText="false" text="seeMore" @click.native="scrollDown" />
  </div>
</template>

<script>
import AreaofExpertise from "./ExpertiseCard.vue";
import SeeMoreButton from "./SeeMoreButton.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  async mounted() {
    let selectedExpertises = JSON.parse(localStorage.getItem("expertises"));
    if (selectedExpertises) {
      this.$store.commit("setFormData", {
        field: "expertises",
        value: selectedExpertises,
      });
      this.selectedExpertises = selectedExpertises;
    }
    this.initialHeight = this.$refs.areaOfExpertise.scrollHeight;
  },
  computed: {
    ...mapGetters(["getRegistrationForm", "getErrorMsg"]),
    filteredExpertise() {
      if (this.expertises.length) {
        return this.expertises.filter((expertise) => {
          return expertise.name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      } else {
        return this.expertises;
      }
    },
  },
  methods: {
    ...mapActions([
      "loadTalents",
      "loadCountriesList",
      "loadForeignCountriesList",
    ]),
    selectExpertise(expertise) {
      this.$store.commit("setErrorMsg", {
        field: "err_msg_exp_diasp",
        value: null,
      });
      let error = false;
      if (this.selectedExpertises.length >= 10) {
        this.max_msg = "You’ve now reach a maximum of 10.";
        error = true;
        this.selectedExpertises = this.selectedExpertises.filter((item) => {
          return item !== expertise.talent_id;
        });
      } else {
        error = false;
        this.$store.commit("setErrorMsg", {
          field: "err_msg_exp_diasp",
          value: null,
        });
        this.max_msg = "";
      }
      if (!error) {
        this.$store.commit("setErrorMsg", {
          field: "err_msg_exp_diasp",
          value: null,
        });
        if (this.selectedExpertises.includes(expertise.talent_id)) {
          if (this.selectedExpertises.length <= 10) {
            error = false;
            this.$store.commit("setErrorMsg", {
              field: "err_msg_exp_diasp",
              value: null,
            });
            this.max_msg = "";
          }
          this.selectedExpertises = this.selectedExpertises.filter((item) => {
            return item !== expertise.talent_id;
          });
        } else {
          this.selectedExpertises.push(expertise.talent_id);
        }
        this.$store.commit("setFormData", {
          field: "expertises",
          value: this.selectedExpertises,
        });
        localStorage.setItem(
          "expertises",
          JSON.stringify(this.selectedExpertises)
        );
      }
    },

    scrollDown() {
      var content = this.$refs.areaOfExpertise;
      if (content) {
        content.scrollTop = this.initialHeight * 2;
      }
      this.initialHeight = this.initialHeight * 2;
    },
  },
  name: "SelectExpertise",
  data: () => {
    return {
      initialHeight: 0,
      max_msg: "",
      defaultExp: [],
      selectedExpertises: [],
      search: "",
      checkIcon: require("../../assets/registration/icons/check_green.svg"),
      levelOfEducation: ["Bachelors", "Masters", "PhD", "Other"],
      yearsOfExperience: ["0-1", "2-4", "5-10", "10+"],
    };
  },
  components: {
    AreaofExpertise,
    SeeMoreButton,
  },
  props: {
    expertises: Array,
    searchTitle: {
      type: String,
      default: "",
    },
    err_msg: String,
    searchPlaceHolder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.msg_error {
  color: var(--error-color);
  font-size: 15px;
}
</style>
<style scoped>
.searchContainer {
  width: 100%;
  padding-bottom: 10px;
  text-align: left;
}
.search-input {
  height: 55px;

   height: 45px;
  border: 1px solid #ccc !important;
  width: 100% !important;
  padding: 12px 20px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  box-sizing: border-box;
}
input:focus {
  outline: none !important;
  border: 1px solid var(--first-color) !important;
}
.areaOfExpertise {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  align-items: flex-start;

  /* background: #00800011; */
  border-radius: 44px;
}
.areaOfExpertiseListConainter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 128, 0, 0.016);
  width: 100%;
  padding-left: 0;
}

#ul-expertise {
  /* display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 14.5px; */
  row-gap: 10px;
  width: 100%;
  align-items: center;
  height: 370px;

  height: 390px;
  overflow-y: auto;
  place-content: baseline;
  /* border-top: 1px solid rgba(0, 128, 0, 0.201);
  border-bottom: 1px solid rgba(0, 128, 0, 0.201); */
  padding: 10px 0;

  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  /* justify-content: center; */
}
#ul-expertise li {
  list-style-type: none;
  background-color: none;
  padding-right: 10px;
}

.searchContainer {
  width: 100%;
  padding-bottom: 10px;
}
/* .search-input {
  
} */
input:focus {
  outline: none !important;
  border: 1px solid #39a251 !important;
}
.search-header-title {
  text-align: left;
  font-size: 22px !important;
  /* color: #ff0000; */
  margin-bottom: 0.5rem;
  /* font-size: 18px !important; */
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .areaOfExpertise {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    align-items: flex-start;
    border-top: 0.5px solid #6161611a;
    padding-top: 20px;
  }
  .areaOfExpertiseListConainter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 128, 0, 0.016);
    width: 100%;
    padding-left: 0;
    padding-bottom: 60px;
  }
  #ul-expertise {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 13.5px;
    row-gap: 10px;
    width: 100%;
    align-items: flex-start;
    height: 390px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;

    /* height: auto;
    overflow-y: visible;

    overflow-y: visible !important;
    height: auto !important; */

    /* background-color: orange; */

    /* background: red; */
  }
  #ul-expertise li {
    list-style-type: none;
    /* background-color: blue; */
  }

  .searchContainer {
    width: 100%;
    padding-bottom: 10px;
  }
  .search-input {
    height: 45px;
    border: 1px solid #ccc !important;
    width: 100% !important;
    padding: 12px 20px;
    border-radius: 10px;
    box-sizing: border-box;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #39a251 !important;
  }

  .search-header-title {
    font-size: 18px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 320px) {
  #ul-expertise {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 8px;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    height: 290px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;
  }
  .search-header-title {
    font-size: 15px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
</style>