<template>
  <div
    class="personal container"
    :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }"
  >
    <div class="header">
      <h2>{{ $t("changePersonalSettings") }}</h2>
    </div>
    <div class="settings-container">
      <div class="settings">
        <div class="setting-items">
          <HeaderButton
            iconName="lock"
            :btnText="$t('ChangePassword')"
            :isActive="isCurrentTab(1)"
            @click.native="gotToTab(1)"
          ></HeaderButton>
          <HeaderButton
            iconName="envelope"
            :btnText="$t('ChangeEmail')"
            :isActive="isCurrentTab(2)"
            @click.native="gotToTab(2)"
          ></HeaderButton>
          <HeaderButton
            iconName="globe"
            :btnText="$t('ChangeLanguage')"
            :isActive="isCurrentTab(3)"
            @click.native="gotToTab(3)"
          ></HeaderButton>
          <!-- <HeaderButton
            iconName="bell"
            btnText="Notifications"
            :isActive="isCurrentTab(4)"
            @click.native="gotToTab(4)"
          ></HeaderButton> -->
        </div>

        <div class="setting-options-wrapper">
          <div class="setting-content" v-show="tab === 1">
            <ChangePass />
          </div>

          <div class="setting-content" v-show="tab === 2">
            <ChangeEmail />
          </div>

          <div class="setting-options" v-show="tab === 3">
            <ChangeLang />
          </div>

          <!-- <div class="notification-content" v-show="tab === 4">
            <h2 class="settingInerTitle">Notifications</h2>
            <DiasporaNotification />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import i18n from "../../i18n";
import HeaderButton from "../../components/HeaderButton.vue";
import ChangeEmail from "./ChangeEmail.vue";
import ChangePass from "./ChangePass.vue";
import DiasporaNotification from "./DiasporaNotification.vue";
import ChangeLang from "./ChangeLang.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
    //this.authUser;
  },

  async mounted() {
    this.form = {};
    let page = this.$route.params.page;
    if (page === "pass") this.setTab(1);
    else if (page === "email") this.setTab(2);
    else if (page === "lang") this.setTab(3);
    else if (page === "notifications") this.setTab(4);
    else this.setTab(1);
  },
  metaInfo() {
    return {
      title: "Bantaba | Change Password ",
    };
  },
  computed: {
    ...mapGetters(["authUser", "getProfile"]),
  },
  data() {
    return {
      tab: 1,
      form: {},
      viewPassword: "off",
      eyeIcon: "feather-eye",
      inputType: "password",
      error: "",
      loading: false,
      passMacth: false,
      macthText: null,
      emailMacth: false,
    };
  },
  methods: {
    setTab(index) {
      this.tab = index;
    },
    gotToTab(index) {
      let url = "";
      if (index === 1) {
        // url  =
        this.$router.push(
          `/${i18n.locale}/${this.$i18n.t("routes.changePassword")}/pass`
        );
      } else if (index === 2) {
        url = `/${i18n.locale}/${this.$i18n.t("routes.changePassword")}/email`;
        // this.$router.push(
        //   `/${i18n.locale}/${this.$i18n.t("routes.changePassword")}/email`
        // );
      } else if (index === 3) {
        url = `/${i18n.locale}/${this.$i18n.t("routes.changePassword")}/lang`;
        // this.$router.push(
        //   `/${i18n.locale}/${this.$i18n.t("routes.changePassword")}/lang`
        // );
      } else if (index === 4) {
        url = `/${i18n.locale}/${this.$i18n.t(
          "routes.changePassword"
        )}/notifications`;
        // this.$router.push(

        // );
      }
      // let url  = originalName
      if (this.$route.path != url) {
        this.$router.push(url);
      }
      // this.tab = index;
    },
    isCurrentTab(index) {
      return this.tab === index;
    },
  },
  name: "ChangePassword",
  components: {
    HeaderButton,
    ChangeEmail,
    ChangePass,
    //DiasporaNotification,
    ChangeLang,
  },
};
</script>
<style scoped>
.showNotVerifiedNotfication {
  padding-top: 40px;
}
.notification-content {
  width: 100% !important;
}
.setting-content {
  margin-bottom: 60px !important;
}

.btn-apply {
  display: block;
  background: #cfead9;
  width: 100% !important;
  padding: 0.5rem;
  color: #1ba54b;
  border-radius: 1rem;
  font-size: 0.75rem;
  text-align: center;
  text-decoration: none;
}
.btn-apply:hover {
  background: var(--first-color);
  color: #fff !important;
}
</style>

<style scoped>
.settingInerTitle {
  font-size: 20px !important;
  margin-bottom: 20px !important;
}
.languages-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 40px;
}
.setting-options h2 {
  font-weight: 500;
}

.settings-container {
  display: flex;
  justify-content: center;
}
.personal {
  position: relative;
  margin-top: 0px !important;
  height: 100% !important;
}
.personal .header h2 {
  position: absolute;
  top: 50px !important;
  color: black !important;
  font-weight: 500;
}

/* mobile */
.personal .header {
  background-color: #cfead9;
  height: 150px;
  display: flex;
  justify-content: center;
}

.setting-items {
  margin: 0 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings {
  position: relative;
  width: 98%;
  height: 100% !important;
  padding: 0;
  top: -50px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.61);
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.personal .header h2 {
  top: 40px !important;
  font-size: 25px;
}
.setting-options-wrapper {
  margin-top: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
}
.setting-options {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px !important;
  background-color: transparent !important;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .settings {
    width: 80%;
    padding: 20px 25px;
    top: -70px;
  }
  .personal .header h2 {
    top: 50px !important;
    font-size: 40px;
  }

  .setting-options {
    width: 85%;
  }

  .setting-items {
    margin: 0;
  }
}
@media only screen and (min-width: 992px) {
  .settings {
    background-color: rgba(255, 255, 255, 0.61);
    box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 20px 40px;
    width: 70%;
    position: relative;
    top: -70px;
  }

  .personal .header {
    background-color: #cfead9;
    height: 200px;
    display: flex;
    justify-content: center;
  }

  .setting-options {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    background-color: #ebeced;
    border-radius: 10px;
  }
  .setting-options-wrapper {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .setting-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 950px) {
  .form {
    width: 100% !important;
  }

  .form-header {
    margin: 0 0 20px;
  }
  .form-header h1 {
    padding: 4px 0;
    font-size: 20px;
  }

  .eye {
    left: 90% !important;
  }
}

@media screen and (max-width: 750px) {
  .form {
    width: 100% !important;
  }

  .form-header h1 {
    padding: 4px 0;
    font-size: 18px;
  }

  .eye {
    left: 85% !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .showNotVerifiedNotfication {
    margin-top: -60px !important;
  }

  .personal .header h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) and (min-width: 300px) {
  .showNotVerifiedNotfication {
    padding-top: 20px !important;
  }

  .personal .header h2 {
    font-size: 17px;
  }
}

@media screen and (max-width: 500px) {
  .form {
    width: 100% !important;
  }

  .form-header h1 {
    padding: 4px 0;
    font-size: 16px;
  }

  .eye {
    left: 85% !important;
  }
}
</style>
