<template>
  <div class="py-4">
    <div class="py-4 bg-white rounded" v-if="!loading">
      <div class="fluid-container">
        <div class="overflow-hidden px-5">
          <a
            class="font-weight-bold btn btn-primary rounded p-3"
            href="#"
            @click="showAddForm"
          >
            {{ $t("newMember") }}
          </a>
        </div>
        <div class="row box shadow-sm bg-white rounded p-5" v-if="showMembers">
          <div
            class="col-xl-3 col-lg-4 col-md-6 shadow-sm mb-4"
            v-for="(member, index) in getStartupTeam"
            :key="index"
          >
            <div class="m-1 box rounded bg-white profile-box shadow-sm">
              <div class="widget-author" id="user-view">
                <div class="author-heading">
                  <div class="cover-img">
                    <img :src="cover" alt="cover" />
                  </div>
                  <div class="profile-img">
                    <a href="#">
                      <img class="mx-5" :src="member.image" alt="" />
                    </a>
                  </div>
                  <div class="profile-name">
                    <h4 class="author-name">
                      <a href="#">{{ member.name }} </a>
                    </h4>
                    <div class="author-location">{{ member.position }}</div>
                  </div>
                  <p>
                    {{ member.description }}
                  </p>
                </div>
                <div class="network-item-footer py-4 d-flex text-center">
                  <div class="col-2 pr-1 pl-1 offset-sm-5">
                    <a
                      @click="deleteMember(member.id)"
                      href="#"
                      class="btn btn-danger btn-sm btn-block"
                    >
                      <i class="feather-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row box shadow-sm bg-white rounded p-5" v-else>
          <aside class="col-md-4">
            <UpdateAvatar :profileAavatar="form.image" :is_team="true" />
          </aside>
          <main class="col-md-8">
            <div class="border rounded bg-white mb-3">
              <div class="box-body p-3">
                <transition name="slide">
                  <form class="js-validate" @submit.prevent="createTeamMember">
                    <div class="row">
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">
                            {{ $t("name") }}
                            <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model="form.name"
                              :placeholder="$t('namePlaceholder')"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="usernameLabel" class="form-label">
                            Position
                            <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model="form.position"
                              :placeholder="$t('namePlaceholder')"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="BIO" class="form-label">
                            BIO
                            <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <textarea
                              class="form-control"
                              rows="5"
                              name="bio"
                              placeholder="Bio"
                              v-model="form.description"
                              required
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 text-right">
                        <button
                          :disabled="!getTeamTempImgStatus"
                          type="submit"
                          class="
                            font-weight-bold
                            btn btn-primary
                            rounded
                            p-2
                            m-2
                          "
                          href="#"
                        >
                          <i
                            class="fa fa-spin fa-1x fa-spinner"
                            v-if="btnLoading"
                          ></i>
                          {{ loading ? "" : $t("save") }}
                        </button>
                        <button
                          class="font-weight-bold btn btn-danger rounded p-2"
                          @click="cancel"
                        >
                          {{ $t("cancel") }}
                        </button>
                      </div>
                    </div>
                  </form>
                </transition>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Axios from "axios";
import APIURLS from "../../../../constant";
import i18n from "../../../../i18n";

export default {
  metaInfo() {
    return {
      title: "Bantaba | Team ",
    };
  },
  async mounted() {
    await this.loadStartup();
    this.startup = this.getStartup;
    let user_id = this.startup.user_id;
    await this.loadStartupTeam({ user_id });
    this.cover = this.getUserCover;
    this.loading = false;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartup",
      "getStartupTeam",
      "getUserCover",
      "getTeamTempImage",
      "getTeamTempImgStatus",
    ]),
  },
  data() {
    return {
      modalVisible: false,
      loadingPage: true,
      loaded: "",
      member: {},
      form: {},
      teams: [],
      loading: true,
      btnLoading: false,
      showMembers: true,
      cover: "",
    };
  },
  methods: {
    ...mapActions(["loadStartup", "loadStartupTeam"]),
    createTeamMember() {
     
      this.loading = true;
      this.form.startup_id = this.startup.id;
      this.form.image = this.getTeamTempImage.image;
      this.$store
        .dispatch("createTeamMember", { member: this.form })
        .then(async (success) => {
          this.$store.commit("setTeamTempImage", "");
          this.form = "";
          this.showMembers = true;
          let user_id = this.startup.user_id;
          await this.loadStartupTeam({ user_id });
          this.$noty.success(`${i18n.t("success")}`);
          this.loading = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    showAddForm() {
      this.showMembers = false;
    },
    cancel() {
      this.showMembers = true;
      this.form = "";
    },
    editMember(member) {
      
      this.showMembers = false;
      this.form.name = member.name;
      this.form.position = member.position;
      this.form.description = member.description;
      this.form.image = member.avatar;
    },

    deleteMember(member_id) {
      this.loading = true;
      this.$store
        .dispatch("deleteTeamMember", { member_id })
        .then((success) => {
          this.$noty.success(`${i18n.t("success")}`);
          this.loading = false;
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Team",
  components: {},
};
</script>

<style scoped>
.widget-author {
  text-align: center;
  padding: 15px;
  position: relative;
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

.widget-author .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.widget-author .cover-img img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100px;
}

.widget-author .profile-img {
  margin-bottom: 26px;
}

.widget-author .profile-img img {
  width: 150px;
  height: 150px;
  /* border: 2px solid red !important; */
}

.widget-author .profile-img a {
  border-radius: 50%;
  position: relative;
}

.widget-author .profile-img a img {
  box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.widget-author .author-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-author .author-name a {
  color: #000000;
}

.widget-author .author-name a:hover {
  color: #0f7f12;
}

.widget-author .author-location {
  font-size: 14px;
  color: #9e9faf;
  margin-bottom: 5px;
}

.widget-author .author-badge {
  margin-bottom: 45px;
}

.widget-author .author-badge li {
  display: inline-block;
  margin-right: 8px;
}

.widget-author .author-badge li:last-child {
  margin-right: 0;
}

.widget-author .author-badge a {
  font-size: 20px;
  color: #ffffff;
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  position: relative;
}

.widget-author .author-badge a:before {
  content: "";
  height: 5px;
  width: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 8px;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}

.widget-author .author-badge .profile-circle {
  background-color: #e7ecf4;
  font-size: 16px;
  font-weight: 700;
  color: #8f8f8f;
}

.widget-author .author-statistics li {
  display: inline-block;
  position: relative;
  line-height: 1;
}

.widget-author .author-statistics li:after {
  content: "";
  height: 31px;
  width: 1px;
  background-color: #e8e8e8;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-author .author-statistics li:last-child {
  margin-right: 0;
}

.widget-author .author-statistics li:last-child:after {
  display: none;
}

.widget-author .author-statistics li a {
  text-align: center;
  padding: 0 15px 0 12px;
}

.widget-author .author-statistics .item-number {
  font-weight: 400;
  color: #9e9faf;
  display: block;
  margin-bottom: 5px;
}

.widget-author .author-statistics .item-text {
  font-weight: 400;
  color: #9e9faf;
  font-size: 12px;
}

.special-input-class {
  background-color: black !important;
  color: white !important;
}
.special-highlight-class {
  font-weight: 900;
  color: #585656;
}

.connection {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
}
.connectionTitle {
  height: 25px !important;
}
</style>
