<template>
  <div class="page-card-header">
    <h2>{{ $t(title) }}</h2>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  props: {
    title: String,
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.page-card-header {
  background-color: red;
  width: 100%;
  height: 45px;
  background: rgba(190, 241, 218, 0.6);
  background: linear-gradient(90deg, #eafff2 0%, rgb(255, 255, 255) 100%);
  background: rgba(207, 234, 217, 0.43);
  display: flex;
  align-items: center;
  padding: 0px 20px;
  position: relative;
  font-weight: bolder;
  font-size: 20px;
  z-index: 10 !important;
  /* color: white; */
}

@media (max-width: 600px) {
  .page-card-header {
    width: 100%;
    height: 49px;
    /* background: #cfead9; */
    display: flex;
    font-size: 20px;
  }
}
@media (max-width: 320px) {
  .page-card-header {
    font-size: 18px;
  }
}
</style>