<template>
  <!-- Modal -->
  <div
    class="create-feed-modal"
    id="exampleModalCenter"
    style="background: #99a09c"
    tabindex="-1"
    role="dialog"
    v-show="isShow"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    @click.self="$emit('closeModal')"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-second"
      style="max-width: initial"
      role="document"
    >
      <div class="modal-content py-md-4 py-0 px-4 px-md-4 feed-modal">
        <div class="px-md-3 py-md-2 my-4 my-md-0" v-show="!loading">
          <div class="modal-header upload-image-modal-header p-0">
            <h1
              class="modal-title feed-modal-title pb-2 mb-1"
              id="exampleModalLongTitle"
            >
              {{ $t("uploadNow") }}
            </h1>
            <button
              type="button"
              class="cross-icon"
              data-dismiss="modal"
              aria-label="Close"
              @click="$emit('closeModal')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="cross-svg-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body mt-4 modal-custom-body">
            <div style="background: #f2fdf7; border-radius: 8px">
              <div class="">
                <div
                  :style="isActive === true ? 'background: #e0f8eb;' : ''"
                  @dragover="setActive"
                  @dragleave="cancelActive"
                  @drop.stop.prevent="onFileChang"
                  v-if="!showPreview"
                  class="text-center py-5 py-2"
                >
                  <div style="width: 20p">
                    <img
                      src="@/assets/images/plus.png"
                      alt=""
                      style="width: 70px"
                    />
                  </div>
                  <label class="mb-0 pt-1">
                    <h4
                      class="btn px-3 py-2 mt-3 btn-secondary"
                      style="background-color: #009743; font-size: 15px"
                    >
                      {{ $t("clickDragUploadImage") }}
                    </h4>
                    <input
                      type="file"
                      :multiple="false"
                      accept="image/gif,image/jpeg,image/jpg,image/png"
                      @change="onFileChang"
                      class="sr-only"
                    />
                  </label>
                  <h5
                    v-if="isImageUploadError === ''"
                    class="text-secondry mt-3 pt-1"
                    style="font-size: 14px; color: #5b615e"
                  >
                    {{ $t("youCanUploadUpto") }}
                  </h5>
                  <div v-else class="text-center mt-3 pt-1">
                    <img
                      src="@/assets/images/bang.png"
                      style="width: 23px"
                      alt=""
                    />
                    <h2 class="mt-2" style="color: #ec3f3a; font-size: 14px">
                      {{ isImageUploadError }}
                    </h2>
                  </div>
                </div>
                <div
                  v-if="showPreview && isPreview"
                  style="position: relative"
                  id="circle-crop"
                >
                  <VueCropper
                    ref="cropper"
                    :src="userAvatarBlob"
                    :viewMode="1"
                    :aspect-ratio="4 / 4"
                    drag-mode="move"
                    :view-mode="1"
                    :movable="true"
                    :crop-box-movable="false"
                    :crop-box-resizable="false"
                    alt="Profiel afbeelding"
                    :img-style="{ width: '450px', height: '350px' }"
                  />
                  <div class="d-flex justify-content-center">
                    <button class="btn d-flex px-4 drag-button">
                      <img
                        src="@/assets/images/drag-icon.png"
                        class="pr-2"
                        alt=""
                      />
                      {{ $t("dragImageToReposition") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showPreview"
            class="
              modal-foote
              pt-md-3
              mt-md-4 mt-5
              d-flex
              align-items-center
              justify-content-between
              upload-image-modal-footer
            "
          >
          <label>
            <span
              class="btn text-secondary px-3 upload-img"
              data-dismiss="modal"
            >
              {{ $t("changeImage") }}
            </span>
              <input
                type="file"
                :multiple="false"
                accept="image/gif,image/jpeg,image/jpg,image/png"
                @change="onFileChang"
                class="sr-only"
              />
            </label>
            <button
              type="button"
              class="btn publish-now px-3"
              data-dismiss="modal"
              @click="submit"
            >
              {{ $t("publishNow") }}
            </button>
          </div>
        </div>
        <div v-show="loading" class="py-md-5 my-5 my-md-0 text-center">
          <h4 style="font-size: 22px; font-weight: 700; color: black">
            {{ $t("youUploadIsInProgress") }}
          </h4>
          <div class="iCountUp">
            <ICountUp
              :startVal="startVal"
              :endVal="endVal"
              :decimals="decimals"
              :options="options"
            />
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" v-if="isProgressBar" viewBox="-1 -1 34 34">
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              class="progress-bar__background"
            />

            <circle
              cx="16"
              cy="16"
              r="15.9155"
              class="progress-bar__progress js-progress-bar"
            />
          </svg>
          <slot></slot>
          <div class="mt-5 pt-2 d-flex justify-content-center">
            <button
              class="btn btn-light progress-cancel-btn py-md-2" @click="$emit('closeModal')"
              style="
                color: black;
                background-color: transparent;
                border: 2px solid #eeefef;
                padding-right: 35px;
                padding-left: 35px;
              "
            >
              {{ $t("cancelUploadProgress") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import "./upload-modal.css";
import ICountUp from "vue-countup-v2";
import VueCropper from "vue-cropperjs";
export default {
  data() {
    return {
      isActive: false,
      isImageUploadError: "",
      file: "",
      userAvatarBlob: "",
      isPreview: true
    };
  },
  // watch: {
  //   userAvatarBlob() {
  //     this.makeRoundedCircle()
  //   }
  // },
  methods: {
    makeRoundedCircle() {
      console.log(this.$refs)
      let imgData = this.$refs.cropper.getImageData()
      this.$refs.cropper.setAspectRatio(1)
      this.$refs.cropper.setData({ width: (Math.round(80 / 100) * imgData.naturalWidth), height: (Math.round(80 / 100) * imgData.naturalWidth)})
    },
    cancelHandlers(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    setActive(e) {
      this.isActive = true;
      this.cancelHandlers(e);
    },
    cancelActive(e) {
      this.isActive = false;
      this.cancelHandlers(e);
    },
    submit() {
      const obj = {
        cropedImageData: this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL("image/jpeg", 0.9),
        file: this.file,
      };
      this.$emit("submit", obj);
    },
    onFileChang(e) {
      this.isPreview = false
      console.log("run");
      this.isActive = false;
      this.cancelHandlers(e);
      const wasDropped = e.dataTransfer;
      const files = wasDropped ? e.dataTransfer.files[0] : e.target.files[0];
      if (wasDropped && e.dataTransfer.files.length > 1) {
        this.isImageUploadError = this.$i18n.t("multipalFilesNotAllowed");
      } else if (wasDropped) {
        if (files.type.includes("image/")) {
          this.addFile(files);
        } else {
          this.isImageUploadError = this.$i18n.t("fileTypeNotAllowed");
        }
      } else {
        this.addFile(files);
      }
    },
    addFile(file) {
      this.isImageUploadError = "";
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 10) {
        this.isImageUploadError = this.$i18n.t("imageSizeIsMoreThan");
      } else {
        this.$emit("showPreviewTrue", true);
        console.log('true')
        this.file = file;
        this.userAvatarBlob = URL.createObjectURL(file);
        console.log(this.userAvatarBlob)
        setTimeout(() => {
          this.isPreview = true
        }, 10);
        // setTimeout(() => {
        //   this.makeRoundedCircle()
        // }, 100);
      }
    },
    changeImage() {
      this.$emit("showPreviewTrue", false);
      this.file = "";
      this.userAvatarBlob = "";
    },
  },
  props: {
    loading: {
      type: Boolean,
    },
    isShow: {
      type: Boolean,
    },
    showPreview: {
      type: Boolean,
    },
    startVal: {
      type: Number,
    },
    endVal: {
      type: Number,
    },
    decimals: {
      type: Number,
    },
    options: {
      type: Object,
    },
    isProgressBar: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ICountUp,
    VueCropper,
  },
};
</script>

<style lang="scss" scoped>
.drag-button {
  background-color: #011108;
  color: #ffffff;
  font-size: 14px;
  z-index: 500;
  margin-top: -60px;
  cursor: default;
  position: absolute;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.upload-image-modal-footer {
  border-top: 1px solid rgb(222 226 230);
}

$progress-bar-stroke-width: 3;
$progress-bar-size: 166px;

svg {
  height: $progress-bar-size;
  transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #c9e9d7;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #c9e9d7;
  stroke: #009743;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 3s ease-in-out;
}
.modal-trigger-heading {
  font-size: 20px;
}
.feed-modal-title {
  font-size: 20px;
  color: #011108;
  font-weight: 700 !important;
  font-family: poppins, sans-serif !important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.cross-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #5b615e;
}
.cross-svg-icon {
  width: 16px;
  height: 16px;
  color: #009743;
}
.create-feed-modal {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex;
  z-index: 15000 !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.feed-modal {
  border-radius: 20px;
  width: 645px;
}
.upload-img {
  background-color: transparent !important;
  border: 1px solid rgb(238 238 238);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}
.upload-img:hover {
  background-color: rgb(233, 230, 230) !important;
}
.publish-now {
  background-color: #009743;
  color: white;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}
.publish-now:hover {
  background-color: #09b657;
  color: white;
}
.modal-custom-body {
  padding: 0px !important;
}
.cropper-wrap-box {
  height: 100px !important;
}
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}
.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-line {
  display: none !important;
}
.cropper-view-box {
  outline: inherit !important;
}

.cropper-point.point-se {
  top: calc(85% + 1px);
  right: 14%;
}
.cropper-point.point-sw {
  top: calc(85% + 1px);
  left: 14%;
}
.cropper-point.point-nw {
  top: calc(15% - 5px);
  left: 14%;
}
.cropper-point.point-ne {
  top: calc(15% - 5px);
  right: 14%;
}
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
.upload-btn-row {
  width: 100% !important;
  padding-left: 0px;
}
.avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  border: 2px solid #fff;
  font-size: 45px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 5px solid green; */
  flex-direction: column;

  .camera {
    display: none;
  }

  input {
    display: none;
  }

  .edit-profile & {
    .camera {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(233, 233, 233, 0.81);

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
button.btn.upload-btn.btn-success {
  width: 200px;
  height: 45px;
}
button.btn.upload-btn.btn-danger {
  width: 200px;
  height: 45px;
}
.avatar-custom {
  position: relative;
  background: white !important;
  margin-bottom: -100px;
}
@media only screen and (max-width: 600px) {
  .avatar-custom {
    margin-bottom: 50px;
  }
  .drag-button {
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: center;
    width: 85%;
  }
  .progress-cancel-btn {
    width: 90%;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
  .upload-image-modal-header {
    border-bottom: none !important;
  }
  .upload-image-modal-footer {
    border-top: none;
  }
  #circle-crop {
    margin-top: -280px !important;
  }
  .modal-trigger-heading {
    padding-left: 8px;
    font-size: 15px;
  }
  .modal-second {
    width: 100%;
  }
  .upload-img {
    background-color: #f2fdf7;
  }
  .publish-now {
    width: 150px;
  }
  .feed-modal-title {
    font-size: 18px;
  }
  .publish-now-disable {
    width: 150px;
  }
  .feed-modal {
    border-radius: 15px;
    width: 100%;
  }
  button.btn.upload-btn.btn-success {
    width: 300px;
    height: 45px;
  }
  button.btn.upload-btn.btn-danger {
    width: 300px;
    height: 45px;
  }
}
</style>