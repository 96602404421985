<template>
  <a href="#" class="btn-connect" :class="`button-` + icon">
    <span class="btn-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 30 24"
        v-if="icon === 'connect'"
      >
        <path
          d="M29.25,9.75h-3v-3A.752.752,0,0,0,25.5,6H24a.752.752,0,0,0-.75.75v3h-3a.752.752,0,0,0-.75.75V12a.752.752,0,0,0,.75.75h3v3a.752.752,0,0,0,.75.75h1.5a.752.752,0,0,0,.75-.75v-3h3A.752.752,0,0,0,30,12V10.5A.752.752,0,0,0,29.25,9.75ZM10.5,12a6,6,0,1,0-6-6A6,6,0,0,0,10.5,12Zm4.2,1.5h-.783a8.16,8.16,0,0,1-6.834,0H6.3A6.3,6.3,0,0,0,0,19.8v1.95A2.251,2.251,0,0,0,2.25,24h16.5A2.251,2.251,0,0,0,21,21.75V19.8A6.3,6.3,0,0,0,14.7,13.5Z"
          fill="#1ca54c"
        />
      </svg>
      <svg class="svg-icon" viewBox="0 0 20 20" v-else-if="icon === 'sent'">
        <path
          fill="#777"
          d="M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#349E4D"
        class="bi bi-person-check-fill"
        viewBox="0 0 16 16"
        v-else-if="icon === 'received'"
      >
        <path
          fill-rule="evenodd"
          d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
        />
        <path
          d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
      </svg>
    </span>
    <span class="btn-label">{{ $t(text) }}</span>
  </a>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "connect",
    },
    icon: {
      type: String,
      default: "connect",
    },
  },
};
</script>

<style lang="scss" scoped>
.button-sent {
  border: 1px solid #777 !important;
  color: #777 !important;
  background: rgba(119, 119, 119, 0.04) !important;
}

.button-sent:hover {
  background: rgb(119, 119, 119) !important;
  color: #fff !important;
}
.btn-connect {
  display: flex;
  align-items: center;
  width: 140px;
  height: 34px;
  border: 2px solid var(--green-color);
  border-radius: 17px;
  padding-right: 10px;
  color: var(--green-color);
  font-size: 16px;

  &:hover {
    background: var(--green-color);
    color: #fff;
    border-color: rgba(0, 90, 31, 0.27);

    path {
      fill: #fff;
    }

    .btn-icon {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .btn-icon {
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.07);
  }

  .btn-label {
    flex-grow: 1;
    text-align: center;
  }
}
</style>
