<template>
  <div class="rounded m-3" v-if="talents.foundTalents">
    <div class="box-title border-bottom p-3 btn-primary rounded">
      <h6 class="m-0 text-center">{{ $t('relevantalents') }}</h6>
    </div>
    <div class="box shadow-sm mb-3 border rounded bg-white ads-box text-center">
      <div class="image-overlap-2 pt-4">
        <img
          src="https://res.cloudinary.com/bantaba/image/upload/v1622658273/platform/startup-1_cismls.png"
          class="img-fluid rounded-circle shadow-sm"
          alt="Responsive image"
        />
        <img
          src="https://res.cloudinary.com/bantaba/image/upload/v1622658235/platform/diaspora-1_w7fv9h.png"
          class="img-fluid rounded-circle shadow-sm"
          alt="Responsive image"
        />
      </div>
      <div class="p-3">
        <div class="small text-success">
          <i class="feather-check-circle"></i> {{ $t('itsAMatch') }}
        </div>
      </div>
    </div>
    <div v-for="(talent, index) in talents.result" :key="index">
      <div v-if="talent.talents.length">
        <div class="box-title border-bottom p-3 btn-secondary rounded">
          <h6 class="m-0 text-center">{{ talent.name }}</h6>
        </div>
        <div>
          <carousel :responsive="responsive" :dots="true" :nav="false">
            <ProfileCard
              class="mb-3 shadow-md"
              v-for="(member, key2) in talent.talents"
              :key="key2"
              :user="member.user"
              type="willingToSupport"
            />
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIURLS from '../../constant';
import i18n from '../../i18n';
import { mapGetters, mapActions } from 'vuex';
import Axios from 'axios';
export default {
  async mounted() {
    this.routeName = this.$route.name;
    this.cover = this.getUserCover;
    if (this.showConnectButton) await this.getStatus();
  },
  data() {
    return {
      routeName: '',
      status: '',
      cover: '',
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['authUser', 'getUserCover']),
  },
  methods: {},
  name: 'RelevantTalents',
  props: {
    users: {},
    talents: {},
  },
};
</script>
