<template>
  <div class="card">
    <div class="card-inner round-lg background-white">
      <div class="card-content">
        <h2 class="fw-bold article-title">
          {{ article.title }}
        </h2>
        <div class="card-buttons">
          <span class="badge-mute text-mute">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.12"
              height="17.105"
              viewBox="0 0 12.12 17.105"
            >
              <g
                id="Group_197"
                data-name="Group 197"
                transform="translate(-873.76 -490)"
              >
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M880.149,490c.214.027.429.043.641.082a5.984,5.984,0,0,1,4.693,3.827,5.709,5.709,0,0,1,.4,1.947,5.129,5.129,0,0,1-.324,1.863,17.74,17.74,0,0,1-1.44,3.069,52.77,52.77,0,0,1-3.533,5.329c-.245.33-.5.654-.751.987a.482.482,0,0,1-.049-.047,52.617,52.617,0,0,1-4.5-6.715,16.816,16.816,0,0,1-1.218-2.69,5.405,5.405,0,0,1,.569-4.705,5.9,5.9,0,0,1,4.145-2.855c.2-.037.4-.049.6-.074.041,0,.082-.013.123-.019Zm2.019,5.8a2.348,2.348,0,1,0-2.342,2.344A2.347,2.347,0,0,0,882.168,495.8Z"
                />
              </g>
            </svg>
            {{ fortmatCountry(article.country) }}
          </span>
        </div>
        <p class="article-para">
          {{ article.summary | str_limit(240) | striphtml }}
        </p>

        <div class="article-date-action">
          <div class="article-bottom-date">
            <svg
              class="article-bd-icon"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 395 395"
              style="enable-background: new 0 0 395 395"
              xml:space="preserve"
            >
              <g>
                <polygon
                  points="278.333,52.5 278.333,12.5 248.333,12.5 248.333,52.5 146.667,52.5 146.667,12.5 116.667,12.5 116.667,52.5 0,52.5 0,117.5 395,117.5 395,52.5"
                />
                <path
                  d="M0,382.5h395v-235H0V382.5z M262.5,195h50v50h-50V195z M262.5,285h50v50h-50V285z M172.5,195h50v50h-50V195z M172.5,285h50 v50h-50V285z M82.5,195h50v50h-50V195z M82.5,285h50v50h-50V285z"
                />
              </g>
            </svg>
            <span class="article-bd-text"
              ><timeago
                :datetime="article.publishedDate"
                :locale="$i18n.locale"
              ></timeago>
              | {{ readingTime(article.fullText) }} min read</span
            >
          </div>
          <a :href="`news/${article.slug}`" class="article-action-button">
            {{ $t("readMore") }}
            <svg
              class="aab-icon-image"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 476.213 476.213"
              style="enable-background: new 0 0 476.213 476.213"
              xml:space="preserve"
            >
              <polygon
                points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  methods: {
    readingTime(text) {
      let minutes = 0;
      const contentString = JSON.stringify(text);
      const words = contentString.split(" ").length;
      const wordsPerMinute = 200;
      minutes = Math.ceil(words / wordsPerMinute);
      return minutes;
    },
    fortmatCountry(country) {
      var countries = require("i18n-iso-countries");
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      return (country = countries.getName(country, i18n.locale, {
        select: "official",
      }));
    },
  },
  name: "NewsCard",
  props: {
    article: null,
  },
};
</script>

<style scoped>
/* Article Title & Para */
.article-title {
  text-align: left !important;
  color: #030303 !important;
  font-size: 18px !important;
  height: auto !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.article-para {
  height: 170px !important;
  padding: 10px 0px !important;
  font-weight: normal !important;
  text-align: left !important;
}
.card {
  background: white;
  border: 1px solid white !important;
  border-radius: 15px;
  box-shadow: 4px 4px 4px 4px #f5f5f7;
  border: 1px solid #9b9b9b;
  padding: 20px 15px;
  position: relative;
  text-align: center;
  transition: 0.5s;
}

.card:hover {
  border: 1px solid #1ba54a !important;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1) !important;
}
.card .badge {
  position: absolute;
  right: 20px;

  top: 20px;
}
.badge {
  padding: 7px 20px 7px 20px;
  border-radius: 8px;
}

.card-text {
  height: 120px !important;
  font-weight: normal !important;
  text-align: left !important;
}
.badge-success {
  background: #d4f2e7;
}
.badge-danger {
  background: #ffeee9;
}
.text-success {
  color: #00b074;
  font-weight: bold;
}

.round-lg {
  border-radius: calc(20) !important;
}

.text-danger {
  color: #fa582c;
  font-weight: bold;
}

.fill-success {
  fill: #00b074;
  stroke: #00b074;
}
.fill-danger {
  fill: #fa582c !important;
  stroke: #fa582c;
}
p {
  color: #4c4c4c;
  font-weight: bold;
  line-height: 25px;
}
.fw-bold {
  font-weight: bold !important;
}
.card .card-buttons {
  width: 100%;
}

.badge-mute {
  width: auto;
  display: inline-block;
  background: #9bcdf779;
  border: none;
  padding: 1px 8px;
  border-radius: 5px;
  font-size: 13px;
  margin-top: 15px;
  margin-bottom: 0px !important;
  font-weight: 600;
  max-width: 100% !important;
}

.text-mute {
  color: #01192cc4 !important;
}

.badge-mute svg {
  width: 7px;
  fill: #a0a0a0c0 !important;
  stroke: #a0a0a0 !important;
}
.badge-mute path {
  fill: #01192cc4 !important;
  stroke: #01192cc4 !important;
}

button {
  cursor: pointer;
}

.btn-success {
  background: #cfead9;
  padding: 0.5rem;
  color: #1ba54b;
  border-radius: 1rem;
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;
}

.btn-success svg {
  fill: #00b074 !important;
  stroke: #00b074 !important;
}

.aab-icon {
  fill: #a0a0a0 !important;
}

path {
  fill: #a0a0a0 !important;
}

.btn-success:hover {
  color: #fff;
  background: #1ba54a;
}
.text-center {
  text-align: center;
}
.btn-see-all {
  background: none;
  border: none;
  font-size: 17px;
}

/* Article Location & Action */
.article-location-action,
.article-date-action {
  padding-top: 10px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card.card-main .article-location-action,
.card.card-main .article-date-action {
  padding-top: 40px;
}

.article-location,
.article-bottom-date {
  display: flex;
  align-items: center;
}

.card:not(.card-main) .article-location {
  margin: 10px 0;
}

.article-location-icon,
.article-bd-icon {
  margin-right: 4px;
  fill: #a0a0a0 !important;
  width: 13px;
  height: auto;
}
.article-date-action path {
  fill: #a0a0a0 !important;
  stroke: #a0a0a0 !important;
}

.article-location-text,
.article-bd-text {
  color: #a0a0a0 !important;
  font-size: 13px;
}

.article-bd-text {
  padding-top: 10px;
}

.article-bottom-date svg {
  padding-top: 5px;
}
.article-action-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 14px;
  color: #1ba54b !important;
  font-size: 14px !important;
  text-decoration: none;
  background-color: #cfead9;
  border-radius: 50px;
  transition: 0.4s ease;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.article-action-button.green,
.article-action-button:hover,
.article-action-button:active {
  color: #fff !important;
  background-color: var(--first-color);
}

.article-action-button .aab-icon-image {
  margin-left: 11px !important;
  width: 11px !important;
  height: auto;
  transition: 0.4s ease;
}

.article-action-button:not(.green):not(.article-action-button:active):not(.article-action-button:hover)
  .aab-icon-image {
  fill: #a0a0a0 !important;
}
.article-action-button.green .aab-icon-image,
.article-action-button:hover .aab-icon-image,
.article-action-button:active .aab-icon-image {
  fill: #fff;
}

@media (max-width: 600px) {
  .card {
    padding: 60px 20px 40px 20px !important;
  }
  .card .badge {
    right: 12px;
    top: 15px;
  }
  .badge {
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
  }
  .card-footer-btn {
    width: 100%;
    border-radius: 10px;
  }
  .cards-grid {
    grid-gap: 2rem;
  }
}
@media (min-width: 600px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
