<template>
  <div class="d-flex">
    <a class="btn-save" @click.prevent="save()">{{ $t("save") }}</a>
    <a class="btn-cancel" @click.prevent="cancel()">{{ $t("cancel") }}</a>
  </div>
</template>

<script>
export default {
  methods: {
    save: function () {
      this.$emit("save");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin btn-props {
  display: block;
  color: var(--green-color);
  font-size: 15px;
  background-color: #fff;
  padding: 5px 15px;
  border: 1px solid var(--green-color);
  border-radius: 15px;

  &:hover {
    background-color: var(--green-color);
    color: #fff;
  }
}

.btn-save {
  @include btn-props;
}

.btn-cancel {
  @include btn-props;
  margin-left: 10px;
  border: 1px solid var(--error-color);
  color: var(--error-color);

  &:hover {
    background-color: var(--error-color);
    color: #fff;
  }
}
</style>
