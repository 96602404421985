<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="interests-header" v-if="getUserSectorOfInterest.length || showEditButtons">
      <span class="title">{{ $t("InterestedInStartupsWithin") }}</span>
      <labeled-edit-button @click.native="edit()" v-if="showEditButtons && !editing" />
      <save-cancel-button-group @save="save" @cancel="cancelEdit()" v-if="editing" />
    </div>
    <div class="interests-body" ref="startup">
      <div class="show-wrapper d-flex flex-wrap" v-if="!adding">
        <div v-for="(sector, index) in getUserSectorOfInterest" :key="index" class="tag-wrapper">
          <tag v-if="getProfileEditingMode && showEditButtons && editing" :name="sector.sectorList_name"
            :isEditMode="showEditButtons && editing"
            @click.native="deleteSector(sector.usersInterestsSectors_sector_id)" />
          <tag v-else :name="sector.sectorList_name" />
        </div>
        <div class="tag-wrapper" v-if="editing">
          <add-more-button @click.native="addMore()" />
        </div>
        <p v-if="showEditButtons && getUserSectorOfInterest.length <= 0 && !editing">
          {{$t("addInterestedInStartupsWithin")}}</p>
      </div>
      <div class="add-wrapper" v-if="adding">
        <select name="startup" class="w-100 profile-controls" v-model="sector">
          <option v-for="(sector, index) in getSectorstList" :key="index" :value="sector.id" :selected="index === 0">
            {{ sector.name }}
          </option>
        </select>
        <div class="w-100">
          <labeled-save-cancel-button-group class="justify-content-end" @save="createSector" @cancel="cancelAdd()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from "../main/Tag";
import AddMoreButton from "../buttons/AddMoreButton";
import LabeledEditButton from "../buttons/LabeledEditButton";
import SaveCancelButtonGroup from "../buttons/SaveCancelButtonGroup";
import LabeledSaveCancelButtonGroup from "../buttons/LabeledSaveCancelButtonGroup";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../../../i18n";
export default {
  async mounted() {
    this.user_id = this.getProfile.id;
    var lang = this.getUserLang;
    await this.loadSectorsList({ lang });
    await this.loadUserSectorsOfInterest({ user_id: this.user_id, lang });
    this.sector = this.getSectorstList[0].id;
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "showEditButtons",
      "getUserSectorOfInterest",
      "getSectorstList",
      "getProfileEditingMode",
      "getUserLang",
    ]),
  },
  props: {
    tags: Array,
  },
  components: {
    Tag,
    AddMoreButton,
    LabeledEditButton,
    SaveCancelButtonGroup,
    LabeledSaveCancelButtonGroup,
  },
  data() {
    return {
      editing: false,
      adding: false,
      sectors: [],
      sector: 0,
    };
  },
  watch: {
    editing: function (newValue) {
      if (!newValue) this.adding = false;
    },
  },
  methods: {
    ...mapActions([
      "loadProfile",
      "updateBasicInfo",
      "loadSectorsList",
      "loadUserSectorsOfInterest",
      "createUserSectorOfInterest",
      "deleteUserSector",
      "loadUserExpertises",
    ]),
    createSector() {
      if (this.sector) {
        let user_id = this.authUser.id;
        let sector_id = this.sector;
        this.$store
          .dispatch("createUserSectorOfInterest", {
            user_id,
            sector_id,
          })
          .then(async () => {
            await this.loadUserSectorsOfInterest({
              user_id,
              lang: i18n.locale,
            });
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
            this.adding = true;
          })
          .catch((error) => {
            this.$noty.error(error);
          });
      }
    },
    async deleteSector(sector_id) {
      if (this.getUserSectorOfInterest.length <= 1) {
        this.$noty.info(this.$i18n.t("sectionCantBeEmpty"));
      } else { 
        let user_id = this.authUser.id;
        this.$store
          .dispatch("deleteUserSector", { user_id, sector_id })
          .then(async () => {
            await this.loadUserSectorsOfInterest({
              user_id,
              lang: i18n.locale,
            });
            // this.$forceUpdate();
            this.$noty.success(this.$i18n.t("success"));
            this.editing = false;
            this.adding = false;
          })
          .catch((error) => {
            console.error({ error });
            this.$noty.error(error);
          });
      }
    },
    save: function () {
      this.editing = false;
      this.$refs.startup.classList.remove("editing");
    },
    addMore: function () {
      this.adding = true;
    },
    cancelAdd: function () {
      this.adding = false;
    },
    edit: function () {
      this.editing = true;
      this.$refs.startup.classList.add("editing");
    },
    cancelEdit: function () {
      this.editing = false;
      this.$refs.startup.classList.remove("editing");
    },
  },
};
</script>

<style scoped>
.profile-controls {
  width: 100% !important;
  height: 100% !important;
  font-size: var(--normal-font-size) !important;
  border: none !important;
  border-bottom: 2px solid var(--first-color) !important;
  border-radius: 0rem !important;
  outline: none !important;
  padding: 0.3rem !important;
}
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
select:focus {
  outline: none !important;
}

select {
  appearance: none !important;
  -webkit-appearance: none !important;
}
:focus {
  outline: none !important;
}

.tag {
  color: rgba(0, 0, 0, .69) !important;
  border: 1px solid rgba(0, 0, 0, .15) !important;
  box-shadow: none !important;
}

.editBorder {
  color: #c31e3e !important;
  border: 1px solid #c31e3e !important;
}
</style>

