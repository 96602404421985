<template>
  <div class="areaOfExpertise" v-if="getShowTypes">
    <div class="searchContainer">
      <label class="search-header-title">
        {{ $t(searchTitle) }}
        <!-- <span class="msg_error">{{ err_msg ? err_msg : max_msg }}</span> -->
        <span class="msg_error" v-if="getErrorMsg('err_msg_sectorOfInterest')"
          >:{{ $t(getErrorMsg("err_msg_sectorOfInterest")) }}</span
        >
      </label>
      <input
        class="search-input"
        type="text"
        :placeholder="$t(searchPlaceHolder)"
        v-model="search"
      />
    </div>
    <div class="areaOfExpertiseListConainter">
      <ul id="ul-expertise" ref="areaOfExpertise">
        <li v-for="sector in filteredSectors" :key="sector.id">
          <AreaofExpertise
            @click.native="selectSector(sector)"
            v-if="getAccountType === 'Diaspora'"
            :expertise="sector.name"
            :checkIcon="checkIcon"
            :active="selectedSectors.includes(sector.sector_id)"
          />
          <AreaofExpertise
            v-if="getAccountType === 'Startup'"
            @click.native="selectStartupSector(sector)"
            :expertise="sector.name"
            :checkIcon="checkIcon"
            :active="getFordataField('sector') === sector.code"
          />
        </li>
      </ul>
    </div>
    <SeeMoreButton :hasText="false" text="seeMore" @click.native="scrollDown" />
  </div>
</template>

<script>
import AreaofExpertise from "./ExpertiseCard.vue";
import SeeMoreButton from "./SeeMoreButton.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
export default {
  async mounted() {
    if (this.getAccountType === "Diaspora") {
      let selectedSectors = JSON.parse(
        localStorage.getItem("sectorOfInterrest")
      );
      if (selectedSectors) {
        this.$store.commit("setFormData", {
          field: "sectorOfInterrest",
          value: selectedSectors,
        });
        this.selectedSectors = selectedSectors;
      }
    } else {
      let sector = localStorage.getItem("sector");
      if (sector) {
        this.$store.commit("setFormData", {
          field: "sector",
          value: sector,
        });
        this.sector = sector;
      }
    }

    this.initialHeight = this.$refs.areaOfExpertise.scrollHeight;
  },
  computed: {
    ...mapGetters([
      "getAccountType",
      "getRegistrationForm",
      "getSectorstList",
      "getShowTypes",
      "getSector_err",
      "getFordataField",
      "getErrorMsg",
    ]),
    filteredSectors() {
      if (this.sectors.length) {
        return this.sectors.filter((item) => {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        });
      } else {
        return this.sectors;
      }
    },
  },
  methods: {
    ...mapActions(["loadTalents"]),
    selectSector(sector) {
      this.$store.commit("setErrorMsg", {
        field: "err_msg_sectorOfInterest",
        value: null,
      });
      // this.err_msg = "";
      //let error = false;
      //this.$store.commit("setSector_err", false);
      if (this.selectedSectors.length >= 5) {
        this.$store.commit("setErrorMsg", {
          field: "err_msg_sectorOfInterest",
          value: "You’ve now reach a maximum of 5.",
        });
        this.selectedSectors = this.selectedSectors.filter((item) => {
          return item !== sector.sector_id;
        });

        if (this.selectedSectors.includes(sector.sector_id)) {
          this.selectedSectors = this.selectedSectors.filter((item) => {
            return item !== sector.sector_id;
          });
        }
      } else {
        if (this.selectedSectors.includes(sector.sector_id)) {
          this.selectedSectors = this.selectedSectors.filter((item) => {
            return item !== sector.sector_id;
          });
          this.$store.commit("setErrorMsg", {
            field: "err_msg_sectorOfInterest",
            value: null,
          });
        } else {
          this.selectedSectors.push(sector.sector_id);
        }
        //this.$store.commit("setSector_err", false);
        this.$store.commit("setErrorMsg", {
          field: "err_msg_sectorOfInterest",
          value: null,
        });
      }

      this.$store.commit("setFormData", {
        field: "sectorOfInterrest",
        value: this.selectedSectors,
      });
      localStorage.setItem(
        "sectorOfInterrest",
        JSON.stringify(this.selectedSectors)
      );
      // if (!this.getSector_err) {
      //   this.max_msg = "";

      // }
    },
    async selectStartupSector(sector) {
      //this.err_msg = "";
      localStorage.setItem("sector", sector.code);
      this.$store.commit("setFormData", {
        field: "sector",
        value: sector.code,
      });
    },
    scrollDown() {
      var content = this.$refs.areaOfExpertise;
      if (content) {
        content.scrollTop = this.initialHeight * 2;
      }
      this.initialHeight = this.initialHeight * 2;
    },
  },
  name: "SelectInterest",
  data: () => {
    return {
      initialHeight: 0,
      max_msg: "",
      selectedSectors: [],
      sector: "",
      search: "",
      checkIcon: require("@/assets/registration/icons/check_green.svg"),
    };
  },
  components: {
    AreaofExpertise,
    SeeMoreButton,
  },
  props: {
    sectors: Array,
    searchTitle: {
      type: String,
      default: "",
    },
    err_msg: String,
    searchPlaceHolder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.msg_error {
  color: var(--error-color);
  font-size: 15px;
}
</style>
<style scoped>
.searchContainer {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.search-input {
  /* height: 55px; */

  height: 40px;
  border: 1px solid #ccc !important;
  width: 50% !important;
  padding: 12px 20px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
}

input:focus {
  outline: none !important;
  border: 1px solid var(--first-color) !important;
}
.areaOfExpertise {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  align-items: flex-start;

  /* background: #00800011; */
  border-radius: 44px;
}
.areaOfExpertiseListConainter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 128, 0, 0.016);
  width: 100%;
  padding-left: 0;
}

#ul-expertise {
  /* display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 14.5px; */
  row-gap: 10px;
  width: 100%;
  align-items: center;
  height: 370px;

  height: 390px;
  overflow-y: auto;
  /* border-top: 1px solid rgba(0, 128, 0, 0.201);
  border-bottom: 1px solid rgba(0, 128, 0, 0.201); */
  padding: 10px 0;
  place-content: baseline;

  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  /* justify-content: center; */
}
#ul-expertise li {
  list-style-type: none;
  background-color: none;
  padding-right: 10px;
}

.searchContainer {
  width: 100%;
  padding-bottom: 10px;
}
.search-input {
  height: 40px;
  border: 1px solid #ccc !important;
  width: 100% !important;
  padding: 12px 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
input:focus {
  outline: none !important;
  border: 1px solid #39a251 !important;
}

.search-header-title {
  /* text-align: left;
  font-size: 18px !important;
  font-weight: normal !important; */
  /* color: #8B90AA; */
  text-align: left;
  font-size: 22px !important;
  font-weight: normal !important;
  margin-bottom: 0.5rem;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .areaOfExpertise {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    align-items: flex-start;
  }
  .areaOfExpertiseListConainter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 128, 0, 0.016);
    width: 100%;
    padding-left: 0;
    padding-bottom: 60px;
  }
  #ul-expertise {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 13.5px;
    row-gap: 10px;
    width: 100%;
    align-items: center;
    height: 340px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;

    /* overflow-y: visible !important;
    height: auto !important; */
  }
  #ul-expertise li {
    list-style-type: none;
    /* background-color: blue; */
  }

  .searchContainer {
    width: 100%;
    padding-bottom: 10px;
  }
  .search-input {
    height: 40px;
    border: 1px solid #ccc !important;
    width: 100%;
    padding: 12px 20px;
    border-radius: 24px;
    box-sizing: border-box;
    border-radius: 10px !important;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #39a251 !important;
  }

  .search-header-title {
    font-size: 18px !important;
    /* color: #717171; */
  }
}

@media only screen and (max-width: 320px) {
  #ul-expertise {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 8px;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    height: 340px;
    overflow-y: auto;
    padding: 10px 0;
    place-content: baseline;
  }
  .search-header-title {
    font-size: 15px !important;
    /* color: #717171; */
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
@media only screen and (max-width: 120px) {
}
</style>