<template>
  <div v-bind="$attrs">
    <template v-if="getProfileEditingMode">
      <labeled-edit-button
        v-if="!showEditButtons"
        text="Edit Mode"
        @click.native="viewEditButtons"
      />
      <labeled-edit-button
        v-else
        text="Exit Edit Mode"
        @click.native="hideEditButtons"
      />
    </template>

    <template v-else>
      <chat-button
        v-if="authUser.type === 2"
        @click.native="openChatBoxWithUser(user)"
      />
      <template v-else-if="authUser.type === 1">
        <connect-button
          @click.native="connect(getProfile)"
          v-if="getConnectionStatus === ''"
        />
        <chat-button
          v-else-if="getConnectionStatus === 'connected'"
          @click.native="openChatBoxWithUser(user)"
        />
        <connect-button
          text="sent"
          icon="sent"
          v-if="getConnectionStatus === 'sent'"

        />
        <connect-button
          text="accept"
          icon="received"
          v-if="getConnectionStatus === 'received'"
          @click.native="accept(getProfile)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import ChatButton from "./ChatButton";
import ConnectButton from "./ConnectButton";
import { mapGetters, mapActions } from "vuex";
import LabeledEditButton from "../buttons/LabeledEditButton";
import Axios from "axios";
import APIURLS from "../../../../../constant";
import { io } from "socket.io-client";
export default {
  props: {
    showConnectButton: Boolean,
    user: Object,
    status: String,
  },
  components: {
    ChatButton,
    ConnectButton,
    LabeledEditButton,
  },
  async mounted() {
    if (this.authUser.type === 1) {
      await this.getStatus();
    }
    this.sockets = io(APIURLS.CHATLINK);
    this.sockets.on("request", async (data) => {
      if (data.user_id === this.authUser.id) {
        await this.getStatus();
        await this.getNotifs();
      }
    });
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getProfile",
      "getConnectionStatus",
      "getProfileSections",
      "getUserSupportTypes",
      "getProfileEditingMode",
      "showEditButtons",
      "getUserLang",
      "getUserNotifications",
      "getUserTalents"
    ]),
  },
  data() {
    return {
      sockets: {},
    };
  },
  methods: {
    ...mapActions([
      "openChatBoxWithUser",
      "sendConnectionRequest",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "loadUserNotifications",
      "getUserById",
      "loadUserExpertises"
    ]),
    async getNotifs() {
      let type = "new";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.$store.commit("setUserNewNotifications", this.getUserNotifications);
    },
    viewEditButtons() {
      this.$store.commit("setShowEditButtons", true);
    },
    hideEditButtons() {
      this.$store.commit("setShowEditButtons", false);
    },
    edit: function () {
      this.editing = !this.editing;
    },
    cancel: function () {
      this.editing = false;
    },
    async getStatus() {
      Axios.get(
        APIURLS.CONNECTIONS +
          "connected/user/" +
          this.getProfile.id +
          "/auth_user/" +
          this.authUser.id
      )
        .then((response) => {
          console.log({ response });
          this.$store.commit("setConnectionStatus", {
            status: response.data,
          });
          //this.connectionStatus = response.data;
         
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async connect(user) {
      let user_id = user.id;
      let countryOfOrigin, livesIn;
      let lang = this.getUserLang;
      let originCode = this.authUser.profile
        ? this.authUser.profile.birthCountry
        : "";
      countryOfOrigin = await this.getAFricanCountryByLang({
        code: originCode,
        lang,
      });
      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";
      livesIn = await this.getForeignCountryByLang({ code: livesInCode, lang });
      let expertise = '';
      let accept_link = `/${this.$i18n.locale}/${this.$i18n.t('routes.network')}/${this.$i18n.t('routes.pendingRequests')}`;
      let profile_link = `/${this.getUserLang}/profile/${this.authUser.username}`;

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });


      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }

      let data = {
        from: this.authUser.id,
        to: user_id,
        name: this.authUser.firstname
          ? this.authUser.firstname
          : "" + " " + this.authUser.name,
        receiver_name: user.firstname,
        email: user.email,
        lang,
        countryOfOrigin,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        livesIn,
        expertise,
        profile_link,
        accept_link
      };
      this.sendConnectionRequest({ data })
        .then(async () => {
          await this.getStatus();
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          console.error({ error });
          this.$noty.error(error);
        });
    },
    async accept(user) {
      let user_id = user.id;
      let expertise = '';
      let lang = this.getUserLang;

      let livesInCode = this.authUser.profile
        ? this.authUser.profile.residenceContry
        : "";
      let livesIn = await this.getForeignCountryByLang({ code: livesInCode, lang });

      await this.loadUserExpertises({
        lang,
        user_id: this.authUser.id,
      });


      let talents = this.getUserTalents;
      if (talents.length && talents[0].talents_name) {
        expertise = talents[0].talents_name;
      }

      let data = {
        from: user_id,
        to: this.authUser.id,
        sender_short_name: user.firstname,
        receiver_short_name: this.authUser.firstname,
        full_name: this.authUser.firstname
          ? this.authUser.firstname + " " + this.authUser.name
          : this.authUser.name,
        email: user.email,
        lang,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
        expertise,
        location: livesIn,
        link: APIURLS.SITELINK
      };
      Axios.post(`${APIURLS.CONNECTIONS}accept`, data)
        .then(async ({ data }) => {
          await this.getStatus();
          Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
          this.$noty.success("Success");
        })
        .catch((error) => {
          this.$noty.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
