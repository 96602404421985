<template>
  <div class="sent-status">
    <i class="feather-check-circle" aria-hidden="true"></i>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "SentStatus",
  computed: {
    ...mapGetters([
      "authUser",
      "getCurrentlyChattingWith",
      "getIsBlocked",
      "getBlockedUser",
      "getIsNewChatConvo",
    ]),
  },
  methods: {},
};
</script>

<style scoped>
.sent-status i {
  position: relative;
  top: 25px;
  bottom: 0px;
  right: 0px;
  color: #bcc0c4 !important;
}
</style>