<template>
  <div class="flex alert-bar" :style="checkZIndexFromVueX">
    <div class="flex space-justify alert-container">
      <div class="flex left-align left-content">
        {{ text }}
        <span class="showonDesktop"> {{ subText }}</span>
      </div>
      <div class="right-content">
        <button class="alert-action-button" @click="showToast">
          {{ buttonText }}
        </button>
      </div>
    </div>
    <Toast :class="{ showToast: isShowToast, hideToast: !isShowToast }" />
  </div>
</template>
<script>
import Toast from "./Toast.vue";
import store from '../../store'
export default {
  name: "AlertBar",
  props: {
    text: String,
    buttonText: String,
    subText: String,
  },
  data() {
    return {
      isShowToast: false,
    };
  },
  components: {
    Toast,
  },
  watch: {
    modalState() {
      console.log(this.modalState)
    }
  },
  methods: {
    showToast() {
      if (!this.isShowToast) {
        this.isShowToast = true;
      }
      setTimeout(() => {
        this.isShowToast = false;
      }, 5000);
    },
  },
  computed: {
    checkZIndexFromVueX() {
      if (this.modalState === true) {
        return 'z-index: 0 !important;'
      } else {
        return 'z-index: 10000 !important;'
      }
    },
    modalState() {
      return store.state.isImageModal.open
    }
  }
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.space-justify {
  justify-content: space-between;
}
.left-align {
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}
.alert-bar {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  max-height: 90px;
  padding: 5px;
  z-index: 1000;
  background-color: #fde8e8;
  /* background-color: #00b37e; */
  color: #e72623;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  /* border-bottom: 1px solid var(--green-color); */
}
.alert-container {
  width: 100%;
  padding: 0 10px;
  /* border:  1px solid green; */
  max-width: 990px;
}
.right-content {
  /* max-width: 20%; */
  color: #e72623;
}
.left-content {
  text-align: left;
  padding: 0 10px;
  padding-right: 10px;
  /* max-width: 80%; */
}
.alert-action-button {
  height: 2.5em;
  padding: 0 8px;
  color: black;
  border-radius: 0.5rem;
  /* background-color: var(--green-color);
     border: 1px solid var(--green-color); */
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(250, 24, 24);
  border-color: #d9e1ec;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.alert-action-button:hover {
  background-color: #d40000;
}
.hideToast {
  display: none;
}
.showToast {
  display: block;
}
.showonDesktop {
  display: none;
}
@media (min-width: 600px) {
  .alert-bar {
    top: 0px;
    max-height: 50px;
    padding: 5px 0;
  }
  .alert-container {
    flex-direction: row !important;
    width: 100%;
    max-width: 990px;
    max-width: 990px;
    justify-content: space-between;
    /* padding: 0 15px; */
  }
  .left-content {
    text-align: left;
    padding: 0;
    /* max-width: 80%; */
  }
}
@media only screen and (max-width: 350px) {
  .left-content {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  .alert-container {
    width: 990px;
    padding: 0;
    max-width: 990px;
  }
  .showonDesktop {
    display: block;
  }
}
</style>