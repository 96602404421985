<!-- eslint-disable vue/multi-word-component-names -->
<script>
import moment from 'moment';
import Vue from 'vue';
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});
export default {
    props: {
        vaults: Array,
        text: {
            type: String,
            default: "document",
        },

        text1: {
            type: String,
            default: "uploadDate",
        },

        text2: {
            type: String,
            default: "status",
        },

        text3: {
            type: String,
            default: "actions",
        },

        text4: {
            type: String,
            default: "underReview",
        },

        text5: {
            type: String,
            default: "approved",
        },

        text6: {
            type: String,
            default: "rejected",
        },

        text7: {
            type: String,
            default: "viewFounderProfile",
        },

        text11: {
            type: String,
            default: "viewBusinessRegistration",
        },

        text12: {
            type: String,
            default: "viewPitchDeck",
        },

        text8: {
            type: String,
            default: "uploadFounderProfile",
        },

        text9: {
            type: String,
            default: "uploadBusinessRegistration",
        },

        text10: {
            type: String,
            default: "uploadPitchDeck",
        }
    },

    data() {
        return {
            modal_data: {
                show: true,
                category: '',
                content: {}
            }
        }
    },

    methods: {
        showUploadModal(e) {
            if (e.document_id === 1) {
                this.modal_data.category = 'Pitch Deck';
            }

            if (e.document_id === 2) {
                this.modal_data.category = 'Business Registration';
            }

            if (e.document_id === 3 || e.document_id === 4) {
                this.modal_data.category = 'Founder Profile';
            }

            this.modal_data.content = e;
            this.$store.commit("set_show_modal", this.modal_data)
        }
    }
}
</script>

<template>
    <div>
        <table class="table">
            <tr>
                <th class="th-1">{{ $t(text) }}</th>
                <th class="th-2">{{ $t(text1) }}</th>
                <th class="th-3">{{ $t(text2) }}</th>
                <th>{{ $t(text3) }}</th>
            </tr>

            <tr v-for="(vault, index) in vaults" :key="index">
                <td>{{vault.document_type}}</td>
                <td>{{vault.upload_date | formatDate}}</td>
                <td>
                    <span v-if="vault.status === '1'" class="primary">{{ $t(text4) }}</span>
                    <span v-if="vault.status === '2'" class="success">{{ $t(text5) }}</span>
                    <span v-if="vault.status === '3'" class="danger">
                        <div class="">
                            <p class="d-flex align-items-center tool-tip">
                                <span>
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" class="svg_width">
                                        <path
                                            d="M12.2083 19.9585H14.7916V12.2085H12.2083V19.9585ZM13.4999 0.583496C6.36346 0.583496 0.583252 6.3637 0.583252 13.5002C0.583252 20.6366 6.36346 26.4168 13.4999 26.4168C20.6364 26.4168 26.4166 20.6366 26.4166 13.5002C26.4166 6.3637 20.6364 0.583496 13.4999 0.583496ZM13.4999 23.8335C7.80367 23.8335 3.16659 19.1964 3.16659 13.5002C3.16659 7.80391 7.80367 3.16683 13.4999 3.16683C19.1962 3.16683 23.8333 7.80391 23.8333 13.5002C23.8333 19.1964 19.1962 23.8335 13.4999 23.8335ZM12.2083 9.62516H14.7916V7.04183H12.2083V9.62516Z"
                                            fill="black" />
                                    </svg>

                                    <span class="ml-2">{{ $t(text6) }}</span>
                                </span>
                                <span class="tool-tip-text">
                                    <p class="p-3">
                                        {{vault.reason}}
                                    </p>
                                </span>
                            </p>
                        </div>
                    </span>
                </td>
                <td>
                    <ul class="list-action">
                        <li class="list-action-link tool-tips">
                            <a :href="vault.linked_in_url" target="_blank" v-if="vault.document_id === 4">
                                <svg width="44" height="26" viewBox="0 0 44 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22 4.68C17.6978 4.68 14.1778 8.424 14.1778 13C14.1778 17.576 17.6978 21.32 22 21.32C26.3022 21.32 29.8222 17.576 29.8222 13C29.8222 8.424 26.3022 4.68 22 4.68ZM43.7067 12.272L39.5022 7.748C34.8578 2.756 28.6489 0 22 0C15.3511 0 9.14222 2.756 4.49778 7.748L0.293333 12.272C-0.0977778 12.688 -0.0977778 13.312 0.293333 13.728L4.49778 18.252C9.14222 23.244 15.3511 26 22 26C28.6489 26 34.8578 23.244 39.5022 18.252L43.7067 13.728C44.0978 13.312 44.0978 12.688 43.7067 12.272ZM38.1333 16.796C33.8311 21.372 28.1111 23.92 22 23.92C15.8889 23.92 10.1689 21.372 5.86667 16.796L2.34667 13L5.86667 9.204C10.1689 4.628 15.8889 2.08 22 2.08C28.1111 2.08 33.8311 4.628 38.1333 9.204L41.6533 13L38.1333 16.796Z"
                                        fill="black" />
                                </svg>
                            </a>
                            <a :href="vault.value" target="_blank" v-else>
                                <svg width="44" height="26" viewBox="0 0 44 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22 4.68C17.6978 4.68 14.1778 8.424 14.1778 13C14.1778 17.576 17.6978 21.32 22 21.32C26.3022 21.32 29.8222 17.576 29.8222 13C29.8222 8.424 26.3022 4.68 22 4.68ZM43.7067 12.272L39.5022 7.748C34.8578 2.756 28.6489 0 22 0C15.3511 0 9.14222 2.756 4.49778 7.748L0.293333 12.272C-0.0977778 12.688 -0.0977778 13.312 0.293333 13.728L4.49778 18.252C9.14222 23.244 15.3511 26 22 26C28.6489 26 34.8578 23.244 39.5022 18.252L43.7067 13.728C44.0978 13.312 44.0978 12.688 43.7067 12.272ZM38.1333 16.796C33.8311 21.372 28.1111 23.92 22 23.92C15.8889 23.92 10.1689 21.372 5.86667 16.796L2.34667 13L5.86667 9.204C10.1689 4.628 15.8889 2.08 22 2.08C28.1111 2.08 33.8311 4.628 38.1333 9.204L41.6533 13L38.1333 16.796Z"
                                        fill="black" />
                                </svg>
                            </a>

                            <span class="tool-tip-texts">
                                <p class="p-3" v-if="vault.document_type === 'Founder Profile'">
                                    {{ $t(text7) }}
                                </p>

                                <p class="p-3" v-if="vault.document_type === 'Business Registration'">
                                    {{ $t(text11) }}
                                </p>

                                <p class="p-3" v-if="vault.document_type === 'Pitch Deck'">
                                    {{ $t(text12) }}
                                </p>
                            </span>
                        </li>
                        <li class="list-action-link tool-tips" @click="showUploadModal(vault)">
                            <svg width="34" height="25" viewBox="0 0 34 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M27.6803 12.4979C27.5889 12.4979 27.4996 12.4979 27.4125 12.5C27.5527 11.8146 27.625 11.1146 27.625 10.4167C27.625 4.67292 22.8586 0 17 0C11.1456 0 6.375 4.62708 6.36863 10.3104L6.38563 10.5208C2.7455 11.0312 0 14.1208 0 17.7083C0 21.3458 2.67325 24.475 6.375 25H11.6875C11.9693 25 12.2395 24.8903 12.4388 24.6949C12.6381 24.4996 12.75 24.2346 12.75 23.9583C12.75 23.6821 12.6381 23.4171 12.4388 23.2218C12.2395 23.0264 11.9693 22.9167 11.6875 22.9167L6.53012 22.9271C4.06087 22.5708 2.125 20.2771 2.125 17.7083C2.125 15.15 4.08425 12.9479 6.68525 12.5833L7.599 12.4542C7.85209 12.4186 8.08359 12.2947 8.25104 12.1053C8.4185 11.9159 8.51066 11.6736 8.51062 11.4229L8.49362 10.3146C8.5 5.775 12.3144 2.08333 17 2.08333C21.6877 2.08333 25.5 5.82083 25.5 10.4167C25.5 10.9812 25.4426 11.55 25.3258 12.1021L25.0452 13.4271C24.9751 13.7583 25.075 14.1 25.3088 14.3479C25.5425 14.5938 25.8783 14.7208 26.2267 14.6687C26.2501 14.6667 26.9153 14.5812 27.6803 14.5812C29.9944 14.5812 31.875 16.45 31.875 18.75C31.875 21.0479 29.9689 22.9167 27.625 22.9167H20.1875C17.2125 22.9167 17.0106 21.1687 17 20.8333V13.9208L19.5139 16.3833C19.7143 16.5731 19.9827 16.6781 20.2612 16.6757C20.5398 16.6733 20.8063 16.5638 21.0033 16.3706C21.2003 16.1775 21.312 15.9162 21.3145 15.6431C21.3169 15.37 21.2098 15.1069 21.0163 14.9104L16.7407 10.7208C16.6413 10.6238 16.5233 10.5469 16.3935 10.4947C16.2636 10.4425 16.1246 10.416 15.9843 10.4167C15.9672 10.4167 15.9545 10.4083 15.9375 10.4083C15.7929 10.4086 15.6499 10.4381 15.5176 10.4951C15.3852 10.5521 15.2663 10.6354 15.1683 10.7396L10.8885 14.9312C10.695 15.1277 10.5879 15.3908 10.5903 15.664C10.5927 15.9371 10.7044 16.1983 10.9014 16.3915C11.0984 16.5846 11.3649 16.6942 11.6435 16.6965C11.9221 16.6989 12.1905 16.5939 12.3909 16.4042L14.875 13.9708V20.8333C14.875 22.2729 15.9843 25 20.1875 25H27.625C31.1397 25 34 22.1958 34 18.75C34 15.3042 31.1653 12.4979 27.6803 12.4979Z"
                                    fill="black" />
                            </svg>

                            <span class="tool-tip-texts">
                                <p class="p-3" v-if="vault.document_type === 'Founder Profile'">
                                    {{ $t(text8) }}
                                </p>

                                <p class="p-3" v-if="vault.document_type === 'Business Registration'">
                                    {{ $t(text9) }}
                                </p>

                                <p class="p-3" v-if="vault.document_type === 'Pitch Deck'">
                                    {{ $t(text10) }}
                                </p>
                            </span>
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</template>

<style scoped>
ul li {
    list-style: none !important;
}

table,
.table,
tr,
th,
td {
    padding: 0;
    margin: 0;
    border: none;
    text-align: left
}

tr,
th,
td {
    height: 2px;
}

.th-1,
.th-2 {
    width: 35%;
}

.th-3 {
    width: 25%
}

.table>tr>th {
    color: #242424;
    /* sfont-size: 26px; */
    font-weight: bold;
    line-height: 35.1px;
}

.table>tr>td {
    color: #242424;
    /* font-size: 28px;
        font-weight: 400; */
    line-height: 32.2px;
}

.list-action {
    display: flex;
    align-items: center;
}

.list-action-link {
    padding: .3em;
}

.primary {
    color: #2F80ED;
}

.success {
    color: #00853B;
}

.danger {
    color: #EB5757;
}

.tool-tip .tool-tip-text {
    display: none;
    width: 220px;
    background-color: #ffff;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    right: 20em;
    box-shadow: 2px 3px #c7c7c7;
}

.tool-tip:hover .tool-tip-text {
    display: block;
}

.tool-tips .tool-tip-texts {
    display: none;
    width: 235px;
    background-color: rgb(64, 63, 63);
    color: #ffff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    right: 7em;
    box-shadow: 2px 3px #c7c7c7;
}

.tool-tips:hover .tool-tip-texts {
    display: block;
}

.svg_width {
    width: 16px;
}
</style>