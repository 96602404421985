<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3">
      <h6 class="m-0">{{ $t("overview") }}</h6>
    </div>
    <div class="box-body">
      <table class="table table-borderless mb-0">
        <tbody>
          <tr class="border-bottom">
            <th class="p-3">{{ $t("Industry") }}</th>
            <td class="p-3">{{ sector }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="p-3">{{ $t("employees") }}</th>
            <td class="p-3">{{ startup.numbInTeam }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="p-3">{{ $t("Headquarters") }}</th>
            <td class="p-3">{{ startup.location }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="p-3">{{ $t("Founded") }}</th>
            <td class="p-3">{{ startup.foundingYear }}</td>
          </tr>
          <tr v-if="startup.specialties">
            <th class="p-3">{{ $t("Specialties") }}</th>
            <td class="p-3">
              {{ startup.specialties }}
            </td>
          </tr>
          <!-- <tr class="border-bottom">
            <th class="p-3">{{ $t('website') }}</th>
            <td class="p-3">
              <a :href="startup.website" target="_blank">{{ $t('website') }}</a>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import APIURLS from "../constant";
import i18n from "../i18n";
import moment from "moment";
import Axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  watch: {
    async $route() {
      await this.getSector();
    },
  },
  async mounted() {
    await this.getSector();
    // this.website = this.startup.website;
    // let start = this.startup.website.substring(0, 3);
    // if (start === 'www') {
    //   this.website = this.startup.website.substring(4);
    // } else {
    //   let start2 = this.startup.website.substring(0, 5);
    // 
    //   if (start2 === 'https') {
    //     this.website = this.startup.website.substring(5);
    //   } else if (start2 === 'http:') {
    //     this.website = this.startup.website.substring(4);
    //   }
    // }
  },
  computed: {
    ...mapGetters(["authUser"]),
  },
  methods: {
    ...mapActions(["loadStartup"]),
    getSector() {
      this.sector = this.startup.sector;
      Axios.get(
        APIURLS.SECTORSLIST +
          "code/" +
          this.startup.sector +
          "/lang/" +
          i18n.locale
      )
        .then((response) => {
          if (response.data.statusMsg === "Success") {
            this.sector = response.data.sector.name;
          }
        })
        .catch((error) => {
          console.log("error while fetching the sector from db", error);
          // this.$noty.error(error.response.data.message);
        });
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Overview",
  data() {
    return {
      website: "",
      sector: "",
    };
  },
  props: {
    startup: {},
  },
};
</script>

<style></style>
