<template>
  <div class="left-side">
    <AuthHeader :logo="require('@/assets/registration/logo.png')" />
    <div class="content">
      <Form />
    </div>
  </div>
</template>
<script>
import Form from "../../../../components/Auth/Form.vue";
import AuthHeader from "../../../../components/Auth/AuthHeader.vue";
export default {
  name: "LeftSide",
  components: {
    Form,
    AuthHeader,
  },
};
</script>
<style scoped>
.left-side {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 0;
  flex-grow: 0.4;
  padding-left: 2vw;
  font-family: "Inter", sans-serif;
}
.side-header {
  width: 90%;
  padding-top: 50px;
  padding-left: 0%;
  display: flex;
  align-items: center;
}
.logo-img {
  width: 200px;
}
.content {
  width: 90%;
  height: 100%;
  background-color: #549b560d;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 30px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .left-side {
    /* background-color:rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 0;
    flex-grow: 0.4;
    padding-left: 2vw;
    font-family: 'Inter', sans-serif; */
    /* margin-top: 100px; */
  /* flex-grow: auto;
  flex: none; */
  
    padding-left: 0;
    border-radius: 50px;
    z-index: 3000;
  }
  .content {
  width: 100%;
  height: 100%;
  background-color: #549b560d;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 30px;
}
/* .side-header {
  padding-top: 10px;
  background-color: red;
} */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>