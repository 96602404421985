<template>
  <div class="rightContainer">
    <div class="diaspora-container-scroll" v-if="getAccountType === 'Diaspora'">
      <SectionHeader text="Almost there!" class="reset-padding" />
      <Alert
        v-if="error"
        :text="errorMsg"
        type="danger"
        :size="10"
        class="offset-md-2"
      />
      <div class="bio-container">
        <p class="bio-title">
          {{ $t("Write your Bio") }}
          <span class="error_msg" v-if="errors.bio"> {{ $t(msg.bio) }}</span>
        </p>
        <p class="word-left">{{ $t("chars") }}: {{ this.wordLenght + " " }}</p>
        <p class="sub-text">
          {{ $t("StartupsTellUs") }}
        </p>
        <textarea
          v-on:keyup="checkWordLen"
          class="textarea"
          v-model="form.bio"
          :placeholder="$t('bioPlaceholder')"
          @change="saveInput"
          :class="errors.bio ? 'error' : ''"
          required
        >
        </textarea>
      </div>
      <div class="profile-upload">
        <h3>{{ $t("Let’s put a face to your name") }}</h3>
        <h5>{{ $t("A picture is worth a thousand words") }}</h5>
        <div class="profile-card-container">
          <!-- <ImportLinkedInPhoto v-if="!getisLinkedinLoggedin" /> -->
          <ProfileUlpoad v-if="!getisLinkedinLoggedin" />
          <div class="lk-container" v-if="getisLinkedinLoggedin">
            <!-- localStorage.getItem('linkedInData') -->
            <div class="lk-info-column" v-if="getlinkedInData.name">
              <div class="lk-name-row">
                <p>{{ getlinkedInData.name }}</p>
                <p>{{ getlinkedInData.family }}</p>
              </div>
              <p class="lk-email">{{ getlinkedInData.email }}</p>
              <img class="profile-photo" :src="getlinkedInData.photo" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="startup-container-scroll">
      <SectionHeader text="Stand out" emphasize="from the crowd!" />
      <div class="right-header">
        <h5 class="startup-h5">
          {{
            $t(
              "Make a good first impression. A picture is worth a thousand words."
            )
          }}
        </h5>
      </div>
      <Alert
        v-if="error"
        :text="errorMsg"
        type="danger"
        :size="10"
        class="offset-md-2"
      />
      <div class="startup-profile">
        <div class="container">
          <UlpoadCover />
          <UlpoadLogo />
        </div>
      </div>
      <div class="pitch-video">
        <label>{{ $t("Pitch or trailer video") }}</label>
        <input
          type="text"
          :placeholder="$t('Add link to your pitch or trailer video')"
          v-model="form.video"
        />
      </div>
    </div>
    <div class="step-footer">
      <StepButton
        text="previous"
        v-if="getAccountType === 'Diaspora'"
        @click.native="goPrevious"
        :icon="require('@/assets/registration/icons/previous.svg')"
        className="previous"
      />
      <StepButton
        v-if="getAccountType === 'Startup'"
        text="previous"
        @click.native="goPreviousBis(3.5)"
        :icon="require('@/assets/registration/icons/previous.svg')"
        class="previous"
      />

      <StepButton
        text="Finish"
        @click.native="nextStep"
        :icon="require('@/assets/registration/icons/next.svg')"
        class="step4_finish"
      />
      <!-- @click.native="gotoNext" -->
      <!-- @click.native="nextStep" -->
    </div>
  </div>
</template>
<script>
// import ImportLinkedInPhoto from "../../../../components/Auth/ImportLinkedInPhoto.vue";
import ProfileUlpoad from "../../../../components/Auth/ProfileUlpoad.vue";
import UlpoadLogo from "../../../../components/Auth/UlpoadLogo.vue";
import UlpoadCover from "../../../../components/Auth/UlpoadCover.vue";

import SectionHeader from "../../../../components/Auth/SectionHeader.vue";
import StepButton from "../../../../components/Auth/StepButton.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import moment from "moment";
import Axios from "axios";
import i18n from "../../../../i18n";
import store from "../../../../store/";
import APIURLS from "../../../../constant";
export default {
  created() {
    let that = this;
    console.table({
      traction: that.getFordataField("tractions"),
      business_model: that.getFordataField("business_model"),
      revenue_state: that.getFordataField("revenue_state"),
      referral_code: that.getFordataField("referral_code"),
    });
    let bio = localStorage.getItem("bio");
    let video = localStorage.getItem("video");
    if (bio) {
      this.$store.commit("setFormData", {
        field: "bio",
        value: bio.trim(),
      });
      this.form.bio = bio;
    }

    if (video) {
      this.$store.commit("setFormData", {
        field: "video",
        value: video.trim(),
      });
      this.form.video = video;
    }
  },
  computed: {
    ...mapGetters([
      "getAccountType",
      "getFomData",
      "getFordataField",
      "getisLinkedinLoggedin",
      "getlinkedInData",
    ]),
  },
  data: () => {
    return {
      linkedinStaticData: {},
      form: {
        bio: "",
        video: "",
      },
      errors: {
        bio: "",
        video: "",
      },
      msg: {
        bio: "",
        video: "",
      },
      loading: false,
      errorMsg: "",
      error: false,
      wordLenght: 0,
      minChars: 150,
    };
  },
  methods: {
    ...mapMutations(["gotoNext", "goPrevious", "setStep", "goPreviousBis"]),
    ...mapActions(["logUserIn", "getUserGeoInfos"]),
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    async saveInput() {
      if (this.isEmpty(this.form.bio)) {
        this.errors.bio = true;
        this.msg.bio = `${this.$i18n.t("bio")} is required`;
      } else {
        if (this.form.bio.length < this.minChars) {
          this.errors.bio = true;
          this.msg.bio = `Min ${this.minChars} ` + this.$i18n.t("chars");
        } else {
          this.errors.bio = null;
          this.msg.bio = "";

          localStorage.setItem("bio", this.form.bio);
          this.$store.commit("setFormData", {
            field: "bio",
            value: this.form.bio,
          });
        }
      }
    },

    checkWordLen(event) {
      this.wordLenght = this.form.bio.length;
    },

    WordCount(str) {
      return str.split(" ").filter(function (n) {
        return n != "";
      }).length;
    },
    async registerDiaspora() {
      if (this.isEmpty(this.form.bio)) {
        this.errors.bio = true;
        this.msg.bio = `${this.$i18n.t("bio")} is required`;
      } else {
        if (this.form.bio.length < this.minChars) {
          this.errors.bio = true;
          this.msg.bio = `Min ${this.minChars} ` + this.$i18n.t("chars");
        } else {
          this.errors.bio = null;
          this.msg.bio = "";

          //register the user
          this.loading = true;
          let data = {
            name: this.getFordataField("lastName"),
            firstname: this.getFordataField("firstName"),
            educationLevel: this.getFordataField("levelOfEducation"),
            experienceYears: this.getFordataField("yearsOfExperience"),
            username: this.getFordataField("username"),
            email: this.getFordataField("email"),
            password: this.getFordataField("password"),
            avatar: this.getFordataField("avatar"),
            supportStartup: this.getFordataField("supportStartup"),
            typeOfSupport: this.getFordataField("supportType"),
            CountryofResidence: this.getFordataField("countryOfResidence"),
            AfricanCountryofOrigin: this.getFordataField(
              "africanCountryOfOrigin"
            ),
            talents: this.getFordataField("expertises"),
            sectors: this.getFordataField("sectorOfInterrest"),
            about: this.getFordataField("bio"),
          };
          data.emailData = {
            subject: this.$i18n.t("thanksForSignup"),
            buttonText: this.$i18n.t("verifyEmail"),
            email: data.email,
            link: `${APIURLS.SITELINK}${i18n.locale}/activate/`,
            text: this.$i18n.t("confirmEmailText"),
            title: this.$i18n.t("thanksForSignup"),
            name: data.name,
            thanks: this.$i18n.t("thanks"),
          };
          data.type = 1;
          Axios.post(APIURLS.AUTH + "register", data)
            .then(async (response) => {
              const loginDetails = {
                email: data.email,
                password: data.password,
              };

              const ipRegistry = await this.getUserGeoInfos();
              loginDetails.login = {
                country_name: ipRegistry.location.country.name,
                country_code: ipRegistry.location.country.code,
                city: ipRegistry.location.city,
                startDate: moment(new Date()).format("MM-DD-YYYY"),
                startTime: moment(new Date()).format("h:mm A"),
                fullStartTime: moment().toISOString(),
                startTimestamp: moment().unix(),
                ip: ipRegistry.ip,
                latitude: ipRegistry.location.latitude,
                longitude: ipRegistry.location.longitude,
                postal_code: ipRegistry.location.postal,
                region: ipRegistry.location.region.name,
                timezone_name: ipRegistry.time_zone.name,
                continent_name: ipRegistry.location.continent.name,
                continent_code: ipRegistry.location.continent.code,
              };

              this.logUserIn(loginDetails)
                .then(async (response) => {
                  this.loading = false;
                  this.$noty.success("Success");
                  this.gotoNext();
                  // this.$router.push(
                  //   `/${i18n.locale}/${i18n.t("routes.myActivity")}`
                  // );
                })
                .catch((error) => {
                  this.loading = false;
                  this.error = true;
                  this.errorMsg = error.response.data.message;
                  this.$noty.error(this.errorMsg);
                });
            })
            .catch((error) => {
              console.log("error  register", error);
              console.log(
                "error.response.data.message",
                error.response.data.message
              );
              this.loading = false;
              this.error = true;
              this.errorMsg = error.response.data.message;
              this.$noty.error(this.errorMsg);
            });
        }
      }
    },
    async registerStartup() {
      this.loading = true;
      let startupname = this.getFordataField("startupName").trim();
      Axios.get(APIURLS.STARTUP + "check-startupname/" + startupname).then(
        (response) => {
          if (!response.data) {
            this.loading = false;
            this.error = true;
            this.errorMsg = `${i18n.t("startupAlreadyExists")}`;
          } else {
            let data = {
              name: this.getFordataField("lastName"),
              firstname: this.getFordataField("firstName"),
              email: this.getFordataField("email"),
              password: this.getFordataField("password"),
              startupname: this.getFordataField("startupName"),
              slug: this.getFordataField("slug"),
              location: this.getFordataField("location"),
              foundingYear: this.getFordataField("foundingYear"),
              description: this.getFordataField("description"),
              website: this.getFordataField("website"),
              stage: this.getFordataField("startupStage"),
              numbInTeam: this.getFordataField("numberInTeams"),
              sector: this.getFordataField("sector"),
              typeofTalents: this.getFordataField("supportType"),
              talents: this.getFordataField("expertises"),
              cover: this.getFordataField("cover"),
              avatar: this.getFordataField("avatar"),
              video: this.getFordataField("video"),

              traction: this.getFordataField("tractions"),
              business_model: this.getFordataField("business_model"),
              revenue_state: this.getFordataField("revenue_state"),
              referral_code: this.getFordataField("referral_code"),
              is_entrepreneur: 1,
              is_verified: 0,
            };

            data.emailData = {
              subject: this.$i18n.t("thanksForSignup"),
              buttonText: this.$i18n.t("verifyEmail"),
              email: data.email,
              link: `${APIURLS.SITELINK}${i18n.locale}/activate/`,
              text: this.$i18n.t("confirmEmailText"),
              title: this.$i18n.t("thanksForSignup"),
              name: data.name + data.firstname,
              thanks: this.$i18n.t("thanks"),
            };
            data.type = 2;
            data.is_entrepreneur = 1;
            data.is_verified = 0;
            Axios.post(APIURLS.AUTH + "register", data)
              .then((response) => {
                this.loading = false;
                const loginDetails = {
                  email: data.email,
                  password: data.password,
                };
                this.logUserIn(loginDetails)
                  .then((response) => {
                    this.form.email = "";
                    this.form.password = "";
                    this.loading = false;
                    this.$noty.success("Success");
                    this.gotoNext();
                    // this.$router.push(
                    //   `/${i18n.locale}/${i18n.t("routes.myActivity")}`
                    // );
                  })
                  .catch((error) => {
                    this.loading = false;
                    this.error = true;
                    this.errorMsg = error.response.data.message;
                    this.$noty.error(this.errorMsg);
                  });
              })
              .catch((error) => {
                console.log("error", error);
                console.log(
                  "error.response.data.message",
                  error.response.data.message
                );
                this.loading = false;
                this.error = true;
                this.errorMsg = error.response.data.message;

                this.$noty.error(this.errorMsg);
              });
          }
        }
      );
    },
    async nextStep() {
      if (this.getAccountType === "Diaspora") {
        this.registerDiaspora();
      } else {
        this.registerStartup();
      }

      window.dataLayer.push({ event: "step4_finish" });
    },
  },
  name: "StepFourContent",
  components: {
    SectionHeader,
    StepButton,
    // ImportLinkedInPhoto,
    ProfileUlpoad,
    UlpoadLogo,
    UlpoadCover,
  },
};
</script>
<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}
h3,
h5 {
  font-family: inherit;
  text-align: left;
}
h3 {
  font-size: 18px !important;
  font-weight: normal !important;
  color: #8b90aa;
}

.word-left {
  color: rgb(110, 118, 125);
  font-size: 13px;
  position: relative;
  width: 100px !important;
  margin-top: 10px !important;
  bottom: 10px;
  border: 1px solid rgba(0, 128, 0, 0.358);
  padding: 2px 8px;
  border-radius: 20px;
  display: block;
  color: rgba(0, 128, 0, 0.8);
}

.error_msg {
  color: var(--error-color);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 15px;
}
.textarea.error {
  border: solid 1px var(--error-color) !important;
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
  animation: shake 0.2s ease-in-out 0s 2;
}
.rightContainer {
  background-color: rgb(255, 255, 255);
  height: 100%;
  flex-grow: 0.6;
  padding: 0px 0px;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.right-header {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  align-items: flex-start;
}
.right-header h1 {
  padding: 0px 0px !important;
  color: rgba(0, 0, 0, 0.26);
}
.bio-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 50px;
  align-items: flex-start;
}
.textarea {
  /* height: 250px; */
  border-radius: 15px;
  padding: 10px;
  background-color: #349e4d17;
  border: 1px solid #71717159 !important;

  height: 200px;
  overflow-y: scroll;
}
.textarea::placeholder {
  color: #b4b9c3 !important;
}
.textarea:focus {
  color: rgb(16, 16, 16);
  border: 2px solid rgba(0, 0, 0, 0.3);
  border: solid 1px orange;
}

.profile-upload {
  padding: 20px 50px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.profile-upload h3 {
  font-size: 22px !important;
  color: black;
  padding: 0;
}
.profile-upload h5,
.sub-text {
  font-size: 18px;
  padding: 0;
  color: rgba(0, 0, 0, 0.58);
  font-weight: lighter;
  margin-bottom: 10px;
}
.profile-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.profile-text {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.startup-profile {
  padding: 30px 50px;
}
.startup-profile .container {
  background-color: #eff4f7;
  position: relative;
  height: 35vh;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.startup-profile-card {
  position: absolute;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-radius: 20px;
  bottom: 20px;
  left: 20px;
}
.startup-profile-text {
  font-size: 22px;
  position: relative;
}
/* .startup-profile-icon {
} */
.cover-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  overflow: hidden;
}
.cover-text {
  font-size: 25px;
  padding-right: 30px;
  padding-top: 15px;
}
.pitch-video {
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
}
.pitch-video label {
  font-size: 22px;
  text-align: left !important;
}
.pitch-video input {
  border: 1px solid #ccd0d5 !important;
  padding: 18px 10px;
  font-size: 18px;

  height: 47px;
  border-radius: 15px;
}

.step-footer {
  position: absolute !important;
  bottom: 0;
  right: 0;
  left: 0;
  height: 80px;
  padding: 10px 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
}
.bio-title {
  text-align: left;
  /* font-size: 18px !important; */
  font-weight: normal !important;
  color: black;
  font-size: 22px;
}
.bio-title:placeholder {
  color: red;
}

.lk-container {
  background-color: #0000000a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  border-radius: 10px;
  width: 100%;
}
.lk-info-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.lk-name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  font-size: 18px;
  width: 100%;
}
.lk-email {
  font-size: 18px;
  width: 100%;
  padding: 10px 20px;
}
.profile-photo {
  border-radius: 50%;
}
.startup-h5 {
  font-size: 22px !important;
}
@media only screen and (min-width: 1200px) {
  .reset-padding {
    padding-left: 0;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .rightContainer {
    background-color: rgb(255, 255, 255);
    height: 80%;
    height: calc(100%);
    width: 100vw;
    flex-grow: 0.6;
    padding: 10px 0px;
    align-items: flex-start;
    position: relative;
    z-index: 1000;
    overflow-y: scroll;
    text-align: left;
  }
  .right-header {
    padding: 0px 15px;
  }
  .startup-h5 {
    font-size: 18px !important;
  }
  .bio-title {
    color: #717171 !important;
    padding-bottom: 0.5rem !important;
  }
  .bio-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 15px;
    align-items: flex-start;
  }

  .profile-upload {
    padding: 10px 15px;
    padding-top: 0px;
    /* height: 50vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 30px;
  }

  .textarea {
    /* height: 18vh;
    height: 23vh; */
    height: 28vh;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .right-header h1 {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px !important;
    padding: 10px;
    margin-top: -40px;
    text-align: center;
    width: 100vw;
    z-index: 1000;
    border-radius: 64px;
    background-color: #ffffff;
  }
  .profile-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0px;
    width: 100%;
  }

  .profile-upload h3 {
    font-size: 20px;
    color: #808896;
    padding: 0;
  }
  .profile-upload h5,
  .sub-text {
    font-size: 16px;
    padding: 0;
    /* color: #b4b9c3; */
    font-weight: lighter;
  }
  .profile-text {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .step-footer {
    height: 60px;
    width: 100%;
    padding: 0 15px;

    height: 90px;
    z-index: 999;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .startup-profile {
    padding: 10px 15px;
  }
  /* .startup-profile {
  padding: 30px 15px;
} */
  .startup-profile .container {
    padding: 0;
  }
  .startup-container-scroll {
    /* height: 100%;
  overflow-y: scroll; */
    padding-bottom: 100px;
    padding-top: 10px;
  }
  .container {
    width: 100%;
  }
  .pitch-video {
    padding: 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cover-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding-left: 10px;
  }
  .lk-container {
    background-color: #0000000a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border-radius: 10px;
  }
  .lk-info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .lk-name-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    font-size: 18px;
    width: 100%;
  }
  .lk-email {
    font-size: 18px;
    width: 100%;
    padding: 10px 20px;
  }
  .profile-photo {
    border-radius: 50%;
  }

  .pitch-video input {
    height: 50px;
    border-radius: 15px;
  }

  .diaspora-container-scroll {
    overflow-y: scroll;
  }
  .pitch-video label[data-v-4c0ecda2] {
    font-size: 18px;
    text-align: left !important;
  }
}

/* @media only screen and (max-width: 320px) {} */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 320px) {
  .textarea {
    /* height: 18vh; */
    /* height: 18vh; */
    height: 28vh;
    font-size: 14px;
    overflow-y: scroll;
  }
  .bio-container {
    padding: 10px 15px;
  }
  .sub-text {
    display: none !important;
  }
  .bio-title {
    padding: 0.5rem 0 !important;
    font-size: 18px;
  }
  .profile-upload h3 {
    font-size: 18px !important;
  }
  .pitch-video input {
    height: 40px;
    border-radius: 15px;
    font-size: 14px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>