import { render, staticRenderFns } from "./StarupNewsResources.vue?vue&type=template&id=64214d23&scoped=true&"
import script from "./StarupNewsResources.vue?vue&type=script&lang=js&"
export * from "./StarupNewsResources.vue?vue&type=script&lang=js&"
import style0 from "./StarupNewsResources.vue?vue&type=style&index=0&id=64214d23&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64214d23",
  null
  
)

export default component.exports