<template>
  <div class="py-4">
    <div class="container-fluid px-md-5 min-layout">
      <div class="row center-on-desktop" v-if="!loading">
        <main
          class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          "
        >
          <div class="mb-3 w-100 col-md-12">
            <JobsList
              :jobs="jobs"
              v-if="jobs.length"
              :showFilter="false"
              :showSearch="false"
            />
            <NoDataFound
              v-else
              :svg="require('../../assets/images/noData/no_jobs.svg')"
              title="Oops! No Jobs Found"
              text="Something wrong happend. Please try again or contact the administrator"
              :hasTitle="true"
              :hasText="false"
              :hasButton="false"
            />
          </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NoDataFound from "../../components/NoDataFound/Version-1.vue";

export default {
  metaInfo() {
    return {
      title: "Bantaba | Careers",
    };
  },
  async created() {
    await this.loadJobs();
    this.jobs = this.getJobs;
    this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getUsers", "getConnectionsCounts", "getJobs"]),
  },
  methods: {
    ...mapActions(["loadUsers", "loadConnCounts", "loadJobs"]),
  },
  data() {
    return {
      loading: true,
      users: {},
      counts: {},
      jobs: {},
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Jobs",
  components: {
    NoDataFound,
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .min-layout {
    /* min-width: min(78vw, 1140px); */
    min-width: 990px;
  }
  main.col.col-xl-12.order-xl-2.col-lg-12.order-lg-1.col-md-12.col-sm-12.col-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-width: 67%; */
    /* max-width: min(78vw, 1140px) !important; */
    max-width: 990px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0rem !important;
  }
  .center-on-desktop {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .container-fluid {
    min-height: 100vh;
    width: 98vw;

    width: 100vw !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 0rem !important;
  }

  main.col.col-xl-12.order-xl-2.col-lg-12.order-lg-1.col-md-12.col-sm-12.col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
