<template>
  <div>
    <div class="py-4">
      <div class="container-fluid px-md-5">
        <div class="row" v-if="!loading">
          <aside class="col-md-4">
            <AddSkills :tags="skills" />
            <AddLanguages :languages="languages" />
            <AddInterrests :interrests="interrests" />
          </aside>
          <main class="col-md-8">
            <AddExperiences :experiences="experiences" />
            <AddEducations :educations="educations" />
          </main>
        </div>
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  async created() {
    await this.loadSkills();
    await this.loadLanguages();
    await this.loadExperiences();
    await this.loadEducation();
    await this.loadInterrests();
    this.skills = this.getSkills;
    this.languages = this.getLanguages;
    this.experiences = this.getExperiences;
    this.educations = this.getEducations;
    this.interrests = this.getInterrests;
    this.loading = false;
  },
  metaInfo() {
    return {
      title: "Bantaba | Edit Career ",
    };
  },

  computed: {
    ...mapGetters([
      "authUser",
      "getSkills",
      "getLanguages",
      "getExperiences",
      "getEducations",
      "getInterrests",
    ]),
  },
  data() {
    return {
      skills: {},
      languages: {},
      experiences: {},
      educations: {},
      interrests: {},
      loading: true,
    };
  },
  methods: {
    ...mapActions([
      "updateBasicInfo",
      "loadSkills",
      "loadLanguages",
      "loadExperiences",
      "loadEducation",
      "loadInterrests",
    ]),
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Cursus",
};
</script>
