<template>
  <div class="py-4">
    <div class="container-fluid px-md-5">
      <div class="row" v-if="!loading">
        <main
          class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          "
        >
          <StartupAside :consultantsCount="count" />
          <div
            class="box shadow-sm border rounded bg-white mb-3 osahan-share-post"
          >
            <Connections
              :users="users"
              type="investors"
              emptyMessage="NoInvestors"
              :showSearch="false"
            />
          </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Bantaba | Community | Consultants",
    };
  },
  async created() {
    if (!JSON.parse(localStorage.getItem("consultants"))) {
      await this.loadConsultants(this.authUser.id);
      this.users = this.getConsultants;
      //save to locale storage
      localStorage.setItem("consultants", JSON.stringify(this.getConsultants));
    } else {
      this.users = JSON.parse(localStorage.getItem("consultants"));
    }
    this.count = this.users.length;
    this.loading = false;
  },
  computed: {
    ...mapGetters(["authUser", "getConsultants"]),
  },
  methods: {
    ...mapActions(["loadConsultants"]),
  },
  data() {
    return {
      loading: true,
      users: {},
      count: 0,
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Investors",
};
</script>

