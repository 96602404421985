<template>
  <div class="notification-settings-item">
    <div class="name-icon-group" :class="{'group-single-toggle':singleToggle , 'group-with-toggles ': withToggles }">
      <div class="setting-icon" :style="{ backgroundImage: `linear-gradient(to bottom right, ${topColor || '#3434ac'}, ${bottomColor || '#b2c51d'})` }">
        <font-awesome-icon :icon="['fas', iconName]" />
      </div>

      <span class="setting-name"> {{ settingName }}</span>
    </div>

    <div class="single-toggle" v-if="singleToggle"> 
        <div class="toggle">
            <ToggleSwitch withCheck  @switchChecked="singleSwitch"></ToggleSwitch>
        </div>
        
    </div>
    <div class="toggles" v-if="withToggles"> 
        <div class="toggle">
            <ToggleSwitch withCheck @switchChecked="dailySwitch"></ToggleSwitch> 
            <span class="frequency">Daily</span>
        </div>
        <div class="toggle">
            <ToggleSwitch withCheck @switchChecked="weeklySwitch"></ToggleSwitch> 
            <span class="frequency">Weekly</span>
        </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from "./ToggleSwitch.vue";

export default {
  name: "SettingItem",
  components: {
    ToggleSwitch,
  },
  props: {
    settingName: String,
    iconName: String,
    topColor: String,
    bottomColor: String,
    withToggles: Boolean,
    singleToggle: Boolean,
  },
  data: function () {
    return {};
  },
  methods:{
    //CATCH CUSTOM EVENT FROM TOGGLE
    singleSwitch(checked){
      console.log("DAILY SWITCH", checked)
    },
    dailySwitch(checked){
      console.log("DAILY SWITCH", checked)
    },
    weeklySwitch(checked){
      console.log("WEEKLY SWITCH", checked)
    }
  }
};
</script>

<style>

.toggle{
    display: flex;
}



.setting-name{
  font-size: 14px;
}
.toggle .frequency{
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.single-toggle {
  display: flex;
  justify-content:flex-end;
    width: 20%;
}


.name-icon-group .setting-icon svg {
  color: white;
}

.name-icon-group .setting-icon {
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.group-with-toggles {
  width: 55%;
}

.group-single-toggle {
  width: 80%;
}

.toggles {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-icon-group {
  display: flex;
  align-items: center;
}
.notification-settings-item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #11111111;
}
.notification-settings-item:first-child {
  border-top: 1px solid #11111111;
}

@media screen and (min-width: 600px) {
  .notification-settings-item {

  padding: 10px 20px;
}
  .toggle .switch, .setting-name{
  font-size: 20px;
}

.toggle:not(:first-child){
    margin-left: 40px;
}
}
</style>
