<template>
  <div class="notifications-page">
    <div v-if="!loading">
      <div class="container" v-if="getUserNotifications.length">
        <div class="wrapper">
          <div class="top-head" v-if="getUserNotifications.length">
            <div class="top-head-container">
              <div class="top-head-left">
                <div class="left">
                  <h1 class="">
                    <span class="font-weight-bold">
                      <!-- <span v-if="getUserNotifications.length">
                      ({{ getUserNotifications.length }})</span
                    > -->
                      <Icon size="40" color="gray">
                        <NotyIcon />
                      </Icon>

                      Notifications
                    </span>
                  </h1>
                </div>
                <div class="right" v-if="getUserNotifications.length">
                  <button @click="deleteAllNotif()" class="link-special">
                    <Icon size="40" color="gray">
                      <ClearAll />
                    </Icon>
                    {{ $t("clear") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-row">
            <button @click="filterNotifByAll" class="filter-btn" :class="{active: tabIndex === 0}">
              <!-- <Icon size="30" color="green" >
                  <Trash />
                </Icon> -->
              <span>{{$t('All')}}</span>
              <span v-if="getUserNotifications.length">
                ({{ getUserNotifications.length }})</span>
            </button>
            <button @click="filterNotifByLikesComments" class="filter-btn" :class="{active: tabIndex === 1}">
              <Icon size="30" color="gray">
                <MessageCircle />
              </Icon>
              <span>{{$t('Likes & Comments')}}</span>
              <span v-if="getUserNotifications.length">
                ({{ likesComments.length }})</span>
            </button>
            <!-- // it's a 'request' button on diaspora  & 'followers'  -->
            <button @click="filterNotifByRequests" class="filter-btn" :class="{active: tabIndex === 2}"
              v-if="authUser.type === 1">
              <Icon size="30" color="gray">
                <UserPlus />
              </Icon>
              <span>{{ $t('Requests')}}</span>
              <span v-if="getUserNotifications.length">
                ({{ requests.length }})</span>
            </button>
            <button @click="filterNotifByViews" class="filter-btn" :class="{active: tabIndex === 4}">
              <Icon size="30" color="gray">
                <Eye />
              </Icon>
              <span>{{ $t('Views')}}</span>
              <span v-if="getUserNotifications.length">
                ({{ views.length }})</span>
            </button>
            <button @click="filterNotifByFollowers" class="filter-btn" :class="{active: tabIndex === 5}"
              v-if="authUser.type === 2">
              <Icon size="30" color="gray">
                <Users />
              </Icon>
              <span>{{ $t('Followers')}} </span>
              <span v-if="getUserNotifications.length">
                ({{ followers.length }})</span>
            </button>
            <!-- <button @click="filterNotifByMentions" class="filter-btn" :class="{active: tabIndex === 3}">
            <Icon size="30" color="gray">
              <At />
            </Icon>
            <span>{{ $t('Mentions')}}</span>
            <span v-if="getUserNotifications.length">
              ({{ mentions.length }})</span>
          </button> -->
          </div>
          <ul class="sessions" v-if="getUserNotifications.length && tabIndex === 0">

            <li v-for="(notification, index) in getUserNotifications" :key="index"
              :class="notification.viewed ? 'viewedNotif' : ''">
              <!-- {{notification}} -->
              <!-- <p>{{JSON.Stringfy(getUserNotifications)}}</p> -->
              <div>
                <div class="noty-top" @click="getRoute(notification)">
                  <div class="noty-left">
                    <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                    <img class="noty-avatar"
                      src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                      v-else />
                  </div>
                  <div class="noty-right">
                    <p class="post">
                      <span class="noty-name">{{ notification.name + ' ' }} </span> {{ $t(notification.text) }}
                      <span v-if="
                        notification.type === 'comment' ||
                        notification.type === 'like'
                      ">
                        : <span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0,
                        substring)))}}..."</span>
                      </span>
                    </p>
                    <span v-if="notification.type === 'request'" class="request-btns-container">
                      <button class="noty-request-btn accept" @click="acceptRequest(notification.sender_id,notification,index)">{{
                      $t('accept')}}
                      </button>
                      <button class="noty-request-btn view" @click="goToProfile(notification.sender_id)">{{
                      $t('viewProfile')}}</button>
                    </span>
                    <div class="time">
                      <span class="time-clock">
                        <Icon size="30" color="green">
                          <Clock />
                        </Icon>
                      </span>
                      <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                    </div>
                  </div>
                </div>
                <button class="right delete-button" @click="removeNotif(notification)">
                  <!-- <i class="feather-trash"></i> -->
                  <Icon size="30" color="green">
                    <Trash />
                  </Icon>
                </button>

              </div>

            </li>
          </ul>
          <ul class="sessions" v-if="likesComments.length && tabIndex === 1">
            <li v-for="(notification, index) in likesComments" :key="index"
              :class="notification.viewed ? 'viewedNotif' : ''">
              <!-- {{notification}} -->
              <div>
                <div class="noty-top" @click="getRoute(notification)">
                  <div class="noty-left">
                    <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                    <img class="noty-avatar"
                      src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                      v-else />
                  </div>
                  <div class="noty-right">
                    <p class="post">
                      <span class="noty-name">{{ notification.name + ' ' }} </span> {{ $t(notification.text) }}
                      <span v-if="
                        notification.type === 'comment' ||
                        notification.type === 'like'
                      ">:<span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0, substring)))
                      }}..."</span>
                      </span>

                    </p>
                    <span v-if="notification.type === 'request'" class="request-btns-container">
                      <span class="noty-request-btn accept" @click="acceptRequest(notification.sender_id,notification,index)">{{
                      $t('accept')}}
                      </span>
                      <a :href="`/${$i18n.locale}/profile/${notification.name}`" class="noty-request-btn view">{{
                      $t('viewProfile')}}</a>
                    </span>
                    <div class="time">
                      <span class="time-clock">
                        <Icon size="30" color="green">
                          <Clock />
                        </Icon>
                      </span>
                      <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                    </div>
                  </div>
                </div>
                <button class="right delete-button" @click="removeNotif(notification)">
                  <!-- <i class="feather-trash"></i> -->
                  <Icon size="30" color="green">
                    <Trash />
                  </Icon>
                </button>
              </div>


            </li>
          </ul>
          <ul class="sessions" v-if="requests.length && tabIndex === 2">
            <li v-for="(notification, index) in requests" :key="index"
              :class="notification.viewed ? 'viewedNotif' : ''">
              <div>
                <div class="noty-top" @click="getRoute(notification)">
                  <div class="noty-left">
                    <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                    <img class="noty-avatar"
                      src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                      v-else />
                  </div>
                  <div class="noty-right">
                    <p class="post">
                      <span class="noty-name">{{ notification.name + ' ' }} </span> {{ $t(notification.text) }}
                      <span v-if="
                        notification.type === 'comment' ||
                        notification.type === 'like'
                      ">
                        : <span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0, substring)))
                        }}..."</span>
                      </span>
                    </p>
                    <span v-if="notification.type === 'request'" class="request-btns-container">
                      <button class="noty-request-btn accept" @click="acceptRequest(notification.sender_id,notification,index)">{{
                      $t('accept')}} </button>
                      <button @click="goToProfile(notification.sender_id)" class="noty-request-btn view">{{
                      $t('viewProfile')}}
                      </button>
                    </span>
                    <div class="time">
                      <span class="time-clock">
                        <Icon size="30" color="green">
                          <Clock />
                        </Icon>
                      </span>
                      <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                    </div>
                  </div>
                </div>
                <button class="right delete-button" @click="removeNotif(notification)">
                  <!-- <i class="feather-trash"></i> -->
                  <Icon size="30" color="green">
                    <Trash />
                  </Icon>
                </button>
              </div>


            </li>
          </ul>
          <ul class="sessions" v-if="mentions.length && tabIndex === 3">
            <li v-for="(notification, index) in mentions" :key="index"
              :class="notification.viewed ? 'viewedNotif' : ''">
              <div>
                <div class="noty-top" @click="getRoute(notification)">
                  <div class="noty-left">
                    <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                    <img class="noty-avatar"
                      src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                      v-else />
                  </div>
                  <div class="noty-right">
                    <p class="post">
                      <span class="noty-name">{{ notification.name + ' ' }} </span> {{ $t(notification.text) }}
                      <span v-if="
                        notification.type === 'comment' ||
                        notification.type === 'like'
                      ">
                        : <span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0, substring)))
                        }}..."</span>
                      </span>
                    </p>
                    <span v-if="notification.type === 'request'" class="request-btns-container">
                      <span class="noty-request-btn accept">{{ $t('accept')}} </span>
                      <button @click="goToProfile(notification.sender_id)" class="noty-request-btn view">{{
                      $t('viewProfile')}}</button>
                    </span>
                    <div class="time">
                      <span class="time-clock">
                        <Icon size="30" color="green">
                          <Clock />
                        </Icon>
                      </span>
                      <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                    </div>
                  </div>
                </div>
                <button class="right delete-button" @click="removeNotif(notification)">
                  <!-- <i class="feather-trash"></i> -->
                  <Icon size="30" color="green">
                    <Trash />
                  </Icon>
                </button>
              </div>
            </li>
          </ul>
          <ul class="sessions" v-if="views.length && tabIndex === 4">
            <li v-for="(notification, index) in views" :key="index" :class="notification.viewed ? 'viewedNotif' : ''">
              <div>
                <div class="noty-top" @click="getRoute(notification)">
                  <div class="noty-left">
                    <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                    <img class="noty-avatar"
                      src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                      v-else />
                  </div>
                  <div class="noty-right">
                    <p class="post">
                      <span class="noty-name">{{ notification.name + ' ' }} </span> {{ $t(notification.text) }}
                      <span v-if="
                        notification.type === 'comment' ||
                        notification.type === 'like'
                      ">
                        : <span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0, substring)))
                        }}..."</span>
                      </span>

                    </p>
                    <span v-if="notification.type === 'request'" class="request-btns-container">
                      <span class="noty-request-btn accept">{{ $t('accept')}} </span>
                      <button @click="goToProfile(notification.sender_id)" class="noty-request-btn view">{{
                      $t('viewProfile')}}</button>
                    </span>
                    <div class="time">
                      <span class="time-clock">
                        <Icon size="30" color="green">
                          <Clock />
                        </Icon>
                      </span>
                      <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                    </div>
                  </div>


                </div>
                <button class="right delete-button" @click="removeNotif(notification)">
                  <Icon size="30" color="green">
                    <Trash />
                  </Icon>
                </button>
              </div>

            </li>
          </ul>
          <ul class="sessions" v-if="followers.length && tabIndex === 5">
            <li v-for="(notification, index) in followers" :key="index"
              :class="notification.viewed ? 'viewedNotif' : ''" @click="getRoute(notification)">
              <!-- {{notification}} -->
              <!-- <p>{{JSON.Stringfy(getUserNotifications)}}</p> -->
              <div class="noty-top">
                <div class="noty-left">
                  <img class="noty-avatar" :src="notification.sender_avatar" v-if="notification.sender_avatar" />
                  <img class="noty-avatar"
                    src="https://www.pngkey.com/png/detail/73-730477_first-name-profile-image-placeholder-png.png"
                    v-else />
                </div>
                <div class="noty-right">
                  <p class="post">
                    <span class="noty-name">{{ notification.name + ' ' }} </span> {{ $t(notification.text) }}
                    <span v-if="
                      notification.type === 'comment' ||
                      notification.type === 'like'
                    ">
                      : <span class="post-text">"{{ $t(removeHtmlTags(notification.post.substring(0, substring)))
                      }}..."</span>
                    </span>
                    <button class="right delete-button" @click="removeNotif(notification)">
                      <!-- <i class="feather-trash"></i> -->
                      <Icon size="30" color="green">
                        <Trash />
                      </Icon>
                    </button>
                  </p>
                  <span v-if="notification.type === 'request'" class="request-btns-container">
                    <span class="noty-request-btn accept" @click="acceptRequest(notification.sender_id,notification,index)">
                      {{ $t('accept')}} </span>
                    <a :href="`/${$i18n.locale}/profile/${notification.name}`" class="noty-request-btn view">view
                      profile</a>
                  </span>
                  <div class="time">
                    <span class="time-clock">
                      <Icon size="30" color="green">
                        <Clock />
                      </Icon>
                    </span>
                    <timeago :datetime="notification.created_at" :locale="$i18n.locale"></timeago>
                  </div>
                </div>


              </div>

            </li>
          </ul>
          <!-- v-if="!this.notification && !this.likesComments  && !this.requests && !this.mentions" -->
          <NoDataFound
            v-if="(notifications.length===0 && tabIndex === 0) || (likesComments.length===0 && tabIndex === 1) ||  (requests.length===0 && tabIndex === 2) || (mentions.length === 0 && tabIndex === 3)"
            class="padd-20" :svg="require('../../assets/images/noData/no_notification.svg')"
            :title="$t('No Notifications yet!')" :text="$t('we will notify you when something arrives')"
            :hasTitle="true" :hasText="true" :hasButton="false" />
        </div>
      </div>
      <div class="container" v-else>
        <div class="wrapper pt-5">
          <img class="no-notifs" src="../../assets/images/noData/no_notification.svg" />
          <p class="no-notif-text text-center p-3">{{ $t("You have no notifications") }}</p>
        </div>

      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import store from "../../store";
import APIURLS from "../../constant";
import NoDataFound from "../../components/NoDataFound/Version-1.vue";
import { mapActions, mapGetters } from "vuex";


import {Trash, Clock, MessageCircle, UserPlus, At, BellRinging as NotyIcon, ClearAll, Eye, Users} from "@v2icons/tabler";
import { Icon } from "@v2icons/utils";
export default {
  async mounted() {
    this.getNotifs();
    this.loading = false;
  },
  metaInfo() {
    return {
      title: "Bantaba | Notifications",
    };
  },
  data() {
    return {
      substring:35,
      notifications: {},
      loading: true,
      tabIndex: 0,
      likesComments: [],
      requests: [],
      mentions: [],
      views: [],
      followers: [],
    };
  },
  computed: {
    ...mapGetters(["authUser", "getUserNotifications", "getUserTalents","getUserLang"]),
  },
  methods: {
    ...mapActions([
      "loadUserNotifications",
      "getUsernameById",
      "setViewedNotif", "loadUserExpertises", "getForeignCountryByLang",
      "deleteNotif", "getSingleUserById"]),
    async checkConnection(user_id) {
      await Axios.get(
        APIURLS.CONNECTIONS +
        "connected/user/" +
        user_id +
        "/auth_user/" +
        this.authUser.id
      )
        .then((response) => {
          this.check = response.data;
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    removeHtmlTags(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.innerText;
    },
    async acceptRequest(user_id, notification, index) {
      //alert(notification.id)
      let data = {
        from: user_id,
        to: this.authUser.id,
        name: this.authUser.firstname + " " + this.authUser.name,
        lang: this.getUserLang,
        avatar: this.authUser.avatar,
        sender_id: this.authUser.id,
      };
      //check if users already connected

      await this.checkConnection(user_id);

      if (this.check === 'connected') {
        //if they are already connected
        this.$noty.success(this.$t('alreadyConnected'));
      } else {
        let expertise;
        let location;
        let lang = this.getUserLang;

        let livesInCode = this.authUser.profile
          ? this.authUser.profile.residenceContry
          : "";

        location = await this.getForeignCountryByLang({
          code: livesInCode,
          lang,
        });

        await this.loadUserExpertises({
          lang,
          user_id: this.authUser.id,
        });

        let talents = this.getUserTalents;
        if (talents.length && talents[0].talents_name) {
          expertise = talents[0].talents_name;
        }
        let user = await this.getSingleUserById({ user_id });

        data.email = user.email;
        data.expertise = expertise;
        data.location = location;
        data.sender_name = notification.name;
        data.sender_short_name = notification.name.split(" ")[0];
        data.receiver_short_name = this.authUser.firstname;
        data.full_name = this.authUser.firstname + ' ' + this.authUser.name;
        data.link = APIURLS.SITELINK;
        Axios.post(`${APIURLS.CONNECTIONS}accept`, data)
          .then(async ({ data }) => {
            //console.log('data', data);
            Axios.get(`${APIURLS.LINKEDINSERVERLINK}/request/` + user_id);
            this.$noty.success("Success");
          })
          .catch((error) => {
            this.$noty.error(error.response.data.message);
          });
      }

      //then  delete the notification
      await this.deleteNotif({ notif_id: notification.id });
      //delete it from store
      this.getUserNewNotifications.splice(index, 1);
    },
    async getNotifs() {
      let type = "all";
      let user_id = this.authUser.id;
      await this.loadUserNotifications({ user_id, type });
      this.notifications = this.getUserNotifications;
      this.likesComments = await this.notifications.filter(item => item.type === "like" || item.type === "comment");
    this.requests = await this.notifications.filter(item => item.type === "request");
    this.mentions = await this.notifications.filter(item => item.type === "mention");

      this.views = await this.notifications.filter(item => item.type === "view");
    if(this.authUser.type === 2){
     
      this.followers = await this.notifications.filter(item => item.type === "follow");
    }
      // console.log("notification", this.notifications)
    },
    async goToProfile(user_id){
      if (user_id) {
        let request = await this.getUsernameById({ user_id });
        console.log({ request })
        let username = request.user;
        let slug = request.slug;
        let type = request.type;
        let url;
        if (type === 1) {
          url = `/${this.$i18n.locale}/profile/${username}`;
        } else {
          url = `/${this.$i18n.locale}/startup/${slug}`;
        }

        window.location.href = url;

        // alert(username);

      }
    },
    async getRoute(notification) {
     
      this.isMobiletoggle = !this.isMobiletoggle;
      this.isDesktoptoggle = false;
      let url = "";
      let user_id = this.authUser.id;
      const notif_id = notification.id;

      await this.setViewedNotif({ user_id, notif_id });
      if (notification.type === "like" || notification.type === "comment") {
        if (notification.post_uuid) {
          url = "/" + this.$i18n.locale + "/home/post_uuid=" + notification.post_uuid;
          window.location.href = url;
          if (this.$route.path != url) {
            this.$router.push({ path: url });
          }
        } else {
          return;
        }

      } else if (notification.type === "request") {
        return;
      }
      else if (notification.type === "view" || notification.type === "follow") {
        await this.goToProfile(notification.sender_id)
      }
      else {
        if (notification.post_uuid) {
          url = "/" + this.$i18n.locale + "/home/post_uuid=" + notification.post_uuid;
          // await this.setViewedNotif({ user_id, notif_id });
          // this.$router.push(url).catch(err => {}).catch(err => {});
          if (this.$route.path != url) {
            this.$router.push(url);
          }
        } else {
          this.$noty.error(this.$i18n.t("postNotFound"));
        }

      }


      

      //notification.type === "follow" should take you to that person's profile 


      
      // if (notification.type === "request") {
      //   url =
      //     "/" +
      //     this.$i18n.locale +
      //     "/" +
      //     this.$i18n.t("routes.network") +
      //     "/" +
      //     this.$i18n.t("routes.pendingRequests");
      // } else if (notification.type === "accept") {
      //   url =
      //     "/" +
      //     this.$i18n.locale +
      //     "/" +
      //     this.$i18n.t("routes.network") +
      //     "/connections";
      // } else if (notification.type === "follow") {
      //   url = "/" + this.$i18n.locale + "/" + this.$i18n.t("routes.followers");
      // }
      // //   else if (notification.type === "message") {
      // //     //console.log("notification", notification);
      // //     let user_id = notification.sender_id;
      // //     let user = await this.getUserById({ user_id });
      // //     await this.openChatBoxWithUser(user);
      // //   }
      // else {
      //   url = "/" + this.$i18n.locale + "/notifications";
      // }
    },
    async removeNotif(notification) {
      this.$store.dispatch("deleteNotif", { notif_id: notification.id })
        .then((success) => {
          this.$noty.success(this.$i18n.t("notifDeleted"));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    async deleteAllNotif() {
      let user_id = this.authUser.id;
      this.$store.dispatch("deleteAllNotif", { user_id }).then((res) => {
          this.$noty.success(this.$i18n.t("notifDeleted"));
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    filterNotifByAll() {
    this.notifications = this.getUserNotifications.filter(item => item.type === "like" || item.type === "comment");
    //console.log("new notification", this.getUserNotifications.filter(item => item.type === "like" || item.type === "comment"))
    this.tabIndex = 0;
    return  this.notifications
    },
    filterNotifByLikesComments() {
    this.likesComments = this.getUserNotifications.filter(item => item.type === "like" || item.type === "comment");
    this.tabIndex = 1;
    },
    filterNotifByRequests() {
    this.requests = this.getUserNotifications.filter(item => item.type === "request");

    this.tabIndex = 2;
    },
    filterNotifByMentions() {
    this.mentions = this.getUserNotifications.filter(item => item.type === "mention");
    console.log("new notification", this.getUserNotifications.filter(item => item.type === "like" || item.type === "comment"))
    this.tabIndex = 3;
    },
    filterNotifByViews() {
    this.views = this.getUserNotifications.filter(item => item.type === "view");
    this.tabIndex = 4;
    },
    filterNotifByFollowers() {
    this.followers = this.getUserNotifications.filter(item => item.type === "follow");
    this.tabIndex = 5;
    },

  },

  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notifications",
  components: {
    NoDataFound,
    Trash,
    Clock,
    MessageCircle,
    UserPlus,
    // At,
    NotyIcon,
    ClearAll,
    Eye,
    Users
  },
};
</script>

<style scoped>
.top-head {
  color: #000000 !important;
  min-height: 50px !important;
  padding: 5px 4rem !important;
  padding-bottom: 0;
  margin: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  font-weight: 700 !important;
    font-size: 23px !important;
    line-height: 28px !important;
    color: #329E4D !important;
  /* background-color: rgba(255, 255, 255, 0.051) !important; */
  /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0 0 0 2px rgba(255, 255, 255, 0.064), 0.3em 0.3em 1em rgba(0, 0, 0, 0.03); */
}
.link-special {
  text-align: center;
  background-color: #eaefec;
  border-radius: 20px;
  border: 1px solid #dce0de;
  font-size: 22px;
  color: #6f6f6f;
  font-weight: 400;
  font-size: 14px;
  height: 30px;
  padding: 0px 15px !important;
  box-shadow: 0 4px 4px 1px rgba(85, 84, 83, 0.19),
    0 2px 3px 0 rgba(83, 82, 81, 0.17);
    font-weight: bold;
}

.delete-button {
  text-align: center;
  background-color: transparent;
  font-size: 22px;
  color: rgb(88, 88, 88) !important;
  font-weight: 400;
  font-size: 15px;
  position: absolute;
  z-index: 20;
  top:20px;
  right: 40px;
  border: none;
  width: 35px;
  height: 35px;
  background: #0000001c;
  border-radius: 50%;
  opacity: 0.5;
}

.link-special:hover {
  background-color: red;
  color: white !important;
  
}

.delete-button:hover {
  background-color: transparent;
  color: red !important;
   opacity: 1 !important;
}

.left {
  float: left;
}
.right {
  float: right;
}
.notifications-page {
  /* width: 40%; */
  margin: 0 !important;
  max-width: 990px !important;
  width: 100%;
}
ul,
li {
  list-style: none;
  padding: 0;
}

.container {
  display: flex;
  justify-content: left !important;
  align-items: flex-start !important;
  width: 100% !important;
  padding: 3rem 0;
  min-height: 95vh;
}
.padd-20 {
    padding: 3rem 4rem;
}

.wrapper {
  /* background: #8bd4b685; */
  padding: 2rem 0rem;
  padding-top: 1rem;
  width: 100% !important;
  border-radius: 15px;
  /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    width: 100%;
/* max-width: 800px; */
}

h1 {
  font-size: 1.1rem;
  font-family: sans-serif;
}
span.font-weight-bold {
    font-size: 20px;
}
span.font-weight-bold icon {
    width: 55px;
    /* background-color: rgba(0, 0, 0, 0.11); */
    border-radius: 50%;
}
.sessions {
  /* margin-top: 2rem; */
  border-radius: 12px;
  position: relative;
}

.sessions li p,
.sessions li time {
  color: rgb(0, 0, 0) !important;
  /* font-weight: 600 !important; */
}

.sessions li {
  color: rgb(0, 0, 0) !important;
  font-weight: 600 !important;
}

.sessions .viewedNotif p,
.sessions .viewedNotif time {
  color: rgb(66, 66, 66) !important;
  font-weight: normal !important;
}

li {
  padding-bottom: 1.5rem;
  /* border-left: 1px solid var(--first-color) !important; */
  position: relative;
  /* padding-left: 20px; */
  /* margin-left: 10px; */
  justify-content: left !important;
  align-items: flex-start !important;
  list-style: none !important;
  width: 100%;
  /* background-color: red; */
  border-bottom: 2px solid rgba(0, 0, 0, 0.03);
  padding: 15px 4rem;
}
li:last-child {
  border: 0px;
  padding-bottom: 0;
  border-bottom: none;
  
}
li:hover{
  background-color: rgba(0, 128, 0, 0.064);
  cursor: pointer;
}
/* li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: white;
  border: 2px solid var(--first-color);
  border-radius: 50%;
  position: absolute;
  text-align: left !important;
  left: -8px;
  top: 0px;
} */
::marker {
    display: none !important;
}

.time {
  color: #2a2839;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: left !important;
  margin-top: 5px;
  opacity: 0.5;
}
.time-clock icon svg{
    width: 16px;
  }
.time-clock{
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.post {
  /* background-color: #eeeeee; */
  padding: 2px 0px;
  /* padding-left: 20px; */
  /* border: 1px solid #dddddd65; */
  border-radius: 5px;
  margin-top: 3px;
  padding-right: 15px;

  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  /* font-weight: 500; */
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  color: #000000;


  display: flex;
  flex-wrap: wrap;
}
.post-text {
  /* background-color: #cccccc6c; */
  color: #000000;
  font-weight: 500;
  font-style: italic;
  /* padding: 10px !important;
  border: 1px solid #dddddd65;
  border-radius: 5px !important; */
}
.noty-name{
  font-weight: bold;
  margin-right: 4px;
}
.noty-avatar{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.request-btns-container{
  display: flex;
  padding: 5px 0;
}
.noty-request-btn {
padding: 5px 15px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
border-radius: 9px;
border:none !important;
font-weight: 400;
font-size: 13.5px;
line-height: 16px;
margin-right: 5px;
}
.accept{
  background: #329E4D;
  color: white;

}
.view{
background: #FFFFFF;
border: 1px solid #DCDCDC;
}
.view:hover{
color: #FFFFFF;
background: #329E4D;
}

.filter-row{
  width: 100%;
  /* background: red; */
  display: flex;
  padding: 0.5rem 4rem;
  padding-top: 1rem;
}
.filter-btn {
  padding: 5px 15px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
border-radius: 9px;

font-weight: 400;
font-size: 13.5px;
line-height: 16px;
margin-right: 5px;
background: #FFFFFF;
border: 1px solid #DCDCDC;
display: flex;
align-items: center;
justify-content: center;
Transition: all 0.2s ease-in-out;
}
.filter-btn span {
  margin-left: 5px;
  font-weight: 600;
  opacity: 0.5;
}
.active{
  background: rgba( 50, 158, 77, 0.21);
  border: 1px solid rgba(0,0, 0, 0.12);

}
.active span{
  color: #329E4D;
  opacity: 1;
}
.active svg{
  fill: red !important;
}
@media screen and (min-width: 601px) {
  .time {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 600px) {
  .time {
    margin-bottom: 0.3rem;
    font-size: 0.85rem;
  }
}

p {
  color: #4f4f4f;
  font-family: sans-serif;
  line-height: 1.5;
  margin-top: 0.4rem;
}
.noty-top{
  display: flex;
  width: 98%;
  position: relative;
  z-index: 1 !important;

}
.noty-left {
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #D9D9D9;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noty-right {
    width: calc(100% - 70px);
    padding-left: 20px;
    position: relative;
}


@media screen and (max-width: 950px) {
  .notifications-page {
    width: 100% !important;
  }
}


@media screen and (max-width: 830px) {
  .container{
    margin-top: 20px;
  }
  .top-head{
    padding: 5px 2rem !important;
  }
  
  .filter-row {
    padding: 0.5rem 1rem ;
    padding-top: 1rem;
}
li{
  padding: 15px 2rem;
}
}
@media screen and (max-width: 750px) {
  .notifications-page {
    width: 100% !important;
  }
  .top-head{
    padding: 5px 1rem !important;
  }
  .filter-row{
    width: 100vw;
    overflow-x: scroll;
}
  .filter-btn icon{
    display: none;
  }
  .filter-btn span{
    margin-left: 0px;
    max-width: 100px; 
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}
  li{
  padding: 8px 1rem;
}

}
@media screen and (max-width: 600px) {
  p {
    font-size: 0.9rem;
  }
  .container{
    
    min-height: 100vh;
    margin-top: 0;
}
.post{
  font-size: 14px;
  /* line-height: 21px; */
}
.delete-button {
    right: 27px;
}
.noty-right{ 
    padding-left: 7px;
   
}
.sessions{
    margin-bottom: 70px;
}




}
@media screen and (max-width: 500px) {
  .notifications-page {
    width: 100% !important;
  }
}
</style>

<style>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
ul *::-webkit-scrollbar {
    display: inherit !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@media screen and (max-width: 600px) {
  .app .page-content {
    padding-top: 0px !important;
}
.padd-20  .card{
  width: 100% !important;
}
}

</style>
