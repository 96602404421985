<template>
  <span
    class="mb-3 mx-3 typing"
    v-if="getTyping && getCurrentlyChattingWith.id === getTypingUser.user_id"
    >{{ getTypingUser.name }} {{ $t("isTyping") }}
  </span>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getTyping", "getTypingUser", "getCurrentlyChattingWith"]),
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    getByKeyword(list, keyword) {
      this.search = this.search.trim().toLowerCase();
      if (this.search.length) alert("can search");
      //return list.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    },
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Typing",
  props: {
    user: String,
  },
};
</script>

<style scoped>
.typing {
  font-size: 12px;
  color: rgb(143, 143, 143);
  font-style: italic;
}

.typing:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>