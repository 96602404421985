<template>
  <div>
    <div class="p-3">
      <button
        @click="getComment('Awesome!!')"
        type="button"
        class="btn btn-outline-primary btn-sm mr-1"
      >
        Awesome!!
      </button>
      <button
        @click="getComment('😍')"
        type="button"
        class="btn btn-light btn-sm mr-1"
      >
        😍
      </button>
      <button
        @click="getComment('Whats it about?')"
        type="button"
        class="btn btn-outline-secondary btn-sm mr-1"
      >
        Whats it about?
      </button>
      <button
        @click="getComment('Oooo Great Wow')"
        type="button"
        class="btn btn-outline-secondary btn-sm mr-1"
      >
        Oooo Great Wow
      </button>
    </div>
    <div class="row col-md-12">
      <div class="col-md-12">
        <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              class="textarea p-2 mr-4 mb-4 form-control  border-0 p-0 shadow-none"
              placeholder="Add Comment..."
              v-model="comment"
              @input="resize"
              v-on:keyup.enter="addComment"
            ></textarea>
          </template>
        </ResizeAuto>
      </div>
    </div>
  </div>
</template>

<script>
import ResizeAuto from '../ResizeAuto';
import Axios from 'axios';
import APIURLS from '../../constant';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters(['authUser', 'getInterrestList']),
    ...mapActions(['loadInterrestList']),
  },
  methods: {
    getComment(text) {
      if (text) {
        Axios.post(`${APIURLS.COMMENTS}create`, {
          newsId: this.newsId,
          user_id: this.authUser.id,
          text: text,
        })
          .then(({ data }) => {
            //this.allComments.unshift(data.comment);
            this.comment = null;
          })
          .catch((error) => {
            console.log('error', error);
            this.$noty.error(error.message);
          });
      }
    },
    addComment() {
      if (this.comment) {
        Axios.post(`${APIURLS.COMMENTS}create`, {
          newsId: this.newsId,
          user_id: this.authUser.id,
          text: this.comment,
        })
          .then(({ data }) => {
            //this.allComments.unshift(data.comment);
            this.comment = null;
          })
          .catch((error) => {
            console.log('error', error);
            this.$noty.error(error);
          });
      }
    },
  },
  name: 'AddComment',
  components: {
    ResizeAuto,
  },
  props: {
    newsId: String,
  },
  data() {
    return {
      comment: '',
    };
  },
};
</script>

<style scoped lang="css">
.textarea {
  overflow-y: hidden;
  resize: none;
  border-color: #edf2f7;
  background-color: #edf2f7;
  border-width: 5px;
  border-radius: 5px;
  width: 100%;
  appearance: none;
}
</style>
