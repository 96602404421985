<template>
  <button
    :class="variantClass"
    :disabled="disabled"
    class="btn"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "VButton",
  props: {
    variant: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    variantClass() {
      return `btn-${this.variant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  background: #e6e6e6 padding-box;
  // border: 2px solid #aaaaaa;
  padding: 10px 0;
  color: #6a6a6a;
  border-radius: 2rem;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;

  &:not(:disabled) {
    &:hover {
      //background: #cecdcd;
      opacity: 0.5;
    }

    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  &:disabled {
    cursor: not-allowed !important;
  }

  $this: &;

  &#{$this}-green {
    background: #e4f4e9;
    //
    color: #0f8d42;
  }

  &#{$this}-red {
    background: #f5d6d6;
    border: 0.1px solid #f5d6d6;
    color: #dd1010;
  }

  &#{$this}-disabled {
    background: #ebebeb;
    border: 0.1px solid #ebebeb;
    color: #707070;
  }
}

@media only screen and (min-width: 600px) {
  .btn {
    max-width: 250px;
  }
}
</style>
