import { render, staticRenderFns } from "./ReferalModal.vue?vue&type=template&id=87fd8ef6&scoped=true&"
import script from "./ReferalModal.vue?vue&type=script&lang=js&"
export * from "./ReferalModal.vue?vue&type=script&lang=js&"
import style0 from "./ReferalModal.vue?vue&type=style&index=0&id=87fd8ef6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87fd8ef6",
  null
  
)

export default component.exports