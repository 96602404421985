<template>
  <div>
    <div class="box shadow-sm rounded bg-white mb-3">
      <ul class="nav nav-justified osahan-line-tab" id="myTab" role="tablist">
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('DiasporaFr') || activeMenu('DiasporaEn')"
            :to="`/${$i18n.locale}/diaspora`"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            ><i class="feather-globe"></i> Diaspora
            <span v-if="diasporaCount">({{ diasporaCount }})</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('MentorsFr') || activeMenu('MentorsEn')"
            :to="`/${$i18n.locale}/mentors`"
            role="tab"
            aria-selected="false"
          >
            <i class="feather-users text-dark"></i> Mentors
            <span v-if="mentorsCount">({{ mentorsCount }})</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('ConsultantsEn') || activeMenu('ConsultantsFr')"
            :to="`/${$i18n.locale}/consultants`"
          >
            <i class="feather-star text-dark"></i>
            Consultants
            <span v-if="consultantsCount">({{ consultantsCount }})</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link connection-aside"
            :class="activeMenu('FollowersEn') || activeMenu('FollowersFr')"
            :to="`/${$i18n.locale}/${this.$i18n.t('routes.followers')}`"
          >
            <i class="feather-check-circle text-dark"></i>
            {{ $t("followers") }}
            <span v-if="followersCount">({{ followersCount }})</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  computed: {},
  methods: {
    activeMenu(name) {
      let urlName = this.$route.name;
      if (urlName === name) return "active ";
    },
  },
  name: "StartupAside",
  props: {
    diasporaCount: Number,
    followersCount: Number,
    mentorsCount: Number,
    consultantsCount: Number,
  },
};
</script>

<style scoped>
a.nav-link.router-link-exact-active.router-link-active {
  border: none !important;
}
.connection-aside.active {
  font-weight: bold;
  background: #49af66 !important;
  color: #fff !important;
  border: 5px solid white !important;
  border-radius: 10px !important;
  margin: 5px;
}

.connection-aside.active,
.connection-aside i {
  font-weight: bold;
  color: #fff !important;
}
.connection-aside {
  margin: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 1rem !important;
  color: #888da8;
  position: relative;
}
.aside-menu li:hover {
  font-weight: bold;
  color: #0f7f12 !important;
}
</style>
