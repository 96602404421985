<template>
  <div class="placeholder">
    <div class="jobsBoard"
    :class="{ showNotVerifiedNotfication: authUser.is_verified === 0 }">
      <div v-if="jobs" class="wow">
        <!-- <div class="flex-row">
          <Searchbar />
        </div> -->
        <div class="cards-grid">
          <JobCard :job="job" v-for="job in jobs" :key="job.id" />
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info m-4 text-center" role="alert">
          <h4 class="alert-heading pt-4">{{ $t("emptyJobMessage") }}</h4>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import JobCard from "./JobCards.vue";
import Searchbar from "../Startups/Searchbar_jobs.vue";
import i18n from "../../i18n";
export default {
  async created() {
    await this.loadInterrestList;
    this.tags = this.getInterrestList;
  },
  computed: {
    ...mapGetters(["authUser", "getInterrestList"]),
    ...mapActions(["loadInterrestList"]),
  },
  data() {
    return {
      tags: {},
      showFilter: false,
      search: {},
    };
  },
  methods: {},
  name: "JobsList",
  props: {
    jobs: {},
    type: {
      type: String,
      default: "all",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    sent: {
      type: Boolean,
      default: false,
    },
    received: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: "NoConnections",
    },
  },
  components: {
    JobCard,
    //Searchbar,
  },
};
</script>

<style scoped>
.jobsBoard {
  background: #f8f8f8 !important;
}
.showNotVerifiedNotfication{
  margin-top: 48px;
}
.cards-grid {
  max-width: 100%;
  /* margin: 0 auto; */
  display: grid;
  grid-gap: 1rem;
  background: transparent !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 1rem !important;
  padding: 0 1% !important;
  margin: 0.8% 0 !important;
  overflow-y: scroll !important;
}

@media (max-width: 600px) {
}
@media (min-width: 600px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>

<style scoped>
.mainBackground {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  padding: 0 1%;
  margin: 0.8% 0;
  overflow-y: scroll;
}
.form-inline-2 {
  width: 100% !important;
}
.clear-icon-container {
  position: relative;
  right: 5px;
  height: 100%;
  z-index: 9999;
}
.clear-icon-container button {
  background: rgb(176, 187, 186);
  width: 35px;
  height: 35px;
  border: 2px solid white;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear-icon-container :hover {
  background: rgb(146, 146, 146);
}
.clear-icon-container button svg {
  color: rgba(0, 0, 0, 0.6);
  width: 10px;
  height: 10px;
}

img.search-img {
  width: 20px !important;
  height: 20px !important;
  padding: 0;
}

.searchbar {
  position: relative;
  box-sizing: border-box;
  padding: 0.3rem !important;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  height: 55px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
}

.search-img {
  margin-left: 1.5rem;
}
.searchbar input[type="text"] {
  border: none !important;
  font-size: 0.85rem;
  padding: 0 1.25rem !important;
  color: #000;
}
.searchbar input[type="text"]::placeholder {
  color: #8c8c8c;
  font-size: 0.85rem;
}
.searchbar input[type="text"]:focus {
  outline: none !important;
}

.btn-search {
  position: absolute;
  right: 10px;
  background-color: #39a251;
  color: #fff;
  border: 1px solid rgb(255 255 255 / 27%);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.7rem 3.5rem;
}
.btn-search:hover {
  background: #18792f;
}

@media (max-width: 991px) {
  .searchbar {
    padding: 0.7rem;
  }
  .search-img {
    padding: 0 0.5rem 0 0;
  }

  .searchbar input[type="text"] {
    padding: 0 0.5rem 0 0;
    font-size: 16px;
  }
  .btn-search {
    display: none;
    /* font-size: .875rem;
    padding: .65rem 2.5rem; */
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    height: 100vh;
  }
  div#home {
    /* background: red; */
    display: flex;
    align-items: center;
    /* max-width: min(78vw, 1140px); */
    max-width: 990px;
  }
  .mainBackground {
    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 1rem;
    padding: 0 0%;
    margin: 0.8% 0;
    /* min-width: min(78vw, 1140px) !important;  */
    width: 100%;
    min-height: 80vh;
  }
  .wow {
    width: 100%;
  }
  .flex-start {
    display: flex;
    align-items: flex-start;
    /* background:red; */
    align-items: flex-start !important;
    /* background: red; */
    justify-content: flex-start !important;
    height: 100%;
  }
  .full-view {
    height: 100%;
  }
  .emptyCard {
    height: 70vh;
    /* background-color: pink; */
  }
  /* individual cards */
  .col-xl-3.col-lg-4.col-md-6.mb-3 {
    max-height: 400px;
  }
  .flex-row.no-search {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
  }
  .alert.alert-info.m-4.text-center {
    /* height: 300px; */
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    background: white;
    height: 100%;
    width: 100%;
  }
  .alert-card {
    background-color: transparent;
    max-width: 100%;
    height: 200px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 574px) {
  .searchbar {
    padding: 0 !important;
  }
  .alert.alert-info.m-4.text-center {
    /* height: 300px; */
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    background: white;
    height: 100%;
    width: 100%;
    margin: 0 !important;
  }
  .alert-card {
    background-color: transparent;
    max-width: 100vw;
    height: 60vh;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  .flex-row.no-search {
    background-color: transparent;
    width: 100%;
    height: 70vh;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .flex-start {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .flex-row {
    margin-top: 45px;
    padding-top: 0;
    padding-bottom: 50px;
  }
  .wow {
    padding-top: 10px;
  }
}
</style>
