<template>
  <button
    type="submit"
    class="form__button"
    :value="$t(value)"
    :disabled="loading"
  >
    <i class="fa fa-spin fa-1x fa-spinner" v-if="loading && hasLoader"></i>
    {{ loading ? " " : $t(value) }}
  </button>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "SubmitBtn",
  props: {
    value: String,
    hasLoader: Boolean,
    loading: Boolean,
  },
  methods: {
    commitStep1() {},
    ...mapMutations(["gotoNext", "goPrevious", "setStep"]),
  },
};
</script>

<style scoped>
.form__button {
  display: block;
  margin-left: auto;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  background-color: var(--primary-green);
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  /* height: 74px; */
  letter-spacing: 1px;
  margin-top: 10px;
}
.form__button:hover {
  box-shadow: 0 10px 36px rgba(25, 111, 1, 0.324);
  padding-left: 1rem;
}
@media only screen and (max-width: 600px) {
  .form__button {
    text-align: center;
  }
}
@media only screen and (max-width: 320px) {
  .form__button {
    padding: 0.5rem 2rem;
    font-size: 18px;
    border-radius: 12px;
  }
}
</style>