<template>
  <div class="overview" :class="{ fixOverflow: showEditButtons }">
    <Header title="Overview" />
    <Body
      :listData="listData"
      type="list"
      :editState="editing"
      :startup_id="startup_id"
      :isWebsiteLinkError ="isWebsiteLinkError"
    />
    <template v-if="getStartupEditingMode">
      <edit-button
        class="edit-button"
        v-if="showEditButtons && !editing"
        @click.native="toggleEditMode"
        text="edit"
      />
      <edit-button
        class="edit-button"
        v-if="editing"
        @click.native="save"
        text="save"
        :isSave="true"
      />
    </template>
  </div>
</template>
<script>
import Header from "./ShareElements/Header.vue";
import Body from "./ShareElements/Body.vue";
import EditButton from "../../../components/Profile/Diaspora/profile/buttons/LabeledEditButton.vue";
import SaveCancelButtonGroup from "../../../components/Profile/Diaspora/profile/buttons/SaveCancelButtonGroup.vue";
import i18n from "../../../i18n";
import { mapGetters, mapActions } from "vuex";
var psl = require("psl");
export default {
  // watch: {
  //   async $route() {
  //     await this.getSector();
  //   },
  // },
  async mounted() {
    if (this.getStartupOverviewForm.website === ' ' || this.getStartupOverviewForm.website) {
      this.isWebsiteLinkError = false;
    }
    if (this.startup) this.startup_id = this.startup.id;
    if (!this.showEditButtons) {
      this.editing = false;
    }
    let website = null;
    if (this.startup.website) {
      var parsed = psl.parse(this.startup.website);
      website = parsed.domain;
    }
    await this.getSector();
    await this.getAfricanCountries();
    await this.getAllSectors();
    await this.loadStartupTypeOfSupport({ startup_id: this.startup.id });
    if (
      this.getUserSupportTypes.filter(function (e) {
        return e.typeOfSupport === "Invest";
      }).length > 0
    ) {
      this.seekingFunding = "yes";
    } else {
      this.seekingFunding = "no";
    }
    this.listData[0].value = this.startup.numbInTeam;
    this.listData[1].value = this.startup.foundingYear;
    this.listData[2].value = this.startup.stage;
    this.listData[3].value = website;
    this.listData[4].value = this.seekingFunding;
    this.listData[5].value = this.startup.business_model;
    this.listData[6].value = this.startup.revenue_state;
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Overview",
  components: {
    Header,
    Body,
    EditButton,
    //SaveCancelButtonGroup,
  },
  props: {
    startup: Object,
    // seekingFunding: String,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getStartupEditingMode",
      "showEditButtons",
      "getSectorstList",
      "getCountriesList",
      "getStartupOverviewForm",
      "getUserSupportTypes",
    ]),
  },
  data() {
    return {
      seekingFunding: "",
      startup_id: 0,
      countries: null,
      africanCountries: [],
      allSectors: [],
      sector: "",
      isEditMode: false,
      editing: false,
      year: 0,
      isWebsiteLinkError: false,
      listData: [
        // {
        //   label: "Industry",
        //   value: "",
        //   code: "",
        // },
        {
          label: "Employees",
          value: "",
          code: "",
        },
        // ƒƒ
        {
          label: "Founded",
          value: 0,
          code: "",
        },
        {
          label: "Stage",
          value: "",
          code: "",
        },
        {
          label: "website",
          value: "",
          code: "",
        },
        {
          label: "SeekingFunding",
          value: "",
          code: "",
        },
        {
          label: "BusinessType",
          value: "",
          code: "",
        },
        {
          label: "RevenueStatus",
          value: "",
          code: "",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getSectorByLang",
      "getAFricanCountryByLang",
      "getForeignCountryByLang",
      "loadSectorsList",
      "loadCountriesList",
      "loadStartupTypeOfSupport",
      "addOrDeleteStartupSupport",
    ]),
    async getAllSectors() {
      var lang = i18n.locale;
      await this.loadSectorsList({ lang });
      this.getSectorstList.forEach((sector) => {
        this.allSectors.push({
          id: sector.id,
          code: sector.code,
          name: sector.name,
        });
      });
    },
    async getAfricanCountries() {
      var lang = i18n.locale;
      await this.loadCountriesList({ lang });
      this.getCountriesList.forEach((country) => {
        this.africanCountries.push({
          id: country.id,
          code: country.code,
          name: country.name,
        });
      });
    },
    toggleEditMode() {
      this.editing = true;
    },

    async save() {
      if (this.getStartupOverviewForm.seekingFounding === "Yes") {
        //add invest in the list of startups type of support
        await this.addOrDeleteStartupSupport({
          startup_id: this.startup.id,
          action: "add",
        });
      } else {
        //delete invest in the list of startups type of support
        await this.addOrDeleteStartupSupport({
          startup_id: this.startup.id,
          action: "delete",
        });
      }
      // this.getStartupOverviewForm.website === ' '
      //   || this.getStartupOverviewForm.website.includes(".com")
      //   || this.getStartupOverviewForm.website.includes(".net")
      //   || this.getStartupOverviewForm.website.includes(".tech")
      //   || this.getStartupOverviewForm.website.includes(".io")
      //   || this.getStartupOverviewForm.website.includes(".org")
      //   || this.getStartupOverviewForm.website.includes(".me")
      // || this.getStartupOverviewForm.website.includes(".co")
      let tempLink   = this.getStartupOverviewForm.website || this.startup.website
      // console.log("this.getStartupOverviewForm.website.lenght", this.getStartupOverviewForm.website);
      // console.log("website likn = ", this.startup.website)
      // console.log(" templink = ", tempLink)
      if (tempLink.includes(".") || this.getStartupOverviewForm.website ==='' || this.getStartupOverviewForm.website === undefined || tempLink === '' ) {
        this.$store
        .dispatch("updateStartup", {
          sector: this.getStartupOverviewForm.sector,
          location: this.getStartupOverviewForm.location,
          foundingYear: this.getStartupOverviewForm.foundingYear,
          website: this.getStartupOverviewForm.website,
          stage: this.getStartupOverviewForm.stage,
          numbInTeam: this.getStartupOverviewForm.numbInTeam,
          revenue_state: this.getStartupOverviewForm.revenue_state,
          business_model: this.getStartupOverviewForm.business_model,
        })
        .then(async () => {
          this.editing = false;
          this.$snotify.success(this.$i18n.t("success"), "Success");
          this.$router.go();
        })
        .catch((error) => {
          this.$noty.error(error);
        });
        // if (!this.getStartupOverviewForm.website.includes("."))
      } else {
        this.isWebsiteLinkError = true;
        this.$noty.error("Please enter a valid website URL");
      }
      // console.log("entered url  = ", this.getStartupOverviewForm.website)
      
    },
    cancel: function () {
      this.editing = false;
    },
    async getSector() {
      let lang = this.$i18n.locale;
      let code = this.startup.sector;
      if (this.startup.sector != null) {
        this.sector = await this.getSectorByLang({ code, lang });
      } else {
        this.sector = "";
      }
      // this.sector = await this.getSectorByLang({ code, lang });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.overview {
  width: 40%;
  height: 100%;
  margin-left: 10px;
  /* padding-bottom: 10px; */
  padding-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
  margin-top: 0px;
  position: relative;
  overflow-y: scroll;
}
.fixOverflow {
  overflow: inherit;
  /* overflow-y: scroll; */
  height: auto;
}
.edit-button {
  position: absolute !important;
  right: 20px;
  /* top: 20px; */
  top: 5px;
  padding: 5px 20px;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  z-index: 99;
}

.btn-wrapper {
  position: relative;
  background: white;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrapper .btn-edit {
  width: 25px;
  height: 25px;
  padding: 5px;
  line-height: 0;
  border: 1px solid hsla(0, 0%, 43.9%, 0.002);
  border-radius: 15px;
  background-color: hsla(0, 0%, 54.9%, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrapper .btn-edit {
  position: absolute;
  left: 2px;
}
@media (max-width: 600px) {
  .overview {
    width: 100vw;
    border-radius: 0;
    margin-top: 0.5px;
    margin-left: 0;
  }
  .edit-button {
    right: 20px;
    top: 8px;
  }
}
</style>
