<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
      
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option.name;
          open = false;
          $emit('input', option[selectedOption]);
        "
      >
        {{ option.name }}
      </div>
    </div>
     <input 
    class="search-input"
    type="text" placeholder="search for ...."/>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$emit("input", this.selected);
  },
  props: {
    selectedOption: {
      type: String,
      default: "code",
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },

  methods: {
    // onOptionChange (option) {
    //   this.$emit('onOptionChange', 'someValue')
    // }
  },
};
</script>

<style scoped>
* {
  outline: none;
}
.custom-select {
  position: relative;
  width: 250px;
  text-align: left;
  outline: none;
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 900;
  /* border-radius: 2px;  */
  background: red;
}

.search-input{
    background-color: green;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
}
.custom-select .selected {
  /* border-radius: 2px; */
  border: 1px solid #666666;

  color: #1c1c1c;
  padding: 0;
  padding-left: 1em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #fff;
  /* border: 1px solid #d9d9d9; */
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid #00853c30;
  font-size: 16px;
}

.custom-select .selected.open {
  /* border: 1px solid #666666; */
  /* border-radius: 6px 6px 0px 0px; */

  border: 2px solid #00853c;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #000000d9;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: 190px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.custom-select .items div {
  /* color: #fff; */
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #d9d9d950;
}

.custom-select .items div:hover {
  background-color: rgba(255, 255, 255, 0.267);
}

.selectHide {
  display: none;
}
</style>
