<template>
  <div class="box shadow-sm border rounded bg-white mb-3">
    <div class="box-title border-bottom p-3 text-center">
      <h6 class="m-0">{{ $t('recentConnections') }}</h6>
    </div>
    <div class="box-body p-3">
      <div
        class="d-flex align-items-center osahan-post-header mb-3 people-list"
        v-for="(connection, index) in Connections"
        :key="index"
      >
        <div class="dropdown-list-image mr-3">
          <img class="rounded-circle" :src="connection.avatar" alt="avatar" />
          <!-- <div class="status-indicator bg-success"></div> -->
        </div>
        <div class="font-weight-bold mr-2">
          <div class="text-truncate">
            <a :href="`/${$i18n.locale}/profile/${connection.username}`">{{
              connection.name
            }}</a>
          </div>
          <div class="small text-gray-500">@{{ connection.username }}</div>
        </div>
        <span class="ml-auto"
          ><button type="button" class="btn btn-light btn-sm">
            <i class="feather-message-square"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import APIURLS from '../../constant';
import i18n from '../../i18n';
export default {
  created() {},

  data() {
    return {};
  },
  methods: {},
  name: 'RecentConnections',
  props: {
    Connections: {},
  },
};
</script>
