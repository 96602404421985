<template>
  <div class="work-experiences">
    <career-title @add="add()" class="title">{{
      $t("WorkExperience")
    }}</career-title>
    <div class="container-box" v-if="!adding">
      <work-experience
        v-for="(experience, index) in experiences"
        :key="index"
        :experience="experience"
      />
    </div>
    <add-experience @cancel="cancel()" v-if="adding" />
  </div>
</template>

<script>
import WorkExperience from "./WorkExperience";
import CareerTitle from "./CareerTitle";
import AddExperience from "./AddExperience";

export default {
  props: {
    experiences: Array,
  },
  components: {
    CareerTitle,
    WorkExperience,
    AddExperience,
  },
  data() {
    return {
      adding: false,
    };
  },
  computed: {},
  methods: {
    add: function () {
      this.adding = true;
    },
    cancel: function () {
      this.adding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  padding: 8px;
}
.work-experiences {
  margin-top: 15px;
}
.title {
  font-weight: bold !important;
}
</style>
