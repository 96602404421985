<template>
  <aside class="view-aside round-lg over-hidden tab-display-none">
    <div class="view-aside-head">
      <div class="view-aside-head-icon-wrapper">
        <div class="view-aside-head-icon-inner">
          <div class="circle">
            <svg
              class="view-aside-head-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="31.145"
              height="31.145"
              viewBox="0 0 31.145 31.145"
            >
              <path
                id="Icon_awesome-tags"
                data-name="Icon awesome-tags"
                d="M19.038,8.639l-8.1-8.1A1.835,1.835,0,0,0,9.639,0h-7.8A1.835,1.835,0,0,0,0,1.835v7.8a1.835,1.835,0,0,0,.538,1.3l8.1,8.1a1.835,1.835,0,0,0,2.6,0l7.8-7.8a1.835,1.835,0,0,0,0-2.6ZM4.282,6.117A1.835,1.835,0,1,1,6.117,4.282,1.835,1.835,0,0,1,4.282,6.117Zm19.65,5.117-7.8,7.8a1.835,1.835,0,0,1-2.6,0l-.014-.014,6.655-6.655a3.441,3.441,0,0,0,0-4.866L12.67,0h1.863a1.835,1.835,0,0,1,1.3.538l8.1,8.1a1.835,1.835,0,0,1,0,2.6Z"
                transform="translate(0 17.302) rotate(-45)"
                fill="#868d96"
              />
            </svg>
          </div>
        </div>
      </div>
      <h3 class="view-aside-title">Discover more News</h3>
    </div>
    <div class="view-aside-body">
      <SideNewsCard
        @click.native="goToNews(post)"
        :post="post"
        v-for="post in getSimilarNews"
        :key="post.id"
      />
    </div>
  </aside>
</template>

<script>
import Axios from "axios";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
import SideNewsCard from "./SideNewsCard.vue";
import i18n from "../../i18n";
export default {
  name: "SideNews",
  computed: {
    ...mapGetters(["authUser", "getSimilarNews"]),
  },
  methods: {
    goToNews(post) {
      window.open(`/news/${post.slug}`);
    },
  },
  components: {
    SideNewsCard,
  },
  props: {
    news: {},
  },
};
</script>

<style scoped>
.circle {
  background-color: #ccd0d3 !important;
  padding-top: 3px !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}
.view-aside {
  flex-grow: 0;
  flex-basis: 350px;
  margin: 30px 10px;
  padding-bottom: 20px !important;
  border-radius: 30px !important;
  border: 1px solid #cfd4db !important;
  margin-top: 10px;


  
  display: none;
}

.view-aside-head {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  color: #717b83 !important;
  background-color: #eaf1f9 !important;
  border-bottom: 1px solid #b2b9c37e !important;
}

.view-header .container-lg {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
}

.view-aside-title {
  font-size: 21px;
  font-weight: normal;
}

.view-aside-head-icon-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-aside-head-icon-inner {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  fill: var(--discover-head-icon-fg);

  background-color: var(--discover-head-icon-bg);
  border-radius: 40px;
}

.view-aside-head-icon {
  width: 20px;
  height: auto;
}
</style>
