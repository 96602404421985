<template>
  <transition v-if="isShow" name="modal">
    <div class="modal-mask partnership-modal" @click.self="close">
      <div class="modal-wrapper" @click.self="hide">
        <div class="modal-container reset-height">
          <div class="close-button-container">
            <button class="modal-default-button" @click="close">
              <img
                alt=""
                src="./../../assets/images/partnerships/modal-close.png"
              />
            </button>
          </div>
          <!-- <div class="modal-header bg-light-green"></div> -->
          <div class="modal-header">
            <div class="left d-flex">
              <div class="img-container">
                <template v-if="logo">
                  <img :alt="title" :src="logo" />
                </template>
                <template v-else>
                  <img
                    :alt="title"
                    src="./../../assets/images/partnerships/no-img.png"
                  />
                </template>
              </div>
              <div class="">
                <div class="partnership-modal-title">{{ featuredCompany }}</div>
                <div class="partnership-modal-subtitle">{{ title }}</div>
              </div>
            </div>

            <div class="right apply-button-header" v-if="active">
              <a
                class="btn btn-success"
                @click="apply(company_code, link, offer_code)"
              >
                <span class="btn-text">
                  <i class="fa fa-spin fa-1x fa-spinner" v-if="getLoading"></i>
                  {{ this.getLoading ? $t("loading") : $t("applyBtn") }}
                </span>
              </a>
              <slot name="header"></slot>
            </div>
            <div class="right apply-button-header" v-else>
              <span class="close-badge">{{ $t("closed") }}</span>
              <slot name="header"></slot>
            </div>
          </div>
          <div class="modal-body">
            <div class="apply-button-body" v-if="active">
              <a
                class="btn btn-outline-success my-4"
                @click="apply(company_code, link, offer_code)"
                ><i class="fa fa-spin fa-1x fa-spinner" v-if="getLoading"></i>
                {{ this.getLoading ? $t("loading") : $t("applyBtn") }}</a
              >
              <slot name="header"></slot>
            </div>
            <div class="apply-button-body" v-else>
              <span class="close-badge">{{ $t("closed") }}</span>
              <slot name="header"></slot>
            </div>
            <slot name="body"></slot>
            <div
              class="text-left text-modal mb-3"
              v-html="formatDescription"
            ></div>
            <div class="video-wrapper" v-if="videoLink">
              <template>
                <iframe
                  :src="`https://www.youtube.com/embed/${videoId}`"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  frameborder="0"
                  height="315"
                  title="YouTube video player"
                  width="560"
                ></iframe>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import modal from "@/mixins/modal";
import { mapGetters } from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Modal",
  mixins: [modal],
  props: {
    type: {
      type: String,
    },
    offer_code: {
      type: String,
    },
    logo: {
      type: String,
    },
    active: {
      type: Number,
    },
    link: {
      type: String,
    },
    company_code: {
      type: String,
    },
    videoLink: {
      type: [String, null],
    },
    featuredCompany: {
      type: String,
    },
    imgSrc: {
      type: [String, null],
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // alert(this.type);
  },
  computed: {
    ...mapGetters(["getLoading"]),
    formatDescription() {
      // return this.description.replace(/\r\n|\r|\n/g, "<br>");
      return this.description;
    },
    videoId() {
      if (this.videoLink) return new URL(this.videoLink).searchParams.get("v");
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modal";

ol li {
  list-style: decimal !important;
  list-style-position: inside !important;
}

.partnership-modal {
  color: var(--text-common-color);
  text-align: left;

  .partnership-modal-title {
    font: normal normal bold 24px/29px Helvetica;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 24px;
  }

.modal-header{
  padding: 16px 40px;
  padding-top: 0;
}
.img-container{
    position: relative;
    margin-right: 14px;
    border-radius: 12px;
    min-width: 60px;
    max-height: 60px;
    height: 60px;
    width: 60px;
    overflow: hidden;
}
.modal-wrapper{
  overflow-y: scroll;
  margin-top: 0;
  padding-top: 50px;

}
.modal-body{
  overflow: inherit;
}
.modal-container{
  max-height: inherit !important;
  height: auto;
  padding: 40px !important;
}
.partnership-modal .modal-container{
  max-height: inherit !important;
  margin: 0;
  padding: 0;
  line-height: 0;
  box-sizing: border-box;

}
.reset-height{
  max-height: inherit !important;
}
.text-modal{
    font-size: 16px;
    padding-bottom: 40px;
}
.text-modal > p{
    font-size: 16px;
    padding-bottom: 0px;
    
}
  .text-modal {
    font-size: 18px;
    // line-height: 1.1 !important;
    padding-bottom: 40px;
  }
  .text-modal ul li{
    list-style: dotted !important;
  }

  .partnership-modal-subtitle {
    font: normal normal normal 24px/35px Helvetica;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 24px;
    line-height: 32px;
    color: #4b587c;
    padding: 5px 0;
  }

  .btn-success {
    padding: 10px;
    min-width: 180px;
    margin-left: 25px;
  }

  .modal {
    &-wrapper {
      height: 100vh !important;
      display: block;
    }

    &-header {
      .left,
      .right {
        flex: 1 1 auto;
        align-items: center;
      }

      .left {
        flex-basis: 100%;
      }

      .right {
        flex-shrink: 0;
      }

      .left > div:last-child {
        width: 100%;
      }
    }

    &-container {
      margin: 10px auto;
      /* max-height: calc(100vh - 20px) !important; */
      max-height: auto;
      width: 100%;
      max-width: 990px;
      border-radius: 15px !important;
      display: flex;
      flex-direction: column;
      overflow: initial !important;
      height: auto !important;
      border-bottom: none;
      

      @media (min-width: 600px) and (max-width: 1024px) {
        min-width: calc(100% - 40px);
        max-width: calc(100vw - 40px);
      }
    }
  }

  .btn-text {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    .btn-success {
      min-width: auto;
      width: 100%;
      margin: 0;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @media (max-width: 600px) {
    .modal {
      &-wrapper {
        padding: 0;
      }

      &-container {
        padding: 0 !important;
        border-rdaius: 0;
        /* max-height: 100vh !important; */
        margin: 0;
        width: 100vw;
        border-radius: 0 !important;
        /* padding: 15px; */
        display: flex;
        flex-direction: column;
        border-bottom: none;
      }

      &-header {
        /* border-radius: 15px 15px 0 0; */
        border-radius: 0;
        border: 1px solid #01853b;
        border-bottom: none;
        flex-direction: column;
        box-shadow: 0 -4px 8px 0 rgba(164, 174, 181, 0.36);
      }

      &-body {
        /* border-radius: 0 0 15px 15px; */
         border-radius: 0;
        padding-bottom: 25px;
        border: 1px solid #01853b;
        border-top: none;
        box-shadow: 0 4px 8px 0 rgba(164, 174, 181, 0.36);
      }
    }
    .partnership-modal-title {
      font: normal normal bold 24px/29px Helvetica;
      font-size: 21px;
    }

    .text-modal {
      font-size: 16px;
    }

    .partnership-modal-subtitle {
      font: normal normal normal 24px/35px Helvetica;
      font-size: 15px;
    }

    .close-button-container {
      position: absolute;
      left: 0;
      right: auto;
      padding: 5px;
      /* background-color: #ff4743; */
      background-color: #8E8E8E;
      border-radius: 0 50% 50% 0;
      z-index: 2;
      top: 10px;

      button {
        margin: 0;
      }
    }
  }
}
</style>

<style>
*{
  box-sizing: border-box;
}
.partnership-modal .modal-container{
  max-height: inherit !important;
  border-radius: 3px;
}
.text-modal{
    display: flex;
    flex-direction: column;
    color: #21293c;
    font-size: 16px;
    line-height: 24px;
}

.text-modal > p{
  padding: 5px 0;
  font-size: 16px;
    line-height: 24px;
}
ol, ul{
  margin: 0;
  padding: 0;
}
ol li, ul li{
  list-style: dotted !important;
  padding: 5px 0;
}
.text-modal > ul, ol{
  display: flex;
  flex-direction: column;
 jusitfy-content: center;
 padding: 10px 50px;
 padding-bottom: 30px;

}
b, strong {
    /* font-weight: bolder; */
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #21293c;
}
em > span{
  color: black !important;
  font-weight: bolder;
  font-size: 14px;
  line-height: 21px;
}
.modal-container{
  
  border-radius: 3px;
}

.close-badge{
  background: #ffeee9;
  padding: 7px 20px;
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 8px;
    z-index: 10000;
}
@media (max-width: 600px){
  .partnership-modal .modal-container{
    max-height: inherit !important;
  }
  .reset-height{
  max-height: inherit !important;
}
.partnership-modal .modal-header{
  background-color: rgba(0, 255, 42, 0.102);
  align-items: center;
  justify-content: center;
}
.partnership-modal .modal-header .left{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  padding-bottom: 0;
  flex-direction: row;
}
.partnership-modal .img-container{
    
    margin-left: 0;
    margin-right: 0;
}
.partnership-modal .modal-header .left > div:last-child {
    margin-top: 15px;
}
.apply-button-body .btn[data-v-47db75aa] {
   
    min-width: 222px;
    width: 100%;
    background-color: var(--first-color) !important;
    color: white;
    font-size: 20px;
    padding: 10px;
}

.partnership-modal .modal-body{
  padding: 15px;
  /* padding-bottom: 50px; */
  }
.text-modal > ul, ol{
 
 padding: 10px 20px;
}
 
 
}
</style>
