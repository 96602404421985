<template>
  <div class="boxShadow flex-column justify-content-center text-center">
    <div class="container-layout">
      <div class="avatar avatar-lg mb-2">
        <img class="gravatar2 rounded-circle" :src="profile_img" alt="avatar" />
      </div>
      <h6>{{ fullname }}</h6>
      <p class="text-muted">
        {{ $t(text) }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  mounted() {
    let profileUrl = this.avatar;
    if (profileUrl && profileUrl.indexOf("upload/") != -1) {
      let index = profileUrl.indexOf("upload/");
      let first_part = profileUrl.substr(0, index + 6);
      let last_part = profileUrl.substr(index + 6);
      this.profile_img =
        first_part + "/q_auto:good/c_fill,h_50,w_50" + last_part;
    } else {
      this.profile_img = profileUrl;
    }
  },
  name: "EmptyNewConvo",
  props: {
    avatar: String,
    fullname: String,
    showButton: Boolean,
    text: String,
  },
  computed: {
    ...mapGetters([
      "authUser",
      "getCurrentlyChattingWith",
      "getIsBlocked",
      "getBlockedUser",
      "getIsNewChatConvo",
    ]),
  },
  data: () => {
    return {
      profile_img: "",
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped>
.gravatar2 {
  width: 64px;
  height: 64px;
  background-color: #eee;
  border: 3px solid #b3b3b3;
}
.svg2 {
  width: 140px;
  height: 140px;
}
.boxShadow {
  background-color: white;
  /* -webkit-box-shadow: 0px 0px 5px 1px rgba(15, 15, 15, 0.2);
  box-shadow: 0px 0px 5px 1px rgba(15, 15, 15, 0.2); */
  margin: 0px;
  padding: 20px;
  height: auto;
  border-radius: 8px;
}
.text-muted {
  /* background: red; */
  text-align: center !important;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  padding: 10px 0;
  font-size: 14px;
  /* background-color: red; */
  border-radius: 50%;
  width: 100%;
}
</style>