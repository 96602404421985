<template>
  <div class="py-4 container">
    <div class="container-fluid px-md-5">
      <div class="row center-on-desktop" v-if="!loading">
        <main
          class="
            col col-xl-12
            order-xl-2
            col-lg-12
            order-lg-1
            col-md-12 col-sm-12 col-12
          "
        >
          <ConnectionAside />
          <div class="mb-3 w-100 col-md-12">
            <Connections
              :key="getRerender"
              :users="getBlocked"
              type="blocked"
              emptyMessage="NoBlocked"
              :showSearch="false"
            />
          </div>
        </main>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import i18n from "../../i18n";
import APIURLS from "../../constant";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: `Bantaba | Community | ${this.$i18n.t("blocked")}`,
    };
  },
  async created() {
    if (this.authUser.confirmed) {
    this.$store.commit("setShowDiasporaLoadMore", false);
    if (!JSON.parse(localStorage.getItem("blocked"))) {
      await this.loadBlocked(this.authUser.id);
      this.users = this.getBlocked;
      //save to locale storage
      localStorage.setItem("blocked", JSON.stringify(this.getBlocked));
    } else {
      let users = JSON.parse(localStorage.getItem("blocked"));
      this.$store.commit("setBlocked", users);
    }
    this.$store.commit("setUsers", this.getBlocked);
      this.loading = false;

    } else {
      this.$noty.error(this.$i18n.t('NonApprovedMsg'));
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters(["authUser", "getBlocked", "getConnectionsCounts"]),
  },
  methods: {
    ...mapActions(["loadBlocked", "loadConnCounts"]),
  },
  data() {
    return {
      loading: true,
      users: {},
      counts: {},
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Blocked",
};
</script>

<style>
@media (min-width: 1200px) {
  .center-on-desktop {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .container-fluid {
    min-height: 100vh;
  }
  .mainBackground {
    min-height: 67vh !important;
  }
}
</style>
