<template>
  <transition name="modal">
    <div class="modal-mask" @click.self="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modalheader mb-1">
            <!-- <slot name="header"> </slot> -->
            <button class="modal-default-button" @click="$emit('close')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.654"
                height="22.654"
                viewBox="0 0 21.654 22.654"
              >
                <g
                  id="Group_2"
                  data-name="Group 2"
                  transform="translate(-1237.673 -235.673)"
                >
                  <line
                    id="Line_1"
                    data-name="Line 1"
                    x2="16"
                    y2="17"
                    transform="translate(1240.5 238.5)"
                    fill="none"
                    stroke="#707070"
                    stroke-linecap="round"
                    stroke-width="4"
                  />
                  <line
                    id="Line_2"
                    data-name="Line 2"
                    x1="16"
                    y2="17"
                    transform="translate(1240.5 238.5)"
                    fill="none"
                    stroke="#707070"
                    stroke-linecap="round"
                    stroke-width="4"
                  />
                </g>
              </svg>
            </button>
          </div>

          <div class="modal-body">
            <div class="modal-text-container">
              <slot name="body"> </slot>
            </div>
            
            
            <div class="text-center">
              <a @click="$emit('close')" class="btn btn-action my-4"
                ><span class="btn-text close-btn">{{ $t("Close") }}</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      expired: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Modal",
  // components: { FlipCountdown },
};
</script>
<style>
::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

::-webkit-scrollbar {
  width: 11px !important;
  height: 11px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  border: 2px solid white !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-body {
    padding-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
    padding: 25px;
    padding-bottom: 0;
    width: 100%;

}

.modal-text-container{
  width: 100;
  white-space: initial;
  

}
.text-center {
  text-align: center !important;
}
.btn-action:hover {
  /* background-color: var(--first-color); */
  background-color: #B30D00 !important;
  color: #fff !important;
}

.btn-action {
  border: 1px solid var(--first-color);
  border-radius: 15px !important;
  color: white !important;
  background-color: #B30D0050 !important;
}
.modal-mask {
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  padding-top: 80px;
  overflow-y: scroll;
}

.modal-wrapper {
      display: flex;
    height: auto;
    margin-top: 150px;
}

.modal-container {
  width: 100%;
  height: auto !important;
  max-width: 990px;
  padding: 20px;
  height: auto;
  /* height: 100%; */
  /* max-height: 500px; */
  border-bottom: 30px solid #fff;
  overflow-y: scroll !important;
  margin: 0px auto;
  /* padding: 20px !important; */
  padding: 0 !important;
  background-color: rgb(255, 255, 255);
  border-radius: 5px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.modal-default-button {
  border: none !important;
  position: relative;
  top: 0px;
  width: 30px;
  height: 30px;
  border-radius: 1500px !important;
  background: #ececec !important;
  cursor: pointer !important;
  right: 0px;
  float: right;
  font-size: 10px;
  z-index: 10000;
}

.modal-default-button svg {
  font-size: 10px;
  width: 15px;
  height: 15px;
}

.modal-default-button:hover {
  background: #cecccc !important;
}

.btn-icon .svg {
  fill: #707070 !important;
  color: #707070 !important;
}

@media screen and (min-width: 375px) and (max-width: 800px) {
  .modal-container {
    width: 380px;
    height: 550px !important;
    max-height: 1000px !important;
    border-bottom: 30px solid #fff;
    overflow: scroll !important;
  }
}

@media screen and (max-width: 375px) {
  .modal-container {
    width: 100%;
    height: 100% !important;
    max-height: 100vh !important;
    overflow: scroll !important;
    padding: 20px !important;
  }
  .modal-wrapper{
    margin-top: 0 !important;
    height: 100%;
  }
  .modal-body {
  padding-bottom: 0px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
      padding: 5px;
}
}

.modal-header h3 {
  margin-top: 0;
  text-align: center !important;
  color: #42b983;
}

.modal-default-button {
  border: none !important;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  background: rgb(148, 148, 148);
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
